import dayjs from "dayjs";
import ExcelJS from "exceljs";

export const exportAllToExcel = async (data) => {
  console.log(data);
  if (!Array.isArray(data)) {
    console.error("Data is not an array:", data);
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet1 = workbook.addWorksheet("Probation");

  const headerRow = worksheet1.addRow([
    "ชื่อ-สกุล",
    "บริษัท",
    "ฝ่าย",
    "ส่วน",
    "แผนก",
    "ตำแหน่ง",
    "รายการ",
    "ดำเนินการภายใน",
    "สถานะ",
    "วันที่ตรวจสอบ",
    "ตรวจสอบโดย",
  ]);

  const headerStyle = {
    font: {
      bold: true,
      size: 18,
      name: "TH SarabunPSK",
      color: { argb: "FFFFFF" },
    },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    },
  };

  headerRow.eachCell((cell) => {
    cell.style = headerStyle;
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  headerRow.height = 50;

  worksheet1.columns = [
    { header: "ชื่อ-สกุล", key: "employee", width: 25 },
    { header: "บริษัท", key: "company", width: 25 },
    { header: "ฝ่าย", key: "division", width: 20 },
    { header: "ส่วน", key: "department", width: 20 },
    { header: "แผนก", key: "section", width: 20 },
    { header: "ตำแหน่ง", key: "position", width: 20 },
    { header: "รายการ", key: "item", width: 20 },
    { header: "ดำเนินการภายใน", key: "day", width: 20 },
    { header: "สถานะ", key: "status", width: 15 },
    { header: "วันที่ตรวจสอบ", key: "updateDate", width: 15 },
    { header: "ตรวจสอบโดย", key: "reviewer", width: 25 },
  ];

  data.forEach((item) => {
    item.onBoardingList.forEach((onBoarding) =>
      worksheet1.addRow({
        employee: `${item.firstname_TH} ${item.lastname_TH}`,
        company: item.companyName || "-",
        division: item.divisionName || "-",
        department: item.departmentName || "-",
        section: item.sectionName || "-",
        position: item.positionName || "-",
        item: onBoarding.onBoardingListName || "-",
        day: onBoarding.day || "-",
        status:
          onBoarding.isApprove === 1
            ? "อนุมัติ"
            : onBoarding.isApprove === 0
            ? "ไม่อนุมัติ"
            : "รอดำเนินการ",
        updateDate: onBoarding.updateDate
          ? dayjs(onBoarding.updateDate).format("DD-MM-YYYY")
          : "-",
        reviewer: onBoarding.reviewer_firstname_TH
          ? `${onBoarding.reviewer_firstname_TH} ${onBoarding.reviewer_lastname_TH}`
          : "-",
      })
    );
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const firstEmployee = data.length > 0 ? data[0] : null;
    const fileName = firstEmployee ? `รายงานสถานะ Onboarding.xlsx` : "รายงานสถานะ Onboarding.xlsx";

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  });
};
