import React, { useState, useEffect } from "react";
import { styled } from "@mui/styles";
import { useHistory } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import EventNoteIcon from "@mui/icons-material/EventNote";
import StarIcon from '@mui/icons-material/Star';
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import {
  Grid,
  Button,
  Container,
  Typography,
  IconButton,
  Box,
  MenuItem,
  Stack,
  Divider,
  Chip,
  Card,
  CardContent,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Rating,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteKPI,
  getKPI,
  updateKPISubmit,
} from "../../../../../actions/kpi";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import KPIApprove from "../assets/KPIApprove.png";
import KPIPercent from "../assets/KPIPercent.png";
import KPIweight from "../assets/KPIweight.png";
import { t } from "i18next";
import KpiOrganizationDetail from "../organization/KpiOrganizationDetail";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ContainerStyled = styled(Container)({
  height: "100%",
  paddingTop: "100px",
  backgroundColor: "transparent",
  minHeight: "100vh",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'auto',
    padding: '8px',
  },
});

const StatusChip = styled(Chip)(({ theme, status }) => ({
  backgroundColor: status === 1 ? '#50B498' : status === 0 ? '#FF6969' : '#83B4FF',
  color: '#fff',
  borderRadius: '16px',
  minWidth: '140px',
}));

const ApproveProgress = styled(LinearProgress)({
  height: '10px',
  borderRadius: '5px',
  backgroundColor: '#FFF5D9',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#FFBB38',
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const TotalWeightProgress = styled(LinearProgress)({
  height: '10px',
  borderRadius: '5px',
  backgroundColor: '#DCFAF8',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#16DBCC',
  },
});

const AddNewButton = styled(Button)(({ disabled }) => ({
  color: disabled ? '#9e9e9e' : '#4535C1',
  backgroundColor: disabled ? '#EEEEEE' : 'white',
  border: `1px solid ${disabled ? '#9e9e9e' : '#4535C1'}`,
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: disabled ? '#EEEEEE' : '#f2f2f2',
  },
}));

const YearSelect = styled(TextFieldTheme)({
  width: "100px",
  height: "4 0px",  
  '& .MuiOutlinedInput-root': {
    height: "100%", 
    '& fieldset': {
      borderColor: '#4535C1',
    },
    '&:hover fieldset': {
      borderColor: '#4535C1',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4535C1',
    },
  },
  '& .MuiSelect-select': {
    backgroundColor: 'white',
    borderRadius: '8px',
    height: "100%", 
  },
});


const KpiCard = styled(Card)({
  marginBottom: "12px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "16px",
  padding: "14px", 
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px',
  },
});

const KpiCardComponent = ({ kpi, onDelete, onView, totalWeight }) => {
  const history = useHistory();
  const rating = Math.min(5, Math.max(0, Math.round(kpi.managerRating)));
  const kpiData = useSelector((state) => state.kpi.result);
  const progress = kpi.progress || 0;
  const currentDate = dayjs();

  const kpiEditDates = kpiData && kpiData.kpiEditDates ? kpiData.kpiEditDates : [];

  const isDateInRange = (dateRanges, currentDate) => {
    return dateRanges.some((dateRange) =>
      currentDate.startOf('day').isBetween(
        dayjs(dateRange.startDate).startOf('day'),
        dayjs(dateRange.endDate).endOf('day'),
        null,
        '[]'
      )
    );
  };
  

  const editDates = kpiEditDates.filter(dateItem => dateItem.type === "edit");
  const isEditDateInRange = isDateInRange(editDates, currentDate);
  
  const addDates = kpiEditDates.filter(dateItem => dateItem.type === "add");
  const isAddDateInRange = isDateInRange(addDates, currentDate);
  

  return (
    <Card
      sx={{
        marginBottom: "16px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center">
          <Box
            sx={{
              backgroundColor: "#F4F6FA",
              borderRadius: "16px",
              padding: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "90px",
              height: "80px",
              marginRight: { sm: "16px", xs: "0px" },
              marginBottom: { xs: "16px", sm: "0px" },
            }}
          >
            <Typography
              variant="h6"
              component="div"
              color="#4535C1"
              fontSize="14px"
              fontWeight="400"
              textAlign="center"
            >
              {t("Weight")}<br />
              <Typography
                variant="h6"
                component="div"
                fontSize="28px"
                color="#4535C1"
                fontWeight="600"
              >
                {`${Math.round(kpi.weight)}%`}
              </Typography>
            </Typography>
          </Box>
          <Box flex={1} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" mb={{ xs: 2, sm: 0 }}>
            <Box flex={2} mr={2}>
              <Typography variant="body2">
                <span style={{ color: "#718EBF", fontWeight: 500 }}>{t("KPIName")}: </span>
                <Typography fontSize={16}>{kpi.kpiName}</Typography>
              </Typography>
            </Box>
            <Box flex={1.2} mr={2}>
              <Typography variant="body2">
                <span style={{ color: "#718EBF", fontWeight: 500 }}>{t("Date")}: </span>
                <Typography fontSize={16}>
                  {dayjs(kpi.startDate).format("YYYY/MM/DD")} - {dayjs(kpi.endDate).format("YYYY/MM/DD")}
                </Typography>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start" mr={2}>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="body2" color="#718EBF" fontWeight={500} mr={1}>
                  {t("Progress")}:
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    width: { xs: '100px', sm: '150px' },
                    height: '8px',
                    borderRadius: '5px',
                    backgroundColor: '#FFCBCB',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FF4E88',
                    },
                  }}
                />
                <Typography ml={1} color="#FF4E88" fontWeight={600}>{progress}%</Typography>
              </Box>

              <Box display="flex" alignItems="center">
                <Typography variant="body2" color="#718EBF" fontWeight={500} mr={1}>
                  {t("Rating")}:
                </Typography>
                <Box display="flex" alignItems="center">
                  {[...Array(5)].map((_, index) => (
                    <StarIcon
                      key={index}
                      sx={{
                        fontSize: "30px",
                        color: index < rating ? "#FFC403" : "#E0E0E0",
                        marginRight: index < 4 ? "4px" : 0,
                      }}
                    />
                  ))}
                  <Typography fontWeight={600} ml={1} color="#FFC403">
                    {rating}.0
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                sx={{ color: "#604CC3" }}
                onClick={() => onView(kpi)}
              >
                <EventNoteIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  history.push({
                    pathname: "/kpi-individual-plan",
                    state: { planList: kpi, isEdit: true, totalWeight },
                  });
                }}
                sx={{ color: kpi.isApprove === 1 && isEditDateInRange ? '#4535C1' : '#EEEEEE' }}
                disabled={!(kpi.isApprove === 1 && isEditDateInRange)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={() => onDelete(kpi.idKPI)}
                disabled={kpi.isApprove === 1}
                sx={{ color: kpi.isApprove === 1 ? '#EEEEEE' : '#E4003A' }}
              >
                <DeleteOutlineRoundedIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Box mt={1}>
              <StatusChip 
                status={kpi.isApprove} 
                label={kpi.isApprove === 1 ? t("Approved") : kpi.isApprove === 0 ? t("NotApproved") : t("Waiting")}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

function KpisIndividualPlanList() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const kpiData = useSelector((state) => state.kpi.result);
  const [selectedYear, setSelectedYear] = useState(dayjs().get("year"));
  const [planItems, setPlanItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); 
  const [openOrganizationKpi, setOpenOrganizationKpi] = useState(false); 
  const [selectedKpi, setSelectedKpi] = useState(null); 

  const OrganizationWeight = kpiData
    ? kpiData.kpiOrganization.reduce((sum, item) => sum + item.weight, 0)
    : 0

  const totalWeight =
    planItems.reduce(
      (sum, item) => sum + (item.isApprove === 1 ? parseFloat(item.weight) : 0),
      0
    ) + OrganizationWeight;

  const approvedCount = planItems.filter((item) => item.isApprove === 1).length;
  const validPlanItems = planItems.filter((item) => item.isApprove !== 0).length;
  const currentDate = dayjs();
  const kpiPlanStore = kpiData && kpiData.kpis ? kpiData.kpis : [];
  const kpiEditDates = kpiData && kpiData.kpiEditDates ? kpiData.kpiEditDates : [];

  const isDateInRange = (dateRanges, currentDate) => {
    const normalizedCurrentDate = currentDate.startOf('day');
    
    return dateRanges.some((dateRange) => {
      const startDate = dayjs(dateRange.startDate).startOf('day');
      const endDate = dayjs(dateRange.endDate).endOf('day'); 
      // console.log("Comparing:", {
      //   startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
      //   endDate: endDate.format('YYYY-MM-DD HH:mm:ss'),
      //   currentDate: normalizedCurrentDate.format('YYYY-MM-DD HH:mm:ss')
      // });
  
      return normalizedCurrentDate.isBetween(startDate, endDate, null, '[]');
    });
  };
  
  const addDates = kpiEditDates.filter(dateItem => dateItem.type === "add");
  const isAddDateInRange = isDateInRange(addDates, currentDate);

    
  const onDeleteKPI = async (idKPI) => {
    const res = await dispatch(deleteKPI(idKPI));
    if (res && res.status === 200) {
      Swal.fire({
        title: t("Success"),
        text: t("KPIRemovedSuccessfully"),
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: t("OK"),
      });
      await dispatch(getKPI());
    }
  };

  const onDelete = async (idKPI) => {
    try {
      const result = await Swal.fire({
        title: t("ConfirmKPIDeletion"),
        text: t("CannotUndo")+"!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        await onDeleteKPI(idKPI);
      }
    } catch (error) {
      console.error("Error deleting KPI:", error);
    }
  };

  const handleYearChange = (event) => {
    const filterKPI = kpiPlanStore.filter(
      (item) => dayjs(item.createAt).get("year") === event.target.value
    );
    setPlanItems(filterKPI);
    setSelectedYear(event.target.value);
  };

  const handleView = (kpi) => {
    setSelectedKpi(kpi); 
    setOpenDialog(true); 
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedKpi(null);
  };

  const handleOpenOrganizationKpi = (kpi) => {
    setSelectedKpi(kpi);
    setOpenOrganizationKpi(true)
  };

  const handleCloseOrganizationKpi = () => {
    setOpenOrganizationKpi(false); 
    setSelectedKpi(null);
  };

  useEffect(() => {
    dispatch(getKPI());
  }, []);

  useEffect(() => {
    if (kpiPlanStore.length > 0) {
      let kpiPlanList = [...kpiPlanStore];
      setPlanItems(kpiPlanList);
    }
  }, [kpiPlanStore]);

  return (
    <ContainerStyled>
      {kpiPlanStore && (
        <>
          <Box marginBottom="32px">
            <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
                  KPI
                </Typography>
              </Box>
            </GridStyled>
  
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems="flex-start"
              mt={1}
              mb={4}
              sx={{
                gap: "16px",
                padding: { xs: "0 16px", sm: "0 8px" },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "100%", sm: "29%" },
                  height: "auto",
                }}
              >
                <img
                  src={KPIweight}
                  alt="KPIs"
                  style={{ height: "70px", marginRight: "16px" }}
                />
                <Box>
                  <Typography variant="body1" fontWeight="600">
                    KPIs
                  </Typography>
                  <Typography variant="body1" mt={1}>
                    {t("All")} {validPlanItems} {t("List")}
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "100%", sm: "29%" },
                  height: "auto",
                }}
              >
                <img
                  src={KPIApprove}
                  alt={t("Approve")}
                  style={{ height: "70px", marginRight: "16px" }}
                />
                <Box width="100%">
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1" fontWeight="600">
                      {t("Approve")}
                    </Typography>
                    <Typography variant="body1" fontWeight="600">
                      {validPlanItems === 0
                        ? "0"
                        : ((approvedCount / validPlanItems) * 100).toFixed(
                            2
                          )}{" "}
                      %
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <ApproveProgress
                      variant="determinate"
                      value={validPlanItems === 0 ? 0 : (approvedCount / validPlanItems) * 100}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                  <Typography variant="body2" align="right" mt={1}>{approvedCount} / {validPlanItems} {t("List")}</Typography>
                </Box>
              </Box>
  
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "100%", sm: "29%" },
                  height: "auto",
                }}
              >
                <img src={KPIPercent} alt={t("TotalWeight")} style={{ height: "70px", marginRight: "16px" }} />
                <Box width="100%">
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1" fontWeight="600">{t("TotalWeight")}</Typography>
                    <Typography variant="body1" fontWeight="600">{totalWeight} %</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={totalWeight > 100 ? 1 : 2}>
                    <TotalWeightProgress
                      variant="determinate"
                      value={totalWeight}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                  {totalWeight > 100 && (
                    <Typography variant="body2" color="error" align="right" mt={1}>
                      {t("MaxWeightAllowed")} 100%
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
  
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography variant="h7" sx={{ color: "#343C6A", fontSize: "20px", fontWeight: "500" }}>{t("Years")}</Typography>
              <YearSelect
                variant="outlined"
                select
                fullWidth
                value={selectedYear}
                onChange={handleYearChange}
              >
                <MenuItem value={dayjs().get("year")}>
                  {dayjs().format(
                    i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                  )}
                </MenuItem>
                <MenuItem value={dayjs().get("year") - 1}>
                  {dayjs()
                    .subtract(1, "year")
                    .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                </MenuItem>
              </YearSelect>
            </Stack>
            <AddNewButton
              variant="outlined"
              onClick={() => {
                history.push({
                  pathname: "/kpi-individual-plan",
                  state: { planList: null, isEdit: null, totalWeight },
                });
              }}
              disabled={totalWeight >= 100 || !isAddDateInRange}  
            >
              +{t("AddNewKPI")}
            </AddNewButton>
          </Box>
          {kpiData &&
            kpiData.kpiOrganization.length > 0 &&
            kpiData.kpiOrganization.map((kpi, index) => (
              <Card
                key={index}
                sx={{
                  marginBottom: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "16px",
                  position: "relative",
                }}
              >
                <CardContent>
                  <Box
                    position={"absolute"}
                    top={"-1px"}
                    left={"30px"}
                    color={"#ffffff"}
                    bgcolor={"#ff4e88"}
                    borderRadius={"8px"}
                    padding={"2px 4px"}
                    fontSize={"14px"}
                  >
                    {t("Cascade")}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems="center"
                    gap={2}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#F4F6FA",
                        borderRadius: "16px",
                        padding: "16px 24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        color="#4535C1"
                        fontSize="14px"
                        fontWeight="400"
                        textAlign="center"
                      >
                        {t("Weight")}
                        <Typography
                          variant="h6"
                          component="div"
                          fontSize="28px"
                          color="#4535C1"
                          fontWeight="600"
                        >
                          {`${Math.round(kpi.weight)}%`}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      flex={1}
                      display="flex"
                      flexDirection={{ xs: "column", sm: "row" }}
                      alignItems="center"
                      mb={{ xs: 2, sm: 0 }}
                    >
                      <Box flex={2} mr={2}>
                        <Typography variant="body2">
                          <span style={{ color: "#718EBF", fontWeight: 500 }}>
                            {t("KPIName")}:{" "}
                          </span>
                          <Typography fontSize={16}>{kpi.kpiName}</Typography>
                        </Typography>
                      </Box>
                      <Box flex={1.2} mr={2}>
                        <Typography variant="body2">
                          <span style={{ color: "#718EBF", fontWeight: 500 }}>
                            {t("Date")}:{" "}
                          </span>
                          <Typography fontSize={16}>
                            {dayjs(kpi.startDate).format("YYYY/MM/DD")} -{" "}
                            {dayjs(kpi.endDate).format("YYYY/MM/DD")}
                          </Typography>
                        </Typography>
                      </Box>
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color="#718EBF"
                            fontWeight={500}
                            mr={1}
                          >
                            {t("Progress")}:
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={kpi.progress}
                            sx={{
                              width: { xs: "100px", sm: "150px" },
                              height: "8px",
                              borderRadius: "5px",
                              backgroundColor: "#FFCBCB",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#FF4E88",
                              },
                            }}
                          />
                          <Typography ml={1} color="#FF4E88" fontWeight={500}>
                            {kpi.progress}%
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color="#718EBF"
                            fontWeight={500}
                            mr={1}
                          >
                            {t("Rating")}:
                          </Typography>
                          <Box display="flex" alignItems="flex-end" gap={1}>
                            <Rating value={kpi.rating} readOnly />
                            <Typography fontWeight={500} color="#FFC403">
                              {kpi.rating ? kpi.rating : null}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Typography
                            variant="body2"
                            color="#718EBF"
                            fontWeight={500}
                          >
                            {t("Status")}:
                          </Typography>
                          {kpi.evaluate === null ? (
                            <Chip label={t("HaveNotBeenEvaluated")} />
                          ) : kpi.evaluate === 1 ? (
                            <Chip
                              sx={{
                                backgroundColor: "#50B498",
                                color: "#ffffff",
                              }}
                              label={t("Success")}
                            />
                          ) : (
                            <Chip
                              sx={{
                                backgroundColor: "#E46A76",
                                color: "#ffffff",
                              }}
                              label={t("Failure")}
                            />
                          )}
                        </Box>
                      </Stack>
                    </Box>
                    <Box display="flex" alignItems="flex-end">
                      <IconButton
                        sx={{ color: "#604CC3" }}
                        onClick={() => handleOpenOrganizationKpi(kpi)}
                      >
                        <EventNoteIcon />
                      </IconButton>
                      <ButtonBlue
                        variant="outlined"
                        component={Link}
                        to={{
                          pathname: "/kpi/organization/update/user",
                          state: { planList: kpi }
                        }}
                      >
                        {t("Progress")}
                      </ButtonBlue>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          {planItems.filter(item => item.isApprove === 1 && item.isApprove !== 0).map((item, index) => (
            <KpiCardComponent key={index} kpi={item} onDelete={onDelete} onView={handleView} totalWeight={totalWeight}/>
          ))}
          {planItems.filter(item => item.isApprove !== 1 && item.isApprove !== 0).map((item, index) => (
            <KpiCardComponent key={index} kpi={item} onDelete={onDelete} onView={handleView} />
          ))}
          {planItems.filter(item => item.isApprove === 0).map((item, index) => (
            <KpiCardComponent key={index} kpi={item} onDelete={onDelete} onView={handleView} />
          ))}
  
          {planItems.length === 0 && (
            <Box
              height="120px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={4}
            >
              <Typography variant="h6">
                <FindInPageIcon />
                {t("NoData")}
              </Typography>
            </Box>
          )}
          {selectedKpi && (
            <KpiOrganizationDetail
              open={openOrganizationKpi}
              handleClose={handleCloseOrganizationKpi}
              selectedKpi={selectedKpi}
            />
          )}
          {selectedKpi && (
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth="md"
              fullWidth
              PaperProps={{
                style: {
                  padding: 0,
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  width: '700px', 
                  height: '1200px',
                },
              }}
            >
              <div className="card-section" style={{ backgroundColor: '#fff', borderRadius: '28px', overflow: 'hidden' }}>
                <div className="header-section">
                  <Grid container>
                    <Grid item>
                      <h3 className="header-topic">KPI Details</h3>
                    </Grid>
                  </Grid>
                </div>
                <Box padding="12px" marginTop="-28px" style={{ overflowY: 'auto', maxHeight: '700px' }}>
                  <Stack spacing={2}>
                    <Typography marginBottom="8px">
                      <span style={{ fontSize: "25px", fontWeight: "500", color: '#083A83' }}>{selectedKpi.kpiName}</span>
                    </Typography>
                    <Typography marginBottom="8px">
                      <span style={{ color: "#083A83", paddingRight: "8px", fontSize: "18px" }}>{t("Scope")} :</span>
                      {selectedKpi.scope}
                    </Typography>
                    <StyledDivider />
                    <Typography marginBottom="8px">
                      <span style={{ color: "#083A83", paddingRight: "8px", fontSize: "18px" }}>{t("Target")} :</span>
                      {selectedKpi.target}
                    </Typography>
                    <StyledDivider />
                    <Typography marginBottom="8px">
                      <span style={{ color: "#083A83", paddingRight: "8px", fontSize: "18px" }}>{t("Evidence")} :</span>
                      {selectedKpi.evidence}
                    </Typography>
                    <StyledDivider />
                    <Typography marginBottom="8px">
                      <span style={{ color: "#083A83", paddingRight: "8px", fontSize: "18px" }}>{t("Descriptions")} :</span>
                      {selectedKpi.description}
                    </Typography>
                    <StyledDivider />
                    <Typography marginBottom="8px">
                      <span style={{ color: "#083A83", paddingRight: "8px", fontSize: "18px" }}>{t("Duration")} :</span>
                      {`${dayjs(selectedKpi.startDate).format(
                        i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                      )} - ${dayjs(selectedKpi.endDate).format(
                        i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                      )}`}
                    </Typography>
                    <Typography marginBottom="8px">
                      <span style={{ color: "#083A83", paddingRight: "8px", fontSize: "18px" }}>{t("Assessor")} :</span>
                      {selectedKpi.asessor_firstname_TH} {selectedKpi.asessor_lastname_TH}
                    </Typography>
                    <Typography marginBottom="8px">
                      <span style={{ color: "#083A83", paddingRight: "8px", fontSize: "18px" }}>{t("TypeMeasurement")} :</span>
                      {selectedKpi.measureType}
                    </Typography>
                    <Typography marginBottom="8px">
                      <span style={{ color: "#083A83", paddingRight: "8px", fontSize: "18px" }}>{t("Weight")} :</span>
                      {selectedKpi.weight}
                    </Typography>
                  </Stack>
                </Box>
              </div>
            </Dialog>
          )}
        </>
      )}
    </ContainerStyled>
  );
}  
export default KpisIndividualPlanList;
