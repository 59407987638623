import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArticleIcon from "@mui/icons-material/Article";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import {
  getUserDepartment,
  getUserDivision,
  getUserFullName,
  getUserPosition,
  getUserSection,
} from "../../../../../utils/userData";
import { useSelector } from "react-redux";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  maxWidth: "320px",
  marginBottom: "16px",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const ROWS_PER_PAGE = 25;

const StatusShow = ({ status }) => {
  const { t } = useTranslation();

  let bgColor = null;
  let text = null;

  if (status === 1) {
    text = t("Pass");
    bgColor = "#4caf50";
  } else if (status === 0) {
    text = t("NotPass");
    bgColor = "#ef5350";
  } else {
    text = t("WaitingForEvaluation");
    bgColor = "#ff9800";
  }

  return (
    <Chip
      sx={{
        backgroundColor: bgColor,
        minWidth: "80px",
        color: "#ffffff",
      }}
      label={text}
    />
  );
};

function Row(props) {
  const { row, handleEachDetail } = props;
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSelectDetail = (item, row) => {
    handleEachDetail(item, {
      firstname_TH: row.firstname_TH,
      lastname_TH: row.lastname_TH,
      firstname_EN: row.firstname_EN,
      lastname_EN: row.lastname_EN,
      positionName: row.positionName,
      positionName_EN: row.positionName_EN,
    });
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ padding: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar
              sx={{
                width: 40,
                height: 40,
                "& img": { objectFit: "contain" },
              }}
              src={row.imageURL}
            />
            {getUserFullName(row)}
          </Stack>
        </TableCell>
        <TableCell>{getUserDivision(row)}</TableCell>
        <TableCell>{getUserDepartment(row)}</TableCell>
        <TableCell>{getUserSection(row)}</TableCell>
        <TableCell>
          {getUserPosition(row)}
        </TableCell>
        <TableCell>
          {dayjs(row.hiringDate).format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}
        </TableCell>
      </TableRow>
      <TableRow sx={{ bgcolor: "#f8f8f8" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {t("Probation")}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("ProbationaryExpirationDate")}</TableCell>
                    <TableCell align="center">{t("ProbationRound")}</TableCell>
                    <TableCell align="center">{t("Status")}</TableCell>
                    <TableCell align="center">{t("Descriptions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.probation.map((item) => {
                    const result =
                      item.adminResult !== null
                        ? item.adminResult
                        : item.managerLV2Result !== null
                        ? item.managerLV2Result
                        : item.managerLV1Result;
                    return (
                      <TableRow
                        key={item.idProbation}
                        sx={{ "&:last-child td": { borderBottom: 0 } }}
                      >
                        <TableCell>
                          {dayjs(item.probationDate).format(
                            i18n.resolvedLanguage === "th"
                              ? "D MMM BBBB"
                              : "D MMM YYYY"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Chip
                            sx={{
                              color: "#ffffff",
                              bgcolor: item.probationRound === 1 ? "#42a5f5" : "#ba68c8",
                            }}
                            label={`${t("RoundNo")} ${item.probationRound}`}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(row.resignDate).isBefore(item.probationDate) ? (
                            <Chip label={t("Terminated")} color="error" />
                          ) : result === 1 ? (
                            <Chip label={t("Pass")} color="success" />
                          ) : result === 0 ? (
                            <Chip label={t("NotPass")} color="error" />
                          ) : (
                            <Chip label={t("WaitingForEvaluation")} color="warning" />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <ButtonBlue
                            variant="contained"
                            onClick={() => handleSelectDetail(item, row)}
                            // disabled={
                            //   item.employeeEvaluateDate === null &&
                            //   item.managerEvaluateDate === null
                            // }
                          >
                            <ArticleIcon />
                          </ButtonBlue>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const status = { pass: 1, notpass: 0, waiting: null }

const TableProbationStatus = (props) => {
  const { rows, handleEachDetail } = props;
  const { t } = useTranslation();
  const { result: employees } = useSelector((state) => state.employees);
  const [selectEmployee, setSelectEmployee] = useState(null);
  const [selectStatus, setSelectStatus] = useState("all");
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const filterData = rows.filter(item => {
    if (selectEmployee) {
      return item.idEmployees === selectEmployee.idEmployees
    }
    if (selectStatus === "all") {
      return item
    }
    return item.probation.some((p) => {
      const result = p.adminResult !== null ? p.adminResult : p.managerResult
      return result === status[selectStatus]
    });
  })

  useEffect(() => {
    setSelectEmployee(null);
  }, [rows]);

  return (
    <div>
      <Divider sx={{ marginBottom: "16px" }} />
      <div style={{ display: "flex", gap: "8px" }}>
        <div style={{ maxWidth: "320px", width: "100%" }}>
          <Typography color="text.third" fontWeight={600} fontSize={14}>
            {t("SearchEmp")}
          </Typography>
          <StyledAutocomplete
            fullWidth
            options={employees || []}
            value={selectEmployee}
            onChange={(event, newValue) => setSelectEmployee(newValue)}
            popupIcon={<i className="fa-light fa-chevron-down"></i>}
            isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
            getOptionLabel={(option) => getUserFullName(option)}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.idEmployees}>
                  {getUserFullName(option)}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                placeholder={t("SelectEmp")}
              />
            )}
            noOptionsText={t("NoData")}
          />
        </div>
        <div>
          <Typography color="text.third" fontWeight={600} fontSize={14}>
            {t("SelectStatus")}
          </Typography>
          <FormControl sx={{ backgroundColor: "#919eab14", minWidth: 120 }}>
            <Select
              value={selectStatus}
              onChange={(event) => setSelectStatus(event.target.value)}
              sx={{ borderRadius: "8px" }}
            >
              <MenuItem value={"all"}>{t("All")}</MenuItem>
              <MenuItem value={"pass"}>{t("Pass")}</MenuItem>
              <MenuItem value={"notpass"}>{t("NotPass")}</MenuItem>
              <MenuItem value={"waiting"}>{t("WaitingForEvaluation")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {filterData.length > 0 ? (
        <Stack spacing={2} alignItems="center">
          <TableContainer style={{ minHeight: 400 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledCellHeader style={{ padding: 0 }} />
                  <StyledCellHeader style={{ minWidth: 160 }}>{t("FullName")}</StyledCellHeader>
                  <StyledCellHeader>{t("Division")}</StyledCellHeader>
                  <StyledCellHeader>{t("Department")}</StyledCellHeader>
                  <StyledCellHeader>{t("Section")}</StyledCellHeader>
                  <StyledCellHeader>{t("Position")}</StyledCellHeader>
                  <StyledCellHeader style={{ minWidth: 100 }}>{t("HiringDate")}</StyledCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterData
                .slice(ROWS_PER_PAGE * page - ROWS_PER_PAGE, ROWS_PER_PAGE * page)
                .map((r, rIndex) => (
                  <Row key={rIndex} row={r} handleEachDetail={handleEachDetail} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(filterData.length / ROWS_PER_PAGE)} 
            page={page}
            onChange={handleChange}
            color="primary" 
          />
        </Stack>
      ) : (
        <Box
          style={{
            minHeight: 400,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize="20px">{t("NoData")}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TableProbationStatus;
