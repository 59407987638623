import ExcelJS from 'exceljs';
export const OrganizationStructureXlsx = async (t, data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet(t("ManageOrganizationalStructure"));

    const headerRow = worksheet1.addRow([
        `${t("departmentCode")} (Organization Code)`,
        t("departmentName"),
        `${t("departmentName")} (English)`,
        t("departmentLevel"),
        t("currentHead"),
        t("CostCenter"),
        "Parent",
    ]);

    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
    });

    const colWidths = [
        { key: "code", width: 40 },
        { key: "name", width: 40 },
        { key: "name_EN", width: 40 },
        { key: "organization", width: 40 },
        { key: "manager", width: 40 },
        { key: "costCenter", width: 40 },
        { key: "parent", width: 40 },
    ];

    let row = []

    if (data.ceos) {
        data.ceos.forEach((item) => {
            row = [
                item.code ? item.code : "-",
                item.name ? item.name : "-",
                item.name_EN ? item.name_EN : "-",
                item.nodeType ? item.nodeType : "-",
                item.mainPosition && item.mainPosition.employee && item.mainPosition.employee.firstname_TH
                    ? `${item.mainPosition && item.mainPosition.employee && item.mainPosition.employee.firstname_TH} ${item.mainPosition && item.mainPosition.employee && item.mainPosition.employee.lastname_TH}`
                    : "-",
                item.costCenter ? item.costCenter : "-",
                item.parent !== null ? item.parent.parentCode : "-"
            ];
            const excelRow = worksheet1.addRow(row);
            const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

            colWidths.forEach((col, index) => {
                worksheet1.getColumn(index + 1).width = col.width;
            });

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        })
    }

    if (data.businessUnits) {
        data.businessUnits.forEach((item) => {
            row = [
                item.code ? item.code : "-",
                item.name ? item.name : "-",
                item.name_EN ? item.name_EN : "-",
                item.nodeType ? item.nodeType : "-",
                item.manager_firstname_TH ? `${item.manager_firstname_TH} ${item.manager_lastname_TH}` : "-",
                item.costCenter ? item.costCenter : "-",
                item.parent !== null ? item.parent.parentCode : "-"
            ];
            const excelRow = worksheet1.addRow(row);
            const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

            colWidths.forEach((col, index) => {
                worksheet1.getColumn(index + 1).width = col.width;
            });

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        })
    }

    if (data.divisions) {
        data.divisions.forEach((item) => {
            row = [
                item.code ? item.code : "-",
                item.name ? item.name : "-",
                item.name_EN ? item.name_EN : "-",
                item.nodeType ? item.nodeType : "-",
                item.manager_firstname_TH
                    ? `${item.manager_firstname_TH} ${item.manager_lastname_TH}`
                    : "-",
                item.costCenter ? item.costCenter : "-",
                item.parent !== null ? item.parent.parentCode : "-"
            ];
            const excelRow = worksheet1.addRow(row);
            const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

            colWidths.forEach((col, index) => {
                worksheet1.getColumn(index + 1).width = col.width;
            });

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        })
    }

    if (data.departments) {
        data.departments.forEach((item) => {
            row = [
                item.code ? item.code : "-",
                item.name ? item.name : "-",
                item.name_EN ? item.name_EN : "-",
                item.nodeType ? item.nodeType : "-",
                item.manager_firstname_TH
                    ? `${item.manager_firstname_TH} ${item.manager_lastname_TH}`
                    : "-",
                item.costCenter ? item.costCenter : "-",
                item.parent !== null ? item.parent.parentCode : "-"
            ];
            const excelRow = worksheet1.addRow(row);
            const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

            colWidths.forEach((col, index) => {
                worksheet1.getColumn(index + 1).width = col.width;
            });

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        })
    }

    if (data.sections) {
        data.sections.forEach((item) => {
            row = [
                item.code ? item.code : "-",
                item.name ? item.name : "-",
                item.name_EN ? item.name_EN : "-",
                item.nodeType ? item.nodeType : "-",
                item.manager_firstname_TH
                    ? `${item.manager_firstname_TH} ${item.manager_lastname_TH}`
                    : "-",
                item.costCenter ? item.costCenter : "-",
                item.parent !== null ? item.parent.parentCode : "-"
            ];
            const excelRow = worksheet1.addRow(row);
            const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

            colWidths.forEach((col, index) => {
                worksheet1.getColumn(index + 1).width = col.width;
            });

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        })
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("ManageOrganizationalStructure")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}