import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import {
  Grid,
  Typography,
  TextField,
  Avatar,
  Box,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  FormControl,
  FilledInput,
  InputAdornment,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form"
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { getDepartment } from "../../../../actions/company";
import { getAllEmployees } from "../../../../actions/employee";
import {
  getAffiliateOrganization,
} from "../../../../actions/company";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import SearchIcon from "@mui/icons-material/Search"

//Translator TH-EN
import { useTranslation } from "react-i18next";

import More from "./viewmore";
import DialogImport from "./dialogImport";
import {
  getAllDocumentsByIdFilter,
  getDocumentTypeName,
} from "../../../../actions/document";
import { getFullNameLang } from "../shift/addShift/dnd/utils";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));


const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const documentPage = (props) => {
  const { idDocumentGroup, typeDialog, setTypeDialog } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { result: docItemName } = useSelector((state) => state.documentItem);
  const { result: employeesList } = useSelector((state) => state.document);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({ idCompany: 1 });
  const [doc, setDoc] = useState({ idDocumentGroup: 1, idDocumentType: 1, documentTypeName: 'default' });

  const [docName, setDocname] = useState([]);
  const [idEmployees, setIdEmployees] = React.useState(1);
  const [reload, setReload] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllEmployees());
    if (userProfile) {
      dispatch(getAllDocumentsByIdFilter({ idCompany: userProfile.idCompany, page: page + 1, rowsPerPage }));
      dispatch(getDocumentTypeName(idDocumentGroup, idEmployees, doc.idDocumentType, doc.documentTypeName, userProfile.idCompany));
    }
    dispatch(getAffiliateOrganization());
  }, [idDocumentGroup]);

  const [documentName, setDocumentName] = useState([]);

  useEffect(() => {
    if (docItemName) {
      setDocumentName(docItemName);
    }
  }, [docItemName]);

  const docNameActivate = documentName ? documentName.filter(doc => doc.isActivate === 1) : [];

  useEffect(() => {
    if (employeesList) {
      setTotal(employeesList ? employeesList.total : 0)
    }
  }, [employeesList]);

  useEffect(() => {
    if (documentName)
      setDocname(
        documentName.filter((d) => {
          return d.isShow === 1;
        })
      );
  }, [documentName]);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const [openRow, setOpenRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [savedDoc, setSavedDoc] = useState(null)

  const handleToggle = (type, index, d) => {
    setOpenRow(openRow === index ? null : index);
    setSavedDoc(d);
  };

  useEffect(() => {
    if (!open && typeDialog === 'more' && savedDoc) {
      setDoc(savedDoc);
    }
  }, [open, typeDialog, savedDoc]);

  const handleClose = () => {
    setOpen(false);
    setTypeDialog("more");
  };

  useEffect(() => {
    if (reload) {
      if (selectedCompany && selectedCompany.idCompany) {
        dispatch(getAllDocumentsByIdFilter({ idCompany: userProfile.idCompany, page: page + 1, rowsPerPage }));
      }
      setReload(true);
    } else {
      setReload(false);
    }
  }, [reload]);

  const handleDialog = async (type, formData, d) => {
    setTypeDialog(type);
    setOpen(true);

    if (formData) {
      setFormData(formData);
      setIdEmployees(formData.idEmployees);
    }
    if (d) {
      if (type === 'edit') {
        try {
          const res = await dispatch(
            getDocumentTypeName(
              idDocumentGroup,
              formData.idEmployees,
              d.idDocumentType,
              d.documentTypeName,
              selectedCompany.idCompany
            ));
          const foundDocumentType = res.find(x => x.idDocumentType === d.idDocumentType);
          if (foundDocumentType && selectedCompany) {
            setDoc({
              ...foundDocumentType,
              idDocumentGroup: idDocumentGroup,
              idDocumentType: d.idDocumentType,
              documentTypeName: d.documentTypeName,
              fileURL: foundDocumentType.fileURL,
              idCompany: selectedCompany.idCompany
            });
          }
        } catch (err) {
          console.error('Error fetching document type:', err);
        }
      } else if (type === 'more') {
        setDoc(d);
      } else if (type === 'import') {
        setOpenRow(null)
        setDoc(d);
      }
    } else {
      setDoc({ idDocumentType: 1, documentTypeName: '' });
    }
  };

  const useHookForm = useForm({
    defaultValues: {
      name: "",
      company: { idCompany: "all", companyName: `${t("All")}` },
      businessUnit: { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
      division: { idDivision: "all", divisionName: `${t("All")}` },
      department: { idDepartment: "all", departmentName: `${t("All")}` },
      section: { idSection: "all", sectionName: `${t("All")}` },
      position: { idPosition: "all", positionName: `${t("All")}` },
      manager: { idEmployees: "all", firstname_TH: `${t("All")}`, lastname_TH: "" },
    },
    mode: "all",
  })

  const handleClear = () => {
    useHookForm.reset();
  };

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isTerminate, setIsTermiante] = useState(0);
  const [moreFilter, setMoreFilter] = useState(false);
  const [businessUnitList, setBusinessUnitList] = useState(null);
  const [divisionList, setDivisionList] = useState(null);
  const [sectionList, setSectionList] = useState(null);
  const [positionList, setPositionList] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);

  const onSubmit = async (data) => {

    const searchData = {
      name: data.name ? data.name : null,
      idCompany: (data.company && data.company.idCompany !== "all") ? data.company.idCompany : null,
      idBusinessUnit: (data.businessUnit && data.businessUnit.idBusinessUnit !== "all") ? data.businessUnit.idBusinessUnit : null,
      idDivision: (data.division && data.division.idDivision !== "all") ? data.division.idDivision : null,
      idDepartment: (data.department && data.department.idDepartment !== "all") ? data.department.idDepartment : null,
      idSection: (data.section && data.section.idSection !== "all") ? data.section.idSection : null,
      idPosition: (data.position && data.position.idPosition !== "all") ? data.position.idPosition : null,
      idManager: (data.manager && data.manager.idEmployees !== "all") ? data.manager.idEmployees : null,
      rowsPerPage,
      page: 1,
      isTerminate: isTerminate,
      language: i18n.language,
    }

    setPage(0)
    dispatch(getAllDocumentsByIdFilter(searchData)).then(res => {
      setTotal(res && res.data ? res.data.total : 0)
    })
  }

  const renderAllDataSelect = (organization) => {

    let uniqueBusinessUnit = [
      ...new Map(
        organization.map((item) => [item["idBusinessUnit"], item])
      ).values(),
    ];

    setBusinessUnitList(uniqueBusinessUnit.filter((x) => x.idBusinessUnit));

    let uniqueDivision = [
      ...new Map(
        organization.map((item) => [item["idDivision"], item])
      ).values(),
    ];

    setDivisionList(uniqueDivision.filter((x) => x.idDivision));

    let uniqueDepartment = [
      ...new Map(
        organization.map((item) => [item["idDepartment"], item])
      ).values(),
    ];

    setDepartmentList(uniqueDepartment.filter((x) => x.idDepartment));

    let uniqueSection = [
      ...new Map(
        organization.map((item) => [item["idSection"], item])
      ).values(),
    ];

    setSectionList(uniqueSection.filter((x) => x.idSection));

    let uniquePosition = [
      ...new Map(
        organization.map((item) => [item["idPosition"], item])
      ).values(),
    ];

    setPositionList(uniquePosition.filter((x) => x.idPosition));
  };

  const handleChangePage = (_, newPage) => {

    const searchData = {
      name: useHookForm.getValues("name") ? useHookForm.getValues("name") : null,
      idCompany: (useHookForm.getValues("company") && useHookForm.getValues("company.idCompany") !== "all") ? useHookForm.getValues("company.idCompany") : null,
      idBusinessUnit: (useHookForm.getValues("businessUnit") && useHookForm.getValues("businessUnit.idBusinessUnit") !== "all") ? useHookForm.getValues("businessUnit.idBusinessUnit") : null,
      idDivision: (useHookForm.getValues("division") && useHookForm.getValues("division.idDivision") !== "all") ? useHookForm.getValues("division.idDivision") : null,
      idDepartment: (useHookForm.getValues("department") && useHookForm.getValues("department.idDepartment") !== "all") ? useHookForm.getValues("department.idDepartment") : null,
      idSection: (useHookForm.getValues("section") && useHookForm.getValues("section.idSection") !== "all") ? useHookForm.getValues("section.idSection") : null,
      idPosition: (useHookForm.getValues("position") && useHookForm.getValues("position.idPosition") !== "all") ? useHookForm.getValues("position.idPosition") : null,
      idManager: (useHookForm.getValues("manager") && useHookForm.getValues("manager.idEmployees") !== "all") ? useHookForm.getValues("manager.idEmployees") : null,
      rowsPerPage,
      page: newPage + 1,
      isTerminate: isTerminate,
    }

    setPage(newPage);

    dispatch(getAllDocumentsByIdFilter(searchData)).then(res => {
      setTotal(res && res.data ? res.data.total : 0)
    })
  };

  const handleChangeRowsPerPage = (event) => {
    const searchData = {
      name: useHookForm.getValues("name") ? useHookForm.getValues("name") : null,
      idCompany: (useHookForm.getValues("company") && useHookForm.getValues("company.idCompany") !== "all") ? useHookForm.getValues("company.idCompany") : null,
      idBusinessUnit: (useHookForm.getValues("businessUnit") && useHookForm.getValues("businessUnit.idBusinessUnit") !== "all") ? useHookForm.getValues("businessUnit.idBusinessUnit") : null,
      idDivision: (useHookForm.getValues("division") && useHookForm.getValues("division.idDivision") !== "all") ? useHookForm.getValues("division.idDivision") : null,
      idDepartment: (useHookForm.getValues("department") && useHookForm.getValues("department.idDepartment") !== "all") ? useHookForm.getValues("department.idDepartment") : null,
      idSection: (useHookForm.getValues("section") && useHookForm.getValues("section.idSection") !== "all") ? useHookForm.getValues("section.idSection") : null,
      idPosition: (useHookForm.getValues("position") && useHookForm.getValues("position.idPosition") !== "all") ? useHookForm.getValues("position.idPosition") : null,
      idManager: (useHookForm.getValues("manager") && useHookForm.getValues("manager.idEmployees") !== "all") ? useHookForm.getValues("manager.idEmployees") : null,
      rowsPerPage: event.target.value,
      page: 1,
      isTerminate: isTerminate,
    }
    setRowsPerPage(event.target.value);
    setPage(0);

    dispatch(getAllDocumentsByIdFilter(searchData)).then(res => {
      setTotal(res && res.data ? res.data.total : 0)
    })
  };

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      const foundCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (foundCompany) {
        useHookForm.resetField("company", { defaultValue: foundCompany })
      } else {
        useHookForm.resetField("company", { defaultValue: affiliateOrganizationList[0] })
      }
      renderAllDataSelect(foundCompany ? foundCompany.organization : affiliateOrganizationList[0].organization);
      const searchData = {
        idCompany: foundCompany ? foundCompany.idCompany : affiliateOrganizationList[0].idCompany,
        rowsPerPage,
        page: page + 1,
        isTerminate: isTerminate,
      }
      dispatch(getAllDocumentsByIdFilter(searchData)).then(res => {
        setTotal(res && res.data ? res.data.total : 0)
      })
    }
  }, [affiliateOrganizationList]);

  return (
    <div>
      <StyledRoot>
        <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: 1 }}>
          {/* <Typography
            className="label"
            color="text.third"
            style={{ marginBottom: 7, marginLeft: 3 }}
          >
            {t("Company")}
          </Typography>
          <SelectAffiliateCompany
            options={affiliateOrganizationList}
            value={selectedCompany}
            onChange={(_, value) => {
              onChangeCompany(value);
            }}
          /> */}
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                <Box>
                  <Typography variant="h4" align="right">
                    {total}
                  </Typography>
                  <Typography
                    variant="body2"
                    align="right"
                    color="text.secondary"
                    style={{ fontWeight: 500 }}
                  >
                    {t("All")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <Link
                  component="button"
                  type="button"
                  variant="body2"
                  onClick={() => { setMoreFilter(!moreFilter) }}
                >
                  {moreFilter ? `${t("HideSearch")}` : `${t("SearchMore")}`}
                </Link>
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Search")}
                  </Typography>
                  <Controller
                    name="name"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth variant="filled">
                        <StyledFilledInput
                          {...field}
                          placeholder={`${t("Search")}${t("FullName")}`}
                          name="search"
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    )}
                  />
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Company")}
                  </Typography>
                  <Controller
                    name="company"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <StyledAutocomplete
                        {...field}
                        options={[
                          { idCompany: "all", companyName: `${t("All")}`, companyName_EN: `${t("All")}` },
                          ...(affiliateOrganizationList ? affiliateOrganizationList : [])
                        ]}
                        getOptionLabel={(option) =>
                          i18n.resolvedLanguage === "th"
                            ? option.companyName
                            : option.companyName_EN
                        }
                        isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("Company")}
                            onBlur={field.onBlur}
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value)
                          useHookForm.setValue("name", "")
                          useHookForm.setValue("businessUnit", { idBusinessUnit: "all", businessUnitName: `${t("All")}` })
                          useHookForm.setValue("division", { idDivision: "all", divisionName: `${t("All")}` })
                          useHookForm.setValue("department", { idDepartment: "all", departmentName: `${t("All")}` })
                          useHookForm.setValue("section", { idSection: "all", sectionName: `${t("All")}` })
                          useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                          useHookForm.setValue("manager", { idEmployees: "all", firstname_TH: `${t("All")}`, lastname_TH: "" })

                          // setExcelDataCompany(value);
                          // fetchingEmployee();
                          if (value.idCompany !== "all") {
                            renderAllDataSelect(value.organization);
                          }
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>

              {moreFilter && <Fragment>
                <Grid item xs={12} md={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Business")}
                    </Typography>
                    <Controller
                      name="businessUnit"
                      control={useHookForm.control}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          {...field}
                          options={[
                            { idBusinessUnit: "all", businessUnitName: `${t("All")}`, businessUnitName_EN: `${t("All")}` },
                            ...businessUnitList
                          ]}
                          getOptionLabel={(option) => `${option.businessUnitName}`}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.idBusinessUnit}>
                              <Box>
                                <Typography>
                                  {i18n.resolvedLanguage === "th"
                                    ? option.businessUnitName
                                    : option.businessUnitName_EN}
                                </Typography>
                              </Box>
                            </MenuItem>
                          )}
                          isOptionEqualToValue={(option, value) => option.idBusinessUnit === value.idBusinessUnit}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              variant="filled"
                              placeholder={t("BusinessUnit")}
                              onBlur={field.onBlur}
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error ? fieldState.error.message : null}
                            />
                          )}
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value)
                            useHookForm.setValue("division", { idDivision: "all", divisionName: `${t("All")}` })
                            useHookForm.setValue("department", { idDepartment: "all", departmentName: `${t("All")}` })
                            useHookForm.setValue("section", { idSection: "all", sectionName: `${t("All")}` })
                            useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                          }}
                          noOptionsText={t("NoData")}
                          disableClearable
                        />
                      )}
                    />
                  </StyledBoxSearch>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Division")}
                    </Typography>
                    <Controller
                      name="division"
                      control={useHookForm.control}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          {...field}
                          options={[
                            { idDivision: "all", divisionName: `${t("All")}`, divisionName_EN: `${t("All")}` },
                            ...(divisionList
                              ? divisionList.filter((x) => {
                                return (
                                  x
                                  && (useHookForm.getValues("businessUnit").idBusinessUnit === "all" || useHookForm.getValues("businessUnit").idBusinessUnit === x.idBusinessUnit)
                                )
                              })
                              : []),
                          ]}
                          getOptionLabel={(option) => `${option.divisionName}`}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.idDivision}>
                              <Box>
                                <Typography>
                                  {i18n.resolvedLanguage === "th"
                                    ? option.divisionName
                                    : option.divisionName_EN}
                                </Typography>
                              </Box>
                            </MenuItem>
                          )}
                          isOptionEqualToValue={(option, value) => option.idDivision === value.idDivision}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              variant="filled"
                              placeholder={t("Division")}
                              onBlur={field.onBlur}
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error ? fieldState.error.message : null}
                            />
                          )}
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value)
                            if (value.idDivision !== "all") {
                              useHookForm.setValue(
                                "businessUnit",
                                businessUnitList && businessUnitList.length > 0
                                  ? businessUnitList.find(
                                    (x) => x.idBusinessUnit === value.idBusinessUnit
                                  ) || { idBusinessUnit: "all", businessUnitName: `${t("All")}` }
                                  : { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
                              )
                            }
                            useHookForm.setValue("department", { idDepartment: "all", departmentName: `${t("All")}` })
                            useHookForm.setValue("section", { idSection: "all", sectionName: `${t("All")}` })
                            useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                          }}
                          noOptionsText={t("NoData")}
                          disableClearable
                        />
                      )}
                    />
                  </StyledBoxSearch>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Department")}
                    </Typography>
                    <Controller
                      name="department"
                      control={useHookForm.control}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          {...field}
                          options={[
                            { idDepartment: "all", departmentName: `${t("All")}`, departmentName_EN: `${t("All")}` },
                            ...(departmentList
                              ? departmentList.filter((x) => {
                                return (
                                  x
                                  && (useHookForm.getValues("businessUnit").idBusinessUnit === "all" || useHookForm.getValues("businessUnit").idBusinessUnit === x.idBusinessUnit)
                                  && (useHookForm.getValues("division").idDivision === "all" || useHookForm.getValues("division").idDivision === x.idDivision)
                                )
                              })
                              : []),
                          ]}
                          getOptionLabel={(option) => `${option.departmentName}`}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.idDepartment}>
                              <Box>
                                <Typography>
                                  {i18n.resolvedLanguage === "th"
                                    ? option.departmentName
                                    : option.departmentName_EN}
                                </Typography>
                              </Box>
                            </MenuItem>
                          )}
                          isOptionEqualToValue={(option, value) => option.idDepartment === value.idDepartment}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              variant="filled"
                              placeholder={t("Department")}
                              onBlur={field.onBlur}
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error ? fieldState.error.message : null}
                            />
                          )}
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value)
                            if (value.idDepartment !== "all") {
                              useHookForm.setValue(
                                "businessUnit",
                                businessUnitList && businessUnitList.length > 0
                                  ? businessUnitList.find(
                                    (x) => x.idBusinessUnit === value.idBusinessUnit
                                  ) || { idBusinessUnit: "all", businessUnitName: `${t("All")}` }
                                  : { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
                              )
                              useHookForm.setValue(
                                "division",
                                divisionList && divisionList.length > 0
                                  ? divisionList.find(
                                    (x) => x.idDivision === value.idDivision
                                  ) || { idDivision: "all", divisionName: `${t("All")}` }
                                  : { idDivision: "all", divisionName: `${t("All")}` },
                              )
                            }
                            useHookForm.setValue("section", { idSection: "all", sectionName: `${t("All")}` })
                            useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                          }}
                          noOptionsText={t("NoData")}
                          disableClearable
                        />
                      )}
                    />
                  </StyledBoxSearch>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Section")}
                    </Typography>
                    <Controller
                      name="section"
                      control={useHookForm.control}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          {...field}
                          options={[
                            { idSection: "all", sectionName: `${t("All")}`, sectionName_EN: `${t("All")}` },
                            ...(sectionList
                              ? sectionList.filter((x) => {
                                return (
                                  x
                                  && (useHookForm.getValues("businessUnit").idBusinessUnit === "all" || useHookForm.getValues("businessUnit").idBusinessUnit === x.idBusinessUnit)
                                  && (useHookForm.getValues("division").idDivision === "all" || useHookForm.getValues("division").idDivision === x.idDivision)
                                  && (useHookForm.getValues("department").idDepartment === "all" || useHookForm.getValues("department").idDepartment === x.idDepartment)
                                )
                              })
                              : []),
                          ]}
                          getOptionLabel={(option) => `${option.sectionName}`}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.idSection}>
                              <Box>
                                <Typography>
                                  {i18n.resolvedLanguage === "th"
                                    ? option.sectionName
                                    : option.sectionName_EN}
                                </Typography>
                              </Box>
                            </MenuItem>
                          )}
                          isOptionEqualToValue={(option, value) => option.idSection === value.idSection}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              variant="filled"
                              placeholder={t("Section")}
                              onBlur={field.onBlur}
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error ? fieldState.error.message : null}
                            />
                          )}
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value)
                            if (value.idSection !== "all") {
                              useHookForm.setValue(
                                "businessUnit",
                                businessUnitList && businessUnitList.length > 0
                                  ? businessUnitList.find(
                                    (x) => x.idBusinessUnit === value.idBusinessUnit
                                  ) || { idBusinessUnit: "all", businessUnitName: `${t("All")}` }
                                  : { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
                              )
                              useHookForm.setValue(
                                "division",
                                divisionList && divisionList.length > 0
                                  ? divisionList.find(
                                    (x) => x.idDivision === value.idDivision
                                  ) || { idDivision: "all", divisionName: `${t("All")}` }
                                  : { idDivision: "all", divisionName: `${t("All")}` },
                              )
                              useHookForm.setValue(
                                "department",
                                departmentList && departmentList.length > 0
                                  ? departmentList.find(
                                    (x) => x.idDepartment === value.idDepartment
                                  ) || { idDepartment: "all", departmentName: `${t("All")}` }
                                  : { idDepartment: "all", departmentName: `${t("All")}` },
                              )
                            }
                            useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                          }}
                          noOptionsText={t("NoData")}
                          disableClearable
                        />
                      )}
                    />
                  </StyledBoxSearch>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Position")}
                    </Typography>
                    <Controller
                      name="position"
                      control={useHookForm.control}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          {...field}
                          options={[
                            { idPosition: "all", positionName: `${t("All")}`, positionName_EN: `${t("All")}` },
                            ...(positionList
                              ? positionList.filter((x) => {
                                return (
                                  x
                                  && (useHookForm.getValues("businessUnit").idBusinessUnit === "all" || useHookForm.getValues("businessUnit").idBusinessUnit === x.idBusinessUnit)
                                  && (useHookForm.getValues("division").idDivision === "all" || useHookForm.getValues("division").idDivision === x.idDivision)
                                  && (useHookForm.getValues("department").idDepartment === "all" || useHookForm.getValues("department").idDepartment === x.idDepartment)
                                  && (useHookForm.getValues("section").idSection === "all" || useHookForm.getValues("section").idSection === x.idSection)
                                )
                              })
                              : []),
                          ]}
                          getOptionLabel={(option) => `${option.positionName}`}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.idPosition}>
                              <Box>
                                <Typography>
                                  {i18n.resolvedLanguage === "th"
                                    ? option.positionName
                                    : option.positionName_EN}
                                </Typography>
                              </Box>
                            </MenuItem>
                          )}
                          isOptionEqualToValue={(option, value) => option.idPosition === value.idPosition}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              variant="filled"
                              placeholder={t("Position")}
                              onBlur={field.onBlur}
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error ? fieldState.error.message : null}
                            />
                          )}
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value)
                            if (value.idPosition !== "all") {
                              useHookForm.setValue(
                                "businessUnit",
                                businessUnitList && businessUnitList.length > 0
                                  ? businessUnitList.find(
                                    (x) => x.idBusinessUnit === value.idBusinessUnit
                                  ) || { idBusinessUnit: "all", businessUnitName: `${t("All")}` }
                                  : { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
                              )
                              useHookForm.setValue(
                                "division",
                                divisionList && divisionList.length > 0
                                  ? divisionList.find(
                                    (x) => x.idDivision === value.idDivision
                                  ) || { idDivision: "all", divisionName: `${t("All")}` }
                                  : { idDivision: "all", divisionName: `${t("All")}` },
                              )
                              useHookForm.setValue(
                                "department",
                                departmentList && departmentList.length > 0
                                  ? departmentList.find(
                                    (x) => x.idDepartment === value.idDepartment
                                  ) || { idDepartment: "all", departmentName: `${t("All")}` }
                                  : { idDepartment: "all", departmentName: `${t("All")}` },
                              )
                              useHookForm.setValue(
                                "section",
                                sectionList && sectionList.length > 0
                                  ? sectionList.find(
                                    (x) => x.idSection === value.idSection
                                  ) || { idSection: "all", sectionName: `${t("All")}` }
                                  : { idSection: "all", sectionName: `${t("All")}` },
                              )
                            }
                          }}
                          noOptionsText={t("NoData")}
                          disableClearable
                        />
                      )}
                    />
                  </StyledBoxSearch>
                </Grid>
              </Fragment>}

              <Grid item xs={12} container justifyContent="space-between">
                <Grid>
                  <Grid
                    container
                    pl={2}
                    spacing={2}
                    justifyContent="flex-start"
                  >
                    <StyledBoxSearch>
                      <div className="wrap-search-action" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonBlue
                          variant="contained"
                          startIcon={<FileUploadOutlinedIcon />}
                          onClick={() => {
                            history.push(`/employees-documents/add/${selectedCompany.idCompany}`);
                          }}
                          style={{ marginRight: 10 }}
                        >
                          {t("UploadDocuments")}
                        </ButtonBlue>
                        <ButtonBlue
                          variant="contained"
                          startIcon={<FileUploadOutlinedIcon />}
                          onClick={() => {
                            if (selectedCompany) {
                              history.push(`/employees-documents/edit/${selectedCompany.idCompany}`);
                            }
                          }}
                        >
                          {t("EditDocumentType")}
                        </ButtonBlue>
                      </div>
                    </StyledBoxSearch>
                  </Grid>
                </Grid>
                <Grid >
                  <Grid
                    container
                    gap={2}
                  >
                    <ButtonBlue variant="outlined" type="button" onClick={handleClear}>{t("Clear")}</ButtonBlue>
                    <ButtonBlue variant="contained" type="submit">{t("Search")}</ButtonBlue>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {docName ? (
          <CardStyle>
            <Box style={{ padding: "24px", userSelect: 'none' }}>
              <Box style={{ display: "flex", position: "relative" }}>
                <StyledBox className={"close"}>
                  <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledCellHeader
                            align={"center"}
                            className="sticky"
                            style={{ minWidth: 240 }}
                          >
                            {t("FullName")}
                          </StyledCellHeader>
                          {docName.map((d, index) => {
                            return (
                              <StyledCellHeader key={index}>
                                <Typography variant="body2" align="center">
                                  {`${t(d.documentTypeName)}`}
                                </Typography>
                              </StyledCellHeader>
                            );
                          })}
                          <StyledCellHeader>
                            <Typography variant="body2" align="center">
                              {t("More")}
                            </Typography>
                          </StyledCellHeader>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employeesList && employeesList.data ?
                          employeesList.data.map((row, index) => (
                            <React.Fragment key={index}>
                              <StyledRowContent>
                                <StyledCellContent
                                  key={`${index}`}
                                  className="sticky"
                                  style={{
                                    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  <div className="avatar">
                                    <Avatar src={`${row.imageProfile}`} />
                                    <div>
                                      <Typography className="fullname">
                                        {getFullNameLang(row, i18n.resolvedLanguage)}
                                      </Typography>
                                    </div>
                                  </div>
                                </StyledCellContent>
                                {docName.map((d, idx) => (
                                  <StyledCellContent align="center" key={idx}>
                                    {row.file.find((r) => r.idDocumentType === d.idDocumentType) ? (
                                      <CheckCircleOutlineIcon
                                        style={{ color: "green" }}
                                        onClick={() =>
                                          handleDialog("edit", row, d)
                                        }
                                      />
                                    ) : (
                                      <CancelOutlinedIcon
                                        style={{ color: "red" }}
                                        onClick={() => handleDialog("import", row, d)}
                                      />
                                    )}
                                  </StyledCellContent>
                                ))}
                                <StyledCellContent align="center">
                                  <ExpandCircleDownOutlinedIcon
                                    onClick={() => {
                                      handleDialog("more", row, docNameActivate);
                                      handleToggle("more", index, docNameActivate);
                                    }}
                                  />
                                </StyledCellContent>
                              </StyledRowContent>
                              {doc && openRow === index && typeDialog === "more" && (
                                <StyledRowContent>
                                  <StyledCellContent colSpan={docName.length + 4}>
                                    <More
                                      doc={doc}
                                      data={formData}
                                      handleDialog={handleDialog}
                                      handleToggle={handleToggle}
                                    />
                                  </StyledCellContent>
                                </StyledRowContent>
                              )}
                            </React.Fragment>
                          )) : `${t("NoData")}`}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={total}
                    // count={(employeeSearch && employeeSearch.pagination)? employeeSearch.pagination.total: 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={`${t("RowsPerPage")}`}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`
                      }`
                    }
                  />
                </StyledBox>
              </Box>
            </Box>
          </CardStyle>
        ) : (
          ""
        )}
      </StyledRoot>
      {typeDialog === "import" ? (
        <DialogImport
          open={open}
          setReload={setReload}
          mode={typeDialog}
          empData={formData}
          doc={doc}
          setOpen={setOpen}
          setTypeDialog={setTypeDialog}
          handleClose={handleClose}
          type={typeDialog}
          idCompany={selectedCompany.idCompany}
        />
      ) : (
        ""
      )}
      {typeDialog === "edit" ? (
        <DialogImport
          open={open}
          setReload={setReload}
          mode={typeDialog}
          empData={formData}
          doc={doc}
          setOpen={setOpen}
          setTypeDialog={setTypeDialog}
          handleClose={handleClose}
          type={typeDialog}
          idCompany={selectedCompany.idCompany}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default documentPage;