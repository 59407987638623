import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  Pagination,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import th from "date-fns/locale/th";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { useDropzone } from "react-dropzone";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyled from "../../shared/general/Card";

import CardHistoryLeave from "./CardHistoryLeave";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";

import {
  getLeaveAvaiable,
  getLeaveSetting,
  withdrawLeaveEmployee,
} from "../../../../actions/leave";
import {
  getLeaveQuota,
  getLeaveRequestAllYear,
} from "../../../../actions/employee";

import { getAllHolidayByIdEmployeesAndYear } from "../../../../actions/holiday";

import DialogNewLeave from "./DialogNewLeave";
import utils from "../../../../utils";
import { getManager } from "../../../../actions/manager";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import QuotaProgress from "./QuotaProgress";
import leaveService from "../../../../services/leave.service";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 24,
  },
  "& .quota": {
    padding: 24,
  },
});

const StyledCardDark = styled("div")({
  padding: 8,
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardTitle": {
    fontSize: 22,
  },
});

const StyledCalendar = styled(Calendar)({
  height: "350px !important",
  "& .rbc-date-cell": {
    paddingRight: 0,
    textAlign: "center",
  },
  "& .rbc-month-row": {
    justifyContent: "center",
  },
  "& .rbc-event-content": {
    display: "none !important",
  },
  "& .rbc-row-segment": {
    //]]display: "none !important",
  },
  "& .rbc-today": {
    borderRadius: 4,
  },
});

const StyledToolbarCalendar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .todayBtn": {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
  },
  "& .prevNextBtn": {
    marginRight: 4,
    marginLeft: 4,
  },
  "& .toolbarCalendarLabel": {
    fontSize: 24,
  },
});

const StyledWrapHistory = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: 8,
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
  ["@media only screen and (min-width: 600px)"]: {
    display: "none",
  },
});

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 350,
});

const StyledWrapQuota = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .right": {
    marginLeft: 16,
    "& .leave-name": {
      lineHeight: 1.2,
    },
  },
});

const locales = {
  "th-TH": th,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CARD_PER_PAGE = 12;

function Leavelist() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const today = dayjs().toDate();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: holiday } = useSelector((state) => state.holiday);
  const { result: leaveSetting } = useSelector((state) => state.leaveSetting);
  const { result: leaveAvailable } = useSelector(
    (state) => state.leaveAvailable
  );
  const [fileSelected, setFileSelected] = useState([]);
  const [activeDate, setActiveDate] = useState({
    date: today,
    direction: null,
  });
  const [requestLeave, setRequestLeave] = React.useState({
    idLeaveType: 0,
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    endDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    description: "",
    leaveDayType: "fullday",
    startDatehour: 0,
    startDateminute: 0,
    endDatehour: 0,
    endDateminute: 0,
  });
  const [dataWithDraw, setDataWithDraw] = useState(null);
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedLeaveRoundDate, setSelecetedLeaveroundDate] = useState("");
  const [selectMenuLeaveRound, setSelectMenuLeaveRound] = useState([]);
  const [leaveRoundConfig, setLeaveRoundConfig] = useState({
    date: "",
    start: "",
    end: "",
  });
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    data: {
      selectedLeaveRoundDate: "",
    },
  });

  const onDrawerClose = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const onDrawerOpen = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      data: {
        selectedLeaveRoundDate: selectedLeaveRoundDate,
      },
    }));
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    let newStyle = {
      color: "black",
      borderRadius: 4,
      border: "none",
    };
    if (event.resource) {
      return {
        className: event.resource.typeEvent + "Event",
        style: newStyle,
      };
    } else {
      return {
        className: "",
        style: newStyle,
      };
    }
  };

  const CustomToolbar = (toolbar) => {
    let min = (userProfile && userProfile.hiringDate) ?
      dayjs(userProfile.hiringDate).isBefore("2023-01-01", "day") ?
        dayjs("2023-01-01") : dayjs(userProfile.hiringDate)
      : dayjs();
    
    let max = (userProfile && userProfile.resignDate) ?
      dayjs(userProfile.resignDate).isBefore("2023-01-01", "day") ?
        dayjs("2023-01-01") : dayjs(userProfile.resignDate)
      : dayjs().add(1, "year");

    const goToBack = async () => {
      toolbar.onNavigate("PREV");
      setActiveDate({
        date: toolbar.date,
        direction: "PREV",
      });
    };

    const goToNext = async () => {
      toolbar.onNavigate("NEXT");
      setActiveDate({
        date: toolbar.date,
        direction: "NEXT",
      });
    };

    const goToCurrent = async () => {
      toolbar.onNavigate("TODAY");
      setActiveDate({
        date: toolbar.date,
        direction: "TODAY",
      });
    };

    const label = () => {
      const date = dayjs(toolbar.date);
      return (
        <span style={{ fontSize: 18, margin: 8 }}>
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </span>
      );
    };

    return (
      <StyledToolbarCalendar>
        <label className={"toolbarCalendarLabel"}>{label()}</label>
        <div>
          <IconButton
            aria-label="prev"
            size="small"
            className={`prevNextBtn`}
            onClick={goToBack}
            disabled={parseInt(dayjs(toolbar.date).subtract(1, "month").year()) < min.year()}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <ButtonBlue
            size={"small"}
            variant={"contained"}
            onClick={goToCurrent}
          >
            {`${t("Today")}`}
          </ButtonBlue>
          <IconButton
            aria-label="next"
            size="small"
            className={`prevNextBtn`}
            onClick={goToNext}
            disabled={parseInt(dayjs(toolbar.date).add(1, "month").year()) > max.year()}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </div>
      </StyledToolbarCalendar>
    );
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      fileSelected.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [fileSelected]
  );

  useEffect(() => {
    if (userProfile) {
      dispatch(getManager());
      dispatch(getLeaveSetting());
      fetchLeaveRound();
    }
  }, [userProfile]);

  useEffect(() => {
    dispatch(getAllHolidayByIdEmployeesAndYear(currentYear));
  },[currentYear]);

  const handleCloseDialogConfirmLeave = () => {
    setOpenNewDialog(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const RenderHolidayList = () => {
    var dateCurrent = null;
    if (activeDate.direction === "NEXT") {
      dateCurrent = dayjs(activeDate.date).add(1, "month").format("YYYY-MM-DD");
    } else if (activeDate.direction === "PREV") {
      dateCurrent = dayjs(activeDate.date)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
    } else {
      dateCurrent = dayjs(today).format("YYYY-MM-DD");
    }

    if (holiday) {
      var holidayMonth = holiday.filter(
        (value) =>
          dayjs(value.dateHoliday).get("month") ===
            dayjs(dateCurrent).get("month") &&
          dayjs(value.dateHoliday).get("year") ===
            dayjs(dateCurrent).get("year")
      );
      if (holidayMonth.length > 0) {
        return holidayMonth.map((value) => (
          <div style={{ marginBottom: 8 }}>
            <Typography variant="h6" style={{ fontSize: 18 }}>
              <CircleIcon
                style={{
                  fontSize: 12,
                  marginRight: 8,
                  color: "#fc4b6c",
                }}
              />
              {i18n.resolvedLanguage === "th"
                ? value.name
                : value.name_EN
                ? value.name_EN
                : ""}
            </Typography>
            <Typography
              color="text.secondary"
              style={{
                paddingLeft: 12,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {dayjs(value.dateHoliday).format("DD MMM YYYY")}
            </Typography>
          </div>
        ));
      } else {
        return (
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              {`${t("NoHolidays")}`}
            </Typography>
          </StyledEmpty>
        );
      }
    }
  };

  const handleDeleteLeave = async () => {
    const formData = {
      idLeave: dataWithDraw.idLeave,
    };

    const result = await dispatch(withdrawLeaveEmployee(null, formData));
    if (result && result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      setOpenConfirmDialog(false);
      dispatch(
        getLeaveRequestAllYear({
          filter: dayjs(selectedLeaveRoundDate).format("YYYY"),
        })
      );
      dispatch(
        getLeaveAvaiable({
          date: dayjs(selectedLeaveRoundDate).format("YYYY-MM-DD"),
        })
      );
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
      setOpenConfirmDialog(false);
    }
  };

  const handleChangeLeaveRound = (date) => {
    setSelecetedLeaveroundDate(date);
    dispatch(getLeaveAvaiable({ date: date }));
  };

  const fetchLeaveRound = () => {
    leaveService
      .getLeaveRoundList()
      .then((res) => {
        const today = dayjs();
        const foundLeaveRound = res.data.find((item) =>
          dayjs(today).isBetween(dayjs(item.start), dayjs(item.end), "[]")
        );
        setSelectMenuLeaveRound(res.data);
        setSelecetedLeaveroundDate(foundLeaveRound.date);
        setLeaveRoundConfig((prev) => ({
          ...prev,
          ...foundLeaveRound,
        }));
        // dispatch(getLeaveAvaiable({date: foundLeaveRound.date}))
      })
      .catch((error) => {
        setSelectMenuLeaveRound([]);
        setSelecetedLeaveroundDate("");
        setLeaveRoundConfig((prev) => ({
          ...prev,
          date: "",
          start: "",
          end: "",
        }));
      });
  };

  useEffect(() => {
    if (selectedLeaveRoundDate) {
      dispatch(
        getLeaveRequestAllYear({
          filter: dayjs(selectedLeaveRoundDate).format("YYYY"),
        })
      );
      dispatch(
        getLeaveAvaiable({
          date: dayjs(selectedLeaveRoundDate).format("YYYY-MM-DD"),
        })
      );
      setLeaveRoundConfig((prev) => ({
        ...prev,
        ...selectMenuLeaveRound.find(
          (item) => item.date === selectedLeaveRoundDate
        ),
      }));
    }
  }, [selectedLeaveRoundDate]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8, marginBottom: 16 }}>
          {`${t("Leave")}`}
        </Typography>
        <Box
          marginBottom="16px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          sx={{
            "@media only screen and (max-width: 600px)": {
              flexDirection: "column",
              gap: "16px",
              alignItems: "flex-end",
            },
          }}
        >
          <Box
            maxWidth="min-content"
            sx={{
              "@media only screen and (max-width: 600px)": {
                maxWidth: "100%",
                width: "100%",
              },
            }}
          >
            {leaveSetting && (
              <TextFieldTheme
                select
                label={t("SelectYear")}
                value={selectedLeaveRoundDate}
                onChange={(event) => {
                  handleChangeLeaveRound(event.target.value);
                }}
              >
                {selectMenuLeaveRound.map((item) => (
                  <MenuItem value={item.date}>
                    {i18n.language === "th"
                      ? `${dayjs(item.start).format("D MMMM BBBB")} - ${dayjs(
                          item.end
                        ).format("D MMMM BBBB")}`
                      : `${dayjs(item.start)
                          .locale(i18n.language)
                          .format("D MMMM YYYY")} - ${dayjs(item.end)
                          .locale(i18n.language)
                          .format("D MMMM YYYY")}`}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          </Box>
          {leaveRoundConfig.end && dayjs().isSameOrBefore(dayjs(leaveRoundConfig.end).add(2, 'month'), 'date') && <ButtonBlue
            startIcon={<AddRoundedIcon />}
            variant="contained"
            onClick={onDrawerOpen}
          >
            {`${t("LeaveRequest")}`}
          </ButtonBlue>}
        </Box>

        <CardStyled style={{ marginBottom: 16 }}>
          <div className="quota">
            <Typography
              variant="h4"
              style={{ marginBottom: 16 }}
              className={`cardTitle`}
              gutterBottom
            >
              {`${t("AllLeaveRights")}`}
            </Typography>
            <Grid container spacing={2}>
              {leaveAvailable.map((item, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <QuotaProgress leaveGroup={item} />
                </Grid>
              ))}
            </Grid>
          </div>
        </CardStyled>
        <CardStyled>
          <CardContent>
            <Typography
              variant="h4"
              style={{ marginBottom: 16 }}
              className={`cardTitle`}
              gutterBottom
            >
              {`${t("WorkLeaveCalendar")}`}
            </Typography>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6}>
                <div>
                  <StyledCalendar
                    className="calendar-leave"
                    localizer={localizer}
                    events={holiday || []}
                    views={["month"]}
                    startAccessor="start"
                    endAccessor="end"
                    popup={true}
                    defaultDate={new Date()}
                    onSelectEvent={(selected) => {
                      console.log(selected);
                    }}
                    eventPropGetter={eventPropGetter}
                    components={{
                      toolbar: CustomToolbar,
                    }}
                    formats={{
                      dateFormat: "d",
                      dayHeaderFormat: "d MMMM yyyy",
                      weekdayFormat: "E",
                    }}
                    onNavigate={(newDate) => {
                      let year = dayjs(newDate).year();
                      if(currentYear !== dayjs(newDate).year()){
                        setCurrentYear(year);
                      }
                    }}
                  />
                </div>
              </Grid>
              <StyledDivider />
              <Grid item xs={12} sm={6}>
                <div>{RenderHolidayList()}</div>
              </Grid>
            </Grid>
          </CardContent>
        </CardStyled>
        <StyledCardDark style={{ marginTop: 16, minHeight: 200 }}>
          <Typography variant="h4" className={`cardTitle`} gutterBottom>
            {`${t("LeaveRecord")}`}
          </Typography>
          {leaveEmployeesList && leaveEmployeesList.length > 0 ? (
            <>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Pagination
                  count={Math.ceil(leaveEmployeesList.length / CARD_PER_PAGE)}
                  page={page}
                  onChange={handleChangePage}
                  color="primary"
                />
              </Box>
              <Grid container spacing={1} marginTop={1} marginBottom={2}>
                {leaveEmployeesList
                  .slice((page - 1) * CARD_PER_PAGE, page * CARD_PER_PAGE)
                  .map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.idLeave}>
                      <CardHistoryLeave
                        handleDeleteLeave={handleDeleteLeave}
                        data={item}
                        setDataWithDraw={setDataWithDraw}
                        openConfirmDialog={openConfirmDialog}
                        setOpenConfirmDialog={setOpenConfirmDialog}
                      />
                    </Grid>
                  ))}
              </Grid>
            </>
          ) : (
            <Typography color="text.secondary">{`${t(
              "NoLeaveRecord"
            )}`}</Typography>
          )}
        </StyledCardDark>
      </Container>

      <DialogNewLeave
        drawerConfig={drawerConfig}
        onClose={onDrawerClose}
        leaveRoundConfig={leaveRoundConfig}
      />

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default Leavelist;
