import React, { Fragment, useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../../actions/family";
import dayjs from "dayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import {
  addEducation,
  updateEducation,
} from "../../../../../../actions/education";
import {
  addExperience,
  updateExperience,
} from "../../../../../../actions/experience";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../../shared/date/datePicker";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const DialogExperience = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    startDate: yup.date().nullable().required(t("PleaseEnterRequiredInformationCompletely")),
    endDate: yup
      .date()
      .nullable()
      .when("isCurrentlyWork", {
        is: false,
        then: yup.date().nullable().required(t("PleaseEnterRequiredInformationCompletely")),
      }),
    positionName: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
    companyName: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
    description: yup
      .string()
      .test(
        "length",
        "ห้ามเกิน 1000 ตัวอักษร",
        (value) => value.length <= 1000
      ),
  });

  const useHookForm = useForm({
    defaultValues: {
      isCurrentlyWork: false,
      startDate: null,
      endDate: null,
      positionName: "",
      companyName: "",
      description: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const isCurrentlyWorkWatch = useHookForm.watch("isCurrentlyWork");

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      if (drawerConfig.isEdit) {
        useHookForm.reset({
          isCurrentlyWork: drawerConfig.data.endDate ? false : true,
          startDate: drawerConfig.data.startDate
            ? new Date(drawerConfig.data.startDate)
            : null,
          endDate: drawerConfig.data.endDate
            ? new Date(drawerConfig.data.endDate)
            : null,
          positionName: drawerConfig.data.positionName
            ? drawerConfig.data.positionName
            : "",
          companyName: drawerConfig.data.companyName
            ? drawerConfig.data.companyName
            : "",
          description: drawerConfig.data.description
            ? drawerConfig.data.description
            : "",
        });
      } else {
        useHookForm.reset({
          isCurrentlyWork: false,
          startDate: null,
          endDate: null,
          positionName: "",
          companyName: "",
          description: "",
        });
      }
    }
  }, [drawerConfig.isOpen]);

  const onSubmit = async (data) => {
    let result = null;

    const { isCurrentlyWork, ...formData } = data;

    formData.startDate = formData.startDate
      ? dayjs(formData.startDate).format("YYYY-MM-01")
      : null;
    formData.endDate = formData.endDate
      ? dayjs(formData.endDate).format("YYYY-MM-01")
      : null;
    formData.positionName = formData.positionName
      ? formData.positionName
      : null;
    formData.companyName = formData.companyName ? formData.companyName : null;
    formData.description = formData.description ? formData.description : null;

    if (isCurrentlyWork) {
      formData.endDate = null;
    }

    if (drawerConfig.isEdit) {
      result = await dispatch(
        updateExperience(drawerConfig.data.idExperiences, formData)
      );
    } else {
      formData.idEmployees = employeeProfile.idEmployees;
      result = await dispatch(addExperience(formData));
    }

    // const result = {status: 200}

    // console.log(result)

    if (result) {
      if (result.status === 200) {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
        onClose();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: t("ErrorOccurredContactAdmin")
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: t("ErrorOccurredContactAdmin")
      // }))
    }
  };

  return (
    <DrawerCustom
      title={
        drawerConfig.isEdit
          ? `${t("EditWorkExperience")}`
          : `${t("AddWorkExperience")}`
      }
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="isCurrentlyWork"
                control={useHookForm.control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          useHookForm.resetField("endDate", {
                            defaultValue: null,
                          });
                          field.onChange(e.target.checked);
                        }}
                      />
                    }
                    label={t("currentWorkplace")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="field-label">{t("Start")}</Typography>
              <Controller
                name="startDate"
                control={useHookForm.control}
                render={({ field }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month"]}
                    inputFormat="MMMM YYYY"
                    disableFuture
                    disableMaskedInput
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("monthYearBE"),
                        }}
                        onBlur={field.onBlur}
                        error={
                          useHookForm.formState.errors[field.name]
                            ? true
                            : false
                        }
                        helperText={
                          useHookForm.formState.errors[field.name]
                            ? useHookForm.formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="field-label">{t("End")}</Typography>
              <Controller
                name="endDate"
                control={useHookForm.control}
                render={({ field }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month"]}
                    inputFormat="MMMM YYYY"
                    disableFuture
                    disableMaskedInput
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("monthYearBE"),
                        }}
                        onBlur={field.onBlur}
                        error={
                          useHookForm.formState.errors[field.name]
                            ? true
                            : false
                        }
                        helperText={
                          useHookForm.formState.errors[field.name]
                            ? useHookForm.formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                    disabled={isCurrentlyWorkWatch}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("PositionName")}
              </Typography>
              <Controller
                name="positionName"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("CompanyName")}
              </Typography>
              <Controller
                name="companyName"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("Descriptions")}
              </Typography>
              <Controller
                name="description"
                control={useHookForm.control}
                render={({ field }) => (
                  <Fragment>
                    <TextFieldTheme
                      {...field}
                      multiline
                      rows={5}
                      helperText={
                        useHookForm.formState.errors[field.name]
                          ? useHookForm.formState.errors[field.name].message
                          : null
                      }
                      error={
                        useHookForm.formState.errors[field.name] ? true : false
                      }
                    />
                    <Box paddingTop="4px">
                      <Typography
                        color="text.secondary"
                        fontSize="12px"
                        textAlign="right"
                        lineHeight="1"
                      >
                        {field.value.length}/1000
                      </Typography>
                    </Box>
                  </Fragment>
                )}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogExperience;
