import {
  REPORT_LEAVE_ABSENT_LATE_PAYROLL_FETCHING,
  REPORT_LEAVE_ABSENT_LATE_PAYROLL_SUCCESS,
  REPORT_LEAVE_ABSENT_LATE_PAYROLL_FAILED,
} from "../actions/types";

const initialState = {
  result: [],
  isFetching: true,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REPORT_LEAVE_ABSENT_LATE_PAYROLL_FETCHING:
      return { ...state, result: [], isFetching: true, isError: false };
    case REPORT_LEAVE_ABSENT_LATE_PAYROLL_FAILED:
      return { ...state, result: [], isFetching: false, isError: true };
    case REPORT_LEAVE_ABSENT_LATE_PAYROLL_SUCCESS:
      return { ...state, result: payload? payload: [], isFetching: false, isError: false };
    default:
      return state;
  }
}
