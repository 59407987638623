import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Button,
  IconButton,
  Container,
  Typography,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  TablePagination,
  TextField,
  Grid,
  Popper,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
} from "@mui/material";
import Dropzone, { useDropzone } from "react-dropzone";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import GalleryIcon from "../../../../assets/gallery.png";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";

import ActionButtons from "../action-buttons";
import RowItemExpense from "./row-mileage";
import RowItemGasExpense from "./row-mileage-gas";
import SelectApprover from "../../select-approver";
import utils from "../../../../../../utils";

import { addExpenseMileage } from "../../../../../../actions/expense";
import SelectCC from "../../select-cc";
import { getExpenseSettingMileage } from "../../../../../../actions/expenseSetting";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const rowHeaderGas = [
  {
    caption: "",
    className: "delete",
  },
  {
    caption: "DocumentDate",
    className: "date-start-end",
  },
  {
    caption: "สถานที่เติม",
    className: "description",
  },
  {
    caption: "จำนวนเงิน (รวม VAT) ",
    className: "amount",
  },
  {
    caption: "",
    className: "delete",
  },
];

const Mileage = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const today = dayjs().toDate();
  const { typeExpense, setting } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employees } = useSelector((state) => state.employees);
  const { result: expenseSettingMileage } = useSelector(
    (state) => state.expenseSettingMileage
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listMenuTaxOrWithholding, setListMenuTaxOrWithholding] =
    React.useState(null);
  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const periodRegex = /^([+]?[0-9]\d*)$/;
  const [fileSelected, setFileSelected] = useState([]);
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });

  const rowHeader = [
    {
      caption: "",
      className: "delete",
    },
    {
      caption: t("Date"),
      className: "date-start-end",
    },
    {
      caption: t("StartingPoint"),
      className: "description",
    },
    {
      caption: t("Destination"),
      className: "description",
    },
    {
      caption: t("StartMileage"),
      className: "amount",
    },
    {
      caption: t("EndMileage"),
      className: "amount",
    },
    {
      caption: `${t("TotalDistance")} (${t("Unit.km")})`,
      className: "amount",
    },
    {
      caption: `${t("PersonalUse")} (${t("Unit.km")})`,
      className: "amount",
    },
    {
      caption: `${t("NetDistance")} (${t("Unit.km")})`,
      className: "amount",
    },
    {
      caption: "",
      className: "delete",
    },
  ];

  const dataInitial = {
    date: dayjs(today).format("YYYY/MM/DD"),
    startLocation: "",
    stopLocation: "",
    startMile: 0,
    stopMile: 0,
    total: 0,
    personal: 0,
    net: 0,
  };
  const [formData, setFormData] = useState({
    nameExpense: "",
    listExpense: [dataInitial],
    file: null,
    remark: "",
    typeExpenseName: typeExpense,
    status: "",
    totalDistance: 0,
    personalDistance: 0,
    netDistance: 0,
    net: 0,
    taxable: 0,
    nonTaxable: 0,
    approver: {},
    cc_email: [],
  });
  const [rate, setRate] = useState(5);
  const [govermentRate, setGovermentRate] = useState(4);
  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const validationSchema = Yup.object().shape({
    nameExpense: Yup.string().required(t("ThisFieldIsRequired")),
    approver: Yup.string().required(t("ThisFieldIsRequired")),
    listExpense: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required(t("ThisFieldIsRequired")),
        startLocation: Yup.string().required(t("ThisFieldIsRequired")),
        stopLocation: Yup.string().required(t("ThisFieldIsRequired")),
        startMile: Yup.number().required(t("ThisFieldIsRequired")),
        stopMile: Yup.number().required(t("ThisFieldIsRequired")),
        total: Yup.number().required(t("ThisFieldIsRequired")),
        personal: Yup.number().required(t("ThisFieldIsRequired")),
        net: Yup.number().required(t("ThisFieldIsRequired")),
      })
    ),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
    remark: Yup.string(),
    typeExpenseName: Yup.string().default(typeExpense),
    status: Yup.string(),
    totalDistance: Yup.string(),
    personalDistance: Yup.string(),
    netDistance: Yup.string(),
    net: Yup.string(),
    taxable: Yup.string(),
    nonTaxable: Yup.string(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "listExpense",
  });

  const checkValidation = (error) => {
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  useEffect(() => {
    if (userProfile && userProfile.idManagerLV1 && employees) {
      setFormData({
        ...formData,
        approver: userProfile.idManagerLV1,
      });
      setValue("approver", userProfile.idManagerLV1);
    }
  }, [employees, userProfile]);

  useEffect(() => {
    if (!expenseSettingMileage) {
      dispatch(getExpenseSettingMileage(userProfile.idCompany));
    }
  }, []);

  useEffect(() => {
    if (expenseSettingMileage) {
      console.log(expenseSettingMileage);

      setRate(expenseSettingMileage.mileageRate);
      setGovermentRate(expenseSettingMileage.mileageGovermentRate);
    }
  }, [expenseSettingMileage]);

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} />
  ));

  const calculateSum = (array) => {
    let sumDistance = array.reduce((accumulator, object) => {
      return accumulator + parseInt(object.total);
    }, 0);
    let sumPersonal = array.reduce((accumulator, object) => {
      return accumulator + parseInt(object.personal);
    }, 0);
    let sumWork = array.reduce((accumulator, object) => {
      return accumulator + parseInt(object.net);
    }, 0);

    console.log("rate", rate);
    console.log("govermentRate", govermentRate);

    setValue("totalDistance", sumDistance);
    setValue("personalDistance", sumPersonal);
    setValue("netDistance", sumWork);
    setValue("net", parseInt(sumWork) * rate);
    setValue("taxable", parseInt(sumWork) * (rate - govermentRate));
    setValue("nonTaxable", parseInt(sumWork) * govermentRate);
  };

  const handleIsNan = (value) => {
    return isNaN(value) ? 0 : value;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeMile = (item) => {
    let newTotal =
      parseInt(getValues(`${item}.stopMile`)) -
      parseInt(getValues(`${item}.startMile`));
    setValue(`${item}.total`, newTotal > 0 ? handleIsNan(newTotal) : 0);
    setValue(
      `${item}.net`,
      handleIsNan(parseInt(newTotal) - parseInt(getValues(`${item}.personal`)))
    );
    calculateSum(getValues("listExpense"));
  };

  const createFormData = (statusId) => {
    let data = new FormData();
    data.append("nameExpense", getValues("nameExpense"));
    data.append("listExpense", JSON.stringify(getValues("listExpense")));
    if (watch("file")) {
      data.append("file", getValues("file"), getValues("file").path);
    }
    data.append("remark", getValues("remark"));
    data.append("typeExpense", 3);
    data.append("typeExpenseName", getValues("typeExpenseName"));
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("status", statusId);
    data.append("totalDistance", getValues("totalDistance"));
    data.append("personalDistance", getValues("personalDistance"));
    data.append("netDistance", getValues("netDistance"));
    data.append("net", getValues("net"));
    data.append("taxable", getValues("taxable"));
    data.append("nonTaxable", getValues("nonTaxable"));
    data.append("idEmpApprover", getValues("approver"));
    data.append(
      "cc_email",
      JSON.stringify(
        getValues("cc_email")
          ? getValues("cc_email")
              .map((e) => e.email)
              .join(";")
          : null
      )
    );
    data.append("idPosition", userProfile.idPosition);
    return data;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOnClickCleardata = () => {
    reset(formData);
  };

  const handleOnClickSavedraft = async () => {
    let data = createFormData(1);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      result = await dispatch(addExpenseMileage(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        history.push(`/expense/Mileage/Draft/${result.data.idExpense}`);
      } else {
        handleChangeAlertType("error");
      }
    }
  };

  const handleOnClickSent = async () => {
    let data = createFormData(2);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      result = await dispatch(addExpenseMileage(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  return (
    <Box>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      <form>
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">
              {t("GeneralInformation")}
            </StyledHeadLabel>
          </div>
          <div>
            <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
              {t("TitleName")}
            </StyledHeadLabel>
            <Controller
              name="nameExpense"
              control={control}
              render={({ field }) => (
                <>
                  <TextFieldTheme
                    {...field}
                    name="nameExpense"
                    inputProps={{ maxLength: 200 }}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                  <Typography
                    component="p"
                    textAlign="right"
                    variant="caption"
                    color="text.secondary"
                  >{`${field.value.length}/200`}</Typography>
                </>
              )}
            />
          </div>
          <div>
            <FormControl
              fullWidth
              error={errors && errors.approver ? true : false}
            >
              {errors && errors.approver && errors.approver.message && (
                <FormHelperText error>{errors.approver.message}</FormHelperText>
              )}
              <Controller
                name="approver"
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <SelectApprover
                      {...field}
                      idApprover={getValues("approver")}
                      handleChange={setValue}
                      position={userProfile && userProfile.idCompany === 3}
                    />
                  </>
                )}
              />
            </FormControl>
          </div>
          <div>
            <SelectCC control={control} errors={errors} />
          </div>
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("List")}</StyledHeadLabel>
            <div>
              <ButtonBlue
                size="small"
                startIcon={<AddRoundedIcon />}
                variant="outlined"
                onClick={() => {
                  append(dataInitial);
                }}
              >
                {t("AddItem")}
              </ButtonBlue>
            </div>
          </div>

          <TableContainer>
            <Table
              style={{
                minWidth: 650,
                borderSpacing: "0 4px",
                borderCollapse: "separate",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {rowHeader.map((item) => (
                    <StyledTableCell className={item.className}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t(item.caption)}
                      </StyledHeadLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.length > 0 &&
                  fields
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <RowItemExpense
                        key={item.id}
                        count={fields.length}
                        index={index}
                        open={open}
                        data={item}
                        handleDeleteRow={remove}
                        handleChangeMile={handleChangeMile}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              10,
              20,
              30,
              40,
              50,
              { label: t("All"), value: -1 },
            ]}
            component="div"
            count={watch("listExpense.length")}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={`${t("RowsPerPage")}`}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t("OutOf")} ${
                count !== -1 ? count : `${t("MoreThan")} ${to}`
              }`
            }
          />
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("SumaryItem")}</StyledHeadLabel>
          </div>
          <div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("TotalDistance")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(watch("totalDistance"))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Unit.km")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("PersonalUse")} &nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(watch("personalDistance"))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Unit.km")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("UsedInWork")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(watch("netDistance"))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Unit.km")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("taxable")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(watch("taxable"))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Baht")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography variant="h5">
                {t("TotalNetWorth")}&nbsp;&nbsp;
              </Typography>
              <Typography variant="h4">
                {utils.numberWithCommas(watch("net"))}
              </Typography>
              <Typography variant="h5">&nbsp;&nbsp;{t("Baht")}</Typography>
            </div>
          </div>
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("AttachFile")}</StyledHeadLabel>
          </div>
          {!watch("file") ? (
            <Controller
              name={"file"}
              control={control}
              render={({ field }) => (
                <>
                  <StyledDropzone>
                    <Dropzone
                      accept="image/jpeg, image/png, application/pdf"
                      maxFiles={1}
                      multiple={false}
                      maxSize={3145728}
                      onDrop={(acceptedFiles, rejectedFiles) => {
                        if (acceptedFiles.length > 0) {
                          field.onChange(acceptedFiles[0]);
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: "dropzone-upload-file",
                          })}
                        >
                          <div className="inner-dropzone">
                            <input {...getInputProps()} />
                            {/* <Fragment>{thumbs}</Fragment> */}
                            <div style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <img alt="add" src={GalleryIcon} />
                              </div>
                              <Typography
                                variant="caption"
                                style={{ lineHeight: 0.5, color: "#999999" }}
                              >
                                {t("imageFileNotMore500KB")}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </StyledDropzone>
                </>
              )}
            />
          ) : (
            <Box style={{ position: "relative", textAlign: "center" }}>
              <IconButton
                style={{ position: "absolute", margin: 7, right: 0 }}
                color="error"
                onClick={() => {
                  setValue("file", null);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <div
                style={{
                  padding: 15,
                  backgroundColor: "#f9f9fb",
                  display: "flex",
                  borderRadius: 15,
                }}
              >
                <FilePresentIcon
                  height="24"
                  style={{
                    marginRight: 10,
                  }}
                ></FilePresentIcon>
                <Typography>{watch("file").path}</Typography>
              </div>
            </Box>
          )}
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("Note")}</StyledHeadLabel>
          </div>
          <Controller
            name={"remark"}
            control={control}
            render={({ field }) => (
              <>
                <TextFieldTheme {...field} multiline rows={4} />
                <Typography
                  component="p"
                  textAlign="right"
                  variant="caption"
                  color="text.secondary"
                >{`${field.value ? field.value.length : 0}/500`}</Typography>
              </>
            )}
          />
        </div>
        <StyledDivider />
        <ActionButtons
          handleOnClickCleardata={handleOnClickCleardata}
          handleSubmitDraft={handleSubmit(
            handleOnClickSavedraft,
            checkValidation
          )}
          handleSubmitSent={handleSubmit(handleOnClickSent, checkValidation)}
        />
      </form>
    </Box>
  );
};

export default Mileage;
