import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  ThemeProvider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme} from '@mui/material/styles';
import { useTranslation } from 'react-i18next'; 
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteIcon from '@mui/icons-material/Delete'; 

const theme = createTheme({
    palette: {
      primary: {
        main: '#039be5', // สีของปุ่ม primary
      },
      secondary: {
        main: '#FF0000', 
      },
      alert: {
        main: '#039be5', // สีของ Dialog title background
        text: '#283593', // สีของข้อความใน Dialog content
      },
    },
});
const useStyles = makeStyles({
  iconButton: {
      transition: 'transform 0.3s ease',
      color: '#000000',
      '&:hover': {
          transform: 'scale(1.2)',
          color: '#00CC00', 
      },
  },
  iconButtonDelete: {
      transition: 'transform 0.3s ease',
      color: '#000000',
      '&:hover': {
          transform: 'scale(1.2)',
          color: '#FF0000', 
      },
  },
  dialogBackground: {
    '& .MuiDialog-paper': {
      backgroundColor: '##BEBEBE', 
      borderRadius: '10px', 
    },
    '& .css-ut4szi': {
      fontSize: '24px', 
      width: '90vw',     
      height: '90vh',   
    },
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none', 
        border: '1px solid black', 
        borderRadius: '10px',
        background: 'linear-gradient(to bottom, #FFFFFF, #F0F8FF)',
      },
    },   
    '& .MuiBackdrop-root': {
      backdropFilter: 'none', 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '40vw', 
      maxHeight: '60vh', 
      borderRadius: '10px', 
    },
    '& .MuiBackdrop-root.MuiModal-backdrop': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '0vw', 
      maxHeight: '0vh', 
      borderRadius: '10px', 
    },
  },
  cancelButton: {
    color: 'red',
    border: '1px solid red',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
    },
  },
  confirmButton: {
    color: 'green',
    border: '1px solid green',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(0, 255, 0, 0.1)',
    },
  },
});

const AlertDialog = ({ 
  open, 
  onClose,
  selectedRow,
  setSelectedRow,
  setDialogType,
  dialogType, 
  handleCloseDialog,
  openDialog, 
  setOpenDialog,  
  handleConfirmDelete,
  mergedDocumentGroups 
}) => {
  
  console.log('mergedDocumentGroups: ', mergedDocumentGroups);
  const { t } = useTranslation(); 
  const classes = useStyles();
  
  const handleClickOpen = (type, row) => {
    const {documentTypeNames, ...rest} = row;
    setDialogType(type);
    setSelectedRow(rest);
    setOpenDialog(true);
  };

  const handleConfirm = (type, selectedRow) => {
    handleConfirmDelete(type, selectedRow);
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            height: '80vh', 
            backgroundImage: 'linear-gradient(45deg, rgba(211, 211, 211, 0.9))',
            backgroundColor: 'transparent',
            userSelect: 'none',
            borderRadius: '13px',
          }
        }}
      >
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.palette.alert.main, color: 'white' }}>
          <Typography variant="h6" style={{userSelect: 'none'}}>
            {`${t("editDocumentCategory")}`}
          </Typography>
          <IconButton aria-label="close" onClick={onClose} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: 'white', 
            padding: '16px',
          }}
        >         
          <Box mt={2}>
          <Table>
            <TableHead>
              <TableRow style={{userSelect: 'none' }}>
                <TableCell >{t('Name')}</TableCell>
                <TableCell align="center">{t('Status')}</TableCell>
                <TableCell align="center">{t('Edit')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergedDocumentGroups.map((row, index) => (
                <TableRow key={index}>
                  <TableCell  style={{ verticalAlign: 'middle', userSelect: 'none'  }}>
                    {row.documentGroupName}
                  </TableCell>
                  <TableCell align="center" style={{ verticalAlign: 'middle', userSelect: 'none' }}>
                    {row.documentTypeNames && row.documentTypeNames.length > 0 
                      ? row.documentTypeNames.some(name => name === null) 
                        ? (
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <WarningIcon style={{ color: 'red', marginRight: '8px' }}/>
                            <Typography style={{ color: 'red' }}>{t("noDocumentTypeIsAvailable")}</Typography>
                          </Box>
                        ) 
                        : (
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <CheckIcon style={{ color: 'black', marginRight: '8px' }}/>
                            <Typography style={{ color: 'black' }}>{t("thereAreDocumentTypesInUse")}</Typography>
                          </Box>
                        )
                      : (
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <CloseIcon style={{ color: 'red', marginRight: '8px' }}/>
                          <Typography style={{ color: 'red' }}>{t("noDocumentTypeIsAvailable")}</Typography>
                        </Box>
                      )
                    }
                  </TableCell>
                  <TableCell align="center" style={{ verticalAlign: 'middle', userSelect: 'none' }}>
                    {row.documentTypeNames && row.documentTypeNames.length > 0 
                      ? row.documentTypeNames.some(name => name === null)
                        ? (
                          <DeleteIcon
                          style={{ cursor: 'pointer', color: 'red' }} 
                          onClick={() =>  handleClickOpen('delete', row)} 
                          />
                        ) 
                        : (
                          <DeleteIcon
                          style={{ cursor: 'not-allowed', color: 'gray' }} 
                          onClick={() => {}}
                          />
                        )
                      : (
                          <DeleteIcon
                            style={{ cursor: 'not-allowed', color: 'gray' }} 
                            onClick={() => {}}
                          />
                      )
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </Box>
        </DialogContent>

        {dialogType === "delete" && (
          <Dialog
          open={openDialog}
          onClose={handleCloseDialog}                                 
          className={classes.dialogBackground}
        >
          <DialogTitle>{t("Confirmdelete")}</DialogTitle>
          <DialogContent>
            {t("DeleteCategoryCompayDoc")}
          </DialogContent>
          <DialogActions>
          <IconButton
            onClick={handleCloseDialog} 
            className={classes.cancelButton}
          >
              {t("Cancel")}
            </IconButton>
            <IconButton
              onClick={() => handleConfirm('deleteCategory', selectedRow)} 
              className={classes.confirmButton} 
            >
              {t("Confirm")}
            </IconButton>
          </DialogActions>
          </Dialog>
         )}
      </Dialog>
    </ThemeProvider>

  );
};

export default AlertDialog;