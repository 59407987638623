import React from "react";
import { Box, Grid, FormControl, MenuItem } from "@mui/material";
import DatePickerCustom from "../../../shared/date/datePicker";
import PropTypes from "prop-types";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import ClearIcon from "@mui/icons-material/SearchOff";

const AdvanceSearch = ({
  searchData,
  handleChange,
  handleDateChange,
  handleSearchOff,
  t
}) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <DatePickerCustom
            label={t('eMemo.createdDateFrom')}
            value={searchData.createdDateFrom}
            onChange={(date) => handleDateChange("createdDateFrom", date)}
            renderInput={(params) => <TextFieldTheme {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePickerCustom
            label={t('eMemo.createdDateTo')}
            value={searchData.createdDateTo}
            onChange={(date) => handleDateChange("createdDateTo", date)}
            renderInput={(params) => <TextFieldTheme {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextFieldTheme
            label={t('DocumentNumber')}
            name="documentNo"
            value={searchData.documentNo}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextFieldTheme
            label={t('eMemo.requester')}
            name="requester"
            value={searchData.requester}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextFieldTheme
            label={t('eMemo.creator')}
            name="creator"
            value={searchData.creator}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextFieldTheme
            label={t('eMemo.approver')}
            name="approver"
            value={searchData.approver}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextFieldTheme
              select
              label={t('eMemo.status')}
              name="status"
              value={searchData.status}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="Waiting Approval">Waiting Approval</MenuItem>
              <MenuItem value="Waiting Approver Approve">
                Waiting Approver Approve
              </MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </TextFieldTheme>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextFieldTheme
            label={`${t('eMemo.subject')}`}
            name="subject"
            value={searchData.subject}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} display="flex"
          justifyContent="flex-end">
          <ButtonBlue
            variant="contained"
            startIcon={<ClearIcon />}
            color="primary"
            onClick={handleSearchOff}
          >
            {`${t("clear")}`}
          </ButtonBlue>
        </Grid>
      </Grid>
    </Box>
  );
};

AdvanceSearch.propTypes = {
  searchData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSearchOff: PropTypes.func.isRequired,
};

export default AdvanceSearch;
