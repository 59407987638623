import ExcelJS from "exceljs";
import dayjs from 'dayjs';

export const ProvidentFundCompanyYearlyXlsx = async (t, data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(t("ProvidentFundCompanyYearlyReport"));

  const months = [
    { value: "01", name: t("Months.January") },
    { value: "02", name: t("Months.February") },
    { value: "03", name: t("Months.March") },
    { value: "04", name: t("Months.April") },
    { value: "05", name: t("Months.May") },
    { value: "06", name: t("Months.June") },
    { value: "07", name: t("Months.July") },
    { value: "08", name: t("Months.August") },
    { value: "09", name: t("Months.September") },
    { value: "10", name: t("Months.October") },
    { value: "11", name: t("Months.November") },
    { value: "12", name: t("Months.December") },
  ];

  const uniqueMonths = Array.from(
    new Set(data.flatMap((employee) => employee.provident.map((record) => record.month)))
  ).sort();

  const mappedMonths = uniqueMonths.map((month) => {
    const [year, monthValue] = month.split("-");
    const mapped = months.find((m) => m.value === monthValue);
    return mapped ? `${mapped.name}` : month;
  });

  const headers = [
    t("CompanyName"),
    ...mappedMonths.map((month) => `${t("ProvidentFundCompany")} ${t("Month")}${month}`),
    t("TotalProvidentFundCompany"),
  ];

  const headerRow = worksheet.addRow(headers);

  headerRow.height = 50; 

  const headerStyle = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: '002060' },
    },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };

  headerRow.eachCell((cell) => {
    Object.assign(cell.style, headerStyle);
  });

  const colWidths = [
    { key: "companyName", width: 40 },
    ...mappedMonths.map(() => ({ key: "providentCompany", width: 60 })),
    { key: "totalProvidentCompany", width: 60 },
  ];

  colWidths.forEach((col, index) => {
    worksheet.getColumn(index + 1).width = col.width;
  });

  const groupedData = data.reduce((acc, employee) => {
    if (!acc[employee.companyName]) {
      acc[employee.companyName] = {
        companyName: employee.companyName,
        monthlyProvident: {},
        totalProvident: 0,
      };
    }

    employee.provident.forEach((record) => {
      acc[employee.companyName].monthlyProvident[record.month] =
        (acc[employee.companyName].monthlyProvident[record.month] || 0) +
        (record.provident_company || 0);
      acc[employee.companyName].totalProvident += record.provident_company || 0;
    });

    return acc;
  }, {});

  Object.values(groupedData).forEach((company) => {
    const monthlyData = uniqueMonths.map((month) => {
      return (
        (company.monthlyProvident[month] && company.monthlyProvident[month].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")) || "0.00"
      );
    });

    const row = [
      company.companyName,
      ...monthlyData,
      company.totalProvident.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    const dataRow = worksheet.addRow(row);

    const contentStyle = {
      font: { size: 16, name: "TH SarabunPSK", bold: false },
      alignment: { horizontal: "center", vertical: "middle" },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    dataRow.eachCell((cell) => {
      Object.assign(cell.style, contentStyle);
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${t("ProvidentFundCompanyYearlyReport")}.xlsx`;
  link.click();
};
