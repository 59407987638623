import ExcelJS from "exceljs";
import dayjs from 'dayjs';
import fs from "fs";

export const ProvidentFundDateXlsx = async (t, data) => {

  const months = [
    { value: "01", name: t("Months.January") },
    { value: "02", name: t("Months.February") },
    { value: "03", name: t("Months.March") },
    { value: "04", name: t("Months.April") },
    { value: "05", name: t("Months.May") },
    { value: "06", name:  t("Months.June") },
    { value: "07", name: t("Months.July") },
    { value: "08", name: t("Months.August") },
    { value: "09", name: t("Months.September") },
    { value: "10", name:  t("Months.October") },
    { value: "11", name: t("Months.November") },
    { value: "12", name: t("Months.December") },
  ];

    const monthObject = months.find(month => month.value === data[0]['month'].toString().padStart(2, '0'));

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${t("ProvidentFundInOutReport")}_${t("Monthly")}${monthObject.name}_${data[0]['year']}`);
  
    const headerRow = worksheet.addRow([
      t("EmployeeID"),
      t("FirstName"),
      t("LastName"),
      t("PersonalID"),
      t("Company"),
      t("Section"),
      t("Department"),
      t("Position"),
      t("HiringDate"),
      t("ResignDate"),
      `${t("Age")} (${t("Year")})`,
      `${t("Age")} (${t("Month")})`,
      `${t("ServiceYear")} (${t("Year")})`,
      `${t("ServiceYear")} (${t("Month")})`,
      t("FundEntryDate"),
      t("FundExitDate"),
      `${t("YearInFund")} (${t("Year")})`,
      `${t("YearInFund")} (${t("Month")})`,
      `% ${t("CompanyContributionFund")}`,
      t("CompanyContributionFund"),
      `% ${t("EmployeeContributionFund")}`,
      t("EmployeeContributionFund"),
    ]);

    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    };

    headerRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.style = headerStyle1;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
    });

    const colWidths = [
        { key: "employeeID", width: 40 },
        { key: "firstname", width: 40 },
        { key: "lastname", width: 40 },
        { key: "personalID", width: 40 },
        { key: "companyName", width: 40 },
        { key: "section", width: 40 },
        { key: "department", width: 40 },
        { key: "postion", width: 40 },
        { key: "hiringDate", width: 40 },
        { key: "resignDate", width: 40 },
        { key: "AgeY", width: 40 },
        { key: "AgeM", width: 40 },
        { key: "serviceYearY", width: 40 },
        { key: "serviceYearM", width: 40 },
        { key: "FundEntryDate", width: 40 },
        { key: "FundExitDate", width: 40 },
        { key: "YearInFundY", width: 40 },
        { key: "YearInFundM", width: 40 },
        { key: "Company Contribution Percent", width: 60 },
        { key: "Company Contribution", width: 40 },
        { key: "Employee Contribution Percent", width: 60 },
        { key: "Employee Contribution", width: 40 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });
  
    data.forEach((item) => {
    const hiringDate = item.hiringDate ? dayjs(item.hiringDate).locale('th').format('DD MMMM YYYY') : "-";
    const resignDate = item.resignDate ? dayjs(item.resignDate).locale('th').format('DD MMMM YYYY') : "-";
    const fundEntryDate = item.fundEntryDate ? dayjs(item.fundEntryDate).locale('th').format('DD MMMM YYYY') : "-";
    const fundExitDate = item.fundExitDate ? dayjs(item.fundExitDate).locale('th').format('DD MMMM YYYY') : "-";
      const row =[
        item.employeeID || "-",
        item.firstname || "-",
        item.lastname || "-",
        item.personalID || "-",
        item.companyName || "-",
        item.section || "-",
        item.department || "-",
        item.position || "-",
        hiringDate,
        resignDate,
        item.ageY || "-",
        item.ageM || "-",
        item.serviceYearY || "-",
        item.serviceYearM || "-",
        fundEntryDate,
        fundExitDate,
        item.yearInFundY || "-",
        item.yearInFundM || "-",
        item.companyContributionRate || "-",
        item.companyContributionAmount ? item.companyContributionAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
        item.employeeContributionRate || "-",
        item.employeeContributionAmount ? item.employeeContributionAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
      ];

    const excelRow = worksheet.addRow(row);

    const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } ,alignment: { horizontal: "center", vertical: 'middle' },};

    excelRow.eachCell((cell) => {
        cell.style = contentStyle;
        
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });
    });

  
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${t("ProvidentFundInOutReport")}_${t("Monthly")}${monthObject.name}_${data[0]['year']}.xlsx`;
      link.click();
    });
  };
  