import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { Typography, Alert, Stack } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getAffiliateOrganization, getCompanyOrganizationList } from "../../../../../actions/company";
import { getIrregularitiesLeaveAbsentLate } from "../../../../../actions/report";
import { IrregularitiesLeaveAbsentLateXlsx } from "../xlsx-export/irregularitie-leave-absent-late-xlsx";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
});

const IrregularityLeaveAbsentLate = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose } = props;
    const { control } = useForm({});
    const { t } = useTranslation();

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [noData, setNoData] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [exportExcel, setExportExcel] = useState(null);
    const [searchDate, setSearchDate] = useState({
        month: new Date(dayjs()),
        start: new Date(dayjs().set('date', 1)),
        end: new Date(dayjs().set('date', dayjs().daysInMonth())),
    });

    const handleOpenAlertError = () => setOpenAlert(true);

    const handleCloseAlert = () => {
        setOpenAlert(false);
        setSearchDate(null);
    };

    const onChangeCompany = (newValue) => setSelectedCompany(newValue);

    const fetchedData = async () => {
        if (!selectedCompany || !searchDate.start || !searchDate.end) {
            setNoData(true);
            return;
        }

        try {
            setIsFetching(true);
            const response = await getIrregularitiesLeaveAbsentLate({
                idCompany: selectedCompany.idCompany,
                start: dayjs(searchDate.start).format("YYYY-MM-DD"),
                end: dayjs(searchDate.end).format("YYYY-MM-DD"),
            });

            if (response && response.data && response.data.employeeList.length > 0) {
                setNoData(false);
                setExportExcel(response.data.employeeList);
            } else {
                setNoData(true);
                handleOpenAlertError();
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setNoData(true);
        } finally {
            setIsFetching(false);
        }
    };

    const handleFileExport = () => {
        if (exportExcel && exportExcel.length > 0) {
            IrregularitiesLeaveAbsentLateXlsx(t, exportExcel);
        } else {
            handleOpenAlertError();
        }
    };


    useEffect(() => {
        if (selectedCompany && searchDate.start && searchDate.end) {
            fetchedData();
        } else {
            setIsFetching(false);
        }
    }, [selectedCompany, searchDate]);

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, [dispatch]);

    useEffect(() => {
        if (selectedCompany) {
            dispatch(getCompanyOrganizationList({
                idCompany: selectedCompany.idCompany,
                getSection: true,
            }));
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            let ownCompany = affiliateOrganizationList.find((x) => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany);
        }
    }, [userProfile, affiliateOrganizationList]);

    return (
        <DrawerCustom
            title={t("IrregularityLeaveAbsentLateReport")}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Stack spacing={4}>
                    {openAlert && (
                        <Alert severity="error" onClose={handleCloseAlert}>
                            {t("NoData")}
                        </Alert>
                    )}
                    <Stack spacing={2}>
                        <Stack spacing={1}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                {t("Company")}
                            </Typography>
                            <SelectAffiliateCompany
                                options={affiliateOrganizationList}
                                value={selectedCompany}
                                disabled={isFetching}
                                onChange={(_, value) => onChangeCompany(value)}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                {t("StartDate")}
                            </Typography>
                            <DatePickerCustom
                                inputFormat="DD MMMM YYYY"
                                value={searchDate.start}
                                openTo="month"
                                views={["year", "month", "day"]}
                                onChange={(newValue) => {
                                    if (dayjs(searchDate.end).diff(dayjs(newValue), 'day') > 62) {
                                        setSearchDate(prev => ({
                                            ...prev,
                                            start: newValue,
                                            end: dayjs(newValue).add(60, 'day'),
                                        }));
                                    } else {
                                        setSearchDate(prev => ({
                                            ...prev,
                                            start: newValue
                                        }));
                                    }
                                }}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                {t("EndDate")}</Typography>
                            <DatePickerCustom
                                minDate={searchDate.start}
                                maxDate={dayjs(searchDate.start).add(62, 'day')}
                                inputFormat="DD MMMM YYYY"
                                value={searchDate.end}
                                openTo="month"
                                views={["year", "month", "day"]}
                                onChange={(newValue) => {
                                  setSearchDate(prev => ({
                                    ...prev,
                                    end: newValue
                                  }));
                                }}
                            />
                        </Stack>
                    </Stack>

                    <ButtonBlue
                        variant="contained"
                        disabled={isFetching || noData}
                        onClick={handleFileExport}
                    >
                        {t("Download")}
                    </ButtonBlue>
                </Stack>
            </StyledRoot>
        </DrawerCustom>
    );
};

export default IrregularityLeaveAbsentLate;
