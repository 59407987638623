import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Container, Box, Tabs, Tab, Grid } from "@mui/material";
import PropTypes from "prop-types";
import Announcement from "./announcement";
import CompanyPolicy from "./companyPolicy";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncementByIdCompany } from "../../../../actions/announcement";
import { getPolicyByIdCompany } from "../../../../actions/policy";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import DatePickerCustom from "../../shared/date/datePicker";

const StyledRoot = styled("div")({
  backgroundColor: "#ffffff !important",
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MainTabs() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const [value, setValue] = React.useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedYear, setSelectedYear] = useState(dayjs());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCompany = (event, newValue) => {
    setSelectedCompany(newValue);
  }

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if(affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      setSelectedCompany(affiliateOrganizationList[0])
    }
  }, [affiliateOrganizationList]);

  // useEffect(() => {
  //   if(selectedCompany) {
  //     dispatch(
  //       getAnnouncementByIdCompany({
  //         idCompany: selectedCompany.idCompany,
  //       })
  //     );
  //     dispatch(
  //       getPolicyByIdCompany({
  //         idCompany: selectedCompany.idCompany,
  //       })
  //     );
  //   }
  // }, [selectedCompany]);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        {affiliateOrganizationList.length > 0 && (
          <Grid container spacing={1} marginTop="24px" marginBottom="16px">
            <Grid item xs={12} sm={6} md={7}>
              <Typography className="label" color="text.third">
                {t("SelectCompany")}
              </Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={handleChangeCompany}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="label" color="text.third">
                {t("StartDate")}
              </Typography>
              <DatePickerCustom
                inputFormat="YYYY"
                value={selectedYear}
                minDate={dayjs().subtract(3, 'year')}
                maxDate={dayjs()}
                views={["year"]}
                onChange={(newValue) => setSelectedYear(newValue)}
              />
            </Grid>
          </Grid>
        )}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs 
            value={value} 
            onChange={handleChange}  
            textColor="secondary"
            indicatorColor="secondary"
          > 
            <Tab label={t("Announcement")} {...a11yProps(0)} />
            <Tab label={t("CompanyPolicy")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Announcement selectedCompany={selectedCompany} selectedYear={selectedYear.year()} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CompanyPolicy selectedCompany={selectedCompany} selectedYear={selectedYear.year()} />
        </CustomTabPanel>
      </Container>
    </StyledRoot>
  );
}
