import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  CardContent,
  Typography,
  Container,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Menu,
  MenuItem,
  Switch,
  Select,
  Grid,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Checkbox,
} from "@mui/material";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import { useTranslation } from "react-i18next";
const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OtShiftPanel = (props) => {
  const { t, i18n } = useTranslation();
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [dataForm, setDataForm] = useState(null);

  useEffect(() => {
    if (payrollSetting) {
      setDataForm(payrollSetting);
    }
  }, [payrollSetting]);

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setDataForm({ ...dataForm, [name]: value });
  };

  return (
    <Box>
      {dataForm && (
        <Fragment>
          <div className="wrap-item-data">
            <div className="wrap-check">
              <Typography className="sub-header-1" variant="body1" gutterBottom>
                {t("OvertimePay")}
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    {t("CalculatePay")}
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="calculateOt"
                        value={dataForm.calculateOt}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("Yes")}
                        />
                        <FormControlLabel
                          value="0"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("No")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    {t("CalculateIncomeTax")}
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="otTax"
                        value={dataForm.otTax}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("Yes")}
                        />
                        <FormControlLabel
                          value="0"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("No")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    {t("CalculateSSO")}
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="otSso"
                        value={dataForm.otSso}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("Yes")}
                        />
                        <FormControlLabel
                          value="0"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("No")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    {t("CalculateProvidentFund")}
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="otPf"
                        value={dataForm.otPf}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("Yes")}
                        />
                        <FormControlLabel
                          value="0"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("No")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography
                    className="sub-header"
                    variant="body1"
                    gutterBottom
                  >
                    {t("OvertimePayOnWorkdays")}
                  </Typography>
                  <div>
                    <TextFieldTheme
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: (
                          <Typography color="text.secondary">
                            {t("Times")}
                          </Typography>
                        ),
                      }}
                      value={dataForm.xOT}
                      disabled={userProfile && userProfile.readOnly}
                      inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          console.log("values");
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography
                    className="sub-header"
                    variant="body1"
                    gutterBottom
                  >
                    {t("HolidayPayRate")} ({t("Unit.Days")})
                  </Typography>
                  <div>
                    <TextFieldTheme
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: (
                          <Typography color="text.secondary">
                            {t("Times")}
                          </Typography>
                        ),
                      }}
                      disabled={userProfile && userProfile.readOnly}
                      value={dataForm.xWorkingDailyHoliday}
                      inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          console.log("values");
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography
                    className="sub-header"
                    variant="body1"
                    gutterBottom
                  >
                    {t("HolidayPayRate")} ({t("Month")})
                  </Typography>
                  <div>
                    <TextFieldTheme
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: (
                          <Typography color="text.secondary">
                            {t("Times")}
                          </Typography>
                        ),
                      }}
                      disabled={userProfile && userProfile.readOnly}
                      value={dataForm.xWorkingMonthlyHoliday}
                      inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          console.log("values");
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography
                    className="sub-header"
                    variant="body1"
                    gutterBottom
                  >
                    {t("OvertimePayRateOnHolidays")}
                  </Typography>
                  <div>
                    <TextFieldTheme
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: (
                          <Typography color="text.secondary">
                            {t("Times")}
                          </Typography>
                        ),
                      }}
                      disabled={userProfile && userProfile.readOnly}
                      value={dataForm.xOTHoliday}
                      inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          console.log("values");
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={3}>
                <Box>
                  <Typography
                    className="sub-header"
                    variant="body1"
                    gutterBottom
                  >
                    {t("HolidayPayRate")} ({t("Unit.Days")})
                  </Typography>
                  <div>
                    <TextFieldTheme
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: (
                          <Typography color="text.secondary">
                            {t("Times")}
                          </Typography>
                        ),
                      }}
                      disabled={userProfile && userProfile.readOnly}
                      value={dataForm.xWorkingDailyHoliday}
                      inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          console.log("values");
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid> */}
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    {t("overtimeCalculationHours")}
                  </Typography>
                  <div>
                    <StyledFormControl fullWidth>
                      <Select
                        MenuProps={MenuProps}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="workingHours"
                        value={dataForm.workingHours}
                      >
                        <MenuItem key={8} value={8}>
                          8 {t("Unit.Hours")}
                        </MenuItem>
                        <MenuItem key={-1} value={-1}>
                          {t("basedOnWorkingHours")}
                        </MenuItem>
                      </Select>
                    </StyledFormControl>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>
          <StyledDivider />
          <div className="wrap-item-data">
            <div className="wrap-check">
              <Typography className="sub-header-1" variant="body1" gutterBottom>
                {t("ShiftWorkPay")}
              </Typography>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    {t("CalculatePay")}
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="calculateShiftFee"
                        value={dataForm.calculateShiftFee}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("Yes")}
                        />
                        <FormControlLabel
                          value="0"
                          control={
                            <Radio
                              disabled={userProfile && userProfile.readOnly}
                            />
                          }
                          label={t("No")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateShiftFee) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      {t("CalculateIncomeTax")}
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="shiftFeeTax"
                          value={dataForm.shiftFeeTax}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateShiftFee) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      {t("CalculateSSO")}
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="shiftFeeSso"
                          value={dataForm.shiftFeeSso}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateShiftFee) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      {t("CalculateProvidentFund")}
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="shiftFeePf"
                          value={dataForm.shiftFeePf}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </div>
        </Fragment>
      )}
    </Box>
  );
};

export default OtShiftPanel;
