import React, { useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, MenuItem, styled, Typography } from "@mui/material";
import DrawerCustom from "../../../../shared/general/Drawer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import DatePickerCustom from "../../../../shared/date/datePicker";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { updateUser } from "../../../../../../actions/user";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  paddingBottom: "8px"
});

const DrawerEdit = (props) => {

  const { drawerConfig, handleClose } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: reasonExitInterview } = useSelector((state) => state.exitInterviewReason);

  const validateYupSchema = yup.object({
    resignDate: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    reasonResign: yup.string().required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      resignDate: null,
      reasonResign: "",
      reasonResignOther: "",
      isBlacklist: false,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  const onSubmit = async (data) => {

    const formData = {
      idEmployees: employeeProfile.idEmployees,
      resignDate: data.resignDate? dayjs(data.resignDate).format("YYYY-MM-DD"): null,
      reasonResign: data.reasonResign? data.reasonResign: null,
      reasonResignOther: data.reasonResignOther? data.reasonResignOther: null,
      isBlacklist: data.isBlacklist? 1: 0,
    }

    const result = await dispatch(updateUser(formData));

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }

  }

  useEffect(() => {
    if (drawerConfig.isOpen) {
      useHookForm.reset({
        resignDate: employeeProfile.resignDate? dayjs(employeeProfile.resignDate): null,
        reasonResign: employeeProfile.reasonResign? employeeProfile.reasonResign: "",
        reasonResignOther: employeeProfile.reasonResignOther? employeeProfile.reasonResignOther: "",
        isBlacklist: employeeProfile.isBlacklist? true: false,
      });
    }
  }, [drawerConfig.isOpen]);

  return (
    <DrawerCustom
      title={`${t("AddResignInfo")}`}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {`${t("EffectiveDate")}`}
              </StyledHeadLabel>
              <Controller
                name="resignDate"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("SelectDate"),
                          readOnly: true
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error? true : false}
                        helperText={fieldState.error? fieldState.error.message : null}
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {`${t("Reason")}`}
              </StyledHeadLabel>
              <Controller
                name="reasonResign"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                    // SelectProps={{
                    //   displayEmpty: true,
                    // }}
                  >
                    {/* <MenuItem value="">{t("NotSpecified")}</MenuItem> */}
                    {reasonExitInterview && reasonExitInterview.map((option) => (
                      <MenuItem
                        key={option.idReasonExitInterview}
                        value={option.name}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {t("ReasonOther")}
              </ StyledHeadLabel>
              <Controller
                name="reasonResignOther"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{
                            alignSelf: "flex-end",
                            fontSize: "12px",
                            userSelect: "none",
                          }}
                        >
                          {400 - field.value.length}
                        </InputAdornment>
                      ),
                    }}
                    multiline
                    minRows={3}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                    onChange={(event) => {
                      if (event.target.value.length > 400) {
                        field.onChange(event.target.value.slice(0, 400));
                      } else {
                        field.onChange(event.target.value);
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name={"isBlacklist"}
                control={useHookForm.control}
                render={({field}) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="Blacklist"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={handleClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{t("Save")}</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEdit;