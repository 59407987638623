import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { getKpiOrganizationProgressById } from "../../../../../actions/kpi";
import { useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import LinearProgress from "@mui/material/LinearProgress";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledRoot = styled(Box)({
  display: "flex",
  justifyContent: "center",
  padding: "24px 0",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const monthList = [
  "Months.January",
  "Months.February",
  "Months.March",
  "Months.April",
  "Months.May",
  "Months.June",
  "Months.July",
  "Months.August",
  "Months.September",
  "Months.October",
  "Months.November",
  "Months.December",
];

const KpiOrganizationProgressDetail = ({ selectMonthKPI }) => {
  const { t } = useTranslation();
  return (
    <Stack alignItems="flex-start" spacing={2}>
      <TextField
        fullWidth
        multiline
        label={t("Plan")}
        value={selectMonthKPI ? selectMonthKPI.kpiPlan : ""}
        InputProps={{ readOnly: true }}
      />
      <TextField
        fullWidth
        multiline
        label={t("Achievement")}
        value={selectMonthKPI ? selectMonthKPI.kpiAchievement : ""}
        InputProps={{ readOnly: true }}
      />
      <TextField
        fullWidth
        multiline
        value={selectMonthKPI ? selectMonthKPI.kpiFailure : ""}
        label={t("Failure")}
        InputProps={{ readOnly: true }}
      />
      <TextField
        fullWidth
        multiline
        label={t("KeyLearning")}
        value={selectMonthKPI ? selectMonthKPI.kpiKeyLearning : ""}
        InputProps={{ readOnly: true }}
      />
    </Stack>
  );
};

const KpiOrganizationUpdateUser = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { result: kpiPlan } = useSelector((state) => state.kpiUpdateId);

  const [expanded, setExpanded] = useState(false);
  const [selectMonthKPI, setSelectMonthKPI] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    const findMonthKPI = kpiPlan.updates.find((item) => item.month === panel);
    if (findMonthKPI && isExpanded) {
      setSelectMonthKPI(findMonthKPI);
    } else {
      setSelectMonthKPI(null);
    }
    setExpanded(isExpanded ? panel : false);
  };

  const columns = [];

  useEffect(() => {
    if (state.planList.idKpiOrganization) {
      dispatch(
        getKpiOrganizationProgressById(state.planList.idKpiOrganization)
      );
    }
  }, [dispatch, state.planList.idKpiOrganization]);

  return (
    <StyledRoot className="page">
      <Container>
        <GridStyled
          container
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              className="kpi"
              sx={{ ml: 1, fontSize: "30px" }}
            >
              {t("Update")} KPI
            </Typography>
          </Box>
        </GridStyled>
        <Container>
          {kpiPlan && (
            <div className="cardPlan">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    backgroundColor: "#4f65df",
                    borderRadius: 15,
                    marginBottom: 15,
                    height: "50px",
                  }}
                >
                  <Typography
                    fontSize={16}
                    variant="h6"
                    sx={{ textAlign: "center", color: "#ffffff" }}
                  >
                    {t("UpdateKPISuccessPlanMonth")}
                  </Typography>
                </Grid>
              </Grid>
              <TableContainer sx={{ marginBottom: "16px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((c, index) => (
                        <StyledCellHeader
                          key={index}
                          sx={{
                            textAlign: c.headerTextAlign
                              ? c.headerTextAlign
                              : undefined,
                            minWidth: c.minWidth ? c.minWidth : undefined,
                            width: c.width ? c.width : undefined,
                            maxWidth: c.maxWidth ? c.maxWidth : undefined,
                          }}
                        >
                          {c.name}
                        </StyledCellHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {columns.map((c, cIndex) => (
                        <StyledCellContent
                          key={cIndex}
                          style={{
                            textAlign: c.cellTextAlign
                              ? c.cellTextAlign
                              : undefined,
                            fontSize: c.cellFontSize
                              ? c.cellFontSize
                              : undefined,
                            minWidth: c.minWidth ? c.minWidth : undefined,
                            width: c.width ? c.width : undefined,
                            maxWidth: c.maxWidth ? c.maxWidth : undefined,
                          }}
                        >
                          {c.cellRender(state.planList)}
                        </StyledCellContent>
                      ))}
                    </TableRow>
                    {(!kpiPlan || kpiPlan.length <= 0) && (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          <Box
                            height="120px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography variant="h6">
                              <FindInPageIcon />
                              {t("NoData")}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <div>
                  {[...Array(dayjs(kpiPlan.endDate).get("month") + 1).keys()]
                    .slice(dayjs(kpiPlan.startDate).get("month"))
                    .map((item, index) => (
                      <Accordion
                        key={index}
                        expanded={expanded === item + 1}
                        onChange={handleChange(item + 1)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            {index + 1}. {t(monthList[item])}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <KpiOrganizationProgressDetail
                            selectMonthKPI={selectMonthKPI}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </div>
              </TableContainer>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography mr={2} color="#FF4E88" fontWeight={600}>
                  {t("Progress")}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={kpiPlan.progress}
                  sx={{
                    width: "250px",
                    height: "8px",
                    borderRadius: "5px",
                    backgroundColor: "#FFCBCB",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#FF4E88",
                    },
                  }}
                />
                <Typography ml={1} color="#FF4E88" fontWeight={600}>
                  {kpiPlan.progress}%
                </Typography>
              </Box>
            </div>
          )}
        </Container>
      </Container>
    </StyledRoot>
  );
};

export default KpiOrganizationUpdateUser;
