import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import "dayjs/locale/th";
import ExcelJS from "exceljs";

import { FormControl, Grid, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";

// import downloadHeaderExcel from "../../assets/data/header-download-employee";
import {
  getEmployeeExcelExport,
  getEmployeeExcelDiligenceAllowanceExport,
} from "../../../../actions/employee";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import getDownloadHeaderExcel from "../../assets/data/header-download-employee";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  "& .btn-action": {
    display: "flex",
    justifyContent: "center",
    marginTop: 36,
  },
});

const DialogDownload = (props) => {
  const { open, toggleDrawer, company } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const excelExport = async (option) => {

    setIsLoading(true);

    const employeeList = await dispatch(getEmployeeExcelExport(option)).then(
      (res) => res.data
    );
    const elementList = [];

    if (employeeList.length <= 0) {
      employeeList.push({
        employeeID: null,
      });
    }

    for (let index = 0; index < employeeList.length; index++) {
      const element = {
        employeeID: employeeList[index].employeeID,
        title_TH: employeeList[index].title_TH,
        firstname_TH: employeeList[index].firstname_TH,
        lastname_TH: employeeList[index].lastname_TH,
        title_EN: employeeList[index].title_EN,
        firstname_EN: employeeList[index].firstname_EN,
        lastname_EN: employeeList[index].lastname_EN,
        nickname_TH: employeeList[index].nickname_TH,
        gender:
          employeeList[index].gender === "Male"
            ? "ชาย"
            : employeeList[index].gender === "Female"
            ? "หญิง"
            : "",
        personalID: employeeList[index].personalID,
        nationality: employeeList[index].nationality,
        religion: employeeList[index].idReligion? t(`Religion_Code.${employeeList[index].religionCode}`): t(`NotSpecified`),
        maritalStatus: employeeList[index].maritalStatus,
        passportNumber: employeeList[index].passportNumber,
        passportExpiryDate: employeeList[index].passportExpiryDate? dayjs(employeeList[index].passportExpiryDate).format("DD-MM-YYYY"): "",
        workPermitNumber: employeeList[index].workPermitNumber,
        workPermitExpiryDate: employeeList[index].workPermitExpiryDate? dayjs(employeeList[index].workPermitExpiryDate).format("DD-MM-YYYY"): "",
        taxID: employeeList[index].taxID,
        ssoID: employeeList[index].ssoID,
        birthday: employeeList[index].birthday
          ? dayjs(employeeList[index].birthday).format("DD-MM-YYYY")
          : "",
        houseNo: employeeList[index].houseNo,
        village: employeeList[index].village,
        villageNo: employeeList[index].villageNo,
        alley: employeeList[index].alley,
        road: employeeList[index].road,
        subDistrict: employeeList[index].subDistrict,
        district: employeeList[index].district,
        provience: employeeList[index].provience,
        areaCode: employeeList[index].areaCode,

        username: employeeList[index].username,
        email: employeeList[index].email,
        telephoneMobile: employeeList[index].telephoneMobile,
        emergencyContact: employeeList[index].emergencyContact,
        emergencyRelationship: employeeList[index].emergencyRelationship,
        emergencyPhone: employeeList[index].emergencyPhone,
        numberOfChildren: employeeList[index].numberOfChild,
        spouseFirstname: employeeList[index].spouseFirstname,
        spouseLastname: employeeList[index].spouseLastname,
        spouseBirthday: employeeList[index].spouseBirthday
          ? dayjs(employeeList[index].spouseBirthday).format("DD-MM-YYYY")
          : "",
        spouseTaxID: employeeList[index].spousePersonalID,
        employmentTypeName:
          dayjs().diff(dayjs(employeeList[index].hiringDate), "day") > 120
            ? employeeList[index].employmentTypeName
            : "ทดลองงาน",
        employmentTime: employeeList[index].employmentTime,
        isFinger:
          employeeList[index].isFinger === 1
            ? "ใช่"
            : employeeList[index].isFinger === 0
            ? "ไม่ใช่"
            : "",
        workingLocation: employeeList[index].workingLocation,
        groupGpsLocationName: employeeList[index].groupGpsLocationName,
        filed_office: employeeList[index].filed_office,
        jobLevelGroupName: employeeList[index].jobLevelGroupName,
        jobLevelName: employeeList[index].jobLevelName,
        personnelLevelGroupName: employeeList[index].personnelLevelGroupName,
        personnelLevelName: employeeList[index].personnelLevelName,
        jobGroupName: employeeList[index].jobGroupName,
        subJobGroupName: employeeList[index].subJobGroupName,
        positionName: employeeList[index].positionName,
        companyName: employeeList[index].companyName,
        branchName: employeeList[index].branchName,
        businessUnitName: employeeList[index].businessUnitName,
        divisionName: employeeList[index].divisionName,
        departmentName: employeeList[index].departmentName,
        sectionName: employeeList[index].sectionName,
        hiringDate: employeeList[index].hiringDate
          ? dayjs(employeeList[index].hiringDate).format("DD-MM-YYYY")
          : "",
        managerLV1_fullname: employeeList[index].managerLV1_firstname_TH
          ? `${employeeList[index].managerLV1_firstname_TH} ${employeeList[index].managerLV1_lastname_TH}`
          : null,
        managerLV1_positionName: employeeList[index].managerLV1_positionName,
        managerLV1_email: employeeList[index].managerLV1_email,
        managerLV2_fullname: employeeList[index].managerLV2_firstname_TH
          ? `${employeeList[index].managerLV2_firstname_TH} ${employeeList[index].managerLV2_lastname_TH}`
          : null,
        managerLV2_positionName: employeeList[index].managerLV2_positionName,
        managerLV2_email: employeeList[index].managerLV2_email,
        workingType: employeeList[index].workingType,
        shiftGroupName: employeeList[index].shiftGroupName,
        shiftName: employeeList[index].shiftName,
        timeIn: employeeList[index].timeIn,
        timeOut: employeeList[index].timeOut,
        paymentTypeName: employeeList[index].paymentTypeName,
        paymentRoundName: employeeList[index].paymentRoundName,
        salary: employeeList[index].salary,
        lastPositionDate: employeeList[index].lastPositionDate,
        lastSalary: employeeList[index].lastSalary,
        lastSalaryDate: employeeList[index].lastSalaryDate,
        lastSpecialAdjustmentIncrease:
          employeeList[index].lastSpecialAdjustmentIncrease,
        lastSpecialAdjustmentIncreaseDate:
          employeeList[index].lastSpecialAdjustmentIncreaseDate,
        lastJobLevelDate: employeeList[index].lastPositionDate,
        lastPersonnelLevelDate: employeeList[index].lastPersonnelLevelDate,

        contractType: employeeList[index].contractType,
        retirementDate: employeeList[index].retirementDate
          ? dayjs(employeeList[index].retirementDate).format("DD-MM-YYYY")
          : "",
        contractTermainatoinDate: employeeList[index].contractTermainatoinDate
          ? dayjs(employeeList[index].contractTermainatoinDate).format(
              "DD-MM-YYYY"
            )
          : "",
        contractTermainatoinStatus:
          employeeList[index].contractTermainatoinStatus,
        resignDate: employeeList[index].resignDate
          ? dayjs(employeeList[index].resignDate).format("DD-MM-YYYY")
          : "",
        OESY_Y: employeeList[index].OESY_Y,
        OESY_M: employeeList[index].OESY_M,
        OESY: employeeList[index].OESY,
        SERV_Y: employeeList[index].SERV_Y,
        SERV_M: employeeList[index].SERV_M,
        SERV: employeeList[index].SERV,
        ESY_Y: employeeList[index].ESY_Y,
        ESY_M: employeeList[index].ESY_M,
        ESY: employeeList[index].ESY,
        TIG_Y: employeeList[index].TIG_Y,
        TIG_M: employeeList[index].TIG_M,
        TIG: employeeList[index].TIG,
        AGE_Y: employeeList[index].AGE_Y,
        AGE_M: employeeList[index].AGE_M,
        AGE: employeeList[index].AGE,
        degree_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].degree
            : "",
        university_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].university
            : "",
        faculty_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].faculty
            : "",
        major_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].major
            : "",
        fromYear_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].fromYear
            : "",
        endYear_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].endYear
            : "",
        gpa_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].gpa
            : "",
        degree_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].degree
            : "",
        university_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].university
            : "",
        faculty_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].faculty
            : "",
        major_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].major
            : "",
        gpa_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].gpa
            : "",
        fromYear_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].fromYear
            : "",
        endYear_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].endYear
            : "",
        degree_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].degree
            : "",
        university_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].university
            : "",
        faculty_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].faculty
            : "",
        major_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].major
            : "",
        gpa_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].gpa
            : "",
        fromYear_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].fromYear
            : "",
        endYear_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].endYear
            : "",
        performance_past_1: employeeList[index].performancePast1,
        performance_past_2: employeeList[index].performancePast2,
        performance_past_3: employeeList[index].performancePast3,
        performance_past_4: employeeList[index].performancePast4,
        performance_past_5: employeeList[index].performancePast5,
        potential_past_1: null,
        potential_past_2: null,
        potential_past_3: null,
        leaderRole: null,
        costCenter: employeeList[index].costCenterCharge,
        bookBank: employeeList[index].bookBank,
        bookBankBranchName: employeeList[index].bookBankBranchName,
        bookID: employeeList[index].bookID,
        reportBankBankName: employeeList[index].reportBankBankName,
        reportBankBankID: employeeList[index].reportBankBankID,
        reportBankBookBankID: employeeList[index].reportBankBookBankID,
        reportBankName: employeeList[index].reportBankName,
        reportBankRef: employeeList[index].reportBankRef,
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList, { origin: 'A2', skipHeader: true });

    // console.log(downloadHeaderExcel);

    const headerList = getDownloadHeaderExcel();

    XLSX.utils.sheet_add_aoa(ws, headerList, { origin: 'A1' });

    // for (const [key, value] of Object.entries(objHearder)) {
    //   // console.log(`${key}: ${value}`);
    //   ws[key].v = value;
    // }

    var workbook = XLSX.utils.book_new();

    if (option && option.contractStatus === "normal") {
      XLSX.utils.book_append_sheet(workbook, ws, "PIS Master");
      XLSX.writeFile(
        workbook,
        `${company.companyName}_ข้อมูลพนักงานปกติ ${dayjs().format(
          "YYYY-MM-DD HH:mm:ss"
        )}.xlsx`,
        { type: "file" }
      );
    } else if (option && option.contractStatus === "terminate") {
      XLSX.utils.book_append_sheet(workbook, ws, "PIS Master");
      XLSX.writeFile(
        workbook,
        `${company.companyName}_ข้อมูลพนักงานลาออก ${dayjs().format(
          "YYYY-MM-DD HH:mm:ss"
        )}.xlsx`,
        { type: "file" }
      );
    }

    setIsLoading(false)
  };

  const terminatedEmployeeXlsxFle = async (option) => {

    setIsLoading(true)

    const employeeList = await dispatch(getEmployeeExcelExport(option)).then(
      (res) => res.data
    );

    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("สรุปพนักงานลาออก");

    const headerRow = worksheet1.addRow([
      t("EmployeeID"),
      t("FirstName"),
      t("LastName"),
      t("Position"),
      t("Company"),
      t("Division"),
      t("Department"),
      t("Section"),
      t("ResignDate"),
      t("ResignationReason"),
      t("OtherReason"),
      "Blacklist",
    ]);

    const headerStyle = {
      font: {
        bold: true,
        size: 18,
        name: "TH SarabunPSK",
        color: { argb: "FFFFFF" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
      },
    };

    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    headerRow.height = 50;
    const colWidths = [
      { key: "employeeID", width: 30 },
      { key: "firstname_TH", width: 30 },
      { key: "lastname_TH", width: 30 },
      { key: "positionName", width: 30 },
      { key: "companyName", width: 40 },
      { key: "division", width: 30 },
      { key: "department", width: 30 },
      { key: "section", width: 30 },
      { key: "resignDate", width: 50 },
      { key: "reason", width: 50 },
      { key: "morereason", width: 50 },
      { key: "blacklist", width: 50 },
    ];

    colWidths.forEach((col, index) => {
      worksheet1.getColumn(index + 1).width = col.width;
    });

    employeeList.forEach((item) => {
      const hiringDate =
        dayjs(item.resignDate).locale("th").format("DD MMMM YYYY") || "";
      const row = [
        item.employeeID ? item.employeeID : "-",
        item.firstname_TH ? item.firstname_TH : "-",
        item.lastname_TH ? item.lastname_TH : "-",
        item.positionName ? item.positionName : "-",
        item.companyName ? item.companyName : "-",
        item.divisionName ? item.divisionName : "-",
        item.departmentName ? item.departmentName : "-",
        item.sectionName ? item.sectionName : "-",
        hiringDate,
        item.reasonResign ? item.reasonResign : "-",
        item.reasonResignOther ? item.reasonResignOther : "-",
        item.isBlacklist === 1 ? "ใช่" : "ไม่",
      ];

      const excelRow = worksheet1.addRow(row);
      const contentStyle = { font: { size: 18, name: "TH SarabunPSK" } };

      excelRow.eachCell((cell) => {
        cell.style = contentStyle;
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${company.companyName}-${t("SummaryOfResigned")}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    });

    setIsLoading(false)
  };

  const excelDiligenceAllowanceExport = async () => {

    setIsLoading(true)

    const employeeList = await dispatch(
      getEmployeeExcelDiligenceAllowanceExport()
    ).then((res) => res.data);
    const elementList = [];
    for (let index = 0; index < employeeList.length; index++) {
      const element = {
        firstname: employeeList[index].firstname,
        lastname: employeeList[index].lastname,
        arrive: employeeList[index].arrive,
        absent: employeeList[index].absent,
        late: employeeList[index].late,
        earlyBack: employeeList[index].earlyBack,
        leave: employeeList[index].leave,
      };
      elementList.push(element);
    }

    const header = [
      [
        `${t("FirstName")}`,
        `${t("LastName")}`,
        `${t("WorkingDays")}`,
        `${t("Absent")}`,
        `${t("Late")}`,
        `${t("LeaveEarly")}`,
        `${t("LeaveExceptVacation")}`,
      ],
    ];

    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, header);
    XLSX.utils.sheet_add_json(ws, elementList, {
      origin: "A2",
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(workbook, ws, t("DiligenceAllowance"));
    XLSX.writeFile(
      workbook,
      `${t("EmpDiligenceAllowed")} ${dayjs().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.xlsx`,
      { type: "file" }
    );

    setIsLoading(false)
  };


  useEffect(() => {
    if(open){
      setIsLoading(false);
    }
  }, [open])

  return (
    <DrawerCustom
      title={`${t("Download")}`}
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <StyledRoot>
        {/* <div className="btn-action">
          <ButtonBlue variant="contained" onClick={excelExport} fullWidth>
            ดาวน์โหลดข้อมูล
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={excelDiligenceAllowanceExport} fullWidth>
            ดาวน์โหลดข้อมูล
          </ButtonBlue>
        </div> */}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{t("NormalEmp")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue
              variant="contained"
              onClick={() => {
                excelExport({
                  contractStatus: "normal",
                  idCompany: company.idCompany,
                });
              }}
              disabled={isLoading}
              fullWidth
            >
              {t("Download")}
            </ButtonBlue>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("EmpOut")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue
              variant="contained"
              onClick={() => {
                excelExport({
                  contractStatus: "terminate",
                  idCompany: company.idCompany,
                });
              }}
              disabled={isLoading}
              fullWidth
            >
              {t("Download")}
            </ButtonBlue>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("SummaryOfResignedEmployees")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue
              variant="contained"
              onClick={() => {
                terminatedEmployeeXlsxFle({
                  contractStatus: "terminate",
                  idCompany: company.idCompany,
                });
              }}
              disabled={isLoading}
              fullWidth
            >
              {t("Download")}
            </ButtonBlue>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography>พนักงานที่ได้รับเบี้ยขยัน</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue variant="contained" onClick={excelDiligenceAllowanceExport} fullWidth>
              ดาวน์โหลด
            </ButtonBlue>
          </Grid> */}
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogDownload;
