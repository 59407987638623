import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  CircularProgress,
  FormLabel,
  FormControl,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DomainIcon from "@mui/icons-material/Domain";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EventIcon from "@mui/icons-material/Event";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import {
  addPerformanceAdmin,
  getAllEmployeePerformance,
  getEmployeePerformance,
} from "../../../../actions/estimatePerformance";
import { useTranslation } from "react-i18next";
import LoadingGif from "../../assets/social-media.gif";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import {
  answerQuestion5Scale,
  answerQuestion6Scale,
} from "../../assets/data/answerScale";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const percentageScore = (questionList = []) => {
  let totalRating = 0;
  for (const item of questionList) {
    if (item.questionType === "choice") {
      totalRating += (item.answer * item.weight) / 100;
    }
  }

  return Math.round(totalRating);
};

const DialogPerformance = ({ open, handleClose, performance, idCompany, selectYear }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: estimateLevel } = useSelector((state) => state.estimateLevel);
  const { result: estimatePerformanceAnswer, isFetching } = useSelector(
    (state) => state.estimatePerformanceAnswer
  );

  const validationSchema = yup.object().shape({
    idEstimateLevel: yup
      .number()
      .nullable()
      .required(`${t("ThisFieldIsRequired")}`),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { idEstimateLevel: "" },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values) => {
    const res = await dispatch(addPerformanceAdmin({
      idEmployees: estimatePerformanceAnswer.idEmployees,
      yearPerformance: estimatePerformanceAnswer.year,
      idEstimateLevel: values.idEstimateLevel,
    }));
    if (res && res.status === 200) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getAllEmployeePerformance({ idCompany, year: selectYear }))
      handleClose();
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
  };

  useEffect(() => {
    dispatch(getEmployeePerformance(performance.idEmployeePerformance));
  }, [performance]);

  useEffect(() => {
    if (estimatePerformanceAnswer) {
      reset({ idEstimateLevel: estimatePerformanceAnswer.idEstimateLevel })
    }
  }, [estimatePerformanceAnswer]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t("AnnualEvaluation")}</DialogTitle>
      {isFetching || !estimatePerformanceAnswer ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={LoadingGif} style={{ width: 100, height: 100 }} />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2} marginBottom={6}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <PersonIcon />
                    <Typography>
                      {` ${t("PersonBeingAssessed")} : ${getUserFullName({
                        firstname_TH: estimatePerformanceAnswer.firstname_TH,
                        lastname_TH: estimatePerformanceAnswer.lastname_TH,
                        firstname_EN: estimatePerformanceAnswer.firstname_EN,
                        lastname_EN: estimatePerformanceAnswer.lastname_EN,
                      })}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <DomainIcon />
                    <Typography>
                      {`${t("Position")} : ${getUserPosition({
                        positionName: estimatePerformanceAnswer.positionName,
                        positionName_EN:
                          estimatePerformanceAnswer.positionName_EN,
                      })}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <AssignmentIcon />
                    <Typography>
                      {`${t("questionSet")} : ${
                        estimatePerformanceAnswer.questionSet.questionSetName
                      }`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <AssessmentIcon />
                    <Typography>
                      {`${t("Year")} : ${estimatePerformanceAnswer.year}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <EventIcon />
                    <Typography>
                      {`${t("EvaluationPeriod")} :  ${dayjs(
                        estimatePerformanceAnswer.startDate
                      ).format(
                        i18n.resolvedLanguage === "th"
                          ? "DD/MM/BBBB"
                          : "DD/MM/YYYY"
                      )} - ${dayjs(estimatePerformanceAnswer.endDate).format(
                        i18n.resolvedLanguage === "th"
                          ? "DD/MM/BBBB"
                          : "DD/MM/YYYY"
                      )}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <CheckCircleIcon />
                    <Typography>
                      {`${t("EvaluatedBy")} : ${
                        i18n.resolvedLanguage === "th"
                          ? estimatePerformanceAnswer.approvedBy
                          : estimatePerformanceAnswer.approvedBy_EN
                      }`}
                    </Typography>
                  </Grid>
                </Grid>
                {estimatePerformanceAnswer.questionSet.employeeQuestion && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      {t("Employee")}
                    </Typography>
                    <Stack spacing={1} marginBottom={4}>
                      {estimatePerformanceAnswer.questionSet.employeeQuestion.map(
                        (question, index) => (
                          <div key={question.idPerformanceQuestion}>
                            <Typography gutterBottom fontWeight={500}>
                              {`${t("Question")} ${index + 1} : ${
                                question.question
                              }`}
                            </Typography>
                            {question.questionType === "choice" ? (
                              <>
                                <Typography>{`${t("Weight")} ${
                                  question.weight
                                }%`}</Typography>
                                <RadioGroup row value={question.answer}>
                                  {estimatePerformanceAnswer.questionSet
                                    .answerScale === 6
                                    ? answerQuestion6Scale.map((item) => (
                                        <FormControlLabel
                                          key={item.key}
                                          value={item.value}
                                          control={<Radio />}
                                          label={t(item.label)}
                                        />
                                      ))
                                    : answerQuestion5Scale.map((item) => (
                                        <FormControlLabel
                                          key={item.key}
                                          value={item.value}
                                          control={<Radio />}
                                          label={t(item.label)}
                                        />
                                      ))}
                                </RadioGroup>
                              </>
                            ) : (
                              <TextFieldTheme
                                fullWidth
                                value={question.answer}
                                InputProps={{ readOnly: true }}
                              />
                            )}
                            <Divider
                              sx={{ borderColor: "#e2e2e2", marginTop: "8px" }}
                            />
                          </div>
                        )
                      )}
                    </Stack>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "16px 0px",
                      }}
                    >
                      <Box
                        style={{ position: "relative", display: "inline-flex" }}
                      >
                        <CircularProgress
                          variant="determinate"
                          style={{
                            color: "#eeeeee",
                          }}
                          size={70}
                          thickness={4}
                          value={100}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={percentageScore(
                            estimatePerformanceAnswer.questionSet
                              .employeeQuestion
                          )}
                          style={{
                            color: "#007afe",
                            animationDuration: "550ms",
                            position: "absolute",
                            left: 0,
                          }}
                          size={70}
                        ></CircularProgress>
                        <Box
                          style={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{ fontSize: 16 }}
                            component="div"
                            color="text.third"
                          >
                            {percentageScore(
                              estimatePerformanceAnswer.questionSet
                                .employeeQuestion
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </>
                )}
                <Typography variant="h6" gutterBottom>
                  {t("Manager")}
                </Typography>
                <Stack spacing={1} marginBottom={4}>
                  {estimatePerformanceAnswer.questionSet.managerQuestion
                    .length > 0 ? (
                    estimatePerformanceAnswer.questionSet.managerQuestion.map(
                      (question, index) => (
                        <div key={question.idPerformanceQuestion}>
                          <Typography gutterBottom fontWeight={500}>
                            {`${t("Question")} ${index + 1} : ${
                              question.question
                            }`}
                          </Typography>
                          {question.questionType === "choice" ? (
                            <>
                              <Typography>{`${t("Weight")} ${
                                question.weight
                              }%`}</Typography>
                              <RadioGroup value={question.answer} row>
                                {estimatePerformanceAnswer.answerScale === 6
                                  ? answerQuestion6Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))
                                  : answerQuestion5Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))}
                              </RadioGroup>
                            </>
                          ) : (
                            <TextFieldTheme
                              fullWidth
                              value={question.answer}
                              InputProps={{ readOnly: true }}
                            />
                          )}
                          <Divider
                            sx={{ borderColor: "#e2e2e2", marginTop: "8px" }}
                          />
                        </div>
                      )
                    )
                  ) : (
                    <Typography textAlign="center" color="text.secondary">
                      {t("NoQuestions")}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={2} marginBottom={4}>
                  <div>
                    <Typography gutterBottom>{t("Strength")}</Typography>
                    <TextFieldTheme
                      multiline
                      InputProps={{ readOnly: true }}
                      value={estimatePerformanceAnswer.goodPoint}
                    />
                  </div>
                  <div>
                    <Typography gutterBottom>{t("Weakness")}</Typography>
                    <TextFieldTheme
                      multiline
                      InputProps={{ readOnly: true }}
                      value={estimatePerformanceAnswer.weakPoint}
                    />
                  </div>
                  <div>
                    <Typography gutterBottom>{t("Other")}</Typography>
                    <TextFieldTheme
                      multiline
                      InputProps={{ readOnly: true }}
                      value={estimatePerformanceAnswer.others}
                    />
                  </div>
                  <div>
                    <Typography gutterBottom>
                      {t("FeedbackFromSupervisor")}
                    </Typography>
                    <TextFieldTheme
                      multiline
                      InputProps={{ readOnly: true }}
                      value={estimatePerformanceAnswer.managerComment}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "16px",
                    }}
                  >
                    <Box
                      style={{ position: "relative", display: "inline-flex" }}
                    >
                      <CircularProgress
                        variant="determinate"
                        style={{
                          color: "#eeeeee",
                        }}
                        size={70}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={percentageScore(
                          estimatePerformanceAnswer.questionSet.managerQuestion
                        )}
                        style={{
                          color: "#007afe",
                          animationDuration: "550ms",
                          position: "absolute",
                          left: 0,
                        }}
                        size={70}
                      ></CircularProgress>
                      <Box
                        style={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontSize: 16 }}
                          component="div"
                          color="text.third"
                        >
                          {percentageScore(
                            estimatePerformanceAnswer.questionSet
                              .managerQuestion
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div>
                    <FormControl>
                      <FormLabel sx={{ marginBottom: "8px" }}>
                        {t("PerformanceAssessmentLevels")}
                      </FormLabel>
                      <Controller
                        control={control}
                        name={"idEstimateLevel"}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            select
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                            error={Boolean(fieldState.error)}
                          >
                            {[
                              {
                                idEstimateLevel: estimatePerformanceAnswer.idEstimateLevel,
                                name: estimatePerformanceAnswer.estimateLevel,
                              },
                              ...estimateLevel,
                            ].map((item) => (
                              <MenuItem
                                key={item.idEstimateLevel}
                                value={item.idEstimateLevel}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      />
                    </FormControl>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonBlue type="button" onClick={handleClose}>
              {t("Close")}
            </ButtonBlue>
            <ButtonBlue
              type="submit"
              variant="contained"
              disabled={isSubmitting}
            >
              {t("SaveData")}
            </ButtonBlue>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default DialogPerformance;
