import React, { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateJobProfile } from "../../../../actions/positions";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const DialogUpdateJobProfile = (props) => {
  const {
    drawerConfig,
    onClose,
    setOpenAlert,
    setOpenAlertError
  } = props;

  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [levelList, setLevelList] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);

  const validateYupSchema = yup.object({
    overall_qualification: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
    require_experience: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
  });

  const useHookForm = useForm({
    defaultValues: {
      idPosition: null,
      overall_qualification: "",
      require_experience: "",
      special_requirement: "",
      description: "",
      min_salary: 0,
      max_salary: 0,
      midpoint_salary: 0.0,
      je_score: 0.0,
      minimum_Education: "",
      faculty: "",
      idJobLevelMax: "",
      idJobLevelMin: "",
      jobLevel: [],
      jobImg: ""
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const { reset, handleSubmit, control, formState, watch, setValue } = useHookForm;

  const onSubmit = async (data) => {
    const { ...formData } = data;
    formData.idPosition = drawerConfig.data.idPosition;

    if (uploadedImage) {
      formData.image = uploadedImage;
    };

    delete formData.jobLevel;

    const dataForm = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      dataForm.append(key, value);
    });

    const result = await updateJobProfile(dataForm);

    if (result && result.status === 200) {
      setOpenAlert(true);
      onClose();
    } else {
      setOpenAlertError(true);
    };
  };

  const maxSalary = watch("max_salary");
  const minSalary = watch("min_salary");

  useEffect(() => {
    if (maxSalary && minSalary) {
      const midpoint = (parseInt(maxSalary) + parseInt(minSalary)) / 2;
      setValue("midpoint_salary", midpoint);
    }
  }, [maxSalary, minSalary, setValue]);

  useEffect(() => {
    if (drawerConfig && drawerConfig.data && drawerConfig.data.jobLevel) {
      setLevelList(drawerConfig.data.jobLevel);
      setImage(drawerConfig.data.jobImg)

      reset({
        idPosition: drawerConfig.data.idPosition,
        overall_qualification: drawerConfig.data.overall_qualification || "",
        require_experience: drawerConfig.data.require_experience || "",
        special_requirement: drawerConfig.data.special_requirement || "",
        description: drawerConfig.data.description || "",
        min_salary: drawerConfig.data.min_salary || 0,
        max_salary: drawerConfig.data.max_salary || 0,
        midpoint_salary: drawerConfig.data.midpoint_salary || 0.0,
        je_score: drawerConfig.data.je_score || 0.0,
        minimum_Education: drawerConfig.data.minimum_Education || "",
        faculty: drawerConfig.data.faculty || "",
        idJobLevelMax: drawerConfig.data.idJobLevelMax || "",
        idJobLevelMin: drawerConfig.data.idJobLevelMin || "",
        jobLevel: drawerConfig.data.jobLevel || [],
        jobImg: drawerConfig.data.jobImg || "",
        imgName: drawerConfig.data.imgName || ""
      });
    }
  }, [drawerConfig, reset]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const maxSizeMB = 5;

    if (file) {
      if (file.size > maxSizeMB * 1024 * 1024) {
        setErrorAlert(true);
      } else {
        setUploadedImage(file);
        setSelectedImage(URL.createObjectURL(file));
      }
    }
  };

  const handleCloseAlertError = () => {
    setErrorAlert(false);
  };

  return (
    <DrawerCustom
      title={`${t("updateJobProfile")}`}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
                <Button
                  sx={{ borderRadius: '50%', padding: 0 }}
                  component="label"
                >
                  {selectedImage ? (
                    <Avatar
                      alt="selectedImage"
                      src={selectedImage}
                      sx={{ width: 150, height: 150 }}
                    />
                  ) : (
                    <Avatar
                      alt="jobProfileImg"
                      src={image}
                      sx={{ width: 150, height: 150 }}
                    />
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleImageChange}
                  />
                </Button>

                {errorAlert && (
                  <Alert severity="error" onClose={() => handleCloseAlertError()}>
                    {t("file_too_large")}
                  </Alert>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("overallQualification")}
                </Typography>
              </Box>
              <Controller
                name="overall_qualification"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    multiline
                    rows={4}
                    helperText={
                      formState.errors[field.name]
                        ? formState.errors[field.name].message
                        : null
                    }
                    error={formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("requireExperience")}
                </Typography>
              </Box>
              <Controller
                name="require_experience"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    multiline
                    rows={2}
                    helperText={
                      formState.errors[field.name]
                        ? formState.errors[field.name].message
                        : null
                    }
                    error={formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("JobDescription")}/{t("keyRole")}
                </Typography>
              </Box>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    multiline
                    rows={2}
                    helperText={
                      formState.errors[field.name]
                        ? formState.errors[field.name].message
                        : null
                    }
                    error={formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("specialRequirements")}
                </Typography>
              </Box>
              <Controller
                name="special_requirement"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    multiline
                    rows={2}
                    helperText={
                      formState.errors[field.name]
                        ? formState.errors[field.name].message
                        : null
                    }
                    error={formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("jobLevelMax")}
                </Typography>
              </Box>
              <Controller
                name="idJobLevelMax"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={levelList}
                    getOptionLabel={(option) => `${option.jobLevelName}`}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idJobLevel}>
                        <Box>
                          <Typography>{option.jobLevelName}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={formState.errors[field.name] ? true : false}
                        helperText={
                          formState.errors[field.name]
                            ? formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                    value={
                      levelList
                        ? levelList.find(
                          (option) => option.idJobLevel === field.value
                        ) || null
                        : null
                    }
                    onChange={(_, value) => {
                      field.onChange(value ? value.idJobLevel : "");
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("jobLevelMin")}
                </Typography>
              </Box>
              <Controller
                name="idJobLevelMin"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={levelList}
                    getOptionLabel={(option) => `${option.jobLevelName}`}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idJobLevel}>
                        <Box>
                          <Typography>{option.jobLevelName}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={formState.errors[field.name] ? true : false}
                        helperText={
                          formState.errors[field.name]
                            ? formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                    value={
                      levelList
                        ? levelList.find(
                          (option) => option.idJobLevel === field.value
                        ) || null
                        : null
                    }
                    onChange={(_, value) => {
                      field.onChange(value ? value.idJobLevel : "");
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("minimumEducation")}
                </Typography>
              </Box>
              <Controller
                name="minimum_Education"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={[
                      { label: `${t("PrimaryEducation")}`, value: "ประถมศึกษา" },
                      { label: `${t("JuniorHighSchoolEducation")}`, value: "มัธยมศึกษาตอนต้น" },
                      { label: `${t("SeniorHighSchoolEducation")}`, value: "มัธยมศึกษาตอนปลาย" },
                      { label: `${t("VocationalCertificate")}`, value: "ปวช." },
                      { label: `${t("​CertificateOfTechnicalVocation")}`, value: "ปวส." },
                      { label: `${t("BachelorDegrees")}`, value: "ปริญญาตรี" },
                      { label: `${t("MasterDegrees")}`, value: "ปริญญาโท" },
                      { label: `${t("DoctorDegrees")}`, value: "ปริญญาเอก" }
                    ]}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.value}>
                        <Box>
                          <Typography>{option.label}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={formState.errors[field.name] ? true : false}
                        helperText={
                          formState.errors[field.name]
                            ? formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                    value={
                      field.value ? { label: field.value, value: field.value } : null
                    }
                    onChange={(_, value) => {
                      field.onChange(value ? value.value : "");
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("Faculty")}
                </Typography>
              </Box>
              <Controller
                name="faculty"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      formState.errors[field.name]
                        ? formState.errors[field.name].message
                        : null
                    }
                    error={formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("maxSalary")}
                </Typography>
              </Box>
              <Controller
                name="max_salary"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    type="number"
                    helperText={
                      formState.errors[field.name]
                        ? formState.errors[field.name].message
                        : null
                    }
                    error={formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("minSalary")}
                </Typography>
              </Box>
              <Controller
                name="min_salary"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    type="number"
                    helperText={
                      formState.errors[field.name]
                        ? formState.errors[field.name].message
                        : null
                    }
                    error={formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("midpoint")}
                </Typography>
              </Box>
              <Controller
                name="midpoint_salary"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    type="number"
                    disabled
                    helperText={
                      formState.errors[field.name]
                        ? formState.errors[field.name].message
                        : null
                    }
                    error={formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("JEScore")}
                </Typography>
              </Box>
              <Controller
                name="je_score"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    type="number"
                    helperText={
                      formState.errors[field.name]
                        ? formState.errors[field.name].message
                        : null
                    }
                    error={formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              gap="8px"
              justifyContent="space-between"
            >
              <ButtonBlue variant="text" onClick={onClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={formState.isSubmitting || errorAlert}
              >
                {t("SaveData")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogUpdateJobProfile;
