import React from "react";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Controller } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../../utils/userData";

const ManagerInfo = (params) => {
  const { useForm, registrationMetadata } = params;
  const { t, i18n } = useTranslation();

  const filterOptions = createFilterOptions({
    stringify: (value) =>
      `${getUserFullName(value)} ${value.email}`,
  });

  return (
    <Box>
      <Typography className="title-text">{`${t("Manager")}`}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>{`${t("ManagerLV")}`} 1</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idManagerLV1"
            control={useForm.control}
            render={({ field }) => (
              <Autocomplete
                options={registrationMetadata.managers}
                getOptionLabel={(option) =>
                  `${getUserFullName(option)}`
                }
                filterOptions={filterOptions}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idEmployees}>
                    <Box>
                      <Typography>
                        {getUserFullName(option)}
                      </Typography>
                      <Typography color="text.third" fontSize="14px">
                        {option.email}
                      </Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
                value={
                  registrationMetadata.managers.find(
                    (option) => option.idEmployees === field.value
                  ) || null
                }
                onChange={(_, value) => {
                  field.onChange(value ? value.idEmployees : "");
                }}
                disableClearable
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{`${t("ManagerLV")}`} 2</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idManagerLV2"
            control={useForm.control}
            render={({ field }) => (
              <Autocomplete
                options={registrationMetadata.managers}
                getOptionLabel={(option) =>
                  `${getUserFullName(option)}`
                }
                filterOptions={filterOptions}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idEmployees}>
                    <Box>
                      <Typography>
                        {getUserFullName(option)}
                      </Typography>
                      <Typography color="text.third" fontSize="14px">
                        {option.email}
                      </Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
                value={
                  registrationMetadata.managers.find(
                    (option) => option.idEmployees === field.value
                  ) || null
                }
                onChange={(_, value) => {
                  field.onChange(value ? value.idEmployees : "");
                }}
                disableClearable
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManagerInfo;
