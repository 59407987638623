import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import { AccessTimeRounded } from "@mui/icons-material";
import { updateKpiTeamEvaluate } from "../../../../../actions/kpi";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { useHistory } from "react-router-dom";
import AlertResponse from "../../../shared/general/AlertResponse";
// Translator TH-EN
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Rating from "@mui/material/Rating";
import { Redirect, useLocation } from "react-router-dom";

const StyledRoot = styled(Box)({});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  borderRadius: "30px",
  overflow: "hidden",
  border: "1px solid #EEEEEE",
  display: "flex",
  justifyContent: "center",
  width: "fit-content",
});

const StyledToggleButton = styled(ToggleButton)(({ theme, selectedColor }) => ({
  flex: 1,
  borderRadius: "30px",
  "&.Mui-selected": {
    backgroundColor: selectedColor,
    color: "#FFFFFF",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      borderLeft: "1px solid #EEEEEE",
    },
  },
  "&:hover": {
    backgroundColor: `${selectedColor} !important`,
    color: "#FFFFFF",
  },
  backgroundColor: "#EEEEEE",
  color: "#000000",
  textAlign: "center",
  minWidth: "100px",
  maxWidth: "200px",
}));

const KpiTeamEvaluate = () => {
  const { state: { kpi } } = useLocation();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  if (!kpi) {
    return <Redirect to="/kpi/team" />;
  }

  const [configAlert, setConfigAlert] = useState({
    open: false,
    alertType: "",
  });

  const [ratingValue, setRatingValue] = useState(kpi.rating);

  const handleClose = () => {
    setConfigAlert({ configAlert, open: false });
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      rating: kpi.rating ? kpi.rating : null,
      evaluate: kpi.evaluate ? kpi.evaluate : null,
      feedback: kpi.feedback ? kpi.feedback : "",
    },
  });

  const handleEvaluation = async (formData) => {
    formData.idKpiTeam = kpi.idKpiTeam
    formData.rating = formData.rating || 0;
    formData.feedback = formData.feedback.length > 0 ? formData.feedback : "";

    const res = await dispatch(updateKpiTeamEvaluate(formData) );
    if (res && res.status === 200) {
      await Swal.fire({
        title: t("EvaluationSuccess"),
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      history.replace("/kpi/team");
    } else {
        Swal.fire({
          title: t("AnErrorOccurred"),
          text: t("DataSaveUnsuccessful"),
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
      }
  };

  const onSubmit = async (formData) => {
    try {
      const result = await Swal.fire({
        title: t("ConfirmEvaluation"),
        text: t("AreYouSureToConfirmEvaluation?"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        await handleEvaluation(formData);
      }
    } catch (error) {
      console.error("Error displaying alert:", error);
    }
  };

  return (
    <StyledRoot className="page">
      <Container>
        <div className="cardPlan">
          <Box padding="2px">
            <Typography
              fontSize="40px"
              fontWeight="600"
              style={{ color: "#083A83" }}
            >
              {t("KPIEvaluateDetails")}
            </Typography>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Box>
                <Typography fontSize="22px" marginTop="4px">
                  {kpi.kpiName}
                </Typography>
                <Box display="flex" alignItems="center" marginTop="8px">
                  <AccessTimeRounded
                    fontSize="14px"
                    style={{ marginRight: "8px", color: "#919eab" }}
                  />
                  <Typography
                    fontSize="16px"
                    lineHeight="1"
                    color="text.third"
                  >{`${dayjs(kpi.startDate).format(
                    i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                  )} - ${dayjs(kpi.endDate).format(
                    i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                  )}`}</Typography>
                </Box>
              </Box>
              <Box marginTop="8px" marginBottom="16px">
                <Typography marginBottom="8px">
                  <span
                    style={{
                      color: "#919eab",
                      paddingRight: "8px",
                      fontSize: "16px",
                    }}
                  >{`${t("Scope")}`}</span>
                  {kpi.scope}
                </Typography>
                <Typography marginBottom="8px">
                  <span
                    style={{
                      color: "#919eab",
                      paddingRight: "8px",
                      fontSize: "16px",
                    }}
                  >{`${t("Target")}`}</span>
                  {kpi.target}
                </Typography>
                <Typography marginBottom="8px">
                  <span
                    style={{
                      color: "#919eab",
                      paddingRight: "8px",
                      fontSize: "16px",
                    }}
                  >{`${t("TypeMeasurement")}`}</span>
                  {kpi.measureType}
                </Typography>
                <Typography>
                  <span
                    style={{
                      color: "#919eab",
                      paddingRight: "8px",
                      fontSize: "16px",
                    }}
                  >{`${t("Descriptions")}`}</span>
                  {kpi.description}
                </Typography>
              </Box>
            </Box>
            <Box marginTop="8px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} display="flex" alignItems="center">
                    <Box>
                      <Controller
                        name="evaluate"
                        control={control}
                        render={({ field }) => (
                          <div>
                            <Typography
                              fontSize={16}
                              marginBottom="8px"
                            >{t("Evaluate")}</Typography>
                            <StyledToggleButtonGroup
                              {...field}
                              exclusive
                              onChange={(event, newAlignment) => {
                                field.onChange(newAlignment);
                              }}
                            >
                              <StyledToggleButton
                                value={1}
                                selectedColor="#50B498"
                              >{t("Success")}</StyledToggleButton>
                              <StyledToggleButton
                                value={0}
                                selectedColor="#FF4C4C"
                              >{t("Failure")}</StyledToggleButton>
                            </StyledToggleButtonGroup>
                          </div>
                        )}
                      />
                    </Box>
                    <Box marginLeft="30px">
                      <Typography fontSize={16} marginBottom="4px">
                        {t("Rating")} {ratingValue ? `${ratingValue}` : ""}
                      </Typography>
                      <Controller
                        name="rating"
                        control={control}
                        render={({ field }) => (
                          <Box
                            display="flex"
                            gap="8px"
                            paddingLeft="16px"
                            maxWidth="400px"
                          >
                            <Rating
                              {...field}
                              name="rating"
                              min={1}
                              max={5}
                              precision={1}
                              value={ratingValue}
                              icon={
                                <StarIcon
                                  sx={{
                                    fontSize: "50px",
                                    color: "#FFC403",
                                  }}
                                />
                              }
                              emptyIcon={
                                <StarBorderIcon
                                  sx={{
                                    fontSize: "50px",
                                    color: "#ddd",
                                  }}
                                />
                              }
                              onChange={(_, newValue) => {
                                field.onChange(newValue);
                                setRatingValue(newValue);
                              }}
                            />
                          </Box>
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="feedback"
                      control={control}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          label={t("Feedback")}
                          multiline
                          rows={2}
                          inputProps={{ maxLength: 200 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <ButtonBlue type="submit" variant="contained">
                      {t("Evaluate")}
                    </ButtonBlue>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </div>
        <AlertResponse {...configAlert} handleClose={handleClose} />
      </Container>
    </StyledRoot>
  );
};

export default KpiTeamEvaluate;
