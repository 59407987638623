import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, Breadcrumbs, Container, Grid, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { getAffiliateCompany } from "../../../../../actions/affiliate";
import { getUserCompany, getUserFirstName, getUserFullName, getUserLastName } from "../../../../../utils/userData";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DatePickerCustom from "../../../shared/date/datePicker";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import CardStyle from "../../../shared/general/Card";
import { getLeaveAbsentLatePayroll } from "../../../../../actions/report";
import dayjs from "dayjs";
import loading from "../../../assets/social-media.gif";
import ExcelJS from 'exceljs';

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .search-date-container": {
    marginTop: "24px",
    display: "flex",
    flexWrap: "nowrap",
    gap: "16px",
    "& .search-date-box": {
      maxWidth: "350px",
      width: "100%",
    },
    "& .search-button": {
      marginTop: "29px",
      display: "flex",
      alignItems: "center",
    },
  },

  ["@media only screen and (max-width: 600px)"]: {
    "& .search-date-container": {
      flexDirection: "column",
      "& .search-date-box": {
        maxWidth: "100%",
      },
      "& .search-button": {
        marginTop: "0",
        width: "100%",
      },
    }
  }
})

const StyledCellHeader = styled(TableCell)({
  borderBottom: "0px",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: 8,
  minWidth: 50,
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "10px 0px 10px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 16,
    // borderTopRightRadius: 8,
    // borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "0px",
  padding: 8,
  minWidth: 50,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 8,
  },
  "&:last-of-type": {
    paddingRight: 16,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const LeaveAbsentLatePayroll = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector(state => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  // const { result: affiliateList } = useSelector((state) => state.affiliate);
 
  const { result: ReportLeaveAbsentLatePayrollList, isFetching: isReportLeaveAbsentLatePayrollListFetching } = useSelector(state => state.reportLeaveAbsentLatePayroll);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [reportData, setReportData] = useState(null);

  const [filter, setFilter] = useState({
    year: new Date(dayjs()),
    startMonth: new Date(dayjs().startOf('year')),
    endMonth: new Date(dayjs().endOf('year')),
  })

  const [pagination, setPagination] = useState({
    page: 0,
    count: 0,
    rowsPerPage: 25,
  })

  useEffect(() => {
    if (userProfile) {
      dispatch(getAffiliateOrganization()).then(res => {
        const foundCompany = res.data.find(x => x.idCompany === userProfile.idCompany);
        if (foundCompany) {
          setSelectedCompany(foundCompany)
        } else {
          setSelectedCompany(res.data[0])
        }
      })
    }
  }, [userProfile])

  const handleClickSearch = () => {
    dispatch(getLeaveAbsentLatePayroll({
      idCompany: selectedCompany.idCompany,
      year: dayjs(filter.year).format("YYYY"),
      startMonth: dayjs(filter.startMonth).format("MM"),
      endMonth: dayjs(filter.endMonth).format("MM"),
    })).then(res => {
      if (res && res.status === 200) {
        setReportData(res.data)
        setPagination(prev => ({
          ...prev,
          page: 0,
          count: res.data.employeeList? res.data.employeeList.length : 0
        }))
      } else {
        setReportData({
          employeeList: [],
          leaveList: [],
        })
        setPagination(prev => ({
          ...prev,
          page: 0,
          count: 0
        }))
      }
    })
  }

  const handleChangePage = (_, newPage) => {
    setPagination(prev => ({
      ...prev,
      page: newPage
    }))
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(prev => ({
      ...prev,
      page: 0,
      rowsPerPage: Number(event.target.value),
    }))
  };

  const onDownloadExcel = async () => {

    const workBook = new ExcelJS.Workbook();
    const workSheet = workBook.addWorksheet('Report');
    
    const headerRow = workSheet.addRow([
      t("EmployeeID"),
      t("FirstName"),
      t("LastName"),
      t("PersonalID"),
      t("Absent"),
      `${t("Late")}/${t("LeaveEarly")} (${t("Unit.Minute")})`,
      ...ReportLeaveAbsentLatePayrollList.leaveList.map(x => {
        if(i18n.language === "th"){
          return x.leaveGroupName
        } else {
          return x.leaveGroupName_EN || x.leaveGroupName
        }
      })
    ]);

    headerRow.height = 59;

    const headerCellStyle = {
      font: {
        name: "Tahoma",
        size: 14,
        color: { argb: "FFFFFF" }
      },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: "002060" }
      },
      numFmt: "@",
    }

    const dataCellStyle = {
      font: {
        name: "Tahoma",
        size: 14,
      },
      alignment: {
        vertical: "middle",
        horizontal: "center"
      },
      numFmt: "@",
    }

    headerRow.eachCell((cell) => {
      cell.style = headerCellStyle;
    });

    const colWidths = [
      { key: "employeeID", width: 20 },
      { key: "firstname", width: 20 },
      { key: "lastname", width: 20 },
      { key: "personalID", width: 24 },
      { key: "absent", width: 24 },
      { key: "late", width: 24 },
      ...ReportLeaveAbsentLatePayrollList.leaveList.map(x => ({
        key: `leave_group_${x.idLeaveGroup}`,
        width: 24
      }))
    ];

    ReportLeaveAbsentLatePayrollList.employeeList.map(item => {
      const row = [
        item.employeeID? item.employeeID : "",
        item.firstname_TH? getUserFirstName(item) : "",
        item.lastname_TH? getUserLastName(item) : "",
        item.personalID? item.personalID : "",
        item.absent,
        item.lateAndEarly,
      ];

      ReportLeaveAbsentLatePayrollList.leaveList.map(leave => {
        if (item.leaveUsed[leave.idLeaveGroup]) {
          row.push(Math.round(item.leaveUsed[leave.idLeaveGroup]*100)/100)
        } else {
          row.push(0)
        }
      })

      const excelRow = workSheet.addRow(row);
      excelRow.height = 40;
      colWidths.forEach((col, index) => {
        workSheet.getColumn(index + 1).width = col.width;
      });
      excelRow.eachCell((cell) => {
        cell.style = dataCellStyle;
      });

    })

    workBook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `UniHR ${userProfile && userProfile.roles.includes("ROLE_ADMIN") ? getUserCompany(selectedCompany) : ""} ${t("SummaryOfLeaveAbsenceAndLatenessDataAsOf")} Payroll ${dayjs().format("DD MMMM BBBB HH.mm.ss")}${t("Unit.Minute")}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
  })}

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <Box marginBottom="24px">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{`${t("SummaryOfLeaveAndAbsences")} Payroll`}</Typography>
          </Breadcrumbs>
        </Box>

        <Typography variant="h4">{t("SummaryOfLeaveAndAbsences")} Payroll</Typography>

        <Grid marginTop="24px" container spacing={2}>
          {/* <Grid item xs={12} md={4}>
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("Company")}</Typography>
            <SelectAffiliateCompany
              options={affiliateOrganizationList}
              value={selectedCompany}
              onChange={(_, value) => {
                setSelectedCompany(value)
              }}
              // disabled={isReportLeaveAbsentLateListFetching}
            />
          </Grid> */}

          <Grid item xs={12} md={4}>
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("Company")}</Typography>
            {/* <SelectAffiliateCompany
              options={affiliateOrganizationList}
              value={selectedCompany}
              onChange={(_, value) => {
                setSelectedCompany(value)
              }}
              // disabled={isReportLeaveAbsentLateListFetching}
            /> */}
            <Autocomplete
              options={affiliateOrganizationList? affiliateOrganizationList: []}
              getOptionLabel={(option) => `${getUserCompany(option)}`}
              isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  placeholder={`${t("SelectCompany")}`}
                  // onBlur={field.onBlur}
                  // helperText={fieldState.error? fieldState.error.message: null}
                  // error={fieldState.error? true: false}
                />
              )}
              value={selectedCompany}
              onChange={(_, value) => {
                setSelectedCompany(value)
              }}
              noOptionsText={`${t("NoData")}`}
              disableClearable
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("Year")}</Typography>
                <DatePickerCustom
                  inputFormat="YYYY"
                  value={filter.year}
                  views={['year']}
                  openTo={'year'}
                  inputProps={{
                    readOnly: true
                  }}
                  onChange={(newValue) => {
                    setFilter(prev => ({
                      ...prev,
                      year: newValue,
                      startMonth: dayjs(prev.startMonth).set('year', dayjs(newValue).year()),
                      endMonth: dayjs(prev.endMonth).set('year', dayjs(newValue).year()),
                    }));
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      // helperText={
                      //   errors.endDate && errors.endDate.message
                      // }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("StartMonth")}</Typography>
                <DatePickerCustom
                  inputFormat="MMMM"
                  value={filter.startMonth}
                  views={['month']}
                  openTo={'month'}
                  inputProps={{
                    readOnly: true
                  }}
                  onChange={(newValue) => {
                    setFilter(prev => ({
                      ...prev,
                      startMonth: newValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      // helperText={
                      //   errors.endDate && errors.endDate.message
                      // }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("EndMonth")}</Typography>
                <DatePickerCustom
                  inputFormat="MMMM"
                  value={filter.endMonth}
                  views={['month']}
                  openTo={'month'}
                  inputProps={{
                    readOnly: true
                  }}
                  onChange={(newValue) => {
                    setFilter(prev => ({
                      ...prev,
                      endMonth: newValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      // helperText={
                      //   errors.endDate && errors.endDate.message
                      // }
                    />
                  )}
                />
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" gap="8px">
              <ButtonBlue
                startIcon={<SearchRoundedIcon />}
                variant="contained"
                onClick={handleClickSearch}
                disabled={(dayjs(filter.startMonth).isAfter(dayjs(filter.endMonth), 'month'))}
              >
                {t("Search")}
              </ButtonBlue>
              <ButtonBlue
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                onClick={onDownloadExcel}
                disabled={!ReportLeaveAbsentLatePayrollList 
                  || !ReportLeaveAbsentLatePayrollList.employeeList
                }
              >
                {t("Download")}
              </ButtonBlue>
            </Box>
          </Grid>
        </Grid>

        {reportData && <Box marginTop="24px">
          {!isReportLeaveAbsentLatePayrollListFetching? (<CardStyle>
            <Box padding="24px">
              <Box style={{ display: "flex", position: "relative" }}>
                <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      <TableRow>
                        <StyledCellHeader
                          align={"center"}
                          className="sticky"
                          style={{ minWidth: 240 }}
                          rowSpan={2}
                        >
                          {`${t("FullName")}`}
                        </StyledCellHeader>
                        <StyledCellHeader align="center" style={{ minWidth: 100, whiteSpace: "nowrap" }}>{`${t("Absent")}`}</StyledCellHeader>
                        <StyledCellHeader align="center" style={{ minWidth: 150, whiteSpace: "nowrap" }}>{`${t("Late")}/${t("LeaveEarly")}`} ({t("Unit.Minute")})</StyledCellHeader>

                        {reportData.leaveList && reportData.leaveList.map(leave => (
                          <StyledCellHeader key={leave.idLeaveGroup} align="center" style={{ minWidth: 150, width: "100%", whiteSpace: "nowrap" }}>{i18n.language === "th"? leave.leaveGroupName: (leave.leaveGroupName_EN || leave.leaveGroupName)}</StyledCellHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportData.employeeList && reportData.employeeList.slice((pagination.page * pagination.rowsPerPage), ((pagination.page + 1) * pagination.rowsPerPage)).map((item, index) => (
                        <StyledRowContent>
                          <StyledCellContent
                            key={`${index}`}
                            className="sticky"
                            style={{
                              borderBottom:
                                "1px dashed rgba(224, 224, 224, 1)",
                            }}
                          >
                            <Box className="avatar">
                              {/* <Avatar src="" /> */}
                              <Typography className="fullname">
                                {`${getUserFullName(item) ? getUserFullName(item) : item.firstname_TH && item.lastname_TH ? item.firstname_TH + " " + item.lastname_TH : " "}`}
                              </Typography>
                            </Box>
                          </StyledCellContent>

                          <StyledCellContent align="center">{item.absent}</StyledCellContent>
                          <StyledCellContent align="center">{item.lateAndEarly}</StyledCellContent>

                          {reportData.leaveList.map(leave => (
                            <StyledCellContent key={leave.idLeaveGroup} align="center" style={{ minWidth: 140 }}>{item.leaveUsed[leave.idLeaveGroup]? Math.round(item.leaveUsed[leave.idLeaveGroup]*100)/100 : 0}</StyledCellContent>
                          ))}

                        </StyledRowContent>
                      ))}
                      {/* {ReportLeaveAbsentLatePayrollList.employeeList && ReportLeaveAbsentLatePayrollList.employeeList.length === 0 && (
                        <StyledRowContent>
                          <StyledCellContent
                            // key={`${index}`}
                            className="sticky"
                            style={{
                              borderBottom: "1px dashed rgba(224, 224, 224, 1)",
                            }}
                          >
                            <Box className="avatar">

                            </Box>
                          </StyledCellContent>

                          <StyledCellContent align="center" colSpan={2+ReportLeaveAbsentLatePayrollList.leaveList.length}>{"No Data"}</StyledCellContent>

                        </StyledRowContent>
                      )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              
              {reportData.employeeList && reportData.employeeList.length === 0 && (
                <Box
                  height="250px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography>{t("NoData")}</Typography>
                </Box>
              )}

              <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                count={pagination.count}
                rowsPerPage={25}
                page={pagination.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={`${t("RowsPerPage")}`}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`
                  }`
                }
              />
            </Box>
          </CardStyle>) : (

          // <Typography textAlign="center" color="text.secondary" fontSize="24px">
          //   {t(`LoadingData`)}...
          // </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img alt="loading" src={loading} style={{ width: 100, height: 100 }} />
          </Box>

          )}
        </Box>}

      </Container>
    </StyledRoot>
  )
}

export default LeaveAbsentLatePayroll;