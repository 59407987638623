import React from "react";
import { styled } from "@mui/styles";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import myKPI from "../../admin/kpis/assets/myKPI.png";
import updateKPI from "../../admin/kpis/assets/updateKPI.png";
import evaluationKPI from "../../admin/kpis/assets/approveKPI.png";
import approveKPI from "../../admin/kpis/assets/approveKPI.png";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#f5f5f5 !important",
  paddingBottom: 50,
});

const StyledClickablePaper = styled(Paper)({
  width: "auto",
  height: "30vh",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

function KpiUser() {
  const { t } = useTranslation();
  const menuItems = [
    { label: t("MyKPI"), path: "kpi-individual", icon: myKPI },
    { label: t("KPIPlan"), path: "kpi-individual-planing", icon: updateKPI },
    { label: t("KPIUpdate"), path: "kpi-individual-update", icon: updateKPI },
    {
      label: t("KPIEvaluation"),
      path: "kpi-evaluation-list",
      icon: evaluationKPI,
    },
    {
      label: t("OrganizationKPI"),
      path: "kpi/organization/user",
      icon: approveKPI,
    },
    { label: t("TeamKPI"), path: "kpi/team/user", icon: approveKPI },
  ];

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          {menuItems.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Link to={item.path} style={{ textDecoration: "none" }}>
                <StyledClickablePaper>
                  <img
                    src={item.icon}
                    alt="icon"
                    style={{
                      width: "50%",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography fontSize={"1.2rem"} align="center">
                    {item.label}
                  </Typography>
                </StyledClickablePaper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledRoot>
  );
}
export default KpiUser;
