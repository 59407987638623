import ExcelJS from "exceljs";
import dayjs from "dayjs";

export const IrregularitiesLeaveAbsentLateXlsx = async (t, data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(t("IrregularityLeaveAbsentLateReport"));

  const headers = [
    t("EmployeeID"),
    t("FirstName"),
    t("LastName"),
    t("Division"),
    t("Section"),
    t("Position"),
    t("Department"),
    t("Date"),
    t("Irregularities"),
    `${t("Amount")} (${t("Unit.Minute")})`,
  ];

  const headerRow = worksheet.addRow(headers);
  headerRow.height = 50;

  const headerStyle = {
    font: { bold: true, size: 16, name: "TH SarabunPSK", color: { argb: "FFFFFF" } },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "002060" } },
  };

  headerRow.eachCell((cell) => {
    cell.style = headerStyle;
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  const colWidths = [
    { key: "employeeID", width: 20 },
    { key: "firstname", width: 25 },
    { key: "lastname", width: 25 },
    { key: "divisionName", width: 25 },
    { key: "sectionName", width: 25 },
    { key: "positionName", width: 25 },
    { key: "departmentName", width: 25 },
    { key: "date", width: 15 },
    { key: "irregularities", width: 40 },
    { key: "amountMinutes", width: 20 },
  ];

  colWidths.forEach((col, index) => {
    worksheet.getColumn(index + 1).width = col.width;
  });

  data.forEach((employee) => {
    const rowData = [
      employee.employeeID,
      employee.firstname_EN,
      employee.lastname_EN,
      employee.divisionName || "-",
      employee.sectionName || "-",
      employee.positionName || "-",
      employee.departmentName || "-",
      dayjs(employee.date).format("YYYY-MM-DD"),
      employee.irregularities || "-",
      employee.amountMinutes || "-",
    ];

    const excelRow = worksheet.addRow(rowData);

    const contentStyle = {
      font: { size: 14, name: "TH SarabunPSK", bold: false },
      alignment: { horizontal: "center", vertical: "middle" },
    };

    excelRow.eachCell((cell) => {
      cell.style = contentStyle;
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${t("IrregularityLeaveAbsentLateReport")}.xlsx`;
  link.click();
};
