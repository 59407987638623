import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { getOnBoardingEmployeeByReviewer } from "../../../../../actions/onBoarding";
import { useTranslation } from "react-i18next";
import SnackBarCustom from "../../../shared/snackbarCustom";
import Dialog from "./Dialog";

const OnBoardingPending = ({ handleOpenHistory, handleOpenDialog }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: onBoardingEmployee } = useSelector(
    (state) => state.onBoardingEmployee
  );

  useEffect(() => {
    dispatch(getOnBoardingEmployeeByReviewer());
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Typography fontSize={18} fontWeight={500}>{t("PendingApproval")}</Typography>
        <ButtonBlue variant="contained" onClick={handleOpenHistory}>
          {t("ApprovalHistory")}
        </ButtonBlue>
      </div>
      {onBoardingEmployee && onBoardingEmployee.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("EmployeeName")}</TableCell>
                <TableCell align="center">{t("Position")}</TableCell>
                <TableCell align="center">{t("Company")}</TableCell>
                <TableCell align="center">{t("Department")}</TableCell>
                <TableCell align="center">{t("Section")}</TableCell>
                <TableCell align="center">{t("TitleName")}</TableCell>
                <TableCell align="center">{`${t("ProceedInternally")} (${t(
                  "Unit.Days"
                )})`}</TableCell>
                <TableCell align="center">{t("Status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {onBoardingEmployee.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    {`${row.firstname_TH}  ${row.lastname_TH}`}{" "}
                  </TableCell>
                  <TableCell align="center">{row.positionName} </TableCell>
                  <TableCell align="center">{row.companyName} </TableCell>
                  <TableCell align="center">{row.departmentName} </TableCell>
                  <TableCell align="center">{row.sectionName} </TableCell>
                  <TableCell align="center">
                    {row.OnBoardingListName}{" "}
                  </TableCell>
                  <TableCell align="center">{row.day}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleOpenDialog(row)}>
                      <CancelOutlinedIcon style={{ color: "red" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>{t("NoData")}</Typography>
      )}
    </>
  );
};

export default OnBoardingPending;
