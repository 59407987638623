import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import {
  Container,
  Typography,
  CardContent,
  TextField,
  Grid,
  Popper,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import StyledCard from "../../../shared/general/Card";
import Backdrop from "../../../shared/general/Backdrop";

import General from "./general";
import Welfare from "./welfare";
import PerDiem from "./per-diem";
import Mileage from "./mileage";
import FlexCoin from "./flex-coin";
import Advance from "./advance";
import ClearAdvance from "./clear-advance";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getEmployeesRoleAdmin } from "../../../../../actions/admins";
import { getExpenseSetting } from "../../../../../actions/expenseSetting";
import { getExpenseTypes } from "../../../../../actions/expense";

const StyledRoot = styled("div")({
  maxWidth: "1280px !important",
  margin: "auto",
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
    },
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .MuiFormControl-root": {
    marginBottom: 8,
  },
  "& .wrap-head": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-row-sum": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const StyledAutocompleteFilled = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 48,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const ExpenseUserCreatePage = () => {
  const { t, i18n } = useTranslation();
  const { result: expenseTypes } = useSelector((state) => state.expenseTypes);
  const { result: expenseSetting } = useSelector(
    (state) => state.expenseSetting
  );
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    typeExpenseName: t("ServiceAndGoods"),
    idExpenseType: 1,
  });

  useEffect(() => {
    dispatch(getEmployeesRoleAdmin());
    if (!expenseTypes) {
      dispatch(getExpenseTypes());
    }
    dispatch(getExpenseSetting());
  }, []);

  return (
    <>
      {expenseTypes && expenseSetting ? (
        <StyledRoot className="page">
          <Container maxWidth="xl">
            <div className="head">
              <Typography variant="h4" gutterBottom>
                {t("CreateExpense")}
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <StyledAutocompleteFilled
                    fullWidth
                    name="typeExpenseName"
                    value={formData.typeExpenseName}
                    options={expenseTypes}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setFormData({
                          typeExpenseName: t(newValue.name),
                          idExpenseType: newValue.idExpenseType,
                        });
                      } else {
                        setFormData({
                          typeExpenseName: t(expenseTypes[0].name),
                          idExpenseType: expenseTypes[0].idExpenseType,
                        });
                      }
                    }}
                    popupIcon={<i class="fa-light fa-chevron-down"></i>}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.idExpenseType}>
                          {t(option.name)}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        name="typeExpenseName"
                      />
                    )}
                    PopperComponent={StyledPopper}
                    noOptionsText={t("NoData")}
                  />
                </Grid>
              </Grid>
            </div>
            <StyledCard>
              <StyledCardContent>
                {formData.idExpenseType === 1 && (
                  <General
                    typeExpense={formData.typeExpenseName}
                    setting={expenseSetting}
                  />
                )}
                {formData.idExpenseType === 2 && (
                  <PerDiem
                    typeExpense={formData.typeExpenseName}
                    setting={expenseSetting}
                  />
                )}
                {formData.idExpenseType === 3 && (
                  <Mileage
                    typeExpense={formData.typeExpenseName}
                    setting={expenseSetting}
                  />
                )}
                {formData.idExpenseType === 4 && (
                  <Welfare
                    typeExpense={formData.typeExpenseName}
                    setting={expenseSetting}
                  />
                )}
                {formData.idExpenseType === 5 && (
                  <FlexCoin
                    typeExpense={formData.typeExpenseName}
                    setting={expenseSetting}
                  />
                )}
                {formData.idExpenseType === 6 && (
                  <Advance
                    typeExpense={formData.typeExpenseName}
                    setting={expenseSetting}
                  />
                )}
                {formData.idExpenseType === 7 && (
                  <ClearAdvance
                    typeExpense={formData.typeExpenseName}
                    setting={expenseSetting}
                  />
                )}
              </StyledCardContent>
            </StyledCard>
          </Container>
        </StyledRoot>
      ) : (
        <Backdrop open={true} />
      )}
    </>
  );
};

export default ExpenseUserCreatePage;
