import {
  EXPENSE_FETCHING,
  EXPENSE_FAILED,
  EXPENSE_SUCCESS,
  EXPENSE_DETAIL_FETCHING,
  EXPENSE_DETAIL_FAILED,
  EXPENSE_DETAIL_SUCCESS,
  EXPENSE_PAYMENT_TYPES_FETCHING,
  EXPENSE_PAYMENT_TYPES_FAILED,
  EXPENSE_PAYMENT_TYPES_SUCCESS,
  EXPENSE_RETURNADVANCE_TYPES_FETCHING,
  EXPENSE_RETURNADVANCE_TYPES_FAILED,
  EXPENSE_RETURNADVANCE_TYPES_SUCCESS,
  EXPENSE_TYPES_FETCHING,
  EXPENSE_TYPES_FAILED,
  EXPENSE_TYPES_SUCCESS,
  EXPENSE_ADVANCES_FETCHING,
  EXPENSE_ADVANCES_FAILED,
  EXPENSE_ADVANCES_SUCCESS,
} from "./types";

import ExpenseService from "../services/expense.service";

export const clearExpense = () => async (dispatch) => {
  dispatch({
    type: EXPENSE_SUCCESS,
    payload: null,
  });
};

export const getAllExpense = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_FETCHING,
    });
    const res = await ExpenseService.getAllExpense();
    if (res) {
      dispatch({
        type: EXPENSE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_FAILED,
    });
    return error;
  }
};

export const getExpenseGeneralById = (idExpense) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_DETAIL_FETCHING,
    });
    const res = await ExpenseService.getExpenseGeneralById(idExpense);
    if (res) {
      dispatch({
        type: EXPENSE_DETAIL_SUCCESS,
        payload: res.data,
      });
      //return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_DETAIL_FAILED,
    });
    return error;
  }
};

export const addExpenseGeneral = (formData) => async () => {
  try {
    const res = await ExpenseService.addExpenseGeneral(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateFileExpense = (formData) => async () => {
  try {
    const res = await ExpenseService.updateFileExpense(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateStatusExpense = (idExpense, data) => async () => {
  try {
    const res = await ExpenseService.updateStatusExpense(idExpense, data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateExpenseGeneral = (formData) => async () => {
  try {
    const res = await ExpenseService.updateExpenseGeneral(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getExpenseAllowanceById = (idExpense) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_DETAIL_FETCHING,
    });
    const res = await ExpenseService.getExpenseAllowanceById(idExpense);
    if (res) {
      dispatch({
        type: EXPENSE_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_DETAIL_FAILED,
    });
    return error;
  }
};

export const addExpenseAllowance = (formData) => async () => {
  try {
    const res = await ExpenseService.addExpenseAllowance(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateExpenseAllowance = (formData) => async () => {
  try {
    const res = await ExpenseService.updateExpenseAllowance(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getExpenseMileageById = (idExpense) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_DETAIL_FETCHING,
    });
    const res = await ExpenseService.getExpenseMileageById(idExpense);
    if (res) {
      dispatch({
        type: EXPENSE_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_DETAIL_FAILED,
    });
    return error;
  }
};

export const addExpenseMileage = (formData) => async () => {
  try {
    const res = await ExpenseService.addExpenseMileage(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateExpenseMileage = (formData) => async () => {
  try {
    const res = await ExpenseService.updateExpenseMileage(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getExpenseWelfareById = (idExpense) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_DETAIL_FETCHING,
    });
    const res = await ExpenseService.getExpenseWelfareById(idExpense);
    if (res) {
      dispatch({
        type: EXPENSE_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_DETAIL_FAILED,
    });
    return error;
  }
};

export const addExpenseWelfare = (formData) => async () => {
  try {
    const res = await ExpenseService.addExpenseWelfare(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateExpenseWelfare = (formData) => async () => {
  try {
    const res = await ExpenseService.updateExpenseWelfare(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getExpenseFlexCoinById = (idExpense) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_DETAIL_FETCHING,
    });
    const res = await ExpenseService.getExpenseFlexCoinById(idExpense);
    if (res) {
      dispatch({
        type: EXPENSE_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_DETAIL_FAILED,
    });
    return error;
  }
};

export const addExpenseFlexCoin = (formData) => async () => {
  try {
    const res = await ExpenseService.addExpenseFlexCoin(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateExpenseFlexCoin = (formData) => async () => {
  try {
    const res = await ExpenseService.updateExpenseFlexCoin(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseGeneral = (data) => async () => {
  try {
    const res = await ExpenseService.deleteExpenseGeneral(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseMileage = (data) => async () => {
  try {
    const res = await ExpenseService.deleteExpenseMileage(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseAllowance = (data) => async () => {
  try {
    const res = await ExpenseService.deleteExpenseAllowance(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseWelfare = (data) => async () => {
  try {
    const res = await ExpenseService.deleteExpenseWelfare(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseFlexCoin = (data) => async () => {
  try {
    const res = await ExpenseService.deleteExpenseFlexCoin(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getAllExpenseByIdCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_FETCHING,
    });
    const res = await ExpenseService.getAllExpenseByIdCompany(idCompany);
    if (res) {
      dispatch({
        type: EXPENSE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_FAILED,
    });
    return error;
  }
};

export const getHistoryExpenseByIdCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_FETCHING,
    });
    const res = await ExpenseService.getHistoryExpenseByIdCompany(idCompany);
    if (res) {
      dispatch({
        type: EXPENSE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_FAILED,
    });
    return error;
  }
};

export const getFilteredHistoryExpenseAccountant =
  (data) => async (dispatch) => {
    try {
      dispatch({
        type: EXPENSE_FETCHING,
      });
      const res = await ExpenseService.getFilteredHistoryExpenseAccountant(
        data
      );
      if (res) {
        dispatch({
          type: EXPENSE_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (error) {
      dispatch({
        type: EXPENSE_FAILED,
      });
      return error;
    }
  };

export const getAllExpenseByIdApprover = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_FETCHING,
    });
    const res = await ExpenseService.getAllExpenseByIdApprover();
    if (res) {
      dispatch({
        type: EXPENSE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_FAILED,
    });
    return error;
  }
};

export const getHistoryExpenseForApprover = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_FETCHING,
    });
    const res = await ExpenseService.getHistoryExpenseForApprover();
    if (res) {
      dispatch({
        type: EXPENSE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_FAILED,
    });
    return error;
  }
};

export const getFilteredHistoryExpenseApprover = (data) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_FETCHING,
    });
    const res = await ExpenseService.getFilteredHistoryExpenseApprover(data);
    if (res) {
      dispatch({
        type: EXPENSE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_FAILED,
    });
    return error;
  }
};

export const getAllExpenseByIdReviewer = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_FETCHING,
    });
    const res = await ExpenseService.getAllExpenseByIdReviewer();
    if (res) {
      dispatch({
        type: EXPENSE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_FAILED,
    });
    return error;
  }
};

export const addExpenseAdvance = (formData) => async () => {
  try {
    const res = await ExpenseService.addExpenseAdvance(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateExpenseAdvance = (formData) => async () => {
  try {
    const res = await ExpenseService.updateExpenseAdvance(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseAdvance = (data) => async () => {
  try {
    const res = await ExpenseService.deleteExpenseAdvance(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getExpenseAdvanceById = (idExpense) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_DETAIL_FETCHING,
    });
    const res = await ExpenseService.getExpenseAdvanceById(idExpense);
    if (res) {
      dispatch({
        type: EXPENSE_DETAIL_SUCCESS,
        payload: res.data,
      });
      //return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_DETAIL_FAILED,
    });
    return error;
  }
};

export const getExpensePaymentTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_PAYMENT_TYPES_FETCHING,
    });
    const res = await ExpenseService.getExpensePaymentTypes();
    if (res) {
      dispatch({
        type: EXPENSE_PAYMENT_TYPES_SUCCESS,
        payload: res.data,
      });
      //return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_PAYMENT_TYPES_FAILED,
    });
    return error;
  }
};

export const getExpenseReturnAdvanceTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_RETURNADVANCE_TYPES_FETCHING,
    });
    const res = await ExpenseService.getExpenseReturnAdvanceTypes();
    if (res) {
      dispatch({
        type: EXPENSE_RETURNADVANCE_TYPES_SUCCESS,
        payload: res.data,
      });
      //return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_RETURNADVANCE_TYPES_FAILED,
    });
    return error;
  }
};

export const getExpenseTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_TYPES_FETCHING,
    });
    const res = await ExpenseService.getExpenseTypes();
    if (res) {
      dispatch({
        type: EXPENSE_TYPES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_TYPES_FAILED,
    });
    return error;
  }
};

export const getExpenseAdvances = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_ADVANCES_FETCHING,
    });
    const res = await ExpenseService.getExpenseAdvances();
    if (res) {
      dispatch({
        type: EXPENSE_ADVANCES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_ADVANCES_FAILED,
    });
    return error;
  }
};

export const addExpenseClearAdvance = (formData) => async () => {
  try {
    const res = await ExpenseService.addExpenseClearAdvance(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateExpenseClearAdvance = (formData) => async () => {
  try {
    const res = await ExpenseService.updateExpenseClearAdvance(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseClearAdvance = (data) => async () => {
  try {
    const res = await ExpenseService.deleteExpenseClearAdvance(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getExpenseClearAdvanceById = (idExpense) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_DETAIL_FETCHING,
    });
    const res = await ExpenseService.getExpenseClearAdvanceById(idExpense);
    if (res) {
      dispatch({
        type: EXPENSE_DETAIL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_DETAIL_FAILED,
    });
    return error;
  }
};
