import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { Typography, Alert, Stack } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import {
  getAffiliateOrganization,
  getCompanyOrganizationList,
} from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useTranslation } from "react-i18next";
import { getProvidentFundUpdate } from "../../../../actions/report";
import { ProvidentFundUpdateXlsx } from "./xlsx-export/provident-fund-update-xlsx";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const ProvidentFundUpdate = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose } = props;
  const { control } = useForm({});
  const { t } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [noData, setNoData] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [exportExcel, setExportExcel] = useState(null);

  const handleOpenAlertError = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const onChangeCompany = (newValue) => setSelectedCompany(newValue);

  const fetchData = async () => {
    if (!selectedCompany) return;
    try {
      setIsFetching(true);
      const response = await getProvidentFundUpdate({ idCompany: selectedCompany.idCompany });
  
      if (response && response.data && response.data.length > 0) {
        setNoData(false);
        setExportExcel(response.data);
      } else {
        setNoData(true);
        handleOpenAlertError();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      handleOpenAlertError();
    } finally {
      setIsFetching(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [selectedCompany]);

  const handleFileExport = () => {
    if (exportExcel && exportExcel.length > 0) {
      ProvidentFundUpdateXlsx(t, exportExcel);
    } else {
      handleOpenAlertError();
    }
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, [dispatch]);

  
  useEffect(() => {
    if (userProfile && affiliateOrganizationList.length > 0) {
      const defaultCompany =
        affiliateOrganizationList.find((x) => x.idCompany === userProfile.idCompany) ||
        affiliateOrganizationList[0];
      setSelectedCompany(defaultCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  return (
    <DrawerCustom title={t("ProvidentFundUpdateReport")} anchor="right" open={open} onClose={handleClose}>
      <StyledRoot style={{ width: 400 }}>
        <Stack spacing={4}>
          {openAlert && (
            <Alert severity="error" onClose={handleCloseAlert}>
              {t("NoData")}
            </Alert>
          )}
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                {t("Company")}
              </Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                disabled={isFetching || openAlert}
                onChange={(_, value) => onChangeCompany(value)}
              />
            </Stack>
          </Stack>

          <ButtonBlue variant="contained" disabled={isFetching || noData} onClick={handleFileExport}>
            {t("Download")}
          </ButtonBlue>
        </Stack>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default ProvidentFundUpdate;
