import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const DialogExitInterview = (props) => {
  const { open, handleClose, idEdu, type, value, detail } = props;
  const { t, i18n } = useTranslation();
  return (
    <DrawerCustom
      title={t("ResignationForm")}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ padding: "24px" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={7}>
                <Typography sx={{ display: "inline-block" }}>
                  <i className="fa-regular fa-user fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {` ${t("Maker")} : ${getUserFullName(value)
                  }`}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography sx={{ display: "inline-block" }}>
                  <i className="fa-regular fa-building-columns fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {`${t("Position")} : ${getUserPosition(value)}`}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography sx={{ display: "inline-block" }}>
                  <i className="fa-light fa-whistle fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {`${t("ApprovedBy")} : ${getUserFullName(detail.Manager)
                  }`}{" "}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ paddingLeft: "24px" }}>
            {detail.GroupExitInterview.map((data, index) => (
              <Grid key={data.id} container className="GridContact">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  marginTop={2}
                  className="GridTopicInput"
                >
                  <Grid item xs={12} sm={12}>
                    <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                    &nbsp;&nbsp;
                    {`${t("Question")} ${index + 1} : ${data.question}`}
                  </Grid>
                </Grid>
                <Grid item xs={12} marginTop={1} sm={12}>
                  <Fragment>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={data.answer}
                    >
                      <FormControlLabel
                        value="verygood"
                        control={<Radio />}
                        label={t("Excellence")}
                      />
                      <FormControlLabel
                        value="good"
                        control={<Radio />}
                        label={t("Good")}
                      />
                      <FormControlLabel
                        value="meduim"
                        control={<Radio />}
                        label={t("Neutral")}
                      />
                      <FormControlLabel
                        value="fair"
                        control={<Radio />}
                        label={t("Fair")}
                      />
                      <FormControlLabel
                        value="prettybad"
                        control={<Radio />}
                        label={t("Poor")}
                      />
                      <FormControlLabel
                        value="bad"
                        control={<Radio />}
                        label={t("Bad")}
                      />
                    </RadioGroup>
                  </Fragment>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginTop: "16px",
                      borderColor: "#9e9e9e66",
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ paddingLeft: "24px" }}>
            <Grid container className="GridContact">
              <Grid
                item
                xs={12}
                sm={12}
                marginTop={2}
                className="GridTopicInput"
              >
                <Grid item xs={12} sm={12}>
                  {t("Other")}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} marginBottom={2} sm={12}>
                <TextFieldTheme
                  multiline
                  rows={3}
                  value={detail.others}
                ></TextFieldTheme>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                marginTop={2}
                className="GridTopicInput"
              >
                <Grid item xs={12} sm={12}>
                  {t("ResignationReason")}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <TextFieldTheme value={detail.reason}></TextFieldTheme>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                marginTop={2}
                className="GridTopicInput"
              >
                <Grid item xs={12} sm={12}>
                  {t("EffectiveDate")}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <TextFieldTheme
                  value={dayjs(detail.dateStart).format("D MMM BBBB")}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <StyledFooter>
          <ButtonBlue
            className="cancel"
            onClick={handleClose}
            startIcon={<ArrowBackIcon />}
          >
            {t("Back")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogExitInterview;
