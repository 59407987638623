import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { Divider, Grid, Stack, Typography, styled } from "@mui/material";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { updateUser } from "../../../../../../actions/user";
import { getReasonExitInterview } from "../../../../../../actions/exitInterview";
import DrawerEdit from "./DrawerEdit";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const initialValues = () => ({
  empId: 0,
  effectiveDate: null,
  reason: "",
});

const Terminate = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { t } = useTranslation();

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false
  })

  const handleCloseDrawer = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  };

  const handleOpenDrawer = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
    }))
  };

  const handleCancelTerminate = async () => {
    const formData = {
      resignDate: null,
      reasonResign: null,
      reasonResignOther: null,
      isBlacklist: false,
      idEmployees: employeeProfile.idEmployees,
    };

    const result = await dispatch(updateUser(formData));

    if (result) {
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  useEffect(() => {
    dispatch(getReasonExitInterview());
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            {t("ContractEndDate")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.resignDate
              ? dayjs(employeeProfile.resignDate).format("DD MMMM BBBB")
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            {t("Description")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.reasonResign ? employeeProfile.reasonResign : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            {t("ReasonOther")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.reasonResignOther
              ? employeeProfile.reasonResignOther
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <StyledDivider />
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        {!userProfile.readOnly && (
          <ButtonBlue size="large" variant={"contained"} onClick={handleOpenDrawer}>
            {t("Add")}/{t("EditResignInfo")}
          </ButtonBlue>
        )}
        {employeeProfile.resignDate && (
          <ButtonBlue variant={"outlined"} onClick={handleCancelTerminate}>
            {t("Cancel")}
          </ButtonBlue>
        )}
      </Stack>
      <DrawerEdit
        drawerConfig={drawerConfig}
        handleClose={handleCloseDrawer}
      />
    </div>
  );
}

export default Terminate;
