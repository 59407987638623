import React from "react";
import {
  Typography,
  Stack,
  Dialog,
  styled,
  Divider,
  DialogContent,
  DialogActions,
  DialogTitle,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const DialogKpiDetail = ({ open, handleClose, selectedKpi }) => {
  const { t, i18n } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle bgcolor={"#4f65df"} color={"#ffffff"}>
        {t("KPIDetail")}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} marginTop={2}>
          <Typography color={"#083A83"} fontSize={24} fontWeight={500}>
            {selectedKpi.kpiName}
          </Typography>
          <Typography>
            <span
              style={{
                color: "#083A83",
                paddingRight: "8px",
                fontSize: "18px",
              }}
            >
              {t("Scope")} :
            </span>
            {selectedKpi.scope}
          </Typography>
          <StyledDivider />
          <Typography marginBottom="8px">
            <span
              style={{
                color: "#083A83",
                paddingRight: "8px",
                fontSize: "18px",
              }}
            >
              {t("Target")} :
            </span>
            {selectedKpi.target}
          </Typography>
          <StyledDivider />
          <Typography marginBottom="8px">
            <span
              style={{
                color: "#083A83",
                paddingRight: "8px",
                fontSize: "18px",
              }}
            >
              {t("Evidence")} :
            </span>
            {selectedKpi.evidence}
          </Typography>
          <StyledDivider />
          <Typography marginBottom="8px">
            <span
              style={{
                color: "#083A83",
                paddingRight: "8px",
                fontSize: "18px",
              }}
            >
              {t("Descriptions")} :
            </span>
            {selectedKpi.description}
          </Typography>
          <StyledDivider />
          <Typography marginBottom="8px">
            <span
              style={{
                color: "#083A83",
                paddingRight: "8px",
                fontSize: "18px",
              }}
            >
              {t("Duration")} :
            </span>
            {`${dayjs(selectedKpi.startDate).format(
              i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
            )} - ${dayjs(selectedKpi.endDate).format(
              i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
            )}`}
          </Typography>
          <Typography marginBottom="8px">
            <span
              style={{
                color: "#083A83",
                paddingRight: "8px",
                fontSize: "18px",
              }}
            >
              {t("TypeMeasurement")} :
            </span>
            {selectedKpi.measureType}
          </Typography>
          <Typography marginBottom="8px">
            <span
              style={{
                color: "#083A83",
                paddingRight: "8px",
                fontSize: "18px",
              }}
            >
              {t("Weight")} :
            </span>
            {selectedKpi.weight}
          </Typography>
          {selectedKpi.evaluate === 1 ? (
            <Chip sx={{ backgroundColor: "#50B498", color: "#ffffff", width: "fit-content" }} label={t("Success")} />
          ) : selectedKpi.evaluate === 0 ? (
            <Chip sx={{ backgroundColor: "#E46A76", color: "#ffffff", width: "fit-content" }} label={t("Failure")}/>
          ) : (
            <Chip label={t("HaveNotBeenEvaluated")} sx={{ width: "fit-content" }}/>
          )}
          {selectedKpi.evaluate !== null ? (
            <>
              <Typography marginBottom="8px">
                <span
                  style={{
                    color: "#083A83",
                    paddingRight: "8px",
                    fontSize: "18px",
                  }}
                >
                  {t("Rating")} :
                </span>
                {selectedKpi.rating}
              </Typography>
              <Typography marginBottom="8px">
                <span
                  style={{
                    color: "#083A83",
                    paddingRight: "8px",
                    fontSize: "18px",
                  }}
                >
                  {t("Feedback")} :
                </span>
                {selectedKpi.feedback}
              </Typography>
            </>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonBlue onClick={handleClose}>{t("Close")}</ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogKpiDetail;
