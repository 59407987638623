import React, { useState, useEffect } from "react";
import { Box, Container, Typography, Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import AdvanceSearch from "./components/AdvanceSearch";
import DataTable from "./components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { getEmemoRequestFormById } from "../../../../actions/ememo";
import RequestDetailsModal from "./components/RequestDetailsModal";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  padding: "20px",
});

const CardStyled = styled(Card)({
  marginTop: 16,
  marginBottom: 16,
  padding: 16,
  border: "none",
  borderRadius: 16,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  "& .MuiCardContent-root": {
    padding: 24,
    borderRadius: 16,
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  borderRadius: 16,
});

const EMemoRequestlistAdminPage = () => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState({
    documentNo: "",
    creator: "",
    requester: "",
    approver: "",
    createdDateFrom: dayjs().startOf('month').toDate(),
    createdDateTo: dayjs().endOf('month').toDate(),
    subject: "",
    eMemoType: "",
    status: "",
    classificationLevels: "",
    priority: "",
    initial1: "",
    initial2: "",
    // initial3: "",
  });

  const dispatch = useDispatch();
  const { eMemos } = useSelector((state) => state.eMemos);
  const [filteredEMemos, setFilteredEMemos] = useState([]);
  const userRole = "admin";
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(getEmemoRequestFormById());
  }, [dispatch]);

  useEffect(() => {
    setFilteredEMemos(eMemos);
  }, [eMemos]);

  useEffect(() => {
    const filtered = eMemos.filter((memo) => {
      const createdDate = dayjs(memo.created_date);
      const createdDateFrom = searchData.createdDateFrom
        ? dayjs(searchData.createdDateFrom)
        : null;
      const createdDateTo = searchData.createdDateTo
        ? dayjs(searchData.createdDateTo)
        : null;

      return (
        (!searchData.documentNo ||
          memo.document_no.includes(searchData.documentNo)) &&
        (!searchData.creator || memo.creator.includes(searchData.creator)) &&
        (!searchData.requester ||
          memo.requester.includes(searchData.requester)) &&
        (!searchData.approver ||
          memo.approver_name.includes(searchData.approver)) &&
        (!searchData.subject || memo.subject.includes(searchData.subject)) &&
        (!searchData.status || memo.status.includes(searchData.status)) &&
        (!createdDateFrom ||
          createdDate.isAfter(createdDateFrom) ||
          createdDate.isSame(createdDateFrom, "day")) &&
        (!createdDateTo ||
          createdDate.isBefore(createdDateTo) ||
          createdDate.isSame(createdDateTo, "day")) &&
        (!searchData.classificationLevels ||
          memo.classificationLevels.includes(
            searchData.classificationLevels
          )) &&
        (!searchData.priority || memo.priority.includes(searchData.priority)) &&
        (!searchData.eMemoType || memo.eMemoType.includes(searchData.eMemoType))
        //   &&
        // (!searchData.initial1 ||
        //   memo.initial1_name.includes(searchData.initial1)) &&
        // (!searchData.initial2 ||
        //   memo.initial2_name.includes(searchData.initial2)) &&
        // (!searchData.initial3 ||
        //   memo.initial3_name.includes(searchData.initial3))
      );
    });

    setFilteredEMemos(filtered);
  }, [searchData, eMemos]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setSearchData({ ...searchData, [name]: date });
  };

  const handleSearchOff = () => {
    setSearchData({
      documentNo: "",
      creator: "",
      createdDateFrom: dayjs().startOf('month').toDate(),
      createdDateTo: dayjs().endOf('month').toDate(),
      subject: "",
      status: "",
      approver: "",
      classificationLevels: "",
      priority: "",
      eMemoType: "",
      initial1: "",
      initial2: "",
      // initial3: "",
      requester: ""
    });
  };

  const formatDateRange = () => {
    const now = dayjs();
    const startOfMonth = now.startOf("month").format("DD MMM YYYY");
    const endOfMonth = now.endOf("month").format("DD MMM YYYY");
    const from = searchData.createdDateFrom
      ? dayjs(searchData.createdDateFrom).format("DD MMM YYYY")
      : startOfMonth;
    const to = searchData.createdDateTo
      ? dayjs(searchData.createdDateTo).format("DD MMM YYYY")
      : endOfMonth;
    return `[${from} - ${to}]`;
  };

  const columns = [
    {
      name: `${t("DocumentNumber")}`,
      selector: (row) => row.document_no,
      sortable: true,
    },
    {
      name: `${t('eMemo.createdDate')}`,
      selector: (row) => row.created_date,
      sortable: true,
    },
    { name: `${t('eMemo.subject')}`, selector: (row) => row.subject, sortable: true },
    { name: `${t('eMemo.status')}`, selector: (row) => row.status, sortable: true },
    { name: `${t('eMemo.creator')}`, selector: (row) => row.creator, sortable: true },
    { name: `${t('eMemo.requester')}`, selector: (row) => row.requester, sortable: true },
    {
      name: `${t('eMemo.approver')}`,
      selector: (row) => `${row.firstname_TH} ${row.lastname_TH}`,
      sortable: true,
    },
    { name: `${t('eMemo.type')}`, selector: (row) => row.eMemoType, sortable: true },
    { name: `${t('Company')}`, selector: (row) => row.company, sortable: true },
  ];

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRequest(null);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" style={{ paddingTop: 8 }}>
            E-Memo {t('admin')} {formatDateRange()}
          </Typography>
        </Box>

        <CardStyled>
          <StyledCardContent>
            <AdvanceSearch
              searchData={searchData}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              handleSearchOff={handleSearchOff}
              t={t}
            />
          </StyledCardContent>
        </CardStyled>

        <Typography variant="h6" style={{ marginBottom: 16 }} pt={1}>
          {t("WorkList")}
        </Typography>
        <DataTable
          columns={columns}
          data={filteredEMemos}
          onRowClick={handleViewDetails}
        />

        {selectedRequest && (
          <RequestDetailsModal
            open={isModalOpen}
            onClose={handleCloseModal}
            requestDetails={selectedRequest}
            userProfile={userProfile}
          />
        )}
      </Container>
    </StyledRoot>
  );
};

export default EMemoRequestlistAdminPage;
