import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../shared/general/Drawer";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  createFilterOptions,
  styled,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../actions/family";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import {
  addManagerGroup,
  getManagerGroup,
  updateManagerGroup,
} from "../../../../../actions/manager";
import { useTranslation } from "react-i18next";
import affiliateService from "../../../../../services/affiliate.service";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const DrawerGroupManager = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, onClose, selectedCompany, isReadOnly } = props;

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  // const { result: managerList } = useSelector((state) => state.manager);
  const [managerList, setManagerList] = useState([]);

  const filterOptions = createFilterOptions({
    stringify: (value) =>
      `${value.firstname_TH} ${value.lastname_TH} ${value.email}`,
  });

  // const isFoundSelected

  const validateYupSchema = yup.object({
    managerGroupName: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
    managerList: yup.array().of(
      yup.object().shape({
        idManager: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
      })
    ),
    // firstname_TH: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
    // lastname_TH: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
    // personalID: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
    // birthday: yup.date().nullable().required(t("PleaseEnterRequiredInformationCompletely")),
  });

  const useHookForm = useForm({
    defaultValues: {
      managerGroupName: "",
      managerList: [],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const useFieldArrayManagerList = useFieldArray({
    control: useHookForm.control,
    name: "managerList",
  });

  useEffect(() => {
    if (drawerConfig.isOpen === true) {

      affiliateService.getAffiliateManager().then(res => {
        if(res.status === 200){
          setManagerList(res.data)
        } else {
          setManagerList([])
        }
      })

      if (drawerConfig.isEdit) {
        useHookForm.reset({
          managerGroupName: drawerConfig.data.managerGroupName || "",
          managerList: drawerConfig.data.managerList
            ? drawerConfig.data.managerList.map((item) => ({
              idManager: item.idManager,
            }))
            : [],
        });
      } else {
        useHookForm.reset({
          managerGroupName: "",
          managerList: [],
        });
      }
    }
  }, [drawerConfig.isOpen]);

  const onSubmit = async (data) => {
    // console.log(data)

    let result = null;

    const formData = {
      ...data,
      idCompany: selectedCompany.idCompany,
      // idEmployees: employeeProfile.idEmployees,
    };

    if (!drawerConfig.isEdit) {
      result = await dispatch(addManagerGroup(formData));
    } else {
      result = await dispatch(
        updateManagerGroup(drawerConfig.data.idManagerGroup, formData)
      );
    }

    if (result) {
      if (result.status === 200) {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        // dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
        dispatch(getManagerGroup({ idCompany: selectedCompany.idCompany }));
        onClose();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: t("ErrorOccurredContactAdmin")
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: t("ErrorOccurredContactAdmin")
      // }))
    }
  };

  return (
    <DrawerCustom
      title={
        drawerConfig.isEdit
          ? `${t("EditManagerGroup")}`
          : `${t("AddManagerGroup")}`
      }
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("ManagerGroupName")}
              </Typography>
              <Controller
                name="managerGroupName"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography fontSize="18px" fontWeight="500">
                {t("ManagersInGroup")}
              </Typography>
            </Grid>

            {useFieldArrayManagerList.fields.map((item, index) => (
              <Grid key={item.id} item xs={12}>
                <Box display="flex" alignItems="center" paddingBottom="4px">
                  <Typography flexGrow="1" fontSize="14px" fontWeight="500">
                    {t("ManagerNo")} {index + 1}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      useFieldArrayManagerList.remove(index);
                    }}
                  >
                    <DeleteOutlineRoundedIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Controller
                  name={`managerList.${index}.idManager`}
                  control={useHookForm.control}
                  render={({ field }) => (
                    <Autocomplete
                      options={managerList ? managerList : []}
                      getOptionLabel={(option) =>
                        i18n.resolvedLanguage === "th"
                          ? `${option.firstname_TH} ${option.lastname_TH}`
                          : `${option.firstname_EN} ${option.lastname_EN}`
                      }
                      filterOptions={filterOptions}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idEmployees}>
                          <Box>
                            {i18n.resolvedLanguage === "th" ? (
                              <Typography>
                                {option.firstname_TH} {option.lastname_TH}
                              </Typography>
                            ) : (
                              <Typography>
                                {option.firstname_EN} {option.lastname_EN}
                              </Typography>
                            )}

                            <Typography color="text.third" fontSize="14px">
                              {option.email}
                            </Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          onBlur={field.onBlur}
                          error={
                            useHookForm.formState.errors["managerList"] &&
                              useHookForm.formState.errors["managerList"][
                              index
                              ] &&
                              useHookForm.formState.errors["managerList"][index][
                              "idManager"
                              ]
                              ? true
                              : false
                          }
                          helperText={
                            useHookForm.formState.errors["managerList"] &&
                              useHookForm.formState.errors["managerList"][
                              index
                              ] &&
                              useHookForm.formState.errors["managerList"][index][
                              "idManager"
                              ]
                              ? useHookForm.formState.errors["managerList"][
                                index
                              ]["idManager"].message
                              : null
                          }
                        />
                      )}
                      value={
                        managerList
                          ? managerList.find(
                            (option) => option.idEmployees === field.value
                          ) || null
                          : null
                      }
                      onChange={(_, value) => {
                        field.onChange(value ? value.idEmployees : "");
                      }}
                      disableClearable
                    />
                  )}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <ButtonBlue
                variant="outlined"
                startIcon={<AddIcon />}
                fullWidth
                onClick={() => {
                  useFieldArrayManagerList.append({
                    idManager: "",
                  });
                }}
              >
                {t("AddManager")}
              </ButtonBlue>
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting || isReadOnly}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerGroupManager;
