import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { Typography, MenuItem, Stack, Box } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import { getAffiliateOrganization } from "../../../../actions/company";
import { useDispatch, useSelector } from "react-redux";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import AlertResponse from "../../shared/general/AlertResponse";
import { getAllPayrollDepartment } from "../../../../actions/payruns-export";
import { PayrollXlsxFile } from "../payroll/summary/payrollXlsxFile";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const SelectYearPayrollReportDepartment = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose } = props;
    const { t } = useTranslation();
    const { control } = useForm({});

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const currentYear = new Date().getFullYear();

    const [data, setData] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(1);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const thaiYears = Array.from(
        { length: 6 },
        (_, index) => currentYear + 543 - index
    );

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (
            userProfile &&
            affiliateOrganizationList &&
            affiliateOrganizationList.length > 0
        ) {
            let ownCompany = affiliateOrganizationList.find(
                (x) => x.idCompany === userProfile.idCompany
            );
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany);
        }
    }, [userProfile, affiliateOrganizationList]);

    const fetchedData = async () => {
        try {
            const response = await getAllPayrollDepartment({
                idCompany: selectedCompany && selectedCompany.idCompany,
                year: parseInt(selectedYear) - 543,
                isDepartment: selectedLevel
            });
            if (response && response.status === 200 && response.data && response.data.length > 0) {
                setData(response.data);
            } else {
                handleOpenAlert(true);
                setAlertType(t("NoData"));
            };
        } catch (error) {
            setAlertType(t("error"));
            handleOpenAlert(true);
            console.error(error);
        };
    };

    useEffect(() => {
        if (selectedYear && selectedCompany && selectedLevel) {
            fetchedData();
        };
    }, [selectedYear, selectedCompany, selectedLevel]);

    return (
        <DrawerCustom
            title={`${t("yearlyPayrollReportDepartment")}`}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Stack spacing={2}>
                    <Stack spacing={1}>
                        <Typography>{t("Company")}</Typography>
                        <div className="search-name">
                            <SelectAffiliateCompany
                                fullWidth
                                options={affiliateOrganizationList}
                                value={selectedCompany}
                                // disabled={isFetching}
                                onChange={(_, value) => {
                                    onChangeCompany(value);
                                }}
                            />
                        </div>
                    </Stack>

                    <Stack spacing={1}>
                        <Typography>{t("ChooseField")}</Typography>
                        <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            alignItems={"center"}
                            spacing={2}
                        >
                            <Controller
                                name="year"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme
                                        {...field}
                                        variant="filled"
                                        select
                                        fullWidth
                                        value={selectedLevel}
                                        onChange={(e) => setSelectedLevel(e.target.value)}
                                        disabled={openAlert === true}
                                    >
                                        <MenuItem value={1}>
                                            {t("Department")}
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            {t("Section")}
                                        </MenuItem>
                                    </TextFieldTheme>
                                )}
                            />
                        </Stack>
                    </Stack>

                    <Stack spacing={1}>
                        <Typography>{t("SelectYear")}</Typography>
                        <Controller
                            name="year"
                            control={control}
                            render={({ field }) => (
                                <TextFieldTheme
                                    {...field}
                                    variant="filled"
                                    select
                                    fullWidth
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                // disabled={isFetching}
                                >
                                    {thaiYears.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </TextFieldTheme>
                            )}
                        />
                    </Stack>
                </Stack>

                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonBlue
                        variant="contained"
                        disabled={selectedYear === null || (data && data.length < 1) || !data}
                        onClick={() => PayrollXlsxFile(data, (selectedYear - 543), selectedLevel, t)}
                    >
                        {t("Download")}
                    </ButtonBlue>
                </Box>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    );
};

export default SelectYearPayrollReportDepartment;
