import React from "react";
import { useSelector } from "react-redux";

import KpiAdmin from "../admin/kpis";
import KpiManager from "../manager/kpis";
import KpiUser from "../user/kpis";

import AdminOKRS from "../admin/okrs";
import ManagerOKRS from "../manager/okrs";
import UserOKRS from "../user/okrs";
import UserManagerOKRs from "../manager/okrs/manager-user";

const KPIPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);

  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <KpiAdmin />
        ) : userProfile.roles.includes("ROLE_MANAGER") &&
          userProfile.roles.includes("ROLE_USER") ? (
          <KpiManager />
        ) : userProfile.roles.includes("ROLE_MANAGER") ? (
          <KpiManager />
        ) : (
          <KpiUser />
        )
      ) : null}
    </>
  );
};

export default KPIPage;
