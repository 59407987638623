import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  IconButton,
  ThemeProvider,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BuildIcon from '@mui/icons-material/Build';
import { RotatingLines } from 'react-loader-spinner'
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';

const theme = createTheme({
  palette: {
    primary: {
      main: '#039be5',
    },
    secondary: {
      main: '#FF0000',
    },
    alert: {
      main: '#283593',
      text: '#283593',
    },
  },
});

const AlertDialog = ({ open, onClose, message, handleSubmit, formData, loading }) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        style={{
          backgroundImage: 'linear-gradient(45deg, rgba(211, 211, 211, 0.9))',
          backgroundColor: 'transparent',
        }}
      >
        {loading ? (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '60px',
                  height: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                }}
              >
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  color="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            </Grid>
          </>
        ) : (
          <>
            <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.palette.alert.main, color: 'white' }}>
              <Typography variant="h6">
                {`${t("Alert")}`}
              </Typography>
              <IconButton aria-label="close" onClick={onClose} sx={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography
                variant="body1"
                align="center"
                id="alert-dialog-description"
                sx={{
                  color: theme.palette.alert.text,
                  marginTop: '19px',
                  fontWeight: 'semi-bold',
                  padding: '16px',
                  borderColor: theme.palette.primary.main,
                  fontFamily: 'Roboto, Arial, sans-serif',
                  fontSize: '18px',
                  lineHeight: '1.5',
                  userSelect: 'none'
                }}
              >
                {message}
              </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '16px', backgroundColor: '#f5f5f5' }}>
              <Button onClick={onClose} variant="contained">
                <HighlightOffIcon style={{ fontSize: "1.2rem" }} />
                <Box ml={1}
                  style={{
                    padding: '2px'
                  }}
                >
                  {`${t("Close")}`}
                </Box>
              </Button>
              {formData.isActivate === true ? (
                <Button
                  onClick={() => handleSubmit('activate', formData)}
                  color="primary"
                  autoFocus
                  variant="contained"
                  style={{
                    minWidth: '100px',
                    backgroundColor: '#6495ED',
                  }}
                >
                  {`${t("Open")}`}
                  <Box ml={1}>
                    <DoneIcon style={{ fontSize: "1.2rem", paddingTop: "3px" }} />
                  </Box>
                </Button>
              ) : (
                <Button
                  onClick={() => handleSubmit('activate', formData)}
                  autoFocus
                  variant="contained"
                  style={{
                    minWidth: '100px',
                    backgroundColor: '#708090',
                  }}
                >
                  {`${t("Cancel")}`}
                  <Box ml={1} mt={0.5}>
                    <LockIcon style={{ fontSize: "0.9rem" }} />
                  </Box>
                </Button>
              )
              }
            </DialogActions>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default AlertDialog;