import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  IconButton,
  Autocomplete,
  autocompleteClasses,
  Popper,
  Divider,
  FormControlLabel,
  Radio,
  MenuItem,
  Chip,
  RadioGroup,
  Menu,
} from "@mui/material";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Dropzone, { useDropzone } from "react-dropzone";
import CardStyle from "../../../shared/general/Card";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getExpenseProrateTypes,
  getExpenseAllowanceTypes,
  updateExpenseSetting,
  addExpenseSetting,
  getExpenseSetting,
  getExpenseSettings,
} from "../../../../../actions/expenseSetting";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import GalleryIcon from "../../../assets/gallery.png";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import SaveIcon from "@mui/icons-material/Save";
import { getAllPositions } from "../../../../../actions/positions";
import { getLevelByidCompany } from "../../../../../actions/level";
import { getEmploymentTypeByidCompany } from "../../../../../actions/employment-type";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import TimePicker from "../../../shared/general/TimePicker";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone": {
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledGridItem = styled(Grid)({
  "&.MuiGrid-item": {
    margin: "12px 0",
    padding: "12px 0 0 36px",
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledRadio = styled((props) => <Radio {...props} />)(
  ({ theme, checked }) => ({
    "&.Mui-checked": {
      color: "#46CBE2",
    },
  })
);

class newAdapter extends AdapterDayjs {
  constructor({ locale, formats, instance }) {
    super({ locale, formats, instance });
  }
  formatByString = (date, format) => {
    const yearFormat = i18next.resolvedLanguage === "th" ? "BBBB" : "YYYY";
    const newFormat = format.replace(/\bYYYY\b/g, yearFormat);
    return this.dayjs(date).format(newFormat);
  };
}

function ExpenseSettingForm(props) {
  const {
    drawerConfig,
    onClose,
    setOpenAlert,
    setAlertType,
    selectedCompany,
    isReadOnly,
  } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { result: expenseProrateTypes } = useSelector(
    (state) => state.expenseProrateTypes
  );
  const { result: expenseAllowanceTypes } = useSelector(
    (state) => state.expenseAllowanceTypes
  );
  const { result: employmentTypes } = useSelector(
    (state) => state.employmentType
  );
  const { result: positions } = useSelector((state) => state.positions);
  const { result: groupLevels } = useSelector((state) => state.level);
  const [isSending, setIsSending] = useState(false);
  const [isDeleteFile, setIsDeleteFile] = useState(false);
  const [options, setOptions] = useState();
  const [employmentTypeOption, setEmploymentTypeOption] = useState(null);
  const [groupLevelOption, setGroupLevelOption] = useState(null);
  const [levelOption, setLevelOption] = useState(null);
  const [positionOption, setPositionOption] = useState(null);
  const defaultValue = {
    name: "",
    name_EN: "",
    idExpenseCutOffType: 1,
    dayCutOff: 1,
    bankName: "",
    bankAccountNumber: "",
    qrCodeFilePath: "",
    isUseGroupLevel: 1,
    employmentTypeList: [],
    levelList: [],
    groupLevelList: [],
    positionList: [],
  };
  const [formData, setFormData] = useState(defaultValue);
  const MAX_FILE_SIZE = 512000;
  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };
  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("ThisFieldIsRequired")),
    name_EN: Yup.string().required(t("ThisFieldIsRequired")),
    idExpenseCutOffType: Yup.string().required(t("ThisFieldIsRequired")),
    dayCutOff: Yup.string().required(t("ThisFieldIsRequired")),
    employmentTypeList: Yup.array().min(1).required(t("ThisFieldIsRequired")),
    isUseGroupLevel: Yup.number().required(t("ThisFieldIsRequired")),
    levelList: Yup.array().when("isUseGroupLevel", {
      is: (isUseGroupLevel) => isUseGroupLevel === 0,
      then: (schema) => schema.min(1).required(t("ThisFieldIsRequired")),
    }),
    groupLevelList: Yup.array().when("isUseGroupLevel", {
      is: (isUseGroupLevel) => isUseGroupLevel === 1,
      then: (schema) => schema.min(1).required(t("ThisFieldIsRequired")),
    }),
    positionList: Yup.array().min(1).required(t("ThisFieldIsRequired")),
    bankName: Yup.string().required(t("ThisFieldIsRequired")),
    bankAccountNumber: Yup.string().required(t("ThisFieldIsRequired")),
    qrCodeFilePath: Yup.string().nullable(),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const cutOffTypes = [
    { type: 1, label: t("daysInWeek") },
    { type: 2, label: t("daysInMonth") },
  ];

  const daysInWeek = [
    { label: t("days.everySunday"), date: 0 },
    { label: t("days.everyMonday"), date: 1 },
    { label: t("days.everyTuesday"), date: 2 },
    { label: t("days.everyWednesday"), date: 3 },
    { label: t("days.everyThursday"), date: 4 },
    { label: t("days.everyFriday"), date: 5 },
    { label: t("days.everySaturday"), date: 6 },
  ];

  const daysInMonth = [
    { label: t("days.startOfMonth"), date: 0 },
    { label: t("days.endOfMonth"), date: -1 },
    { label: "1", date: 1 },
    { label: "2", date: 2 },
    { label: "3", date: 3 },
    { label: "4", date: 4 },
    { label: "5", date: 5 },
    { label: "6", date: 6 },
    { label: "7", date: 7 },
    { label: "8", date: 8 },
    { label: "9", date: 9 },
    { label: "10", date: 10 },
    { label: "11", date: 11 },
    { label: "12", date: 12 },
    { label: "13", date: 13 },
    { label: "14", date: 14 },
    { label: "15", date: 15 },
    { label: "16", date: 16 },
    { label: "17", date: 17 },
    { label: "18", date: 18 },
    { label: "19", date: 19 },
    { label: "20", date: 20 },
    { label: "21", date: 21 },
    { label: "22", date: 22 },
    { label: "23", date: 23 },
    { label: "24", date: 24 },
    { label: "25", date: 25 },
    { label: "26", date: 26 },
    { label: "27", date: 27 },
    { label: "28", date: 28 },
    { label: "29", date: 29 },
    { label: "30", date: 30 },
    { label: "31", date: 31 },
  ];

  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });

  const checkValidation = (error) => {
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getEmploymentTypeByidCompany({ idCompany: selectedCompany.idCompany })
      );
      dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }));
      dispatch(getAllPositions({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (drawerConfig.isOpen) {
      if (!expenseAllowanceTypes) {
        dispatch(getExpenseAllowanceTypes());
      }
      if (!expenseProrateTypes) {
        dispatch(getExpenseProrateTypes());
      }
      setOptions(daysInWeek);
      if (drawerConfig.isEdit) {
        let setting = drawerConfig.data;
        if (setting.idExpenseCutOffType === 2) {
          setOptions(daysInMonth);
        }
        let defaultData = {
          name: setting.name,
          name_EN: setting.name_EN,
          idExpenseCutOffType: setting.idExpenseCutOffType,
          dayCutOff: setting.dayCutOff,
          bankName: setting.bankName,
          bankAccountNumber: setting.bankAccountNumber,
          qrCodeFilePath: setting.qrCodeFilePath,
          isUseGroupLevel: setting.isUseGroupLevel,
          employmentTypeList:
            setting.employmentTypeList === null
              ? [""]
              : setting.employmentTypeList.map((employment) => {
                  return employment.idEmploymentType;
                }),
          levelList:
            setting.levelList === null
              ? setting.isUseGroupLevel === 1
                ? []
                : [""]
              : setting.levelList.map((level) => {
                  return level.idLevel;
                }),
          groupLevelList:
            setting.groupLevelList === null
              ? setting.isUseGroupLevel === 1
                ? [""]
                : []
              : setting.groupLevelList.map((groupLevel) => {
                  return groupLevel.idGroupLevel;
                }),
          positionList:
            setting.positionList === null
              ? [""]
              : setting.positionList.map((position) => {
                  return position.idPosition;
                }),
          fileURL: setting.fileURL,
          fileOriginalName: setting.qrCodeFilePath,
        };
        reset(defaultData);
        setFormData(defaultData);
      }
    }
  }, [drawerConfig.isOpen]);

  useEffect(() => {
    let list = [
      {
        idEmploymentType: "",
        employmentTypeName: t("All"),
      },
    ];

    if (employmentTypes) {
      employmentTypes.map((item) => {
        list.push({
          idEmploymentType: item.idEmploymentType,
          employmentTypeName: item.employmentTypeName,
        });
      });
      setEmploymentTypeOption(list);
    }
  }, [employmentTypes]);

  useEffect(() => {
    let listGroupLevel = [
      {
        idGroupLevel: "",
        groupLevelName: t("All"),
      },
    ];

    let listLevel = [
      {
        idLevel: "",
        levelName: t("All"),
      },
    ];

    if (groupLevels) {
      groupLevels.map((item) => {
        listGroupLevel.push({
          idGroupLevel: item.idGroupLevel,
          groupLevelName: item.groupLevelName,
        });

        item.level.map((level) => {
          listLevel.push({
            idLevel: level.idLevel,
            levelName: level.levelName,
          });
        });
      });
      setGroupLevelOption(listGroupLevel);
      setLevelOption(listLevel);
    }
  }, [groupLevels]);

  useEffect(() => {
    let list = [
      {
        idPosition: "",
        positionName: t("All"),
      },
    ];
    if (positions) {
      positions.map((item) => {
        list.push({
          idPosition: item.idPosition,
          positionName: item.positionName,
        });
      });
      setPositionOption(list);
    }
  }, [positions]);

  useEffect(() => {
    if (getValues("isUseGroupLevel") === 1) {
      setValue("levelList", []);
    } else {
      setValue("groupLevelList", []);
    }
  }, [watch("isUseGroupLevel")]);

  useEffect(() => {
    if (watch("idExpenseCutOffType") == 1) {
      setOptions(daysInWeek);
    } else {
      setOptions(daysInMonth);
    }
  }, [watch("idExpenseCutOffType")]);

  const handleChangeTime = (name, unit, periodTime, value) => {
    console.log(name);
    setValue(name, value);
  };

  const handleChangeCancle = () => {
    if (drawerConfig.isEdit === false) {
      reset(defaultValue);
    }
    onClose();
  };

  const onSubmit = async () => {
    setIsSending(true);
    const data = new FormData();
    data.append("idCompany", selectedCompany.idCompany);
    data.append("name", getValues("name"));
    data.append("name_EN", getValues("name_EN"));
    data.append("idExpenseCutOffType", getValues("idExpenseCutOffType"));
    data.append("dayCutOff", getValues("dayCutOff"));
    data.append("bankName", getValues("bankName"));
    data.append("bankAccountNumber", getValues("bankAccountNumber"));
    data.append("isUseGroupLevel", getValues("isUseGroupLevel"));
    if (
      getValues("employmentTypeList").length > 1 ||
      getValues("employmentTypeList").length === 0
    ) {
      data.append(
        "employmentTypeList",
        JSON.stringify(getValues("employmentTypeList"))
      );
    } else {
      if (getValues("employmentTypeList")[0] === "") {
        data.append("employmentTypeList", JSON.stringify([]));
      } else {
        data.append(
          "employmentTypeList",
          JSON.stringify(getValues("employmentTypeList"))
        );
      }
    }

    if (
      getValues("levelList").length > 1 ||
      getValues("levelList").length === 0
    ) {
      data.append("levelList", JSON.stringify(getValues("levelList")));
    } else {
      if (getValues("levelList")[0] === "") {
        data.append("levelList", JSON.stringify([]));
      } else {
        data.append("levelList", JSON.stringify(getValues("levelList")));
      }
    }

    if (
      getValues("groupLevelList").length > 1 ||
      getValues("groupLevelList").length === 0
    ) {
      data.append(
        "groupLevelList",
        JSON.stringify(getValues("groupLevelList"))
      );
    } else {
      if (getValues("groupLevelList")[0] === "") {
        data.append("groupLevelList", JSON.stringify([]));
      } else {
        data.append(
          "groupLevelList",
          JSON.stringify(getValues("groupLevelList"))
        );
      }
    }

    if (
      getValues("positionList").length > 1 ||
      getValues("positionList").length === 0
    ) {
      data.append("positionList", JSON.stringify(getValues("positionList")));
    } else {
      if (getValues("positionList")[0] === "") {
        data.append("positionList", JSON.stringify([]));
      } else {
        data.append("positionList", JSON.stringify(getValues("positionList")));
      }
    }

    if (getValues("file")) {
      data.append("file", getValues("file"), getValues("file.path"));
      data.append("qrCodeFilePath", getValues("file.path"));
    }

    if (isDeleteFile) {
      data.append("fileOriginalName", getValues("fileOriginalName"));
    }

    let res;
    if (drawerConfig.isEdit) {
      data.append("idExpenseSetting", drawerConfig.data.idExpenseSetting);
      res = await dispatch(updateExpenseSetting(data));
    } else {
      res = await dispatch(addExpenseSetting(data));
    }

    if (res.status === 200) {
      setIsSending(false);
      setOpenAlert(true);
      setAlertType("success");
      onClose();
      dispatch(getExpenseSettings(selectedCompany.idCompany));
    } else {
      setIsSending(false);
      setOpenAlert(true);
      setAlertType("error");
    }
  };

  return (
    <>
      <div style={{ marginBottom: 12 }}>
        <Typography variant="h5" style={{ paddingTop: 8 }}>
          {t("addExpenseSetting")}
        </Typography>
      </div>
      {employmentTypeOption &&
        groupLevelOption &&
        levelOption &&
        positionOption &&
        expenseProrateTypes &&
        expenseAllowanceTypes && (
          <CardStyle>
            <Box sx={{ padding: "24px", marginTop: "16px" }}>
              <form handleSubmit={onSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("Name")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.name ? true : false}
                      >
                        {errors && errors.name && errors.name.message && (
                          <FormHelperText error>
                            {errors.name.message}
                          </FormHelperText>
                        )}
                        <Controller
                          name="name"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("NameEN")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.name_EN ? true : false}
                      >
                        {errors && errors.name_EN && errors.name_EN.message && (
                          <FormHelperText error>
                            {errors.name_EN.message}
                          </FormHelperText>
                        )}
                        <Controller
                          name="name_EN"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("dayCutOffType")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={
                          errors && errors.idExpenseCutOffType ? true : false
                        }
                      >
                        {errors &&
                          errors.idExpenseCutOffType &&
                          errors.idExpenseCutOffType.message && (
                            <FormHelperText error>
                              {errors.idExpenseCutOffType.message}
                            </FormHelperText>
                          )}
                        <Controller
                          name="idExpenseCutOffType"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <StyledAutocomplete
                                {...field}
                                fullWidth
                                options={cutOffTypes}
                                onChange={(e, newValue) => {
                                  field.onChange(newValue.type);
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.type === value.type
                                }
                                value={
                                  cutOffTypes.find(
                                    (item) => item.type === field.value
                                  ) || null
                                }
                                popupIcon={
                                  <i class="fa-light fa-chevron-down"></i>
                                }
                                renderOption={(props, option) => (
                                  <Box component="li" {...props}>
                                    {option.label}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextFieldTheme
                                    {...params}
                                    variant="outlined"
                                    placeholder=""
                                  />
                                )}
                                PopperComponent={StyledPopper}
                                noOptionsText={t("NoData")}
                                disableClearable
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("dayCutOff")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.dayCutOff ? true : false}
                      >
                        {errors &&
                          errors.dayCutOff &&
                          errors.dayCutOff.message && (
                            <FormHelperText error>
                              {errors.dayCutOff.message}
                            </FormHelperText>
                          )}
                        <Controller
                          name="dayCutOff"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <StyledAutocomplete
                                {...field}
                                fullWidth
                                options={options}
                                onChange={(e, newValue) => {
                                  setValue("dayCutOff", newValue.date);
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.date === value.date
                                }
                                value={
                                  options.find(
                                    (item) => item.date === field.value
                                  ) || null
                                }
                                popupIcon={
                                  <i class="fa-light fa-chevron-down"></i>
                                }
                                renderOption={(props, option) => (
                                  <Box component="li" {...props}>
                                    {option.label}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextFieldTheme
                                    {...params}
                                    variant="outlined"
                                    placeholder=""
                                  />
                                )}
                                PopperComponent={StyledPopper}
                                noOptionsText={t("NoData")}
                                disableClearable
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("EmployeeType")}
                    </StyledHeadLabel>
                    <Controller
                      name="employmentTypeList"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          select
                          SelectProps={{
                            multiple: true,
                            renderValue: (select) => (
                              <Box>
                                {select.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      employmentTypeOption.find(
                                        (x) => x.idEmploymentType === value
                                      ).employmentTypeName
                                    }
                                  />
                                ))}
                              </Box>
                            ),
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value.length > 0 &&
                              e.target.value[e.target.value.length - 1] !== ""
                            ) {
                              const temp = e.target.value.filter(
                                (x) => x !== ""
                              );
                              field.onChange(temp);
                            } else if (e.target.value.includes("")) {
                              setValue(`employmentTypeList`, [""]);
                            } else {
                              field.onChange(e.target.value);
                            }
                          }}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        >
                          {employmentTypeOption.map((item, typeIndex) => {
                            return (
                              <MenuItem
                                value={item.idEmploymentType}
                                key={item.idEmploymentType}
                              >
                                {item.employmentTypeName}
                              </MenuItem>
                            );
                          })}
                        </TextFieldTheme>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("Position")}
                    </StyledHeadLabel>
                    <Controller
                      name={`positionList`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          select
                          disabled={
                            watch(`employmentTypeList`) &&
                            watch(`employmentTypeList`).length === 0
                          }
                          SelectProps={{
                            multiple: true,
                            renderValue: (select) => (
                              <Box>
                                {select.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      positionOption.find(
                                        (x) => x.idPosition === value
                                      ).positionName
                                    }
                                  />
                                ))}
                              </Box>
                            ),
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value.length > 0 &&
                              e.target.value[e.target.value.length - 1] !== ""
                            ) {
                              const temp = e.target.value.filter(
                                (x) => x !== ""
                              );
                              field.onChange(temp);
                            } else if (e.target.value.includes("")) {
                              setValue(`positionList`, [""]);
                            } else {
                              field.onChange(e.target.value);
                            }
                          }}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        >
                          {positionOption.map((item, typeIndex) => {
                            return (
                              <MenuItem
                                value={item.idPosition}
                                key={item.idPosition}
                              >
                                {item.positionName}
                              </MenuItem>
                            );
                          })}
                        </TextFieldTheme>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("employeeLevelType")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.isUseGroupLevel ? true : false}
                      >
                        <Controller
                          name="isUseGroupLevel"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              row
                              onChange={(e) =>
                                field.onChange(Number(e.target.value))
                              }
                            >
                              <FormControlLabel
                                value={1}
                                control={<StyledRadio />}
                                label={t("EmployeeGroupLevel")}
                              />
                              <FormControlLabel
                                value={0}
                                control={<StyledRadio />}
                                label={t("EmployeePersonalLevel")}
                              />
                            </RadioGroup>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  {watch("isUseGroupLevel") === 1 ? (
                    <Grid item xs={12} md={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("EmployeeGroupLevel")}
                      </StyledHeadLabel>
                      <Controller
                        name={`groupLevelList`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            select
                            disabled={watch(`employmentTypeList`).length === 0}
                            SelectProps={{
                              multiple: true,
                              renderValue: (select) => (
                                <Box>
                                  {select.map((value) => (
                                    <Chip
                                      key={value}
                                      label={
                                        groupLevelOption.find(
                                          (x) => x.idGroupLevel === value
                                        ).groupLevelName
                                      }
                                    />
                                  ))}
                                </Box>
                              ),
                            }}
                            onChange={(e) => {
                              if (
                                e.target.value.length > 0 &&
                                e.target.value[e.target.value.length - 1] !== ""
                              ) {
                                const temp = e.target.value.filter(
                                  (x) => x !== ""
                                );
                                field.onChange(temp);
                              } else if (e.target.value.includes("")) {
                                setValue(`groupLevelList`, [""]);
                              } else {
                                field.onChange(e.target.value);
                              }
                            }}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          >
                            {groupLevelOption.map((item) => (
                              <MenuItem
                                value={item.idGroupLevel}
                                keyvalue={item.idGroupLevel}
                              >
                                {item.groupLevelName}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("EmployeePersonalLevel")}
                      </StyledHeadLabel>
                      <Controller
                        name={`levelList`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            select
                            SelectProps={{
                              multiple: true,
                              renderValue: (select) => (
                                <Box>
                                  {select.map((value) => (
                                    <Chip
                                      key={value}
                                      label={
                                        levelOption.find(
                                          (x) => x.idLevel === value
                                        ).levelName
                                      }
                                    />
                                  ))}
                                </Box>
                              ),
                            }}
                            onChange={(e) => {
                              if (
                                e.target.value.length > 0 &&
                                e.target.value[e.target.value.length - 1] !== ""
                              ) {
                                const temp = e.target.value.filter(
                                  (x) => x !== ""
                                );
                                field.onChange(temp);
                              } else if (e.target.value.includes("")) {
                                setValue(`levelList`, [""]);
                              } else {
                                field.onChange(e.target.value);
                              }
                            }}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          >
                            {levelOption.map((item) => (
                              <MenuItem value={item.idLevel} key={item.idLevel}>
                                {item.levelName}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("bankAccount")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.bankName ? true : false}
                      >
                        {errors &&
                          errors.bankName &&
                          errors.bankName.message && (
                            <FormHelperText error>
                              {errors.bankName.message}
                            </FormHelperText>
                          )}
                        <Controller
                          name="bankName"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("bankAccountNumber")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={
                          errors && errors.bankAccountNumber ? true : false
                        }
                      >
                        {errors &&
                          errors.bankAccountNumber &&
                          errors.bankAccountNumber.message && (
                            <FormHelperText error>
                              {errors.bankAccountNumber.message}
                            </FormHelperText>
                          )}
                        <Controller
                          name="bankAccountNumber"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledDivider />
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <div className="wrap-head">
                        <StyledHeadLabel variant="h6">
                          {t("AttachFile")} QR Code
                        </StyledHeadLabel>
                      </div>
                      {!watch("file") && !watch("fileURL") ? (
                        <StyledDropzone>
                          <Dropzone
                            accept="image/jpg, image/jpeg, image/png"
                            maxFiles={1}
                            multiple={false}
                            maxSize={3145728}
                            onDrop={(acceptedFiles, rejectedFiles) => {
                              if (acceptedFiles.length > 0) {
                                setValue("file", acceptedFiles[0]);
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({
                                  className: "dropzone-upload-file",
                                })}
                              >
                                <div className="inner-dropzone">
                                  <input {...getInputProps()} />
                                  <div style={{ textAlign: "center" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img alt="add" src={GalleryIcon} />
                                    </div>
                                    <Typography
                                      variant="caption"
                                      style={{
                                        lineHeight: 0.5,
                                        color: "#999999",
                                      }}
                                    >
                                      {t("imageFileNotMore500KB")}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </StyledDropzone>
                      ) : !watch("fileURL") && watch("file") ? (
                        <Box
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <IconButton
                            style={{
                              position: "absolute",
                              margin: 7,
                              right: 0,
                            }}
                            color="error"
                            onClick={() => {
                              setValue("file", null);
                              setValue("fileURL", null);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <div
                            style={{
                              padding: 15,
                              backgroundColor: "#f9f9fb",
                              display: "flex",
                              borderRadius: 15,
                            }}
                          >
                            <FilePresentIcon
                              height="24"
                              style={{
                                marginRight: 10,
                              }}
                            ></FilePresentIcon>
                            <Typography>{watch("file.path")}</Typography>
                          </div>
                        </Box>
                      ) : (
                        <Box
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <IconButton
                            style={{
                              position: "absolute",
                              margin: 7,
                              right: 0,
                            }}
                            color="error"
                            onClick={() => {
                              setIsDeleteFile(true);
                              setValue("file", null);
                              setValue("fileURL", null);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <div
                            style={{
                              padding: 15,
                              backgroundColor: "#f9f9fb",
                              display: "flex",
                              borderRadius: 15,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(getValues("fileURL"), "_blank");
                            }}
                          >
                            <FilePresentIcon
                              height="24"
                              style={{
                                marginRight: 10,
                              }}
                            ></FilePresentIcon>
                            <Typography>{watch("qrCodeFilePath")}</Typography>
                          </div>
                        </Box>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <ButtonBlue
                        variant="contained"
                        type
                        color="error"
                        startIcon={<SaveIcon />}
                        onClick={() => handleChangeCancle(false)}
                        sx={{ marginRight: "16px" }}
                      >
                        {t("Cancel")}
                      </ButtonBlue>
                      <ButtonBlue
                        variant="contained"
                        type
                        startIcon={<SaveIcon />}
                        onClick={handleSubmit(onSubmit, checkValidation)}
                        disabled={isSending}
                      >
                        {t("Save")}
                      </ButtonBlue>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </CardStyle>
        )}
    </>
  );
}

export default ExpenseSettingForm;
