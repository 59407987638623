import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  IconButton,
  Autocomplete,
  autocompleteClasses,
  Popper,
  Divider,
  FormControlLabel,
  Radio,
  MenuItem,
  Chip,
  RadioGroup,
  Menu,
} from "@mui/material";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Dropzone, { useDropzone } from "react-dropzone";
import CardStyle from "../../../shared/general/Card";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getExpenseProrateTypes,
  getExpenseAllowanceTypes,
  updateExpenseSettingAllowance,
  addExpenseSettingAllowance,
  getExpenseSettingsAllowance,
} from "../../../../../actions/expenseSetting";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { getAllPositions } from "../../../../../actions/positions";
import { getLevelByidCompany } from "../../../../../actions/level";
import { getEmploymentTypeByidCompany } from "../../../../../actions/employment-type";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import TimePicker from "../../../shared/general/TimePicker";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone": {
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledGridItem = styled(Grid)({
  "&.MuiGrid-item": {
    margin: "12px 0",
    padding: "12px 0 0 36px",
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledRadio = styled((props) => <Radio {...props} />)(
  ({ theme, checked }) => ({
    "&.Mui-checked": {
      color: "#46CBE2",
    },
  })
);

class newAdapter extends AdapterDayjs {
  constructor({ locale, formats, instance }) {
    super({ locale, formats, instance });
  }
  formatByString = (date, format) => {
    const yearFormat = i18next.resolvedLanguage === "th" ? "BBBB" : "YYYY";
    const newFormat = format.replace(/\bYYYY\b/g, yearFormat);
    return this.dayjs(date).format(newFormat);
  };
}

function AllowanceSettingForm(props) {
  const {
    drawerConfig,
    onClose,
    setOpenAlert,
    setAlertType,
    selectedCompany,
    isReadOnly,
  } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: expenseSetting } = useSelector(
    (state) => state.expenseSetting
  );
  const { result: expenseProrateTypes } = useSelector(
    (state) => state.expenseProrateTypes
  );
  const { result: expenseAllowanceTypes } = useSelector(
    (state) => state.expenseAllowanceTypes
  );
  const { result: employmentTypes } = useSelector(
    (state) => state.employmentType
  );
  const { result: positions } = useSelector((state) => state.positions);
  const { result: groupLevels } = useSelector((state) => state.level);
  const [isSending, setIsSending] = useState(false);
  const [employmentTypeOption, setEmploymentTypeOption] = useState(null);
  const [groupLevelOption, setGroupLevelOption] = useState(null);
  const [levelOption, setLevelOption] = useState(null);
  const [positionOption, setPositionOption] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const defaultValue = {
    name: "",
    name_EN: "",
    isUseGroupLevel: 1,
    employmentTypeList: [],
    levelList: [],
    groupLevelList: [],
    positionList: [],
    allowanceList: [
      {
        idExpenseSettingAllowanceType: 1,
        idExpenseProrateType: 1,
        isInternational: 0,
        allowanceRate: 500,
        allowanceGovermentRate: 270,
        isTimeUnit: 0,
        steps: [],
        hoursPerDay: null,
        hoursPerHalfDay: null,
      },
      {
        idExpenseSettingAllowanceType: 1,
        idExpenseProrateType: 1,
        isInternational: 1,
        allowanceRate: 5000,
        allowanceGovermentRate: 4100,
        isTimeUnit: 0,
        steps: [],
        hoursPerDay: null,
        hoursPerHalfDay: null,
      },
    ],
  };
  const [formData, setFormData] = useState(defaultValue);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("ThisFieldIsRequired")),
    name_EN: Yup.string().required(t("ThisFieldIsRequired")),
    employmentTypeList: Yup.array().min(1).required(t("ThisFieldIsRequired")),
    isUseGroupLevel: Yup.number().required(t("ThisFieldIsRequired")),
    levelList: Yup.array().when("isUseGroupLevel", {
      is: (isUseGroupLevel) => isUseGroupLevel === 0,
      then: (schema) => schema.min(1).required(t("ThisFieldIsRequired")),
    }),
    groupLevelList: Yup.array().when("isUseGroupLevel", {
      is: (isUseGroupLevel) => isUseGroupLevel === 1,
      then: (schema) => schema.min(1).required(t("ThisFieldIsRequired")),
    }),
    positionList: Yup.array().min(1).required(t("ThisFieldIsRequired")),
    allowanceList: Yup.array()
      .min(2)
      .of(
        Yup.object().shape({
          idExpenseSettingAllowanceType: Yup.string().required(
            t("ThisFieldIsRequired")
          ),
          idExpenseProrateType: Yup.string().required(t("ThisFieldIsRequired")),
          isInternational: Yup.string().required(t("ThisFieldIsRequired")),
          allowanceRate: Yup.number().when("idExpenseSettingAllowanceType", {
            is: (idExpenseSettingAllowanceType) =>
              idExpenseSettingAllowanceType != 3,
            then: (schema) => schema.required(t("ThisFieldIsRequired")),
            otherwise: (schema) => schema.nullable(),
          }),
          allowanceGovermentRate: Yup.number().when(
            "idExpenseSettingAllowanceType",
            {
              is: (idExpenseSettingAllowanceType) =>
                idExpenseSettingAllowanceType != 3,
              then: (schema) => schema.required(t("ThisFieldIsRequired")),
              otherwise: (schema) => schema.nullable(),
            }
          ),
          allowanceProrateSteps: Yup.array().nullable(),
          isTimeUnit: Yup.string().when("idExpenseSettingAllowanceType", {
            is: (idExpenseSettingAllowanceType) =>
              idExpenseSettingAllowanceType == 3,
            then: (schema) => schema.required(t("ThisFieldIsRequired")),
            otherwise: (schema) => schema.nullable(),
          }),
          steps: Yup.array()
            .when("idExpenseSettingAllowanceType", {
              is: (idExpenseSettingAllowanceType) =>
                idExpenseSettingAllowanceType == 3,
              then: (schema) => schema.min(1),
            })
            .of(
              Yup.object()
                .shape({
                  startHour: Yup.string().required(t("ThisFieldIsRequired")),
                  endHour: Yup.string().required(t("ThisFieldIsRequired")),
                  startMinute: Yup.string().required(t("ThisFieldIsRequired")),
                  endMinute: Yup.string().required(t("ThisFieldIsRequired")),
                  allowanceStepGovermentRate: Yup.string().required(
                    t("ThisFieldIsRequired")
                  ),
                  allowanceStepRate: Yup.string().required(
                    t("ThisFieldIsRequired")
                  ),
                })
                .when("idExpenseSettingAllowanceType", {
                  is: (idExpenseSettingAllowanceType) =>
                    idExpenseSettingAllowanceType == 3,
                  then: (schema) => schema.required(t("ThisFieldIsRequired")),
                })
            ),
          hoursPerDay: Yup.string().when("idExpenseSettingAllowanceType", {
            is: (idExpenseSettingAllowanceType) =>
              idExpenseSettingAllowanceType == 1,
            then: (schema) => schema.required(t("ThisFieldIsRequired")),
            otherwise: (schema) => schema.nullable(),
          }),
          hoursPerHalfDay: Yup.string().when("idExpenseSettingAllowanceType", {
            is: (idExpenseSettingAllowanceType) =>
              idExpenseSettingAllowanceType == 1,
            then: (schema) => schema.required(t("ThisFieldIsRequired")),
            otherwise: (schema) => schema.nullable(),
          }),
        })
      ),
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const { fields: fieldAllowanceList } = useFieldArray({
    control: control,
    name: "allowanceList",
  });

  const {
    append: appendDomestic,
    remove: removeDomestic,
    fields: fieldDomestic,
  } = useFieldArray({
    control: control,
    name: "allowanceList[0].steps",
  });

  const {
    append: appendInternational,
    remove: removeInternational,
    fields: fieldInternational,
  } = useFieldArray({
    control: control,
    name: "allowanceList[1].steps",
  });

  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });

  const checkValidation = (error) => {
    console.log(getValues());

    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getEmploymentTypeByidCompany({ idCompany: selectedCompany.idCompany })
      );
      dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }));
      dispatch(getAllPositions({ idCompany: selectedCompany.idCompany }));
    }
    dispatch(getExpenseProrateTypes());
    dispatch(getExpenseAllowanceTypes());
  }, []);

  useEffect(() => {
    if (drawerConfig.isOpen) {
      if (drawerConfig.isEdit) {
        let setting = drawerConfig.data;
        let allowanceSetting = drawerConfig.data.allowanceList;
        let defaultData = {
          idExpenseSettingAllowance: setting.idExpenseSettingAllowance,
          name: setting.name,
          name_EN: setting.name_EN,
          isUseGroupLevel: setting.isUseGroupLevel,
          allowanceList: [
            {
              idExpenseSettingAllowanceItem:
                allowanceSetting[0].idExpenseSettingAllowanceItem,
              idExpenseSettingAllowanceType:
                allowanceSetting[0].idExpenseSettingAllowanceType,
              idExpenseProrateType: allowanceSetting[0].idExpenseProrateType,
              isInternational: allowanceSetting[0].isInternational,
              allowanceRate: allowanceSetting[0].allowanceRate,
              allowanceGovermentRate:
                allowanceSetting[0].allowanceGovermentRate,
              isTimeUnit: allowanceSetting[0].isTimeUnit,
              steps: allowanceSetting[0].steps ? allowanceSetting[0].steps : [],
              hoursPerDay: allowanceSetting[0].hoursPerDay,
              hoursPerHalfDay: allowanceSetting[0].hoursPerHalfDay,
            },
            {
              idExpenseSettingAllowanceItem:
                allowanceSetting[1].idExpenseSettingAllowanceItem,
              idExpenseSettingAllowanceType:
                allowanceSetting[1].idExpenseSettingAllowanceType,
              idExpenseProrateType: allowanceSetting[1].idExpenseProrateType,
              isInternational: allowanceSetting[1].isInternational,
              allowanceRate: allowanceSetting[1].allowanceRate,
              allowanceGovermentRate:
                allowanceSetting[1].allowanceGovermentRate,
              isTimeUnit: allowanceSetting[1].isTimeUnit,
              steps: allowanceSetting[1].steps ? allowanceSetting[1].steps : [],
              hoursPerDay: allowanceSetting[1].hoursPerDay,
              hoursPerHalfDay: allowanceSetting[1].hoursPerHalfDay,
            },
          ],
          employmentTypeList:
            setting.employmentTypeList === null
              ? [""]
              : setting.employmentTypeList.map((employment) => {
                  return employment.idEmploymentType;
                }),
          levelList:
            setting.levelList === null
              ? setting.isUseGroupLevel === 1
                ? []
                : [""]
              : setting.levelList.map((level) => {
                  return level.idLevel;
                }),
          groupLevelList:
            setting.groupLevelList === null
              ? setting.isUseGroupLevel === 1
                ? [""]
                : []
              : setting.groupLevelList.map((groupLevel) => {
                  return groupLevel.idGroupLevel;
                }),
          positionList:
            setting.positionList === null
              ? [""]
              : setting.positionList.map((position) => {
                  return position.idPosition;
                }),
        };
        reset(defaultData);
        setFormData(defaultData);
      }
    }
  }, [drawerConfig.isOpen]);

  useEffect(() => {
    let list = [
      {
        idEmploymentType: "",
        employmentTypeName: t("All"),
      },
    ];

    if (employmentTypes) {
      employmentTypes.map((item) => {
        list.push({
          idEmploymentType: item.idEmploymentType,
          employmentTypeName: item.employmentTypeName,
        });
      });
      setEmploymentTypeOption(list);
      console.log(list);
    }
  }, [employmentTypes]);

  useEffect(() => {
    let listGroupLevel = [
      {
        idGroupLevel: "",
        groupLevelName: t("All"),
      },
    ];

    let listLevel = [
      {
        idLevel: "",
        levelName: t("All"),
      },
    ];

    if (groupLevels) {
      groupLevels.map((item) => {
        listGroupLevel.push({
          idGroupLevel: item.idGroupLevel,
          groupLevelName: item.groupLevelName,
        });

        item.level.map((level) => {
          listLevel.push({
            idLevel: level.idLevel,
            levelName: level.levelName,
          });
        });
      });
      setGroupLevelOption(listGroupLevel);
      setLevelOption(listLevel);
    }
  }, [groupLevels]);

  useEffect(() => {
    let list = [
      {
        idPosition: "",
        positionName: t("All"),
      },
    ];
    if (positions) {
      positions.map((item) => {
        list.push({
          idPosition: item.idPosition,
          positionName: item.positionName,
        });
      });
      setPositionOption(list);
    }
  }, [positions]);

  useEffect(() => {
    if (getValues("isUseGroupLevel") === 1) {
      setValue("levelList", []);
    } else {
      setValue("groupLevelList", []);
    }
  }, [watch("isUseGroupLevel")]);

  const handleChangeTime = (name, unit, periodTime, value) => {
    setValue(name, value);
  };

  const handleChangeCancle = () => {
    if (drawerConfig.isEdit === false) {
      reset(defaultValue);
    }
    onClose();
  };

  const onSubmit = async () => {
    setIsSending(true);
    setValue("idCompany", selectedCompany.idCompany);
    const data = getValues();
    let res;
    if (drawerConfig.isEdit) {
      res = await dispatch(updateExpenseSettingAllowance(data));
    } else {
      res = await dispatch(addExpenseSettingAllowance(data));
    }

    if (res.status === 200) {
      setIsSending(false);
      setOpenAlert(true);
      setAlertType("success");
      onClose();
      dispatch(getExpenseSettingsAllowance(selectedCompany.idCompany));
    } else {
      setIsSending(false);
      setOpenAlert(true);
      setAlertType("error");
    }
  };

  return (
    <>
      <div style={{ marginBottom: 12 }}>
        <Typography variant="h5" style={{ paddingTop: 8 }}>
          {t("addExpenseSetting")}
        </Typography>
      </div>
      {employmentTypeOption &&
        groupLevelOption &&
        levelOption &&
        positionOption &&
        expenseProrateTypes &&
        expenseAllowanceTypes && (
          <CardStyle>
            <Box sx={{ padding: "24px", marginTop: "16px" }}>
              <form handleSubmit={onSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("Name")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.name ? true : false}
                      >
                        {errors && errors.name && errors.name.message && (
                          <FormHelperText error>
                            {errors.name.message}
                          </FormHelperText>
                        )}
                        <Controller
                          name="name"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("NameEN")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.name_EN ? true : false}
                      >
                        {errors && errors.name_EN && errors.name_EN.message && (
                          <FormHelperText error>
                            {errors.name_EN.message}
                          </FormHelperText>
                        )}
                        <Controller
                          name="name_EN"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("EmployeeType")}
                    </StyledHeadLabel>
                    <Controller
                      name="employmentTypeList"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          select
                          SelectProps={{
                            multiple: true,
                            renderValue: (select) => (
                              <Box>
                                {select.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      employmentTypeOption.find(
                                        (x) => x.idEmploymentType === value
                                      ).employmentTypeName
                                    }
                                  />
                                ))}
                              </Box>
                            ),
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value.length > 0 &&
                              e.target.value[e.target.value.length - 1] !== ""
                            ) {
                              const temp = e.target.value.filter(
                                (x) => x !== ""
                              );
                              field.onChange(temp);
                            } else if (e.target.value.includes("")) {
                              setValue(`employmentTypeList`, [""]);
                            } else {
                              field.onChange(e.target.value);
                            }
                          }}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        >
                          {employmentTypeOption.map((item, typeIndex) => {
                            return (
                              <MenuItem
                                value={item.idEmploymentType}
                                key={item.idEmploymentType}
                              >
                                {item.employmentTypeName}
                              </MenuItem>
                            );
                          })}
                        </TextFieldTheme>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("Position")}
                    </StyledHeadLabel>
                    <Controller
                      name={`positionList`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          select
                          disabled={watch(`employmentTypeList`).length === 0}
                          SelectProps={{
                            multiple: true,
                            renderValue: (select) => (
                              <Box>
                                {select.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      positionOption.find(
                                        (x) => x.idPosition === value
                                      ).positionName
                                    }
                                  />
                                ))}
                              </Box>
                            ),
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value.length > 0 &&
                              e.target.value[e.target.value.length - 1] !== ""
                            ) {
                              const temp = e.target.value.filter(
                                (x) => x !== ""
                              );
                              field.onChange(temp);
                            } else if (e.target.value.includes("")) {
                              setValue(`positionList`, [""]);
                            } else {
                              field.onChange(e.target.value);
                            }
                          }}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        >
                          {positionOption.map((item, typeIndex) => {
                            return (
                              <MenuItem
                                value={item.idPosition}
                                key={item.idPosition}
                              >
                                {item.positionName}
                              </MenuItem>
                            );
                          })}
                        </TextFieldTheme>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("employeeLevelType")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.isUseGroupLevel ? true : false}
                      >
                        <Controller
                          name="isUseGroupLevel"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              row
                              onChange={(e) =>
                                field.onChange(Number(e.target.value))
                              }
                            >
                              <FormControlLabel
                                value={1}
                                control={<StyledRadio />}
                                label={t("EmployeeGroupLevel")}
                              />
                              <FormControlLabel
                                value={0}
                                control={<StyledRadio />}
                                label={t("EmployeePersonalLevel")}
                              />
                            </RadioGroup>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  {watch("isUseGroupLevel") === 1 ? (
                    <Grid item xs={12} md={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("EmployeeGroupLevel")}
                      </StyledHeadLabel>
                      <Controller
                        name={`groupLevelList`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            select
                            disabled={watch(`employmentTypeList`).length === 0}
                            SelectProps={{
                              multiple: true,
                              renderValue: (select) => (
                                <Box>
                                  {select.map((value) => (
                                    <Chip
                                      key={value}
                                      label={
                                        groupLevelOption.find(
                                          (x) => x.idGroupLevel === value
                                        ).groupLevelName
                                      }
                                    />
                                  ))}
                                </Box>
                              ),
                            }}
                            onChange={(e) => {
                              if (
                                e.target.value.length > 0 &&
                                e.target.value[e.target.value.length - 1] !== ""
                              ) {
                                const temp = e.target.value.filter(
                                  (x) => x !== ""
                                );
                                field.onChange(temp);
                              } else if (e.target.value.includes("")) {
                                setValue(`groupLevelList`, [""]);
                              } else {
                                field.onChange(e.target.value);
                              }
                            }}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          >
                            {groupLevelOption.map((item) => (
                              <MenuItem
                                value={item.idGroupLevel}
                                keyvalue={item.idGroupLevel}
                              >
                                {item.groupLevelName}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("EmployeePersonalLevel")}
                      </StyledHeadLabel>
                      <Controller
                        name={`levelList`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            select
                            SelectProps={{
                              multiple: true,
                              renderValue: (select) => (
                                <Box>
                                  {select.map((value) => (
                                    <Chip
                                      key={value}
                                      label={
                                        levelOption.find(
                                          (x) => x.idLevel === value
                                        ).levelName
                                      }
                                    />
                                  ))}
                                </Box>
                              ),
                            }}
                            onChange={(e) => {
                              if (
                                e.target.value.length > 0 &&
                                e.target.value[e.target.value.length - 1] !== ""
                              ) {
                                const temp = e.target.value.filter(
                                  (x) => x !== ""
                                );
                                field.onChange(temp);
                              } else if (e.target.value.includes("")) {
                                setValue(`levelList`, [""]);
                              } else {
                                field.onChange(e.target.value);
                              }
                            }}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          >
                            {levelOption.map((item) => (
                              <MenuItem value={item.idLevel} key={item.idLevel}>
                                {item.levelName}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                  )}
                  {fieldAllowanceList &&
                    fieldAllowanceList.length > 0 &&
                    fieldAllowanceList.map((allowance, index) => (
                      <>
                        <Grid item xs={12}>
                          <StyledHeadLabel
                            variant="subtitle1"
                            color="text.third"
                            gutterBottom
                            sx={{
                              paddingLeft: "6px",
                              borderLeft: "4px solid #46cbe2",
                            }}
                          >
                            {allowance.isInternational == 0
                              ? t("domesticAllowance")
                              : t("internationalAllowance")}
                          </StyledHeadLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <div>
                            <StyledHeadLabel
                              variant="body2"
                              color="text.third"
                              gutterBottom
                            >
                              {t("allowanceSettingType")}
                            </StyledHeadLabel>
                            <FormControl
                              fullWidth
                              error={
                                errors &&
                                errors.allowanceList &&
                                errors.allowanceList[index] &&
                                errors.allowanceList[index]
                                  .idExpenseSettingAllowanceType
                                  ? true
                                  : false
                              }
                            >
                              {errors &&
                                errors.allowanceList &&
                                errors.allowanceList[index] &&
                                errors.allowanceList[index]
                                  .idExpenseSettingAllowanceType &&
                                errors.allowanceList[index]
                                  .idExpenseSettingAllowanceType.message && (
                                  <FormHelperText error>
                                    {
                                      errors.allowanceList[index]
                                        .idExpenseSettingAllowanceType.message
                                    }
                                  </FormHelperText>
                                )}
                              <Controller
                                name={`allowanceList[${index}].idExpenseSettingAllowanceType`}
                                control={control}
                                render={({ field }) => (
                                  <StyledAutocomplete
                                    {...field}
                                    fullWidth
                                    options={expenseAllowanceTypes}
                                    autoComplete={false}
                                    onChange={(e, newValue) => {
                                      setValue(
                                        `allowanceList[${index}].idExpenseSettingAllowanceType`,
                                        newValue.idExpenseSettingAllowanceType
                                      );
                                      if (
                                        newValue.idExpenseSettingAllowanceType ===
                                        1
                                      ) {
                                        setValue(
                                          `allowanceList[${index}].isTimeUnit`,
                                          0
                                        );
                                        setValue(
                                          `allowanceList[${index}].steps`,
                                          []
                                        );
                                      } else if (
                                        newValue.idExpenseSettingAllowanceType ===
                                        2
                                      ) {
                                        setValue(
                                          `allowanceList[${index}].hoursPerDay`,
                                          null
                                        );
                                        setValue(
                                          `allowanceList[${index}].hoursPerHalfDay`,
                                          null
                                        );
                                        setValue(
                                          `allowanceList[${index}].isTimeUnit`,
                                          0
                                        );
                                        setValue(
                                          `allowanceList[${index}].steps`,
                                          []
                                        );
                                      } else if (
                                        newValue.idExpenseSettingAllowanceType ===
                                        3
                                      ) {
                                        setValue(
                                          `allowanceList[${index}].hoursPerDay`,
                                          null
                                        );
                                        setValue(
                                          `allowanceList[${index}].hoursPerHalfDay`,
                                          null
                                        );
                                        setValue(
                                          `allowanceList[${index}].allowanceRate`,
                                          null
                                        );
                                        setValue(
                                          `allowanceList[${index}].allowanceGovermentRate`,
                                          null
                                        );
                                        setValue(
                                          `allowanceList[${index}].steps`,
                                          [
                                            {
                                              startHour: "00",
                                              startMinute: "00",
                                              endHour: "00",
                                              endMinute: "00",
                                              allowanceStepRate: 500,
                                              allowanceStepGovermentRate: 270,
                                            },
                                          ]
                                        );
                                      }
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.idExpenseSettingAllowanceType ===
                                      value.idExpenseSettingAllowanceType
                                    }
                                    value={
                                      expenseAllowanceTypes.find(
                                        (item) =>
                                          item.idExpenseSettingAllowanceType ===
                                          field.value
                                      ) || null
                                    }
                                    popupIcon={
                                      <i className="fa-light fa-chevron-down"></i>
                                    }
                                    getOptionLabel={(option) =>
                                      i18n.resolvedLanguage === "th"
                                        ? option.name
                                        : option.name_EN
                                    }
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {i18n.resolvedLanguage === "th"
                                          ? option.name
                                          : option.name_EN}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextFieldTheme
                                        {...params}
                                        variant="outlined"
                                        placeholder={t("SelectType")}
                                        name="type"
                                      />
                                    )}
                                    PopperComponent={StyledPopper}
                                    noOptionsText={t("NoData")}
                                    disableClearable
                                  />
                                )}
                              />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div>
                            <StyledHeadLabel
                              variant="body2"
                              color="text.third"
                              gutterBottom
                            >
                              {t("prorateType")}
                            </StyledHeadLabel>
                            <FormControl
                              fullWidth
                              error={
                                errors &&
                                errors.allowanceList &&
                                errors.allowanceList[index] &&
                                errors.allowanceList[index].idExpenseProrateType
                                  ? true
                                  : false
                              }
                            >
                              {errors &&
                                errors.allowanceList &&
                                errors.allowanceList[index] &&
                                errors.allowanceList[index]
                                  .idExpenseProrateType &&
                                errors.allowanceList[index].idExpenseProrateType
                                  .message && (
                                  <FormHelperText error>
                                    {
                                      errors.allowanceList[index]
                                        .idExpenseProrateType.message
                                    }
                                  </FormHelperText>
                                )}
                              <Controller
                                name={`allowanceList[${index}].idExpenseProrateType`}
                                control={control}
                                render={({ field }) => (
                                  <StyledAutocomplete
                                    {...field}
                                    fullWidth
                                    options={expenseProrateTypes}
                                    onChange={(e, newValue) => {
                                      setValue(
                                        `allowanceList[${index}].idExpenseProrateType`,
                                        newValue.idExpenseProrateType
                                      );
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.idExpenseProrateType ===
                                      value.idExpenseProrateType
                                    }
                                    value={
                                      expenseProrateTypes.find(
                                        (item) =>
                                          item.idExpenseProrateType ===
                                          field.value
                                      ) || null
                                    }
                                    popupIcon={
                                      <i className="fa-light fa-chevron-down"></i>
                                    }
                                    getOptionLabel={(option) =>
                                      i18n.resolvedLanguage === "th"
                                        ? option.name
                                        : option.name_EN
                                    }
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {i18n.resolvedLanguage === "th"
                                          ? option.name
                                          : option.name_EN}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextFieldTheme
                                        {...params}
                                        variant="outlined"
                                        placeholder={t("SelectType")}
                                        name="type"
                                      />
                                    )}
                                    PopperComponent={StyledPopper}
                                    noOptionsText={t("NoData")}
                                    disableClearable
                                  />
                                )}
                              />
                            </FormControl>
                          </div>
                        </Grid>
                        {watch(
                          `allowanceList[${index}].idExpenseSettingAllowanceType`
                        ) == 1 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <div>
                                <StyledHeadLabel
                                  variant="body2"
                                  color="text.third"
                                  gutterBottom
                                >
                                  {t("hoursPerDay")}
                                </StyledHeadLabel>
                                <FormControl
                                  fullWidth
                                  error={
                                    errors &&
                                    errors.allowanceList &&
                                    errors.allowanceList[index] &&
                                    errors.allowanceList[index].hoursPerDay
                                      ? true
                                      : false
                                  }
                                >
                                  {errors &&
                                    errors.allowanceList &&
                                    errors.allowanceList[index] &&
                                    errors.allowanceList[index].hoursPerDay &&
                                    errors.allowanceList[index].hoursPerDay
                                      .message && (
                                      <FormHelperText error>
                                        {
                                          errors.allowanceList[index]
                                            .hoursPerDay.message
                                        }
                                      </FormHelperText>
                                    )}
                                  <Controller
                                    name={`allowanceList[${index}].hoursPerDay`}
                                    control={control}
                                    errors={errors}
                                    render={({ field }) => (
                                      <>
                                        <TextFieldTheme
                                          {...field}
                                          handleChange={setValue}
                                          control={control}
                                          watch={watch}
                                        />
                                      </>
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div>
                                <StyledHeadLabel
                                  variant="body2"
                                  color="text.third"
                                  gutterBottom
                                >
                                  {t("hoursPerHalfDay")}
                                </StyledHeadLabel>
                                <FormControl
                                  fullWidth
                                  error={
                                    errors &&
                                    errors.allowanceList &&
                                    errors.allowanceList[index] &&
                                    errors.allowanceList[index].hoursPerHalfDay
                                      ? true
                                      : false
                                  }
                                >
                                  {errors &&
                                    errors.allowanceList &&
                                    errors.allowanceList[index] &&
                                    errors.allowanceList[index]
                                      .hoursPerHalfDay &&
                                    errors.allowanceList[index].hoursPerHalfDay
                                      .message && (
                                      <FormHelperText error>
                                        {
                                          errors.allowanceList[index]
                                            .hoursPerHalfDay.message
                                        }
                                      </FormHelperText>
                                    )}
                                  <Controller
                                    name={`allowanceList[${index}].hoursPerHalfDay`}
                                    control={control}
                                    errors={errors}
                                    render={({ field }) => (
                                      <>
                                        <TextFieldTheme
                                          {...field}
                                          handleChange={setValue}
                                          control={control}
                                          watch={watch}
                                        />
                                      </>
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </Grid>
                          </>
                        )}
                        <>
                          {watch(
                            `allowanceList[${index}].idExpenseSettingAllowanceType`
                          ) == 3 && (
                            <>
                              <Grid item xs={6}>
                                <div>
                                  <StyledHeadLabel
                                    variant="body2"
                                    color="text.third"
                                    gutterBottom
                                  >
                                    {t("allowanceUnitType")}
                                  </StyledHeadLabel>
                                  <FormControl
                                    fullWidth
                                    error={
                                      errors &&
                                      errors.allowanceList &&
                                      errors.allowanceList[index] &&
                                      errors.allowanceList[index].isTimeUnit
                                        ? true
                                        : false
                                    }
                                  >
                                    <Controller
                                      name={`allowanceList[${index}].isTimeUnit`}
                                      control={control}
                                      errors={errors}
                                      render={({ field }) => (
                                        <RadioGroup {...field} row>
                                          <FormControlLabel
                                            value={1}
                                            control={<StyledRadio />}
                                            label={t("Time")}
                                          />
                                          <FormControlLabel
                                            value={0}
                                            control={<StyledRadio />}
                                            label={t("Unit.Hours")}
                                          />
                                        </RadioGroup>
                                      )}
                                    />
                                  </FormControl>
                                </div>
                              </Grid>
                              {watch(`allowanceList[${index}].steps`).length <=
                                3 && (
                                <Grid
                                  item
                                  xs={6}
                                  container
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  <ButtonBlue
                                    variant="contained"
                                    disabled={
                                      watch(`allowanceList[${index}].steps`)
                                        .length >= 3
                                    }
                                    onClick={() => {
                                      if (index === 0) {
                                        appendDomestic({
                                          startHour: "00",
                                          startMinute: "00",
                                          endHour: "00",
                                          endMinute: "00",
                                          allowanceStepRate: 0,
                                          allowanceStepGovermentRate: 0,
                                        });
                                      } else {
                                        appendInternational({
                                          startHour: "00",
                                          startMinute: "00",
                                          endHour: "00",
                                          endMinute: "00",
                                          allowanceStepRate: 0,
                                          allowanceStepGovermentRate: 0,
                                        });
                                      }
                                    }}
                                  >
                                    {t("Add")}
                                  </ButtonBlue>
                                </Grid>
                              )}

                              {watch(`allowanceList[${index}].steps`) &&
                                watch(`allowanceList[${index}].steps`).length >
                                  0 &&
                                getValues(`allowanceList[${index}].steps`).map(
                                  (step, stepIndex) => (
                                    <>
                                      <Grid
                                        item
                                        xs={6}
                                        container
                                        alignItems="center"
                                      >
                                        <Typography
                                          variant="subtile1"
                                          color="text.third"
                                        >
                                          {t("step")}: {stepIndex + 1}.
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        container
                                        alignItems="center"
                                        justifyContent="flex-end"
                                      >
                                        {stepIndex ==
                                          watch(`allowanceList[${index}].steps`)
                                            .length -
                                            1 &&
                                          stepIndex !== 0 && (
                                            <ButtonBlue
                                              variant="contained"
                                              color="error"
                                              startIcon={<DeleteIcon />}
                                              onClick={() => {
                                                if (index === 0) {
                                                  removeDomestic(stepIndex);
                                                } else {
                                                  removeInternational(
                                                    stepIndex
                                                  );
                                                }
                                              }}
                                              disabled={
                                                watch(
                                                  `allowanceList[${index}].steps`
                                                ).length <= 1 ||
                                                stepIndex !==
                                                  watch(
                                                    `allowanceList[${index}].steps`
                                                  ).length -
                                                    1
                                              }
                                            >
                                              {t("Delete")}
                                            </ButtonBlue>
                                          )}
                                      </Grid>
                                      <Grid item xs={6} sm={3}>
                                        <div>
                                          <StyledHeadLabel
                                            variant="body2"
                                            color="text.third"
                                            gutterBottom
                                          >
                                            {t("Start")}
                                          </StyledHeadLabel>
                                          <FormControl
                                            fullWidth
                                            error={
                                              errors &&
                                              errors.allowanceList &&
                                              errors.allowanceList[index] &&
                                              errors.allowanceList[index]
                                                .steps &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ] &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].startHour
                                                ? true
                                                : false
                                            }
                                          >
                                            <Controller
                                              name={`allowanceList[${index}].steps[${stepIndex}].startHour`}
                                              control={control}
                                              errors={errors}
                                              render={({ field }) => (
                                                <TimePicker
                                                  {...field}
                                                  handleChangeTime={
                                                    handleChangeTime
                                                  }
                                                  unit="hour"
                                                  step="1"
                                                />
                                              )}
                                            />
                                          </FormControl>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={3}>
                                        <div>
                                          <StyledHeadLabel
                                            variant="body2"
                                            color="text.third"
                                            gutterBottom
                                          >
                                            {t("Start")}
                                          </StyledHeadLabel>
                                          <FormControl
                                            fullWidth
                                            error={
                                              errors &&
                                              errors.allowanceList &&
                                              errors.allowanceList[index] &&
                                              errors.allowanceList[index]
                                                .steps &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ] &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].startMinute
                                                ? true
                                                : false
                                            }
                                          >
                                            <Controller
                                              name={`allowanceList[${index}].steps[${stepIndex}].startMinute`}
                                              control={control}
                                              errors={errors}
                                              render={({ field }) => (
                                                <TimePicker
                                                  {...field}
                                                  handleChangeTime={
                                                    handleChangeTime
                                                  }
                                                  unit="minute"
                                                  step="1"
                                                  value={field.value}
                                                />
                                              )}
                                            />
                                          </FormControl>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={3}>
                                        <div>
                                          <StyledHeadLabel
                                            variant="body2"
                                            color="text.third"
                                            gutterBottom
                                          >
                                            {t("End")}
                                          </StyledHeadLabel>
                                          <FormControl
                                            fullWidth
                                            error={
                                              errors &&
                                              errors.allowanceList &&
                                              errors.allowanceList[index] &&
                                              errors.allowanceList[index]
                                                .steps &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ] &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].endHour
                                                ? true
                                                : false
                                            }
                                          >
                                            <Controller
                                              name={`allowanceList[${index}].steps[${stepIndex}].endHour`}
                                              control={control}
                                              errors={errors}
                                              render={({ field }) => (
                                                <TimePicker
                                                  {...field}
                                                  handleChangeTime={
                                                    handleChangeTime
                                                  }
                                                  unit="hour"
                                                  step="1"
                                                />
                                              )}
                                            />
                                          </FormControl>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={3}>
                                        <div>
                                          <StyledHeadLabel
                                            variant="body2"
                                            color="text.third"
                                            gutterBottom
                                          >
                                            {t("End")}
                                          </StyledHeadLabel>
                                          <FormControl
                                            fullWidth
                                            error={
                                              errors &&
                                              errors.allowanceList &&
                                              errors.allowanceList[index] &&
                                              errors.allowanceList[index]
                                                .steps &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ] &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].endMinute
                                                ? true
                                                : false
                                            }
                                          >
                                            <Controller
                                              name={`allowanceList[${index}].steps[${stepIndex}].endMinute`}
                                              control={control}
                                              errors={errors}
                                              render={({ field }) => (
                                                <TimePicker
                                                  {...field}
                                                  handleChangeTime={
                                                    handleChangeTime
                                                  }
                                                  unit="minute"
                                                  step="1"
                                                  value={field.value}
                                                />
                                              )}
                                            />
                                          </FormControl>
                                        </div>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <div>
                                          <StyledHeadLabel
                                            variant="body2"
                                            color="text.third"
                                            gutterBottom
                                          >
                                            {t("Allowance")}
                                          </StyledHeadLabel>
                                          <FormControl
                                            fullWidth
                                            error={
                                              errors &&
                                              errors.allowanceList &&
                                              errors.allowanceList[index] &&
                                              errors.allowanceList[index]
                                                .steps &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ] &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].allowanceStepRate
                                                ? true
                                                : false
                                            }
                                          >
                                            {errors &&
                                              errors.allowanceList &&
                                              errors.allowanceList[index] &&
                                              errors.allowanceList[index]
                                                .steps &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ] &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].allowanceRate &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].allowanceStepRate.message && (
                                                <FormHelperText error>
                                                  {
                                                    errors.allowanceList[index]
                                                      .steps[stepIndex]
                                                      .allowanceStepRate.message
                                                  }
                                                </FormHelperText>
                                              )}
                                            <Controller
                                              name={`allowanceList[${index}].steps[${stepIndex}].allowanceStepRate`}
                                              control={control}
                                              errors={errors}
                                              render={({ field }) => (
                                                <>
                                                  <TextFieldTheme
                                                    {...field}
                                                    handleChange={setValue}
                                                    control={control}
                                                    watch={watch}
                                                  />
                                                </>
                                              )}
                                            />
                                          </FormControl>
                                        </div>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <div>
                                          <StyledHeadLabel
                                            variant="body2"
                                            color="text.third"
                                            gutterBottom
                                          >
                                            {t("AllowanceGovernmentRate")}
                                          </StyledHeadLabel>
                                          <FormControl
                                            fullWidth
                                            error={
                                              errors &&
                                              errors.allowanceList &&
                                              errors.allowanceList[index] &&
                                              errors.allowanceList[index]
                                                .steps &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ] &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].allowanceStepGovermentRate
                                                ? true
                                                : false
                                            }
                                          >
                                            {errors &&
                                              errors.allowanceList &&
                                              errors.allowanceList[index] &&
                                              errors.allowanceList[index]
                                                .steps &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ] &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].allowanceGovermentRate &&
                                              errors.allowanceList[index].steps[
                                                stepIndex
                                              ].allowanceStepGovermentRate
                                                .message && (
                                                <FormHelperText error>
                                                  {
                                                    errors.allowanceList[index]
                                                      .steps[stepIndex]
                                                      .allowanceStepGovermentRate
                                                      .message
                                                  }
                                                </FormHelperText>
                                              )}
                                            <Controller
                                              name={`allowanceList[${index}].steps[${stepIndex}].allowanceStepGovermentRate`}
                                              control={control}
                                              errors={errors}
                                              render={({ field }) => (
                                                <>
                                                  <TextFieldTheme
                                                    {...field}
                                                    handleChange={setValue}
                                                    control={control}
                                                    watch={watch}
                                                  />
                                                </>
                                              )}
                                            />
                                          </FormControl>
                                        </div>
                                      </Grid>
                                      <Grid item xs={12}>
                                        {/* <StyledDivider /> */}
                                      </Grid>
                                    </>
                                  )
                                )}
                            </>
                          )}
                        </>
                        {watch(
                          `allowanceList[${index}].idExpenseSettingAllowanceType`
                        ) != 3 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <div>
                                <StyledHeadLabel
                                  variant="body2"
                                  color="text.third"
                                  gutterBottom
                                >
                                  {t("Allowance")}
                                </StyledHeadLabel>
                                <FormControl
                                  fullWidth
                                  error={
                                    errors &&
                                    errors.allowanceList &&
                                    errors.allowanceList[0] &&
                                    errors.allowanceList[0].allowanceRate
                                      ? true
                                      : false
                                  }
                                >
                                  {errors &&
                                    errors.allowanceList &&
                                    errors.allowanceList[index] &&
                                    errors.allowanceList[index].allowanceRate &&
                                    errors.allowanceList[index].allowanceRate
                                      .message && (
                                      <FormHelperText error>
                                        {
                                          errors.allowanceList[index]
                                            .allowanceRate.message
                                        }
                                      </FormHelperText>
                                    )}
                                  <Controller
                                    name={`allowanceList[${index}].allowanceRate`}
                                    control={control}
                                    errors={errors}
                                    render={({ field }) => (
                                      <>
                                        <TextFieldTheme
                                          {...field}
                                          handleChange={setValue}
                                          control={control}
                                          watch={watch}
                                        />
                                      </>
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div>
                                <StyledHeadLabel
                                  variant="body2"
                                  color="text.third"
                                  gutterBottom
                                >
                                  {t("AllowanceGovernmentRate")}
                                </StyledHeadLabel>
                                <FormControl
                                  fullWidth
                                  error={
                                    errors &&
                                    errors.allowanceList &&
                                    errors.allowanceList[0] &&
                                    errors.allowanceList[0]
                                      .allowanceGovermentRate
                                      ? true
                                      : false
                                  }
                                >
                                  {errors &&
                                    errors.allowanceList &&
                                    errors.allowanceList[index] &&
                                    errors.allowanceList[index]
                                      .allowanceGovermentRate &&
                                    errors.allowanceList[index]
                                      .allowanceGovermentRate.message && (
                                      <FormHelperText error>
                                        {
                                          errors.allowanceList[index]
                                            .allowanceGovermentRate.message
                                        }
                                      </FormHelperText>
                                    )}
                                  <Controller
                                    name={`allowanceList[${index}].allowanceGovermentRate`}
                                    control={control}
                                    errors={errors}
                                    render={({ field }) => (
                                      <>
                                        <TextFieldTheme
                                          {...field}
                                          handleChange={setValue}
                                          control={control}
                                          watch={watch}
                                        />
                                      </>
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12}>
                          <StyledDivider />
                        </Grid>
                      </>
                    ))}

                  <Grid item xs={12}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <ButtonBlue
                        variant="contained"
                        type
                        color="error"
                        startIcon={<SaveIcon />}
                        onClick={() => handleChangeCancle(false)}
                        sx={{ marginRight: "16px" }}
                      >
                        {t("Cancel")}
                      </ButtonBlue>
                      <ButtonBlue
                        variant="contained"
                        type
                        startIcon={<SaveIcon />}
                        onClick={handleSubmit(onSubmit, checkValidation)}
                        disabled={isSending}
                      >
                        {t("Save")}
                      </ButtonBlue>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </CardStyle>
        )}
    </>
  );
}

export default AllowanceSettingForm;
