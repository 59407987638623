import React, { useEffect, Fragment } from "react";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  Divider,
  CircularProgress,
  Stack,
  Switch,
  FormGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonIcon from '@mui/icons-material/Person';
import DomainIcon from '@mui/icons-material/Domain';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import dayjs from "dayjs";
import { Controller, useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NumberFormat from "react-number-format";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { useDispatch, useSelector } from "react-redux";
import { updateProbationManager } from "../../../../actions/probation";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";
import {
  answerQuestion5Scale,
  answerQuestion6Scale,
} from "../../assets/data/answerScale";

const NumberFormatTheme = React.forwardRef((props, ref) => {
  return <NumberFormat {...props} getInputRef={ref} />;
});

const percentageScore = (questionList = []) => {
  let totalRating = 0;
  for (const item of questionList) {
    if (item.questionType === "choice") {
      totalRating += (item.answer * item.weight) / 100;
    }
  }

  return Math.round(totalRating);
};

const DialogProbation = ({
  open,
  handleClose,
  probation,
  handleSubmitProbation,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const answerScalce =
    probation.questionSet && probation.questionSet.answerScale === 6
      ? answerQuestion6Scale
      : answerQuestion5Scale;

  const validationSchema = yup.object().shape({
    isExpand: yup.boolean(),
    statusProbation: yup
      .number()
      .nullable()
      .when("isExpand", {
        is: (val) => !val,
        then: (schema) => schema.required(t("PleaseSelectStatus")),
      }),
    questionList: yup.array().when("isExpand", {
      is: (val) => !val,
      then: (schema) =>
        schema.of(
          yup.object().shape({
            answer: yup.string().max(300).required(`${t("ThisFieldIsRequired")}`),
          })
        ),
    }),
    goodPoint: yup
      .string()
      .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    weakPoint: yup
      .string()
      .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    others: yup.string().max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    expandTime: yup
      .number()
      .integer()
      .transform((val, orig) => (orig === "" ? undefined : val))
      .when("isExpand", {
        is: (val) => val,
        then: (schema) =>
          schema
            .min(1, `${t("NotLessThan")} 1 ${t("Unit.Days")}`)
            .max(60, `${t("NotMoreThan")} 60 ${t("Unit.Days")}`)
            .nullable()
            .required(t("ThisFieldIsRequired")),
      }),
    reasonExpandTime: yup.string().when("isExpand", {
      is: (val) => val,
      then: (schema) =>
        schema
          .min(1, t("ThisFieldIsRequired"))
          .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    }),
    reasonTermination: yup.string().when("statusProbation", {
      is: (val) => val === 0,
      then: (schema) =>
        schema
          .min(1, t("ThisFieldIsRequired"))
          .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    }),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      questionList: [],
      goodPoint: "",
      weakPoint: "",
      others: "",
      statusProbation: null,
      isExpand: false,
      expandTime: "",
      reasonExpandTime: "",
      reasonTermination: "",
      comment: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const questionUseFieldArray = useFieldArray({
    control: control,
    name: "questionList",
  });

  const questionList = useWatch({ control, name: "questionList" });
  const isExpand = useWatch({ control, name: "isExpand" });
  const statusProbation = useWatch({ control, name: "statusProbation" });

  const onSubmit = async (formData) => {
    const cleanQuestion = formData.questionList.map((item) => ({
      idProbationQuestion: item.idProbationQuestion,
      answer: item.answer,
    }));

    const cleansingForm = {
      ...formData,
      idProbation: probation.idProbation,
      questionList: cleanQuestion,
      result: formData.statusProbation,
      reasonTermination:
      formData.statusProbation === 0 ? formData.reasonTermination : null,
    };

    const response = await dispatch(updateProbationManager(cleansingForm));
    handleSubmitProbation(response);
  };

  useEffect(() => {
    if (
      probation.questionSet &&
      probation.questionSet.managerQuestion &&
      probation.questionSet.managerQuestion.length > 0
    ) {
      const activeQuestion = probation.questionSet.managerQuestion.map((item) => ({
        idProbationQuestion: item.idProbationQuestion,
        question: item.question,
        questionType: item.questionType,
        weight: item.weight,
        answer: "",
      }));

      questionUseFieldArray.replace(activeQuestion);
    }
  }, [probation]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t("ProbationEvaluationForm")}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2} marginBottom={6}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <PersonIcon />
                  <Typography>
                    {` ${t("PersonBeingAssessed")} : ${
                      probation.firstname_TH
                    } ${probation.lastname_TH}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <DomainIcon />
                  <Typography>
                    {`${t("Position")} : ${probation.positionName}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <AssignmentIcon />
                  <Typography>
                    {`${t("questionSet")} : ${probation.questionSet ? probation.questionSet.questionSetName : t("NoQuestions")}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <AssessmentIcon />
                  <Typography>
                    {`${t("ProbationRound")} : ${probation.probationRound}`}
                  </Typography>
                </Grid>
                {probation.expandTime && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <AddCircleIcon />
                    <Typography>
                      {`${t("ExtendProbationPeriod")}: ${
                        probation.expandTime
                      } ${t("Unit.Days")}`}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <EventIcon />
                  <Typography>
                    {`${t("ProbationaryExpirationDate")} :  ${dayjs(
                      probation.probationDate
                    ).format(
                      i18n.resolvedLanguage === "th"
                        ? "D MMM BBBB"
                        : "D MMM YYYY"
                    )}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <CheckCircleIcon />
                  <Typography>
                    {`${t("EvaluatedBy")} : ${getUserFullName(userProfile)}`}
                  </Typography>
                </Grid>
              </Grid>
              {probation.questionSet && probation.questionSet.employeeQuestion && (
                <>
                  <Typography variant="h6" gutterBottom>{t("Employee")}</Typography>
                  <Stack spacing={1} marginBottom={4}>
                    {probation.questionSet.employeeQuestion.map((question, index) => (
                      <div key={question.idProbationQuestion}>
                        <Typography gutterBottom fontWeight={500}>
                          {`${t("Question")} ${index + 1} : ${question.question}`}
                        </Typography>
                        {question.questionType === "choice" ? (
                          <>
                            <Typography>{`${t("Weight")} ${question.weight}%`}</Typography>
                            <RadioGroup row value={question.answer}>
                              {answerScalce.map((item) => (
                                <FormControlLabel
                                  key={item.key}
                                  value={item.value}
                                  control={<Radio />}
                                  label={t(item.label)}
                                />
                              ))}
                            </RadioGroup>
                          </>
                        ) : (
                          <TextFieldTheme fullWidth value={question.answer} InputProps={{ readOnly: true }} />
                        )}
                        <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
                      </div>
                    ))}
                  </Stack>
                  <div style={{ display: "flex", justifyContent: "center", margin: "16px 0px" }}>
                    <Box
                      style={{ position: "relative", display: "inline-flex" }}
                    >
                      <CircularProgress
                        variant="determinate"
                        style={{
                          color: "#eeeeee",
                        }}
                        size={70}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={percentageScore(probation.questionSet.employeeQuestion)}
                        style={{
                          color: "#007afe",
                          animationDuration: "550ms",
                          position: "absolute",
                          left: 0,
                        }}
                        size={70}
                      ></CircularProgress>
                      <Box
                        style={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontSize: 16 }}
                          component="div"
                          color="text.third"
                        >
                          {percentageScore(probation.questionSet.employeeQuestion)}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </>
              )}

              <Typography variant="h6" gutterBottom>{t("Manager")}</Typography>
              {!probation.expandTime && (
                <Box marginBottom={2}>
                  <Controller
                    control={control}
                    name={"isExpand"}
                    render={({ field }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={<Switch {...field} checked={field.value} />}
                          label={t("ExtendPeriod")}
                        />
                      </FormGroup>
                    )}
                  />
                </Box>
              )}
              {isExpand ? (
                <Stack spacing={2} marginBottom={4}>
                  <div>
                    <Typography
                      marginBottom={"8px"}
                    >{`${t("Duration")} (${t(
                      "Unit.Day"
                    )})`}</Typography>
                    <Controller
                      control={control}
                      name={"expandTime"}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          fullWidth
                          InputProps={{
                            inputComponent: NumberFormatTheme,
                          }}
                          inputProps={{
                            onValueChange: (values) => {
                              const { value } = values;
                              field.onChange(value);
                            },
                            value: field.value,
                          }}
                          onChange={() => {}}
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                    />
                    {watch("expandTime") && (
                      <Typography margin={"0px 8px"} fontWeight={500} color="text.secondary">
                        {`${t("ExtendProbationPeriod")} : ${dayjs(
                          probation.probationDate
                        ).add(watch("expandTime"), "day").format(
                          i18n.resolvedLanguage === "th"
                            ? "D MMM BBBB"
                            : "D MMM YYYY"
                        )}`}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <Typography marginBottom={1}>
                      {t("ReasonForExtendingProbation")}
                    </Typography>
                    <Controller
                      control={control}
                      name={"reasonExpandTime"}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          multiline
                          rows={2}
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                    />
                  </div>
                </Stack>
              ) : (
                <>
                  <Stack spacing={1} marginBottom={4}>
                    {questionUseFieldArray.fields.length > 0 ? (
                      questionUseFieldArray.fields.map((question, index) => (
                        <div key={question.id}>
                          <Typography gutterBottom fontWeight={500}>
                            {`${t("Question")} ${index + 1} : ${question.question}`}
                          </Typography>
                          {question.questionType === "choice" ? (
                            <Controller
                              control={control}
                              name={`questionList.${index}.answer`}
                              render={({ field, fieldState }) => (
                                <>
                                  <Typography>{`${t("Weight")} ${question.weight}%`}</Typography>
                                  <RadioGroup {...field} row>
                                    {answerScalce.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))}
                                  </RadioGroup>
                                  {Boolean(fieldState.error) && (
                                    <FormHelperText sx={{ color: "#ff0000" }}>
                                      {fieldState.error.message}
                                    </FormHelperText>
                                  )}
                                </>
                              )}
                            />
                          ) : (
                            <Controller
                              control={control}
                              name={`questionList.${index}.answer`}
                              render={({ field, fieldState }) => (
                                <TextFieldTheme
                                  {...field}
                                  fullWidth
                                  error={Boolean(fieldState.error)}
                                  helperText={
                                    fieldState.error && fieldState.error.message
                                  }
                                />
                              )}
                            />
                          )}
                          <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
                        </div>
                      ))
                    ) : (
                      <Typography textAlign="center" color="text.secondary">
                        {t("NoQuestions")}
                      </Typography>
                    )}
                  </Stack>
                  <Stack spacing={2} marginBottom={4}>
                    <div>
                      <Typography gutterBottom>{t("Strength")}</Typography>
                      <Controller
                        control={control}
                        name={`goodPoint`}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            autoComplete="goodPoint"
                            multiline
                            rows={2}
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Typography gutterBottom>{t("Weakness")}</Typography>
                      <Controller
                        control={control}
                        name={`weakPoint`}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            multiline
                            rows={2}
                            autoComplete="weakPoint"
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Typography gutterBottom>{t("Other")}</Typography>
                      <Controller
                        control={control}
                        name={`others`}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            multiline
                            rows={2}
                            autoComplete="others"
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Typography gutterBottom>
                        {t("FeedbackFromSupervisor")}
                      </Typography>
                      <Controller
                        control={control}
                        name={"comment"}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            multiline
                            rows={2}
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "16px",
                      }}
                    >
                      <Box
                        style={{ position: "relative", display: "inline-flex" }}
                      >
                        <CircularProgress
                          variant="determinate"
                          style={{
                            color: "#eeeeee",
                          }}
                          size={70}
                          thickness={4}
                          value={100}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={percentageScore(questionList)}
                          style={{
                            color: "#007afe",
                            animationDuration: "550ms",
                            position: "absolute",
                            left: 0,
                          }}
                          size={70}
                        ></CircularProgress>
                        <Box
                          style={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{ fontSize: 16 }}
                            component="div"
                            color="text.third"
                          >
                            {percentageScore(questionList)}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name={"statusProbation"}
                        render={({ field, fieldState }) => (
                          <Fragment>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                align: "center",
                              }}
                            >
                              <ButtonBlue
                                sx={{ backgroundColor: "#4caf50" }}
                                variant="contained"
                                disabled={statusProbation === 1}
                                onClick={() => field.onChange(1)}
                              >
                                {t("Pass")}
                              </ButtonBlue>
                              <ButtonBlue
                                sx={{ backgroundColor: "#ef5350" }}
                                variant="contained"
                                disabled={statusProbation === 0}
                                onClick={() => field.onChange(0)}
                              >
                                {t("NotPass")}
                              </ButtonBlue>
                            </div>
                            {Boolean(fieldState.error) && (
                              <FormHelperText sx={{ color: "#ff0000" }}>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </Fragment>
                        )}
                      />
                    </div>
                  </Stack>
                  {statusProbation === 0 && (
                    <Box marginBottom={4}>
                      <Typography marginBottom={1}>
                        {t("ReasonForNotPassingProbation")}
                      </Typography>
                      <Controller
                        control={control}
                        name={"reasonTermination"}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            multiline
                            rows={2}
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        )}
                      />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonBlue type="button" onClick={handleClose}>
            {t("Close")}
          </ButtonBlue>
          <ButtonBlue type="submit" variant="contained" disabled={isSubmitting}>
            {t("SaveData")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogProbation;
