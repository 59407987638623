import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, CardActionArea } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";

import StyledCard from "../../shared/general/Card";

import CustomPlan from "./customPlan";
import ListEmployee from "./listEmployee";

import { getShift } from "../../../../actions/shift";
import DrawerShiftFeeSetting from "./DrawerShiftFeeSetting";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import { getAllHolidaysPattern } from "../../../../actions/holiday";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DrawerShiftGroupSetting from "./DrawerShiftGroupSetting";

const StyledRoot = styled("div")({
  // minHeight: 700,
  // paddingBottom: "24px",
  backgroundColor: "#FFFFFF !important",
});

const StyledAvatar = styled(Avatar)(({ shift }) => ({
  width: 30,
  height: 30,
  ...(shift === `A` && {
    backgroundColor: "#ffb94e",
  }),
  ...(shift === `D` && {
    backgroundColor: "#fe6c76",
  }),
  ...(shift === `C` && {
    backgroundColor: "#29a2e0",
  }),
  ...(shift === `B` && {
    backgroundColor: "#02bbb5",
  }),
}));

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
});

const StyledCardAdd = styled(Card)({
  height: 268,
  border: "1px #919eab52 dashed",
  borderRadius: 20,
  backgroundColor: "transparent",
  "& .cardActionArea": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  "& .cardContent": {
    padding: "16px !important",
    width: "100%",
    height: 204,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#b1b1b11f",
    margin: 16,
    borderRadius: 16,
  },
});

const StyledCardShift = styled("div")({
  padding: 16,
  "& .part-one": {
    display: "flex",
    justifyContent: "space-between",
    "& .start-create-date": {
      display: "flex",
      flexDirection: "column",
      "& .inner": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  },
  "& .partTwo": {
    boxShadow: "#f4f6f8 0px 8px 16px 0px",
    backgroundColor: "#ecf7ff",
    color: "#007afe",
    borderRadius: 16,
    marginTop: 8,
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  "& .partThree": {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-between",
    "&.day": {
      marginTop: 8,
    },
  },
  "& .partFour": {
    height: 30,
    marginTop: 16,
    display: "flex",
    justifyContent: "space-evenly",
  },
});

const StyledPleaseSelectCompany = styled("div")({
  padding: 18,
  width: "100%",
  "& .MuiTypography-root": {
    fontStyle: "oblique",
    letterSpacing: 2,
  },
});

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function ShiftVendorPage(props) {
  const { result: shiftGroupList } = useSelector((state) => state.shift);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const { result: holidayPattern } = useSelector((state) => state.holidayPattern);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);
  const [menuSelected, setMenuSelected] = React.useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [holidayPatternList, setHolidayPatternList] = useState([]);

  const [drawerShiftSettingConfig, setDrawerShiftSettingConfig] = useState({
    isOpen: false,
    data: {},
  });

  const [drawerShiftGroupSettingConfig, setDrawerShiftGroupSettingConfig] = useState({
    isOpen: false,
    data: {},
  })

  const OrderShiftName = ["A", "B", "C", "D", "E", "F"];

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (userProfile && affiliateOrganizationList.length > 0) {
      setSelectedCompany(
        affiliateOrganizationList.find(
          (x) => x.idCompany === userProfile.idCompany
        ) || null
      );
    }
  }, [userProfile, affiliateOrganizationList]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getShift({ idCompany: selectedCompany.idCompany }));
      dispatch(getAllHolidaysPattern({
        start: dayjs()
          .set("date", 1)
          .set("month", 0)
          .format("YYYY-MM-DD"),
        end: dayjs()
          .set("date", 31)
          .set("month", 11)
          .format("YYYY-MM-DD"),
        idCompany: selectedCompany.idCompany,
      }))
    }
  }, [selectedCompany]);

  useEffect(() => {
    if(holidayPattern && Object.keys(holidayPattern) > 0){
      let objCurYear = holidayPattern[dayjs().format("YYYY")];

      if(!objCurYear || Object.keys(objCurYear).length <= 0){
        setHolidayPatternList([]);
      }else{
        let tempList = [];
        Object.keys(objCurYear).forEach((id) => {
          if(!objCurYear[id].isMainPattern){
            tempList.push({...objCurYear[id], idHolidayPattern : parseInt(id)});
          }
        });
        setHolidayPatternList(tempList);
      }
    }else{
      setHolidayPatternList([]);
    }
  },[holidayPattern]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const displayTimeType = (shiftType, typeEng) => {
    let FilterType = shiftType
      ? shiftType.find((shift) =>
          shift.shiftTypeName.toLowerCase().includes(typeEng)
        )
      : undefined;

    if (typeEng === "day") {
      FilterType = shiftType
        ? shiftType.find((shift) => shift.period === 1)
        : undefined;
    } else if (typeEng === "mid") {
      FilterType = shiftType
        ? shiftType.find((shift) => shift.period === 2)
        : undefined;
    } else if (typeEng === "night") {
      FilterType = shiftType
        ? shiftType.find((shift) => shift.period === 3)
        : undefined;
    }

    if (!FilterType) {
      if (typeEng === "วันทำงาน") {
        FilterType = shiftType
          ? shiftType.find((shift) => shift.period === 1)
          : undefined;
      }
    }

    if (FilterType) {
      let splitStartTime = FilterType.timeIn.split(":");
      let splitEndTime = FilterType.timeOut.split(":");
      let startTime = dayjs()
        .hour(splitStartTime[0])
        .minute(splitStartTime[1])
        .format("HH:mm");
      let endTime = dayjs()
        .hour(splitEndTime[0])
        .minute(splitEndTime[1])
        .format("HH:mm");
      return `${startTime} - ${endTime}`;
    } else {
      return "-";
    }
  };

  const handleClickAll = () => {
    setValueSelected(null);
    setMenuSelected(null);
    setBreadcrumbs([]);
  };

  const handleClickListName = () => {
    setMenuSelected("list");
    let temp = [];
    temp.push(
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleClickAll}
        style={{ cursor: "pointer" }}
      >
        {t("All")}
      </Link>
    );
    temp.push(
      <Link
        underline="hover"
        key="2"
        color="inherit"
        onClick={handleClickListName}
        style={{ cursor: "pointer" }}
      >
        {valueSelected.shiftGroupName}
      </Link>
    );
    setBreadcrumbs(temp);
  };

  const handleClickEditTimeWorking = () => {
    setMenuSelected("custom");
    let temp = [...breadcrumbs];
    temp.push(
      <Link
        underline="hover"
        key="3"
        color="inherit"
        style={{ cursor: "pointer" }}
      >
        {t("EditWorkingTime")}
      </Link>
    );
    setBreadcrumbs(temp);
  };

  const onDrawerShiftSettingClose = () => {
    setDrawerShiftSettingConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleOpenDrawerShiftGroupSetting = (data) => {
    setDrawerShiftGroupSettingConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        idShiftGroup: data.idShiftGroup,
        shiftGroupCode: data.shiftGroupCode,
        shiftGroupCodeCustom: data.shiftGroupCodeCustom,
        isCustomShiftGroupCode: data.isCustomShiftGroupCode,
        idCompany: selectedCompany.idCompany,
        holidayPatternList: data.holidayPattern,
        idHolidayPattern: data.idHolidayPattern,
        shiftStartDate: data.shiftStartDate
      }
    }))
  }

  const handleCloseDrawerShiftGroupSetting = () => {
    setDrawerShiftGroupSettingConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("ShiftManagement")}
          </Typography>
        </div>
        <Box marginBottom="24px">
          <SelectAffiliateCompany
            options={affiliateOrganizationList}
            value={selectedCompany}
            disabled={menuSelected !== null}
            onChange={(_, value) => {
              onChangeCompany(value);
            }}
          />
        </Box>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Fragment>
          {menuSelected === null && (
            <Fragment>
              <div style={{ marginTop: 16, paddingBottom: 20 }}>
                <Grid container spacing={3}>
                  {shiftGroupList &&
                    shiftGroupList.map((shiftGroup) => (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        key={shiftGroup.idShiftGroup}
                      >
                        <StyledCard
                          style={{ marginTop: "0 !important", height: "100%" }}
                        >
                          <StyledCardShift style={{ height: "inherit" }}>
                            <div className={`part-one`}>
                              <div className={`start-create-date`}>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  style={{ fontSize: 14 }}
                                >
                                  {t("Start")}:{" "}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  style={{ fontSize: 14, fontWeight: 500 }}
                                >
                                  {dayjs(shiftGroup.shiftStartDate).format(
                                    i18n.resolvedLanguage === "th"
                                      ? "DD/MM/BBBB"
                                      : "DD/MM/YYYY"
                                  )}
                                </Typography>
                              </div>
                              <div>
                                <div className={`inner`}>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={(event) => {
                                      setValueSelected(shiftGroup);
                                      setAnchorEl(event.currentTarget);
                                    }}
                                  >
                                    <MoreVertIcon fontSize="inherit" />
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                            <div className={`partTwo`}>
                              <Typography variant="h5">
                                {shiftGroup.shiftGroupName}
                              </Typography>
                              <Typography fontSize="12px" color="text.secondary">
                                ({shiftGroup.isCustomShiftGroupCode? shiftGroup.shiftGroupCodeCustom: shiftGroup.shiftGroupCode})
                              </Typography>
                            </div>
                            <Box marginTop="16px">
                              <Typography
                                fontSize="12px"
                                color="text.secondary"
                                textAlign="right"
                              >
                                ({t("ShiftValue")})
                              </Typography>
                            </Box>
                            {shiftGroup.idWorkingType === 1 ? (
                              <div style={{ minHeight: "126px" }}>
                                <div className={`partThree`}>
                                  <Typography color="text.secondary">
                                    {t("DayShift")}:{" "}
                                  </Typography>
                                  <Typography>
                                    {displayTimeType(
                                      shiftGroup.shiftType,
                                      "day"
                                    )}{" "}
                                    {shiftGroup.shiftType.find(
                                      (x) => x.period === 1
                                    ) && (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "#919eab",
                                        }}
                                      >
                                        (
                                        {shiftGroup.shiftFee &&
                                        shiftGroup.shiftFee.morningShiftFee
                                          ? shiftGroup.shiftFee.morningShiftFee
                                          : `${t("NoFee")}`}
                                        )
                                      </span>
                                    )}
                                  </Typography>
                                </div>
                                <div className={`partThree`}>
                                  <Typography color="text.secondary">
                                    {t("MidShift")}:{" "}
                                  </Typography>
                                  <Typography>
                                    {displayTimeType(
                                      shiftGroup.shiftType,
                                      "mid"
                                    )}{" "}
                                    {shiftGroup.shiftType.find(
                                      (x) => x.period === 2
                                    ) && (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "#919eab",
                                        }}
                                      >
                                        (
                                        {shiftGroup.shiftFee &&
                                        shiftGroup.shiftFee.afternoonShiftFee
                                          ? shiftGroup.shiftFee
                                              .afternoonShiftFee
                                          : `${t("NoFee")}`}
                                        )
                                      </span>
                                    )}
                                  </Typography>
                                </div>
                                <div className={`partThree`}>
                                  <Typography color="text.secondary">
                                    {t("NightShift")}:
                                  </Typography>
                                  <Typography>
                                    {displayTimeType(
                                      shiftGroup.shiftType,
                                      "night"
                                    )}{" "}
                                    {shiftGroup.shiftType.find(
                                      (x) => x.period === 3
                                    ) && (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "#919eab",
                                        }}
                                      >
                                        (
                                        {shiftGroup.shiftFee &&
                                        shiftGroup.shiftFee.nightShiftFee
                                          ? shiftGroup.shiftFee.nightShiftFee
                                          : `${t("NoFee")}`}
                                        )
                                      </span>
                                    )}
                                  </Typography>
                                </div>
                                <Box marginTop="8px" display="flex">
                                  <Typography
                                    flexGrow="1"
                                    color="text.secondary"
                                  >
                                    {t("BreakTime")}:
                                  </Typography>
                                  <Typography>
                                    {shiftGroup.breakTime === 1
                                      ? `${t("WorkBreak")}`
                                      : `${dayjs(
                                          shiftGroup.startBreak,
                                          "HH:mm:ss"
                                        ).format("HH:mm")} - ${dayjs(
                                          shiftGroup.startBreak,
                                          "HH:mm:ss"
                                        )
                                          .add(
                                            shiftGroup.breakTimeMin,
                                            "minute"
                                          )
                                          .format("HH:mm")}`}
                                  </Typography>
                                </Box>
                                <div className={`partFour`}>
                                  {shiftGroup.shiftNumber &&
                                    shiftGroup.shift &&
                                    shiftGroup.shift.map((value, index) => (
                                      <StyledAvatar
                                        shift={`${OrderShiftName[index]}`}
                                        key={`shift_${value.idShift}`}
                                      >
                                        {OrderShiftName[index]}
                                      </StyledAvatar>
                                    ))}
                                </div>
                              </div>
                            ) : (
                              <div style={{ minHeight: "126px" }}>
                                <div className={`partThree day`}>
                                  <Typography color="text.secondary">
                                    {t("ShiftType")}:{" "}
                                  </Typography>
                                  <Typography style={{ fontWeight: 500 }}>
                                    {displayTimeType(
                                      shiftGroup.shiftType,
                                      "วันทำงาน"
                                    )}{" "}
                                    {
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "#919eab",
                                        }}
                                      >
                                        (
                                        {shiftGroup.shiftFee &&
                                        shiftGroup.shiftFee.morningShiftFee
                                          ? shiftGroup.shiftFee.morningShiftFee
                                          : `${t("NoFee")}`}
                                        )
                                      </span>
                                    }
                                  </Typography>
                                </div>
                                <Box marginTop="8px" display="flex">
                                  <Typography
                                    flexGrow="1"
                                    color="text.secondary"
                                  >
                                    {t("BreakTime")}:
                                  </Typography>
                                  <Typography fontWeight="500">
                                    {shiftGroup.breakTime === 1
                                      ? t("StartBreak")
                                      : `${dayjs(
                                          shiftGroup.startBreak,
                                          "HH:mm:ss"
                                        ).format("HH:mm")} - ${dayjs(
                                          shiftGroup.startBreak,
                                          "HH:mm:ss"
                                        )
                                          .add(
                                            shiftGroup.breakTimeMin,
                                            "minute"
                                          )
                                          .format("HH:mm")}`}
                                  </Typography>
                                </Box>
                              </div>
                            )}
                          </StyledCardShift>
                        </StyledCard>
                      </Grid>
                    ))}
                </Grid>
                <StyledMenu
                  id={`more-menu`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                    onClick={() => {
                      handleOpenDrawerShiftGroupSetting(valueSelected)
                      handleCloseMenu();
                    }}
                  >
                    {t("Setting")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      handleClickListName();
                    }}
                  >
                    {t("ListShiftEmployees")}
                  </MenuItem>
                  {/* {valueSelected && (
                    <MenuItem
                      component={NavLink}
                      to={`/vendor/shift/year/" + ${valueSelected.idShiftGroup}`}
                    >
                      ตารางกะรายปี
                    </MenuItem>
                  )} */}
                  {/* <MenuItem>แก้ไขตารางกะ</MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      setDrawerShiftSettingConfig((prev) => ({
                        ...prev,
                        isOpen: true,
                        data: {
                          idShiftGroup: valueSelected.idShiftGroup,
                          hasMorningShift: valueSelected.shiftType.find(
                            (x) => x.period === 1
                          )
                            ? true
                            : false,
                          hasAfternoongShift: valueSelected.shiftType.find(
                            (x) => x.period === 2
                          )
                            ? true
                            : false,
                          hasNightShift: valueSelected.shiftType.find(
                            (x) => x.period === 3
                          )
                            ? true
                            : false,
                          morningShiftFee:
                            valueSelected.shiftFee &&
                            valueSelected.shiftFee.morningShiftFee
                              ? valueSelected.shiftFee.morningShiftFee
                              : null,
                          afternoonShiftFee:
                            valueSelected.shiftFee &&
                            valueSelected.shiftFee.afternoonShiftFee
                              ? valueSelected.shiftFee.afternoonShiftFee
                              : null,
                          nightShiftFee:
                            valueSelected.shiftFee &&
                            valueSelected.shiftFee.nightShiftFee
                              ? valueSelected.shiftFee.nightShiftFee
                              : null,
                          shiftFeeHistory: valueSelected.shiftFeeHistory,
                          shiftFeeInAdvance: valueSelected.shiftFeeInAdvance,
                        },
                      }));
                    }}
                  >
                    {t("EditShiftFee")}
                  </MenuItem>
                </StyledMenu>
              </div>
            </Fragment>
          )}
          {menuSelected === "list" && (
            <ListEmployee
              selectedCompany={selectedCompany}
              shiftGroup={valueSelected}
              handleClickEditTimeWorking={handleClickEditTimeWorking}
            />
          )}
          {menuSelected === "custom" && (
            <CustomPlan shiftGroup={valueSelected} />
          )}
        </Fragment>
      </Container>

      <DrawerShiftGroupSetting
        drawerConfig={drawerShiftGroupSettingConfig}
        handleClose={handleCloseDrawerShiftGroupSetting}
        isReadOnly={userProfile && userProfile.readOnly}
      />

      <DrawerShiftFeeSetting
        drawerConfig={drawerShiftSettingConfig}
        onClose={onDrawerShiftSettingClose}
        isReadOnly={userProfile && userProfile.readOnly}
      />
    </StyledRoot>
  );
}
