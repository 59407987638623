import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  MenuItem,
  Typography,
  createFilterOptions,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import utils from "../../../../../../utils";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import dayjs from "dayjs";
import {
  addDeleteEmployeePersonnelLevel,
  addDeleteEmployeePosition,
  addDeleteEmployeeSalary,
  addEmployeeInformationChange,
  getEmployeePersonnelLevelChange,
  getEmployeePositionChange,
  getEmployeeProfile,
  getEmployeeSalaryChange,
} from "../../../../../../actions/employee";
import { getAffiliateOrganization } from "../../../../../../actions/company";
import { getAllPositions } from "../../../../../../actions/positions";
import { getManager } from "../../../../../../actions/manager";
import { getSalaryReasons } from "../../../../../../actions/salaryReasons";
import DatePickerCustom from "../../../../shared/date/datePicker";
import { getUserCompany, getUserFullName } from "../../../../../../utils/userData";
import { getLevelByidCompany } from "../../../../../../actions/level";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const FlowForm = (props) => {
  const { drawerConfig, handleClose, defaultPage } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [page, setPage] = useState("position");
  const [formStateData, setFormStateData] = useState({});
  // const [nextPage, setNextPage] = useState("")

  const validateYupSchema = yup.object({
    positionData: yup
      .object({
        idPosition:
          defaultPage === "position"
            ? yup.string().required(`${t("ThisFieldIsRequired")}`).test("cantSameAsPrevProsition", t("ไม่สามารถเลือกตำแหน่งงานเดิมได้"), (value, _) => {
              return (!employeeProfile) || Number(employeeProfile.idPosition) !== Number(value)
            })
            : undefined,
        idManagerLV1:
          defaultPage === "position"
            ? yup.string().required(`${t("ThisFieldIsRequired")}`)
            : undefined,
        idManagerLV2:
          defaultPage === "position"
            ? yup.string().required(`${t("ThisFieldIsRequired")}`)
            : undefined,
      })
      .nullable(),
    personnelLevelData: yup
      .object({
        idLevel:
          defaultPage === "personnelLevel"
            ? yup.string().required(`${t("ThisFieldIsRequired")}`)
            : undefined,
      })
      .nullable(),
    salaryData: yup
      .object({
        salaryValue:
          defaultPage === "salary"
            ? yup.string().required(`${t("ThisFieldIsRequired")}`)
            : undefined,
      })
      .nullable(),
  });

  const useHookForm = useForm({
    defaultValues: {
      positionData: {
        company: null,
        idPosition: "",
        description: "",
        idManagerLV1: "",
        idManagerLV2: "",
        start: new Date(),
      },
      personnelLevelData: {
        idLevel: "",
        remark: "",
        start: new Date(),
      },
      salaryData: {
        salaryValue: "",
        idSalaryReason: "",
        reason: "",
        remark: "",
        start: new Date(),
      },
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const displayTitle = () => {
    switch (page) {
      case "position": {
        return `${t("JobTransfer")}`;
      }
      case "personnelLevel": {
        return `${t("AdjustEmployeeLevel")}`;
      }
      case "salary": {
        return `${t("AdjustSalary")}`;
      }
      case "prePosition": {
        return `${t("JobTransfer?")}`;
      }
      case "prePersonnelLevel": {
        return `${t("AdjustEmployeeLevel?")}`;
      }
      case "preSalary": {
        return `${t("AdjustSalary?")}`;
      }
    }
  };

  const handleClosePreForm = () => {
    switch (page) {
      case "prePosition": {
        return setPage("prePersonnelLevel");
      }
      case "prePersonnelLevel": {
        return setPage("preSalary");
      }
      case "preSalary": {
        return handleClose();
      }
    }
  };

  const handleFormSubmit = async () => {
    const preData = useHookForm.getValues();

    const formData = {};

    if (preData.positionData && preData.positionData.idPosition) {
      formData.positionData = {};
      formData.positionData.idPosition = preData.positionData.idPosition;
      formData.positionData.description =
        String(preData.positionData.description) || null;
      formData.positionData.idManagerLV1 = preData.positionData.idManagerLV1;
      formData.positionData.idManagerLV2 = preData.positionData.idManagerLV2;
      formData.positionData.start = dayjs(preData.positionData.start).format(
        "YYYY-MM-DD"
      );
      formData.positionData.idEmployees = employeeProfile.idEmployees;
      // formData.positionData.idEmployeePositionOld = employeeProfile.idEmployeePosition;
    }
    if (preData.personnelLevelData && preData.personnelLevelData.idLevel) {
      formData.personnelLevelData = {};
      formData.personnelLevelData.idLevel = preData.personnelLevelData.idLevel;
      formData.personnelLevelData.remark =
        String(preData.personnelLevelData.remark) || null;
      formData.personnelLevelData.start = dayjs(
        preData.personnelLevelData.start
      ).format("YYYY-MM-DD");
      formData.personnelLevelData.idAdmin = userProfile.idEmployees;
      formData.personnelLevelData.idEmployees = employeeProfile.idEmployees;
      // formData.personnelLevelData.idEmployeePersonnelLevelOld = employeeProfile.idEmployeePersonnelLevel || null;
    }
    if (preData.salaryData && preData.salaryData.salaryValue) {
      formData.salaryData = {};
      formData.salaryData.salaryValue = preData.salaryData.salaryValue;
      formData.salaryData.remark = String(preData.salaryData.remark) || null;
      formData.salaryData.start = dayjs(preData.salaryData.start).format(
        "YYYY-MM-DD"
      );
      formData.salaryData.idSalaryReason = preData.salaryData.idSalaryReason;
      formData.salaryData.idAdmin = userProfile.idEmployees;
      formData.salaryData.idEmployees = employeeProfile.idEmployees;
      // formData.salaryData.idEmployeePersonnelLevelOld = employeeProfile.idEmployeePersonnelLevel || null;
    }

    const result = await dispatch(addEmployeeInformationChange(formData));

    if (result && result.status === 200) {
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      handleClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      if (defaultPage === "position") {
        dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
      } else if (defaultPage === "personnelLevel") {
        dispatch(getEmployeePersonnelLevelChange(employeeProfile.idEmployees));
      } else if (defaultPage === "salary") {
        dispatch(getEmployeeSalaryChange(employeeProfile.idEmployees));
      }
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: result.data.message? result.data.message: t("AnErrorOccurred"),
      }));
    }
  };

  useLayoutEffect(() => {
    if (drawerConfig.isOpen) {
      useHookForm.reset({
        positionData: {
          company: null,
          idPosition: "",
          description: "",
          idManagerLV1: "",
          idManagerLV2: "",
          newPositionName: "",
          start: new Date(),
        },
        personnelLevelData: {
          idLevel: "",
          remark: "",
          start: new Date(),
        },
        salaryData: {
          salaryValue: "",
          remark: "",
          start: new Date(),
        },
      });
      if (defaultPage) {
        setPage(defaultPage);
      }
      // setFormStateData({});
      dispatch(getAffiliateOrganization());
    }
  }, [drawerConfig.isOpen]);

  // useEffect(() => {
  //   if(defaultPage === "position"){
  //     if(page === "personnelLevel"){
  //       dispatch(getLevelByidCompany({idCompany: useHookForm.getValues("positionData.company").idCompany}))
  //     }
  //   }
  // }, [page])

  return (
    <DrawerCustom
      title={displayTitle()}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        {page === "position" && (
          <PositionForm
            setPage={setPage}
            handleClose={handleClose}
            defaultPage={defaultPage}
            formStateData={formStateData}
            setFormStateData={setFormStateData}
            useHookForm={useHookForm}
          />
        )}
        {page === "personnelLevel" && (
          <PersonnelLevelForm
            setPage={setPage}
            handleClose={handleClose}
            defaultPage={defaultPage}
            formStateData={formStateData}
            setFormStateData={setFormStateData}
            useHookForm={useHookForm}
          />
        )}
        {page === "salary" && (
          <SalaryForm
            setPage={setPage}
            handleClose={handleClose}
            defaultPage={defaultPage}
            formStateData={formStateData}
            setFormStateData={setFormStateData}
            useHookForm={useHookForm}
            handleFormSubmit={handleFormSubmit}
          />
        )}
        {["prePersonnelLevel", "preSalary"].includes(page) && (
          <ConfirmNextPage
            setPage={setPage}
            page={page}
            handleClose={handleClosePreForm}
          />
        )}
      </StyledRoot>
    </DrawerCustom>
  );
};

const PositionForm = (props) => {
  const {
    setPage,
    handleClose,
    defaultPage,
    formStateData,
    setFormStateData,
    useHookForm,
  } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: positionList } = useSelector((state) => state.positions);
  const { result: managerList } = useSelector((state) => state.manager);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [nextPosition, setNextPosition] = useState({
    positionName: "",
    sectionName: "",
    departmentName: "",
    divisionName: "",
  });

  const validateYupSchema = yup.object({
    idPosition:
      defaultPage === "position"
        ? yup.string().required(`${t("ThisFieldIsRequired")}`)
        : undefined,
    idManagerLV1:
      defaultPage === "position"
        ? yup.string().required(`${t("ThisFieldIsRequired")}`)
        : undefined,
    idManagerLV2:
      defaultPage === "position"
        ? yup.string().required(`${t("ThisFieldIsRequired")}`)
        : undefined,
    start:
      defaultPage === "position"
        ? yup
            .date()
            .required(`${t("ThisFieldIsRequired")}`)
            .nullable()
        : undefined,
  });

  const filterOptions = createFilterOptions({
    stringify: (value) =>
      `${value.positionName} ${value.positionName_EN} ${value.positionCode}`,
  });

  const filterManagerOptions = createFilterOptions({
    stringify: (value) =>
      `${value.firstname_TH} ${value.lastname_TH} ${value.email}`,
  });

  const onSubmit = async (data) => {
    const formData = { ...data };

    formData.start = dayjs(formData.start).format("YYYY-MM-DD");
    formData.idEmployees = employeeProfile.idEmployees;
    formData.idEmployeePositionOld = employeeProfile.idEmployeePosition;
    formData.end = null;
    formData.isActive = 1;

    Object.keys(formData).map((k) => {
      if (formData[k] === "") {
        formData[k] = null;
      }
    });

    // console.log(formData)

    if (
      formData.idPosition === employeeProfile.idPosition &&
      formData.idManagerLV1 === employeeProfile.managerLV1_idManagerLV1 &&
      formData.idManagerLV2 === employeeProfile.managerLV2_idManagerLV2
    ) {
      alert(`${t("NoDataChange")}`);
    } else {
      formData.method = "add";

      setFormStateData((prev) => ({
        ...prev,
        positionData: formData.idPosition ? formData : null,
        personnelLevelData:
          !prev.personnelLevelData ||
          (prev.personnelLevelData && !prev.personnelLevelData.remark)
            ? { remark: "เปลี่ยนตำแหน่งงาน\n1 เป็น\n2" }
            : prev.personnelLevelData,
      }));
      setPage("personnelLevel");

      // let result = await dispatch(addDeleteEmployeePosition(formData));

      // if (result) {
      //   dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      //   dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
      //   setPage("prePersonnelLevel")
      // }
    }
  };

  const onNextPage = async () => {
    if (useHookForm.getValues("positionData.idPosition")) {
      // console.log("1", useHookForm.getValues("personnelLevelData.remark"))
      if (!useHookForm.getValues("personnelLevelData.remark")) {
        // console.log("2", useHookForm.getValues("personnelLevelData.remark"))
        useHookForm.setValue(
          "personnelLevelData.remark",
          `เปลี่ยนตำแหน่งงาน\nจาก ${employeeProfile.positionName}\nเป็น ${nextPosition.positionName}`
        );
      }
    }

    await useHookForm.trigger();

    // console.log(useHookForm.formState.errors)

    if (!useHookForm.formState.errors.positionData) {
      setPage("personnelLevel");
    }
  };

  useEffect(() => {
    if (useHookForm.getValues("positionData.idPosition")) {
      const value = positionList
        ? positionList.find(
            (option) =>
              Number(option.idPosition) ===
              Number(useHookForm.getValues("positionData.idPosition"))
          ) || null
        : null;
      if (value) {
        setNextPosition((prev) => ({
          ...prev,
          positionName: value.positionName,
          sectionName: value.sectionName,
          departmentName: value.departmentName,
          divisionName: value.divisionName,
          businessUnitName: value.businessUnitName,
        }));
      }
    }
  }, []);

  const companyWatch = useHookForm.watch("positionData.company");

  return (
    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="16px">
            {`${t("CurrentData")}`}
          </Typography>
        </Grid>
        {employeeProfile && (
          <Fragment>
            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {t("Company")}
              </Typography>
              {getUserCompany(employeeProfile) ? (
                <Typography fontWeight="500">
                  {getUserCompany(employeeProfile)}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {t("NoFee")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Position")}`}
              </Typography>
              {employeeProfile.positionName ? (
                <Typography fontWeight="500">
                  {employeeProfile.positionName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Section")}`}
              </Typography>
              {employeeProfile.sectionName ? (
                <Typography fontWeight="500">
                  {employeeProfile.sectionName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Department")}`}
              </Typography>
              {employeeProfile.departmentName ? (
                <Typography fontWeight="500">
                  {employeeProfile.departmentName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Division")}`}
              </Typography>
              {employeeProfile.divisionName ? (
                <Typography fontWeight="500">
                  {employeeProfile.divisionName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("BusinessUnit")}`}
              </Typography>
              {employeeProfile.businessUnitName ? (
                <Typography fontWeight="500">
                  {employeeProfile.businessUnitName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("ManagerLV")}`} 1
              </Typography>
              {employeeProfile.idManagerLV1 ? (
                <Fragment>
                  <Typography fontWeight="500">
                    {getUserFullName({
                      firstname_TH: employeeProfile.managerLV1_firstname_TH,
                      lastname_TH: employeeProfile.managerLV1_lastname_TH,
                      firstname_EN: employeeProfile.managerLV1_firstname_EN,
                      lastname_EN: employeeProfile.managerLV1_lastname_EN,
                    })}
                  </Typography>
                  <Typography fontSize="14px">
                    {employeeProfile.managerLV1_positionName}
                  </Typography>
                  <Typography fontSize="14px">
                    {employeeProfile.managerLV1_email}
                  </Typography>
                </Fragment>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("ManagerLV")}`} 2
              </Typography>
              {employeeProfile.idManagerLV2 ? (
                <Fragment>
                  <Typography fontWeight="500">
                    {getUserFullName({
                      firstname_TH: employeeProfile.managerLV2_firstname_TH,
                      lastname_TH: employeeProfile.managerLV2_lastname_TH,
                      firstname_EN: employeeProfile.managerLV2_firstname_EN,
                      lastname_EN: employeeProfile.managerLV2_lastname_EN,
                    })}
                  </Typography>
                  <Typography fontSize="14px">
                    {employeeProfile.managerLV2_positionName}
                  </Typography>
                  <Typography fontSize="14px">
                    {employeeProfile.managerLV2_email}
                  </Typography>
                </Fragment>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{
                  borderColor: "rgba(145, 158, 171, 0.32)",
                  borderStyle: "dashed",
                }}
              />
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >{`${t("Company")}`}</Typography>
          <Controller
            name="positionData.company"
            control={useHookForm.control}
            render={({ field, fieldState }) => (
              <Autocomplete
                options={
                  affiliateOrganizationList ? affiliateOrganizationList : []
                }
                getOptionLabel={(option) => `${getUserCompany(option)}`}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idCompany}>
                    <Box>
                      <Typography>{getUserCompany(option)}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder={`${t("Search")}`}
                    onBlur={field.onBlur}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
                value={field.value}
                onChange={(_, value) => {
                  field.onChange(value);
                  if (value) {
                    dispatch(getAllPositions({ idCompany: value.idCompany }));
                    dispatch(getLevelByidCompany({idCompany: value.idCompany}))
                    // dispatch(getManager({ idCompany: value.idCompany }));
                    dispatch(getManager());
                  }
                  if (value) {
                    useHookForm.setValue("positionData.idPosition", "");
                    useHookForm.setValue("positionData.idManagerLV1", "");
                    useHookForm.setValue("positionData.idManagerLV2", "");
                    useHookForm.setValue("positionData.newPositionName", "");
                    setNextPosition((prev) => ({
                      ...prev,
                      positionName: null,
                      sectionName: null,
                      departmentName: null,
                      divisionName: null,
                      businessUnitName: null,
                    }));
                  }
                }}
                noOptionsText="ไม่พบข้อมูล"
                disableClearable
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("NewPosition")}`}
          </Typography>
          <Controller
            name="positionData.idPosition"
            control={useHookForm.control}
            render={({ field }) => (
              <Autocomplete
                options={positionList ? positionList : []}
                getOptionLabel={(option) => `${option.positionName}`}
                filterOptions={filterOptions}
                disabled={!companyWatch}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idPosition}>
                    <Box>
                      <Typography color="text.secondary" fontSize="12px">
                        {option.positionCode}
                      </Typography>
                      <Typography>{option.positionName}</Typography>
                      <Typography color="text.secondary" fontSize="14px">
                        {option.divisionName || "-"}/
                        {option.departmentName || "-"}/
                        {option.sectionName || "-"}
                      </Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder={`${t("Search")}`}
                    onBlur={field.onBlur}
                    error={
                      useHookForm.formState.errors["positionData"] &&
                      useHookForm.formState.errors["positionData"]["idPosition"]
                        ? true
                        : false
                    }
                    helperText={
                      useHookForm.formState.errors["positionData"] &&
                      useHookForm.formState.errors["positionData"]["idPosition"]
                        ? useHookForm.formState.errors["positionData"][
                            "idPosition"
                          ].message
                        : null
                    }
                  />
                )}
                value={
                  positionList
                    ? positionList.find(
                        (option) =>
                          Number(option.idPosition) === Number(field.value)
                      ) || null
                    : null
                }
                onChange={(_, value) => {
                  field.onChange(value ? value.idPosition : "");
                  if (value) {
                    useHookForm.setValue(
                      "positionData.newPositionName",
                      value.positionName
                    );
                    setNextPosition((prev) => ({
                      ...prev,
                      positionName: value.positionName,
                      sectionName: value.sectionName,
                      departmentName: value.departmentName,
                      divisionName: value.divisionName,
                      businessUnitName: value.businessUnitName,
                    }));
                  }
                }}
                noOptionsText={`${t("NoData")}`}
                // disableClearable
              />
            )}
          />
        </Grid>
        <Controller
          name="positionData.idPosition"
          control={useHookForm.control}
          render={({ field }) => (
            <Fragment>
              {field.value && (
                <Fragment>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      fontWeight="600"
                      fontSize="14px"
                      color="text.secondary"
                      paddingBottom="4px"
                    >
                      {`${t("Section")}`}
                    </Typography>
                    {nextPosition.sectionName ? (
                      <Typography fontWeight="500">
                        {nextPosition.sectionName}
                      </Typography>
                    ) : (
                      <Typography color="text.secondary" fontStyle="italic">
                        {`${t("NoItems")}`}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      fontWeight="600"
                      fontSize="14px"
                      color="text.secondary"
                      paddingBottom="4px"
                    >
                      {`${t("Department")}`}
                    </Typography>
                    {nextPosition.departmentName ? (
                      <Typography fontWeight="500">
                        {nextPosition.departmentName}
                      </Typography>
                    ) : (
                      <Typography color="text.secondary" fontStyle="italic">
                        {`${t("NoItems")}`}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      fontWeight="600"
                      fontSize="14px"
                      color="text.secondary"
                      paddingBottom="4px"
                    >
                      {`${t("Division")}`}
                    </Typography>
                    {nextPosition.divisionName ? (
                      <Typography fontWeight="500">
                        {nextPosition.divisionName}
                      </Typography>
                    ) : (
                      <Typography color="text.secondary" fontStyle="italic">
                        {`${t("NoItems")}`}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      fontWeight="600"
                      fontSize="14px"
                      color="text.secondary"
                      paddingBottom="4px"
                    >
                      {`${t("BusinessUnit")}`}
                    </Typography>
                    {nextPosition.businessUnitName ? (
                      <Typography fontWeight="500">
                        {nextPosition.businessUnitName}
                      </Typography>
                    ) : (
                      <Typography color="text.secondary" fontStyle="italic">
                        {`${t("NoItems")}`}
                      </Typography>
                    )}
                  </Grid>
                </Fragment>
              )}
            </Fragment>
          )}
        />
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("ManagerLV")}`} 1
          </Typography>
          <Controller
            name="positionData.idManagerLV1"
            control={useHookForm.control}
            render={({ field }) => (
              <Autocomplete
                options={managerList ? managerList : []}
                getOptionLabel={(option) =>
                  `${option.firstname_TH} ${option.lastname_TH}`
                }
                filterOptions={filterManagerOptions}
                disabled={!companyWatch}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idEmployees}>
                    <Box>
                      <Typography>
                        {option.firstname_TH} {option.lastname_TH}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        {option.email}
                      </Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder={`${t("SearchManager")}`}
                    onBlur={field.onBlur}
                    error={
                      useHookForm.formState.errors["positionData"] &&
                      useHookForm.formState.errors["positionData"][
                        "idManagerLV1"
                      ]
                        ? true
                        : false
                    }
                    helperText={
                      useHookForm.formState.errors["positionData"] &&
                      useHookForm.formState.errors["positionData"][
                        "idManagerLV1"
                      ]
                        ? useHookForm.formState.errors["positionData"][
                            "idManagerLV1"
                          ].message
                        : null
                    }
                  />
                )}
                value={
                  managerList
                    ? managerList.find(
                        (option) =>
                          Number(option.idEmployees) === Number(field.value)
                      ) || null
                    : null
                }
                onChange={(_, value) => {
                  field.onChange(value ? value.idEmployees : "");
                }}
                noOptionsText={`${t("NoData")}`}
                // disableClearable
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("ManagerLV")}`} 2
          </Typography>
          <Controller
            name="positionData.idManagerLV2"
            control={useHookForm.control}
            render={({ field }) => (
              <Autocomplete
                options={managerList ? managerList : []}
                getOptionLabel={(option) =>
                  `${option.firstname_TH} ${option.lastname_TH}`
                }
                filterOptions={filterManagerOptions}
                disabled={!companyWatch}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idEmployees}>
                    <Box>
                      <Typography>
                        {option.firstname_TH} {option.lastname_TH}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        {option.email}
                      </Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder={`${t("SearchManager")}`}
                    onBlur={field.onBlur}
                    error={
                      useHookForm.formState.errors["positionData"] &&
                      useHookForm.formState.errors["positionData"][
                        "idManagerLV2"
                      ]
                        ? true
                        : false
                    }
                    helperText={
                      useHookForm.formState.errors["positionData"] &&
                      useHookForm.formState.errors["positionData"][
                        "idManagerLV2"
                      ]
                        ? useHookForm.formState.errors["positionData"][
                            "idManagerLV2"
                          ].message
                        : null
                    }
                  />
                )}
                value={
                  managerList
                    ? managerList.find(
                        (option) =>
                          Number(option.idEmployees) === Number(field.value)
                      ) || null
                    : null
                }
                onChange={(_, value) => {
                  field.onChange(value ? value.idEmployees : "");
                }}
                noOptionsText={`${t("NoData")}`}
                // disableClearable
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("Note")}`}
          </Typography>
          <Controller
            name="positionData.description"
            control={useHookForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                multiline
                rows={4}
                error={
                  useHookForm.formState.errors["positionData"] &&
                  useHookForm.formState.errors["positionData"]["description"]
                    ? true
                    : false
                }
                helperText={
                  useHookForm.formState.errors["positionData"] &&
                  useHookForm.formState.errors["positionData"]["description"]
                    ? useHookForm.formState.errors["positionData"][
                        "description"
                      ].message
                    : null
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("EffectiveDate")}`}
          </Typography>
          <Controller
            name="positionData.start"
            control={useHookForm.control}
            render={({ field }) => (
              <DatePickerCustom
                {...field}
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                openTo="year"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: `${t("DayMonthYear(AD)")}`,
                    }}
                    onBlur={field.onBlur}
                    error={
                      useHookForm.formState.errors["positionData"] &&
                      useHookForm.formState.errors["positionData"]["start"]
                        ? true
                        : false
                    }
                    helperText={
                      useHookForm.formState.errors["positionData"] &&
                      useHookForm.formState.errors["positionData"]["start"]
                        ? useHookForm.formState.errors["positionData"][
                            "start"
                          ].message
                        : null
                    }
                  />
                )}
                PaperProps={{
                  sx: {
                    "& .Mui-selected": {
                      backgroundColor: "#46cbe2!important",
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <ButtonBlue
            variant="text"
            onClick={() => {
              handleClose();
            }}
            disabled={useHookForm.formState.isSubmitting}
          >
            {`${t("Cancel")}`}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={() => {
              onNextPage();
            }}
            disabled={useHookForm.formState.isSubmitting}
          >
            {`${t("Next")}`}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  );
};

const PersonnelLevelForm = (props) => {
  const {
    setPage,
    handleClose,
    defaultPage,
    formStateData,
    setFormStateData,
    useHookForm,
  } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: levelList } = useSelector((state) => state.level);

  const validateYupSchema = yup.object({
    idLevel:
      defaultPage === "personnelLevel"
        ? yup.string().required(`${t("ThisFieldIsRequired")}`)
        : undefined,
    start:
      defaultPage === "personnelLevel"
        ? yup
            .date()
            .required(`${t("ThisFieldIsRequired")}`)
            .nullable()
        : undefined,
  });

  // const useHookForm = useForm({
  //   defaultValues: {
  //     idLevel: "",
  //     remark: "",
  //     start: new Date(),
  //   },
  //   resolver: yupResolver(validateYupSchema),
  //   mode: "all",
  // })

  const filterOptions = createFilterOptions({
    stringify: (value) => `${value.levelName}`,
  });

  const onSubmit = async (data) => {
    const formData = { ...data };

    formData.start = dayjs(formData.start).format("YYYY-MM-DD");
    formData.idEmployees = employeeProfile.idEmployees;
    formData.idEmployeePersonnelLevelOld =
      employeeProfile.idEmployeePersonnelLevel
        ? employeeProfile.idEmployeePersonnelLevel
        : null;
    formData.end = null;
    formData.idAdmin = userProfile.idEmployees;

    Object.keys(formData).map((k) => {
      if (formData[k] === "") {
        formData[k] = null;
      }
    });

    if (Number(formData.idLevel) === Number(employeeProfile.idPersonnelLevel)) {
      alert(`${t("NoDataChange")}`);
    } else {
      // let result = await dispatch(addDeleteEmployeePersonnelLevel(formData));

      // if (result) {
      //   dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      //   dispatch(getEmployeePersonnelLevelChange(employeeProfile.idEmployees));
      //   setPage("preSalary")
      // }
      setFormStateData((prev) => ({
        ...prev,
        personnelLevelData: formData.idLevel ? formData : null,
        // personnelLevelData: (!prev.personnelLevelData ||  (prev.personnelLevelData && !prev.personnelLevelData.remark))? ({remark: "เปลี่ยนตำแหน่งงาน\n1 เป็น\n2"}): prev.personnelLevelData
      }));
      setPage("salary");
    }
  };

  const onNextPage = async () => {
    let salaryRemarkText = [];

    if (useHookForm.getValues("positionData.idPosition"))
      salaryRemarkText.push(
        `เปลี่ยนตำแหน่งงาน\nจาก ${
          employeeProfile.positionName
        }\nเป็น ${useHookForm.getValues("positionData.newPositionName")}`
      );

    if (useHookForm.getValues("personnelLevelData.idLevel")) {
      const value = levelList
        ? []
            .concat(...levelList.map((group) => group.level))
            .find(
              (option) =>
                Number(option.idLevel) ===
                Number(useHookForm.getValues("personnelLevelData.idLevel"))
            ) || null
        : null;
      if (value)
        salaryRemarkText.push(
          `เปลี่ยนระดับพนักงาน\nจาก ${employeeProfile.personnelLevelName}\nเป็น ${value.levelName}`
        );
    }

    if (!useHookForm.getValues("salaryData.remark")) {
      useHookForm.setValue("salaryData.remark", salaryRemarkText.join("\n"));
    }
    //

    await useHookForm.trigger();

    if (!useHookForm.formState.errors.personnelLevelData) {
      setPage("salary");
    }
  };

  // useEffect(() => {
  //   // console.log(formStateData)
  //   if(formStateData.personnelLevelData){
  //     useHookForm.reset({
  //       idLevel: formStateData.personnelLevelData.idLevel || "",
  //       remark: formStateData.personnelLevelData.remark || "",
  //       start: new Date(formStateData.personnelLevelData.start),
  //     })
  //   } else {
  //     useHookForm.reset({
  //       idLevel: "",
  //       remark: "",
  //       start: new Date(),
  //     })
  //   }
  // }, [])

  return (
    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="16px">
            {`${t("CurrentData")}`}
          </Typography>
        </Grid>
        {employeeProfile && (
          <Fragment>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("LevelGroup")}`}
              </Typography>
              {employeeProfile.personnelLevelGroupName ? (
                <Typography fontWeight="500">
                  {employeeProfile.personnelLevelGroupName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("EmployeePersonalLevel")}`}
              </Typography>
              {employeeProfile.personnelLevelName ? (
                <Typography fontWeight="500">
                  {employeeProfile.personnelLevelName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12}>
          <Divider
            style={{
              borderColor: "rgba(145, 158, 171, 0.32)",
              borderStyle: "dashed",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("NewEmployeeLevel")}`}
          </Typography>
          <Controller
            name="personnelLevelData.idLevel"
            control={useHookForm.control}
            render={({ field }) => (
              <Autocomplete
                options={
                  levelList
                    ? [].concat(...levelList.map((group) => group.level))
                    : []
                }
                getOptionLabel={(option) => `${option.levelName}`}
                filterOptions={filterOptions}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idLevel}>
                    <Box>
                      {/* <Typography color="text.secondary" fontSize="12px">{option.positionCode}</Typography> */}
                      <Typography>{option.levelName}</Typography>
                      {/* <Typography color="text.secondary" fontSize="14px">{option.divisionName || "-"}/{option.departmentName || "-"}/{option.sectionName || "-"}</Typography> */}
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder={`${t("Search")}`}
                    onBlur={field.onBlur}
                    error={
                      useHookForm.formState.errors["personnelLevelData"] &&
                      useHookForm.formState.errors["personnelLevelData"][
                        "idLevel"
                      ]
                        ? true
                        : false
                    }
                    helperText={
                      useHookForm.formState.errors["personnelLevelData"] &&
                      useHookForm.formState.errors["personnelLevelData"][
                        "idLevel"
                      ]
                        ? useHookForm.formState.errors["personnelLevelData"][
                            "idLevel"
                          ].message
                        : null
                    }
                  />
                )}
                value={
                  levelList
                    ? []
                        .concat(...levelList.map((group) => group.level))
                        .find((option) => option.idLevel === field.value) ||
                      null
                    : null
                }
                onChange={(_, value) => {
                  field.onChange(value ? value.idLevel : "");
                }}
                noOptionsText={`${t("NoData")}`}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("Note")}`}
          </Typography>
          <Controller
            name="personnelLevelData.remark"
            control={useHookForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                multiline
                rows={4}
                error={
                  useHookForm.formState.errors["personnelLevelData"] &&
                  useHookForm.formState.errors["personnelLevelData"]["remark"]
                    ? true
                    : false
                }
                helperText={
                  useHookForm.formState.errors["personnelLevelData"] &&
                  useHookForm.formState.errors["personnelLevelData"]["remark"]
                    ? useHookForm.formState.errors["personnelLevelData"][
                        "remark"
                      ].message
                    : null
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("EffectiveDate")}`}
          </Typography>
          <Controller
            name="personnelLevelData.start"
            control={useHookForm.control}
            render={({ field }) => (
              <DatePickerCustom
                {...field}
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                openTo="year"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: `${t("DayMonthYear(AD)")}`,
                    }}
                    onBlur={field.onBlur}
                    error={
                      useHookForm.formState.errors["personnelLevelData"] &&
                      useHookForm.formState.errors["personnelLevelData"][
                        "start"
                      ]
                        ? true
                        : false
                    }
                    helperText={
                      useHookForm.formState.errors["personnelLevelData"] &&
                      useHookForm.formState.errors["personnelLevelData"][
                        "start"
                      ]
                        ? useHookForm.formState.errors["personnelLevelData"][
                            "start"
                          ].message
                        : null
                    }
                  />
                )}
                PaperProps={{
                  sx: {
                    "& .Mui-selected": {
                      backgroundColor: "#46cbe2!important",
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <ButtonBlue
            variant="text"
            onClick={() => {
              if (defaultPage == "personnelLevel") {
                handleClose();
              } else {
                setPage("position");
              }
            }}
            disabled={useHookForm.formState.isSubmitting}
          >
            {defaultPage === "personnelLevel"
              ? `${t("Cancel")}`
              : `${t("Back")}`}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={() => {
              onNextPage();
            }}
            disabled={useHookForm.formState.isSubmitting}
          >
            {`${t("Next")}`}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  );
};

const SalaryForm = (props) => {
  const { setPage, handleClose, defaultPage, useHookForm, handleFormSubmit } =
    props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: salaryReasons } = useSelector((state) => state.salaryReasons);

  const validateYupSchema = yup.object({
    salaryValue:
      defaultPage === "salary"
        ? yup.string().required(`${t("ThisFieldIsRequired")}`)
        : undefined,
    start:
      defaultPage === "salary"
        ? yup
            .date()
            .required(`${t("ThisFieldIsRequired")}`)
            .nullable()
        : undefined,
  });

  useEffect(() => {
    dispatch(getSalaryReasons());
  }, []);

  const reasons = {
    ปรับประจำปี: "AnnualAdjustment",
    ปรับโครงสร้าง: "Restructure",
    ปรับตำแหน่ง: "AdjustPosition",
    ปรับระดับงาน: "AdjustJobLevel",
    ปรับพิเศษ: "AdjustSpecial",
    ปรับด้วยเหตุผลอื่นๆ: "AdjustedOtherReasons",
  };

  // const useHookForm = useForm({
  //   defaultValues: {
  //     salaryValue: "",
  //     remark: "",
  //     start: new Date(),
  //   },
  //   resolver: yupResolver(validateYupSchema),
  //   mode: "all",
  // })

  const onSubmit = async () => {
    await useHookForm.trigger();

    if (!useHookForm.formState.errors.salaryData) {
      // console.log(useHookForm.getValues())
      handleFormSubmit();
    }
    // console.log(useHookForm.formState.errors)

    //   const formData = {...data};

    //   formData.start = dayjs(formData.start).format("YYYY-MM-DD");
    //   formData.idEmployees = employeeProfile.idEmployees;
    //   formData.idEmployeeSalaryOld = employeeProfile.idEmployeeSalary? employeeProfile.idEmployeeSalary: null;
    //   formData.end = null;
    //   formData.idAdmin = userProfile.idEmployees;

    //   Object.keys(formData).map(k => {
    //     if(formData[k] === ""){
    //       formData[k] = null
    //     }
    //   })

    //   if (Number(formData.salaryData.salaryValue) === Number(employeeProfile.salary)) {
    //     alert("ข้อมูลไม่มีการเปลี่ยนแปลง");
    //   } else {
    //     // let result = await dispatch(addDeleteEmployeeSalary(formData));

    //     // if (result) {
    //     //   dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    //     //   dispatch(getEmployeeSalaryChange(employeeProfile.idEmployees));
    //     //   handleClose();
    //     // }
    //     let textRemark = "";

    //     setFormStateData(prev => ({
    //       ...prev,
    //       salaryData: formData.salaryValue? formData: null,
    //       // personnelLevelData: (!prev.personnelLevelData ||  (prev.personnelLevelData && !prev.personnelLevelData.remark))? ({remark: "เปลี่ยนตำแหน่งงาน\n1 เป็น\n2"}): prev.personnelLevelData
    //     }))
    //     handleClose();
    //   }
  };

  // useEffect(() => {
  //   // console.log(formStateData)
  //   if(formStateData.salaryData){
  //     useHookForm.reset({
  //       salaryValue: formStateData.personnelLevelData.salaryValue || "",
  //       remark: formStateData.personnelLevelData.remark || "",
  //       start: new Date(formStateData.personnelLevelData.start),
  //     })
  //   } else {
  //     useHookForm.reset({
  //       salaryValue: "",
  //       remark: "",
  //       start: new Date(),
  //     })
  //   }
  // }, [])

  return (
    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="16px">
            {`${t("CurrentData")}`}
          </Typography>
        </Grid>
        {employeeProfile && (
          <Fragment>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Salary")}`}
              </Typography>
              {employeeProfile.salary ? (
                <Typography fontWeight="500">
                  {utils.numberWithCommas(employeeProfile.salary)}{" "}
                  {`${t("Baht")}`}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12}>
          <Divider
            style={{
              borderColor: "rgba(145, 158, 171, 0.32)",
              borderStyle: "dashed",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("NewSalary")}`}
          </Typography>
          <Controller
            name="salaryData.salaryValue"
            control={useHookForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  allowNegative: false,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value);
                  },
                  suffix: `${t("Baht")}`,
                  decimalScale: 2,
                  value: field.value,
                }}
                onChange={() => {}}
                error={
                  useHookForm.formState.errors["salaryData"] &&
                  useHookForm.formState.errors["salaryData"]["salaryValue"]
                    ? true
                    : false
                }
                helperText={
                  useHookForm.formState.errors["salaryData"] &&
                  useHookForm.formState.errors["salaryData"]["salaryValue"]
                    ? useHookForm.formState.errors["salaryData"]["salaryValue"]
                        .message
                    : null
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {salaryReasons && (
            <>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Description")}`}
              </Typography>
              <Controller
                name="salaryData.idSalaryReason"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={
                      useHookForm.formState.errors["salaryData"] &&
                      useHookForm.formState.errors["salaryData"][
                        "idSalaryReason"
                      ]
                        ? true
                        : false
                    }
                    helperText={
                      useHookForm.formState.errors["salaryData"] &&
                      useHookForm.formState.errors["salaryData"][
                        "idSalaryReason"
                      ]
                        ? useHookForm.formState.errors["salaryData"][
                            "idSalaryReason"
                          ].message
                        : null
                    }
                  >
                    {salaryReasons.map((reason) => (
                      <MenuItem value={reason.idSalaryReason}>
                        {t(`${reasons[reason.reason]}`)}
                      </MenuItem>
                    ))}
                  </TextFieldTheme>
                )}
              />
            </>
          )}
        </Grid>
        {/* <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("Description")}`}
          </Typography>
          <Controller
            name="salaryData.reason"
            control={useHookForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                select
                error={
                  useHookForm.formState.errors["salaryData"] &&
                  useHookForm.formState.errors["salaryData"]["reason"]
                    ? true
                    : false
                }
                helperText={
                  useHookForm.formState.errors["salaryData"] &&
                  useHookForm.formState.errors["salaryData"]["reason"]
                    ? useHookForm.formState.errors["salaryData"]["reason"]
                        .message
                    : null
                }
              >
                <MenuItem value="ปรับประจำปี">{t("AnnualAdjustment")}</MenuItem>
                <MenuItem value="ปรับโครงสร้าง">{t("Restructure")}</MenuItem>
                <MenuItem value="ปรับตำแหน่ง">{t("AdjustPosition")}</MenuItem>
                <MenuItem value="ปรับระดับงาน">{t("AdjustJobLevel")}</MenuItem>
                <MenuItem value="ปรับพิเศษ">{t("AdjustSpecial")}</MenuItem>
                <MenuItem value="ปรับด้วยเหตุผลอื่นๆ">
                  {t("AdjustedOtherReasons")}
                </MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("Note")}`}
          </Typography>
          <Controller
            name="salaryData.remark"
            control={useHookForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                multiline
                rows={4}
                error={
                  useHookForm.formState.errors["salaryData"] &&
                  useHookForm.formState.errors["salaryData"]["remark"]
                    ? true
                    : false
                }
                helperText={
                  useHookForm.formState.errors["salaryData"] &&
                  useHookForm.formState.errors["salaryData"]["remark"]
                    ? useHookForm.formState.errors["salaryData"]["remark"]
                        .message
                    : null
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="600"
            fontSize="14px"
            color="text.secondary"
            paddingBottom="4px"
          >
            {`${t("EffectiveDate")}`}
          </Typography>
          <Controller
            name="salaryData.start"
            control={useHookForm.control}
            render={({ field }) => (
              <DatePickerCustom
                {...field}
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                openTo="year"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: `${t("DayMonthYear(AD)")}`,
                    }}
                    onBlur={field.onBlur}
                    error={
                      useHookForm.formState.errors["salaryData"] &&
                      useHookForm.formState.errors["salaryData"]["start"]
                        ? true
                        : false
                    }
                    helperText={
                      useHookForm.formState.errors["salaryData"] &&
                      useHookForm.formState.errors["salaryData"]["start"]
                        ? useHookForm.formState.errors["salaryData"]["start"]
                            .message
                        : null
                    }
                  />
                )}
                PaperProps={{
                  sx: {
                    "& .Mui-selected": {
                      backgroundColor: "#46cbe2!important",
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <ButtonBlue
            variant="text"
            onClick={() => {
              if (defaultPage === "salary") {
                handleClose();
              } else {
                setPage("personnelLevel");
              }
            }}
            disabled={useHookForm.formState.isSubmitting}
          >
            {defaultPage === "salary" ? `${t("Cancel")}` : `${t("Back")}`}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={() => {
              onSubmit();
            }}
            disabled={useHookForm.formState.isSubmitting}
          >
            {`${t("Save")}`}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  );
};

const ConfirmNextPage = (props) => {
  const { page, setPage, handleClose } = props;
  const { t, i18n } = useTranslation();

  const displayQuestion = () => {
    switch (page) {
      case "prePosition": {
        return `${t("JobTransfer")}`;
      }
      case "prePersonnelLevel": {
        return `${t("AdjustEmployeeLevel")}`;
      }
      case "preSalary": {
        return `${t("AdjustSalary")}`;
      }
    }
  };

  const onConfirm = () => {
    switch (page) {
      case "prePosition": {
        return () => {
          setPage("position");
        };
      }
      case "prePersonnelLevel": {
        return () => {
          setPage("personnelLevel");
        };
      }
      case "preSalary": {
        return () => {
          setPage("salary");
        };
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box padding="24px 0">
          <Typography variant="h6" textAlign="center">
            {`${t("Want")}`}
            {displayQuestion()} ?
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <ButtonBlue
          variant="text"
          onClick={() => {
            handleClose();
          }}
        >
          {`${t("Want")}`}
        </ButtonBlue>
        <ButtonBlue
          variant="contained"
          onClick={() => {
            onConfirm()();
          }}
        >
          {`${t("DoNotWant")}`}
        </ButtonBlue>
      </Grid>
    </Grid>
  );
};

export default FlowForm;
