import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Divider,
  styled,
  Typography,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import DialogEducation from "./DialogEducation";
import DialogExperience from "./DialogExperience";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { deleteEducation } from "../../../../../../actions/education";

import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import { deleteExperience } from "../../../../../../actions/experience";

// import { deleteExperience } from "../../../../../../actions/experience";
// import { deleteEducation } from "../../../../../../actions/education";
// import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledPaper = styled("div")({
  border: "none",
  display: "flex",
  alignItems: "flex-start",
  marginBottom: 16,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "&.bottom": {
    marginTop: 48,
  },
  "& .left": {
    marginBottom: 16,
    display: "flex",
    alignItems: "baseline",
  },
});

const StyledWrapNothing = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 24,
});

const EducationExperience = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { t, i18n } = useTranslation();

  const [drawerEducationConfig, setDrawerEducationConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const [deleteEducationConfig, setDeleteEducationConfig] = useState({
    isOpen: false,
    data: {},
  });

  const [drawerExperienceConfig, setDrawerExperienceConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const [deleteExperienceConfig, setDeleteExperienceConfig] = useState({
    isOpen: false,
    data: {},
  });

  const displayDuration = (startDate, endDate) => {
    let result = dayjs(startDate).format("MMMM BBBB - ");
    if (endDate) {
      result += dayjs(endDate).format("MMMM BBBB");
    } else {
      result += "ปัจจุบัน";
    }
    return result;
  };

  const handleCloseDeleteEducationModal = () => {
    setDeleteEducationConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleDeleteEducation = async () => {
    const result = await dispatch(
      deleteEducation(deleteEducationConfig.data.idEducations)
    );

    if (result) {
      if (result.status === 200) {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
        handleCloseDeleteEducationModal();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: t("ErrorOccurredContactAdmin")
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: t("ErrorOccurredContactAdmin")
      // }))
    }
  };

  const handleCloseDeleteExperienceModal = () => {
    setDeleteExperienceConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleDeleteExperience = async () => {
    const result = await dispatch(
      deleteExperience(deleteExperienceConfig.data.idExperiences)
    );

    if (result) {
      if (result.status === 200) {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
        handleCloseDeleteExperienceModal();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: t("ErrorOccurredContactAdmin")
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: t("ErrorOccurredContactAdmin")
      // }))
    }
  };

  return (
    <div>
      <StyledWrapHead>
        <div className="left">
          <StyledHeadLabel>{`${t("EducationInfo")}`}</StyledHeadLabel>
          {/* <div style={{ marginLeft: 8 }}>
            <Tooltip title="ประวัติแก้ไข">
              <IconButton aria-label="history" size="small">
                <ManageHistoryIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div> */}
        </div>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<PlaylistAddRoundedIcon />}
            onClick={() => {
              setDrawerEducationConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: false,
              }));
            }}
          >
            {t("AddEducation")}
          </ButtonBlue>
        )}
      </StyledWrapHead>

      {employeeProfile.educations.length > 0 ? (
        employeeProfile.educations.map((education) => (
          <Fragment>
            <StyledPaper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                {!userProfile.readOnly && (
                  <Box style={{ position: "absolute", right: "0", top: "0" }}>
                    <IconButton
                      aria-label="edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDrawerEducationConfig((prev) => ({
                          ...prev,
                          isOpen: true,
                          isEdit: true,
                          data: {
                            idEducations: education.idEducations,
                            fromYear: education.fromYear,
                            endYear: education.endYear,
                            degree: education.degree,
                            university: education.university,
                            faculty: education.faculty,
                            major: education.major,
                            gpa: education.gpa,
                          },
                        }));
                      }}
                    >
                      <EditNoteRoundedIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteEducationConfig((prev) => ({
                          ...prev,
                          isOpen: true,
                          data: {
                            idEducations: education.idEducations,
                            degree: education.degree,
                            university: education.university,
                          },
                        }));
                      }}
                    >
                      <DeleteOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                )}
                <Typography
                  variant="h6"
                  style={{ width: "calc(100% - 143px)" }}
                >
                  {`(${
                    education.fromYear
                      ? dayjs(`01-01-${education.fromYear}`, "DD-MM-YYYY").format(
                          i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                        )
                      : t("NotSpecified")
                  } - ${
                    education.endYear
                      ? dayjs(`01-01-${education.endYear}`, "DD-MM-YYYY").format(
                          i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                        )
                      : t("NotSpecified")
                  }) ${
                    education.university
                      ? education.university
                      : t("NotSpecifiedSchool")
                  }`}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {`${education.major || "-"}, ${education.faculty || "-"}, ${
                    education.degree || "-"
                  }`}
                </Typography>
                <Typography style={{ fontWeight: 700, marginTop: 8 }}>
                  {`${t("GPA")}: ${education.gpa ? education.gpa : t("NotSpecified")}`}
                </Typography>
              </div>
            </StyledPaper>
            <StyledDivider />
          </Fragment>
        ))
      ) : (
        <StyledWrapNothing>
          <Typography
            variant="h6"
            color="text.secondary"
            style={{ fontStyle: "oblique", fontWeight: 400 }}
          >
            {t("NoEducationInfo")}
          </Typography>
        </StyledWrapNothing>
      )}

      <StyledWrapHead className="bottom">
        <div className="left">
          <StyledHeadLabel> {`${t("WorkExperience")}`}</StyledHeadLabel>
        </div>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<PlaylistAddRoundedIcon />}
            onClick={() => {
              setDrawerExperienceConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: false,
              }));
            }}
          >
            {t("AddWorkExperience")}
          </ButtonBlue>
        )}
      </StyledWrapHead>

      {employeeProfile.experience && employeeProfile.experience.length > 0 ? (
        employeeProfile.experience.map((experience) => (
          <Fragment>
            <StyledPaper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                {!userProfile.readOnly && (
                  <Box style={{ position: "absolute", right: "0", top: "0" }}>
                    <IconButton
                      aria-label="edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDrawerExperienceConfig((prev) => ({
                          ...prev,
                          isOpen: true,
                          isEdit: true,
                          data: {
                            idExperiences: experience.idExperiences,
                            startDate: experience.startDate,
                            endDate: experience.endDate,
                            positionName: experience.positionName,
                            companyName: experience.companyName,
                            description: experience.description,
                          },
                        }));
                      }}
                    >
                      <EditNoteRoundedIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteExperienceConfig((prev) => ({
                          ...prev,
                          isOpen: true,
                          data: {
                            idExperiences: experience.idExperiences,
                            positionName: experience.positionName,
                            companyName: experience.companyName,
                          },
                        }));
                      }}
                    >
                      <DeleteOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                )}
                <Typography style={{ fontWeight: 500 }}>
                  {`(${displayDuration(
                    experience.startDate,
                    experience.endDate
                  )})`}
                </Typography>
                <Typography variant="h5">{experience.positionName}</Typography>
                <Typography variant="body1" color="text.secondary">
                  {experience.companyName}
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    marginTop: 8,
                    whiteSpace: "pre-line",
                  }}
                >
                  {experience.description}
                </Typography>
              </div>
            </StyledPaper>
            <StyledDivider />
          </Fragment>
        ))
      ) : (
        <StyledWrapNothing>
          <Typography
            variant="h6"
            color="text.secondary"
            style={{ fontStyle: "oblique", fontWeight: 400 }}
          >
            {t("NoWorkExperience")}
          </Typography>
        </StyledWrapNothing>
      )}

      <DialogEducation
        drawerConfig={drawerEducationConfig}
        onClose={() => {
          setDrawerEducationConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      <DialogConfirmDelete
        open={deleteEducationConfig.isOpen}
        handleClose={handleCloseDeleteEducationModal}
        label={"ประวัติการศึกษา"}
        text={
          <Box>
            <Typography
              fontSize="14px"
              fontWeight="600"
              style={{ color: "#f15e5e" }}
            >
              {deleteEducationConfig.data.university}
            </Typography>
            <Typography fontSize="18px" style={{ color: "#f15e5e" }}>
              {deleteEducationConfig.data.degree}
            </Typography>
          </Box>
        }
        handleDelete={handleDeleteEducation}
      />

      <DialogExperience
        drawerConfig={drawerExperienceConfig}
        onClose={() => {
          setDrawerExperienceConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      <DialogConfirmDelete
        open={deleteExperienceConfig.isOpen}
        handleClose={handleCloseDeleteExperienceModal}
        label={"ประสบการณ์ทำงาน"}
        text={
          <Box>
            <Typography
              fontSize="14px"
              fontWeight="600"
              style={{ color: "#f15e5e" }}
            >
              {deleteExperienceConfig.data.positionName}
            </Typography>
            <Typography fontSize="18px" style={{ color: "#f15e5e" }}>
              {deleteExperienceConfig.data.companyName}
            </Typography>
          </Box>
        }
        handleDelete={handleDeleteExperience}
      />
    </div>
  );
};

export default EducationExperience;
