import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Avatar, Box, Grid, IconButton, Chip } from "@mui/material";
import { styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogName from "./DialogName";
import {
  getOnBoardingList,
  getOnBoardingReviewer,
  deleteOnBoardingReviewer,
} from "../../../../../actions/onBoarding";
import DialogReview from "./DialogReview";
import { useTranslation } from "react-i18next";
import { getAffiliateOrganization } from "../../../../../actions/company";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import Loading from "../../../shared/loading";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
});

const StyledAccordion = styled(Accordion)({
  backgroundColor: "rgba(0,0,0,0.03)",
  marginBottom: 12,
  borderRadius: 8,
  "&:before": {
    display: "none",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  display: "flex",
  alignItems: "center",
  padding: "16px 24px",
  "& .MuiTypography-root": {
    flexGrow: 1,
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  backgroundColor: "#ffffff",
  padding: "16px",
});

const OnBoardingInspector = ({
  row,
  setOnBoardingListFormData,
  handleDeleteInspector,
  handleOpenDialogReview,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { result: onBoardingReviewer, isFetching } = useSelector(
    (state) => state.onBoardingReviewer
  );

  useEffect(() => {
    dispatch(getOnBoardingReviewer(row.idOnBoardingList));
  }, [row.idOnBoardingList]);

  return (
    <StyledAccordionDetails>
      {isFetching || onBoardingReviewer === null ? (
        <Loading />
      ) : onBoardingReviewer.length > 0 ? (
        onBoardingReviewer.map((review, idx) => (
          <Grid
            container
            key={idx}
            spacing={2}
            alignItems="center"
            sx={{
              marginBottom: "14px",
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: 1,
            }}
          >
            <Grid item xs={4} display="flex" alignItems="center">
              <Avatar
                src={review.imageProfile}
                alt={review.firstname_TH}
                sx={{ width: 40, height: 40, marginRight: 1.5 }}
              />
              <Typography>{`${review.firstname_TH} ${review.lastname_TH}`}</Typography>
            </Grid>
            <Grid item xs={4} textAlign="left">
              <Typography>
                {t("Department")}: {review.departmentName || "-"}
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <IconButton
                onClick={() =>
                  handleDeleteInspector(review.idOnBoardingReviewer, row.idOnBoardingList)
                }
              >
                <DeleteIcon sx={{ color: "red" }} />
              </IconButton>
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography sx={{ color: "text.secondary" }}>
          {t("NoInspector")}
        </Typography>
      )}
      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        height="100%"
        mt={2}
      >
        <ButtonBlue
          size="small"
          variant="contained"
          onClick={() => {
            setOnBoardingListFormData({
              idOnBoardingList: row.idOnBoardingList,
              name: row.name,
            });
            handleOpenDialogReview();
          }}
          sx={{ minWidth: "150px", minHeight: "50px" }}
        >
          {t("AddInspector")}
        </ButtonBlue>
      </Box>
    </StyledAccordionDetails>
  );
};

const OnBoardingList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: onBoardingList } = useSelector(
    (state) => state.onBoardingList
  );
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [openDialog, setopenDialog] = useState(false);
  const [openDialogReview, setOpenDialogReview] = useState(false);
  const [formData, setFormData] = useState(null);
  const [onBoardingListFormData, setOnBoardingListFormData] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getOnBoardingList({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      setSelectedCompany(affiliateOrganizationList[0]);
    }
  }, [affiliateOrganizationList]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenDialog = () => {
    setopenDialog(true);
  };

  const handleClose = () => {
    setopenDialog(false);
    setFormData(null);
  };

  const handleOpenDialogReview = () => {
    setOpenDialogReview(true);
  };

  const handleOpenCloseDialogReview = () => {
    setOpenDialogReview(false);
  };

  const handleDeleteInspector = (idOnBoardingReviewer, idOnBoardingList) => {
    Swal.fire({
      title: t("ConfirmDeleteInspector"),
      text: t("WantToDeleteInspector"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteOnBoardingReviewer(idOnBoardingReviewer)).then(() => {
          dispatch(getOnBoardingReviewer(idOnBoardingList))
          Swal.fire({
            title: t("InspectorDeleted"),
            text: t("TheInspectorHasBeenDeleted"),
            icon: "success",
            confirmButtonColor: "#46cbe2",
            confirmButtonText: t("OK"),
          })
        });
      }
    });
  };

  return (
    <>
      <StyledWrapBranch>
        <Box className="wrap-branch-top" sx={{ marginBottom: "16px" }}>
          <Box sx={{ minWidth: 400, marginRight: "auto" }}>
            <SelectAffiliateCompany
              options={affiliateOrganizationList}
              value={selectedCompany}
              onChange={(_, newValue) => setSelectedCompany(newValue)}
              placeholder={t("SelectCompany")}
            />
          </Box>
          <Box>
            <ButtonBlue
              style={{ padding: "14px 60px" }}
              variant="contained"
              onClick={handleOpenDialog}
            >
              {t("AddItem")}
            </ButtonBlue>
          </Box>
        </Box>

        {onBoardingList && onBoardingList.length > 0 ? (
          <div>
            {onBoardingList
              .filter((row) => row.idCompany === selectedCompany.idCompany)
              .sort((a, b) => (a.assignmentType === "all" ? -1 : 1))
              .map((row) => (
                <StyledAccordion
                  key={row.idOnBoardingList}
                  expanded={expanded === row.idOnBoardingList}
                  onChange={handleChange(row.idOnBoardingList)}
                >
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container alignItems="center" width="100%">
                      <Grid item xs={8}>
                        <Typography>
                          {`${row.name} ภายใน ${row.day} วัน`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display="flex" justifyContent="center">
                        {row.assignmentType === "all" && (
                          <Chip
                            label={t("EnforceTask")}
                            sx={{
                              backgroundColor: "red",
                              color: "white",
                              fontWeight: "bold",
                              marginRight: 1,
                            }}
                            size="small"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Typography sx={{ color: "text.secondary" }}>
                          {t("Inspector")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </StyledAccordionSummary>
                  {expanded === row.idOnBoardingList && (
                    <OnBoardingInspector
                      row={row}
                      setOnBoardingListFormData={setOnBoardingListFormData}
                      handleDeleteInspector={handleDeleteInspector}
                      handleOpenDialogReview={handleOpenDialogReview}
                    />
                  )}
                </StyledAccordion>
              ))}
          </div>
        ) : (
          `${t("NoItems")}`
        )}
      </StyledWrapBranch>

      {openDialog && (
        <DialogName
          open={openDialog}
          data={formData}
          handleClose={handleClose}
          selectedCompany={selectedCompany}
        />
      )}
      {openDialogReview && (
        <DialogReview
          open={openDialogReview}
          handleClose={handleOpenCloseDialogReview}
          data={onBoardingListFormData}
        />
      )}
    </>
  );
};

export default OnBoardingList;
