import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Themplates from "../../shared/theme";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  MenuItem,
  Button,
  Icon,
  IconButton,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText,
  TextField,
} from "@mui/material";

import { Box } from "@mui/system";
import DatePickerCustom from "../../shared/date/datePicker";
import "./index.css";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import ButtonBlue from "../../shared/general/ButtonBlue";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import {
  getLevelGroup,
  addRights,
  updateRights,
} from "../../../../actions/rightsManage";
import { getEmploymentTypeByidCompany } from "../../../../actions/employment-type";
import { useTranslation } from "react-i18next";

const theme = createTheme(Themplates);

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "rgb(3 0 71 / 9%) 0px 1px 3px",
    padding: "1.75rem",
  },
  catPaper: {
    height: "30px",
    padding: "40px",
    borderRight: "50%",
  },
  typography: {
    fontSize: "24px",
    marginBottom: "24px",
    fontWeight: "400",
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    address: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .50)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
}));

const InsuranceRights = (props) => {
  const {
    drawerConfig,
    onClose,
    handleSubmitRights,
    setAlertType,
    setOpenAlert,
  } = props;
  const classes = useStyles();
  const formRef = useRef();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: LevelGroup } = useSelector((state) => state.levelGroup);
  const { result: employmentType } = useSelector(
    (state) => state.employmentType
  );

  const today = dayjs().toDate();
  const [tempDate, setTempDate] = useState({
    startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    endDate: dayjs(dayjs().toDate()).add(5, "year").format("YYYY/MM/DD"),
  });
  const [idSurplus, setIdSurplus] = useState([]);
  const [idSurplusSelf, setIdSurplusSelf] = useState([]);
  const [idSurplusCouple, setIdSurplusCouple] = useState([]);
  const [idSurplusParents, setIdSurplusParents] = useState([]);
  const [idSurplusChild, setIdSurplusChild] = useState([]);
  const [idSurplusOPD, setIdSurplusOPD] = useState([]);
  const [idSurplusIPD, setIdSurplusIPD] = useState([]);
  const [idSurplusDental, setIdSurplusDental] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [idIPD, setIdIPD] = useState([]);
  const [idDental, setIdDental] = useState([]);

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      rightsName: "",
      idEmploymentType: "",
      idLevel: "",
      startDate: tempDate.startDate,
      endDate: tempDate.endDate,
      principle: "",
      limit: "",
      isHelpingSurplus: 0,
      surplus: [
        {
          numberSurplus: "",
          surplusPercent: "",
        },
      ],
      limitSelf: "",
      isHelpingSurplusSelf: 0,
      surplusSelf: [
        {
          numberSurplus: "",
          surplusPercent: "",
        },
      ],
      limitCouple: "",
      isHelpingSurplusCouple: 0,
      surplusCouple: [
        {
          numberSurplus: "",
          surplusPercent: "",
        },
      ],
      limitParents: "",
      isHelpingSurplusParents: 0,
      surplusParents: [
        {
          numberSurplus: "",
          surplusPercent: "",
        },
      ],
      limitChild: "",
      isHelpingSurplusChild: 0,
      surplusChild: [
        {
          numberSurplus: "",
          surplusPercent: "",
        },
      ],
      useForWho: [],
      OPD_Principle: "",
      limitOPD: "",
      OPD_Number: "",
      OPD_MoneyPerTimes: "",
      isHelpingSurplusOPD: 0,
      surplusOPD: [
        {
          numberSurplus: "",
          surplusPercent: "",
        },
      ],
      IPD_Principle: "",
      limitIPD: "",
      isHelpingSurplusIPD: 0,
      surplusIPD: [
        {
          numberSurplus: "",
          surplusPercent: "",
        },
      ],
      IPD: [
        {
          nameList: "",
          limitList: "",
        },
      ],
      Dental_Principle: "",
      limitDental: "",
      isHelpingSurplusDental: 0,
      surplusDental: [
        {
          numberSurplus: "",
          surplusPercent: "",
        },
      ],
      Dental: [
        {
          nameList: "",
          limitList: "",
        },
      ],
    },
  });

  const handleOnChange = (value, type) => {
    switch (type) {
      case "Couple":
        SetToggleHelpingSurplusCouple(value);
        setValue("isHelpingSurplusCouple", value);
        if (value === 0) {
          setValue("surplusCouple", "");
        }
        break;
      case "Parents":
        SetToggleHelpingSurplusParents(value);
        setValue("isHelpingSurplusParents", value);
        if (value === 0) {
          setValue("surplusParents", "");
        }
        break;
      case "Child":
        SetToggleHelpingSurplusChild(value);
        setValue("isHelpingSurplusChild", value);
        if (value === 0) {
          setValue("surplusChild", "");
        }
        break;
      case "total":
        SetToggleHelpingSurplus(value);
        setValue("isHelpingSurplus", value);
        if (value === 0) {
          setValue("surplus", "");
        }
        break;
      case "Self":
        SetToggleHelpingSurplusSelf(value);
        setValue("isHelpingSurplusSelf", value);
        if (value === 0) {
          setValue("surplusSelf", "");
        }
        break;
      case "OPD":
        SetToggleHelpingSurplusOPD(value);
        setValue("isHelpingSurplusOPD", value);
        if (value === 0) {
          setValue("surplusOPD", "");
        }
        break;
      case "IPD":
        SetToggleHelpingSurplusIPD(value);
        setValue("isHelpingSurplusIPD", value);
        if (value === 0) {
          setValue("surplusIPD", "");
        }
        break;
      case "Dental":
        SetToggleHelpingSurplusDental(value);
        setValue("isHelpingSurplusDental", value);
        if (value === 0) {
          setValue("surplusDental", "");
        }
        break;
      default:
        console.log("isHelpingSurplus", value, type);
    }
  };

  const [selectedButton, SetselectedButton] = useState("");
  const [ToggleHelpingSurplus, SetToggleHelpingSurplus] = useState("");
  const [ToggleHelpingSurplusSelf, SetToggleHelpingSurplusSelf] = useState("");
  const [ToggleHelpingSurplusCouple, SetToggleHelpingSurplusCouple] =
    useState("");
  const [ToggleHelpingSurplusParents, SetToggleHelpingSurplusParents] =
    useState("");
  const [ToggleHelpingSurplusChild, SetToggleHelpingSurplusChild] =
    useState("");
  const [ToggleHelpingSurplusOPD, SetToggleHelpingSurplusOPD] = useState("");
  const [ToggleHelpingSurplusIPD, SetToggleHelpingSurplusIPD] = useState("");
  const [ToggleHelpingSurplusDental, SetToggleHelpingSurplusDental] =
    useState("");
  const [selectedButtonOPD, SetSelectedButtonOPD] = useState("");
  const [selectedButtonIPD, SetSelectedButtonIPD] = useState("");
  const [selectedButtonDental, SetSelectedButtonDental] = useState("");
  const [page, Setpage] = useState(0);

  const nameRelation = ["ตนเอง", "คู่สมรส", "บิดามารดา", "บุตร"];

  const surplusLevelUseFieldArray = useFieldArray({
    control: control,
    name: "surplus",
  });

  const surplusSelfLevelUseFieldArray = useFieldArray({
    control: control,
    name: "surplusSelf",
  });

  const surplusCoupleLevelUseFieldArray = useFieldArray({
    control: control,
    name: "surplusCouple",
  });
  const surplusParentsLevelUseFieldArray = useFieldArray({
    control: control,
    name: "surplusParents",
  });
  const surplusChildLevelUseFieldArray = useFieldArray({
    control: control,
    name: "surplusChild",
  });

  const surplusOPDLevelUseFieldArray = useFieldArray({
    control: control,
    name: "surplusOPD",
  });

  const surplusIPDLevelUseFieldArray = useFieldArray({
    control: control,
    name: "surplusIPD",
  });

  const surplusDentalLevelUseFieldArray = useFieldArray({
    control: control,
    name: "surplusDental",
  });

  const IPDUseFieldArray = useFieldArray({
    control: control,
    name: "IPD",
  });
  const DentalUseFieldArray = useFieldArray({
    control: control,
    name: "Dental",
  });

  const initialSurplusData = {
    numberSurplus: "",
    surplusPercent: "",
  };
  const initialIPD = {
    nameList: "",
    limitList: "",
  };
  const initialDental = {
    nameList: "",
    limitList: "",
  };

  const appendInitailSurplus = (type) => {
    if (type === "Couple") {
      surplusCoupleLevelUseFieldArray.append(initialSurplusData);
    } else if (type === "Parents") {
      surplusParentsLevelUseFieldArray.append(initialSurplusData);
    } else if (type === "Child") {
      surplusChildLevelUseFieldArray.append(initialSurplusData);
    } else if (type === "Self") {
      surplusSelfLevelUseFieldArray.append(initialSurplusData);
    } else if (type === "OPD") {
      surplusOPDLevelUseFieldArray.append(initialSurplusData);
    } else if (type === "IPD") {
      surplusIPDLevelUseFieldArray.append(initialSurplusData);
    } else if (type === "Dental") {
      surplusDentalLevelUseFieldArray.append(initialSurplusData);
    } else {
      surplusLevelUseFieldArray.append(initialSurplusData);
    }
  };

  const appendInitialIPD = () => {
    IPDUseFieldArray.append(initialIPD);
  };

  const appendInitialDental = () => {
    DentalUseFieldArray.append(initialDental);
  };

  const handleChangePerson = (event, field) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);
    field.onChange(value);
    /* console.log("personName", personName) */
  };

  const onSubmit = async (formData) => {
    /*  console.log("formData",formData) */
    let tempUseForWho;
    if (drawerConfig && drawerConfig.data && drawerConfig.data.UseForWho) {
      tempUseForWho = drawerConfig.data.UseForWho.map(
        (index) => index.idUseForWho
      );
    }
    const cleansingForm = {
      rightsName: formData.rightsName || null,
      idEmploymentType: formData.idEmploymentType || null,
      idLevel: formData.idLevel || null,
      startDate: formData.startDate || null,
      endDate: formData.endDate || null,
      principle: formData.principle || null,
      limit: formData.limit || null,
      isHelpingSurplus: formData.isHelpingSurplus,
      surplus: formData.surplus || null,
      limitSelf: formData.limitSelf || null,
      isHelpingSurplusSelf: formData.isHelpingSurplusSelf,
      surplusSelf: formData.surplusSelf || null,
      limitCouple: formData.limitCouple || null,
      isHelpingSurplusCouple: formData.isHelpingSurplusCouple,
      surplusCouple: formData.surplusCouple || null,
      limitParents: formData.limitParents || null,
      isHelpingSurplusParents: formData.isHelpingSurplusParents,
      surplusParents: formData.surplusParents || null,
      limitChild: formData.limitChild || null,
      isHelpingSurplusChild: formData.isHelpingSurplusChild,
      surplusChild: formData.surplusChild || null,
      useForWho: personName || null,
      OPD_Principle: formData.OPD_Principle || null,
      limitOPD: formData.limitOPD || null,
      OPD_Number: formData.OPD_Number || null,
      OPD_MoneyPerTimes: formData.OPD_MoneyPerTimes || null,
      isHelpingSurplusOPD: formData.isHelpingSurplusOPD,
      surplusOPD: formData.surplusOPD || null,
      IPD_Principle: formData.IPD_Principle || null,
      limitIPD: formData.limitIPD || null,
      IPD: formData.IPD || null,
      isHelpingSurplusIPD: formData.isHelpingSurplusIPD,
      surplusIPD: formData.surplusIPD || null,
      Dental_Principle: formData.Dental_Principle || null,
      limitDental: formData.limitDental || null,
      Dental: formData.Dental || null,
      isHelpingSurplusDental: formData.isHelpingSurplusDental,
      surplusDental: formData.surplusDental || null,
      idRightsManage:
        (drawerConfig &&
          drawerConfig.data &&
          drawerConfig.data.idRightsManage) ||
        null,
      idUseForWho:
        (drawerConfig &&
          drawerConfig.data &&
          drawerConfig.data.UseForWho &&
          tempUseForWho) ||
        [],
    };

    cleansingForm &&
      cleansingForm.Dental &&
      cleansingForm.Dental.map((dental) => {
        dental.limitList = dental.limitList === "" ? null : dental.limitList;
        dental.nameList = dental.nameList === "" ? null : dental.nameList;
      });

    cleansingForm &&
      cleansingForm.IPD &&
      cleansingForm.IPD.map((IPD) => {
        IPD.limitList = IPD.limitList === "" ? null : IPD.limitList;
        IPD.nameList = IPD.nameList === "" ? null : IPD.nameList;
      });

    cleansingForm &&
      cleansingForm.surplus &&
      cleansingForm.surplus.map((surplus) => {
        surplus.numberSurplus =
          surplus.numberSurplus === "" ? null : surplus.numberSurplus;
        surplus.surplusPercent =
          surplus.surplusPercent === "" ? null : surplus.surplusPercent;
      });
    cleansingForm &&
      cleansingForm.surplusSelf &&
      cleansingForm.surplusSelf.map((surplusSelf) => {
        surplusSelf.numberSurplus =
          surplusSelf.numberSurplus === "" ? null : surplusSelf.numberSurplus;
        surplusSelf.surplusPercent =
          surplusSelf.surplusPercent === "" ? null : surplusSelf.surplusPercent;
      });
    cleansingForm &&
      cleansingForm.surplusCouple &&
      cleansingForm.surplusCouple.map((surplusCouple) => {
        surplusCouple.numberSurplus =
          surplusCouple.numberSurplus === ""
            ? null
            : surplusCouple.numberSurplus;
        surplusCouple.surplusPercent =
          surplusCouple.surplusPercent === ""
            ? null
            : surplusCouple.surplusPercent;
      });
    cleansingForm &&
      cleansingForm.surplusChild &&
      cleansingForm.surplusChild.map((surplusChild) => {
        surplusChild.numberSurplus =
          surplusChild.numberSurplus === "" ? null : surplusChild.numberSurplus;
        surplusChild.surplusPercent =
          surplusChild.surplusPercent === ""
            ? null
            : surplusChild.surplusPercent;
      });
    cleansingForm &&
      cleansingForm.surplusParents &&
      cleansingForm.surplusParents.map((surplusParents) => {
        surplusParents.numberSurplus =
          surplusParents.numberSurplus === ""
            ? null
            : surplusParents.numberSurplus;
        surplusParents.surplusPercent =
          surplusParents.surplusPercent === ""
            ? null
            : surplusParents.surplusPercent;
      });
    cleansingForm &&
      cleansingForm.surplusOPD &&
      cleansingForm.surplusOPD.map((surplusOPD) => {
        surplusOPD.numberSurplus =
          surplusOPD.numberSurplus === "" ? null : surplusOPD.numberSurplus;
        surplusOPD.surplusPercent =
          surplusOPD.surplusPercent === "" ? null : surplusOPD.surplusPercent;
      });
    cleansingForm &&
      cleansingForm.surplusIPD &&
      cleansingForm.surplusIPD.map((surplusIPD) => {
        surplusIPD.numberSurplus =
          surplusIPD.numberSurplus === "" ? null : surplusIPD.numberSurplus;
        surplusIPD.surplusPercent =
          surplusIPD.surplusPercent === "" ? null : surplusIPD.surplusPercent;
      });
    cleansingForm &&
      cleansingForm.surplusDental &&
      cleansingForm.surplusDental.map((surplusDental) => {
        surplusDental.numberSurplus =
          surplusDental.numberSurplus === ""
            ? null
            : surplusDental.numberSurplus;
        surplusDental.surplusPercent =
          surplusDental.surplusPercent === ""
            ? null
            : surplusDental.surplusPercent;
      });

    console.log("formData", cleansingForm);
    if (drawerConfig && drawerConfig.isEdit) {
      const response = await dispatch(updateRights(cleansingForm));
      handleSubmitRights(response);
    } else {
      const response = await dispatch(addRights(cleansingForm));
      handleSubmitRights(response);
    }
  };

  useEffect(() => {
    dispatch(getLevelGroup());
    dispatch(getEmploymentTypeByidCompany);
    if (drawerConfig && drawerConfig.isEdit) {
      if (drawerConfig.data) {
        const personName = drawerConfig.data.UseForWho.map((index) => {
          console.log("index", index);
          return index.relationName;
        });
        console.log("show", personName);
        SetselectedButton(
          drawerConfig.data.principle === "totalLimit"
            ? "1"
            : drawerConfig.data.principle === "individualLimit"
            ? "2"
            : drawerConfig.data.principle === "detail"
            ? "3"
            : ""
        );
        SetSelectedButtonOPD(
          drawerConfig.data.OPD_principle === "OPD_totalLimit"
            ? "1"
            : drawerConfig.data.OPD_principle === "OPD_number"
            ? "4"
            : ""
        );
        SetSelectedButtonIPD(
          drawerConfig.data.IPD_Principle === "IPD_totalLimit"
            ? "1"
            : drawerConfig.data.IPD_Principle === "IPD_detail"
            ? "3"
            : ""
        );
        SetSelectedButtonDental(
          drawerConfig.data.Dental_Principle === "Dental_totalLimit"
            ? "1"
            : drawerConfig.data.Dental_Principle === "Dental_detail"
            ? "3"
            : ""
        );
        setPersonName(personName);
        SetToggleHelpingSurplus(drawerConfig.data.isHelpingSurplus);
        SetToggleHelpingSurplusSelf(drawerConfig.data.isHelpingSurplusSelf);
        SetToggleHelpingSurplusCouple(drawerConfig.data.isHelpingSurplusCouple);
        SetToggleHelpingSurplusParents(
          drawerConfig.data.isHelpingSurplusParents
        );
        SetToggleHelpingSurplusChild(drawerConfig.data.isHelpingSurplusChild);
        SetToggleHelpingSurplusOPD(drawerConfig.data.isHelpingSurplusOPD);
        SetToggleHelpingSurplusIPD(drawerConfig.data.isHelpingSurplusIPD);
        SetToggleHelpingSurplusDental(drawerConfig.data.isHelpingSurplusDental);
        reset({
          rightsName: drawerConfig.data.rightsName,
          idEmploymentType: drawerConfig.data.idEmploymentType,
          startDate: drawerConfig.data.startDate,
          endDate: drawerConfig.data.endDate,
          idLevel: drawerConfig.data.idLevel,
          principle: drawerConfig.data.principle,
          limit: drawerConfig.data.limit,
          isHelpingSurplus: drawerConfig.data.isHelpingSurplus,
          surplus: drawerConfig.data.Surplus,
          limitSelf: drawerConfig.data.limitSelf,
          isHelpingSurplusSelf: drawerConfig.data.isHelpingSurplusSelf,
          surplusSelf: drawerConfig.data.SurplusSelf,
          limitCouple: drawerConfig.data.limitCouple,
          isHelpingSurplusCouple: drawerConfig.data.isHelpingSurplusCouple,
          surplusCouple: drawerConfig.data.SurplusCouple,
          limitParents: drawerConfig.data.limitParents,
          isHelpingSurplusParents: drawerConfig.data.isHelpingSurplusParents,
          surplusParents: drawerConfig.data.SurplusParents,
          limitChild: drawerConfig.data.limitChild,
          isHelpingSurplusChild: drawerConfig.data.isHelpingSurplusChild,
          surplusChild: drawerConfig.data.SurplusChild,
          useForWho: personName,
          limitOPD: drawerConfig.data.limitOPD,
          OPD_Number: drawerConfig.data.OPD_Number,
          OPD_MoneyPerTimes: drawerConfig.data.OPD_MoneyPerTimes,
          isHelpingSurplusOPD: drawerConfig.data.isHelpingSurplusOPD,
          IPD_Principle: drawerConfig.data.IPD_Principle,
          limitIPD: drawerConfig.data.limitIPD,
          IPD: drawerConfig.data.IPD,
          isHelpingSurplusIPD: drawerConfig.data.isHelpingSurplusIPD,
          Dental_Principle: drawerConfig.data.Dental_Principle,
          limitDental: drawerConfig.data.limitDental,
          Dental: drawerConfig.data.Dental,
          isHelpingSurplusDental: drawerConfig.data.isHelpingSurplusDental,
        });
      }
    }
  }, [drawerConfig]);

  const handleSetValueToNull = () => {
    setValue("limitOPD", "");
    setValue("OPD_Principle", "");
    setValue("limitDental", "");
    setValue("Dental", "");
    setValue("Dental_Principle", "");
    setValue("limitIPD", "");
    setValue("IPD", "");
    setValue("IPD_Principle", "");
    setValue("OPD_MoneyPerTimes", "");
    setValue("OPD_Number", "");
    setValue("isHelpingSurplus", "");
    setValue("isHelpingSurplusSelf", "");
    setValue("isHelpingSurplusChild", "");
    setValue("isHelpingSurplusCouple", "");
    setValue("isHelpingSurplusParents", "");
    setValue("isHelpingSurplusOPD", "");
    setValue("isHelpingSurplusIPD", "");
    setValue("isHelpingSurplusDental", "");
    setValue("limit", "");
    setValue("limitSelf", "");
    setValue("limitChild", "");
    setValue("limitCouple", "");
    setValue("limitParents", "");
    setValue("surplus", "");
    setValue("surplusSelf", "");
    setValue("surplusChild", "");
    setValue("surplusCouple", "");
    setValue("surplusParents", "");
    setValue("surplusOPD", "");
    setValue("surplusIPD", "");
    setValue("surplusDental", "");
    setValue("useForWho", []);
    SetToggleHelpingSurplus("");
    SetToggleHelpingSurplusSelf("");
    SetToggleHelpingSurplusChild("");
    SetToggleHelpingSurplusCouple("");
    SetToggleHelpingSurplusParents("");
    SetToggleHelpingSurplusOPD("");
    SetToggleHelpingSurplusIPD("");
    SetToggleHelpingSurplusDental("");
    SetSelectedButtonOPD("");
    SetSelectedButtonIPD("");
    SetSelectedButtonDental("");
  };

  return (
    <div className={drawerConfig && drawerConfig.idEdit ? "" : "page"}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container
            maxWidth="lg"
            style={{ padding: "20px 20px", marginTop: "20px" }}
          >
            {/* {console.log("LevelGroup", LevelGroup)} */}
            <Typography variant="h4" component="div" gutterBottom>
              {drawerConfig && drawerConfig.idEdit
                ? "แก้ไขสิทธิพนักงาน"
                : "จัดการสิทธิพนักงาน"}
            </Typography>
            {console.log("drawerConfig", drawerConfig)}
            <form
              onSubmit={handleSubmit(onSubmit, (errors) => {
                console.log(errors);
              })}
            >
              {page === 0 && (
                <Fragment>
                  {/* ชื่อสิทธื */}
                  <Grid
                    item
                    container
                    marginTop={2}
                    marginLeft={3}
                    xs={11.5}
                    justifyContent={"space-between"}
                  >
                    <Grid item marginTop={1} sm={12}>
                      <Typography style={{ fontSize: "18px" }}>
                        {" "}
                        ชื่อสิทธิ{" "}
                      </Typography>
                      <Controller
                        control={control}
                        name={`rightsName`}
                        rules={{
                          required: {
                            value: true,
                            message: "กรุณากรอกชื่อสิทธิ",
                          },
                        }}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            autoComplete="rightsName"
                            error={
                              formState.errors && formState.errors.rightsName
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors.rightsName
                                ? formState.errors.rightsName.message
                                : null
                            }
                          ></TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/*ประเภทการจ้าง */}
                  <Grid
                    item
                    container
                    marginTop={2}
                    marginLeft={3}
                    xs={11.5}
                    justifyContent={"space-between"}
                  >
                    <Grid item marginTop={1} sm={5.8}>
                      <Typography style={{ fontSize: "18px" }}>
                        {" "}
                        {t("EmploymentType")}{" "}
                      </Typography>
                      <Controller
                        control={control}
                        name={`idEmploymentType`}
                        rules={{
                          required: {
                            value: true,
                            message: t("pleaseSelectEmploymentType"),
                          },
                        }}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            select
                            error={
                              formState.errors && formState.idEmploymentType
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors &&
                              formState.errors.idEmploymentType
                                ? formState.errors.idEmploymentType.message
                                : null
                            }
                          >
                            <MenuItem value="none" disabled={true}>
                              --{t("selectEmploymentType")}--
                            </MenuItem>
                            {/* <MenuItem value={"FULLTIME"}>พนักงานประจำ</MenuItem>
                            <MenuItem value={"CONTRACT"}>
                              พนักงานสัญญาจ้าง
                            </MenuItem> */}
                            {employmentType &&
                              employmentType.map((item) => (
                                <MenuItem
                                  key={item.idEmploymentType}
                                  value={item.idEmploymentType}
                                >
                                  {item.employmentTypeName}
                                </MenuItem>
                              ))}
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                    <Grid item marginTop={1} sm={5.8}>
                      <Typography style={{ fontSize: "18px" }}>
                        {" "}
                        Level Group ({t("Optional")}){" "}
                      </Typography>
                      <Controller
                        control={control}
                        name={`idLevel`}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            select
                            error={
                              formState.errors && formState.errors.idLevel
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors.idLevel
                                ? formState.errors.idLevel.message
                                : null
                            }
                          >
                            {LevelGroup &&
                              LevelGroup.data &&
                              LevelGroup.data.map((level) => (
                                <MenuItem
                                  key={level.idLevel}
                                  value={level.idLevel}
                                >
                                  {level.levelName}
                                </MenuItem>
                              ))}
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/*ช่วงเวลาที่ใช้สิทธิ*/}
                  <Grid
                    item
                    container
                    marginTop={2}
                    marginLeft={3}
                    xs={11.5}
                    justifyContent={"space-between"}
                  >
                    <Grid item marginTop={1} sm={5.8}>
                      <Typography style={{ fontSize: "18px" }}>
                        {" "}
                        {t("StartDate")}{" "}
                      </Typography>
                      <Controller
                        control={control}
                        name={`startDate`}
                        errors={errors}
                        render={({ field, formState }) => (
                          <DatePickerCustom
                            {...field}
                            error={
                              formState.errors && formState.errors.startDate
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors.startDate
                                ? formState.errors.startDate.message
                                : null
                            }
                            inputFormat="DD/MM/YYYY"
                            onChange={(e) => {
                              field.onChange(dayjs(e).format("YYYY/MM/DD"));
                            }}
                            renderInput={(params) => (
                              <TextFieldTheme fullWidth {...params} />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item marginTop={1} sm={5.8}>
                      <Typography style={{ fontSize: "18px" }}>
                        {" "}
                        {t("EndDate")}{" "}
                      </Typography>
                      <Controller
                        control={control}
                        name={`endDate`}
                        errors={errors}
                        render={({ field, formState }) => (
                          <DatePickerCustom
                            {...field}
                            error={
                              formState.errors && formState.errors.endDate
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors.endDate
                                ? formState.errors.endDate.message
                                : null
                            }
                            inputFormat="DD/MM/YYYY"
                            onChange={(e) => {
                              field.onChange(dayjs(e).format("YYYY/MM/DD"));
                            }}
                            renderInput={(params) => (
                              <TextFieldTheme fullWidth {...params} />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/*หลักการ*/}
                  <Grid item container marginTop={2} xs={12}>
                    <Typography
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      {" "}
                      หลักการ{" "}
                    </Typography>
                  </Grid>
                  {selectedButton && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        color="error"
                        onClick={() => {
                          SetselectedButton("");
                          setValue("principle", "");
                          handleSetValueToNull();
                        }}
                      >
                        <Typography>{t("Edit")}</Typography>
                      </Button>
                    </Grid>
                  )}
                  <Grid item container marginTop={2} xs={12}>
                    <Grid item marginTop={1} sm={12}>
                      <Controller
                        control={control}
                        name={`principle`}
                        rules={{
                          required: {
                            value: true,
                            message: "กรุณาเลือกหลักการ",
                          },
                        }}
                        render={({ field, formState }) => (
                          <Fragment>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              gap={20}
                            >
                              <ButtonBlue
                                disabled={
                                  selectedButton === "2" ||
                                  selectedButton === "3"
                                }
                                onClick={() => {
                                  SetselectedButton("1");
                                  setValue("principle", "totalLimit");
                                  clearErrors("principle");
                                }}
                                variant="contained"
                              >
                                {" "}
                                <Typography>วงเงินรวม</Typography>
                              </ButtonBlue>
                              <ButtonBlue
                                disabled={
                                  selectedButton === "1" ||
                                  selectedButton === "3"
                                }
                                onClick={() => {
                                  SetselectedButton("2");
                                  setValue("principle", "individualLimit");
                                  clearErrors("principle");
                                }}
                                variant="contained"
                              >
                                {" "}
                                <Typography>วงเงินรายคน</Typography>
                              </ButtonBlue>
                              <ButtonBlue
                                disabled={
                                  selectedButton === "1" ||
                                  selectedButton === "2"
                                }
                                onClick={() => {
                                  SetselectedButton("3");
                                  setValue("principle", "detail");
                                  clearErrors("principle");
                                }}
                                variant="contained"
                              >
                                {" "}
                                <Typography>แยกตามรายละเอียด</Typography>
                              </ButtonBlue>
                            </Box>
                            <FormHelperText sx={{ marginTop: 1 }}>
                              {watch("principle") === "" ? (
                                "กรุณาเลือกหลักการ"
                              ) : (
                                <div></div>
                              )}
                            </FormHelperText>
                          </Fragment>
                        )}
                      />
                    </Grid>
                  </Grid>

                  {/*รายละเอียดหลักการ*/}

                  {/*วงเงินรวม*/}
                  {selectedButton === "1" && (
                    <Fragment>
                      {/* วงเงินรวม กับ ช่วยเหลือส่วนเกิน */}
                      <Grid
                        item
                        container
                        marginTop={2}
                        xs={11.5}
                        marginLeft={3}
                        justifyContent={"space-between"}
                      >
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            วงเงิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`limit`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกจำนวนเงิน",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  suffix: " บาท",
                                  thousandSeparator: ",",
                                  value: field.value,
                                  onValueChange: (values) => {
                                    /* console.log("values",values) */
                                    const { value } = values;
                                    /* console.log("value",value) */
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                                autoComplete="limit"
                                error={
                                  formState.errors && formState.errors.limit
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors && formState.errors.limit
                                    ? formState.errors.limit.message
                                    : null
                                }
                              ></TextFieldTheme>
                            )}
                          />
                        </Grid>
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            ช่วยเหลือส่วนเกิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`isHelpingSurplus`}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                select
                                value={ToggleHelpingSurplus}
                                onChange={(e) => {
                                  handleOnChange(e.target.value, "total");
                                }}
                                error={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplus
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplus
                                    ? formState.errors.isHelpingSurplus.message
                                    : null
                                }
                              >
                                <MenuItem value="none" disabled={true}>
                                  --เลือกรูปแบบการช่วยเหลือ--
                                </MenuItem>
                                <MenuItem value={1}>มี</MenuItem>
                                <MenuItem value={0}>ไม่มี</MenuItem>
                              </TextFieldTheme>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {ToggleHelpingSurplus === 1 &&
                        surplusLevelUseFieldArray.fields.map(
                          (surplus, index) => (
                            <Fragment>
                              <Grid key={surplus.id} item xs={12}>
                                <Grid item xs={12}>
                                  <Divider
                                    sx={{
                                      marginTop: "24px",
                                      borderColor: "#9e9e9e66",
                                    }}
                                  />
                                </Grid>
                                {surplusLevelUseFieldArray.fields.length >
                                  1 && (
                                  <Grid marginTop={1.5}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          surplusLevelUseFieldArray.remove(
                                            index
                                          );
                                          setIdSurplus([
                                            ...idSurplus,
                                            surplus.idSurplus,
                                          ]);
                                        }}
                                      >
                                        <i
                                          className="fa-regular fa-trash"
                                          style={{ color: "#ff0000" }}
                                        ></i>
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  container
                                  xs={11.5}
                                  marginTop={1}
                                  marginLeft={3}
                                  justifyContent={"space-between"}
                                >
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {`ส่วนเกินขั้น ${index + 1} (ไม่เกิน)`}{" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplus.${index}.numberSurplus`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวนเงิน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            allowNegative: false,
                                            suffix: " บาท",
                                            thousandSeparator: ",",
                                            value: field.value,
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplus &&
                                            formState.errors.surplus[index] &&
                                            formState.errors.surplus[index]
                                              .numberSurplus
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplus &&
                                            formState.errors.surplus[index] &&
                                            formState.errors.surplus[index]
                                              .numberSurplus
                                              ? formState.errors.surplus[index]
                                                  .numberSurplus.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {" "}
                                      บริษัทช่วยเหลือเพิ่ม (%){" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplus.${index}.surplusPercent`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          /* onChange={(e) => { }} */
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            value: field.value,
                                            allowNegative: false,
                                            suffix: " %",
                                            thousandSeparator: ",",
                                            isAllowed: (value) => {
                                              const { floatValue } = value;
                                              return floatValue < 100;
                                            },
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplus &&
                                            formState.errors.surplus[index] &&
                                            formState.errors.surplus[index]
                                              .surplusPercent
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplus &&
                                            formState.errors.surplus[index] &&
                                            formState.errors.surplus[index]
                                              .surplusPercent
                                              ? formState.errors.surplus[index]
                                                  .surplusPercent.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                                {/* <Grid item xs={12}>
                          <Divider
                            sx={{
                              marginTop: "24px",
                              borderColor: "#9e9e9e66"
                            }}
                          />
                        </Grid> */}
                              </Grid>
                            </Fragment>
                          )
                        )}
                      {ToggleHelpingSurplus === 1 && (
                        <Grid marginTop={1}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                appendInitailSurplus();
                              }}
                            >
                              <i className="fa-regular fa-circle-plus"></i>
                            </IconButton>
                          </Box>
                        </Grid>
                      )}
                    </Fragment>
                  )}

                  {/* วงเงินรายบุคคล */}
                  {selectedButton === "2" && (
                    <Fragment>
                      {/* วงเงินรวม กับ ช่วยเหลือส่วนเกิน */}
                      <Grid marginTop={3} xs={12}>
                        <Typography style={{ fontSize: "24px" }}>
                          {" "}
                          พนักงาน{" "}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        marginLeft={3}
                        marginTop={1}
                        xs={11.5}
                        justifyContent={"space-between"}
                      >
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            วงเงิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`limitSelf`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกจำนวนเงิน",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  suffix: " บาท",
                                  thousandSeparator: ",",
                                  value: field.value,
                                  onValueChange: (values) => {
                                    /* console.log("values",values) */
                                    const { value } = values;
                                    /* console.log("value",value) */
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                                autoComplete="limitSelf"
                                error={
                                  formState.errors && formState.errors.limitSelf
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors && formState.errors.limitSelf
                                    ? formState.errors.limitSelf.message
                                    : null
                                }
                              ></TextFieldTheme>
                            )}
                          />
                        </Grid>
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            ช่วยเหลือส่วนเกิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`isHelpingSurplusSelf`}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                select
                                value={ToggleHelpingSurplusSelf}
                                onChange={(e) => {
                                  handleOnChange(e.target.value, "Self");
                                }}
                                error={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusSelf
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusSelf
                                    ? formState.errors.isHelpingSurplusSelf
                                        .message
                                    : null
                                }
                              >
                                <MenuItem value="none" disabled={true}>
                                  --เลือกรูปแบบการช่วยเหลือ--
                                </MenuItem>
                                <MenuItem value={1}>มี</MenuItem>
                                <MenuItem value={0}>ไม่มี</MenuItem>
                              </TextFieldTheme>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {ToggleHelpingSurplusSelf === 1 &&
                        surplusSelfLevelUseFieldArray.fields.map(
                          (surplusSelf, index) => (
                            <Fragment>
                              <Grid key={surplusSelf.id} item xs={12}>
                                <Grid item xs={12}>
                                  <Divider
                                    sx={{
                                      marginTop: "24px",
                                      borderColor: "#9e9e9e66",
                                    }}
                                  />
                                </Grid>
                                {surplusSelfLevelUseFieldArray.fields.length >
                                  1 && (
                                  <Grid marginTop={1.5}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          surplusSelfLevelUseFieldArray.remove(
                                            index
                                          );
                                          setIdSurplusParents([
                                            ...idSurplusSelf,
                                            surplusSelf.idSurplusSelf,
                                          ]);
                                        }}
                                      >
                                        <i
                                          className="fa-regular fa-trash"
                                          style={{ color: "#ff0000" }}
                                        ></i>
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  container
                                  xs={11.5}
                                  marginLeft={3}
                                  marginTop={1}
                                  justifyContent={"space-between"}
                                >
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {`ส่วนเกินขั้น ${index + 1} (ไม่เกิน)`}{" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusSelf.${index}.numberSurplus`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวนเงิน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            allowNegative: false,
                                            suffix: " บาท",
                                            thousandSeparator: ",",
                                            value: field.value,
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusSelf &&
                                            formState.errors.surplusSelf[
                                              index
                                            ] &&
                                            formState.errors.surplusSelf[index]
                                              .numberSurplus
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusSelf &&
                                            formState.errors.surplusSelf[
                                              index
                                            ] &&
                                            formState.errors.surplusSelf[index]
                                              .numberSurplus
                                              ? formState.errors.surplusSelf[
                                                  index
                                                ].numberSurplus.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {" "}
                                      บริษัทช่วยเหลือเพิ่ม (%){" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusSelf.${index}.surplusPercent`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          /* onChange={(e) => { }} */
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            value: field.value,
                                            allowNegative: false,
                                            suffix: " %",
                                            thousandSeparator: ",",
                                            isAllowed: (value) => {
                                              const { floatValue } = value;
                                              return floatValue < 100;
                                            },
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusSelf &&
                                            formState.errors.surplusSelf[
                                              index
                                            ] &&
                                            formState.errors.surplusSelf[index]
                                              .surplusPercent
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusSelf &&
                                            formState.errors.surplusSelf[
                                              index
                                            ] &&
                                            formState.errors.surplusSelf[index]
                                              .surplusPercent
                                              ? formState.errors.surplusSelf[
                                                  index
                                                ].surplusPercent.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                                {/* <Grid item xs={12}>
                          <Divider
                            sx={{
                              marginTop: "24px",
                              borderColor: "#9e9e9e66"
                            }}
                          />
                        </Grid> */}
                              </Grid>
                            </Fragment>
                          )
                        )}
                      {ToggleHelpingSurplusSelf === 1 && (
                        <Grid marginTop={1}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                appendInitailSurplus("Self");
                              }}
                            >
                              <i className="fa-regular fa-circle-plus"></i>
                            </IconButton>
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider
                          sx={{
                            marginTop: "24px",
                            borderColor: "#9e9e9e66",
                          }}
                        />
                      </Grid>

                      <Grid marginTop={3} xs={12}>
                        <Typography style={{ fontSize: "24px" }}>
                          {" "}
                          คู่สมรส{" "}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        marginLeft={3}
                        marginTop={1}
                        xs={11.5}
                        justifyContent={"space-between"}
                      >
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            วงเงิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`limitCouple`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกจำนวนเงิน",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  suffix: " บาท",
                                  thousandSeparator: ",",
                                  value: field.value,
                                  onValueChange: (values) => {
                                    /* console.log("values",values) */
                                    const { value } = values;
                                    /* console.log("value",value) */
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                                autoComplete="limitCouple"
                                error={
                                  formState.errors &&
                                  formState.errors.limitCouple
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.limitCouple
                                    ? formState.errors.limitCouple.message
                                    : null
                                }
                              ></TextFieldTheme>
                            )}
                          />
                        </Grid>
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            ช่วยเหลือส่วนเกิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`isHelpingSurplusCouple`}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                select
                                value={ToggleHelpingSurplusCouple}
                                onChange={(e) => {
                                  handleOnChange(e.target.value, "Couple");
                                }}
                                error={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusCouple
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusCouple
                                    ? formState.errors.isHelpingSurplusCouple
                                        .message
                                    : null
                                }
                              >
                                <MenuItem value="none" disabled={true}>
                                  --เลือกรูปแบบการช่วยเหลือ--
                                </MenuItem>
                                <MenuItem value={1}>มี</MenuItem>
                                <MenuItem value={0}>ไม่มี</MenuItem>
                              </TextFieldTheme>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {ToggleHelpingSurplusCouple === 1 &&
                        surplusCoupleLevelUseFieldArray.fields.map(
                          (surplusCouple, index) => (
                            <Fragment>
                              <Grid key={surplusCouple.id} item xs={12}>
                                <Grid item xs={12}>
                                  <Divider
                                    sx={{
                                      marginTop: "24px",
                                      borderColor: "#9e9e9e66",
                                    }}
                                  />
                                </Grid>
                                {surplusCoupleLevelUseFieldArray.fields.length >
                                  1 && (
                                  <Grid marginTop={1.5}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          surplusCoupleLevelUseFieldArray.remove(
                                            index
                                          );
                                          setIdSurplusCouple([
                                            ...idSurplusCouple,
                                            surplusCouple.idSurplusCouple,
                                          ]);
                                        }}
                                      >
                                        <i
                                          className="fa-regular fa-trash"
                                          style={{ color: "#ff0000" }}
                                        ></i>
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  container
                                  xs={11.5}
                                  marginLeft={3}
                                  marginTop={1}
                                  justifyContent={"space-between"}
                                >
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {`ส่วนเกินขั้น ${index + 1} (ไม่เกิน)`}{" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusCouple.${index}.numberSurplus`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวนเงิน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            allowNegative: false,
                                            suffix: " บาท",
                                            thousandSeparator: ",",
                                            value: field.value,
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusCouple &&
                                            formState.errors.surplusCouple[
                                              index
                                            ] &&
                                            formState.errors.surplusCouple[
                                              index
                                            ].numberSurplus
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusCouple &&
                                            formState.errors.surplusCouple[
                                              index
                                            ] &&
                                            formState.errors.surplusCouple[
                                              index
                                            ].numberSurplus
                                              ? formState.errors.surplusCouple[
                                                  index
                                                ].numberSurplus.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {" "}
                                      บริษัทช่วยเหลือเพิ่ม (%){" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusCouple.${index}.surplusPercent`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          /* onChange={(e) => { }} */
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            value: field.value,
                                            allowNegative: false,
                                            suffix: " %",
                                            thousandSeparator: ",",
                                            isAllowed: (value) => {
                                              const { floatValue } = value;
                                              return floatValue < 100;
                                            },
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusCouple &&
                                            formState.errors.surplusCouple[
                                              index
                                            ] &&
                                            formState.errors.surplusCouple[
                                              index
                                            ].surplusPercent
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusCouple &&
                                            formState.errors.surplusCouple[
                                              index
                                            ] &&
                                            formState.errors.surplusCouple[
                                              index
                                            ].surplusPercent
                                              ? formState.errors.surplusCouple[
                                                  index
                                                ].surplusPercent.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                                {/* <Grid item xs={12}>
                          <Divider
                            sx={{
                              marginTop: "24px",
                              borderColor: "#9e9e9e66"
                            }}
                          />
                        </Grid> */}
                              </Grid>
                            </Fragment>
                          )
                        )}
                      {ToggleHelpingSurplusCouple === 1 && (
                        <Grid marginTop={1}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                appendInitailSurplus("Couple");
                              }}
                            >
                              <i className="fa-regular fa-circle-plus"></i>
                            </IconButton>
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider
                          sx={{
                            marginTop: "24px",
                            borderColor: "#9e9e9e66",
                          }}
                        />
                      </Grid>
                      <Grid marginTop={3} xs={12}>
                        <Typography style={{ fontSize: "24px" }}>
                          {" "}
                          บิดามารดา{" "}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        marginLeft={3}
                        marginTop={1}
                        xs={11.5}
                        justifyContent={"space-between"}
                      >
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            วงเงิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`limitParents`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกจำนวนเงิน",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  suffix: " บาท",
                                  thousandSeparator: ",",
                                  value: field.value,
                                  onValueChange: (values) => {
                                    /* console.log("values",values) */
                                    const { value } = values;
                                    /* console.log("value",value) */
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                                autoComplete="limitParents"
                                error={
                                  formState.errors &&
                                  formState.errors.limitParents
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.limitParents
                                    ? formState.errors.limitParents.message
                                    : null
                                }
                              ></TextFieldTheme>
                            )}
                          />
                        </Grid>
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            ช่วยเหลือส่วนเกิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`isHelpingSurplusParents`}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                select
                                value={ToggleHelpingSurplusParents}
                                onChange={(e) => {
                                  handleOnChange(e.target.value, "Parents");
                                }}
                                error={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusParents
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusParents
                                    ? formState.errors.isHelpingSurplusParents
                                        .message
                                    : null
                                }
                              >
                                <MenuItem value="none" disabled={true}>
                                  --เลือกรูปแบบการช่วยเหลือ--
                                </MenuItem>
                                <MenuItem value={1}>มี</MenuItem>
                                <MenuItem value={0}>ไม่มี</MenuItem>
                              </TextFieldTheme>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {ToggleHelpingSurplusParents === 1 &&
                        surplusParentsLevelUseFieldArray.fields.map(
                          (surplusParents, index) => (
                            <Fragment>
                              <Grid key={surplusParents.id} item xs={12}>
                                <Grid item xs={12}>
                                  <Divider
                                    sx={{
                                      marginTop: "24px",
                                      borderColor: "#9e9e9e66",
                                    }}
                                  />
                                </Grid>
                                {surplusParentsLevelUseFieldArray.fields
                                  .length > 1 && (
                                  <Grid marginTop={1.5}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          surplusParentsLevelUseFieldArray.remove(
                                            index
                                          );
                                          setIdSurplusParents([
                                            ...idSurplusParents,
                                            surplusParents.idSurplusParents,
                                          ]);
                                        }}
                                      >
                                        <i
                                          className="fa-regular fa-trash"
                                          style={{ color: "#ff0000" }}
                                        ></i>
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  container
                                  xs={11.5}
                                  marginLeft={3}
                                  marginTop={1}
                                  justifyContent={"space-between"}
                                >
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {`ส่วนเกินขั้น ${index + 1} (ไม่เกิน)`}{" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusParents.${index}.numberSurplus`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวนเงิน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            allowNegative: false,
                                            suffix: " บาท",
                                            thousandSeparator: ",",
                                            value: field.value,
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusParents &&
                                            formState.errors.surplusParents[
                                              index
                                            ] &&
                                            formState.errors.surplusParents[
                                              index
                                            ].numberSurplus
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusParents &&
                                            formState.errors.surplusParents[
                                              index
                                            ] &&
                                            formState.errors.surplusParents[
                                              index
                                            ].numberSurplus
                                              ? formState.errors.surplusParents[
                                                  index
                                                ].numberSurplus.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {" "}
                                      บริษัทช่วยเหลือเพิ่ม (%){" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusParents.${index}.surplusPercent`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          /* onChange={(e) => { }} */
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            value: field.value,
                                            allowNegative: false,
                                            suffix: " %",
                                            thousandSeparator: ",",
                                            isAllowed: (value) => {
                                              const { floatValue } = value;
                                              return floatValue < 100;
                                            },
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusParents &&
                                            formState.errors.surplusParents[
                                              index
                                            ] &&
                                            formState.errors.surplusParents[
                                              index
                                            ].surplusPercent
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusParents &&
                                            formState.errors.surplusParents[
                                              index
                                            ] &&
                                            formState.errors.surplusParents[
                                              index
                                            ].surplusPercent
                                              ? formState.errors.surplusParents[
                                                  index
                                                ].surplusPercent.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                                {/* <Grid item xs={12}>
                          <Divider
                            sx={{
                              marginTop: "24px",
                              borderColor: "#9e9e9e66"
                            }}
                          />
                        </Grid> */}
                              </Grid>
                            </Fragment>
                          )
                        )}
                      {ToggleHelpingSurplusParents === 1 && (
                        <Grid marginTop={1}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                appendInitailSurplus("Parents");
                              }}
                            >
                              <i className="fa-regular fa-circle-plus"></i>
                            </IconButton>
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider
                          sx={{
                            marginTop: "24px",
                            borderColor: "#9e9e9e66",
                          }}
                        />
                      </Grid>
                      <Grid marginTop={3} xs={12}>
                        <Typography style={{ fontSize: "24px" }}>
                          {" "}
                          บุตร{" "}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        marginLeft={3}
                        marginTop={1}
                        xs={11.5}
                        justifyContent={"space-between"}
                      >
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            วงเงิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`limitChild`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกจำนวนเงิน",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  suffix: " บาท",
                                  thousandSeparator: ",",
                                  value: field.value,
                                  onValueChange: (values) => {
                                    /* console.log("values",values) */
                                    const { value } = values;
                                    /* console.log("value",value) */
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                                autoComplete="limitChild"
                                error={
                                  formState.errors &&
                                  formState.errors.limitChild
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.limitChild
                                    ? formState.errors.limitChild.message
                                    : null
                                }
                              ></TextFieldTheme>
                            )}
                          />
                        </Grid>
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            ช่วยเหลือส่วนเกิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`isHelpingSurplusChild`}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                select
                                value={ToggleHelpingSurplusChild}
                                onChange={(e) => {
                                  handleOnChange(e.target.value, "Child");
                                }}
                                error={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusChild
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusChild
                                    ? formState.errors.isHelpingSurplusChild
                                        .message
                                    : null
                                }
                              >
                                <MenuItem value="none" disabled={true}>
                                  --เลือกรูปแบบการช่วยเหลือ--
                                </MenuItem>
                                <MenuItem value={1}>มี</MenuItem>
                                <MenuItem value={0}>ไม่มี</MenuItem>
                              </TextFieldTheme>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {ToggleHelpingSurplusParents === 1 &&
                        surplusChildLevelUseFieldArray.fields.map(
                          (surplusChild, index) => (
                            <Fragment>
                              <Grid key={surplusChild.id} item xs={12}>
                                <Grid item xs={12}>
                                  <Divider
                                    sx={{
                                      marginTop: "24px",
                                      borderColor: "#9e9e9e66",
                                    }}
                                  />
                                </Grid>
                                {surplusChildLevelUseFieldArray.fields.length >
                                  1 && (
                                  <Grid marginTop={1.5}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          surplusChildLevelUseFieldArray.remove(
                                            index
                                          );
                                          setIdSurplusChild([
                                            ...idSurplusChild,
                                            surplusChild.idSurplusChild,
                                          ]);
                                        }}
                                      >
                                        <i
                                          className="fa-regular fa-trash"
                                          style={{ color: "#ff0000" }}
                                        ></i>
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  container
                                  xs={11.5}
                                  marginLeft={3}
                                  marginTop={1}
                                  justifyContent={"space-between"}
                                >
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {`ส่วนเกินขั้น ${index + 1} (ไม่เกิน)`}{" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusChild.${index}.numberSurplus`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวนเงิน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            allowNegative: false,
                                            suffix: " บาท",
                                            thousandSeparator: ",",
                                            value: field.value,
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusChild &&
                                            formState.errors.surplusChild[
                                              index
                                            ] &&
                                            formState.errors.surplusChild[index]
                                              .numberSurplus
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusChild &&
                                            formState.errors.surplusChild[
                                              index
                                            ] &&
                                            formState.errors.surplusChild[index]
                                              .numberSurplus
                                              ? formState.errors.surplusChild[
                                                  index
                                                ].numberSurplus.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {" "}
                                      บริษัทช่วยเหลือเพิ่ม (%){" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusChild.${index}.surplusPercent`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          /* onChange={(e) => { }} */
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            value: field.value,
                                            allowNegative: false,
                                            suffix: " %",
                                            thousandSeparator: ",",
                                            isAllowed: (value) => {
                                              const { floatValue } = value;
                                              return floatValue < 100;
                                            },
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusChild &&
                                            formState.errors.surplusChild[
                                              index
                                            ] &&
                                            formState.errors.surplusChild[index]
                                              .surplusPercent
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusChild &&
                                            formState.errors.surplusChild[
                                              index
                                            ] &&
                                            formState.errors.surplusChild[index]
                                              .surplusPercent
                                              ? formState.errors.surplusChild[
                                                  index
                                                ].surplusPercent.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Fragment>
                          )
                        )}
                      {ToggleHelpingSurplusParents === 1 && (
                        <Grid marginTop={1}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                appendInitailSurplus();
                              }}
                            >
                              <i className="fa-regular fa-circle-plus"></i>
                            </IconButton>
                          </Box>
                        </Grid>
                      )}
                      {/* Footer */}
                      {/* <Grid item xs={12} marginTop={3} sm={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonBlue variant="contained" type="submit" ><Typography>ยืนยัน</Typography></ButtonBlue>
                      </Grid> */}
                    </Fragment>
                  )}

                  {/* แยกตามรายละเอียด */}
                  {selectedButton === "3" && (
                    <Fragment>
                      <Grid
                        item
                        container
                        marginTop={2}
                        xs={12}
                        justifyContent={"space-between"}
                      >
                        <Grid item marginTop={1} sm={11.5} marginLeft={3}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            ใช้กับ{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`useForWho`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกผู้ที่ต้องการใช้",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                select
                                SelectProps={{
                                  multiple: true,
                                  value: field.value,
                                  onChange: (event) => {
                                    handleChangePerson(event, field);
                                  },
                                  renderValue: (selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {console.log("field", field.value)}
                                      {selected.map((value) => (
                                        <Chip
                                          sx={{ fontSize: "16px" }}
                                          key={value}
                                          label={value}
                                        />
                                      ))}
                                    </Box>
                                  ),
                                }}
                                autoComplete="useForWho"
                                error={
                                  formState.errors && formState.errors.useForWho
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors && formState.errors.useForWho
                                    ? formState.errors.useForWho.message
                                    : null
                                }
                              >
                                {nameRelation.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    <Checkbox
                                      checked={
                                        field.value.find(
                                          (data) => data === name
                                        )
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <ListItemText primary={name} />
                                  </MenuItem>
                                ))}
                              </TextFieldTheme>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid marginTop={3} xs={12}>
                        {" "}
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          {" "}
                          OPD{" "}
                        </Typography>
                      </Grid>

                      {selectedButtonOPD && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Button
                            color="error"
                            onClick={() => {
                              SetSelectedButtonOPD("");
                              setValue("OPD_Principle", "");
                              setValue("limitOPD", "");
                              setValue("OPD_Number", "");
                              setValue("OPD_MoneyPerTimes", "");
                            }}
                          >
                            <Typography>แก้ไข</Typography>
                          </Button>
                        </Grid>
                      )}
                      <Grid item container marginTop={2} xs={12}>
                        <Grid item marginTop={1} sm={12}>
                          <Controller
                            control={control}
                            name={`OPD_Principle`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณาเลือกหลักการ",
                              },
                            }}
                            render={({ field, formState }) => (
                              <Fragment>
                                <Box
                                  display={"flex"}
                                  justifyContent={"center"}
                                  gap={20}
                                >
                                  <ButtonBlue
                                    disabled={selectedButtonOPD === "4"}
                                    onClick={() => {
                                      SetSelectedButtonOPD("1");
                                      setValue(
                                        "OPD_Principle",
                                        "OPD_totalLimit"
                                      );
                                      clearErrors("OPD_Principle");
                                    }}
                                    variant="contained"
                                  >
                                    {" "}
                                    <Typography>วงเงินรวม</Typography>
                                  </ButtonBlue>
                                  <ButtonBlue
                                    disabled={selectedButtonOPD === "1"}
                                    onClick={() => {
                                      SetSelectedButtonOPD("4");
                                      setValue("OPD_Principle", "OPD_number");
                                      clearErrors("OPD_Principle");
                                    }}
                                    variant="contained"
                                  >
                                    {" "}
                                    <Typography>จำนวนครั้ง</Typography>
                                  </ButtonBlue>
                                </Box>
                                <FormHelperText sx={{ marginTop: 1 }}>
                                  {watch("OPD_Principle") === "" ? (
                                    "กรุณาเลือกหลักการ"
                                  ) : (
                                    <div></div>
                                  )}
                                </FormHelperText>
                              </Fragment>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {selectedButtonOPD === "1" && (
                        <Fragment>
                          <Grid
                            item
                            container
                            marginTop={2}
                            xs={12}
                            justifyContent={"space-between"}
                          >
                            <Grid item marginTop={1} sm={5.8} marginLeft={3}>
                              <Typography style={{ fontSize: "18px" }}>
                                {" "}
                                วงเงินรวม{" "}
                              </Typography>
                              <Controller
                                control={control}
                                name={`limitOPD`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "กรุณากรอกจำนวนเงิน",
                                  },
                                }}
                                render={({ field, formState }) => (
                                  <TextFieldTheme
                                    {...field}
                                    InputProps={{
                                      inputComponent: NumberFormatTheme,
                                    }}
                                    inputProps={{
                                      allowNegative: false,
                                      suffix: " บาท",
                                      thousandSeparator: ",",
                                      value: field.value,
                                      onValueChange: (values) => {
                                        /* console.log("values",values) */
                                        const { value } = values;
                                        /* console.log("value",value) */
                                        field.onChange(value);
                                      },
                                    }}
                                    onChange={() => {}}
                                    autoComplete="limitOPD"
                                    error={
                                      formState.errors &&
                                      formState.errors.limitOPD
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formState.errors &&
                                      formState.errors.limitOPD
                                        ? formState.errors.limitOPD.message
                                        : null
                                    }
                                  ></TextFieldTheme>
                                )}
                              />
                            </Grid>
                            <Grid item marginTop={1} sm={5.8}>
                              <Typography style={{ fontSize: "18px" }}>
                                {" "}
                                ช่วยเหลือส่วนเกิน{" "}
                              </Typography>
                              <Controller
                                control={control}
                                name={`isHelpingSurplusOPD`}
                                render={({ field, formState }) => (
                                  <TextFieldTheme
                                    {...field}
                                    select
                                    value={ToggleHelpingSurplusOPD}
                                    onChange={(e) => {
                                      handleOnChange(e.target.value, "OPD");
                                    }}
                                    error={
                                      formState.errors &&
                                      formState.errors.isHelpingSurplusOPD
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formState.errors &&
                                      formState.errors.isHelpingSurplusOPD
                                        ? formState.errors.isHelpingSurplusOPD
                                            .message
                                        : null
                                    }
                                  >
                                    <MenuItem value="none" disabled={true}>
                                      --เลือกรูปแบบการช่วยเหลือ--
                                    </MenuItem>
                                    <MenuItem value={1}>มี</MenuItem>
                                    <MenuItem value={0}>ไม่มี</MenuItem>
                                  </TextFieldTheme>
                                )}
                              />
                            </Grid>
                          </Grid>

                          {ToggleHelpingSurplusOPD === 1 &&
                            surplusOPDLevelUseFieldArray.fields.map(
                              (surplusOPD, index) => (
                                <Fragment>
                                  <Grid key={surplusOPD.id} item xs={12}>
                                    <Grid item xs={12}>
                                      <Divider
                                        sx={{
                                          marginTop: "24px",
                                          borderColor: "#9e9e9e66",
                                        }}
                                      />
                                    </Grid>
                                    {surplusOPDLevelUseFieldArray.fields
                                      .length > 1 && (
                                      <Grid marginTop={1.5}>
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <IconButton
                                            onClick={() => {
                                              surplusOPDLevelUseFieldArray.remove(
                                                index
                                              );
                                              setIdSurplusOPD([
                                                ...idSurplusOPD,
                                                surplusOPD.idSurplusOPD,
                                              ]);
                                            }}
                                          >
                                            <i
                                              className="fa-regular fa-trash"
                                              style={{ color: "#ff0000" }}
                                            ></i>
                                          </IconButton>
                                        </Box>
                                      </Grid>
                                    )}
                                    <Grid
                                      item
                                      container
                                      xs={11.5}
                                      marginLeft={3}
                                      marginTop={1}
                                      justifyContent={"space-between"}
                                    >
                                      <Grid item marginTop={1} sm={5.8}>
                                        <Typography
                                          style={{ fontSize: "18px" }}
                                        >
                                          {`ส่วนเกินขั้น ${
                                            index + 1
                                          } (ไม่เกิน)`}{" "}
                                        </Typography>
                                        <Controller
                                          control={control}
                                          name={`surplusOPD.${index}.numberSurplus`}
                                          rules={{
                                            required: {
                                              value: true,
                                              message: "กรุณากรอกจำนวนเงิน",
                                            },
                                          }}
                                          render={({ field, formState }) => (
                                            <TextFieldTheme
                                              {...field}
                                              InputProps={{
                                                inputComponent:
                                                  NumberFormatTheme,
                                              }}
                                              inputProps={{
                                                allowNegative: false,
                                                suffix: " บาท",
                                                thousandSeparator: ",",
                                                value: field.value,
                                                onValueChange: (values) => {
                                                  const { value } = values;
                                                  field.onChange(value);
                                                },
                                              }}
                                              onChange={() => {}}
                                              error={
                                                formState.errors &&
                                                formState.errors.surplusOPD &&
                                                formState.errors.surplusOPD[
                                                  index
                                                ] &&
                                                formState.errors.surplusOPD[
                                                  index
                                                ].numberSurplus
                                                  ? true
                                                  : false
                                              }
                                              helperText={
                                                formState.errors &&
                                                formState.errors.surplusOPD &&
                                                formState.errors.surplusOPD[
                                                  index
                                                ] &&
                                                formState.errors.surplusOPD[
                                                  index
                                                ].numberSurplus
                                                  ? formState.errors.surplusOPD[
                                                      index
                                                    ].numberSurplus.message
                                                  : null
                                              }
                                            ></TextFieldTheme>
                                          )}
                                        />
                                      </Grid>
                                      <Grid item marginTop={1} sm={5.8}>
                                        <Typography
                                          style={{ fontSize: "18px" }}
                                        >
                                          {" "}
                                          บริษัทช่วยเหลือเพิ่ม (%){" "}
                                        </Typography>
                                        <Controller
                                          control={control}
                                          name={`surplusOPD.${index}.surplusPercent`}
                                          rules={{
                                            required: {
                                              value: true,
                                              message: "กรุณากรอกจำนวน",
                                            },
                                          }}
                                          render={({ field, formState }) => (
                                            <TextFieldTheme
                                              /* onChange={(e) => { }} */
                                              {...field}
                                              InputProps={{
                                                inputComponent:
                                                  NumberFormatTheme,
                                              }}
                                              inputProps={{
                                                value: field.value,
                                                allowNegative: false,
                                                suffix: " %",
                                                thousandSeparator: ",",
                                                isAllowed: (value) => {
                                                  const { floatValue } = value;
                                                  return floatValue < 100;
                                                },
                                                onValueChange: (values) => {
                                                  /* console.log("values",values) */
                                                  const { value } = values;
                                                  /* console.log("value",value) */
                                                  field.onChange(value);
                                                },
                                              }}
                                              onChange={() => {}}
                                              error={
                                                formState.errors &&
                                                formState.errors.surplusOPD &&
                                                formState.errors.surplusOPD[
                                                  index
                                                ] &&
                                                formState.errors.surplusOPD[
                                                  index
                                                ].surplusPercent
                                                  ? true
                                                  : false
                                              }
                                              helperText={
                                                formState.errors &&
                                                formState.errors.surplusOPD &&
                                                formState.errors.surplusOPD[
                                                  index
                                                ] &&
                                                formState.errors.surplusOPD[
                                                  index
                                                ].surplusPercent
                                                  ? formState.errors.surplusOPD[
                                                      index
                                                    ].surplusPercent.message
                                                  : null
                                              }
                                            ></TextFieldTheme>
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Fragment>
                              )
                            )}
                          {ToggleHelpingSurplusOPD === 1 && (
                            <Grid marginTop={1}>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    appendInitailSurplus("OPD");
                                  }}
                                >
                                  <i className="fa-regular fa-circle-plus"></i>
                                </IconButton>
                              </Box>
                            </Grid>
                          )}
                        </Fragment>
                      )}
                      {selectedButtonOPD === "4" && (
                        <Fragment>
                          <Grid
                            item
                            container
                            xs={11.5}
                            marginLeft={3}
                            /* marginTop={1} */ justifyContent={"space-between"}
                          >
                            <Grid item marginTop={1} sm={5.8}>
                              <Typography style={{ fontSize: "18px" }}>
                                {" "}
                                จำนวนครั้ง{" "}
                              </Typography>
                              <Controller
                                control={control}
                                name={`OPD_Number`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "กรุณากรอกจำนวนครั้ง",
                                  },
                                }}
                                render={({ field, formState }) => (
                                  <TextFieldTheme
                                    {...field}
                                    InputProps={{
                                      inputComponent: NumberFormatTheme,
                                    }}
                                    inputProps={{
                                      allowNegative: false,
                                      suffix: " ครั้ง",
                                      thousandSeparator: ",",
                                      value: field.value,
                                      onValueChange: (values) => {
                                        /* console.log("values",values) */
                                        const { value } = values;
                                        /* console.log("value",value) */
                                        field.onChange(value);
                                      },
                                    }}
                                    onChange={() => {}}
                                    autoComplete="OPD_Number"
                                    error={
                                      formState.errors &&
                                      formState.errors.OPD_Number
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formState.errors &&
                                      formState.errors.OPD_Number
                                        ? formState.errors.OPD_Number.message
                                        : null
                                    }
                                  ></TextFieldTheme>
                                )}
                              />
                            </Grid>
                            <Grid item marginTop={1} sm={5.8}>
                              <Typography style={{ fontSize: "18px" }}>
                                {" "}
                                เบิกได้ต่อครั้ง{" "}
                              </Typography>
                              <Controller
                                control={control}
                                name={`OPD_MoneyPerTimes`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "กรุณากรอกจำนวนเงิน",
                                  },
                                }}
                                render={({ field, formState }) => (
                                  <TextFieldTheme
                                    {...field}
                                    InputProps={{
                                      inputComponent: NumberFormatTheme,
                                    }}
                                    inputProps={{
                                      allowNegative: false,
                                      suffix: " บาท/ครั้ง",
                                      thousandSeparator: ",",
                                      value: field.value,
                                      onValueChange: (values) => {
                                        /* console.log("values",values) */
                                        const { value } = values;
                                        /* console.log("value",value) */
                                        field.onChange(value);
                                      },
                                    }}
                                    onChange={() => {}}
                                    autoComplete="OPD_MoneyPerTimes"
                                    error={
                                      formState.errors &&
                                      formState.errors.OPD_MoneyPerTimes
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formState.errors &&
                                      formState.errors.OPD_MoneyPerTimes
                                        ? formState.errors.OPD_MoneyPerTimes
                                            .message
                                        : null
                                    }
                                  ></TextFieldTheme>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}
                      {/* endOfOPD */}

                      {/* <Grid
                        item
                        container
                        xs={11.5}
                        marginLeft={3}
                        justifyContent={"space-between"}
                      >
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            จำนวนครั้ง{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`OPD_Number`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกจำนวนครั้ง",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  suffix: " ครั้ง",
                                  thousandSeparator: ",",
                                  value: field.value,
                                  onValueChange: (values) => {
                                    const { value } = values;
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                                autoComplete="OPD_Number"
                                error={
                                  formState.errors &&
                                  formState.errors.OPD_Number
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.OPD_Number
                                    ? formState.errors.OPD_Number.message
                                    : null
                                }
                              ></TextFieldTheme>
                            )}
                          />
                        </Grid>
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            เบิกได้ต่อครั้ง{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`OPD_MoneyPerTimes`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกจำนวนเงิน",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  suffix: " บาท/ครั้ง",
                                  thousandSeparator: ",",
                                  value: field.value,
                                  onValueChange: (values) => {
                                    const { value } = values;
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                                autoComplete="OPD_MoneyPerTimes"
                                error={
                                  formState.errors &&
                                  formState.errors.OPD_MoneyPerTimes
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.OPD_MoneyPerTimes
                                    ? formState.errors.OPD_MoneyPerTimes.message
                                    : null
                                }
                              ></TextFieldTheme>
                            )}
                          />
                        </Grid>
                      </Grid> */}

                      <Grid
                        item
                        xs={12}
                        marginTop={3}
                        sm={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link to="/admin/manage/RightsManage">
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              onClose && onClose();
                            }}
                          >
                            <Typography>ยกเลิก</Typography>
                          </Button>
                        </Link>
                        <ButtonBlue
                          variant="outlined"
                          onClick={() => {
                            Setpage(1);
                          }}
                        >
                          <Typography>ถัดไป</Typography>
                        </ButtonBlue>
                      </Grid>
                    </Fragment>
                  )}

                  {/* Footer */}
                  {selectedButton !== "3" && (
                    <Grid
                      item
                      xs={12}
                      marginTop={3}
                      sm={12}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Link to="/admin/manage/RightsManage">
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            onClose && onClose();
                          }}
                        >
                          <Typography>ยกเลิก</Typography>
                        </Button>
                      </Link>
                      <ButtonBlue
                        variant="contained"
                        type="submit"
                        disabled={!selectedButton}
                      >
                        <Typography>ยืนยัน</Typography>
                      </ButtonBlue>
                    </Grid>
                  )}
                </Fragment>
              )}
              {page === 1 && (
                <Fragment>
                  <Grid marginTop={3} xs={12}>
                    {" "}
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      {" "}
                      IPD{" "}
                    </Typography>
                  </Grid>
                  {selectedButtonIPD && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        color="error"
                        onClick={() => {
                          SetSelectedButtonIPD("");
                          setValue("IPD_Principle", "");
                          setValue("limitIPD", "");
                          setValue("IPD", "");
                        }}
                      >
                        <Typography>แก้ไข</Typography>
                      </Button>
                    </Grid>
                  )}
                  <Grid item container marginTop={2} xs={12}>
                    <Grid item marginTop={1} sm={12}>
                      <Controller
                        control={control}
                        name={`IPD_Principle`}
                        rules={{
                          required: {
                            value: true,
                            message: "กรุณาเลือกหลักการ",
                          },
                        }}
                        render={({ field, formState }) => (
                          <Fragment>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              gap={20}
                            >
                              <ButtonBlue
                                disabled={selectedButtonIPD === "3"}
                                onClick={() => {
                                  SetSelectedButtonIPD("1");
                                  setValue("IPD_Principle", "IPD_totalLimit");
                                  clearErrors("IPD_Principle");
                                }}
                                variant="contained"
                              >
                                {" "}
                                <Typography>วงเงินรวม</Typography>
                              </ButtonBlue>
                              <ButtonBlue
                                disabled={selectedButtonIPD === "1"}
                                onClick={() => {
                                  SetSelectedButtonIPD("3");
                                  setValue("IPD_Principle", "IPD_detail");
                                  clearErrors("IPD_Principle");
                                }}
                                variant="contained"
                              >
                                {" "}
                                <Typography>แยกตามรายละเอียด</Typography>
                              </ButtonBlue>
                            </Box>
                            <FormHelperText sx={{ marginTop: 1 }}>
                              {watch("IPD_Principle") === "" ? (
                                "กรุณาเลือกหลักการ"
                              ) : (
                                <div></div>
                              )}
                            </FormHelperText>
                          </Fragment>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {selectedButtonIPD === "1" && (
                    <Fragment>
                      <Grid
                        item
                        container
                        marginTop={2}
                        xs={12}
                        justifyContent={"space-between"}
                      >
                        <Grid item marginTop={1} sm={5.8} marginLeft={3}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            วงเงิน(ต่อครั้ง){" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`limitIPD`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกจำนวนเงิน",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  suffix: " บาท",
                                  thousandSeparator: ",",
                                  value: field.value,
                                  onValueChange: (values) => {
                                    /* console.log("values",values) */
                                    const { value } = values;
                                    /* console.log("value",value) */
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                                autoComplete="limitIPD"
                                error={
                                  formState.errors && formState.errors.limitIPD
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors && formState.errors.limitIPD
                                    ? formState.errors.limitIPD.message
                                    : null
                                }
                              ></TextFieldTheme>
                            )}
                          />
                        </Grid>
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            ช่วยเหลือส่วนเกิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`isHelpingSurplusIPD`}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                select
                                value={ToggleHelpingSurplusIPD}
                                onChange={(e) => {
                                  handleOnChange(e.target.value, "IPD");
                                }}
                                error={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusIPD
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusIPD
                                    ? formState.errors.isHelpingSurplusIPD
                                        .message
                                    : null
                                }
                              >
                                <MenuItem value="none" disabled={true}>
                                  --เลือกรูปแบบการช่วยเหลือ--
                                </MenuItem>
                                <MenuItem value={1}>มี</MenuItem>
                                <MenuItem value={0}>ไม่มี</MenuItem>
                              </TextFieldTheme>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {ToggleHelpingSurplusIPD === 1 &&
                        surplusIPDLevelUseFieldArray.fields.map(
                          (surplusIPD, index) => (
                            <Fragment>
                              <Grid key={surplusIPD.id} item xs={12}>
                                <Grid item xs={12}>
                                  <Divider
                                    sx={{
                                      marginTop: "24px",
                                      borderColor: "#9e9e9e66",
                                    }}
                                  />
                                </Grid>
                                {surplusIPDLevelUseFieldArray.fields.length >
                                  1 && (
                                  <Grid marginTop={1.5}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          surplusOPDLevelUseFieldArray.remove(
                                            index
                                          );
                                          setIdSurplusIPD([
                                            ...idSurplusIPD,
                                            surplusIPD.idSurplusIPD,
                                          ]);
                                        }}
                                      >
                                        <i
                                          className="fa-regular fa-trash"
                                          style={{ color: "#ff0000" }}
                                        ></i>
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  container
                                  xs={11.5}
                                  marginLeft={3}
                                  marginTop={1}
                                  justifyContent={"space-between"}
                                >
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {`ส่วนเกินขั้น ${index + 1} (ไม่เกิน)`}{" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusIPD.${index}.numberSurplus`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวนเงิน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            allowNegative: false,
                                            suffix: " บาท",
                                            thousandSeparator: ",",
                                            value: field.value,
                                            onValueChange: (values) => {
                                              const { value } = values;
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusIPD &&
                                            formState.errors.surplusIPD[
                                              index
                                            ] &&
                                            formState.errors.surplusIPD[index]
                                              .numberSurplus
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusIPD &&
                                            formState.errors.surplusIPD[
                                              index
                                            ] &&
                                            formState.errors.surplusIPD[index]
                                              .numberSurplus
                                              ? formState.errors.surplusIPD[
                                                  index
                                                ].numberSurplus.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {" "}
                                      บริษัทช่วยเหลือเพิ่ม (%){" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusIPD.${index}.surplusPercent`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          /* onChange={(e) => { }} */
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            value: field.value,
                                            allowNegative: false,
                                            suffix: " %",
                                            thousandSeparator: ",",
                                            isAllowed: (value) => {
                                              const { floatValue } = value;
                                              return floatValue < 100;
                                            },
                                            onValueChange: (values) => {
                                              const { value } = values;
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusIPD &&
                                            formState.errors.surplusIPD[
                                              index
                                            ] &&
                                            formState.errors.surplusIPD[index]
                                              .surplusPercent
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusIPD &&
                                            formState.errors.surplusIPD[
                                              index
                                            ] &&
                                            formState.errors.surplusIPD[index]
                                              .surplusPercent
                                              ? formState.errors.surplusIPD[
                                                  index
                                                ].surplusPercent.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Fragment>
                          )
                        )}
                      {ToggleHelpingSurplusIPD === 1 && (
                        <Grid marginTop={1}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                appendInitailSurplus("IPD");
                              }}
                            >
                              <i className="fa-regular fa-circle-plus"></i>
                            </IconButton>
                          </Box>
                        </Grid>
                      )}
                    </Fragment>
                  )}
                  {selectedButtonIPD === "3" && (
                    <Fragment>
                      {IPDUseFieldArray.fields.map((IPD, index) => (
                        <Grid key={IPD.id} item xs={12}>
                          <Grid
                            item
                            container
                            xs={11.5}
                            marginTop={3}
                            justifyContent={"space-between"}
                            marginLeft={3}
                          >
                            <Grid marginLeft={2} item marginTop={1} sm={0.8}>
                              <Typography
                                gutterBottom={true}
                                sx={{ fontSize: "18px", marginTop: "40px" }}
                              >
                                {" "}
                                {`IPD${String(index + 1).padStart(
                                  3,
                                  "0"
                                )}`}{" "}
                              </Typography>
                            </Grid>
                            <Grid item marginTop={1} sm={5}>
                              <Typography style={{ fontSize: "18px" }}>
                                {`ชื่อรายการ`}{" "}
                              </Typography>
                              <Controller
                                control={control}
                                name={`IPD.${index}.nameList`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "กรุณากรอกชื่อรายการ",
                                  },
                                }}
                                render={({ field, formState }) => (
                                  <TextFieldTheme
                                    {...field}
                                    error={
                                      formState.errors &&
                                      formState.errors.IPD &&
                                      formState.errors.IPD[index] &&
                                      formState.errors.IPD[index].nameList
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formState.errors &&
                                      formState.errors.IPD &&
                                      formState.errors.IPD[index] &&
                                      formState.errors.IPD[index].nameList
                                        ? formState.errors.IPD[index].nameList
                                            .message
                                        : null
                                    }
                                  ></TextFieldTheme>
                                )}
                              />
                            </Grid>
                            <Grid item marginTop={1} sm={5}>
                              <Typography style={{ fontSize: "18px" }}>
                                {`วงเงิน`}{" "}
                              </Typography>
                              <Controller
                                control={control}
                                name={`IPD.${index}.limitList`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "กรุณากรอกจำนวน",
                                  },
                                }}
                                render={({ field, formState }) => (
                                  <TextFieldTheme
                                    {...field}
                                    InputProps={{
                                      inputComponent: NumberFormatTheme,
                                    }}
                                    inputProps={{
                                      allowNegative: false,
                                      suffix: " บาท",
                                      thousandSeparator: ",",
                                      value: field.value,
                                      onValueChange: (values) => {
                                        /* console.log("values",values) */
                                        const { value } = values;
                                        /* console.log("value",value) */
                                        field.onChange(value);
                                      },
                                    }}
                                    onChange={() => {}}
                                    error={
                                      formState.errors &&
                                      formState.errors.IPD &&
                                      formState.errors.IPD[index] &&
                                      formState.errors.IPD[index].limitList
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formState.errors &&
                                      formState.errors.IPD &&
                                      formState.errors.IPD[index] &&
                                      formState.errors.IPD[index].limitList
                                        ? formState.errors.IPD[index].limitList
                                            .message
                                        : null
                                    }
                                  ></TextFieldTheme>
                                )}
                              />
                            </Grid>
                            {IPDUseFieldArray.fields.length > 1 && (
                              <Grid marginTop={4.5}>
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      IPDUseFieldArray.remove(index);
                                      setIdIPD([...idIPD, IPD.idIPD]);
                                    }}
                                  >
                                    <i
                                      className="fa-regular fa-trash"
                                      style={{ color: "#ff0000" }}
                                    ></i>
                                  </IconButton>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      <Grid marginTop={1}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              appendInitialIPD();
                            }}
                          >
                            <i className="fa-regular fa-circle-plus"></i>
                          </IconButton>
                        </Box>
                      </Grid>
                    </Fragment>
                  )}

                  <Grid marginTop={3} xs={12}>
                    {" "}
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      {" "}
                      Dental{" "}
                    </Typography>
                  </Grid>
                  {selectedButtonDental && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        color="error"
                        onClick={() => {
                          SetSelectedButtonDental("");
                          setValue("Dental_Principle", "");
                          setValue("limitDental", "");
                          setValue("Dental", "");
                        }}
                      >
                        <Typography>แก้ไข</Typography>
                      </Button>
                    </Grid>
                  )}
                  <Grid item container marginTop={2} xs={12}>
                    <Grid item marginTop={1} sm={12}>
                      <Controller
                        control={control}
                        name={`Dental_Principle`}
                        rules={{
                          required: {
                            value: true,
                            message: "กรุณาเลือกหลักการ",
                          },
                        }}
                        render={({ field, formState }) => (
                          <Fragment>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              gap={20}
                            >
                              <ButtonBlue
                                disabled={selectedButtonDental === "3"}
                                onClick={() => {
                                  SetSelectedButtonDental("1");
                                  setValue(
                                    "Dental_Principle",
                                    "Dental_totalLimit"
                                  );
                                  clearErrors("Dental_Principle");
                                }}
                                variant="contained"
                              >
                                {" "}
                                <Typography>วงเงินรวม</Typography>
                              </ButtonBlue>
                              <ButtonBlue
                                disabled={selectedButtonDental === "1"}
                                onClick={() => {
                                  SetSelectedButtonDental("3");
                                  setValue("Dental_Principle", "Dental_detail");
                                  clearErrors("Dental_Principle");
                                }}
                                variant="contained"
                              >
                                {" "}
                                <Typography>แยกตามรายละเอียด</Typography>
                              </ButtonBlue>
                            </Box>
                            <FormHelperText sx={{ marginTop: 1 }}>
                              {watch("Dental_Principle") === "" ? (
                                "กรุณาเลือกหลักการ"
                              ) : (
                                <div></div>
                              )}
                            </FormHelperText>
                          </Fragment>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {selectedButtonDental === "1" && (
                    <Fragment>
                      <Grid
                        item
                        container
                        marginTop={2}
                        xs={11.5}
                        justifyContent={"space-between"}
                        marginLeft={3}
                      >
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            วงเงิน(ต่อครั้ง){" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`limitDental`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกจำนวนเงิน",
                              },
                            }}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  suffix: " บาท",
                                  thousandSeparator: ",",
                                  value: field.value,
                                  onValueChange: (values) => {
                                    /* console.log("values",values) */
                                    const { value } = values;
                                    /* console.log("value",value) */
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                                autoComplete="limitDental"
                                error={
                                  formState.errors &&
                                  formState.errors.limitDental
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.limitDental
                                    ? formState.errors.limitDental.message
                                    : null
                                }
                              ></TextFieldTheme>
                            )}
                          />
                        </Grid>
                        <Grid item marginTop={1} sm={5.8}>
                          <Typography style={{ fontSize: "18px" }}>
                            {" "}
                            ช่วยเหลือส่วนเกิน{" "}
                          </Typography>
                          <Controller
                            control={control}
                            name={`isHelpingSurplusDental`}
                            render={({ field, formState }) => (
                              <TextFieldTheme
                                {...field}
                                select
                                value={ToggleHelpingSurplusDental}
                                onChange={(e) => {
                                  handleOnChange(e.target.value, "Dental");
                                }}
                                error={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusDental
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors.isHelpingSurplusDental
                                    ? formState.errors.isHelpingSurplusDental
                                        .message
                                    : null
                                }
                              >
                                <MenuItem value="none" disabled={true}>
                                  --เลือกรูปแบบการช่วยเหลือ--
                                </MenuItem>
                                <MenuItem value={1}>มี</MenuItem>
                                <MenuItem value={0}>ไม่มี</MenuItem>
                              </TextFieldTheme>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {ToggleHelpingSurplusDental === 1 &&
                        surplusDentalLevelUseFieldArray.fields.map(
                          (surplusDental, index) => (
                            <Fragment>
                              <Grid key={surplusDental.id} item xs={12}>
                                <Grid item xs={12}>
                                  <Divider
                                    sx={{
                                      marginTop: "24px",
                                      borderColor: "#9e9e9e66",
                                    }}
                                  />
                                </Grid>
                                {surplusDentalLevelUseFieldArray.fields.length >
                                  1 && (
                                  <Grid marginTop={1.5}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          surplusDentalLevelUseFieldArray.remove(
                                            index
                                          );
                                          setIdSurplusDental([
                                            ...idSurplusDental,
                                            surplusDental.idSurplusDental,
                                          ]);
                                        }}
                                      >
                                        <i
                                          className="fa-regular fa-trash"
                                          style={{ color: "#ff0000" }}
                                        ></i>
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  container
                                  xs={11.5}
                                  marginLeft={3}
                                  marginTop={1}
                                  justifyContent={"space-between"}
                                >
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {`ส่วนเกินขั้น ${index + 1} (ไม่เกิน)`}{" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusDental.${index}.numberSurplus`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวนเงิน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            allowNegative: false,
                                            suffix: " บาท",
                                            thousandSeparator: ",",
                                            value: field.value,
                                            onValueChange: (values) => {
                                              const { value } = values;
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusDental &&
                                            formState.errors.surplusDental[
                                              index
                                            ] &&
                                            formState.errors.surplusDental[
                                              index
                                            ].numberSurplus
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusDental &&
                                            formState.errors.surplusDental[
                                              index
                                            ] &&
                                            formState.errors.surplusDental[
                                              index
                                            ].numberSurplus
                                              ? formState.errors.surplusDental[
                                                  index
                                                ].numberSurplus.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item marginTop={1} sm={5.8}>
                                    <Typography style={{ fontSize: "18px" }}>
                                      {" "}
                                      บริษัทช่วยเหลือเพิ่ม (%){" "}
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`surplusDental.${index}.surplusPercent`}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "กรุณากรอกจำนวน",
                                        },
                                      }}
                                      render={({ field, formState }) => (
                                        <TextFieldTheme
                                          /* onChange={(e) => { }} */
                                          {...field}
                                          InputProps={{
                                            inputComponent: NumberFormatTheme,
                                          }}
                                          inputProps={{
                                            value: field.value,
                                            allowNegative: false,
                                            suffix: " %",
                                            thousandSeparator: ",",
                                            isAllowed: (value) => {
                                              const { floatValue } = value;
                                              return floatValue < 100;
                                            },
                                            onValueChange: (values) => {
                                              /* console.log("values",values) */
                                              const { value } = values;
                                              /* console.log("value",value) */
                                              field.onChange(value);
                                            },
                                          }}
                                          onChange={() => {}}
                                          error={
                                            formState.errors &&
                                            formState.errors.surplusDental &&
                                            formState.errors.surplusDental[
                                              index
                                            ] &&
                                            formState.errors.surplusDental[
                                              index
                                            ].surplusPercent
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            formState.errors &&
                                            formState.errors.surplusDental &&
                                            formState.errors.surplusDental[
                                              index
                                            ] &&
                                            formState.errors.surplusDental[
                                              index
                                            ].surplusPercent
                                              ? formState.errors.surplusDental[
                                                  index
                                                ].surplusPercent.message
                                              : null
                                          }
                                        ></TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Fragment>
                          )
                        )}
                      {ToggleHelpingSurplusDental === 1 && (
                        <Grid marginTop={1}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                appendInitailSurplus("Dental");
                              }}
                            >
                              <i className="fa-regular fa-circle-plus"></i>
                            </IconButton>
                          </Box>
                        </Grid>
                      )}
                    </Fragment>
                  )}
                  {selectedButtonDental === "3" && (
                    <Fragment>
                      {DentalUseFieldArray.fields.map((Dental, index) => (
                        <Grid key={Dental.id} item xs={12}>
                          <Grid
                            item
                            container
                            xs={11.5}
                            marginTop={3}
                            marginLeft={3}
                            justifyContent={"space-between"}
                          >
                            {/* <Grid item xs={12}>
                              <Divider
                                sx={{
                                  marginTop: "24px",
                                  borderColor: "#9e9e9e66"
                                }}
                              />
                            </Grid> */}
                            <Grid marginLeft={2} item marginTop={1} sm={0.8}>
                              <Typography
                                gutterBottom={true}
                                sx={{ fontSize: "18px", marginTop: "40px" }}
                              >
                                {" "}
                                {`DT${String(index + 1).padStart(3, "0")}`}{" "}
                              </Typography>
                            </Grid>
                            <Grid item marginTop={1} sm={5}>
                              <Typography style={{ fontSize: "18px" }}>
                                {`ชื่อรายการ`}{" "}
                              </Typography>
                              <Controller
                                control={control}
                                name={`Dental.${index}.nameList`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "กรุณากรอกชื่อรายการ",
                                  },
                                }}
                                render={({ field, formState }) => (
                                  <TextFieldTheme
                                    {...field}
                                    error={
                                      formState.errors &&
                                      formState.errors.Dental &&
                                      formState.errors.Dental[index] &&
                                      formState.errors.Dental[index].nameList
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formState.errors &&
                                      formState.errors.Dental &&
                                      formState.errors.Dental[index] &&
                                      formState.errors.Dental[index].nameList
                                        ? formState.errors.Dental[index]
                                            .nameList.message
                                        : null
                                    }
                                  ></TextFieldTheme>
                                )}
                              />
                            </Grid>
                            <Grid item marginTop={1} sm={5}>
                              <Typography style={{ fontSize: "18px" }}>
                                {`วงเงิน`}{" "}
                              </Typography>
                              <Controller
                                control={control}
                                name={`Dental.${index}.limitList`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "กรุณากรอกจำนวน",
                                  },
                                }}
                                render={({ field, formState }) => (
                                  <TextFieldTheme
                                    {...field}
                                    InputProps={{
                                      inputComponent: NumberFormatTheme,
                                    }}
                                    inputProps={{
                                      allowNegative: false,
                                      suffix: " บาท",
                                      thousandSeparator: ",",
                                      value: field.value,
                                      onValueChange: (values) => {
                                        /* console.log("values",values) */
                                        const { value } = values;
                                        /* console.log("value",value) */
                                        field.onChange(value);
                                      },
                                    }}
                                    onChange={() => {}}
                                    error={
                                      formState.errors &&
                                      formState.errors.Dental &&
                                      formState.errors.Dental[index] &&
                                      formState.errors.Dental[index].limitList
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formState.errors &&
                                      formState.errors.Dental &&
                                      formState.errors.Dental[index] &&
                                      formState.errors.Dental[index].limitList
                                        ? formState.errors.Dental[index]
                                            .limitList.message
                                        : null
                                    }
                                  ></TextFieldTheme>
                                )}
                              />
                            </Grid>
                            {DentalUseFieldArray.fields.length > 1 && (
                              <Grid marginTop={4.5}>
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      DentalUseFieldArray.remove(index);
                                      setIdDental([
                                        ...idDental,
                                        Dental.idDental,
                                      ]);
                                    }}
                                  >
                                    <i
                                      className="fa-regular fa-trash"
                                      style={{ color: "#ff0000" }}
                                    ></i>
                                  </IconButton>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      <Grid marginTop={1}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              appendInitialDental();
                            }}
                          >
                            <i className="fa-regular fa-circle-plus"></i>
                          </IconButton>
                        </Box>
                      </Grid>
                    </Fragment>
                  )}

                  {/* Footer */}
                  <Grid
                    item
                    container
                    marginTop={3}
                    xs={12}
                    justifyContent={"space-between"}
                  >
                    <ButtonBlue
                      variant="outlined"
                      onClick={() => {
                        Setpage(0);
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>
                        ย้อนกลับ
                      </Typography>
                    </ButtonBlue>
                    <ButtonBlue variant="contained" type="submit">
                      <Typography>ยืนยัน</Typography>
                    </ButtonBlue>
                  </Grid>
                </Fragment>
              )}
            </form>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default InsuranceRights;
