import React, { useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  Divider,
  CircularProgress,
  FormLabel,
  FormControl,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import DomainIcon from '@mui/icons-material/Domain';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { Controller, useForm, useFieldArray, useWatch } from "react-hook-form";
import { addPerformanceManager, getPerformanceManager, updateEstimatePerformanceManager } from "../../../../actions/estimatePerformance";
import { getEstimatePerformanceEmployees } from "../../../../actions/employee";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";
import {
  answerQuestion5Scale,
  answerQuestion6Scale,
} from "../../assets/data/answerScale";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const percentageScore = (questionList = []) => {
  let totalRating = 0;
  for (const item of questionList) {
    if (item.questionType === "choice") {
      totalRating += (item.answer * item.weight) / 100;
    }
  }

  return Math.round(totalRating);
};

const DialogEstimatePerformance = ({
  open,
  handleClose,
  performance,
  handleSubmitEstimatePerformance,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: estimateLevel } = useSelector((state) => state.estimateLevel);

  const validationSchema = yup.object().shape({
    idEstimateLevel: yup.number().nullable().required(`${t("ThisFieldIsRequired")}`),
    questionList: yup.array().of(
      yup.object().shape({
        answer: yup
          .string()
          .max(300)
          .required(`${t("ThisFieldIsRequired")}`),
      })
    ),
    goodPoint: yup
      .string()
      .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    weakPoint: yup
      .string()
      .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    others: yup.string().max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      questionList: [],
      goodPoint: "",
      weakPoint: "",
      others: "",
      idEstimateLevel: "",
      managerComment: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const questionUseFieldArray = useFieldArray({
    control: control,
    name: "questionList",
  });

  const questionList = useWatch({ control, name: "questionList" });

  const onSubmit = async (formData) => {
    const cleanQuestion = formData.questionList.map((item) => ({
      idPerformanceQuestion: item.idPerformanceQuestion,
      answer: item.answer,
    }));

    const cleansingForm = {
      ...formData,
      questionList: cleanQuestion,
      idEmployees: performance.idEmployees,
      yearPerformance: performance.year,
    };

    const res = await dispatch(addPerformanceManager(cleansingForm));
    if (res && res.status === 201) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getPerformanceManager());
      handleClose();
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
  };
  
  useEffect(() => {
    if (
      performance.questionSet &&
      performance.questionSet.managerQuestion &&
      performance.questionSet.managerQuestion.length > 0
    ) {
      const activeQuestion = performance.questionSet.managerQuestion.map((item) => ({
        idPerformanceQuestion: item.idPerformanceQuestion,
        question: item.question,
        questionType: item.questionType,
        weight: item.weight,
        answer: "",
      }));

      questionUseFieldArray.replace(activeQuestion);
    }
  }, [performance]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t("AnnualEvaluation")}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2} marginBottom={6}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <PersonIcon />
                  <Typography>
                    {` ${t("PersonBeingAssessed")} : ${
                      performance.firstname_TH
                    } ${performance.lastname_TH}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <DomainIcon />
                  <Typography>
                    {`${t("Position")} : ${performance.positionName}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <AssignmentIcon />
                  <Typography>
                    {`${t("questionSet")} : ${performance.questionSet
                      ? performance.questionSet.questionSetName
                      : t("NoData")
                    }`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <AssessmentIcon />
                  <Typography>
                    {`${t("Year")} : ${performance.year}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <EventIcon />
                  <Typography>
                    {`${t("EvaluationPeriod")} :  ${dayjs(
                      performance.startDate
                    ).format(
                      i18n.resolvedLanguage === "th"
                        ? "DD/MM/BBBB"
                        : "DD/MM/YYYY"
                    )} - ${dayjs(performance.endDate).format(
                      i18n.resolvedLanguage === "th"
                        ? "DD/MM/BBBB"
                        : "DD/MM/YYYY"
                    )}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <CheckCircleIcon />
                  <Typography>
                    {`${t("EvaluatedBy")} : ${getUserFullName(userProfile)}`}
                  </Typography>
                </Grid>
              </Grid>
              {performance.questionSet && performance.questionSet.employeeQuestion && (
                <>
                  <Typography variant="h6" gutterBottom>
                    {t("Employee")}
                  </Typography>
                  <Stack spacing={1} marginBottom={4}>
                    {performance.questionSet.employeeQuestion.map(
                      (question, index) => (
                        <div key={question.idPerformanceQuestion}>
                          <Typography gutterBottom fontWeight={500}>
                            {`${t("Question")} ${index + 1} : ${
                              question.question
                            }`}
                          </Typography>
                          {question.questionType === "choice" ? (
                            <>
                              <Typography>{`${t("Weight")} ${question.weight}%`}</Typography>
                              <RadioGroup row value={question.answer}>
                                {performance.questionSet.answerScale === 6
                                  ? answerQuestion6Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))
                                  : answerQuestion5Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))}
                              </RadioGroup>
                            </>
                          ) : (
                            <TextFieldTheme
                              fullWidth
                              value={question.answer}
                              InputProps={{ readOnly: true }}
                            />
                          )}
                          <Divider
                            sx={{ borderColor: "#e2e2e2", marginTop: "8px" }}
                          />
                        </div>
                      )
                    )}
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "16px 0px",
                    }}
                  >
                    <Box
                      style={{ position: "relative", display: "inline-flex" }}
                    >
                      <CircularProgress
                        variant="determinate"
                        style={{
                          color: "#eeeeee",
                        }}
                        size={70}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={percentageScore(
                          performance.questionSet.employeeQuestion
                        )}
                        style={{
                          color: "#007afe",
                          animationDuration: "550ms",
                          position: "absolute",
                          left: 0,
                        }}
                        size={70}
                      ></CircularProgress>
                      <Box
                        style={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontSize: 16 }}
                          component="div"
                          color="text.third"
                        >
                          {percentageScore(
                            performance.questionSet.employeeQuestion
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </>
              )}
              <Typography variant="h6" gutterBottom>
                {t("Manager")}
              </Typography>
              <Stack spacing={1} marginBottom={4}>
                {questionUseFieldArray.fields.length > 0 ? (
                  questionUseFieldArray.fields.map((question, index) => (
                    <div key={question.id}>
                      <Typography gutterBottom fontWeight={500}>
                        {`${t("Question")} ${index + 1} : ${question.question}`}
                      </Typography>
                      {question.questionType === "choice" ? (
                        <Controller
                          control={control}
                          name={`questionList.${index}.answer`}
                          render={({ field, fieldState }) => (
                            <>
                              <Typography>{`${t("Weight")} ${
                                question.weight
                              }%`}</Typography>
                              <RadioGroup {...field} row>
                                {performance.answerScale === 6
                                  ? answerQuestion6Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))
                                  : answerQuestion5Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))}
                              </RadioGroup>
                              {Boolean(fieldState.error) && (
                                <FormHelperText sx={{ color: "#ff0000" }}>
                                  {fieldState.error.message}
                                </FormHelperText>
                              )}
                            </>
                          )}
                        />
                      ) : (
                        <Controller
                          control={control}
                          name={`questionList.${index}.answer`}
                          render={({ field, fieldState }) => (
                            <TextFieldTheme
                              {...field}
                              fullWidth
                              error={Boolean(fieldState.error)}
                              helperText={
                                fieldState.error && fieldState.error.message
                              }
                            />
                          )}
                        />
                      )}
                      <Divider
                        sx={{ borderColor: "#e2e2e2", marginTop: "8px" }}
                      />
                    </div>
                  ))
                ) : (
                  <Typography textAlign="center" color="text.secondary">
                    {t("NoQuestions")}
                  </Typography>
                )}
              </Stack>
              <Stack spacing={2} marginBottom={4}>
                <div>
                  <Typography gutterBottom>{t("Strength")}</Typography>
                  <Controller
                    control={control}
                    name={`goodPoint`}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        autoComplete="goodPoint"
                        multiline
                        rows={2}
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography gutterBottom>{t("Weakness")}</Typography>
                  <Controller
                    control={control}
                    name={`weakPoint`}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        multiline
                        rows={2}
                        autoComplete="weakPoint"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography gutterBottom>{t("Other")}</Typography>
                  <Controller
                    control={control}
                    name={`others`}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        multiline
                        rows={2}
                        autoComplete="others"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography gutterBottom>
                    {t("FeedbackFromSupervisor")}
                  </Typography>
                  <Controller
                    control={control}
                    name={"managerComment"}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        multiline
                        rows={2}
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "16px",
                  }}
                >
                  <Box style={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      variant="determinate"
                      style={{
                        color: "#eeeeee",
                      }}
                      size={70}
                      thickness={4}
                      value={100}
                    />
                    <CircularProgress
                      variant="determinate"
                      value={percentageScore(questionList)}
                      style={{
                        color: "#007afe",
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                      }}
                      size={70}
                    ></CircularProgress>
                    <Box
                      style={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{ fontSize: 16 }}
                        component="div"
                        color="text.third"
                      >
                        {percentageScore(questionList)}
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div>
                  <FormControl>
                    <FormLabel>{`${t("PerformanceAssessmentLevels")}`}</FormLabel>
                    <Controller
                      control={control}
                      name={"idEstimateLevel"}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          select
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                          error={Boolean(fieldState.error)}
                        >
                          {estimateLevel.map((item) => (
                            <MenuItem key={item.idEstimateLevel} value={item.idEstimateLevel}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextFieldTheme>
                      )}
                    />
                  </FormControl>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonBlue type="button" onClick={handleClose}>
            {t("Close")}
          </ButtonBlue>
          <ButtonBlue type="submit" variant="contained" disabled={isSubmitting}>
            {t("SaveData")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogEstimatePerformance;
