import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Breadcrumbs, Container, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from "react-i18next";
import CardStyle from "../../../shared/general/Card";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { getAffiliateCompany } from "../../../../../actions/affiliate";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../../shared/date/datePicker";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DataGrid, { Column, Export, HeaderFilter, SearchPanel } from "devextreme-react/data-grid";
import { calculateAdditionSpecialConditionTime, getAdditionSpecialConditionTimePersonalSetting, getAdditionSpecialConditionTimeSetting } from "../../../../../actions/additionSpecial";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import LoadingGif from "../../../assets/social-media.gif";
import AlertResponse from "../../../shared/general/AlertResponse";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
  "& .MuiOutlinedInput-root, & .MuiFilledInput-root": {
    "& .MuiSelect-select": {
      padding: "13.5px 14px",
    },
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root, & .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      height: "56px",
      "& input": {
        padding: 0,
        borderRadius: 0
      },
    },
  },
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px",
  },
})

const StyledWrapDataGrid = styled(Box)({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },

  '& .column-working-hours[role="columnheader"]': {
    backgroundColor: "#FFFF00",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
});

const AdditionSpecialReport = () => { 
  const category = 'AS3';
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const { result: additionSpecialConditionTimeSetting } = useSelector((state) => state.additionSpecialConditionTimeSetting);
  // const { result: additionSpecialConditionTimePersonalSetting } = useSelector((state) => state.additionSpecialConditionTimePersonalSetting);
  const { result: additionSpecialConditionTimeReport, isFetching: isFetchingAdditionSpecialConditionTimeReport } = useSelector((state) => state.additionSpecialConditionTimeReport);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const useHookForm = useForm({
    defaultValues: {
      company: null,
      searchDate: dayjs(),
      additionSpecialConditionTimeType: "addition-special-condition-time",
      additionSpecialConditionTimeSelected: "",
    },
  });

  const fetchingAdditionSpecialConditionTimeSetting = (idCompany) => {
    dispatch(getAdditionSpecialConditionTimeSetting({type:category,idCompany}))
  }

  // const fetchingAdditionSpecialConditionTimePersonalSetting = (idCompany) => {
  //   dispatch(getAdditionSpecialConditionTimePersonalSetting({type:'AS2',idCompany}))
  // }

  useEffect(() => {
    dispatch(getAffiliateCompany());
  }, []);

  useEffect(() => {
    if (userProfile && affiliateList && affiliateList.length > 0) {
      const foundCompany = affiliateList.find(x => x.idCompany === userProfile.idCompany);
      if (foundCompany) {
        useHookForm.resetField("company", { defaultValue: foundCompany })
        fetchingAdditionSpecialConditionTimeSetting(foundCompany.idCompany)
        // fetchingAdditionSpecialConditionTimePersonalSetting(foundCompany.idCompany)
      } else {
        useHookForm.resetField("company", { defaultValue: null });
      }
    }
  }, [affiliateList, userProfile]);

  const onSubmit = async (data) => {
    const result = await dispatch(calculateAdditionSpecialConditionTime({
      idCompany: data.company.idCompany,
      year: dayjs(data.searchDate).format("YYYY"),
      month: dayjs(data.searchDate).format("M"),
      additionSpecialConditionTimeType: data.additionSpecialConditionTimeType,
      additionSpecialConditionTimeSelected: data.additionSpecialConditionTimeSelected,
    }))

    if (result && result.status === 200) {
      setIsSearched(true);
      // dispatch(openNotificationAlert({
      //   type: "success",
      //   message: t("DataSaveSuccessful"),
      // }));
    } else {
      // dispatch(openNotificationAlert({
      //   type: "error",
      //   message: t("AnErrorOccurred")
      // }));
      handleChangeAlertType(t("NoData"));
      handleOpenAlert(true);
    }
  }

  const AdditionSpecialConditionTimeTypeWatch = useHookForm.watch("additionSpecialConditionTimeType");

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <Box marginBottom="24px">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{t("AdditionalSpecialOffSite")}</Typography>
          </Breadcrumbs>
        </Box>

        <Typography variant="h4">{t("AdditionalSpecialOffSite")}</Typography>
        <CardStyle style={{ marginTop: "24px", padding: 24 }}>
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="label" color="text.third">{t("Company")}</Typography>
                <Controller
                  name="company"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      options={affiliateList ? affiliateList : []}
                      getOptionLabel={(option) => {
                        const companyName = i18n.resolvedLanguage === 'th' 
                          ? option.companyName || option.companyName_EN
                          : option.companyName_EN;
                        return companyName;
                      }}
                      isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={`${t("SelectCompany")}`}
                          onBlur={field.onBlur}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          error={fieldState.error ? true : false}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        fetchingAdditionSpecialConditionTimeSetting(value.idCompany)
                        // fetchingAdditionSpecialConditionTimePersonalSetting(value.idCompany)
                        field.onChange(value);
                        useHookForm.setValue("additionSpecialSelected", "");
                        useHookForm.setValue("additionSpecialConditionTimeSelected", "");
                      }}
                      noOptionsText={`${t("NoData")}`}
                      disableClearable
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="label" color="text.third">{t("SelectMonth")}</Typography>
                <Controller
                  control={useHookForm.control}
                  name="searchDate"
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      minDate={dayjs().subtract(1, 'month')}
                      maxDate={dayjs()}
                      inputFormat="MMMM YYYY"
                      value={field.value}
                      views={['year', 'month']}
                      openTo={'month'}
                      inputProps={{
                        readOnly: true
                      }}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                        // setIsSubmitted(false);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="label" color="text.third">{t('TypesofSpecialIncome')}</Typography>
                <Controller
                  name="additionSpecialConditionTimeType"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      variant="filled"
                      // placeholder={`${t("SelectCompany")}`}
                      onBlur={field.onBlur}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      error={fieldState.error ? true : false}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        useHookForm.setValue("additionSpecialConditionTimeSelected", "");
                      }}
                    >
                      <MenuItem value="addition-special-condition-time">{t("SpecialIncomeBasedOnTimeConditions")}</MenuItem>
                      {/* <MenuItem value="addition-special-condition-time-personal">{`${t("SpecialIncomeBasedOnTimeConditions")} (${t("Individual")})`}</MenuItem> */}
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="label" color="text.third">{t("SpecialIncomeItems")}</Typography>
                <Controller
                  name="additionSpecialConditionTimeSelected"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      variant="filled"
                      // placeholder={`${t("SelectCompany")}`}
                      onBlur={field.onBlur}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      error={fieldState.error ? true : false}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>{t("SelectItem")}</MenuItem>
                      {AdditionSpecialConditionTimeTypeWatch === "addition-special-condition-time" && (
                        additionSpecialConditionTimeSetting.map((item) => (
                          <MenuItem key={item.idAdditionSpecialConditionTime} value={item.idAdditionSpecialConditionTime}>{item.additionSpecialConditionTimeName}</MenuItem>
                        ))
                      )}
                      {/* {AdditionSpecialConditionTimeTypeWatch === "addition-special-condition-time-personal" && (
                        additionSpecialConditionTimePersonalSetting.map(item => (
                          <MenuItem value={item.idAdditionSpecialConditionTimePersonal}>{item.additionSpecialConditionTimePersonalName}</MenuItem>
                        ))
                      )} */}
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={12} container justifyContent="flex-end">
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={useHookForm.formState.isSubmitting || !useHookForm.watch("additionSpecialConditionTimeSelected")}
                >
                  {t("Search")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </form>

          {useHookForm.formState.isSubmitting && isFetchingAdditionSpecialConditionTimeReport && (
            <Box
              marginTop="24px"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {/* <Typography fontSize="24px" fontStyle="oblique" textAlign="center">{t("LoadingData")}...</Typography> */}
              <img src={LoadingGif} style={{ width: 100, height: 100 }} />
            </Box>
          )}

          {isSearched && additionSpecialConditionTimeReport.AdditionSpecialPayInfo && (
            <Box marginTop="24px">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color="text.secondary">{t('Month')}</Typography>
                  <Typography fontSize="20px">{dayjs(additionSpecialConditionTimeReport.AdditionSpecialPayInfo.monthPeriod).format("MMMM YYYY")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="text.secondary">{t("DateOfTheStatisticalDataUsedForCalculation")}</Typography>
                  <Typography fontSize="20px">{dayjs(additionSpecialConditionTimeReport.AdditionSpecialPayInfo.dataStartDate).format("D MMM YYYY")} - {dayjs(additionSpecialConditionTimeReport.AdditionSpecialPayInfo.dataEndDate).format("D MMM YYYY")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="text.secondary">{t("Company")}</Typography>
                  <Typography fontSize="20px">
                    {i18n.resolvedLanguage === 'th'
                      ? additionSpecialConditionTimeReport.AdditionSpecialPayInfo.companyName ||
                      additionSpecialConditionTimeReport.AdditionSpecialPayInfo.companyName_EN
                      : additionSpecialConditionTimeReport.AdditionSpecialPayInfo.companyName_EN}
                  </Typography>
                </Grid>

                {additionSpecialConditionTimeReport && additionSpecialConditionTimeReport.AdditionSpecialPayInfo && additionSpecialConditionTimeReport.AdditionSpecialPayInfo.employeePayInfo && <Grid item xs={12}>
                  <StyledWrapDataGrid>
                    <DataGrid
                      dataSource={(additionSpecialConditionTimeReport && additionSpecialConditionTimeReport.AdditionSpecialPayInfo && additionSpecialConditionTimeReport.AdditionSpecialPayInfo.employeePayInfo) ? additionSpecialConditionTimeReport.AdditionSpecialPayInfo.employeePayInfo : []}
                      showBorders={true}
                      showColumnLines={true}
                      rowAlternationEnabled={true}
                      columnAutoWidth={true}
                      allowColumnResizing={true}
                      columnResizingMode='widget'
                    >
                      <Column
                        caption={`${t("EmployeeID")}`}
                        dataField="employeeID"
                        dataType="string"
                        width={150}
                        cssClass="column-info"
                      />
                      <Column
                        dataField={i18n.resolvedLanguage === "th" ? "fullname_TH" : "fullname_EN"}
                        caption={`${t("FullName")}`}
                        dataType="string"
                        cssClass="column-info"
                      >
                        <HeaderFilter allowSearch={true} />
                      </Column>
                      <Column
                        dataField={i18n.resolvedLanguage === "th" ? "companyName" : "companyName_EN"}
                        caption={`${t("Company")}`}
                        dataType="string"
                        cssClass="column-info"
                      />
                      <Column
                        dataField={i18n.resolvedLanguage === "th" ? "businessUnitName" : "businessUnitName_EN"}
                        caption={`${t("BusinessUnit")}`}
                        dataType="string"
                        cssClass="column-info"
                      />
                      <Column
                        dataField={i18n.resolvedLanguage === "th" ? "divisionName" : "divisionName_EN"}
                        caption={`${t("Division")}`}
                        dataType="string"
                        cssClass="column-info"
                      />
                      <Column
                        dataField={i18n.resolvedLanguage === "th" ? "departmentName" : "departmentName_EN"}
                        caption={`${t("Department")}`}
                        dataType="string"
                        cssClass="column-info"
                      />
                      <Column
                        dataField={i18n.resolvedLanguage === "th" ? "sectionName" : "sectionName_EN"}
                        caption={`${t("Section")}`}
                        dataType="string"
                        cssClass="column-info"
                      />
                      <Column
                        dataField={i18n.resolvedLanguage === "th" ? "positionName" : "positionName_EN"}
                        caption={`${t("Position")}`}
                        dataType="string"
                        cssClass="column-info"
                      />
                      {(additionSpecialConditionTimeReport && additionSpecialConditionTimeReport.AdditionSpecialPayInfo) && (
                        [...new Array(dayjs(additionSpecialConditionTimeReport.AdditionSpecialPayInfo.dataEndDate).diff(dayjs(additionSpecialConditionTimeReport.AdditionSpecialPayInfo.dataStartDate), 'day') + 1)].map((date, index) => {
                          console.log(`${dayjs(additionSpecialConditionTimeReport.AdditionSpecialPayInfo.dataStartDate).add(index, 'day').format("YYYY-MM-DD")}`)
                          return <Column
                            dataField={`${dayjs(additionSpecialConditionTimeReport.AdditionSpecialPayInfo.dataStartDate).add(index, 'day').format("YYYY-MM-DD")}`}
                            caption={`${dayjs(additionSpecialConditionTimeReport.AdditionSpecialPayInfo.dataStartDate).add(index, 'day').format("DD/MM/YYYY (dddd)")}`}
                            format="###,###,##0.00"
                            cssClass="column-shift"
                          />
                        })
                      )}
                      <Column
                        dataField="totalAmount"
                        caption={t("TotalIncome")}
                        format="###,###,##0.00"
                        cssClass="column-shift"
                      />
                      <SearchPanel
                        visible={true}
                        width={240}
                        placeholder={`${t("Search")}`}
                      />
                      <Export
                        enabled={true}
                        // fileName={`รายงานเบี้ยขยัน ${DiligenceAllowanceReport.companyName} เดือน ${dayjs(DiligenceAllowanceReport.monthPeriod).format("MMMM YYYY")} ข้อมูล ณ ${dayjs().format("DD.MM.YYYY HH.mm.ss")}`}
                        texts={{
                          exportAll: t("DownloadAllDataToExcel"),
                        }}
                      />
                    </DataGrid>
                  </StyledWrapDataGrid>
                </Grid>}
              </Grid>
            </Box>
          )}
        </CardStyle>

      </Container>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  )
}

export default AdditionSpecialReport;