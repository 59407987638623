import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompanyPerformanceYear,
  getCompanyPerformanceYear,
  updateCompanyPerformanceYear,
} from "../../../../../actions/estimatePerformance";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";

const DialogPublish = ({
  open,
  handleClose,
  idCompany,
  selectPerformanceYear,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { result: performanceYear } = useSelector(
    (state) => state.performanceYear
  );

  const validationSchema = yup.object().shape({
    year: yup.date(),
    startDate: yup.date(),
    endDate: yup.date().min(yup.ref("startDate"), t("InvalidStartandEndDates")),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      startDate: selectPerformanceYear
        ? dayjs(selectPerformanceYear.startDate)
        : dayjs(),
      endDate: selectPerformanceYear
        ? dayjs(selectPerformanceYear.endDate)
        : dayjs().add(1, "day"),
      year: selectPerformanceYear
        ? dayjs().set("year", selectPerformanceYear.year)
        : dayjs(),
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values) => {
    let isDuplicateYear = false
    if (!selectPerformanceYear) {
      isDuplicateYear = performanceYear.some(
        (item) => item.year === dayjs(values.year).year()
      );
    }

    if (isDuplicateYear) {
      dispatch(openNotificationAlert({ message: "ปีที่ประเมินซ้ำ", type: "error" }));
    } else {
      let res = null
      if (selectPerformanceYear) {
        res = await dispatch(
          updateCompanyPerformanceYear({
            idPerformanceYear: selectPerformanceYear.idPerformanceYear,
            startDate: values.startDate,
            endDate: values.endDate,
          })
        );
      } else {
        res = await dispatch(
          addCompanyPerformanceYear({
            idCompany,
            year: dayjs(values.year).year(),
            startDate: values.startDate,
            endDate: values.endDate,
          })
        );
      }

      if (res && (res.status === 200 || res.status === 201)) {
        dispatch(
          openNotificationAlert({ message: "success", type: "success" })
        );
        dispatch(getCompanyPerformanceYear({ idCompany }));
        handleClose();
      } else {
        dispatch(openNotificationAlert({ message: "error", type: "error" }));
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("selectEvaluationPeriod")}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div style={{ display: "flex", gap: "8px" }}>
            {!selectPerformanceYear && (
              <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <DatePickerCustom
                    {...field}
                    label={t("evaluationYear")}
                    views={["year"]}
                    minDate={dayjs().subtract(1, "year")}
                    maxDate={dayjs().add(1, "year")}
                    inputFormat={"YYYY"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.startDate}
                        helperText={errors.startDate && errors.startDate.message}
                      />
                    )}
                  />
                )}
              />
            )}
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DatePickerCustom
                  {...field}
                  label={t("evaluationStartDate")}
                  minDate={dayjs(watch("year"))
                    .subtract(1, "year")
                    .startOf("year")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.startDate}
                      helperText={errors.startDate && errors.startDate.message}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DatePickerCustom
                  {...field}
                  label={t("evaluationEndDate")}
                  minDate={dayjs(watch("startDate")).add(1, "day")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.endDate}
                      helperText={errors.endDate && errors.endDate.message}
                    />
                  )}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonBlue onClick={handleClose}>{t("Cancel")}</ButtonBlue>
          <ButtonBlue variant="contained" type="submit" disabled={isSubmitting}>
            {t("Save")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogPublish;
