import React, { useState, useEffect } from "react";
import { styled } from "@mui/styles";
import { useHistory } from "react-router-dom";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import {
  Grid,
  Button,
  Container,
  Typography,
  Box,
  MenuItem,
  Stack,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getKpiOrganization,
  deleteKpiOrganization,
} from "../../../../../actions/kpi";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import KPIPercent from "../assets/KPIPercent.png";
import KPIweight from "../assets/KPIweight.png";
import KpiCard from "./KpiCard";
import KpiOrganizationDetail from "./KpiOrganizationDetail";

const ContainerStyled = styled(Container)({
  height: "100%",
  paddingTop: "100px",
  backgroundColor: "transparent",
  minHeight: "100vh",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto",
    padding: "8px",
  },
});

const TotalWeightProgress = styled(LinearProgress)({
  height: "10px",
  borderRadius: "5px",
  backgroundColor: "#DCFAF8",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#16DBCC",
  },
});

const AddNewButton = styled(Button)(({ disabled }) => ({
  color: disabled ? "#9e9e9e" : "#4535C1",
  backgroundColor: disabled ? "#EEEEEE" : "white",
  border: `1px solid ${disabled ? "#9e9e9e" : "#4535C1"}`,
  borderRadius: "16px",
  "&:hover": {
    backgroundColor: disabled ? "#EEEEEE" : "#f2f2f2",
  },
}));

const YearSelect = styled(TextFieldTheme)({
  width: "100px",
  height: "4 0px",
  "& .MuiOutlinedInput-root": {
    height: "100%",
    "& fieldset": {
      borderColor: "#4535C1",
    },
    "&:hover fieldset": {
      borderColor: "#4535C1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4535C1",
    },
  },
  "& .MuiSelect-select": {
    backgroundColor: "white",
    borderRadius: "8px",
    height: "100%",
  },
});

function KpiOrganization() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: kpiOrganization } = useSelector((state) => state.kpiOrganization);
  const [selectedYear, setSelectedYear] = useState(dayjs().get("year"));
  const [planItems, setPlanItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState(null);

  const totalWeight = planItems.reduce(
    (sum, item) => sum + parseFloat(item.weight),
    0
  );

  const onDeleteKPI = async (idKpiOrganization) => {
    const res = await dispatch(deleteKpiOrganization(idKpiOrganization));
    if (res && res.status === 200) {
      Swal.fire({
        title: t("Success"),
        text: t("KPIRemovedSuccessfully"),
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: t("OK"),
      });
      await dispatch(getKpiOrganization());
    } else {
      Swal.fire({
        title: t("AnErrorOccurred"),
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: t("OK"),
      });
    }
  };

  const onDelete = async (idKpiOrganization) => {
    try {
      const result = await Swal.fire({
        title: t("ConfirmKPIDeletion"),
        text: t("CannotUndo") + "!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        await onDeleteKPI(idKpiOrganization);
      }
    } catch (error) {
      console.error("Error deleting KPI:", error);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleView = (kpi) => {
    setSelectedKpi(kpi);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    dispatch(getKpiOrganization({ year: selectedYear }));
  }, [selectedYear]);

  useEffect(() => {
    if (kpiOrganization) {
      setPlanItems([...kpiOrganization]);
    }
  }, [kpiOrganization]);

  return (
    <ContainerStyled>
      {kpiOrganization && (
        <>
          <Box marginBottom="32px">
            <GridStyled
              container
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  className="kpi"
                  sx={{ ml: 1, fontSize: "30px" }}
                >
                  KPI Organization
                </Typography>
              </Box>
            </GridStyled>

            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              mt={1}
              mb={4}
              sx={{
                gap: "16px",
                padding: { xs: "0 16px", sm: "0 8px" },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "100%", sm: "29%" },
                  height: "auto",
                }}
              >
                <img
                  src={KPIweight}
                  alt="KPIs"
                  style={{ height: "70px", marginRight: "16px" }}
                />
                <Box>
                  <Typography variant="body1" fontWeight="600">
                    KPIs
                  </Typography>
                  <Typography variant="body1" mt={1}>
                    {`${t("All")} ${planItems.length} ${t("List")}`}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "100%", sm: "29%" },
                  height: "auto",
                }}
              >
                <img
                  src={KPIPercent}
                  alt={t("TotalWeight")}
                  style={{ height: "70px", marginRight: "16px" }}
                />
                <Box width="100%">
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1" fontWeight="600">
                      {t("TotalWeight")}
                    </Typography>
                    <Typography variant="body1" fontWeight="600">
                      {totalWeight} %
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={totalWeight > 100 ? 1 : 2}
                  >
                    <TotalWeightProgress
                      variant="determinate"
                      value={totalWeight}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                  {totalWeight > 100 && (
                    <Typography
                      variant="body2"
                      color="error"
                      align="right"
                      mt={1}
                    >
                      {t("MaxWeightAllowed")} 100%
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={"0px 8px"}
            >
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Typography
                  variant="h7"
                  sx={{ color: "#343C6A", fontSize: "20px", fontWeight: "500" }}
                >
                  {t("Years")}
                </Typography>
                <YearSelect
                  variant="outlined"
                  select
                  fullWidth
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  <MenuItem value={dayjs().get("year")}>
                    {dayjs().format(
                      i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                    )}
                  </MenuItem>
                  <MenuItem value={dayjs().get("year") - 1}>
                    {dayjs()
                      .subtract(1, "year")
                      .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                  </MenuItem>
                </YearSelect>
              </Stack>
              <AddNewButton
                variant="outlined"
                onClick={() => {
                  history.push({
                    pathname: "/kpi/organization/plan",
                    state: { planList: null, isEdit: null, totalWeight },
                  });
                }}
                disabled={totalWeight >= 100}
              >
                +{t("AddNewKPI")}
              </AddNewButton>
            </Box>
          </Box>

          {planItems.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={"4px"}
            >
              <FindInPageIcon />
              <Typography variant="h6">{t("NoData")}</Typography>
            </Box>
          ) : (
            planItems.map((item) => (
              <KpiCard
                key={item.idKpiOrganization}
                kpi={item}
                onDelete={onDelete}
                onView={handleView}
                totalWeight={totalWeight}
              />
            ))
          )}

          {openDialog && selectedKpi && (
            <KpiOrganizationDetail
              open={openDialog}
              handleClose={handleCloseDialog}
              selectedKpi={selectedKpi}
            />
          )}
        </>
      )}
    </ContainerStyled>
  );
}
export default KpiOrganization;
