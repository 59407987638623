import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Select,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  TablePagination,
  TextField,
  Grid,
  Popper,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";
import Autocomplete, {
  autocompleteClasses,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Dropzone, { useDropzone } from "react-dropzone";
import DatePickerCustom from "../../../../shared/date/datePicker";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";

import GalleryIcon from "../../../../assets/gallery.png";

import utils from "../../../../../../utils";

import RowItemExpense from "./row-item";
import ActionButtons from "../action-buttons";
import SelectApprover from "../../select-approver";

import {
  addExpenseClearAdvance,
  addExpenseWelfare,
  getExpenseAdvances,
} from "../../../../../../actions/expense";
import SelectCC from "../../select-cc";
import { useTranslation } from "react-i18next";
import { currency } from "../../../../assets/data/currency";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapQuota = styled("div")({
  marginTop: 16,
  marginBottom: 36,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .right": {
    marginLeft: 16,
    "& .leave-name": {
      lineHeight: 1.2,
    },
  },
});

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.price": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  input: {
    textAlign: "left",
  },
  color: "",
  "& .MuiInputBase-root": {
    borderRadius: 0,
    height: 50,
    paddingLeft: 12,
    "& fieldset": {
      borderRadius: 8,
    },
    "& .MuiInputBase-input": {
      padding: "5px !important",
    },
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const dataInitial = {
  description: "",
  price: 0,
};

const rowHeader = [
  {
    caption: "",
    className: "delete",
  },
  {
    caption: "Unit.Items",
    className: "description",
  },
  {
    caption: "Amount",
    className: "price",
  },
  {
    caption: "",
    className: "delete",
  },
];

const ClearAdvance = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const today = dayjs().toDate();
  const { typeExpense, setting } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employees } = useSelector((state) => state.employees);
  const { result: expenseAdvances } = useSelector(
    (state) => state.expenseAdvances
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);

  const defaultCurrency = currency()[0];
  const [formData, setFormData] = useState({
    nameExpense: "",
    description: "",
    currency: "THB",
    currencyItem: currency()[0],
    currencyUnit: currency()[0].unit,
    listExpense: [dataInitial],
    remark: "",
    typeExpenseName: typeExpense,
    approver: null,
    documentDate: dayjs(today).format("YYYY/MM/DD"),
    cc_email: [],
  });
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });

  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const validationSchema = Yup.object().shape({
    nameExpense: Yup.string().required(t("ThisFieldIsRequired")),
    type: Yup.string().required(t("ThisFieldIsRequired")),
    description: Yup.string().nullable(),
    remark: Yup.string(),
    net: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    listExpense: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(t("ThisFieldIsRequired")),
        price: Yup.string().required(t("ThisFieldIsRequired")),
      })
    ),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "listExpense",
  });

  const checkValidation = (error) => {
    console.log(getValues("listExpense"));
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  useEffect(() => {
    dispatch(getExpenseAdvances());
  }, []);

  useEffect(() => {
    let differentAmount = getValues("totalAdvance") - getValues("net");
    setValue("differentAmount", differentAmount);
  }, [watch("idExpenseAdvance")]);

  useEffect(() => {
    calculateSum();
  }, [watch("listExpense").length]);

  // useEffect(() => {
  //   if (userProfile && userProfile.idManagerLV1 && employees) {
  //     setFormData({
  //       ...formData,
  //       approver: userProfile.idManagerLV1,
  //     });
  //     setValue("approver", userProfile.idManagerLV1);
  //   }
  // }, [employees, userProfile]);

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} />
  ));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const calculateSum = () => {
    let listExpense = getValues("listExpense");
    console.log("list", listExpense);
    let sumTotal = fixPoint(
      listExpense.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.amount);
      }, 0)
    );
    let differentAmount = getValues("totalAdvance") - sumTotal;
    setValue("total", sumTotal);
    setValue("net", sumTotal);
    setValue("differentAmount", differentAmount);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const createFormData = (statusId) => {
    let data = new FormData();
    data.append("nameExpense", getValues("nameExpense"));
    data.append("idExpenseAdvance", getValues("idExpenseAdvance"));
    data.append("description", getValues("description"));
    data.append("listExpense", JSON.stringify(getValues("listExpense")));
    data.append("currency", getValues("currency"));
    if (getValues("file")) {
      data.append("file", getValues("file"), getValues("file.path"));
    }
    data.append("type", getValues("type"));
    data.append("remark", getValues("remark"));
    data.append("typeExpense", 7);
    data.append("typeExpenseName", getValues("typeExpenseName"));
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("status", statusId);
    data.append("total", getValues("total"));
    data.append("net", getValues("net"));
    data.append("differentAmount", getValues("differentAmount"));
    data.append(
      "cc_email",
      JSON.stringify(
        getValues("cc_email")
          ? getValues("cc_email")
              .map((e) => e.email)
              .join(";")
          : null
      )
    );
    data.append("idPosition", userProfile.idPosition);
    return data;
  };

  const fixPoint = (value) => {
    return parseFloat(value).toFixed(2);
  };

  const filterExpenseAdvanceOptions = createFilterOptions({
    stringify: (option) => option.documentId + option.expenseName,
  });

  const handleOnClickCleardata = () => {
    reset(formData);
  };

  const handleOnClickSavedraft = async () => {
    let data = createFormData(1);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      console.log(userProfile);
      result = await dispatch(addExpenseClearAdvance(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense/ClearAdvance/Draft/${result.data.idExpense}`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  const handleOnClickSent = async () => {
    let data = createFormData(11);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      result = await dispatch(addExpenseClearAdvance(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };
  return (
    <Box>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      <form>
        <Grid container spacing={2} style={{}} alignItems={"center"}>
          <Grid item xs={12}>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {t("GeneralInformation")}
              </StyledHeadLabel>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("ClearAdvance")}
              </StyledHeadLabel>
              <FormControl
                fullWidth
                error={errors && errors.idExpenseAdvance ? true : false}
              >
                {errors &&
                  errors.idExpenseAdvance &&
                  errors.idExpenseAdvance.message && (
                    <FormHelperText error>
                      {errors.idExpenseAdvance.message}
                    </FormHelperText>
                  )}
                <Controller
                  name="idExpenseAdvance"
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <>
                      <StyledAutocomplete
                        {...field}
                        fullWidth
                        options={expenseAdvances}
                        filterOptions={filterExpenseAdvanceOptions}
                        onChange={(e, newValue) => {
                          setValue(
                            "idExpenseAdvance",
                            newValue.idExpenseAdvance
                          );
                          setValue("totalAdvance", newValue.net);
                        }}
                        popupIcon={<i class="fa-light fa-chevron-down"></i>}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            <Typography noWrap={true}>
                              [{option.documentId}] {option.expenseName}
                            </Typography>
                          </Box>
                        )}
                        getOptionLabel={(option) =>
                          `[${option.documentId}] ${option.expenseName}`
                        }
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="outlined"
                            placeholder=""
                            name="vatType"
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={t("NoData")}
                      />
                    </>
                  )}
                />
              </FormControl>
            </div>
          </Grid>
          {watch("idExpenseAdvance") && (
            <>
              <Grid item xs={12}>
                <div>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("TitleName")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.nameExpense ? true : false}
                  >
                    {errors &&
                      errors.nameExpense &&
                      errors.nameExpense.message && (
                        <FormHelperText error>
                          {errors.nameExpense.message}
                        </FormHelperText>
                      )}
                    <Controller
                      name="nameExpense"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            name="nameExpense"
                            inputProps={{ maxLength: 200 }}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                          <Typography
                            component="p"
                            textAlign="right"
                            variant="caption"
                            color="text.secondary"
                          >{`${field.value.length}/200`}</Typography>
                        </>
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("Descriptions")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.description ? true : false}
                  >
                    {errors &&
                      errors.description &&
                      errors.description.message && (
                        <FormHelperText error>
                          {errors.description.message}
                        </FormHelperText>
                      )}
                    <Controller
                      name="description"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            name="description"
                            inputProps={{ maxLength: 500 }}
                            multiline
                            rows={2}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                          <Typography
                            component="p"
                            textAlign="right"
                            variant="caption"
                            color="text.secondary"
                          >{`${field.value.length}/500`}</Typography>
                        </>
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <SelectCC control={control} errors={errors} />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("Currency")}
                    </StyledHeadLabel>
                    <FormControl
                      fullWidth
                      error={errors && errors.currency ? true : false}
                    >
                      <Controller
                        name="currencyItem"
                        control={control}
                        errors={errors}
                        render={({ field }) => (
                          <>
                            <StyledAutocomplete
                              {...field}
                              fullWidth
                              options={currency()}
                              onChange={(e, newValue) => {
                                let tempdata = { ...dataInitial };
                                if (newValue === null) {
                                  setValue("currencyItem", defaultCurrency);
                                  setValue("currency", "THB");
                                  setValue("currencyUnit", "THB");
                                  setValue("currencyRate", 1);
                                } else {
                                  if (newValue.unit === "THB") {
                                    setValue("currencyRate", 1);
                                  }
                                  setValue("currencyItem", newValue);
                                  setValue("currency", newValue.unit);
                                  setValue("currencyUnit", newValue.unit);
                                }
                              }}
                              popupIcon={
                                <i class="fa-light fa-chevron-down"></i>
                              }
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <img
                                    loading="lazy"
                                    width="20"
                                    style={{ marginRight: 16, flexShrink: 0 }}
                                    src={`https://flagcdn.com/16x12/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/32x24/${option.code.toLowerCase()}.png 2x`}
                                    alt={option.code}
                                  />
                                  {t(`${option.label}`)}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder=""
                                  name="vatType"
                                />
                              )}
                              PopperComponent={StyledPopper}
                              noOptionsText={t("NoData")}
                            />
                          </>
                        )}
                      />
                      {errors && errors.currency && errors.currency.message && (
                        <FormHelperText error>
                          {errors.currency.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <StyledDivider />
                <div>
                  <div className="wrap-head">
                    <StyledHeadLabel variant="h6">{t("List")}</StyledHeadLabel>
                    <div>
                      <ButtonBlue
                        size="small"
                        startIcon={<AddRoundedIcon />}
                        variant="outlined"
                        onClick={() => {
                          append(dataInitial);
                        }}
                      >
                        {t("AddItem")}
                      </ButtonBlue>
                    </div>
                  </div>

                  <TableContainer>
                    <Table
                      style={{
                        minWidth: 650,
                        borderSpacing: "0 4px",
                        borderCollapse: "separate",
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          {rowHeader.map((item) => (
                            <StyledTableCell className={item.className}>
                              <StyledHeadLabel
                                variant="body2"
                                color="text.third"
                                gutterBottom
                              >
                                {t(item.caption)}
                              </StyledHeadLabel>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fields &&
                          fields.length > 0 &&
                          fields
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((item, index) => (
                              <RowItemExpense
                                key={item.id}
                                count={fields.length}
                                index={index}
                                open={open}
                                handleDeleteRow={remove}
                                calculateSum={calculateSum}
                                control={control}
                                errors={errors}
                              />
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      20,
                      30,
                      40,
                      50,
                      { label: t("All"), value: -1 },
                    ]}
                    component="div"
                    count={fields.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={`${t("RowsPerPage")}`}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} ${t("OutOf")} ${
                        count !== -1 ? count : `${t("MoreThan")} ${to}`
                      }`
                    }
                  />
                </div>
                <StyledDivider />
                <div>
                  <div className="wrap-head">
                    <StyledHeadLabel variant="h6">
                      {t("SumaryItem")}
                    </StyledHeadLabel>
                  </div>
                  <div className="wrap-row-sum">
                    <Typography color="text.third">
                      {t("TotalAdvance")}&nbsp;&nbsp;
                    </Typography>
                    <Typography color="text.third" variant="h6">
                      {utils.numberWithCommas(
                        parseFloat(watch("totalAdvance"))
                      )}
                    </Typography>
                    <Typography color="text.third">
                      &nbsp;&nbsp;{t(`CurrencyUnit.${watch("currency")}`)}
                    </Typography>
                  </div>
                  <div className="wrap-row-sum">
                    <Typography color="text.third">
                      {t("TotaldifferentAmount")}&nbsp;&nbsp;
                    </Typography>
                    <Typography color="text.third" variant="h6">
                      {utils.numberWithCommas(
                        parseFloat(watch("differentAmount"))
                      )}
                    </Typography>
                    <Typography color="text.third">
                      &nbsp;&nbsp;{t(`CurrencyUnit.${watch("currency")}`)}
                    </Typography>
                  </div>
                  <div>
                    <div className="wrap-row-sum">
                      <Typography variant="h5">
                        {t("TotalNetWorth")}&nbsp;&nbsp;
                      </Typography>
                      <Typography variant="h4">
                        {utils.numberWithCommas(parseFloat(watch("net")))}
                      </Typography>
                      <Typography variant="h5">
                        &nbsp;&nbsp;{t(`CurrencyUnit.${watch("currency")}`)}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </>
          )}
        </Grid>

        {watch("idExpenseAdvance") && (
          <>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("AttachFile")}
                </StyledHeadLabel>
              </div>
              {!watch("file") ? (
                <StyledDropzone>
                  <Dropzone
                    accept="image/jpeg, image/png, application/pdf"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setValue("file", acceptedFiles[0]);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({ className: "dropzone-upload-file" })}
                      >
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={GalleryIcon} />
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              {t("imageFileNotMore500KB")}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              ) : (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={() => {
                      setValue("file", null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                    }}
                  >
                    <FilePresentIcon
                      height="24"
                      style={{
                        marginRight: 10,
                      }}
                    ></FilePresentIcon>
                    <Typography>{watch("file.path")}</Typography>
                  </div>
                </Box>
              )}
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">{t("Note")}</StyledHeadLabel>
              </div>
              <Controller
                name={"remark"}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <TextFieldTheme {...field} multiline rows={4} />
                    <Typography
                      component="p"
                      textAlign="right"
                      variant="caption"
                      color="text.secondary"
                    >{`${
                      field.value ? field.value.length : 0
                    }/500`}</Typography>
                  </>
                )}
              />
            </div>
            <StyledDivider />
            <ActionButtons
              handleOnClickCleardata={handleOnClickCleardata}
              handleSubmitDraft={handleSubmit(
                handleOnClickSavedraft,
                checkValidation
              )}
              handleSubmitSent={handleSubmit(
                handleOnClickSent,
                checkValidation
              )}
            />
          </>
        )}
      </form>
    </Box>
  );
};

export default ClearAdvance;
