import React, { Fragment, useEffect, useState, useMemo  } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import {
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Paper,
  styled,
  Typography,
  TextField,
  Box,
  Popper,
} from "@mui/material";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DialogImport from "./dialogImport";
// import TextFieldTheme from "../../shared/general/TextFieldTheme";
// import DialogPersonID from "../../shared/general/dialogPersonID";
import CheckIcon from '@mui/icons-material/Check'; 
//Translator TH-EN
import { useTranslation } from "react-i18next";

import {
    getDowloadEmployeeDocument,
    // getisUploadEmployeeDocument,
} from "../../../../actions/document";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

const RootStyled = styled("div")({
  "& .paper-list": {
    margin: "24px 0px 48px",
    padding: 16,
  },
  "& .filename": {
    fontSize: 16,
  },
  "& .navBox": {
    display: "flex",
    height: 56,
    alignItems: "center",
    "& .downloadButton": {
      width: 96,
      minWidth: 96,
      height: 36,
      marginLeft: 16,
    },
  },
});

const DividerStyled = styled(Divider)({
  margin: "8px 0",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledButtonBlue = styled(ButtonBlue)`
  background-color: ${({ hasFile }) => (hasFile ? '#28a745' : '#5AD1E5')}; /* สีเขียวเมื่อมีไฟล์, สีฟ้าเมื่อไม่มีไฟล์ */
  color: #fff;

  &:hover {
    background-color: ${({ hasFile }) => (hasFile ? '#218838' : '#4FC1E9')}; /* สีเขียวเข้มเมื่อ hover และสีฟ้าเข้มเมื่อ hover */
  }
`;

const UserEmployeeDocument = () => {

  const { result: UserProfile } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [openDialog, setOpenDialog] = useState(true);
  const [openAlert, setOpenAlert] = useState({
    isOpen: false,
    type: null,
    label: null,
  });


  const handleOpenAlertError = (label) => {
    setOpenAlert({ isOpen: true, type: "error", label: label });
  };

  const handleCloseAlert = () => {
    setOpenAlert({ isOpen: false, type: null, label: null });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { result: employeeDocument } = useSelector((state) => state.employeeDocument)
  

  const [employeeDocumentData, setEmployeeDocumentData] = useState(null);

  let filtereduniquedDocumentFileNames;
  let filtereduniquedDocumentGroup; 
  let mergedDocumentData;

  if (Array.isArray(employeeDocumentData)) {
        // && 
        // Array.isArray(companyDocumentData)) {
        mergedDocumentData = [
        ...employeeDocumentData
        // ...documentNames,
        //   ...companyDocumentData.map(item => ({
        //     documentGroupName: item.documentGroupName,
        //     documentTypeName: item.documentTypeName,
        //     fileURL: item.fileURL,
        //     filename: item.documentTypeName,
        //   })),
        ]; 

    const uniqueDocumentFileNames = new Set();
    filtereduniquedDocumentFileNames = mergedDocumentData.filter(item => {
      let cleanGroupName;
      
      if (typeof item.filename === 'object' && item.filename.en) {
        cleanGroupName = item.filename.en.trim().toLowerCase();
      } else if (typeof item.filename === 'string') { 
        cleanGroupName = item.filename.trim().toLowerCase();
      } else {
        return false; 
      }
    
      if (!uniqueDocumentFileNames.has(cleanGroupName)) {
        uniqueDocumentFileNames.add(cleanGroupName);
        return true;
      }
      return false;
    });

    const uniqueDocumentGroupNames = new Set();
    filtereduniquedDocumentGroup = mergedDocumentData.filter(item => {
      const cleanGroupName = item.documentGroupName.trim().toLowerCase();
      if (!uniqueDocumentGroupNames.has(cleanGroupName)) {
        uniqueDocumentGroupNames.add(cleanGroupName);
        return true;
      }
      return false;
    });
  }

  const [selectedDocumentName, setSelectedDocumentName] = useState(null);
  const [selectedDocumentGroup, setSelectedDocumentGroup] = useState(null);
 

  const setdata = () => {  
    if (employeeDocumentData)
      if (employeeDocumentData.length > 0 && selectedDocumentName) {
        let temp = mergedDocumentData.filter((row) => {
          if (selectedDocumentName) {
            if (typeof row.filename === 'object') {
              return (
                row.filename.en === selectedDocumentName ||
                row.filename.th === selectedDocumentName
              )
            } else if (typeof row.filename === 'string') {
                return row.filename === selectedDocumentName;
            }
          } else { 
            return row;
          }
        });
        if (employeeDocumentData.length > 0 && selectedDocumentGroup) {
          const filteredItems = mergedDocumentData.filter(
            (item) => item.documentGroupName === selectedDocumentGroup
          );
          return filteredItems;
        } else {
          return [...temp];
        }
      } else if (mergedDocumentData.length > 0 && selectedDocumentGroup) {
        return [...mergedDocumentData].filter(
          (item) => item.documentGroupName === selectedDocumentGroup
        );
      } else return [...mergedDocumentData];
  };

  useEffect(() => {
    if (
       employeeDocument 
    ) {
      const updatedEmployeeDocumentData = (employeeDocument ? employeeDocument : []).map(doc => ({
        ...doc,
        filename: doc.documentTypeName
      }));

      if (employeeDocument) {
        setEmployeeDocumentData(updatedEmployeeDocumentData);
      }
    }
  }, [
    employeeDocument, 
  ]);

  let res = null;

  useEffect(() => {
    const fetchEmployeeDocument = async () => {
      try {
        res = await dispatch(getDowloadEmployeeDocument(UserProfile.idEmployees, UserProfile.idCompany)); 
      } catch (error) {
        console.error('Error fetching employee document:', error);
      }
    };
    fetchEmployeeDocument();
  }, []);

  
  // const handleDocumentDownload = (fileUrl, fileName) => {
  //     if (fileUrl && fileName) {
  //       const link = document.createElement('a');
  //       link.href = fileUrl;
  //       link.setAttribute('download', fileName);
  //       link.setAttribute('target', '_blank'); 
    
  //       document.body.appendChild(link);
    
  //       link.click();
    
  //       setTimeout(() => {
  //         document.body.removeChild(link);
  //       }, 100);
  //     } else {
  //       handleOpenAlertError(`${t("IncompleteInformation")}`);
  //     }
  // };


//   const handleSmartDocumentDownload = async (smartDocumentDownload) => {
//     if (smartDocumentDownload) {
//       try {
//         // ตรวจสอบว่าข้อมูลที่รับมาคือ Blob หรือ Response ที่สามารถแปลงเป็น ArrayBuffer ได้
//         const arrayBuffer = await (smartDocumentDownload instanceof Response 
//           ? smartDocumentDownload.arrayBuffer() 
//           : smartDocumentDownload);

//         // โหลด ZIP file จาก ArrayBuffer
//         const zip = await JSZip.loadAsync(arrayBuffer);
        
//         // ตรวจสอบและดาวน์โหลดไฟล์ PDF จาก ZIP
//         zip.forEach(async (relativePath, file) => {
//           if (file.name.endsWith('.pdf')) {
//             try {
//               const fileBlob = await file.async('blob');
//               const downloadUrl = URL.createObjectURL(fileBlob);
//               const a = document.createElement('a');
//               a.href = downloadUrl;
//               a.download = file.name;
//               document.body.appendChild(a);
//               a.click();
//               a.remove();
//               URL.revokeObjectURL(downloadUrl);
//             } catch (error) {
//               console.error('Error processing file:', error);
//             }
//           }
//         });
//       } catch (error) {
//         console.error('Error processing ZIP file:', error);
//       }
//     } else {
//       console.error('No document available for download.');
//     }
//   };

  const [open, setOpen] = useState(false);
  const [typeDialog, setTypeDialog] = useState(null); 
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState({});
  

  useEffect(() => {
    if (reload) {
      dispatch(getDowloadEmployeeDocument());
      setReload(false); 
    } else {
      setReload(false);
    }
  }, [reload]);

  const handleDialog = (type, formData) => {
    setTypeDialog(type);
    setOpen(true);
    if (formData) {
      setFormData(formData);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <RootStyled className="page">
      <Container maxWidth="lg">
          <Fragment>
            <Typography variant="h4" style={{ paddingTop: 8, paddingBottom: 8 }}>
              {`${t("EmployeeDocuments")}`}
            </Typography>
            <Grid
            container
            spacing={3}
            columns={12}
            alignItems="center"
            justifyContent="flex-start"
          >
             <Grid item xs={6} sm={6} md={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("searchForDocumentType")}
                </Typography>
                  <div className="search-name">
                    {filtereduniquedDocumentFileNames && (
                      <StyledAutocomplete
                        options={filtereduniquedDocumentFileNames}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedDocumentName(
                              typeof newValue.filename === 'object'
                                ? (selectedDocumentName === newValue.filename.en ?
                                   newValue.filename.en : newValue.filename.th)
                                : newValue.filename
                            );
                            setSelectedDocumentGroup(null);
                          } else {
                            setSelectedDocumentName(null);
                          }
                        }}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => {
                          if (typeof option.filename === 'object') {
                            return `${option.filename.en} / ${option.filename.th}`;
                          } else if (typeof option.filename === 'string') {
                            return option.filename;
                          } else {
                            return ''; 
                          }
                        }}                        
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={`${t("SelectDocumentType")}`}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={`${t("NoData")}`}
                        value={
                          selectedDocumentName
                            ? filtereduniquedDocumentFileNames.find(
                                (item) => {
                                  if (typeof item.filename === 'object') {
                                    return (
                                      (item.filename.en === selectedDocumentName ||
                                        item.filename.th === selectedDocumentName)
                                    );
                                  } else if (typeof item.filename === 'string') {
                                    return item.filename === selectedDocumentName;
                                  } else {
                                    return false; 
                                  }
                                }
                              )
                            : null
                        }
                        style={{ margin: '8px 0' }} 
                      />
                    )}
                  </div>
              </StyledBoxSearch>
            </Grid>
             <Grid item xs={6} sm={6} md={3}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("SearchCategory")}
                    </Typography>
                    <div className="search-name">
                      {filtereduniquedDocumentGroup && (
                        <StyledAutocomplete
                          options={filtereduniquedDocumentGroup}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSelectedDocumentGroup(newValue.documentGroupName);
                              setSelectedDocumentName(null);
                            } else {
                              setSelectedDocumentGroup(null);
                            }
                          }}
                          popupIcon={<i className="fa-light fa-chevron-down"></i>}
                          getOptionLabel={(option) => option.documentGroupName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder={`${t("selectDocumentCategory")}`}
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={`${t("NoData")}`}
                          value={
                            selectedDocumentGroup
                              ? filtereduniquedDocumentGroup.find(
                                  (group) => group.documentGroupName === selectedDocumentGroup
                                )
                              : null
                          }
                          style={{ margin: '8px 0' }} 
                        />
                      )}
                    </div>
                  </StyledBoxSearch>
             </Grid>
            </Grid>
                <Paper className="paper-list">
                  <List>
                     {mergedDocumentData ?
                        setdata()
                        // .filter(row => {
                        //   return !documentNames.some(doc => doc.filename === row.filename);
                        // }) 
                        .map((row, index) => (
                         <>
                           {/* <ListItem key={index}>
                              <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                  <Typography className="filename">
                                    {row.filename}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <div className="navBox">
                                    <ButtonBlue
                                        variant="contained"
                                        className="downloadButton"
                                        onClick={() => handleDocumentDownload(row.fileURL, row.filename)} 
                                    >
                                        {`${t("Download")}`}
                                  </ButtonBlue>  
                                  </div>
                                </Grid>
                              </Grid>
                          </ListItem> */}

                          <ListItem key={index}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                  <Grid item>
                                    <Typography className="filename">
                                      {row.filename ? row.filename : t("No file uploaded")}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                      <label htmlFor={`upload-file-${index}`}>
                                      {row.fileURL ? (
                                        <ButtonBlue
                                          sx={{
                                            backgroundColor: '#4169E1', 
                                            color: '#fff',
                                            '&:hover': {
                                              backgroundColor: '#1E90FF', 
                                            },
                                          }}
                                          variant="contained"
                                          component="span" 
                                          className="uploadButton"
                                          onClick={() => {
                                            const updatedRow = {
                                              ...row,
                                              idEmployees: UserProfile.idEmployees,
                                              idCompany:  UserProfile.idCompany,
                                            };
                                            handleDialog("edit", updatedRow);
                                          }}                                      
                                           >
                                          {t("UploadDoc")}                                 
                                          <CheckIcon sx={{ marginLeft: 1 }} />
                                          </ButtonBlue>
                                      ) : (
                                        <ButtonBlue
                                        variant="contained"
                                        component="span" 
                                        className="uploadButton"
                                        style={{
                                          paddingRight: i18n.language === 'th' ? '21px' : '28px', 
                                          paddingLeft: i18n.language === 'th' ? '21px' : '28px'  
                                        }}
                                        onClick={() => {
                                          const updatedRow = {
                                            ...row,
                                            idEmployees: UserProfile.idEmployees,
                                            idCompany:  UserProfile.idCompany,
                                          };
                                          handleDialog("import", updatedRow);
                                        }}                                      
                                         >
                                          {`${t("UploadFile")}`}
                                        </ButtonBlue>
                                      )}
                                      </label>  
                                  </Grid>
                                </Grid>
                           </ListItem>
                          <DividerStyled />
                        </>
                      )) : `${t("NoData")}`}     
                  </List>
                </Paper>
          </Fragment>
      </Container>   
      { (typeDialog === 'import' || typeDialog === 'edit') && (
        <DialogImport
         open={open}
         reload={reload}
         setReload={setReload}
         doc={formData}
         type={typeDialog}
         handleClose={handleClose}
        />
      )}
      {openAlert.isOpen && (
        <AlertResponse
          open={openAlert.isOpen}
          alertType={openAlert.type}
          label={openAlert.label}
          handleClose={handleCloseAlert}
        />
      )}
    </RootStyled>
  );
};

export default UserEmployeeDocument;
