import {
  EMEMO_FORM_SUBMIT_SUCCESS,
  EMEMO_FORM_SUBMIT_FETCHING,
  EMEMO_FORM_SUBMIT_FAILED,
  EMEMO_ALL_FETCHING,
  EMEMO_ALL_SUCCESS,
  EMEMO_ALL_FAILED,
  EMEMO_UPDATE_STATUS_FETCHING,
  EMEMO_UPDATE_STATUS_SUCCESS,
  EMEMO_UPDATE_STATUS_FAILED,
} from "./types";

import ememoService from "../services/ememo.service";

export const addEmemoRequestForm = (formData) => async () => {
  try {
    const res = await ememoService.addEmemoRequestForm(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const getEmemoRequestFormById = () => async (dispatch) => {
  try {
    dispatch({
      type: EMEMO_ALL_FETCHING,
    });
    const res = await ememoService.getEmemoRequestFormById();
    if (res) {
      dispatch({
        type: EMEMO_ALL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EMEMO_ALL_FAILED,
    });
    return error;
  }
};

export const patchEmemoStatus =
  (id, status, comment, nextStep) => async (dispatch) => {
    try {
      dispatch({
        type: EMEMO_UPDATE_STATUS_FETCHING,
      });
      const res = await ememoService.patchEmemoStatus(
        id,
        status,
        comment,
        nextStep
      );
      if (res) {
        dispatch({
          type: EMEMO_UPDATE_STATUS_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (error) {
      dispatch({
        type: EMEMO_UPDATE_STATUS_FAILED,
      });
      return error;
    }
  };

export const getEmemoRequestStepInitial = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EMEMO_ALL_FETCHING,
    });
    const res = await ememoService.getEmemoRequestStepInitial(id);
    if (res) {
      dispatch({
        type: EMEMO_ALL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EMEMO_ALL_FAILED,
    });
    return error;
  }
};
