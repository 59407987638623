import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Stack,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Chip,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAnnouncement,
  getAnnouncementByIdCompany,
} from "../../../../actions/announcement";
import CreateAnnouncement from "./createAnnouncement";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
});

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    sx={{
      backgroundColor: props.isActive ? "#b2dfdb" : "#ff7961",
    }}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(3),
  },
}));

const AnnountAdmin = (props) => {
  const { t, i18n } = useTranslation();
  const { updateAt, isActive, handleSelect, ...data } = props;
  const { idAnnouncement, title, detail, file, startDate, endDate } = data;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleOpenDelete = () => {
    setOpen(true);
  };

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const handleDelete = async (idAnnouncement) => {
    const res = await dispatch(deleteAnnouncement(idAnnouncement));
    if (res) {
      handleCloseDelete();
      dispatch(getAnnouncementByIdCompany());
    }
  };

  return (
    <>
      <Accordion disableGutters>
        <StyledAccordionSummary isActive={isActive}>
          <Typography variant="h6">{title}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            marginBottom={1}
          >
            <Typography variant="subtitle1" color="text.secondary">
              {`${t("AnnouncementDate")}: ${dayjs(startDate).format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )} - ${dayjs(endDate).format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )}`}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {`${t("LastModified")}: ${dayjs(updateAt).format(
                i18n.resolvedLanguage === "th"
                  ? "DD/MM/BBBB HH:mm"
                  : "DD/MM/YYYY HH:mm"
              )}`}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Chip
              icon={<DescriptionIcon />}
              label={`${t("Descriptions")}`}
              style={{ marginRight: "auto" }}
            />
            <IconButton
              onClick={() => handleSelect(data)}
              style={{ color: "#ffeb3b", border: "1px solid #ffeb3b" }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              style={{ color: "#d32f2f", border: "1px solid #d32f2f" }}
              onClick={handleOpenDelete}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
          <Typography padding={1}>{detail}</Typography>
          {file && (
            <Button
              variant="outlined"
              component={Link}
              href={file}
              target="_blank"
              startIcon={<AttachFileIcon />}
              sx={{ justifyContent: "flex-end" }}
            >
              {`${t("AttachedAnnouncementFile")}`}
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
      {open && (
        <Dialog open={open}>
          <DialogTitle>{`${t("ConfirmDelete")}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>{title}</Typography>
              {`${t("ItemWillBePermanentlyDeleted")}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(idAnnouncement)}
            >
              {`${t("Confirm")}`}
            </Button>
            <Button onClick={handleCloseDelete}>ยกเลิก</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default function Announcement({ selectedCompany, selectedYear }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: announcement } = useSelector((state) => state.announcement);

  const [selectAnnouncement, setSelectAnnouncement] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectAnnouncement(null);
  };

  const handleSelect = (value) => {
    setSelectAnnouncement(value);
    setOpen(true);
  };

  const announcementActive = useMemo(() => {
    if (announcement) {
      return announcement.filter((item) =>
        dayjs().isBetween(item.startDate, item.endDate, "day", "[]")
      );
    }
  }, [announcement]);

  const announcementTotal = useMemo(() => {
    if (announcement) {
      return announcement.filter((item) => 
        !dayjs().isBetween(item.startDate, item.endDate, "day", "[]")
      );
    }
  }, [announcement]);

  useEffect(() => {
    if (selectedCompany && selectedYear) {
      dispatch(
        getAnnouncementByIdCompany({
          idCompany: selectedCompany.idCompany,
          year: selectedYear,
        })
      );
    }
  }, [selectedCompany, selectedYear]);

  return (
    <>
      <WrapHeader>
        <Typography variant="h4">{`${t("Announcement")}`}</Typography>
        {userProfile && userProfile.roles.includes("ROLE_ADMIN") && (
          <ButtonBlue variant="contained" color="primary" onClick={handleClickOpen}>
            {t("Create")}
          </ButtonBlue>
        )}
      </WrapHeader>
      {open && (
        <CreateAnnouncement
          open={open}
          handleClose={handleClose}
          editData={selectAnnouncement}
          selectedCompany={selectedCompany}
          selectedYear={selectedYear}
        />
      )}
      {announcementActive && announcementActive.length > 0 && (
        <Stack marginTop={4}>
          <Typography marginBottom={2}>{`${t("InProgress")}`}</Typography>
          {announcementActive.map((item) => (
            <AnnountAdmin
              key={item.idPolicy}
              handleSelect={handleSelect}
              isActive={true}
              {...item}
            />
          ))}
        </Stack>
      )}
      {announcementTotal && announcementTotal.length > 0 && (
        <Stack marginTop={4} marginBottom={4}>
          <Typography marginBottom={2}>{`${t("All")}`}</Typography>
          {announcementTotal.map((item) => (
            <AnnountAdmin
              key={item.idAnnouncement}
              handleSelect={handleSelect}
              {...item}
              isActive={false}
            />
          ))}
        </Stack>
      )}
      {announcement && announcement.length === 0 && (
        <Typography variant="h6" color={"text.secondary"}>{t("NoData")}</Typography>
      )}
    </>
  );
}
