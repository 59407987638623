import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProbationEmployee } from "../../../../actions/probation";
import dayjs from "dayjs";
import Loading from "../../shared/loading";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import ProbationForm from "./probationForm";
import { useTranslation } from "react-i18next";
import CardStyle from "../../shared/general/Card";

const Probation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: probation, isFetching } = useSelector(
    (state) => state.probationQuestion
  );

  const [open, setOpen] = useState(false);
  const [selectProbation, setSelectProbation] = useState(null);

  const handleOpen = (value) => {
    setOpen(true);
    setSelectProbation(value);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectProbation(null);
  };

  useEffect(() => {
    dispatch(getProbationEmployee());
  }, []);

  if (isFetching) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      {probation && probation.length > 0 ? (
        probation.map((probation) => (
          <CardStyle style={{ marginBottom: "16px" }}>
            <CardContent>
              <Typography>
                {`${t("ProbationRound")}: ${probation.probationRound}`}
              </Typography>
              <Typography>
                {`${t("ProbationaryExpirationDate")}: ${dayjs(probation.probationDate).format("DD-MM-YYYY")}`}
              </Typography>
              <Typography>
                {`${t("questionSet")}: ${probation.questionSet.questionSetName}`}
              </Typography>
              <ButtonBlue
                style={{ marginTop: "16px" }}
                variant="contained"
                onClick={() => handleOpen(probation)}
              >
                {t("Evaluate")}
              </ButtonBlue>
            </CardContent>
          </CardStyle>
        ))
      ) : (
        <Typography variant="h6">{t("NoData")}</Typography>
      )}
      {open && (
        <ProbationForm
          open={open}
          handleClose={handleClose}
          questionSet={selectProbation.questionSet}
          idProbation={selectProbation.idProbation}
        />
      )}
    </div>
  );
};

export default Probation;
