import React from "react";
import { Controller } from "react-hook-form";

import { styled } from "@mui/material/styles";
import {
  TableRow,
  TableCell,
  TextField,
  IconButton,
  FormControl,
  FormHelperText,
} from "@mui/material";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

const StyledTableRow = styled(TableRow)({
  marginBottom: 2,
});

const StyledTableCell = styled(TableCell)({
  padding: 0,
  border: "none",
  "& .wrap": {
    height: 72,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: "1px solid #e1e1e1",
    borderLeft: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRight: "1px solid #e1e1e1",
    },
  },
  "&.date-document": {
    width: 130,
    "& .MuiInputBase-root": {
      paddingRight: 8,
    },
  },
  "&.description": {
    width: 450,
  },

  "&.amount": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledTextArea = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  "& .MuiInputBase-root": {
    borderRadius: 0,
    padding: "0px !important",
    "& textarea": {
      padding: "13.5px !important",
      overflow: "auto !important",
      height: "auto !important",
    },
    "& fieldset": {
      border: "none",
    },
  },
});

const RowItemExpense = (props) => {
  const {
    index,
    handleDeleteRow,
    count,
    open,
    calculateSum,
    control,
    errors,
    addDeleteRow,
    type,
  } = props;

  return (
    <StyledTableRow>
      <StyledTableCell className=""></StyledTableCell>
      <StyledTableCell className="cell-table description">
        <div className="wrap first">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].description
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.description`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea {...field} />
                </>
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].description &&
              errors.listExpense[index].description.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].description.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table amount">
        <div className="wrap last">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].amount
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.amount`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      value: field.value,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                        calculateSum();
                      },
                    }}
                    onChange={() => {}}
                  />
                </>
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].amount &&
              errors.listExpense[index].amount.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].amount.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="delete" align="center">
        {count > 1 && (
          <span>
            {type === "edit" ? (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  addDeleteRow(index);
                  handleDeleteRow(index);
                }}
              >
                <DeleteOutlineRoundedIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  handleDeleteRow(index);
                }}
              >
                <DeleteOutlineRoundedIcon fontSize="small" />
              </IconButton>
            )}
          </span>
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default RowItemExpense;
