import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Box,
  Avatar,
  Collapse,
  IconButton,
  Autocomplete,
  TextField,
  Grid,
  Button,
  MenuItem,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/employee";
import {
  getOnBoardingEmployee,
  getOnBoardingEmployeeByID,
} from "../../../../../actions/onBoarding";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserDepartment,
  getUserFullName,
  getUserPosition,
  getUserDivision,
  getUserSection,
} from "../../../../../utils/userData";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import dayjs from "dayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { exportAllToExcel } from "./ExportExcel";
import { getAffiliateOrganization } from "../../../../../actions/company";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StatusProgress = ({ onBoardingList }) => {
  const approveList = onBoardingList.filter(item => item.isApprove)
  const percentComplete = onBoardingList.length > 0
    ? approveList.length / onBoardingList.length * 100 
    : 0

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={percentComplete}
        size={50}
        thickness={4}
        sx={{ color: "#46cbe2" }}
      />
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(percentComplete)}%`}</Typography>
      </Box>
    </Box>
  );
};

const StatusOnBoarding = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: onBoardingEmployee } = useSelector(
    (state) => state.onBoardingEmployee
  );
  const { result: onBoardingEmployeeDetails } = useSelector(
    (state) => state.onBoardingEmployeeByID
  );
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  
  const [expandedRow, setExpandedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    fullName: "",
    division: null,
    department: null,
    section: null,
    position: null,
    company: null,
  });
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  useEffect(() => {
    if (filters.company) {
      dispatch(getOnBoardingEmployee({ idCompany: filters.company.idCompany }));
    }
  }, [dispatch, filters.company]);
  
  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAffiliateOrganization());
  }, [dispatch]);

  useEffect(() => {
    if (onBoardingEmployee) {
      const filtered = onBoardingEmployee.filter((row) => {
        return (
          (!filters.fullName ||
            getUserFullName(row).includes(filters.fullName)) &&
          (!filters.division || filters.division.idDivision === row.idDivision) &&
          (!filters.department || filters.department.idDepartment === row.idDepartment) &&
          (!filters.section || filters.section.idSection === row.idSection) &&
          (!filters.position || filters.position.idPosition === row.idPosition)
        );
      });
      setFilteredData(filtered);
    }
  }, [filters, onBoardingEmployee]);

  const handleFilterChange = (filterName, value) => {
    if (filterName === "company") {
      setFilters({
        fullName: "",
        division: null,
        department: null,
        section: null,
        position: null,
        company: value,
      });
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: value,
      }));
    }
  };

  const clearFilters = () => {
    setFilters({
      fullName: "",
      division: null,
      department: null,
      section: null,
      position: null,
      company: filters.company,
    });
  };

  const handleExpandClick = (id) => {
    if (expandedRow === id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(id);
      dispatch(getOnBoardingEmployeeByID(id));
    }
  };

  const handleDownload = () => {
    exportAllToExcel(filteredData);
  };

  useEffect(() => {
    if(affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      handleFilterChange("company", affiliateOrganizationList[0])
    }
  }, [affiliateOrganizationList]);

  return (
    <div>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SelectAffiliateCompany
              options={affiliateOrganizationList}
              value={filters.company}
              onChange={(event, newValue) => {
                handleFilterChange("company", newValue)}
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("FullName")}
              variant="outlined"
              fullWidth
              value={filters.fullName}
              onChange={(e) => handleFilterChange("fullName", e.target.value)}
            />
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-start">
            <Button
              variant="text"
              color="primary"
              onClick={() => setShowMoreFilters(!showMoreFilters)}
            >
              {showMoreFilters ? t("HideSearch") : t("SearchMore")}
            </Button>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <ButtonBlue
              //style={{ padding: "10px 30px" }}
              variant="outlined"
              onClick={handleDownload}
            >
              {t("Download")}
            </ButtonBlue>
          </Grid>

          {showMoreFilters && (
            <>
              <Grid item xs={3}>
                <Autocomplete
                  options={filters.company ? filters.company.organization.filter((value, index , self) => {
                    return value.idDivision && index === self.findIndex((t) => (
                      t.idDivision === value.idDivision
                    ))
                  }) : []}
                  getOptionLabel={(option) => `${i18n.resolvedLanguage === "th"
                    ? option.divisionName
                    : option.divisionName_EN}`}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.idDivision}>
                      {i18n.resolvedLanguage === "th"
                        ? option.divisionName
                        : option.divisionName_EN}
                    </MenuItem>
                  )}
                  value={filters.division}
                  onChange={(event, newValue) =>
                    handleFilterChange("division", newValue)
                  }
                  isOptionEqualToValue={(option, value) => option.idDivision === value.idDivision}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Division")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={filters.company ? filters.company.organization.filter((value, index , self) => {
                    return value.idDepartment && index === self.findIndex((t) => (
                      t.idDepartment === value.idDepartment
                    ))
                  }) : []}
                  getOptionLabel={(option) => `${i18n.resolvedLanguage === "th"
                    ? option.departmentName
                    : option.departmentName_EN}`}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.idDepartment}>
                      {i18n.resolvedLanguage === "th"
                        ? option.departmentName
                        : option.departmentName_EN}
                    </MenuItem>
                  )}
                  value={filters.department}
                  onChange={(event, newValue) =>
                    handleFilterChange("department", newValue)
                  }
                  isOptionEqualToValue={(option, value) => option.idDepartment === value.idDepartment}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Department")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={filters.company ? filters.company.organization.filter((value, index , self) => {
                    return value.idSection && index === self.findIndex((t) => (
                      t.idSection === value.idSection
                    ))
                  }) : []}
                  getOptionLabel={(option) => `${i18n.resolvedLanguage === "th"
                    ? option.sectionName
                    : option.sectionName_EN}`}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.idSection}>
                      {i18n.resolvedLanguage === "th"
                        ? option.sectionName
                        : option.sectionName_EN}
                    </MenuItem>
                  )}
                  value={filters.section}
                  onChange={(event, newValue) =>
                    handleFilterChange("section", newValue)
                  }
                  isOptionEqualToValue={(option, value) => option.idSection === value.idSection}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Section")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={filters.company ? filters.company.organization.filter((value, index , self) => {
                    return value.idPosition && index === self.findIndex((t) => (
                      t.idPosition === value.idPosition
                    ))
                  }) : []}
                  getOptionLabel={(option) => `${i18n.resolvedLanguage === "th"
                    ? option.positionName
                    : option.positionName_EN}`}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.idPosition}>
                      {i18n.resolvedLanguage === "th"
                        ? option.positionName
                        : option.positionName_EN}
                    </MenuItem>
                  )}
                  value={filters.position}
                  onChange={(event, newValue) =>
                    handleFilterChange("position", newValue)
                  }
                  isOptionEqualToValue={(option, value) => option.idPosition === value.idPosition}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Position")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-start">
                <Button variant="text" color="primary" onClick={clearFilters}>
                  {t("ClearFilters")}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <StyledWrapBranch>
        {filteredData && filteredData.length > 0 ? (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("FullName")}</TableCell>
                    <TableCell align="center">{t("Division")}</TableCell>
                    <TableCell align="center">{t("Department")}</TableCell>
                    <TableCell align="center">{t("Section")}</TableCell>
                    <TableCell align="center">{t("Position")}</TableCell>
                    <TableCell align="center">{t("Status")}</TableCell>
                    <TableCell align="center">{t("Descriptions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow key={index}>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap={"8px"}
                          >
                            <Avatar
                              src={row.imageProfile}
                              alt={getUserFullName(row)}
                              sx={{ width: 40, height: 40 }}
                            />
                            <Typography variant="body1">
                              {getUserFullName(row)}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {getUserDivision(row) || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {getUserDepartment(row) || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {getUserSection(row) || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {getUserPosition(row) || "-"}
                        </TableCell>
                        <TableCell align="center">
                          <StatusProgress onBoardingList={row.onBoardingList} />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleExpandClick(row.idEmployees)}
                          >
                            {expandedRow === row.idEmployees ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <TableCell
                          colSpan={8}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Collapse
                            in={expandedRow === row.idEmployees}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Table
                              size="small"
                              aria-label="details"
                              sx={{ width: "100%", backgroundColor: "#f9f9f9" }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">
                                    {t("ItemName")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("Inspector")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("Department")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("ProceedInternally")} ({t("Unit.Days")})
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("Status")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("Date")}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {onBoardingEmployeeDetails &&
                                  onBoardingEmployeeDetails.map(
                                    (detail, detailIndex) => (
                                      <TableRow key={detailIndex}>
                                        <TableCell
                                          align="center"
                                          justifyContent="flex-start"
                                        >
                                          {detail.OnBoardingListName}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={"8px"}
                                            width={"100%"}
                                          >
                                            <Avatar
                                              src={detail.reviewer_imageProfile}
                                              alt={`${detail.reviewer_firstname_TH} ${detail.reviewer_lastname_TH}`}
                                              sx={{ width: 32, height: 32 }}
                                            />
                                            <Typography variant="body1">
                                              {detail.reviewer_firstname_TH && detail.reviewer_lastname_TH
                                                ? i18n.resolvedLanguage === "th"
                                                  ? `${detail.reviewer_firstname_TH} ${detail.reviewer_lastname_TH}`
                                                  : `${detail.reviewer_firstname_EN} ${detail.reviewer_lastname_EN}`
                                                : t("NoInspector")}
                                            </Typography>
                                          </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                          {i18n.resolvedLanguage === "th"
                                            ? detail.reviewer_departmentName
                                            : detail.reviewer_departmentName_EN}
                                        </TableCell>
                                        <TableCell align="center">
                                          {detail.day}
                                        </TableCell>
                                        <TableCell align="center">
                                          {detail.isApprove ? (
                                            <CheckCircleOutlineIcon color="success" />
                                          ) : (
                                            <CancelOutlinedIcon color="error" />
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          {detail.updateDate ? dayjs(detail.updateDate).format(
                                            "DD/MM/YYYY"
                                          ) : null}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          `${t("NoItems")}`
        )}
      </StyledWrapBranch>
    </div>
  );
};

export default StatusOnBoarding;
