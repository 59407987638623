import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { Typography, Alert, MenuItem, Stack } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useHistory } from "react-router-dom";
import { getAllCostcenterListByDate, getAllCostcenterListByMonth } from "../../../../actions/payruns-export";
import { useDispatch, useSelector } from "react-redux";
import {
  getAffiliateOrganization,
  getCompanyOrganizationList,
} from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useTranslation } from "react-i18next";
import { CostcenterListMonthXlsx } from "./xlsx-export/payroll-costcenter-xlsx";
import { CostCenterCompanyYearXlsx } from "./xlsx-export/costcenter-company-yearly-xlsx";
import DatePickerCustom from "../../shared/date/datePicker";
import dayjs from "dayjs";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const CostCenterCompanyYear = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose } = props;
  const { control } = useForm({});
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [noData, setNoData] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [costcenterList, setCostcenterList] = useState(null);
  const [searchDate, setSearchDate] = useState({
    start: null,
    end: null
  });

  const handleOpenAlertError = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setSearchDate(null);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const fetchedData = async () => {
    try {
      setIsFetching(true);
      const response = await getAllCostcenterListByDate({
        idCompany: selectedCompany && selectedCompany.idCompany,
        startDate: dayjs(searchDate.start).format("YYYY-MM"),
        endDate: dayjs(searchDate.end).format("YYYY-MM")
        // selectedYear - 543,
        // selectedMonth
        }
      );
      if (response && response.data && response.data.length > 0) {
        setNoData(false);
        setIsFetching(false);
        setCostcenterList(response.data);
      } else {
        setNoData(true);
        setIsFetching(false);
        handleOpenAlertError();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedCompany && searchDate.start && searchDate.end) {
      fetchedData();
    } else {
      setIsFetching(false);
    }
  }, [searchDate, selectedCompany]);

  const handleFileExport = () => {
    if (costcenterList && costcenterList.length > 0) {
        const currentLanguage = i18n.language;
        const companyName = currentLanguage == "th"? selectedCompany.companyName: selectedCompany.companyName_EN
      CostCenterCompanyYearXlsx(t, costcenterList,companyName);
    } else {
      handleOpenAlertError();
    }
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getCompanyOrganizationList({
          idCompany: selectedCompany.idCompany,
          getSection: true,
        })
      );
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  return (
    <DrawerCustom
      title={t("CostCenterCompanyYearly")}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot style={{ width: 400 }}>
        <Stack spacing={4}>
          {openAlert && (
            <Alert severity="error" onClose={() => handleCloseAlert()}>
              {t("NoData")}
            </Alert>
          )}
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography
                color="text.third"
                fontSize="14px"
                fontWeight="600"
                marginBottom="8px"
              >
                {t("Company")}
              </Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                disabled={isFetching || openAlert === true}
                onChange={(_, value) => {
                  onChangeCompany(value);
                }}
              />
            </Stack>
            <Stack spacing={1}>
            <Typography>{t("StartDate")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="start"
                  control={control}
                  render={() => (
                    <DatePickerCustom
                    inputFormat="DD MMMM YYYY"
                      value={searchDate.start}
                      openTo="month"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        if (dayjs(searchDate.end).diff(dayjs(newValue), 'day') > 366) {
                          setSearchDate(prev => ({
                            ...prev,
                            start: newValue,
                            end: dayjs(newValue).add(366, 'day'),
                          }));
                        } else {
                          setSearchDate(prev => ({
                            ...prev,
                            start: newValue
                          }));
                        }
                      }}
                    />
                  )
                }
                />
              </Stack>
            </Stack>
            <Stack spacing={1}>
            <Typography>{t("EndDate")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="end"
                  control={control}
                  render={() => (
                    <DatePickerCustom
                    minDate={searchDate.start}
                      maxDate={dayjs(searchDate.start).add(366, 'day')}
                      inputFormat="DD MMMM YYYY"
                      value={searchDate.end}
                      openTo="month"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearchDate(prev => ({
                          ...prev,
                          end: newValue
                        }));
                      }}
                    />
                    
                  )
                }
                />
              </Stack>
            </Stack>
            
          </Stack>

          <ButtonBlue
            variant="contained"
            disabled={isFetching || noData}
            onClick={() => handleFileExport()}
          >
            {t("Download")}
          </ButtonBlue>
        </Stack>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default CostCenterCompanyYear;
