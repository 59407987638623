import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Box,
  Container,
  Typography,
  Divider,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import Dropzone, { useDropzone } from "react-dropzone";
import AddPhotoIcon from "../../../../components/pages/assets/add.png";
import PaperClip from "../../../../components/pages/assets/paper-clip.png";
import ButtonBlue from "../../shared/general/ButtonBlue";
import BackupIcon from '@mui/icons-material/Backup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DatePickerCustom from "../../shared/date/datePicker";
import Loading from "../../shared/loading";
import Swal from 'sweetalert2';

import {
  addDocumentZip,
  getAllDocumentTypesByCompanyId,
} from "../../../../actions/document";
import { Link } from "react-router-dom";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const addDocument = () => {
  const { idCompany } = useParams();
  const dispatch = useDispatch();
  const { result: alldocumentName } = useSelector((state) => state.alldocumentItem);
  const [value, setValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(true);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const InstructionsModal = () => {
    return (
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: 'fit-content',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom>
            {t('Instructions')}
          </Typography>
          <Divider />
          <Typography variant="body1" sx={{ mb: 1, pt: 1 }}>
            - {t('documentSuggestion.fileNameIDCard')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            - {t('documentSuggestion.pdfOnlyInZip')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            - {t('documentSuggestion.oneFileZip')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            - {t('documentSuggestion.limit50Files')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            - {t('documentSuggestion.maxSize10MB')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button variant="contained" color="primary" onClick={handleClose}>
              {t('Close')}
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  }

  const handleOpenLoading = () => setLoading(true);
  const handleCloseLoading = () => setLoading(false);

  const LoadingModal = () => {
    return (
      <Modal open={loading} >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '16px'
          }}
        >
          <Typography
            variant="h6"
            style={{
              marginTop: '16px',
              color: '#3f51b5',
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
              fontWeight: 'bold',
              userSelect: 'none',
            }}
          >
            {t("UploadingDocCompanyFiles")}
          </Typography>
          <Loading />
        </Box>
      </Modal>
    )
  }

  useEffect(() => {
    if (idCompany) {
      dispatch(getAllDocumentTypesByCompanyId(idCompany));
    }
  }, [idCompany]);

  const {
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const ActivateDocumentName = alldocumentName ? alldocumentName.filter(doc => doc.isActivate === 1) : [];

  const validFileExtensions = { file: ["zip"] };

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
  });

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const { t, i18n } = useTranslation();

  const handleDrop = (acceptedFiles) => {
    const newFileUrls = acceptedFiles.map(file => URL.createObjectURL(file));
    setFormData(prevState => ({
      ...prevState,
      files: acceptedFiles[0],
      fileUrls: newFileUrls[0],
    }));
  };

  const handleDeleteFile = () => {
    setFormData({
      files: null,
      fileUrls: null,
    });
  };


  const truncateFileName = (fileName, number = 5) => {
    if (fileName === null) return ''
    if (fileName.length > number) {
      return `${fileName.substring(0, number)}...`;
    }
    return fileName;
  };

  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    files: null,
    fileUrls: null,
    detail: null,
  });

  const handleChange = (event) => {
    const newValue = event.target.value === 'true';
    setValue(newValue);

    if (!newValue) {
      setFormData({
        ...formData,
        startDate: null,
        endDate: null
      });
    }
  };

  const handleOnSubmit = async () => {
    if (selectedDocumentType.idDocumentType === '' || !formData.files) {
      Swal.fire({
        title: 'Warning!',
        text: `${t("ThisFieldIsRequired")}`,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    } else {
      const data = {
        startDate: formData.startDate !== undefined ? formData.startDate : null,
        endDate: formData.endDate !== undefined ? formData.endDate : null,
        expiration: value ? 1 : 0,
        idDocumentType: selectedDocumentType.idDocumentType,
        idCompany: idCompany,
        documentTypeName: selectedDocumentType.documentTypeName,
        createDate: dayjs(new Date()).format("YYYY-MM-DD"),
        updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
        file: formData.files,
        fileUrls: formData.fileUrls,
      };

      const postData = new FormData();

      Object.keys(data).forEach(key => {
        postData.append(key, data[key]);
      });

      handleOpenLoading()
      try {
        const result = await dispatch(addDocumentZip(postData));
        if (result) {
          Swal.fire({
            title: 'Success!',
            text: `${t("SuccessfullyUploaded")}`,
            icon: 'success',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'An error occurred uploading.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        console.error("Error adding document type:", error);
        Swal.fire({
          title: 'Error!',
          text: "An error occurred uploading.",
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        handleCloseLoading()
      }
    }
  };

  const [selectedDocumentType, setSelectedDocumentType] = useState({
    idDocumentType: '',
    documentTypeName: ''
  });

  const handleChangeDocument = (event) => {
    const selectedId = event.target.value;
    const selectedDocument = (ActivateDocumentName || []).find(doc => doc.idDocumentType === selectedId);
    if (selectedDocument) {
      setSelectedDocumentType({
        idDocumentType: selectedDocument.idDocumentType,
        documentTypeName: selectedDocument.documentTypeName
      });
    } else {
      setSelectedDocumentType({
        idDocumentType: '',
        documentTypeName: ''
      });
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{ userSelect: 'none' }}>
        <Box
          className="wrap-search-action"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 0, pb: 2 }}
        >
          <Typography variant="h4" sx={{ pt: 1, pb: 2 }}>
            {t("AddDocumentFile")}
          </Typography>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 3 }}
        >
          <Grid item xs={12} className="GridTopicInput">
            <Typography
              className="label"
              sx={{ color: "#424242" }}
            >
              {`${t("DocumentName")}`}
            </Typography>
          </Grid>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 1, pb: 2 }}
        >
          <FormControl sx={{ width: '50%', maxWidth: '50%' }}>
            <Select
              name="documentTypeName"
              value={selectedDocumentType.idDocumentType}
              onChange={handleChangeDocument}
              required
            >
              {(ActivateDocumentName || []).map((row,index) => (
                <MenuItem
                  key={index}
                  value={row.idDocumentType}
                >
                  {row.documentTypeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {formData.fileUrls != null && (
          <>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ pt: 4 }}
            >
              <Typography sx={{ mr: 8 }}>{t("ExpirationDate")}</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={`${t("Yes")}`}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={`${t("No")}`}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              justifyContent="flex-center"
              alignItems="center"
              sx={{ pt: 4 }}
            >
              {value ? (
                <>
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6} className="GridTopicInput">
                      {t("Start")}
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center', width: '50%' }}>
                      <DatePickerCustom
                        value={formData.startDate}
                        inputFormat="DD/MM/YYYY"
                        name="startDate"
                        onChange={(newValue) => {
                          setFormData({
                            ...formData,
                            ["startDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth helperText={null} />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ paddingTop: 2 }}>
                    <Grid item xs={12} sm={6} className="GridTopicInput">
                      {t("End")}
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center', width: '50%' }}>
                      <DatePickerCustom
                        value={formData.endDate}
                        inputFormat="DD/MM/YYYY"
                        name="endDate"
                        onChange={(newValue) => {
                          setFormData({
                            ...formData,
                            ["endDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth helperText={null} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Box>
          </>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 2 }}
        >
          <Grid item xs={12} >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ pt: 1, pb: 1 }}
            >
              {selectedDocumentType && (
                <div>
                  <Typography
                    className="label"
                    sx={{ color: "#424242" }}
                  >
                    {`${t("AttachFile")} ${selectedDocumentType.documentTypeName}`}
                  </Typography>
                </div>)}
              {!selectedDocumentType && (
                <div>
                  <Typography
                    className="label"
                    sx={{ color: "#424242" }}
                  >
                    {t("AttachFile")}
                  </Typography>
                </div>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <StyledDropzone>
                <Dropzone
                  accept="application/zip"
                  multiple={false}
                  onDrop={handleDrop}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone-leave" })}>
                      <div className="inner-dropzone">
                        {formData.files === null ? (
                          <>
                            <input {...getInputProps()} />
                            <div style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  userSelect: "none",
                                }}
                              >
                                <img
                                  alt="add"
                                  src={AddPhotoIcon}
                                  style={{ userSelect: "none" }}
                                />
                              </div>
                              <Typography
                                variant="caption"
                                style={{
                                  lineHeight: 0.5,
                                  color: "#999999",
                                  userSelect: "none",
                                }}
                              >
                                {t("AllowedZipMaxSize")}
                              </Typography>
                            </div>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sx={{ position: "relative", width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <IconButton
                                color="error"
                                onClick={() => handleDeleteFile()}
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  zIndex: 1,
                                }}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                              <Box sx={{ mb: 2 }}>
                                <Box>
                                  <img
                                    alt="add"
                                    src={PaperClip}
                                    style={{ userSelect: "none" }}
                                  />
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  sx={{ pt: 2 }}
                                >
                                  {formData.files &&
                                    truncateFileName(formData.files.name || null, 16)}
                                </Box>
                              </Box>
                            </Grid>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </StyledDropzone>

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <ButtonBlue style={{ pt: 2 }} variant="text" onClick={() => handleOpen()}>{t("Instructions")}</ButtonBlue>
                {formData.files === null ? (
                  <>

                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={2}
                      sx={{ pt: 1, userSelect: 'none' }}
                    >
                      <Link to={'/employees-documents'} >
                        <ButtonBlue
                          variant="contained"
                          color="error"
                        >
                          {t("Cancel")}
                        </ButtonBlue>
                      </Link>
                      <ButtonBlue
                        variant="contained"
                        startIcon={<BackupIcon />}
                        onClick={() => handleOnSubmit(watch("file"))}
                        disabled={formData.files === null || selectedDocumentType.idDocumentType === ''}
                      >
                        {t("Upload")}
                      </ButtonBlue>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                      sx={{ pt: 2.5, pb: 2, userSelect: 'none' }}
                    >
                      <Link to={'/employees-documents'} >
                        <ButtonBlue
                          variant="contained"
                          color="error"
                        >
                          {t("Cancel")}
                        </ButtonBlue>
                      </Link>
                      <ButtonBlue
                        variant="contained"
                        startIcon={<BackupIcon />}
                        onClick={() => handleOnSubmit(watch("file"))}
                        disabled={formData.files === null || selectedDocumentType.idDocumentType === ''}
                      >
                        {t("Upload")}
                      </ButtonBlue>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
      <LoadingModal />
      <InstructionsModal />
    </StyledRoot>
  )
}

export default addDocument
