import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import CardStyle from "../../../shared/general/Card";
import { useTranslation } from "react-i18next";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import dayjs from "dayjs";

const AccordionStyled = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryStyled = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledGridItem = styled(Grid)({
  "&.MuiGrid-item": {
    margin: "12px 0",
    padding: "12px 0 0 36px",
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledDetailDiv = (props) => {
  const { header, detail, isExpand = false } = props;
  return (
    <Grid item xs={12} md={isExpand ? 12 : 6}>
      <Typography fontSize="14px" fontWeight="500" color="text.secondary">
        {header}
      </Typography>
      <Typography>&nbsp;&nbsp;&nbsp;{detail}</Typography>
    </Grid>
  );
};

const StyledHeaderDetailDiv = (props) => {
  const { header } = props;

  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          paddingLeft: "6px",
          borderLeft: "4px solid #46cbe2",
        }}
        fontSize="16px"
        fontWeight="500"
      >
        {header}
      </Typography>
    </Grid>
  );
};

const DetailCard = (props) => {
  const { expenseSetting, handleChangeIsEditting } = props;
  const { t, i18n } = useTranslation();

  return (
    <CardStyle sx={{ padding: "0px" }}>
      <Box sx={{ padding: "36px 48px" }}>
        <Grid container spacing={2}>
          {expenseSetting ? (
            <>
              <StyledHeaderDetailDiv header={t("general")} />
              <StyledDetailDiv
                header={t("EmploymentType")}
                detail={
                  expenseSetting.employmentTypeList ? (
                    expenseSetting.employmentTypeList &&
                    expenseSetting.employmentTypeList.map((employmentType) => (
                      <>
                        <Chip
                          label={
                            i18n.resolvedLanguage === "th"
                              ? employmentType.employmentTypeName
                              : employmentType.employmentTypeName_EN
                          }
                          sx={{ marginTop: "4px", marginRight: "6px" }}
                        />
                      </>
                    ))
                  ) : (
                    <Chip label={t("All")} sx={{ marginTop: "4px" }} />
                  )
                }
              />
              {expenseSetting.isUseGroupLevel === 1 && (
                <StyledDetailDiv
                  header={t("GroupLevel")}
                  detail={
                    expenseSetting.groupLevelList ? (
                      expenseSetting.groupLevelList &&
                      expenseSetting.groupLevelList.map((groupLevel) => (
                        <>
                          <Chip
                            label={groupLevel.groupLevelName}
                            sx={{ marginTop: "4px", marginRight: "6px" }}
                          />
                        </>
                      ))
                    ) : (
                      <Chip label={t("All")} sx={{ marginTop: "4px" }} />
                    )
                  }
                />
              )}
              {expenseSetting.isUseGroupLevel === 0 && (
                <StyledDetailDiv
                  header={t("Level")}
                  detail={
                    expenseSetting.levelList ? (
                      expenseSetting.levelList &&
                      expenseSetting.levelList.map((level) => (
                        <>
                          <Chip
                            label={level.levelName}
                            sx={{ marginTop: "4px", marginRight: "6px" }}
                          />
                        </>
                      ))
                    ) : (
                      <Chip label={t("All")} sx={{ marginTop: "4px" }} />
                    )
                  }
                />
              )}

              <StyledDetailDiv
                header={t("Position")}
                isExpand={true}
                detail={
                  expenseSetting.positionList ? (
                    expenseSetting.positionList &&
                    expenseSetting.positionList.map((position) => (
                      <>
                        <Chip
                          label={
                            i18n.resolvedLanguage === "th"
                              ? position.positionName
                              : position.positionName_EN
                          }
                          sx={{ marginTop: "4px", marginRight: "6px" }}
                        />
                      </>
                    ))
                  ) : (
                    <Chip label={t("All")} sx={{ marginTop: "4px" }} />
                  )
                }
              />
              <StyledDetailDiv
                header={t("dayCutOffType")}
                detail={
                  i18n.resolvedLanguage === "th"
                    ? expenseSetting.cutOffTypeName
                    : expenseSetting.cutOffTypeName_EN
                }
              />
              <StyledDetailDiv
                header={t("dayCutOff")}
                detail={
                  expenseSetting.idExpenseCutOffType === 1
                    ? t(dayjs().day(expenseSetting.dayCutOff).format("dddd"))
                    : expenseSetting.dayCutOff === 0
                    ? t("days.startOfMonth")
                    : expenseSetting.dayCutOff === -1
                    ? t("days.endOfMonth")
                    : expenseSetting.dayCutOff
                }
              />
              <StyledDetailDiv
                header={t("bankAccount")}
                detail={expenseSetting.bankName}
              />
              <StyledDetailDiv
                header={t("bankAccountNumber")}
                detail={expenseSetting.bankAccountNumber}
              />
              {expenseSetting.qrCodeFilePath ? (
                <StyledDetailDiv
                  header={t("File")}
                  isExpand={true}
                  detail={
                    <Box
                      style={{
                        position: "relative",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          padding: 15,
                          backgroundColor: "#f9f9fb",
                          display: "flex",
                          borderRadius: 15,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          expenseSetting.fileURL &&
                            window.open(`${expenseSetting.fileURL}`, "_blank");
                        }}
                      >
                        <FilePresentIcon
                          height="24"
                          style={{
                            marginRight: 10,
                          }}
                        ></FilePresentIcon>
                        <Typography>{expenseSetting.qrCodeFilePath}</Typography>
                      </div>
                    </Box>
                  }
                />
              ) : (
                <StyledDetailDiv
                  header={t("File")}
                  detail={t("NoAttachedFile")}
                />
              )}
            </>
          ) : (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "16px",
                }}
              >
                <StyledHeadLabel variant="body2">{`${t(
                  "NoData"
                )}`}</StyledHeadLabel>
              </div>
            </Grid>
          )}
        </Grid>
      </Box>
    </CardStyle>
  );
};

function AccordionRow(props) {
  const { data, setDrawerConfig, setDeleteConfig } = props;
  const { t, i18n } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);

  return (
    <AccordionStyled
      expanded={isExpand}
      onChange={() => {
        setIsExpand((prev) => !prev);
      }}
    >
      <AccordionSummaryStyled>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Typography flexGrow="1">
            {i18n.resolvedLanguage === "th" ? data.name : data.name_EN}
          </Typography>

          <Box>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation();
                setDrawerConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  isEdit: true,
                  data: data,
                }));
              }}
            >
              <EditNoteRoundedIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  data: {
                    idExpenseSetting: data.idExpenseSetting,
                    name:
                      i18n.resolvedLanguage === "th" ? data.name : data.name_EN,
                  },
                }));
              }}
            >
              <DeleteOutlineRoundedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <DetailCard expenseSetting={data} />
      </AccordionDetails>
    </AccordionStyled>
  );
}

export default AccordionRow;
