import React, { useState, useEffect, useSelector } from "react";
import { Modal, Box, Typography, Grid, Button } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import CustomStepper from "../../../manager/e-memo/components/Stepper";
import { useTranslation } from "react-i18next";
import { getNameLang } from "../../../../../utils";

const RequestDetailsModal = ({
  open,
  onClose,
  requestDetails,
  userProfile,
}) => {
  const [detailsText, setDetailsText] = useState("");
  const [approverComment, setApproverComment] = useState("");
  const { t , i18n} = useTranslation();
  useEffect(() => {
    if (requestDetails.details) {
      setDetailsText(requestDetails.details.replace(/(<([^>]+)>)/gi, ""));
    }
  }, [requestDetails.details]);

  const getStep = () => {
    switch (requestDetails.currentStep) {
      case "initial1":
        return 0;
      case "initial2":
        return 1;
      // case "initial3":
      //   return 2;
      case "approver":
        return 3;
      case "success":
        return 5;
      default:
        return 0;
    }
  };

  //const isApproved = requestDetails.status === "Approved";

  const getStatus = (currentStep, step) => {
    if (currentStep === "initial1" && step === "approver") {
      return "Waiting Approval";
    } else if (currentStep === "initial1" && step === "initial1") {
      return "Waiting Approval";
    } else if (currentStep === "initial2" && step === "initial1") {
      return "Approved";
    } else if (currentStep === "initial2" && step === "initial2") {
      return "Waiting Approval";
      // } else if (currentStep === "initial2" && step === "initial3") {
      //   return "Waiting Approval";
    } else if (currentStep === "initial2" && step === "approver") {
      return "Waiting Approval";
      // } else if (currentStep === "initial3" && step === "initial1") {
      //   return "Approved";
      // } else if (currentStep === "initial3" && step === "initial2") {
      //   return "Approved";
      // } else if (currentStep === "initial3" && step === "initial3") {
      //   return "Waiting Approval";
      // } else if (currentStep === "initial3" && step === "approver") {
      //   return "Waiting Approval";
    } else if (
      requestDetails.idInitial1 &&
      (currentStep === "approver" || currentStep === "success") &&
      step === "initial1"
    ) {
      return "Approved";
    } else if (
      requestDetails.idInitial2 &&
      (currentStep === "approver" || currentStep === "success") &&
      step === "initial2"
    ) {
      return "Approved";
      // } else if (
      //   requestDetails.idInitial3 &&
      //   currentStep === "approver" &&
      //   step === "initial3"
      // ) {
      //   return "Approved";
    } else if (currentStep === "approver" && step === "approver") {
      return "Waiting Approval";
    } else if (currentStep === "success" && step === "approver") {
      return "Approved";
    } else if (
      requestDetails.idInitial2 &&
      currentStep === "initial1" &&
      step === "initial2"
    ) {
      return "Waiting Approval";
      // } else if (
      //   requestDetails.idInitial3 &&
      //   currentStep === "initial1" &&
      //   step === "initial3"
      // ) {
      //   return "Waiting Approval";
    }
    return "-";
  };

  const disableActions = () => {
    const currentIdEmployees = userProfile.idEmployees;
    if (requestDetails.status === 'Approved') {
      return true
    }
    if (currentIdEmployees === requestDetails.idManager && requestDetails.status !== 'Waiting Approver Approve') {
      return true
    }
    const isApprovedOrRejected = (status) => {
      return status === "Approved" || status === "-";
    };
    const res = (currentIdEmployees === requestDetails.idInitial1 &&
      isApprovedOrRejected(
        getStatus(requestDetails.currentStep, "initial1")
      )) ||
      (currentIdEmployees === requestDetails.idInitial2 &&
        isApprovedOrRejected(
          getStatus(requestDetails.currentStep, "initial2")
        )) ||
      // (currentUserName === requestDetails.initial3_name &&
      //   isApprovedOrRejected(
      //     getStatus(requestDetails.currentStep, "initial3")
      //   )) ||
      (currentIdEmployees === requestDetails.idManager &&
        isApprovedOrRejected(getStatus(requestDetails.currentStep, "approver")))

    return res;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6">{`${t('Details')}`}</Typography>
        <Box sx={{ ...contentStyle }}>
          <Grid container spacing={2} style={{ marginTop: 16 }}>
            <Grid item xs={12}>
              <CustomStepper activeStep={getStep()} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t("DocumentNumber")}
                value={requestDetails.document_no}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('eMemo.status')}
                value={requestDetails.status}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('eMemo.creator')}
                value={requestDetails.creator}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('eMemo.requester')}
                value={requestDetails.requester}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('eMemo.createdDate')}
                value={requestDetails.created_date}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('Email')}
                value={requestDetails.email}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('eMemo.tel')}
                value={requestDetails.tel}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldTheme
                label={t('eMemo.classificationLevels')}
                value={requestDetails.classificationLevels}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextFieldTheme
                label="Priority"
                value={requestDetails.priority}
                InputProps={{ readOnly: true }}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextFieldTheme
                label={t('eMemo.type')}
                value={requestDetails.eMemoType}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldTheme
                label={t('eMemo.subject')}
                value={requestDetails.subject}
                fullWidth
                InputProps={{ readOnly: true }}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldTheme
                label={t('Details')}
                value={detailsText}
                fullWidth
                InputProps={{ readOnly: true }}
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('eMemo.approver')}
                value={getNameLang(requestDetails, 'approver_name', i18n.resolvedLanguage)|| "-"}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('eMemo.status')}
                value={getStatus(requestDetails.currentStep, "approver")}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" gap={1}>
                <TextFieldTheme
                  label={t('eMemo.initial1')}
                  value={getNameLang(requestDetails,'initial1_name',i18n.resolvedLanguage) || "-"}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('eMemo.status')}
                value={getStatus(requestDetails.currentStep, "initial1")}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" gap={1}>
                <TextFieldTheme
                  label={t('eMemo.initial2')}
                  value={getNameLang(requestDetails,'initial2_name',i18n.resolvedLanguage) || "-"}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={t('eMemo.status')}
                value={getStatus(requestDetails.currentStep, "initial2")}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" gap={1}>
                <TextFieldTheme
                  label="Initial3"
                  value={requestDetails.initial3_name || "-"}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label="Status"
                value={getStatus(requestDetails.currentStep, "initial3")}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid> */}
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: 16 }}>
            <TextFieldTheme
              label={`${t('Comment')}`}
              value={requestDetails.approver_comment || "-"}
              fullWidth
              multiline
              rows={3}
              disabled={disableActions()}
            />
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: 4,
  p: 4,
  outline: "none",
};

const contentStyle = {
  maxHeight: "80vh",
  overflowY: "auto",
};

export default RequestDetailsModal;
