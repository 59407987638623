import ExcelJS from 'exceljs';
import { getUserFirstName, getUserLastName, getUserPosition } from '../../../../../utils/userData';
export const ManagerXlsx = async (t,data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet(t("Manager"));

    const headerRow = worksheet1.addRow([
        t("FirstName"),
        t("LastName"),
        t("Email"),
        t("JobPosition"),
        t("Rights"),
    ]);

    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
    });

    const colWidths = [
        { key: "firstname", width: 40 },
        { key: "lastname", width: 40 },
        { key: "email", width: 40 },
        { key: "position", width: 40 },
        { key: "rights", width: 40 },
    ];

    data.forEach((item) => {
        const row = [
            getUserFirstName(item) ? getUserFirstName(item) : "-",
            getUserLastName(item) ? getUserLastName(item) : "-",
            item.email ? item.email : "-",
            getUserPosition(item) ? getUserPosition(item) : "-",
            item.idRole ? item.idRole === 2 ? `${t("ManagerUser")}`
                : item.idRole === 3 ? t("Manager") : "-"
                : "-"
        ];

        const excelRow = worksheet1.addRow(row);
        const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

        colWidths.forEach((col, index) => {
            worksheet1.getColumn(index + 1).width = col.width;
        });

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    })

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("Manager")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}