import ExcelJS from "exceljs";
import dayjs from 'dayjs';

export const ProvidentFundUpdateXlsx = async (t, data) => {  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("ProvidentFundUpdateReport"));
  
    const headers = [
      t("EmployeeID"),
      t("FirstName"),
      t("LastName"),
      t("PersonalID"),
      t("CompanyName"),
      t("Division"),
      t("Section"),
      t("Position"),
      t("Department"),
      t("PreviousProvidentFundEmployee"),
      t("LatestProvidentFundEmployee"),
      t("FundEntryDate"),
    ];
  
    const headerRow = worksheet.addRow(headers);

    headerRow.height = 50;

    const headerStyle1 = {
      font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
      alignment: { horizontal: "center", vertical: 'middle' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '002060' },
      },
    };

    headerRow.eachCell((cell) => {
      cell.style = headerStyle1;       
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
  
    const colWidths = [
      { key: "employeeID", width: 40 },
      { key: "firstname", width: 40 },
      { key: "lastname", width: 40 },
      { key: "personalID", width: 40 },
      { key: "companyName", width: 40 },
      { key: "divisionName", width: 40 },
      { key: "sectionName", width: 40 },
      { key: "positionName", width: 40 },
      { key: "departmentName", width: 40 },
      { key: "previousUserPF", width: 40 },
      { key: "latestUserPF", width: 40 },
      { key: "userPFStartDate", width: 40 },
    ];

    colWidths.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width;
    });
  
    data.forEach((employee) => {
      const rowData = [
        employee.employeeID || "-",
        employee.firstname || "-",
        employee.lastname || "-",
        employee.personalID || "-",
        employee.companyName || "-",
        employee.divisionName || "-",
        employee.sectionName || "-",
        employee.positionName || "-",
        employee.departmentName || "-",
        employee.previous_userPF || "-",
        employee.latest_userPF || "-",
        employee.userPF_start_date ? dayjs(employee.userPF_start_date).format("YYYY-MM-DD") : "-",
      ];
      
      const dataRow = worksheet.addRow(rowData);

      const contentStyle = {
        font: { size: 16, name: "TH SarabunPSK", bold: false },
        alignment: { horizontal: "center", vertical: "middle" },
        border: {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        },
      };

      dataRow.eachCell((cell) => {
        Object.assign(cell.style, contentStyle);
      });
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${t("ProvidentFundUpdateReport")}.xlsx`;
    link.click();
  };
  