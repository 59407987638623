import React, { useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import dayjs from "dayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import { getOnBoardingHistoryByReviewer } from "../../../../../actions/onBoarding";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";

const OnBoardingHistory = ({ handleCloseHistory, handleOpenDialog }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: onBoardingList } = useSelector(
    (state) => state.onBoardingList
  );

  const [selectDate, setSelectDate] = useState(null);

  useEffect(() => {
    dispatch(getOnBoardingHistoryByReviewer());
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Typography fontSize={18} fontWeight={500}>{t("ApprovalHistory")}</Typography>
        <ButtonBlue variant="contained" onClick={handleCloseHistory}>
          {t("PendingApproval")}
        </ButtonBlue>
      </div>
      <div style={{ maxWidth: "240px", marginBottom: "16px" }}>
        <Typography color="text.third" fontWeight={500}>{t("SelectDate")}</Typography>
        <DatePickerCustom
          views={["year", "month"]}
          maxDate={dayjs()}
          value={selectDate}
          onChange={(newValue) => setSelectDate(newValue)}
          inputFormat={
            i18n.resolvedLanguage === "th" ? "MMMM BBBB" : "MMMM YYYY"
          }
          componentsProps={{
            actionBar: {
              actions: ["today", "clear"],
            },
          }}
        />
      </div>
      {onBoardingList && onBoardingList.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("EmployeeName")}</TableCell>
                <TableCell align="center">{t("Position")}</TableCell>
                <TableCell align="center">{t("Company")}</TableCell>
                <TableCell align="center">{t("Department")}</TableCell>
                <TableCell align="center">{t("Section")}</TableCell>
                <TableCell align="center">{t("TitleName")}</TableCell>
                <TableCell align="center">{t("InspectionDate")}</TableCell>
                <TableCell align="center">{t("Status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {onBoardingList
                .filter((item) =>
                  selectDate
                    ? selectDate.isSame(dayjs(item.updateDate), "month")
                    : true
                )
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {`${row.firstname_TH}  ${row.lastname_TH}`}{" "}
                    </TableCell>
                    <TableCell align="center">{row.positionName} </TableCell>
                    <TableCell align="center">{row.companyName} </TableCell>
                    <TableCell align="center">{row.departmentName} </TableCell>
                    <TableCell align="center">{row.sectionName} </TableCell>
                    <TableCell align="center">
                      {row.OnBoardingListName}{" "}
                    </TableCell>
                    <TableCell align="center">
                      {row.updateDate
                        ? dayjs(row.updateDate).format("DD/MM/YYYY")
                        : null}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleOpenDialog(row)}>
                        <CheckCircleOutlineIcon style={{ color: "green" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>{t("NoData")}</Typography>
      )}
    </>
  );
};

export default OnBoardingHistory;
