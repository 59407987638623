import React, { useEffect, useState } from "react";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";
// //Translator TH-EN
import { useTranslation } from "react-i18next";
import DialogProbation from "./DialogProbation";
import {
  getProbationAdmin,
  updateProbationManager,
} from "../../../../../actions/probation";

const RequestProbation = ({ setNumberOfList, searchDate, selectedCompany }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: employeesProbation } = useSelector(
    (state) => state.employeesProbation
  );

  const [pendingRows, setPendingRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [probation, setProbation] = useState(null);

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
            {row.questionSet.isSelfAssessment === 1 && row.employeeEvaluateDate === null && (
              <Chip
                color="error"
                size="small"
                fontSize="14px" 
                label={t("HaveNotBeenEvaluated")}
              />
            )}
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("HiringDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.hiringDate).format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("ProbationaryExpirationDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {dayjs(row.probationDate).format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: `${t("ProbationRound")}`,
      headerTextAlign: "center",
      minWidth: "120px",
      width: "120px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.probationRound === 1 ? (
            <Chip color="primary" label={`${t("RoundNo")} 1`} />
          ) : (
            <Chip color="secondary" label={`${t("RoundNo")} 2`} />
          )}
        </Box>
      ),
    },
    {
      name: `${t("Manager")}`,
      headerTextAlign: "center",
      minWidth: "160px",
      width: "160px",
      cellRender: (row) => {
        return (
          <>
            {row.questionSet.isManagerLV1Assessment === 1 && row.managerLV1Result === null && (
              <Typography gutterBottom>
                {`1) ${i18n.resolvedLanguage === "th" ? row.managerLV1Name_TH : row.managerLV1Name_EN}`}
              </Typography>
            )}
            {row.questionSet.isManagerLV2Assessment === 1 && row.managerLV2Result === null && (
              <Typography>
                {`2) ${i18n.resolvedLanguage === "th" ? row.managerLV2Name_TH : row.managerLV2Name_EN}`}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      name: `${t("ExtendProbationPeriod")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => {
        return (
          <>
            {row.managerLV1Result === null && row.managerLV1ExpandTime && (
              <Typography gutterBottom>
                {`1) ${dayjs(row.probationDate).add(row.managerLV1ExpandTime,"day").format(
                    i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
                )}`}
              </Typography>
            )}
            {row.managerLV2Result === null && row.managerLV2ExpandTime && (
              <Typography gutterBottom>
                {`2) ${dayjs(row.probationDate).add(row.managerLV2ExpandTime,"day").format(
                    i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
                )}`}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      name: `${t("Evaluate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              align: "center",
            }}
          >
            <ButtonBlue
              variant="contained"
              onClick={() => {
                setProbation(row);
                handleOpenDialog("Add");
              }}
            >
              {t("Evaluate")}
            </ButtonBlue>
          </div>
        </Box>
      ),
    },
  ];

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setProbation(null);
  };

  const handleSubmitProbation = async (formData) => {
    const cleanQuestion = formData.questionList.map((item) => ({
      idProbationQuestion: item.idProbationQuestion,
      answer: item.answer,
    }));

    const cleansingForm = {
      ...formData,
      idProbation: probation.idProbation,
      questionList: cleanQuestion,
      result: formData.statusProbation,
      reasonTermination:
        formData.statusProbation === 0 ? formData.reasonTermination : null,
    };

    const res = await dispatch(updateProbationManager(cleansingForm));
    if (res.status === 200) {
      dispatch(
        openNotificationAlert({
          type: "success",
          message: t("DataSaveSuccessful"),
        })
      );
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred"),
        })
      );
    }
    handleClose();
    dispatch(getProbationAdmin({ idCompany: selectedCompany.idCompany }));
  };

  useEffect(() => {
    let tempPending = [...employeesProbation];
    if (searchDate.start || searchDate.end) {
      tempPending = employeesProbation.filter((item) =>
        dayjs(item.probationDate).isBetween(
          searchDate.start || dayjs(),
          searchDate.end || dayjs()
        )
      );
    }

    setPendingRows(tempPending);
    setNumberOfList(tempPending.length);
  }, [employeesProbation, searchDate]);

  useEffect(() => {
    if (employeesProbation) {
      setPendingRows(employeesProbation);
    }
  }, []);

  return (
    <>
      <TableCustom columns={columns} rows={pendingRows ? pendingRows : []} />
      {openDialog && (
        <DialogProbation
          open={openDialog}
          handleClose={handleClose}
          probation={probation}
          handleSubmitProbation={handleSubmitProbation}
        />
      )}
    </>
  );
};

export default RequestProbation;
