import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';

export const PND1kXlsxFile = (selectYear, employeeDetails, contributionRate) => {
    const presentDay = new Date();

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const taxValue = employeeDetails.TaxValue;
    const employeeData = employeeDetails.employeeDetails;

    const year = dayjs(presentDay).format("YYYY");
    const ThaiYear = parseInt(year) + 543;

    const headerRow = worksheet.addRow([
        "ลักษณะการยื่นแบบ",
        "เลขประจำตัวผู้เสียภาษีอากรของผู้มีหน้าที่หัก ณ ที่จ่าย",
        "เลขประจำตัวผู้เสียภาษีอากรผู้มีหน้าที่หัก ณ ที่จ่าย",
        "เลขที่สาขา ผู้มีหน้าที่หัก ณ ที่จ่าย",
        "เลขประจำตัวผู้เสียภาษีอากรของผู้ถูกหัก ณ ที่จ่าย",
        "เลขประจำตัวผู้เสียภาษีอากรผู้มีเงินได้",
        "คำนำหน้าชื่อผู้มีเงินได้",
        "ชื่อผู้มีเงินได้",
        "นามสกุลผู้มีเงินได้",
        "ที่อยู่ 1",
        "จังหวัด",
        "รหัสไปรษณีย์",
        "ปีภาษี",
        "รหัสเงินได้",
        "วันที่จ่ายเงินได้",
        "อัตราภาษีร้อยละ",
        "จำนวนเงินที่จ่าย",
        "จำนวนเงินภาษีที่หัก และนำส่ง",
        "เงื่อนไขการหักภาษี ณ ที่จ่าย",
    ]);

    headerRow.height = 50;

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK' },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDDDDD' }
        },
    };

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    if (employeeDetails && employeeData.length > 0) {
        for (let itemIndex = 0; itemIndex < employeeData.length; itemIndex++) {
            const item = employeeData[itemIndex];
            const findEmpTax = taxValue && taxValue.find(tax => tax.idEmployees === item.idEmployees);

            const tax = item.otherPayment && item.otherPayment.value ? item.otherPayment.value: 0;
            // const tax = findEmpTax && findEmpTax.value ? findEmpTax.value : 0;
            const SumLate = findEmpTax && findEmpTax.Sum_late ? findEmpTax.Sum_late : 0;
            const SumAbsent = findEmpTax && findEmpTax.Sum_absent ? findEmpTax.Sum_absent : 0;
            const AllEarnings = item.accumulateEarnings || 0;

            const presentDay = new Date();
            const presentMonthTH = dayjs(presentDay).locale('th').format("MMMM");

            const row = [
                `00`,
                item.personalID ? item.personalID : "",
                `0000000000`,
                `0000`,
                item.personalID || "",
                `0000000000`,
                item.title_TH || "",
                item.firstname_TH || "",
                item.lastname_TH || "",
                `${item.addressCompany || ""} ${item.districtCompany || ""}`,
                `${item.provinceCompany || ""}`,
                item.areaCodeCompany || "",
                selectYear,
                '1',
                dayjs(presentDay).format("DD") + " " + `${presentMonthTH}` + " " + `${ThaiYear}`,
                `${contributionRate ? contributionRate : '-'}`,
                AllEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                (tax + item.beforeAccumulateTax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                `1`
            ];

            worksheet.addRow(row);

            // Apply font size to the entire row
            const lastRow = worksheet.lastRow;
            lastRow.font = { size: 16, name: 'TH SarabunPSK' };

            lastRow.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        }

        const colWidths = [
            { key: "", width: 50 },
            { key: "taxID", width: 60 },
            { key: "", width: 60 },
            { key: "", width: 40 },
            { key: "taxID", width: 50 },
            { key: "", width: 60 },
            { key: "title_TH", width: 50 },
            { key: "firstname_TH", width: 40 },
            { key: "lastname_TH", width: 40 },
            { key: "company", width: 100 },
            { key: "company2", width: 100 },
            { key: "areaCode", width: 20 },
            { key: "selectYear", width: 20 },
            { key: "", width: 20 },
            { key: "presentDay", width: 20 },
            { key: "", width: 20 },
            { key: "totalEarnings", width: 40 },
            { key: "value", width: 50 },
            { key: "", width: 50 },
        ];

        colWidths.forEach((col, index) => worksheet.getColumn(index + 1).width = col.width);

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `ภงด-1ก.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    } else {
        console.log("No data");
    }
};