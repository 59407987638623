import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, styled } from "@mui/material";

import LeftPanel from "./left";
import RightPanel from "./right";

import { getAffiliateOrganization, getCompanyProfile } from "../../../../actions/company";
import { getUserProfile } from "../../../../actions/user";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .headerText": {
    fontSize: 12,
    color: "#9e9e9e",
  },
  "& .valueText": {
    wordBreak: "break-word",
  },
});

const CompanyProfilePage = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { result: companyProfile } = useSelector((state) => state.companyProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [selectedCompany, setSelectedCompany] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const onChangeCompany = (newValue) => {
    setIsLoading(true)
    setSelectedCompany(newValue);
    dispatch(getCompanyProfile({
      idCompany: newValue.idCompany,
    })).then(res => {
      setIsLoading(false)
    });
  }

  useEffect(() => {
    // dispatch(getCompanyProfile());
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if(userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0){
      let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if(!ownCompany){
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany)
      dispatch(getCompanyProfile({
        idCompany: ownCompany.idCompany,
      })).then(res => {
        setIsLoading(false)
      });
    }
  }, [userProfile, affiliateOrganizationList])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{ padding: "8px 12px 16px" }}>
        {isLoading && (
          <Typography textAlign="center" fontSize="24px">{t("LoadingData")}...</Typography>
        )}
        {companyProfile && !isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={(_, value) => {
                  onChangeCompany(value)
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <LeftPanel selectedCompany={selectedCompany}/>
            </Grid>
            <Grid item xs={12} lg={8}>
              <RightPanel selectedCompany={selectedCompany} />
            </Grid>
          </Grid>
        )}
      </Container>
    </StyledRoot>
  );
};

export default CompanyProfilePage;
