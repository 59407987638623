import React from "react";
import { useSelector } from "react-redux";
import EMemoRequestlistAdminPage from "../admin/e-memo";
import EMemoRequestlistPage from "../manager/e-memo";
import EMemoWorklistPage from "../user/e-memo";

const EMemoPage = () => {
    const { result: userProfile } = useSelector((state) => state.userProfile);
    return (
        <div>
            {userProfile ? (
                userProfile.roles.includes("ROLE_ADMIN") ? (
                    <EMemoRequestlistAdminPage />
                ) : userProfile.roles.includes("ROLE_USER") ? (
                    <EMemoWorklistPage />
                ) : (
                    <EMemoRequestlistPage />
                )
            ) : null}
        </div>
    );
};

export default EMemoPage;
