import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import SignatureCanvas from 'react-signature-canvas';
import { Box, Grid, Button, useMediaQuery, useTheme  } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from "react-i18next";

import { styled } from '@mui/material/styles';

import {
    addSignatureImageOfEmployees,
  } from "../../../../actions/employee";

  
const SignaturePad = (props) => {

    const { t } = useTranslation();
    const theme = useTheme();
    const { employeeProfile, isDialogOpen, setIsDialogOpen,  matchesXs,  matchesMd } = props;
    const dispatch = useDispatch();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [nameOfImage, setNameOfImage] = useState("employeeSignatureImage.png");
    const handleChangeAlertType = (status) => {
        setAlertType(status);
      };
      
    const handleOpenAlert = () => {
        setOpenAlert(true);
    };
    
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    
    const [sign, setSign] = useState(null);
    const [url, setUrl] = useState('');

    const handleClear = () => {
        sign.clear();
    };

    // สร้างฟังก์ชันเพื่อแปลง Data URL ของ Canvas เป็น Blob
    function dataURLtoBlob(dataURL) {
        const parts = dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    }

    const handleOnSubmitAndGenerate  = async (file)=> {
    try {
        setOpen(true);
        // console.log('dew',file);
        const canvasUrl = sign.getTrimmedCanvas().toDataURL('image/png');
        // console.log('url', canvasUrl);

        const blob = dataURLtoBlob(canvasUrl);

        // handleGenerate();

        const formData = new FormData();

        let filename = null;
        filename = nameOfImage ? nameOfImage : "employeeSignatureImage.png";

        if (employeeProfile && employeeProfile.idEmployees ) {
        formData.append("image", blob, filename);
        formData.append("idCompany", employeeProfile.idCompany);
    
        // console.log(Array.from(formData));
    
        // for(let obj of (formData)) {
        //   console.log(obj);
        // }
        
        } else {
          console.error("Selected  employeeProfile is undefined or missing idCompany");
        }
        // console.log('formData',formData)
    
        const response = await dispatch(addSignatureImageOfEmployees(formData));
    
        if (response.status === 200) {
             handleChangeAlertType("success");
             handleOpenAlert();
             setIsDialogOpen(true);
          } else {
              handleChangeAlertType("error");
              handleOpenAlert();
         }
        } catch (error) {
            console.error('เกิดข้อผิดพลาดในการอัปโหลดไฟล์:', error);
        }
      };

      
      const [open, setOpen] = useState(false);
      const [confirmOpen, setConfirmOpen] = useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const handleConfirm = () => {
        setConfirmOpen(false);
        handleOnSubmitAndGenerate();
      };

      const StyledDialog = styled(Dialog)(({ theme, matchesXs, matchesMd }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: matchesXs ? '0px' : matchesMd ? '400px' : '0',
        marginRight: matchesXs ? '60px' : matchesMd ? '0px' : '0',
        marginTop: matchesXs ? '100px' : matchesMd ? '95px' : '0',
      }));
         
    return (
        <>
            {!isDialogOpen && (
            <Box>
                    <Box>
                    <SignatureCanvas
                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                        ref={data => setSign(data)}
                        style={{ width: '100%', height: '100%' }}
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <Button variant="contained" color="primary" onClick={handleClear}>
                        {`${t("clear")}`}
                        </Button>
                        <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClickOpen}
                        >
                        {`${t("Save")}`}
                        </Button>
                    </Box>
                    <StyledDialog 
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        matchesXs={matchesXs}
                        matchesMd={matchesMd}
                    >
                        <DialogTitle id="confirm-dialog-title">{"Confirm Save?"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`${t("Are you sure you want to save this signature? ")}`}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            style={{
                              backgroundColor: '#f5f5f5',
                              color: 'black',
                              '&:hover': {
                                backgroundColor: 'red', // Change background color to red on hover
                              },
                            }}
                          >
                          {`${t("Cancel")}`}
                        </Button>
                        <Button 
                            onClick={handleConfirm}
                            color="primary"
                            autoFocus 
                            style={{ 
                              backgroundColor: '#f5f5f5', 
                              color: '#0067cc', 
                              '&:hover': { backgroundColor: '#8bc34a' } 
                            }}>
                          {`${t("Confirm")}`}
                        </Button>
                        </DialogActions>
                    </StyledDialog>
                </Box>
            </Box>
            )}
        </>
    );
};

export default SignaturePad;
