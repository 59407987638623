import { httpClient } from "./httpClient";

const getExpenseProrateTypes = () => {
  return httpClient.get(`expense/prorate-types`);
};

const getExpenseAllowanceTypes = () => {
  return httpClient.get(`expense/allowance-types`);
};

//  General Setting
const getExpenseSettings = (idCompany) => {
  return httpClient.get(`expense/settings?idCompany=${idCompany}`);
};

const addExpenseSetting = (formData) => {
  return httpClient.post(`expense/setting`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseSetting = (formData) => {
  return httpClient.put(`expense/setting`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteExpenseSetting = (idExpenseSetting) => {
  return httpClient.post(`expense/setting/delete/${idExpenseSetting}`);
};

const getExpenseSetting = () => {
  return httpClient.get(`expense/setting`);
};

// Mileage Setting
const getExpenseSettingsMileage = (idCompany) => {
  return httpClient.get(`expense/settings/mileage?idCompany=${idCompany}`);
};

const addExpenseSettingMileage = (formData) => {
  return httpClient.post(`expense/setting/mileage`, formData);
};

const updateExpenseSettingMileage = (formData) => {
  return httpClient.put(`expense/setting/mileage`, formData);
};

const deleteExpenseSettingMileage = (idExpenseSettingMileage) => {
  return httpClient.post(
    `expense/setting/mileage/delete/${idExpenseSettingMileage}`
  );
};

const getExpenseSettingMileage = () => {
  return httpClient.get(`expense/setting/mileage`);
};

// Allowance Setting
const getExpenseSettingsAllowance = (idCompany) => {
  return httpClient.get(`expense/settings/allowance?idCompany=${idCompany}`);
};

const addExpenseSettingAllowance = (formData) => {
  return httpClient.post(`expense/setting/allowance`, formData);
};

const updateExpenseSettingAllowance = (formData) => {
  return httpClient.put(`expense/setting/allowance`, formData);
};

const deleteExpenseSettingAllowance = (idExpenseSettingAllowance) => {
  return httpClient.post(
    `expense/setting/allowance/delete/${idExpenseSettingAllowance}`
  );
};

const getExpenseSettingAllowance = () => {
  return httpClient.get(`expense/setting/allowance`);
};

export default {
  getExpenseProrateTypes,
  getExpenseAllowanceTypes,
  getExpenseSettings,
  addExpenseSetting,
  updateExpenseSetting,
  deleteExpenseSetting,
  getExpenseSetting,

  getExpenseSettingsMileage,
  addExpenseSettingMileage,
  updateExpenseSettingMileage,
  deleteExpenseSettingMileage,
  getExpenseSettingMileage,

  getExpenseSettingsAllowance,
  addExpenseSettingAllowance,
  updateExpenseSettingAllowance,
  deleteExpenseSettingAllowance,
  getExpenseSettingAllowance,
};
