import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { Grid, MenuItem, styled, Typography } from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import { 
  getProvidentFundEmployeeByIdEmployee,
  updateProvidentFundDate
} from '../../../../../../actions/providentFund';
import {
  openNotificationAlert
} from '../../../../../../actions/notificationAlert';
import DatePickerCustom from "../../../../shared/date/datePicker";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogPFDate = (props) => {
  const { open, onClose, values } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: EmployeeProvidentFunds } = useSelector(state => state.employeeProvidentFunds);
  const [formData, setFormData] = useState({
    PFDate: dayjs(),
    isServYPFDate: 1
  });

  useEffect(() => {
    if (values) {
      setFormData({
        PFDate: values.PFDate ? dayjs(values.PFDate) : dayjs(),
        isServYPFDate: [0,1].includes(values.isServYPFDate) ? values.isServYPFDate : 1
      });
    }
  }, [values]);

  const handleSubmit = async () => {
    let data = {};
    if(!values.PFDate || values.PFDate && !dayjs(formData.PFDate).isSame(values.PFDate, "date")){
      data.PFDate = dayjs(formData.PFDate).format("YYYY-MM-DD")
    }
    if(values.isServYPFDate !== formData.isServYPFDate){
      data.isServYPFDate = `${formData.isServYPFDate}`;
    }
    let result = null;
    data.idEmployeePFDate = EmployeeProvidentFunds.idEmployeePFDate;
    result = await dispatch(updateProvidentFundDate(data));
    if (result.status === 200) {
      dispatch(openNotificationAlert({
        type: "success",
        message: "ออกจากกองทุนสำเร็จ"
      }));
      onClose();
      dispatch(getProvidentFundEmployeeByIdEmployee(employeeProfile.idEmployees));
    }else{
      dispatch(openNotificationAlert({
        type: "error",
        message: t("ErrorOccurredContactAdmin")
      }));
    }
  };

  return (
    <DrawerCustom
      title={t("EditFundDate")}
      anchor={"right"}
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{t("FundEntryDate")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <DatePickerCustom
              inputFormat="DD/MM/YYYY"
              value={formData.PFDate}
              name="PFDate"
              onChange={(newValue) => {
                setFormData({
                  ...formData,
                  PFDate: newValue,
                });
              }}
              renderInput={(params) => (
                <TextFieldTheme fullWidth {...params} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("CalYearInFund")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldTheme
              value={formData.isServYPFDate}
              name="isServYPFDate"
              onChange={(event) => {
                setFormData({
                  ...formData,
                  isServYPFDate: event.target.value,
                });
              }}
              select
            >
              <MenuItem value={1}>{t("FundEntryDate")}</MenuItem>
              <MenuItem value={0}>{t("ServiceDateInFund")}</MenuItem>
            </TextFieldTheme>
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={onClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("SaveData")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogPFDate;
