import "dayjs/locale/th";
import ExcelJS from "exceljs";

export const CostCenterCompanyMonthXlsx = async (t, data, companyName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(t("CostCenter"));

  const additionLabels = [
    ...new Set(
      data.flatMap((item) =>
        item.payrunDetailAddition.map((add) => add.name)
      )
    ),
  ];

  const deductionLabels = [
    ...new Set(
      data.flatMap((item) =>
        item.payrunDetailDeduction.map((ded) => ded.name)
      )
    ),
  ];

  const headers = [
    t("CostCenter"),
    t("CompanyName"),
    t("Salary"),
    `${t("Overtime")} 1 (${t("Baht")})`,
    `${t("Overtime")} 1.5 (${t("Baht")})`,
    `${t("Overtime")} 2 (${t("Baht")})`,
    `${t("Overtime")} 3 (${t("Baht")})`,
    t("OTTotal"),
    t("ShiftPayTotal"),
    ...additionLabels,
    t("TotalAdditionalMoney"),
    t("TotalIncome"),
    t("ProvidentFundCompany"),
    t("ProvidentFundEmployee"),
    t("SocialSecurity"),
    t("Tax"),
    `${t("Late")}/${t("LeaveEarly")} (${t("Baht")})`,
    `${t("Absent")} (${t("Baht")})`,
    `${t("LeaveWithoutPay")} (${t("Baht")})`,
    ...deductionLabels,
    t("TotalDeduction"),
    t("NetIncome"),
  ];

  const headerRow = worksheet.addRow(headers);
  headerRow.height = 50;

  const headerStyle = {
    font: { bold: true, size: 18, name: "TH SarabunPSK", color: { argb: "FFFFFF" } },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "002060" } },
  };

  const headerStyleAddition = {
    font: { bold: true, size: 18, name: "TH SarabunPSK", color: { argb: "FFFFFF" } },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "0B6623" } },
  };

  const headerStyleDeduction = {
    font: { bold: true, size: 18, name: "TH SarabunPSK", color: { argb: "FFFFFF" } },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "C81A00" } },
  };

  const headerStyles = [
    headerStyle, // CostCenter
    headerStyle, // CompanyName
    headerStyle, // Salary
    headerStyle, // OT1
    headerStyle, // OT1.5
    headerStyle, // OT2
    headerStyle, // OT3
    headerStyle, // TotalOT
    headerStyle, // TotalShift
    ...(additionLabels.map(() => headerStyleAddition)), // Addition columns
    headerStyle, // TotalAdditional
    headerStyle, // TotalIncome
    headerStyle, // ProvidentFund.Company
    headerStyle, // ProvidentFund.Employee
    headerStyle, // SocialSecurity
    headerStyle, // Tax
    headerStyle, // EarlyLateLeave
    headerStyle, // Absent
    headerStyle, // LeaveWithoutPay
    ...(deductionLabels.map(() => headerStyleDeduction)), // Deduction columns
    headerStyle, // TotalDeductions
    headerStyle, // NetIncome
  ];

  headerRow.eachCell((cell, colNumber) => {
    if (headerStyles[colNumber - 1]) {
      cell.style = headerStyles[colNumber - 1];
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    }
  });

  const colWidths = [
    { key: "CostCenter", width: 30 },
    { key: "CompanyName", width: 40 },
    { key: "Salary", width: 40 },
    { key: "OT1", width: 40 },
    { key: "OT1.5", width: 40 },
    { key: "OT2", width: 40 },
    { key: "OT3", width: 40 },
    { key: "TotalOT", width: 40 },
    { key: "TotalShift", width: 40 },
    ...additionLabels.map(() => ({ width: 60 })),
    { key: "TotalAdditional", width: 40 },
    { key: "TotalIncome", width: 40 },
    { key: "ProvidentFundCompany", width: 40 },
    { key: "ProvidentFundEmployee", width: 40 },
    { key: "SocialSecurity", width: 30 },
    { key: "Tax", width: 20 },
    { key: "EarlyLateLeave", width: 30 },
    { key: "Absent", width: 20 },
    { key: "LeaveWithoutPay", width: 30 },
    ...deductionLabels.map(() => ({ width: 60 })),
    { key: "TotalDeductions", width: 30 },
    { key: "NetIncome", width: 30 },
  ];

  colWidths.forEach((col, index) => {
    worksheet.getColumn(index + 1).width = col.width;
  });

  const groupedData = data.reduce((acc, item) => {
    const costCenter = item.costCenter || "Unknown";

    if (!acc[costCenter]) {
      acc[costCenter] = {
        costCenter,
        companyName,
        salary: 0,
        ot1: 0,
        ot15: 0,
        ot2: 0,
        ot3: 0,
        totalOT: 0,
        totalShift: 0,
        additions: {},
        totalAdditional: 0,
        totalIncome: 0,
        companyPF: 0,
        userPF: 0,
        totalSSO: 0,
        totalTax: 0,
        earlyLateLeave: 0,
        absent: 0,
        leaveWithoutPay: 0,
        deductions: {},
        totalDeductions: 0,
        totalNet: 0,
      };
    }

    acc[costCenter].salary += item.salary || 0;
    acc[costCenter].ot1 += item.OT1 || 0;
    acc[costCenter].ot15 += item.OT15 || 0;
    acc[costCenter].ot2 += item.OT2 || 0;
    acc[costCenter].ot3 += item.OT3 || 0;
    acc[costCenter].totalOT += (item.OT1 || 0) + (item.OT15 || 0) + (item.OT2 || 0) + (item.OT3 || 0);
    acc[costCenter].totalShift += (item.shiftDay || 0) + (item.shiftEvening || 0) + (item.shiftNight || 0) + (item.shiftFee || 0);

    item.payrunDetailAddition.forEach((add) => {
      acc[costCenter].additions[add.name] =
        (acc[costCenter].additions[add.name] || 0) + add.value;
    });

    acc[costCenter].totalAdditional = Object.values(acc[costCenter].additions).reduce(
      (sum, value) => sum + value,
      0
    );

    acc[costCenter].totalIncome += item.totalEarnings || 0;
    acc[costCenter].companyPF += item.companyPF || 0;
    acc[costCenter].userPF += item.userPF || 0;
    acc[costCenter].totalSSO += item.totalSSO || 0;
    acc[costCenter].totalTax += item.totalTax || 0;
    acc[costCenter].earlyLateLeave += item.earlyLateLeave || 0;
    acc[costCenter].absent += item.absent || 0;
    acc[costCenter].leaveWithoutPay += item.leaveWithoutPay || 0;

    item.payrunDetailDeduction.forEach((ded) => {
      acc[costCenter].deductions[ded.name] =
        (acc[costCenter].deductions[ded.name] || 0) + ded.value;
    });

    acc[costCenter].totalDeductions = Object.values(acc[costCenter].deductions).reduce(
      (sum, value) => sum + value,
      0
    );

    acc[costCenter].totalNet += item.net || 0;

    return acc;
  }, {});

  Object.values(groupedData).forEach((costCenterData) => {
    const additionValues = additionLabels.map((label) =>
      costCenterData.additions[label]
        ? costCenterData.additions[label].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00"
    );

    const deductionValues = deductionLabels.map((label) =>
      costCenterData.deductions[label]
        ? costCenterData.deductions[label].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00"
    );

    const excelRow = worksheet.addRow([
      costCenterData.costCenter,
      costCenterData.companyName,
      costCenterData.salary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.ot1.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.ot15.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.ot2.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.ot3.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.totalOT.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.totalShift.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      ...additionValues,
      costCenterData.totalAdditional.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.totalIncome.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.companyPF.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.userPF.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.totalSSO.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.earlyLateLeave.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.absent.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.leaveWithoutPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      ...deductionValues,
      costCenterData.totalDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      costCenterData.totalNet.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ]);
    const contentStyle = {
      font: { size: 18, name: 'TH SarabunPSK', bold: false },
      alignment: { horizontal: "center", vertical: "middle" },
    };

    excelRow.eachCell((cell, colNumber) => {
      cell.style = contentStyle;
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      };
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${t("AppMenu.Payroll")}-${t("CostCenter")}(${t("Monthly")}).xlsx`;
  link.click();
  URL.revokeObjectURL(link.href);
};
