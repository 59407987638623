import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const allHoliday = (year, query) => {
  return httpClient.get(`/holiday/${year}`, { params: query });
};

const getHoliday = (query) => {
  return httpClient.get(`/holiday`, { params: query });
};

const getAllHolidayByEmployeeAndDateTime = (query) => {
  return httpClient.get(`/holiday-date-employee`, { params: query });
};

const postHoliday = (data) => {
  return httpClient.post(`/company/holiday/postHoliday`, data);
};

const putHoliday = (data) => {
  return httpClient.put(`/holiday`, data);
};

const deleteHoliday = (id, data) => {
  return httpClient.post(`/company/holiday/deleteHoliday/${id}`, data);
};

const uploadFileHoliday = (formData) => {
  return httpClient.post(`/holiday`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
   }) ;
};

const getAllHolidaysPattern = (query) => {
  return httpClient.get(`/holiday-pattern`, { params: query });
};

const getAllHolidayByIdEmployeesAndYear = (year) => {
  return httpClient.get(`/holiday-year-employee?year=${year}`);
};

export default {
  allHoliday,
  getHoliday,
  getAllHolidayByEmployeeAndDateTime,
  postHoliday,
  putHoliday,
  deleteHoliday,
  uploadFileHoliday,
  getAllHolidaysPattern,
  getAllHolidayByIdEmployeesAndYear
};
