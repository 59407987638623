import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  styled,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
  Chip,
  Tooltip,
  Container,
  Grid,
  Dialog,
  CardContent,
  DialogContent,
  DialogTitle,
  DialogActions,
  Autocomplete,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/employee";
import EditIcon from "@mui/icons-material/Edit";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { Redirect, useLocation } from "react-router-dom";
import {
  addKpiOrganizationCascade,
  deleteKpiOrganizationCascade,
  getKPIDate,
  getKpiOrganizationCascade,
} from "../../../../../actions/kpi";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledRoot = styled(Box)({
  backgroundColor: "#ffffff !important",
  paddingBottom: "150px",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto",
    padding: "8px",
  },
});

const AddNewButton = styled(Button)(({ disabled }) => ({
  color: disabled ? "#9e9e9e" : "#4535C1",
  backgroundColor: disabled ? "#EEEEEE" : "white",
  border: `1px solid ${disabled ? "#9e9e9e" : "#4535C1"}`,
  borderRadius: "16px",
  "&:hover": {
    backgroundColor: disabled ? "#EEEEEE" : "#f2f2f2",
  },
}));

const validationSchema = yup.object({
  weight: yup.number().required(),
  employees: yup.array().min(1),
});

const DialogAddEmployee = ({ open, handleClose, idKpiOrganization, employeeSelected }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: employeesList } = useSelector((state) => state.employees);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      employees: employeeSelected ? [employeeSelected] : [],
      weight: "",
    },
    resolver: yupResolver(validationSchema),
  });
  
  const handleAddEmployee = async (values) => {
    const formData = {
      weight: values.weight,
      idKpiOrganization: idKpiOrganization,
      employees: values.employees.map(emp => emp.idEmployees),
    }

    const res = await dispatch(addKpiOrganizationCascade(formData));
    if (res && (res.status === 200)) { 
      Swal.fire({
        title: 'สำเร็จ',
        text: 'สำเร็จแล้ว',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    } else {
      Swal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถบันทึกข้อมูลได้',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
    }
    dispatch(getKpiOrganizationCascade(idKpiOrganization));
    handleClose();
  };

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{"เพิ่มพนักงานเข้า KPI"}</DialogTitle>
      <form onSubmit={handleSubmit(handleAddEmployee)}>
        <DialogContent>
          <Stack gap={2}>
            <Controller
              name={"employees"}
              control={control}
              defaultValue={[]}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={employeesList || []}
                  getOptionLabel={(option) =>
                    `${option.firstname_TH} ${option.lastname_TH}`
                  }
                  onChange={(e, newValue) => field.onChange(newValue)}
                  renderOption={(props, option) => (
                    <Box {...props} key={option.idEmployees} display="flex" alignItems="center">
                      <Avatar
                        src={option.imageProfile}
                        alt={`${option.firstname_TH} ${option.lastname_TH}`}
                        sx={{
                          width: 24,
                          height: 24,
                          marginRight: 1,
                        }}
                      />
                      {`${option.firstname_TH} ${option.lastname_TH}`}
                    </Box>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        avatar={<Avatar src={option.imageProfile} />}
                        label={`${option.firstname_TH} ${option.lastname_TH}`}
                        key={option.idEmployees}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      placeholder={`${t("SelectEmp")}`}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                  filterSelectedOptions
                  disabled={Boolean(employeeSelected)}
                />
              )}
            />
            <Controller
              name="weight"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth error={Boolean(fieldState.error)}>
                  <InputLabel>{t("Weight")}</InputLabel>
                  <Select {...field} label={t("Weight")}>
                    {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldState.error && (
                    <FormHelperText>{fieldState.error.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonBlue type="button" onClick={handleClose}>
            {t("Close")}
          </ButtonBlue>
          <ButtonBlue
            type="submit"
            variant={"contained"}
            disabled={isSubmitting}
          >
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
}
const DialogDeleteEmployee = ({ open, handleClose, idKpiOrganization, employeeSelected }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteEmployee = async () => {
    const res = await dispatch(deleteKpiOrganizationCascade(employeeSelected.idKpiOrganizationEmployees))
    if (res && (res.status === 200)) { 
      Swal.fire({
        title: 'สำเร็จ',
        text: 'สำเร็จแล้ว',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    } else {
      Swal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถบันทึกข้อมูลได้',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
    }
    dispatch(getKpiOrganizationCascade(idKpiOrganization));
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"ลบพนักงานออกจาก KPI"}</DialogTitle>
      <DialogContent>
        {`${employeeSelected.firstname_TH} ${employeeSelected.lastname_TH}`}
      </DialogContent>
      <DialogActions>
        <ButtonBlue type="button" onClick={handleClose}>
          {t("Close")}
        </ButtonBlue>
        <ButtonBlue
          variant={"contained"}
          onClick={handleDeleteEmployee}
        >
          {t("Confirm")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  )
}

const isDateInRange = (dateRanges, currentDate) => {
  return dateRanges.some((dateRange) =>
    currentDate.startOf('day').isBetween(
      dayjs(dateRange.startDate).startOf('day'),
      dayjs(dateRange.endDate).endOf('day'),
      null,
      '[]'
    )
  );
};

const KpiOrganizationCascade = () => {
  const { state } = useLocation();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { result: kpiCascade } = useSelector((state) => state.kpi);
  const { result: kpiDate } = useSelector((state) => state.kpiDate);

  const [searchEmployee, setSearchEmployee] = useState(null);
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [openDeleteEmployee, setOpenDeleteEmployee] = useState(false);

  const handleOpenAddEmployee = (item) => {
    if (item) {
      setEmployeeSelected(item);
    }
    setOpenAddEmployee(true);
  };

  const handleCloseAddEmployee = () => {
    setEmployeeSelected(null);
    setOpenAddEmployee(false);
  };

  const handleOpenDeleteEmployee = (item) => {
    setEmployeeSelected(item);
    setOpenDeleteEmployee(true);
  };

  const handleCloseDeleteEmployee = () => {
    setEmployeeSelected(null);
    setOpenDeleteEmployee(false);
  };

  useEffect(() => {
    if (!state) {
      return <Redirect to="/kpi/organization" />;
    }
  }, []);

  useEffect(() => {
    if (state.kpi) {
      dispatch(getAllEmployees());
      dispatch(getKpiOrganizationCascade(state.kpi.idKpiOrganization));
      dispatch(getKPIDate({ idCompany: state.kpi.idCompany }))
    }
  }, []);

  const cascadeDates = kpiDate 
    ? kpiDate.filter(dateItem => dateItem.type === "cascade")
    : [];
  const isCascadeDateInRange = isDateInRange(cascadeDates, dayjs());

  return (
    <StyledRoot className="page">
      {kpiCascade && (
        <Container>
          <GridStyled
            container
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="h6"
                className="kpi"
                sx={{ ml: 1, fontSize: "30px" }}
              >
                KPI Organization Cascade
              </Typography>
            </Box>
          </GridStyled>
          <Card
            sx={{
              marginBottom: "16px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "16px",
              position: "relative",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems="center"
                gap={2}
              >
                <Box
                  sx={{
                    backgroundColor: "#F4F6FA",
                    borderRadius: "16px",
                    padding: "16px 24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    color="#4535C1"
                    fontSize="14px"
                    fontWeight="400"
                    textAlign="center"
                  >
                    {t("Weight")}
                    <Typography
                      variant="h6"
                      component="div"
                      fontSize="28px"
                      color="#4535C1"
                      fontWeight="600"
                    >
                      {`${Math.round(state.kpi.weight)}%`}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  mb={{ xs: 2, sm: 0 }}
                >
                  <Box flex={2} mr={2}>
                    <Typography variant="body2" style={{ color: "#718EBF", fontWeight: 500 }}>
                      {t("KPIName")}:
                    </Typography>
                    <Typography>{state.kpi.kpiName}</Typography>
                  </Box>
                  <Box flex={1.2} mr={2}>
                    <Typography variant="body2" style={{ color: "#718EBF", fontWeight: 500 }}>
                      {t("Date")}:
                    </Typography>
                    <Typography>
                      {`${dayjs(state.kpi.startDate).format("DD/MM/YYYY")} -
                      ${dayjs(state.kpi.endDate).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
          {isCascadeDateInRange && (
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px" }}>
              <AddNewButton variant="outlined" onClick={() => handleOpenAddEmployee(null)}>
                {t("AddEmployee")}
              </AddNewButton>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} aria-label="manager table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("FullName")}</TableCell>
                  <TableCell align="center">{t("Division")}</TableCell>
                  <TableCell align="center">{t("Department")}</TableCell>
                  <TableCell align="center">{t("Section")}</TableCell>
                  <TableCell align="center">{t("Position")}</TableCell>
                  <TableCell align="center">{`${t("Cascade")} %`}</TableCell>
                  {isCascadeDateInRange && (
                    <TableCell align="center">{"จัดการ"}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {kpiCascade && kpiCascade.length > 0 ? (
                  kpiCascade
                    .filter((item) =>
                      searchEmployee
                        ? searchEmployee.idEmployees === item.idEmployees
                        : true
                    )
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            gap={1}
                          >
                            <Avatar
                              alt={`${item.firstname_TH} ${item.lastname_TH}`}
                              src={item.imageURL}
                              sx={{ width: 40, height: 40 }}
                            />
                            <Typography variant="body1">
                              {`${item.firstname_TH} ${item.lastname_TH}`}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center" sx={{ minWidth: 80 }}>
                          {item.divisionName || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ minWidth: 80 }}>
                          {item.departmentName || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ minWidth: 80 }}>
                          {item.sectionName || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ minWidth: 80 }}>
                          {item.positionName || "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body1">{item.weight}</Typography>
                        </TableCell>
                        {isCascadeDateInRange && (
                          <TableCell align="center">
                            <Tooltip title={t("Edit")}>
                              <IconButton
                                onClick={() => handleOpenAddEmployee(item)}
                                size="small"
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Delete")}>
                              <IconButton
                                onClick={() => handleOpenDeleteEmployee(item)}
                                size="small"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      {t("NoData")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
      {openAddEmployee && (
        <DialogAddEmployee
          open={openAddEmployee}
          handleClose={handleCloseAddEmployee}
          idKpiOrganization={state.kpi.idKpiOrganization}
          employeeSelected={employeeSelected}
        />
      )}
      {openDeleteEmployee && (
        <DialogDeleteEmployee
          open={openDeleteEmployee}
          handleClose={handleCloseDeleteEmployee}
          idKpiOrganization={state.kpi.idKpiOrganization}
          employeeSelected={employeeSelected}
        />
      )}
    </StyledRoot>
  );
};

export default KpiOrganizationCascade;
