import React, { useState } from "react";
import { styled } from "@mui/material";
import SnackBarCustom from "../../../shared/snackbarCustom";
import Dialog from "./Dialog";
import OnBoardingHistory from "./History";
import OnBoardingPending from "./Pending";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const OnBoarding = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [formData, setFormData] = useState(null);
  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleOpenDialog = (data) => {
    setOpenDialog(true);
    setFormData(data);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setFormData(null);
  };

  const handleOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  return (
    <>
      <StyledWrapBranch>
        {openHistory ? (
          <OnBoardingHistory
            handleCloseHistory={handleCloseHistory}
            handleOpenDialog={handleOpenDialog}
          />
        ) : (
          <OnBoardingPending
            handleOpenHistory={handleOpenHistory}
            handleOpenDialog={handleOpenDialog}
          />
        )}
      </StyledWrapBranch>

      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />
      {openDialog && (
        <Dialog
          open={openDialog}
          data={formData}
          handleClose={handleClose}
          setSnackBarConfig={setSnackBarConfig}
        />
      )}
    </>
  );
};

export default OnBoarding;
