import React from "react";
import {
  Typography,
  IconButton,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Chip,
  Stack,
} from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import StarIcon from "@mui/icons-material/Star";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const KpiCard = ({ kpi, onView }) => {
  const { t } = useTranslation();
  const rating = Math.min(5, Math.max(0, Math.round(kpi.rating)));
  const progress = kpi.progress || 0;

  return (
    <Card
      sx={{
        marginBottom: "16px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="center"
          gap={2}
        >
          <Box
            sx={{
              backgroundColor: "#F4F6FA",
              borderRadius: "16px",
              padding: "16px 24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              color="#4535C1"
              fontSize="14px"
              fontWeight="400"
              textAlign="center"
            >
              {t("Weight")}
              <br />
              <Typography
                variant="h6"
                component="div"
                fontSize="28px"
                color="#4535C1"
                fontWeight="600"
              >
                {`${Math.round(kpi.weight)}%`}
              </Typography>
            </Typography>
          </Box>
          <Box
            flex={1}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems="center"
            mb={{ xs: 2, sm: 0 }}
          >
            <Box flex={2} mr={2}>
              <Typography variant="body2">
                <span style={{ color: "#718EBF", fontWeight: 500 }}>
                  {t("KPIName")}:{" "}
                </span>
                <Typography fontSize={16}>{kpi.kpiName}</Typography>
              </Typography>
            </Box>
            <Box flex={1.2} mr={2}>
              <Typography variant="body2">
                <span style={{ color: "#718EBF", fontWeight: 500 }}>
                  {t("Date")}:{" "}
                </span>
                <Typography fontSize={16}>
                  {`${dayjs(kpi.startDate).format("DD/MM/YYYY")} - ${dayjs(
                    kpi.endDate
                  ).format("DD/MM/YYYY")}`}
                </Typography>
              </Typography>
            </Box>
            <Stack
              display="flex"
              flexDirection="column"
              gap={1}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="#718EBF"
                  fontWeight={500}
                  mr={1}
                >
                  {t("Progress")}:
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    width: { xs: "100px", sm: "150px" },
                    height: "8px",
                    borderRadius: "5px",
                    backgroundColor: "#FFCBCB",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#FF4E88",
                    },
                  }}
                />
                <Typography ml={1} color="#FF4E88" fontWeight={600}>
                  {progress}%
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="#718EBF"
                  fontWeight={500}
                  mr={1}
                >
                  {t("Rating")}:
                </Typography>
                <Box display="flex" alignItems="center">
                  {[...Array(5)].map((_, index) => (
                    <StarIcon
                      key={index}
                      sx={{
                        fontSize: "30px",
                        color: index < rating ? "#FFC403" : "#E0E0E0",
                        marginRight: index < 4 ? "4px" : 0,
                      }}
                    />
                  ))}
                  <Typography fontWeight={600} ml={1} color="#FFC403">
                    {rating ? rating : null}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography
                  variant="body2"
                  color="#718EBF"
                  fontWeight={500}
                  mr={1}
                >
                  {t("Status")}:
                </Typography>
                {kpi.evaluate === null ? (
                  <Chip label={t("HaveNotBeenEvaluated")} />
                ) : kpi.evaluate === 1 ? (
                  <Chip sx={{ backgroundColor: "#50B498", color: "#ffffff" }} label={t("Success")} />
                ) : (
                  <Chip sx={{ backgroundColor: "#E46A76", color: "#ffffff" }} label={t("Failure")}/>
                )}
              </Box>
            </Stack>
          </Box>
          <div>
            <IconButton sx={{ color: "#604CC3" }} onClick={() => onView(kpi)}>
              <EventNoteIcon />
            </IconButton>
            <ButtonBlue
              variant="outlined"
              component={Link}
              to={{
                pathname: "/kpi/organization/update/user",
                state: { planList: kpi }
              }}
            >
              {t("Progress")}
            </ButtonBlue>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default KpiCard;
