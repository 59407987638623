import React from "react";
import { styled } from "@mui/material";
import loading from "../../assets/social-media.gif";

const StyledLoading = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh", // Ensures full viewport height
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex:2,
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Grey background with opacity
});

const LoadingFullPage = () => {
  return (
    <StyledLoading>
      <img width="80" alt="loading" src={loading} />
    </StyledLoading>
  )
}

export default LoadingFullPage;
