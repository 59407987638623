import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  CardContent,
  Typography,
  Container,
  TextField,
  Divider,
  Box,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import LateAbsentPanel from "./late-absent";
import GeneralPanel from "./general";
import OtShiftPanel from "./ot-shift-diligence";

import { getPayrollSetting } from "../../../../../actions/paytypes";
import { getAffiliateOrganization } from "../../../../../actions/company";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 16,
  },
});

const StyledCardContent = styled(CardContent)({
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  "& .sub-header-1": {
    fontWeight: 500,
    fontSize: 18,
    textDecoration: "underline",
  },
  "& .sub-header": {
    fontWeight: 500,
  },
  "& .header-item": {
    fontSize: "1.25rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  "& .wrap-item-data": {
    marginLeft: 16,
    //marginBottom: 8,
  },
  "& .wrap-check": {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      paddingLeft: 0,
    },
  },
  "& .item-name": {
    fontWeight: 600,
    marginBottom: 4,
  },
  "& .wrap-label-switch": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .custom-date-payment": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    alignItems: "center",
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "200px",
  border: 0,
  "& .MuiFilledInput-root": {
    padding: "0px 12px",
    borderRadius: 8,
    height: 56,
    "& .MuiInputAdornment-root": {
      width: 32,
      
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledAutocompleteCompany = styled(Autocomplete)({
  width: "350px",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const PayrollSettingPage = () => {
  const { t, i18n } = useTranslation();
  const options = [
    { id: 2, label: `${t("Monthly")}` },
    { id: 5, label: `${t("Daily")}` },
  ];

  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const [isPayroll, setIsPayroll] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(options[0]);
  const [companyList, setCompanyList] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showHide, setShowHide] = useState({
    general: "hide",
    lateAbsent: "hide",
    otShift: "hide",
  });

  useEffect(() => {
    //dispatch(getPayrollSetting());
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let temp = affiliateOrganizationList.map((x) => ({
        idCompany: x.idCompany,
        companyName: x.companyName,
        companyName_EN: x.companyName_EN,
      }));

      setSelectedCompany(temp[0]);
      setCompanyList(temp);
      dispatch(getPayrollSetting({ idCompany: temp[0].idCompany, yearSetting: dayjs().format("YYYY") }));
    }
  }, [affiliateOrganizationList]);

  return (
    <Fragment>
      {userProfile && (
        <Fragment>
          {isPayroll === false ? (
            <Route>
              <Redirect to="/unauthorized" />
            </Route>
          ) : (
            <StyledRoot className={`page`}>
              <Container maxWidth="lg">
                <div style={{ marginBottom: 8 }}>
                  <Typography variant="h4" style={{ paddingTop: 8 }}>
                    {t("PayrollManagementSettings")}
                  </Typography>
                </div>
                <div style={{marginBottom: 16, display: "flex" }}>
                  <Box style={{ marginRight: 16 }}>
                    <StyledAutocomplete
                      options={options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={""}
                        />
                      )}
                      value={selectedPaymentType}
                      onChange={(event, newValue) => {
                        setSelectedPaymentType(newValue);
                        
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                      popupIcon={<i class="fa-light fa-chevron-down"></i>}
                      PopperComponent={StyledPopper}
                    />
                  </Box>
                  <Box>
                    <StyledAutocompleteCompany
                      options={affiliateOrganizationList ? companyList : []}
                      onChange={(event, newValue) => {
                        console.log("newValue Company:", newValue);
                        setSelectedCompany(newValue);
                        dispatch(getPayrollSetting({ idCompany: newValue.idCompany }))
                      }}
                      value={selectedCompany}
                      disableClearable={true}
                      popupIcon={<i class="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) =>
                        i18n.language === "th"
                          ? option.companyName
                          : option.companyName_EN
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={`${t("AllCompany")}`}
                        />
                      )}
                      PopperComponent={StyledPopper}
                      noOptionsText={`${t("NoData")}`}
                    />
                  </Box>
                </div>
                <StyledCard style={{ marginBottom: 16 }}>
                  <StyledCardContent>
                    <div className="wrap-header">
                      <Typography className="header-item" gutterBottom>
                        {t("GeneralSettings")}
                      </Typography>
                      <div>
                        <ButtonBlue
                          onClick={() => {
                            setShowHide({
                              ...showHide,
                              general:
                                showHide.general === "hide" ? "show" : "hide",
                            });
                          }}
                        >
                          {showHide.general === "hide"
                            ? t("Display")
                            : t("Hide")}
                        </ButtonBlue>
                      </div>
                    </div>
                    {showHide.general === "show" && <GeneralPanel selectedPaymentType={selectedPaymentType}/>}
                  </StyledCardContent>
                </StyledCard>
                <StyledCard style={{ marginBottom: 16 }}>
                  <StyledCardContent>
                    <div className="wrap-header">
                      <Typography className="header-item" gutterBottom>
                        {t("LateArrival-Absence")}
                      </Typography>
                      <div>
                        <ButtonBlue
                          onClick={() => {
                            setShowHide({
                              ...showHide,
                              lateAbsent:
                                showHide.lateAbsent === "hide"
                                  ? "show"
                                  : "hide",
                            });
                          }}
                        >
                          {showHide.lateAbsent === "hide"
                            ? t("Display")
                            : t("Hide")}
                        </ButtonBlue>
                      </div>
                    </div>
                    {showHide.lateAbsent === "show" && <LateAbsentPanel />}
                  </StyledCardContent>
                </StyledCard>
                <StyledCard style={{ marginBottom: 16 }}>
                  <StyledCardContent>
                    <div className="wrap-header">
                      <Typography className="header-item" gutterBottom>
                        {t("OvertimeSetting-ShiftFee")}
                      </Typography>
                      <div>
                        <ButtonBlue
                          onClick={() => {
                            setShowHide({
                              ...showHide,
                              otShift:
                                showHide.otShift === "hide" ? "show" : "hide",
                            });
                          }}
                        >
                          {showHide.otShift === "hide"
                            ? t("Display")
                            : t("Hide")}
                        </ButtonBlue>
                      </div>
                    </div>
                    {showHide.otShift === "show" && <OtShiftPanel />}
                  </StyledCardContent>
                </StyledCard>
              </Container>
            </StyledRoot>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PayrollSettingPage;
