import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Stack,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getManpowerPlanning } from "../../../../actions/organizationStructure";
import Row from "./Row";
import DrawerEdit from "./DrawerEdit";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { ManpowerXlsx } from "./manpowerXlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getAffiliateOrganization } from "../../../../actions/company";
import { getUserCompany } from "../../../../utils/userData";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF!important",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledTableContainer = styled(TableContainer)({
  position: "relative",
  overflowX: "auto",
  "& table": {
    borderCollapse: "separate",
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      padding: "8px 16px",
    },
  },
  "& .stickyColumn-left": {
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  "& .stickyColumn-right": {
    position: "sticky",
    right: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  "&.shadowEffect-left .stickyColumn-left": {
    "&:after": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: "-1px",
      width: "30px",
      transform: "translateX(100%)",
      transition: "box-shadow .3s",
      content: '""',
      pointerEvents: "none",
      boxShadow: "inset 10px 0 8px -8px rgba(5,5,5,.06)",
    },
  },
  "&.shadowEffect-right .stickyColumn-right": {
    "&:after": {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: "-1px",
      width: "30px",
      transform: "translateX(-100%)",
      transition: "box-shadow .3s",
      content: '""',
      pointerEvents: "none",
      boxShadow: "inset -10px 0 8px -8px rgba(5,5,5,.06)",
    },
  },
});

const Manpower = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: manpowerPlanningList } = useSelector((state) => state.manpowerPlanning);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [selectedCompany, setSelectedCompany] = useState(null);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const onDrawerOpen = (data) => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: { ...data },
    }));
  };

  const onDrawerClose = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const onSelectCompany = (value) => {
    if(value){
      // setIsFetching(true)
      dispatch(getManpowerPlanning({idCompany: value.idCompany})).then(res => {
        // setIsFetching(false)
      });
    }
    setSelectedCompany({...value})
  }

  useEffect(() => {
    // setIsFetching(true)
    dispatch(getAffiliateOrganization()).then(res => {
      if(res.data.length > 0){
        setSelectedCompany(res.data[0]);
        dispatch(getManpowerPlanning({
          idCompany: res.data[0].idCompany
        })).then(res => {
          // setIsFetching(false)
        });
      }
    });
  }, []);
  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
          sx={{ mb: 2 }}
        >
          <Typography variant="h4">{t("ManPower")}</Typography>
        </Stack>
        <Box marginBottom="24px" display="flex" alignItems="center" justifyContent="space-between">
          <Box maxWidth="500px" width="100%">
            <Autocomplete
              options={affiliateOrganizationList? affiliateOrganizationList: []}
              getOptionLabel={(option) => `${getUserCompany(option)}`}
              isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.idCompany}>
                  <Box>
                    <Typography>{getUserCompany(option)}</Typography>
                  </Box>
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  placeholder={t("SelectCompany")}
                />
              )}
              value={selectedCompany}
              onChange={(_, value) => {
                onSelectCompany(value)
                // setSelectedCompany(value);
              }}
              noOptionsText={t("NoData")}
              disableClearable
            />
          </Box>
          <ButtonBlue
            variant="outlined"
            onClick={() => ManpowerXlsx(t,manpowerPlanningList)}
            sx={{ height: 40 }}
            startIcon={<FileDownloadIcon />}
          >
            {t("Download")}
          </ButtonBlue>
        </Box>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 200 }}>{t("FullName")}</TableCell>
                <TableCell style={{ minWidth: 200 }}>{t("Level")}</TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  <Typography textAlign="center">
                    {t("CurrentManPower")}/{t("ManPower")}
                  </Typography>
                </TableCell>
                <TableCell style={{ minWidth: 200 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manpowerPlanningList.map((item, index) => (
                <Row
                  key={`${item.level}-${item.idOrganization}`}
                  data={item}
                  onDrawerOpen={onDrawerOpen}
                  onDrawerClose={onDrawerClose}
                />
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Container>

      <DrawerEdit drawerConfig={drawerConfig} onClose={onDrawerClose} isReadOnly={userProfile && userProfile.readOnly} />
    </StyledRoot>
  );
};

export default Manpower;
