import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { getAllEmployees } from "../../../../actions/employee";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import utils from "../../../../utils";

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  width: "330px !important",

  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) =>
    option.firstname_EN + option.lastname_EN + option.positionName,
});

const filterOptionsName = createFilterOptions({
  stringify: (option) =>
    option.firstname_EN + option.lastname_EN + option.employeeID,
});

const filterOptionsTH = createFilterOptions({
  stringify: (option) =>
    option.firstname_TH + option.lastname_TH + option.positionName_TH,
});

const filterOptionsNameTH = createFilterOptions({
  stringify: (option) =>
    option.firstname_TH + option.lastname_TH + option.employeeID,
});

const SelectEmployees = (props) => {
  const { t, i18n } = useTranslation();
  let { handleChange, position, mode, value, selectedCompany, isShowTerminate } = props;
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.employees);
  const { result: userProfile } = useSelector((state) => state.userProfile);
 
  const thaiLanguage = i18n.resolvedLanguage === "th";
  const filterOption = () => {
    if (position) {
      if (thaiLanguage) {
        return filterOptionsTH;
      } else {
        return filterOptions;
      }
    } else {
      if (thaiLanguage) {
        return filterOptionsNameTH;
      } else {
        return filterOptionsName;
      }
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAllEmployees(mode ? mode : false, selectedCompany.idCompany));
    } else {
      dispatch(getAllEmployees(mode ? mode : false));
    }
    handleChange(null);
  }, [selectedCompany]);

  let renderOption = () => {
    let result = [];
    employees.map((item) => {
      if (isShowTerminate || !item.isTerminate) {
        if (userProfile.isFillIn === 1) {
          if(item.idDepartment === userProfile.idDepartment){
            result.push({...item, statusTerminate : item.isTerminate ? `${t("AppMenu.Employees")}${t("EmployeeOut")}` : `${t("AppMenu.Employees")}${t("Normal")}`});
          }
        } else {
          result.push({...item, statusTerminate : item.isTerminate ? `${t("AppMenu.Employees")}${t("EmployeeOut")}` : `${t("AppMenu.Employees")}${t("Normal")}`});
        }
      }
    });

    if(!isShowTerminate){
      result = result.sort((a, b) => a.positionName.localeCompare(b.positionName))
    }else{
      result = result.sort((a, b) => a.statusTerminate.localeCompare(b.statusTerminate))
    }

    return result;
  };

  return (
    <StyledBoxSearch>
      <Typography className="label" color="text.third">
        {t("SearchEmp")}
      </Typography>
      <StyledAutocomplete
        options={
          employees
            ? renderOption()
            : []
        }
        value={value}
        onChange={(_, newValue) => {
          handleChange(newValue);
        }}
        filterOptions={filterOption()}
        groupBy={isShowTerminate ? (option) => option.statusTerminate : position ? (option) => option.positionName : null}
        popupIcon={<i classIcon="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) =>
          thaiLanguage
            ? `${option.firstname_TH} ${option.lastname_TH}`
            : `${option.firstname_EN} ${option.lastname_EN}`
        }
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.idEmployees}>
            <Box>
              <Typography>
                {thaiLanguage
                ? `${option.firstname_TH} ${option.lastname_TH}`
                : `${option.firstname_EN} ${option.lastname_EN}`}
              </Typography>
              <Typography color="text.secondary" fontSize="12px">{option.employeeID}</Typography>
            </Box>
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder={`${t("SelectEmp")}`}
            InputProps={{ ...params.InputProps, endAdornment: null }}
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={`${t("NoData")}`}
        isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
      />
    </StyledBoxSearch>
  );
};

export default SelectEmployees;
