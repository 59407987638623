import React, { Fragment, useEffect, useState } from "react";
import {
    Autocomplete, Box, Container,
    Grid, styled, TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography, Stack,
    Menu, MenuItem, ListItem,
    List, Paper, Divider
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllEmployees, getAllEmployeesByIdCompany, getEmployeesTaxDetails } from "../../../../actions/employee";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import dayjs from "dayjs";
import { getDataWritePDF } from "../../../../actions/user";
import DialogVisaDate from "../../user/my-document/dialogVisaDate";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { PND91PdfFile } from "../../user/governmentReport/filePDFgeneration.js/PND91PdfFile";
import { Tawi50PDFfile } from "../../user/governmentReport/filePDFgeneration.js/50tawiPDFfile";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import AlertResponse from "../../shared/general/AlertResponse";
import { getUserFullName } from "../../../../utils/userData";
import { getallSmartDocumentsById } from "../../../../actions/document";
import { handleDownloadPdf } from "../smart-documents/CreatePdf/utils";
import CardItem from "./component/CardItem";

const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
});

const StyledAutoComplete = styled(Autocomplete)({
    "& .MuiFilledInput-root": {
        padding: "13.5px 14px",
        paddingRight: "32px",
        "& input": {
            padding: 0
        }
    }
});

const DividerStyled = styled(Divider)({
    margin: "8px 0",
});

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&:after": {
            border: "none",
        },
        "&:before": {
            border: "none",
            borderBottomStyle: "none !important",
        }
    },
});

const DownloadFileButton = ({ type, year, disabled, handleOpenAlertError, idEmployees }) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [openDateDialog, setOpenDateDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const fillParagraph = (text, font, fontSize, maxWidth) => {
        var paragraphs = text.split("\n");
        for (let index = 0; index < paragraphs.length; index++) {
            var paragraph = paragraphs[index];
            if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
                var words = paragraph.split(" ");
                var newParagraph = [];
                var i = 0;
                newParagraph[i] = [];
                for (let k = 0; k < words.length; k++) {
                    var word = words[k];
                    newParagraph[i].push(word);
                    if (
                        font.widthOfTextAtSize(newParagraph[i].join(" "), fontSize) >
                        maxWidth
                    ) {
                        newParagraph[i].splice(-1);
                        i = i + 1;
                        newParagraph[i] = [];
                        newParagraph[i].push(word);
                    }
                }
                paragraphs[index] = newParagraph.map((p) => p.join(" ")).join("\n");
            }
        }
        return paragraphs.join("\n");
    };

    const renderPDFThai = async (type, data) => {
        const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
        const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
        let pdfDoc = await PDFDocument.load(existingPdfBytes);
        let pages = pdfDoc.getPages();
        const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
        pdfDoc.registerFontkit(fontkit);

        const font = await pdfDoc.embedFont(fontBytes);
        const widthSize = 200;
        let imgSource = "";
        let sigImg = "";
        let signatureName = "";
        let signaturePosition = "";

        data.map(async (page, index) => {
            const currentPage = pages[index];
            await Promise.all(
                page.map(async (item) => {
                    if (item.text) {
                        let xContainer = 0;
                        if (item.containerSize !== undefined) {
                            const textWidth = font.widthOfTextAtSize(item.text, item.size);
                            xContainer = (widthSize - textWidth) / 2;
                        }

                        currentPage.drawText(
                            type === "PND91"
                                ? item.text.replace(/\u200B/g, "").trim()
                                : item.text,
                            {
                                x: xContainer === 0 ? item.x : item.x + xContainer,
                                y: item.y,
                                ...(item.font ? { font: font } : {}),
                                ...(item.size ? { size: item.size } : {}),
                                ...(item.maxWidth ? { maxWidth: item.maxWidth } : {}),
                                ...(item.fontWeight ? { fontWeight: item.fontWeight } : {}),
                            }
                        );
                    }

                    if (item.imageLogo !== undefined) {
                        imgSource = item.imageLogo;
                    }

                    if (item.signatureImage !== undefined) {
                        sigImg = item.signatureImage;
                    }

                    if (item.certificateSignatureName !== undefined) {
                        signatureName = item.certificateSignatureName;
                    }

                    if (item.certificateSignaturePosition !== undefined) {
                        signaturePosition = item.certificateSignaturePosition;
                    }
                })
            );
        });

        if (imgSource) {
            const pngImage = await pdfDoc.embedPng(imgSource);
            const pngDims = pngImage.scale(0.2);
            pages[0].drawImage(pngImage, {
                x: 40,
                y: 700,
                width: pngDims.width,
                height: pngDims.height,
            });
        }

        if (sigImg) {
            const pngImage = await pdfDoc.embedPng(sigImg);
            const pngDims = pngImage.scale(0.2);
            pages[0].drawImage(pngImage, {
                x: 310 + (150 - pngDims.width) / 2,
                y: 400,
                width: pngDims.width,
                height: pngDims.height,
            });
        }

        if (signatureName) {
            const textWidth = font.widthOfTextAtSize(signatureName, 16);
            pages[0].drawText(signatureName, {
                x: 310 + (150 - textWidth) / 2,
                y: 380,
                size: 16,
                font: font,
            });
        }

        if (signaturePosition) {
            const textWidth = font.widthOfTextAtSize(signaturePosition, 16);
            pages[0].drawText(signaturePosition, {
                x: 310 + (150 - textWidth) / 2,
                y: 360,
                size: 16,
                font: font,
            });
        }

        const pdfBytes = await pdfDoc.save();
        const bytes = new Uint8Array(pdfBytes);
        const blob = new Blob([bytes], { type: "application/pdf" });
        const docUrl = URL.createObjectURL(blob);
        window.open(docUrl, "_blank");
    };

    const renderPDFEnglish = async (type, data) => {
        const widthSize = 200;
        let diffSignatureY = 0;
        if (type === "businessVisa" || type === "touristVisaNoDate") {
            diffSignatureY = 150;
        } else if (type === "certificateEmp" || type === "certificateSalary" || type === "resignationCertificate") {
            diffSignatureY = 80;
        }

        const url = `${process.env.REACT_APP_API_URL}files/EmptyPDF.pdf`;
        const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
        let pdfDoc = await PDFDocument.load(existingPdfBytes);
        let pages = pdfDoc.getPages();
        const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
        pdfDoc.registerFontkit(fontkit);

        const urlFontBold = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew Bold.ttf`;
        const fontBoldBytes = await fetch(urlFontBold).then((res) =>
            res.arrayBuffer()
        );

        const font = await pdfDoc.embedFont(fontBytes);
        const fontBold = await pdfDoc.embedFont(fontBoldBytes);
        let imgSource = "";
        let sigImg = "";
        let signatureName = "";
        let signaturePosition = "";

        data.map(async (page, index) => {
            const currentPage = pages[index];
            await Promise.all(
                page.map(async (item) => {
                    if (item.text) {
                        let xContainer = 0;
                        if (item.containerSize !== undefined) {
                            const textWidth = font.widthOfTextAtSize(item.text, item.size);
                            xContainer = (widthSize - textWidth) / 2;
                        }

                        if (item.multiline !== true) {
                            currentPage.drawText(
                                type === "PND91"
                                    ? item.text.replace(/\u200B/g, "").trim()
                                    : item.text,
                                {
                                    x: xContainer === 0 ? item.x : item.x + xContainer,
                                    y: item.y,
                                    ...(item.font ? { font: font } : {}),
                                    ...(item.size ? { size: item.size } : {}),
                                    ...(item.fontWeight ? { font: fontBold } : {}),
                                    ...(item.maxWidth ? { maxWidth: item.maxWidth } : {}),
                                    ...(item.fontWeight ? { fontWeight: item.fontWeight } : {}),
                                }
                            );
                        } else {
                            var longString = [item.text[0], item.text[1], item.text[2]].join(
                                "\n\n"
                            );

                            currentPage.drawText(
                                fillParagraph(longString, font, item.size, 520),
                                {
                                    x: item.x,
                                    y: item.y,
                                    ...(item.font ? { font: font } : {}),
                                    ...(item.size ? { size: item.size } : {}),
                                }
                            );
                        }
                    }

                    if (item.imageLogo !== undefined) {
                        imgSource = item.imageLogo;
                    }

                    if (item.signatureImage !== undefined) {
                        sigImg = item.signatureImage;
                    }

                    if (item.certificateSignatureName !== undefined) {
                        signatureName = item.certificateSignatureName;
                    }

                    if (item.certificateSignaturePosition !== undefined) {
                        signaturePosition = item.certificateSignaturePosition;
                    }
                })
            );
        });

        if (imgSource) {
            const pngImage = await pdfDoc.embedPng(imgSource);
            const pngDims = pngImage.scale(0.2);
            pages[0].drawImage(pngImage, {
                x: 50,
                y: 700,
                width: pngDims.width,
                height: pngDims.height,
            });
        }

        if (sigImg) {
            const pngImage = await pdfDoc.embedPng(sigImg);
            const pngDims = pngImage.scale(0.2);
            pages[0].drawImage(pngImage, {
                x: 50,
                y: 400 - diffSignatureY,
                width: pngDims.width,
                height: pngDims.height,
            });
        }

        if (signatureName) {
            pages[0].drawText(signatureName, {
                x: 50,
                y: 380 - diffSignatureY,
                size: 18,
                font: font,
            });
        }

        if (signaturePosition) {
            pages[0].drawText(signaturePosition, {
                x: 50,
                y: 360 - diffSignatureY,
                size: 18,
                font: font,
            });
        }

        const pdfBytes = await pdfDoc.save();
        const bytes = new Uint8Array(pdfBytes);
        const blob = new Blob([bytes], { type: "application/pdf" });
        const docUrl = URL.createObjectURL(blob);
        window.open(docUrl, "_blank");
    };

    const handleOnCertEmp = async (rangeDate, language) => {
        const formData = {
            startDate: rangeDate ? dayjs(rangeDate.startDate).format("YYYY/MM/DD") : null,
            endDate: rangeDate ? dayjs(rangeDate && rangeDate.endDate).format("YYYY/MM/DD") : null,
            idEmployees: idEmployees
        };

        const res = await getDataWritePDF(`${type}${(language && language !== "th") ? language.toUpperCase() : ""}`, year, formData);
        if (res.status === 200 && res.data.data && res.data.data.length > 0) {
            if (
                (
                    (
                        type === "certificateSalary" ||
                        type === "certificateEmp" ||
                        type === "resignationCertificate"
                    ) && language === "th"
                ) ||
                type === "50tawi" ||
                type === "PND91"
            ) {
                renderPDFThai(type, res.data.data);
            } else {
                renderPDFEnglish(type, res.data.data);
            }
        } else if (res.status === 404) {
            handleOpenAlertError(`${t("IncompleteInformation")}`);
        }
    };

    return (
        <Fragment>
            <ButtonBlue
                variant="contained"
                className="downloadButton"
                onClick={(event) => {
                    if (type === "businessVisa" || type === "touristVisaNoDate") {
                        setOpenDateDialog(true);
                    } else if (
                        type === "certificateSalary" ||
                        type === "certificateEmp" ||
                        type === "resignationCertificate"
                    ) {
                        setAnchorEl(event.currentTarget);
                    } else {
                        handleOnCertEmp();
                    }
                }}
                disabled={disabled}
            >
                {`${t("Download")}`}
            </ButtonBlue>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    onClick={() => {
                        handleOnCertEmp(null, "th");
                        setAnchorEl(null);
                    }}
                >ภาษาไทย</MenuItem>
                <MenuItem
                    onClick={() => {
                        handleOnCertEmp(null, "en");
                        setAnchorEl(null);
                    }}
                >English</MenuItem>
            </Menu>
            {openDateDialog && (
                <DialogVisaDate
                    open={openDateDialog}
                    onClose={() => setOpenDateDialog(false)}
                    onHandleSubmit={async (rangeDate) => {
                        return await handleOnCertEmp(rangeDate);
                    }}
                />
            )}
        </Fragment>
    );
};

const EmployeesDocument = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [selectedCompany, setSelectedCompany] = useState(null);
    const { result: employeesList } = useSelector((state) => state.employees);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
    const { result: allSmartDocument, isFetching: isFetchingAllSmartDocument } = useSelector((state) => state.allSmartDocument);

    const [selectedType, setSelectedType] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedSmartDocument, setSelectedSmartDocument] = useState(null);
    const [selectedYear2, setSelectedYear2] = useState(null);
    const [selectedIdPayrollType, setSelectedIdPayrollType] = useState(null);

    const currentYear = new Date().getFullYear();

    const thaiYears = Array.from(
        { length: 6 },
        (_, index) => currentYear + 543 - index
    );

    const [openAlert, setOpenAlert] = useState({
        isOpen: false,
        type: null,
        label: null,
    });

    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany);
        }
    }, [userProfile, affiliateOrganizationList]);

    useEffect(() => {
        if (selectedCompany) {
            dispatch(getAllEmployeesByIdCompany(selectedCompany.idCompany));
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (employeesList && selectedCompany) {
            setSelectedEmployee(employeesList[0]);
        }
    }, [employeesList, selectedCompany]);

    const handleOpenAlertError = (label) => {
        setOpenAlert({ isOpen: true, type: "error", label: label });
    };

    const handleCloseAlert = () => {
        setOpenAlert({ isOpen: false, type: null, label: null });
    };

    const modifyPdf = async (selectedMonth, type, selectedYear, idPayrollType) => {
        setSelectedIdPayrollType(idPayrollType);

        try {
            const data = await getEmployeesTaxDetails(selectedMonth, parseInt(selectedYear) - 543, idPayrollType, (selectedEmployee && selectedEmployee.idEmployees), type, selectedCompany && selectedCompany.idCompany);
            if (!data.data) {
                handleOpenAlertError(t("NoData"));
                return;
            }
            const generateFunctionMap = {
                "PND91": PND91PdfFile,
                "50tawi": Tawi50PDFfile
            };

            const generateFunction = generateFunctionMap[type];
            if (!generateFunction) {
                handleOpenAlertError("Unsupported report type");
                return;
            }

            generateFunction(type, selectedYear, data.data);
        } catch (error) {
            // You might want to handle different types of errors here
            handleOpenAlertError("เกิดข้อผิดพลาดขณะดึงข้อมูล");
        }
    };

    const { control, watch } = useForm({
        defaultValues: {
            year50Tawi: "",
            yearPND91: "",
        },
    });

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleSmartDocumentChange = (event) => {
        setSelectedSmartDocument(event.target.value);
    };

    useEffect(() => {
        if ((selectedYear) && selectedIdPayrollType) {
            modifyPdf(0, selectedType, selectedYear, selectedIdPayrollType);
        }
    }, [selectedCompany, selectedYear, selectedIdPayrollType]);

    const fetchAdditionalSmartDocument = async () => {
        if (selectedCompany && selectedCompany.idCompany) {
            dispatch(getallSmartDocumentsById(selectedCompany.idCompany, 'additional','all'));
        }
    }
    useEffect(() => {
        fetchAdditionalSmartDocument();
    }, [selectedCompany]);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <div style={{ marginBottom: 8 }}>
                    <Typography variant="h4" style={{ paddingTop: 8 }}>
                        {t("EmployeeDocuments")}
                    </Typography>
                </div>

                <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Typography fontSize={18} gutterBottom>
                            {`${t("Company")}`}
                        </Typography>
                        <SelectAffiliateCompany
                            options={affiliateOrganizationList}
                            value={selectedCompany}
                            onChange={(_, value) => {
                                onChangeCompany(value)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography fontSize={18} gutterBottom>
                            {`${t("Employee")}`}
                        </Typography>
                        <StyledAutoComplete
                            options={employeesList ? employeesList : []}
                            getOptionLabel={(option) =>
                                getUserFullName(option)
                            }
                            isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                    variant="filled"
                                    placeholder={t("SearchEmp")}
                                />
                            )}
                            value={selectedEmployee}
                            onChange={(event, newValue) => {
                                setSelectedEmployee(newValue);
                            }}
                            noOptionsText={t("NoData")}
                            disableClearable
                        />
                    </Grid>
                </Grid>

                <Box sx={{ mt: 4 }}>
                    <Fragment>
                        <Paper className="paper-list">
                            <List>
                                <ListItem>
                                    <Grid
                                        container
                                        justifyContent={"space-between"}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography className="filename">
                                                {`${t("IncomeCertificate")}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <div className="navBox">
                                                <DownloadFileButton
                                                    type={"certificateSalary"}
                                                    handleOpenAlertError={handleOpenAlertError}
                                                    idEmployees={selectedEmployee && selectedEmployee.idEmployees}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <DividerStyled />
                                <ListItem>
                                    <Grid
                                        container
                                        justifyContent={"space-between"}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography className="filename">
                                                {`${t("EmploymentCertificate")}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <div className="navBox">
                                                <DownloadFileButton
                                                    type={"certificateEmp"}
                                                    handleOpenAlertError={handleOpenAlertError}
                                                    idEmployees={selectedEmployee && selectedEmployee.idEmployees}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <DividerStyled />
                                <ListItem>
                                    <Grid
                                        container
                                        justifyContent={"space-between"}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography className="filename">
                                                {`${t("ResignationCertificate")}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <div className="navBox">
                                                <DownloadFileButton
                                                    type={"resignationCertificate"}
                                                    handleOpenAlertError={handleOpenAlertError}
                                                    idEmployees={selectedEmployee && selectedEmployee.idEmployees}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <DividerStyled />
                                <ListItem>
                                    <Grid
                                        container
                                        justifyContent={"space-between"}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography className="filename">
                                                50 {`${t("Tawi")}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <div className="navBox">
                                                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                                    <Controller
                                                        name="year"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextFieldTheme
                                                                {...field}
                                                                label={`${t("SelectYear")}`}
                                                                select
                                                                value={selectedYear}
                                                                onChange={(e) => handleYearChange(e)}
                                                                style={{ width: 150 }}
                                                            >
                                                                {thaiYears.map((year) => (
                                                                    <MenuItem key={year} value={year}>
                                                                        {year}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextFieldTheme>
                                                        )}
                                                    />
                                                    <ButtonBlue
                                                        variant="contained"
                                                        type="submit"
                                                        disabled={selectedYear === null}
                                                        onClick={
                                                            async () => {
                                                                setSelectedType("50tawi")
                                                                await modifyPdf(0, "50tawi", selectedYear, 12);
                                                            }}
                                                        style={{ width: 95 }}
                                                    >
                                                        {t("View")} PDF
                                                    </ButtonBlue>
                                                </Stack>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <DividerStyled />
                                <ListItem>
                                    <Grid
                                        container
                                        justifyContent={"space-between"}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography className="filename">{`${t("P.N.D.91")}`}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <div className="navBox">
                                                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                                    {/* <Controller
                                                            name="year"
                                                            control={control}
                                                            render={({ field }) => (
                                                            <TextFieldTheme
                                                                {...field}
                                                                label={`${t("SelectYear")}`}
                                                                select
                                                                value={selectedYear2}
                                                                onChange={(e) => handleYearChange2(e)}
                                                                style={{ width: 150 }}
                                                            >
                                                                {thaiYears.map((year) => (
                                                                <MenuItem key={year} value={year}>
                                                                    {year}
                                                                </MenuItem>
                                                                ))}
                                                            </TextFieldTheme>
                                                            )}
                                                        /> */}
                                                    <ButtonBlue
                                                        variant="contained"
                                                        type="submit"
                                                        disabled={selectedYear2 === null}
                                                        onClick={
                                                            async () => {
                                                                setSelectedType("PND91")
                                                                await modifyPdf(0, "PND91", selectedYear2, 12);
                                                            }}
                                                        style={{ width: 95 }}
                                                    >
                                                        {t("View")} PDF
                                                    </ButtonBlue>
                                                </Stack>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <DividerStyled />
                                <ListItem>
                                    <Grid
                                        container
                                        justifyContent={"space-between"}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography className="filename">
                                                Business Visa
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <div className="navBox">
                                                <DownloadFileButton
                                                    type={"businessVisa"}
                                                    handleOpenAlertError={handleOpenAlertError}
                                                    idEmployees={selectedEmployee && selectedEmployee.idEmployees}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <DividerStyled />
                                <ListItem>
                                    <Grid
                                        container
                                        justifyContent={"space-between"}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography className="filename">Tourist Visa</Typography>
                                        </Grid>
                                        <Grid item>
                                            <div className="navBox">
                                                <DownloadFileButton
                                                    type={"touristVisaNoDate"}
                                                    handleOpenAlertError={handleOpenAlertError}
                                                    idEmployees={selectedEmployee && selectedEmployee.idEmployees}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                {/* <DividerStyled />
                                <ListItem>
                                    <Grid
                                        container
                                        justifyContent={"space-between"}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography className="filename">Smart PDF</Typography>
                                        </Grid>
                                        <Grid item>
                                            <div className="navBox">
                                                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                                    <Controller
                                                        name="smartDocument"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextFieldTheme
                                                                {...field}
                                                                label={`${t("SelectItem")}`}
                                                                select
                                                                value={selectedSmartDocument}
                                                                onChange={(e) => handleSmartDocumentChange(e)}
                                                                style={{ width: 200 }}
                                                            >
                                                                {allSmartDocument && allSmartDocument.option && allSmartDocument.option.map((item, index) => (
                                                                    <MenuItem key={`${index}_allSmartDocument`} value={item.smartDocumentId}>
                                                                        {item.documentTypeName}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextFieldTheme>
                                                        )}
                                                    />
                                                    <ButtonBlue
                                                        variant="contained"
                                                        disabled={selectedSmartDocument === null}
                                                        onClick={() => handleDownloadPdf(selectedSmartDocument, selectedEmployee ? selectedEmployee.idEmployees : null, selectedCompany ? selectedCompany.idCompany : null)}
                                                        style={{ width: 95 }}
                                                    >
                                                        {t("Download")}
                                                    </ButtonBlue>
                                                </Stack>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem> */}
                            </List>
                        </Paper>
                    </Fragment>
                </Box>

                <CardItem data={allSmartDocument} selectedEmployee={selectedEmployee && selectedEmployee.idEmployees} selectedCompany={selectedCompany && selectedCompany.idCompany} fetch={fetchAdditionalSmartDocument} editor={true} />
            </Container>
            {openAlert.isOpen && (
                <AlertResponse
                    open={openAlert.isOpen}
                    alertType={openAlert.type}
                    label={openAlert.label}
                    handleClose={handleCloseAlert}
                />
            )}
        </StyledRoot>
    );
};

export default EmployeesDocument;
