import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";
import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import AlertDialog from "./alertDialog";
import Swal from 'sweetalert2';
import {
  addDocumentType,
  editDocumentType,
} from "../../../../actions/document";
import Lottie from 'lottie-react';
import animationData2 from '../company-documents/Animation/Animation - 1721107029244.json'

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogType = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { open, mode, handleClose, data, handleCloseDialog, idCompany } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const { result: docGroup } = useSelector((state) => state.documentGroup);
  const [loading, setLoading] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const [formData, setFormData] = useState({
    documentTypeName: '',
    idDocumentGroup: null,
    documentGroupName: '',
    selectedOptionDoc: '',
    idCompany: idCompany,
    isShow: 0,
    isUserUpload: false,
    detail: '',
    file: null,
  });


  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => {
      let newFormData = { ...prevFormData, [name]: value };

      if (value === "AllowUsersUploadownFiles") {
        newFormData = { ...newFormData, isUserUpload: 1 };
      }
      else if (value === "DisableOptions") {
        newFormData = { ...newFormData, isUserUpload: 0 };
      }
      return newFormData;
    });
  };

  useEffect(() => {
    if (open && data) {
      console.log('data',data)
      if (mode === "edit") {
        setFormData({
          idDocumentType: data.idDocumentType,
          documentTypeName: data.documentTypeName,
          idDocumentGroup: data.idDocumentGroup,
          documentGroupName: data.documentGroupName,
          idCompany: idCompany,
          isActivate: data.isActivate,
          selectedOptionDoc: data.selectedOptionDoc,
          isUserUpload: data.isUserUpload,
          isShow: data.isShow,
          detail: data.detail,
          file: null,
        });
      } else if (mode === "editImDoc") {
        setFormData({
          idDocumentType: data.idDocumentType,
          idDocumentGroup: data.idDocumentGroup,
          documentGroupName: data.documentGroupName,
          documentTypeName: data.documentTypeName,
          idCompany: idCompany,
          isActivate: data.isActivate,
          selectedOptionDoc: data.selectedOptionDoc,
          isUserUpload: data.isUserUpload,
          isShow: data.isShow,
          detail: data.detail,
        })
      } else if (mode === "activate") {
        setFormData({
          idDocumentType: data.idDocumentType,
          idDocumentGroup: data.idDocumentGroup,
          documentGroupName: data.documentGroupName,
          documentTypeName: data.documentTypeName,
          idCompany: idCompany,
          isActivate: data.isActivate
        });
      } else {
        setFormData({
          documentTypeName: null,
          idDocumentGroup: null,
          DocumentGroupName: '',
          selectedOptionDoc: '',
          isUserUpload: false,
          isShow: 0,
          detail: null,
          file: null,
        });
      }
    }
  }, [open, data]);

  const lottieRef = useRef(null);

  useEffect(() => {
    if (lottieRef.current) {
      if (!loading) {
        lottieRef.current.play();
      }
    }
  }, [loading]);

  const handleSubmit = async () => {
    if (!formData.idDocumentGroup || !formData.documentTypeName) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("ThisFieldIsRequired")}`);
    } else {
      const postData = new FormData();
      let data;
      if (mode === "edit") {
        data = {
          documentTypeName: formData.documentTypeName,
          selectedOptionDoc: formData.selectedOptionDoc,
          idDocumentType: formData.idDocumentType,
          isShow: formData.isShow,
          isUserUpload: formData.isUserUpload,
          idCompany: idCompany,
          isActivate: formData.isActivate,
          updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
          detail: formData.detail,
        };
      }
      else if (mode === "editImDoc") {
        data = {
          documentTypeName: formData.documentTypeName,
          selectedOptionDoc: formData.selectedOptionDoc,
          idDocumentType: formData.idDocumentType,
          isShow: formData.isShow,
          isUserUpload: formData.isUserUpload,
          idCompany: idCompany,
          isActivate: formData.isActivate,
          updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
          detail: formData.detail,
        };
      }
      else if (mode === "activate") {
        data = {
          idDocumentType: formData.idDocumentType,
          idDocumentGroup: formData.idDocumentGroup,
          idCompany: idCompany,
          isActivate: formData.isActivate
        }
      } else {
        data = {
          documentTypeName: formData.documentTypeName,
          selectedOptionDoc: formData.selectedOptionDoc,
          idDocumentGroup: formData.idDocumentGroup,
          documentGroupName: formData.documentGroupName,
          idCompany: idCompany,
          isActivate: formData.isActivate || 0,
          isUserUpload: formData.isUserUpload,
          isShow: formData.isShow,
          updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
          createDate: dayjs(new Date()).format("YYYY-MM-DD"),
          detail: formData.detail,
        };
      }
      Object.keys(data).map((k) => {
        postData.append(k, JSON.stringify(data[k]));
      });

      if (formData.file) {
        postData.append("file", formData.file);
      }
      setLoading(true);
      try {
        let result;
        if (mode === "Add") {
          result = await dispatch(addDocumentType(postData));
        } else {
          result = await dispatch(editDocumentType(postData));
        }
        if (result) {
          Swal.fire({
            icon: 'success',
            title: mode === "Add" ? `${t("Data_added_successfully")}!` : `${t("Data_edit_successful")}!`,
            showConfirmButton: false,
            timer: 1500,
          })
          handleClose();
        } else {
          Swal.fire({
            icon: 'error',
            title: mode === "Add" ? `${t("Unable_to_add_data")}!` : `${t("Unable_to_edit_data")}!`,
            text: `${t("PleaseTryAgain")}!`,
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        console.error("Error while editing document: ", error);
        Swal.fire({
          icon: 'error',
          title: `${t("AnErrorOccurred")}!`,
          text: `${t("PleaseTryAgain")}!`,
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const MenuList = [
    { key: "AllowUsersUploadownFiles", label: `${t("AllowUsersUploadownFiles")}` },
    { key: "DisableOptions", label: `${t("DisableOptions")}` },
  ];

  return (
    <>
      {mode === 'activate' ? (
        <>
          <AlertDialog
            message={data.isActivate ? t("OpenEmpDoc") : t("CloseEmpDoc")}
            open={open}
            onClose={handleCloseDialog}
            handleSubmit={handleSubmit}
            formData={formData}
            loading={loading}
          />
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
        </>
      ) : (
        <>
          <DrawerCustom
            title={
              <Typography variant="h6" >
                {t("DocumentDescription")}
              </Typography>
            }
            anchor="right"
            open={open}
            onClose={handleCloseDialog}
          >
            {formData && (
              <StyledRoot>
                <Grid
                  container
                  spacing={2}
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  {mode === "edit" ? (
                    <>
                      {loading ? (
                        <>
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                            <Typography
                              variant="h6"
                              style={{
                                marginTop: '16px',
                                color: '#3f51b5',
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                fontWeight: 'bold',
                                userSelect: 'none',
                              }}
                            >
                              {`${t("EditTypeDocEmployee")}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                            <div style={{ width: '60%', height: '60%' }}>
                              <Lottie
                                lottieRef={lottieRef}
                                animationData={animationData2}
                                loop={true}
                                autoplay={true}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                            <Typography
                              variant="h6"
                              style={{
                                paddingTop: '8px',
                                color: '#708090',
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                fontWeight: 'bold',
                                userSelect: 'none',
                              }}
                            >
                              {`${t("MoveFolder")} `}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <Grid xs={12} className="GridTopicInput">
                              {`${t("DocumentName")} `}
                            </Grid>
                            <TextFieldTheme
                              name="documentTypeName"
                              fullWidth
                              onChange={handleChange}
                              value={formData.documentTypeName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid xs={12} className="GridTopicInput">
                              {`${t("Category")}`}
                            </Grid>
                            <FormControl fullWidth>
                              <Select
                                inputFormat={""}
                                value={formData.idDocumentGroup}
                                onChange={handleChange}
                                disabled={true}
                              >
                                <MenuItem value={formData.idDocumentGroup}>
                                  {formData.documentGroupName}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {mode !== "editImDoc" && (
                        <>
                          <Grid item xs={12}>
                            <Grid xs={12} className="GridTopicInput">
                              {`${t("DocumentName")}`}
                            </Grid>
                            <TextFieldTheme
                              name="documentTypeName"
                              fullWidth
                              onChange={handleChange}
                              value={formData.documentTypeName}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} >
                            <Grid xs={12} className="GridTopicInput">
                              {`${t("Category")}`}
                            </Grid>
                            <FormControl fullWidth>
                              <Select
                                name="idDocumentGroup"
                                inputFormat={""}
                                value={formData.idDocumentGroup}
                                onChange={handleChange}
                              >
                                {docGroup.map((row,index) => (
                                  <MenuItem key={`${index}-docGroup-MenuItem`} value={row.idDocumentGroup}>
                                    {row.documentGroupName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {loading ? (
                    <>
                    </>
                  ) : (
                    <>
                      {mode !== "editImDoc" && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Grid xs={12} className="GridTopicInput">
                              {`${t("DisplayHomepage")}`}
                            </Grid>
                            <FormControl fullWidth>
                              <Select
                                name="isShow"
                                inputFormat={""}
                                value={formData.isShow}
                                onChange={handleChange}
                              >
                                <MenuItem value={1}>{`${t("Display")}`}</MenuItem>
                                <MenuItem value={0}>{`${t("NotDisplayed")}`}</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <Grid xs={12} className="GridTopicInput">
                              {`${t("OptionalDoc")}`}
                            </Grid>
                            <FormControl fullWidth>
                              <Select
                                name="selectedOptionDoc"
                                inputFormat={""}
                                value={formData.selectedOptionDoc}
                                onChange={handleChange}
                              >
                                {MenuList.map((item, index) => (
                                  <MenuItem key={index} value={item.key}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid xs={12} className="GridTopicInput">
                              {`${t("Descriptions")}`}
                            </Grid>
                            <TextFieldTheme
                              name="detail"
                              fullWidth
                              multiline
                              rows={3}
                              onChange={handleChange}
                              value={formData.detail}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                {mode === "editImDoc" && (
                  <>
                    <Grid item xs={12} sm={4} className="GridTopicInput"
                      sx={{ paddingTop: '30px', marginLeft: '4px' }}
                    >
                      {`${t("OptionalDoc")}`}
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <FormControl fullWidth>
                        <Select
                          name="selectedOptionDoc"
                          inputFormat={""}
                          value={formData.selectedOptionDoc}
                          onChange={handleChange}
                        >
                          {MenuList.map((item, index) => (
                            <MenuItem key={index} value={item.key}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} pt={2}>
                      <Grid xs={12} className="GridTopicInput">
                        {`${t("Descriptions")}`}
                      </Grid>
                      <TextFieldTheme
                        name="detail"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={handleChange}
                        value={formData.detail}
                      />
                    </Grid>
                  </>
                )}
                {loading ? (
                  <>
                  </>
                ) : (
                  <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>
                      {`${t("Cancel")}`}
                    </ButtonBlue>
                    <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
                      {`${t("SaveData")}`}
                    </ButtonBlue>
                  </StyledFooter>
                )}
              </StyledRoot>
            )}
            <AlertResponse
              open={openAlert}
              handleClose={handleCloseAlert}
              alertType={alertType}
            />
          </DrawerCustom>
        </>
      )
      }
    </>
  );
};

export default DialogType;