import React, { lazy, Suspense } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Container from "@mui/material/Container";

import Login from "./components/pages/LoginPage";
import Landing from "./components/pages/landing";
import ContactUs from "./components/pages/contactus";
import GpsCheckInOutPage from "./components/pages/line/gpsCheckInOut";
import QRCodeCheckInOutPage from "./components/pages/line/QRCodeCheckInOut";
import TodayCheckPage from "./components/pages/line/today-check";
import { UnauthorizedPage, NotFoundPage } from "./components/pages/error";
import ForgetPasswordPage from "./components/pages/account/forget-password";
import ResetPasswordPage from "./components/pages/account/reset-password";

import HomePage from "./components/pages/homepage";

//Main
import ApproveListPage from "./components/pages/main-page/approve-list";
import DocumentServicePage from "./components/pages/main-page/document-service";
import SummaryTimePage from "./components/pages/main-page/summary-ot";
import OverviewPage from "./components/pages/main-page/overview";
import EmployeesPage from "./components/pages/main-page/employee";
import TimeAttendancePage from "./components/pages/main-page/time-attendance";
import OnBoarding from "./components/pages/main-page/on-boarding";
import OffBoarding from "./components/pages/main-page/off-boarding";
import UniRecruit from "./components/pages/main-page/uni-recruit";
import WarningLetter from "./components/pages/main-page/warning-letter";
import AssetManagement from "./components/pages/main-page/asset-management";
import Announcement from "./components/pages/main-page/announcement";
import AccessAreaPage from "./components/pages/main-page/accessArea";
import AccessTimePage from "./components/pages/main-page/accessTime";
import EmployeeNetworkPage from "./components/pages/main-page/employee-network";
import ExpensePage from "./components/pages/main-page/expense/expense";
import ExpenseDetailPage from "./components/pages/main-page/expense/detail";
import ExpenseHistoryPage from "./components/pages/main-page/expense/history";
import WelfareRights from "./components/pages/main-page/welfare-rights";
import AssessmentPage from "./components/pages/main-page/assessment";
//User
import ProfilePage from "./components/pages/user/profile";
import Notification from "./components/pages/NotificationPage";
import Timeline from "./components/pages/user/timeline";
import TimeSchedule from "./components/pages/user/time-schedule";
import Leavelist from "./components/pages/user/leave/Leavelist";
import Requestlist from "./components/pages/user/requestlist/Requestlist";
import Overtimereport from "./components/pages/user/overtime";
import SalarySlip from "./components/pages/user/salary/paySlip";
import TaxDeduction from "./components/pages/user/salary/tax/TaxDeduction";
import TaxList from "./components/pages/user/salary/tax/TaxList";
import EmployeeNetworkUser from "./components/pages/user/employeeNetwork/employeeProfile";
// import CoursesRegister from "./components/pages/user/training/course";
// import Information from "./components/pages/user/member/information";
// import SelectTask from "./components/pages/user/task/selectTask";
// import EditPersonalTask from "./components/pages/user/task/form/editPersonalTask";
// import AddNewTaskUser from "./components/pages/user/task/form/formTask";
// import PersonalTask from "./components/pages/user/task/showPersonalTask";
// import ProjectAndAVG from "./components/pages/user/task/project&avg";
// import AddPersonalProject from "./components/pages/user/task/form/addPersonalProject";
// import AddPersonalAVG from "./components/pages/user/task/form/addPersonalAVG";
import ShowProject from "./components/pages/user/project/showProject";
import ShowDetialProject from "./components/pages/user/project/showDetail";
import EditPosition from "./components/pages/user/project/editProject";
import AddProject from "./components/pages/user/project/addProject";
import CareerPart from "./components/pages/user/career/careerPart";
import RoadMap from "./components/pages/user/career/roadMap";
import EnterPosition from "./components/pages/user/career/enterPosition";
import JobPosting from "./components/pages/user/jobPosting";
import JobPostingApply from "./components/pages/user/jobPostingApply";
import DemandAnalysis from "./components/pages/user/demand/demandAnalysis";
import MyDocument from "./components/pages/user/my-document";
import SummaryTime from "./components/pages/user/summaryTimeAndSalary";

import OKRsHomepage from "./components/pages/user/okrs";
import OKRsIndividualPlanningPage from "./components/pages/user/okrs/IndividualPlanning";
import OKRsIndividualPlanningAddFormPage from "./components/pages/user/okrs/IndividualPlanning/addForm";
import OKRsIndividualPlanningInformationPage from "./components/pages/user/okrs/IndividualPlanning/IndividualInformation";

import OKRsTeamPlanningPage from "./components/pages/user/okrs/Planning/Team";
import OKRsTeamPlanningAddFormPage from "./components/pages/user/okrs/Planning/Team/addForm";
import OKRsTeamInformationPage from "./components/pages/user/okrs/Planning/Team/Information";

import OKRsOrganizationPlanningPage from "./components/pages/user/okrs/Planning/Organization";
import OKRsOrganizationPlanningAddFormPage from "./components/pages/user/okrs/Planning/Organization/addForm";
import OKRsOrganizationPlanningInformationPage from "./components/pages/user/okrs/Planning/Organization/Information";
// import OKRsOrganizationPlanningAddFormPage from "./components/pages/user/okrs/OrganizationPlanning/addForm";
// import OKRsOrganizationPlanningInformationPage from "./components/pages/user/okrs/OrganizationPlanning/Information";

import OKRsUpdateProgressIndividualPage from "./components/pages/user/okrs/UpdateProgress/Individual";
import OKRsUpdateProgressIndividualObjectivePage from "./components/pages/user/okrs/UpdateProgress/Individual/Objective";
import OKRsUpdateProgressIndividualKeyResultPage from "./components/pages/user/okrs/UpdateProgress/Individual/KeyResult";

import OKRsUpdateProgressTeamPage from "./components/pages/user/okrs/UpdateProgress/Team";
import OKRsUpdateProgressTeamObjectivePage from "./components/pages/user/okrs/UpdateProgress/Team/Objective";
import OKRsUpdateProgressTeamKeyResultPage from "./components/pages/user/okrs/UpdateProgress/Team/KeyResult";

import OKRsUpdateProgressOrganizationPage from "./components/pages/user/okrs/UpdateProgress/Organization";
import OKRsUpdateProgressOrganizationObjectivePage from "./components/pages/user/okrs/UpdateProgress/Organization/Objective";
import OKRsUpdateProgressOrganizationKeyResultPage from "./components/pages/user/okrs/UpdateProgress/Organization/KeyResult";

import OKRsDashboardIndividualPage from "./components/pages/user/okrs/Dashboard/Individual";
import OKRsDashboardTeamPage from "./components/pages/user/okrs/Dashboard/Team";
import OKRsDashboardOrganizationPage from "./components/pages/user/okrs/Dashboard/Organization";

// import OKRsApprovalPage from "./components/pages/user/okrs/Approval";
// import OKRsApprovalIndividualPage from "./components/pages/user/okrs/Approval/Individual";
import OKRsApprovalIndividualPage from "./components/pages/user/okrs/Approval/Individual";
import OKRsApprovalIndividualObjectivePage from "./components/pages/user/okrs/Approval/Individual/Objective";

import OKRsEvaluationPage from "./components/pages/user/okrs/Evaluation/selfEvaluation";
// import OKRsEvaluationIndividualPage from "./components/pages/user/okrs/Evaluation/Individual";
import OKRsEvaluationIndividualPage from "./components/pages/user/okrs/Evaluation/Individual";
import OKRsEvaluationIndividualObjectivePage from "./components/pages/user/okrs/Evaluation/Individual/Objective";
import Boarding from "./components/pages/user/assessor";
// import Timeline2 from "./components/pages/user/timeline2";
import CompanyInterview from "./components/pages/user/Unirecruit/company-interview/index";
import CompanyCandidate from "./components/pages/user/Unirecruit/company-candidate/index";
import OpenJobFormPage from "./components/pages/user/Unirecruit/openjob/openJobForm";
import RequestInformationPageOpenJob from "./components/pages/user/Unirecruit/openjob/RequestInformationPageOpenJob";
import ExpenseUserCreatePage from "./components/pages/user/expense/create";
import ExpenseUserEditPage from "./components/pages/user/expense/edit";
import ExpenseUserViewPage from "./components/pages/user/expense/view";
import ExpenseExport from "./components/pages/admin-accountant/expense-export";
import MyWelfareRights from "./components/pages/user/myWelfareRights";
import MyPersonalTax from "./components/pages/user/personalTax";
//Manager
import HistoryApproveList from "./components/pages/manager/historyApproveList";
import OTMonitoring from "./components/pages/manager/otMonitoring";
import SwitchShiftEmployeePage from "./components/pages/manager/employee/switchShift";
import EmployeeManagerPage from "./components/pages/manager/employee/employeePage";
import EmployeeProfileManagerPage from "./components/pages/manager/employee/profile";
// import MemberInTeamManager from "./components/pages/manager/member/memberInTeam";
// import InformationManager from "./components/pages/manager/member/Information";
// import DetailWorkManager from "./components/pages/manager/member/detailOfWork";
// import EachPersonWhiteManager from "./components/pages/manager/member/eachPerson";
import SuggestCareer from "./components/pages/manager/career/suggestCareer";
import ViewSuggest from "./components/pages/manager/career/viewSuggest";
// import DashboardManagerPage from "./components/pages/manager/dashboardSupply/dashboardPage";
// import ShowDemand from "./components/pages/manager/demand/showDemand";
// import AddDemand from "./components/pages/manager/demand/addDemand";
// import BillRequestM from "./components/pages/manager/health/BillRequestM";
// Admin
import {
  SettingPage,
  EmployeeProfilePage,
  AdminOtMonitoring,
  ShiftPage,
  ShiftAddNewPage,
  ShiftListEmployeePage,
  PlanYearPage,
  ManPowerPage,
  TeamManPowerPage,
  RequestPage,
  RequestInformationPage,
  CandidateProfilePage,
  RequestFormPage,
  OrganizationChartPage,
  ProjectOrganizationChartPage,
  ProjectListPage,
  PayRunsPage,
  PayrollSummaryYearPage,
  PayrollSettingPage,
  PayRunsNewPage,
  PayRunsEditPage,
  PayrollAdditionDeductionPage,
  CompanyProfilePage,
  SelectKey,
  KeyAddition,
  ApporveStatusListPage,
  // CourseDetailAdmin,
  // CoursePageAdmin,
  DetailOfJob,
  ShowAllPosition,
  ShowJobTask,
  ShowTask,
  FormTask,
  EditTask,
  AddJobLevel,
  ShowDivision,
  ShowMemberInDivision,
  InformationDivisionAdmin,
  AddDivision,
  // SupplyDashboard,
  // ManageDemand,
  // EmployeeNetworkAdmin,
  // EmployeeProfile,
  // SelectPersonToVS,
  FormJobStructure,
  // FormPackage,
  // FormLifeStyle,
  // FormTravel,
  // FormInsurance,
  // FormpackageHealthChrck,
  OKRsHomepage as OKRsAdminHomepage,
  OrganizationPlanList,
  OrganizationPlan,
  TeamPlanList,
  TeamPlan,
  IndividualPlanList,
  IndividualPlan,
  // CascadeOKRView,
  OKRTransparent,
  EmpSuccessList,
  FailureHistoryList,
  FailureHistory,
  FailureHistoryView,
  Approval,
  EmployeePlanList,
  IndividualPlanEvaluation,
  ManagerTeamApprove,
  EvaluateManager,
  OrganizationUpdate,
  OrganizationRecord,
  UpdateKROrg,
  TeamUpdate,
  UpdateKRTeam,
  TeamRecord,
  IndividualUpdate,
  UpdateKRIdv,
  IndividualRecord,
  DashboardOKR,
  OrgEvaluationList,
  OrgEvaluation,
  TeamEvaluationList,
  TeamEvaluation,
  IdvEvaluationList,
  IdvEvaluation,
  OKRHistoryList,
  SuccessProfileList,
  SuccessProfile,
  DialogueRecord,
  DialogueRecordView,
  DialogueHistory,
  CascadeMain,
  IndividualDashboard,
  ManagerTeamDashboard,
  IdvPlanListApproved,
  Leaderboard,
  OrganizationDashboard,
  LeaderboardOrg,
  ReportAdminPage,
  PND91AND50tawi,
  HistoryApporveStatusListPage,
  // viewDocumentFileType,
  DialogNewDocument,
  EmployeeNetworkProfilePage,
  WorkforcePlanningCal,
  WorkforceProjectDetails,
  TableProject,
  TaxSummaryPage,
  PersonalTaxAdminPage,
  SmartDocuments,

} from "./components/pages/admin";

import CreatePdf from "./components/pages/admin/smart-documents/CreatePdf";

import SummaryTimeAdminPage from "./components/pages/admin/summaryTime";
import ShowDetailPosition from "./components/pages/shared/pages/job/show/ShowDetailPosition";
import AddEmployeePage from "./components/pages/admin/employee/addEmployee";
import TableHour from "./components/pages/admin/workforce-planning-cal/tableHour";
import detailProjectHour from "./components/pages/admin/workforce-planning-cal/detailProjectHour";
import dashboardProjectHour from "./components/pages/admin/workforce-planning-cal/dashboardProjectHour";
import projectDetails from "./components/pages/admin/workforce-planning-cal/projectDetails";

import All_ReportAdminPage from "./components/pages/admin/all-report";
import LeavePage from "./components/pages/admin/all-report/late-absent/LeavePage";
import CostElement from "./components/pages/admin/all-report/costElement";
import late_absent from "./components/pages/admin/all-report/late-absent/index";
import Payroll from "./components/pages/admin/all-report/payroll";

import OrganizationChartNewPage from "./components/pages/admin/organizationChart";
import OrganizationChartV2Page from "./components/pages/admin/organizationChartV2";
import OrganizationChartV3Page from "./components/pages/admin/organizationChartDabeng";
import RightsManage from "./components/pages/admin-welfare/rights/RightsManage";
import WelfareExpense from "./components/pages/admin-welfare/welfare-expense";
import WelfareBillingPage from "./components/pages/admin-welfare/welfare-expense/billing";
import WelfareHistoryPage from "./components/pages/admin-welfare/welfare-expense/history";
import ExpenseVerifyPage from "./components/pages/admin-welfare/welfare-expense/expense";

import RegisterManpower from "./components/pages/admin/uniRecruit/peer/registerManpower/index";
import Probation from "./components/pages/admin/setting/probation/index";
import HomepageUniRecruit from "./components/pages/admin/uniRecruit/homePage/index";
import PeerPage from "./components/pages/admin/uniRecruit/peer/index";
import Dashboard from "./components/pages/admin/uniRecruit/dashboard/index";
import AddManpowerFormPage from "./components/pages/admin/uniRecruit/peer/addManpower/index";
import VendorManpowerProfilePage from "./components/pages/admin/uniRecruit/peer/addManpower/peerProfile/profilePeer";
import HunterInterviewPage from "./components/pages/admin/uniRecruit/hunter-interview";
import ChangePasswordPage from "./components/pages/account/change-password";
import HunterHistorySending from "./components/pages/admin/uniRecruit/hunter-history-sending";
import RecruitInterviewer from "./components/pages/admin/uniRecruit/interviewer";
import Calibrate from "./components/pages/admin/okrs/Calibrate/calibrate";
import OKRScoreboard from "./components/pages/admin/okrs/scoreboard";
import DashboardCalibrate from "./components/pages/admin/okrs/Calibrate/department";
import OKRSPage from "./components/pages/main-page/okrs";
import CalibrateDepartment from "./components/pages/admin/okrs/Calibrate/departmentDetail";
import ApprovalList from "./components/pages/admin/okrs/Approval/ApprovalList";
import OKRStatus from "./components/pages/admin/okrs/Status";
import OKRStatusTeam from "./components/pages/manager/okrs/status";
import SelfEvaluation from "./components/pages/user/okrs/Evaluation/selfEvaluation";
import SelfEvaluationDetail from "./components/pages/user/okrs/Evaluation/selfEvaluation/selfEvaluationDetail";
import OKRStatusPlanApprove from "./components/pages/admin/okrs/StatusPlanApprove";
import WaitingApprove from "./components/pages/main-page/waitingApprove";

import ReportListsPage from "./components/pages/user/governmentReport/report-list";
import DocumentsReport from "./components/pages/user/governmentReport/documents-report";
import SummaryOTShift from "./components/pages/admin/all-report/summary-ot-shift";
import LeaveAbsentLate from "./components/pages/admin/all-report/leave-absent-late";
import LeaveAbsentLatePayroll from "./components/pages/admin/all-report/leave-absent-late-payroll";
import ProbationReport from "./components/pages/admin/all-report/probation";
import ContractTerminationReport from "./components/pages/admin/all-report/contractTermination";
import NewEmployeesReport from "./components/pages/admin/all-report/newEmployees";
import ResignReport from "./components/pages/admin/all-report/resign";
import DiligenceAllowancePage from "./components/pages/admin/all-report/diligence-allowance";

import InsertTime from "./components/pages/user/workforceUser/index";
import DashboardEmployee from "./components/pages/admin/all-report/dashboard-employee";
import QuotaLeaveList from "./components/pages/admin/all-report/quota-Leave-List";
import DashboardOT from "./components/pages/admin/all-report/summary-hour-wage-ot";
import DashboardProvidentFund from "./components/pages/admin/all-report/providentFund";
import DashboardTax from "./components/pages/admin/all-report/dashboard-tax";
import SelectPersonToVS from "./components/pages/admin/compared";
import Information from "./components/pages/admin/employeeProfile";
import EmployeeProfileCard from "./components/pages/admin/employeeProfile/employee-list";
import PersonalityCharacterTest from "./components/pages/user/personalityCharacterTest";
import DISC1 from "./components/pages/user/personalityCharacterTest/DISC1/DISC1";
import DISC2 from "./components/pages/user/personalityCharacterTest/DISC2/DISC2";
import ResultTestDISC1 from "./components/pages/user/personalityCharacterTest/DISC1/resultTestDISC1";
import ResultTestDISC2 from "./components/pages/user/personalityCharacterTest/DISC2/resultTestDISC2";
import Successor from "./components/pages/main-page/successor";
import DetailPage from "./components/pages/admin/successor/listSuccessorInfo/detailPage";
import TalentPage from "./components/pages/admin/talent";
import ListInfoPage from "./components/pages/admin/talent/listInfoPage";

import KPIPage from "./components/pages/main-page/kpi";
import KpisIndividualPlanList from "./components/pages/admin/kpis/KpisIndividualPlan/KpisIndividualPlanList";
import KpisIndividualPlan from "./components/pages/admin/kpis/KpisIndividualPlan/KpisIndividualPlan";
import KpisApprovalList from "./components/pages/admin/kpis/KpisApproval/KpiApprovalList";
import KpisApprovalPlanList from "./components/pages/admin/kpis/KpisApproval/KpiApprovalPlanList";
import KpiManageIndividual from "./components/pages/admin/kpis/KpisIndividualPlan/KpiManagerIndividual";
import KpiEditdate from "./components/pages/admin/kpis/KpisApproval/KpiEditdate";
import KpiAdminIndividual from "./components/pages/admin/kpis/KpisIndividualPlan/KpiAdminIndividual";
import KpiAdminPlanUpdate from "./components/pages/admin/kpis/KpisIndividualPlan/KpiPlanandUpdate";
import KpiManageEvalution from "./components/pages/admin/kpis/KpisIndividualPlan/KpiManagerPlanList";
import KpiAdminEvalution from "./components/pages/admin/kpis/KpisIndividualPlan/KpiAdminPlanList";
import KpiManagePlan from "./components/pages/admin/kpis/KpisIndividualPlan/KpisManagerPlan";
import KpiAdminPlan from "./components/pages/admin/kpis/KpisIndividualPlan/KpisAdminPlan";
// import KpisIndividualPlanEdit from "./components/pages/admin/kpis/KpisIndividualPlan/KpisIndividualPlanEdit";
import KpisIndividualPlanView from "./components/pages/admin/kpis/KpisIndividualPlan/KpisIndividualPlanView";
import KpisIndividualPlanUpdate from "./components/pages/admin/kpis/KpisIndividualPlan/KpisUpdateList";
import KpisIndividualPlaningUpdate from "./components/pages/admin/kpis/KpisIndividualPlan/KpisPlanList";
import KpiApproval from "./components/pages/admin/kpis/KpisApproval/KpiApproval";
import KpisUpdate from "./components/pages/admin/kpis/KpisIndividualPlan/KpisUpdate";
import KpisPlan from "./components/pages/admin/kpis/KpisIndividualPlan/KpisPaln";
import KpiEvaluation from "./components/pages/admin/kpis/KpisEvaluation";
import KpiEvaluationIndividual from "./components/pages/admin/kpis/KpisEvaluation/KpiEvaluationIndividual";
import KpiCalibrate from "./components/pages/admin/kpis/KpisCalibrate/KpiCalibrate";
import KpiCalibrateDepartment from "./components/pages/admin/kpis/KpisCalibrate/KpiDepartmentDetail";
import KpiIsSuccessful from "./components/pages/admin/kpis/KpisIndividualPlan/KpiIsSuccessful";
import KpiIsSuccessfulAll from "./components/pages/admin/kpis/KpisIndividualPlan/KpiIsSuccessfulAll";
import KpiOrganization from "./components/pages/admin/kpis/organization";
import KpiOrganizationPlan from "./components/pages/admin/kpis/organization/KpiOrganizationPlan";
import KpiOrganizationUpdate from "./components/pages/admin/kpis/organization/KpiOrganizationUpdate";
import KpiOrganizationEvaluate from "./components/pages/admin/kpis/organization/KpiOrganizationEvaluate";
import KpiOrganizationUser from "./components/pages/user/kpis/organization/KpiOrganization";
import KpiOrganizationUpdateUser from "./components/pages/user/kpis/organization/KpiUpdate";
import KpiTeam from "./components/pages/admin/kpis/team";
import KpiTeamPlan from "./components/pages/admin/kpis/team/KpiTeamPlan";
import KpiTeamUpdate from "./components/pages/admin/kpis/team/KpiTeamUpdate";
import KpiTeamEvaluate from "./components/pages/admin/kpis/team/KpiTeamEvaluate";
import KpiTeamManager from "./components/pages/manager/kpis/team";
import KpiTeamPlanManager from "./components/pages/manager/kpis/team/KpiTeamPlan";
import KpiTeamEvaluateManager from "./components/pages/manager/kpis/team/KpiTeamEvaluate";
import KpiTeamUser from "./components/pages/user/kpis/team";
import KpiTeamUpdateUser from "./components/pages/user/kpis/team/KpiTeamUpdate";
import KpiOrganizationCascade from "./components/pages/admin/kpis/organization/KpiOrganizationCascade";
import ManagerCareer from "./components/pages/manager/career";
import AdminCareer from "./components/pages/admin/career";
import KpiEvaluationList from "./components/pages/admin/kpis/KpisEvaluation/KpiEvaluationList";
import LevelingReport from "./components/pages/admin/all-report/leveling";
import employmentReport from "./components/pages/admin/all-report/employment";
import TransferPosition from "./components/pages/admin/all-report/transfer-position";
import JobProfile from "./components/pages/admin/job-profile";

import PageManpowerSelect from "./components/pages/user/Unirecruit/openjob/PageManpowerSelect";
import PageApplicantJob from "./components/pages/user/Unirecruit/company-candidate/PageApplicantJob";
import AddCompanyDocument from "./components/pages/admin/company-documents/addCompanyDocument";
import AddDocument from "./components/pages/admin/document/addDocument";
import NewDocumentCompany from "./components/pages/admin/company-documents/newDocumentCompany";

import ChatBotPage from "./components/pages/main-page/chatbot";
import EditChatBotDocument from "./components/pages/admin/chatbot/editDocument";
import AdditionSpecialReportOffSite from "./components/pages/admin/all-report/addition-special-off-site";
import AdditionSpecialReport from "./components/pages/admin/all-report/addition-special";
import EmployeesDocument from "./components/pages/admin/employee-document";
import AdminPotentail from "./components/pages/admin/Potential/PotentialTest";
import PotentialPerson from "./components/pages/user/Potential/PotentialPersonel";
import UserPotentialTest from "./components/pages/user/Potential/PotentialTest";
import DocumentPage from "./components/pages/admin/document/documentPage";
import MonthlyOvertime from "./components/pages/admin/all-report/monthly-ot";
import OKRTeamStatus from "./components/pages/admin/okrs/TeamStatus";
import TurnoverList from "./components/pages/admin/all-report/turnover-list";
import TurnoverDepartmentList from "./components/pages/admin/all-report/turnover-department-list";
import Assessment from "./components/pages/user/assessment";
import OKRsManagementForAdmin from "./components/pages/admin/okrs/OKRManagementForAdmin";
import OKRsManagementForUser from "./components/pages/user/okrs/OKRManagementForUser";
import OKRsManagementForManager from "./components/pages/manager/okrs/OKRManagementForManager";
import OKRsManagementForGeneralManager from "./components/pages/manager/okrs/OKRManagementForGeneralManager";
import OKRsEmployeeView from "./components/pages/admin/okrs/OKRsEmployeeView";
import EmployeeDocument from "./components/pages/main-page/employee-document";
import CompanyDocument from "./components/pages/main-page/company-document";
import EMemoPage from "./components/pages/main-page/e-memo";
import OverviewUserPage from "./components/pages/main-page/overviewUser";
import ExpenseSetting from "./components/pages/admin-accountant/expense-setting";
// import CourseDetail from "./components/pages/training/course/detail";
// import CoursePage from "./components/pages/training/course";

const useStyles = makeStyles(() => ({
  content: {
    padding: 0,
    // flexGrow: 1,
    // display: "flex",
    // justifyContent: "center",
    // padding: 0,
  },
}));

const Routers = () => {
  const classes = useStyles();
  const { user: currentUser } = useSelector((state) => state.auth);
  // const { result: userProfile } = useSelector((state) => state.userProfile);

  const RedirectLogin = () => (
    <Route>
      <Redirect to="/login" />
    </Route>
  );

  const LoginRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            true ? <Component {...props} /> : <Redirect to="/login" />
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminRoute = ({
    component: Component,
    canFillIn: canFillIn = false,
    ...rest
  }) => {
    if (
      currentUser
      // && userProfile
    ) {
      return (
        <Route
          {...rest}
          render={(props) =>
            // (!Boolean(userProfile.isFillIn) || (canFillIn && Boolean(userProfile.isFillIn))) &&
            currentUser.roles.includes("ROLE_ADMIN") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminUserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ||
            currentUser.roles.includes("ROLE_USER") ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminManagerRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ||
            currentUser.roles.includes("ROLE_MANAGER") ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminManagerUserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ||
            currentUser.roles.includes("ROLE_USER") === true ||
            currentUser.roles.includes("ROLE_MANAGER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const ManagerRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_MANAGER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const UserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_USER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const UserManagerRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_USER") === true ||
            currentUser.roles.includes("ROLE_MANAGER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const TrainingRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_TRAINING") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminManagerAccountantRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ||
            currentUser.roles.includes("ROLE_MANAGER") === true ||
            currentUser.roles.includes("ROLE_ACCOUNT") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };
  const AdminUserManagerAccountantRoute = ({
    component: Component,
    ...rest
  }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ||
            currentUser.roles.includes("ROLE_USER") === true ||
            currentUser.roles.includes("ROLE_MANAGER") === true ||
            currentUser.roles.includes("ROLE_ACCOUNT") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };
  const AccountantRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ACCOUNT") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };
  const AdminWelfareRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN_WELFARE") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };
  const AdminWelfareUserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN_WELFARE") === true ||
            currentUser.roles.includes("ROLE_USER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  return (
    <Container
      style={{ padding: 0 }}
      className={classes.content}
      maxWidth={false}
    >
      <Suspense fallback={<div>Loading..</div>}>
        <Switch>
          <Route
            exact
            path={["/"]}
            component={() =>
              currentUser ? <Redirect to="/home" /> : <Redirect to="/landing" />
            }
          />
          <Route exact path="/login" component={Login} />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/forget-password" component={ForgetPasswordPage} />
          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <AdminManagerRoute
            exact
            path="/change-password"
            component={ChangePasswordPage}
          />
          <Route exact path="/unauthorized" component={UnauthorizedPage} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/gps-check" component={GpsCheckInOutPage} />
          <Route
            exact
            path="/qrcode-check/:idGroupGpsLocations"
            component={QRCodeCheckInOutPage}
          />
          <Route exact path="/today-check" component={TodayCheckPage} />
          <Route
            exact
            path="/manpower/register_manpower"
            component={RegisterManpower}
          />

          <LoginRoute exact path="/home" component={HomePage} />

          <AdminUserRoute
            exact
            path="/government-report"
            component={ReportListsPage}
          />
          <AdminUserRoute
            exact
            path="/document-report/:type"
            component={DocumentsReport}
          />

          <AdminUserRoute
            exact
            path="/request-list"
            component={ApproveListPage}
          />
          <AdminUserRoute
            exact
            path="/document-service"
            component={DocumentServicePage}
          />
          <AdminUserRoute
            exact
            path="/document-service/:idEmployees"
            component={DocumentPage}
          />

          <AdminManagerUserRoute exact path="/e-memo" component={EMemoPage} />

          <AdminRoute
            exact
            path="/employee-document"
            component={EmployeesDocument}
          />

          <AdminRoute
            exact
            path="/admin-potential"
            component={AdminPotentail}
          />

          <AdminUserRoute
            exact
            path="/summary-time"
            component={SummaryTimePage}
          />
          <AdminUserRoute exact path="/recruitment" component={UniRecruit} />
          <AdminManagerUserRoute
            exact
            path="/select/manpower-to-interview"
            component={PageManpowerSelect}
          />
          <UserRoute
            exact
            path="/company-applicant/job/:id"
            component={PageApplicantJob}
          />
          <AdminUserRoute
            exact
            path="/warning-letter"
            component={WarningLetter}
          />
          <AdminUserRoute
            exact
            path="/asset-management"
            component={AssetManagement}
          />
          <AdminUserRoute
            exact
            path="/employees_network"
            component={EmployeeNetworkPage}
          />
          <AdminUserRoute
            exact
            path="/employee_profile"
            component={EmployeeNetworkProfilePage}
          />

          <AdminRoute
            exact
            path="/okrs/admin-team-status"
            component={OKRTeamStatus}
          />

          <AdminUserRoute
            exact
            path="/employees-profile"
            component={EmployeeProfileCard}
          />

          <AdminManagerRoute
            exact
            path="/employees"
            component={EmployeesPage}
          />
          <AdminManagerRoute
            exact
            path="/time-attendance"
            component={TimeAttendancePage}
          />

          <AdminManagerUserRoute
            exact
            path="/company/request/:idRequest"
            component={RequestInformationPageOpenJob}
          />
          <AdminManagerUserRoute
            exact
            path="/overview"
            component={OverviewPage}
          />
          <AdminManagerUserRoute
            exact
            path="/overview-user"
            component={OverviewUserPage}
          />
          <AdminManagerUserRoute
            exact
            path="/announcement"
            component={Announcement}
          />
          <AdminManagerUserRoute exact path="/boarding" component={Boarding} />
          <AdminManagerUserRoute
            exact
            path="/on-boarding"
            component={OnBoarding}
          />
          <AdminManagerUserRoute
            exact
            path="/off-boarding"
            component={OffBoarding}
          />
          <AdminManagerUserRoute exact path="/okrs" component={OKRSPage} />
          <AdminManagerUserRoute
            exact
            path="/access-area"
            component={AccessAreaPage}
          />
          <AdminManagerUserRoute
            exact
            path="/access-time"
            component={AccessTimePage}
          />
          {/* User Route */}
          {/* <UserRoute exact path="/manager/career" component={Career} /> */}
          <UserRoute
            exact
            path="/edit/employee_profile"
            component={EmployeeNetworkUser}
          />

          <UserRoute exact path="/notification" component={Notification} />
          <UserRoute exact path="/profile" component={ProfilePage} />
          <UserRoute exact path="/timeline" component={Timeline} />
          <UserRoute exact path="/time-schedule" component={TimeSchedule} />

          {/* <UserRoute exact path="/timeline2/" component={Timeline} /> */}

          <UserRoute exact path="/leave" component={Leavelist} />
          <UserRoute exact path="/requestlist" component={Requestlist} />
          <UserRoute exact path="/pay-slip" component={SalarySlip} />
          <AdminUserRoute
            exact
            path="/tax-deduction"
            component={TaxDeduction}
          />
          <UserRoute exact path="/summary-time" component={SummaryTime} />
          {/* <UserRoute
            exact
            path="/courses/register"
            component={CoursesRegister}
          /> */}

          <AdminUserRoute exact path="/taxlist" component={TaxList} />
          <UserRoute
            exact
            path="/overtime/overtimereport"
            component={Overtimereport}
          />
          {/* <UserRoute exact path="/FTE" component={PersonalTask} /> */}
          {/* <UserRoute
            exact
            path="/FTE/projectAndAVG"
            component={ProjectAndAVG}
          /> */}
          {/* <UserRoute
            exact
            path="/FTE/projectAndAVG/addProject"
            component={AddPersonalProject}
          /> */}
          {/* <UserRoute
            exact
            path="/FTE/projectAndAVG/addAVG"
            component={AddPersonalAVG}
          /> */}
          {/* <UserRoute exact path="/FTE/selectTask" component={SelectTask} /> */}
          {/* <UserRoute
            exact
            path="/FTE/editPersonalTask/:id"
            component={EditPersonalTask}
          /> */}
          {/* <UserRoute
            exact
            path="/FTE/selectTask/addNewTask"
            component={AddNewTaskUser}
          /> */}

          {/* <UserRoute exact path="/information" component={Information} /> */}

          <UserRoute exact path="/project" component={ShowProject} />
          <UserRoute
            exact
            path="/project/showDetail/:id"
            component={ShowDetialProject}
          />
          <UserRoute
            exact
            path={["/project/editProject/:projectID :role"]}
            component={EditPosition}
          />
          <UserRoute exact path="/project/addProject" component={AddProject} />

          <UserRoute exact path="/myCareerePlan/roadMap" component={RoadMap} />
          <UserRoute exact path="/myCareerPlan" component={CareerPart} />
          <Route
            exact
            path="/myCareerePlan/enterPosition"
            component={EnterPosition}
          />

          <UserRoute exact path="/jobposting" component={JobPosting} />
          <UserRoute
            exact
            path="/jobpostingapply"
            component={JobPostingApply}
          />
          <UserRoute exact path="/demandAnalysis" component={DemandAnalysis} />
          <UserRoute exact path="/my-document" component={MyDocument} />

          {/* <UserRoute exact path="/okrs" component={OKRsHomepage} /> */}
          <UserManagerRoute
            exact
            path="/okrs/individual-planning/form"
            component={OKRsIndividualPlanningAddFormPage}
          />

          <UserRoute
            exact
            path="/expense/create"
            component={ExpenseUserCreatePage}
          />
          <UserRoute
            exact
            path="/expense/:typeExpense/:typeEdit/:idExpense"
            component={ExpenseUserEditPage}
          />
          <UserRoute
            exact
            path="/expense/:typeExpense/view/:idExpense/page"
            component={ExpenseUserViewPage}
          />
          <UserRoute exact path="/myWelfare" component={MyWelfareRights} />
          <UserRoute exact path="/workforceInsertTime" component={InsertTime} />
          <UserRoute
            exact
            path="/dashboardProjectHour"
            component={dashboardProjectHour}
          />

          <UserRoute exact path="/user-potential" component={PotentialPerson} />

          <UserRoute
            exact
            path="/user-potential-test"
            component={UserPotentialTest}
          />

          <UserRoute
            exact
            path="/personality-character-test"
            component={PersonalityCharacterTest}
          />

          <UserRoute
            exact
            path="/personality-character-test/DISC1"
            component={DISC1}
          />

          <UserRoute
            exact
            path="/personality-character-test/DISC2"
            component={DISC2}
          />

          <UserRoute
            exact
            path="/personality-character-test/DISC1/result-test-DISC1"
            component={ResultTestDISC1}
          />

          <UserRoute
            exact
            path="/personality-character-test/DISC2/result-test-DISC2"
            component={ResultTestDISC2}
          />
          <AdminManagerUserRoute
            exact
            path="/assessment"
            component={AssessmentPage}
          />

          <AdminManagerUserRoute
            exact
            path="/okrs/individual-planning/information/:idIndividualObjective"
            component={OKRsIndividualPlanningInformationPage}
          />
          <ManagerRoute
            exact
            path="/okrs/team-planning/form"
            component={OKRsTeamPlanningAddFormPage}
          />
          <UserManagerRoute
            exact
            path="/okrs/team-planning/information/:idTeamObjective"
            component={OKRsTeamInformationPage}
          />

          <UserRoute
            exact
            path="/okrs/update-progress/individual"
            component={OKRsUpdateProgressIndividualPage}
          />
          <UserRoute
            exact
            path="/okrs/update-progress/individual/objective/:idIndividualObjective"
            component={OKRsUpdateProgressIndividualObjectivePage}
          />
          <UserRoute
            exact
            path="/okrs/update-progress/individual/keyresult/:idIndividualKeyResult"
            component={OKRsUpdateProgressIndividualKeyResultPage}
          />
          <ManagerRoute
            exact
            path="/okrs/approval/individual/:idIndividualObjective"
            component={OKRsApprovalIndividualObjectivePage}
          />
          {/* <UserRoute exact path="/okrs/approval/individual2/:idIndividualObjective" component={OKRsApprovalIndividual2Page} /> */}
          <UserManagerRoute
            exact
            path="/okrs/evaluation/individual/:idIndividualObjective"
            component={OKRsEvaluationIndividualObjectivePage}
          />
          <UserRoute
            exact
            path="/okrs/dashboard/individual"
            component={OKRsDashboardIndividualPage}
          />
          <UserRoute
            exact
            path="/okrs/successprofilelist"
            component={SuccessProfileList}
          />
          <UserRoute
            exact
            path="/okrs/failurehistorylist"
            component={FailureHistoryList}
          />
          <UserRoute
            exact
            path="/okrs/okrhistorylist"
            component={OKRHistoryList}
          />
          <UserRoute
            exact
            path="/okrs/individual-evaluation"
            component={SelfEvaluation}
          />
          <UserRoute
            exact
            path="/okrs/individual-evaluation/:idIndividualObjective"
            component={SelfEvaluationDetail}
          />

          <UserManagerRoute
            exact
            path="/okrs/individual-planning"
            component={OKRsIndividualPlanningPage}
          />
          <UserManagerRoute
            exact
            path="/okrs/approval/individual"
            component={OKRsApprovalIndividualPage}
          />
          <UserManagerRoute
            exact
            path="/okrs/cascading"
            component={CascadeMain}
          />
          <UserManagerRoute
            exact
            path="/okrs/transparentlist"
            component={OKRTransparent}
          />
          <UserManagerRoute
            exact
            path="/okrs/dialogue"
            component={DialogueRecord}
          />

          {/* <UserRoute exact path="/okrs/dashboard/team" component={OKRsDashboardTeamPage} /> */}
          {/* <UserRoute exact path="/okrs/dashboard/organization" component={OKRsDashboardTeamPage} /> */}

          {/* <UserRoute exact path="/okrs/team-planning" component={OKRsTeamPlanningPage} />
          <UserRoute exact path="/okrs/team-planning/information/:idTeamObjective" component={OKRsTeamPlanningPage} /> */}

          {/* <UserRoute exact path="/okrs/update-plannig/individual-objective" component={OKRsIndividualKeyResultUpdatePage} /> */}
          {/* <UserRoute exact path="/okrs/update-plannig/individual-objective/:idIndividualObjective" component={OKRsIndividualKeyResultUpdatePage} /> */}
          {/* <UserRoute exact path="/okrs/update-plannig/individual-objective/:idIndividualObjective" component={OKRsIndividualKeyResultUpdatePage} /> */}
          {/* <UserRoute exact path="/okrs/individual-planning/update-status" component={OKRsIndividualKeyResultUpdatePage} /> */}
          {/* <UserRoute exact path="/okrs/individual-planning/update-status/keyresult/:idIndividualKeyResult" component={OKRsIndividualKeyResultUpdatePage} /> */}

          {/* <UserRoute exact path="/okrs/approval" component={OKRsApprovalPage} />
          <UserRoute exact path="/okrs/approval/individual/:idIndividualObjective" component={OKRsApprovalIndividualPage} /> */}
          {/* <UserRoute exact path="/okrs/evaluation/individual/:idIndividualObjective" component={OKRsEvaluationIndividualPage} /> */}
          {/* <UserRoute exact path="/okrs/evaluation/individual/:idIndividualObjective" component={OKRsEvaluationIndividualPage} /> */}

          <UserRoute
            exact
            path="/company/interview"
            component={CompanyInterview}
          />
          <UserRoute
            exact
            path="/company-candidate/:type/:id"
            component={CompanyCandidate}
          />
          <UserRoute
            exact
            path="/manage/company/openjob/form"
            component={OpenJobFormPage}
          />
          <UserRoute path="/personal-tax" component={MyPersonalTax} />

          {/* Manager Route */}
          <ManagerRoute
            exact
            path="/waiting-approve"
            component={WaitingApprove}
          />
          <ManagerRoute
            exact
            path="/history-approve"
            component={HistoryApproveList}
          />

          {/* <ManagerRoute
            exact
            path="/manager/otmonitoring"
            component={OTMonitoring}
          /> */}
          <ManagerRoute
            exact
            path="/manager/employee/:idEmployees"
            component={EmployeeProfileManagerPage}
          />
          <ManagerRoute
            exact
            path="/manager/employee"
            component={EmployeeManagerPage}
          />
          <ManagerRoute
            exact
            path="/okrs/team-status"
            component={OKRStatusTeam}
          />
          <ManagerRoute
            exact
            path="/manager/switch-shift"
            component={SwitchShiftEmployeePage}
          />
          <ManagerRoute
            exact
            path="/manager/career"
            component={ManagerCareer}
          />
          <AdminRoute exact path="/admin/Career" component={AdminCareer} />
          <AdminManagerUserRoute
            exact
            path="/jarvis-chatbot"
            component={ChatBotPage}
          />
          <AdminRoute
            exact
            path="/jarvis-chatbot/edit"
            component={EditChatBotDocument}
          />
          {/* <UserRoute exact path="/jarvis-chatbot" component={ChatBot} />
          <ManagerRoute
            exact
            path="/manager/jarvis-chatbot"
            component={ChatBot}
          /> */}

          <ManagerRoute
            exact
            path="/manager/career/viewSuggest/:idEmployees"
            component={ViewSuggest}
          />

          <AdminRoute
            exact
            path="/admin/career/viewSuggest/:idEmployees"
            component={ViewSuggest}
          />

          <ManagerRoute
            exact
            path="/manager/career/suggestCareer/:idEmployees"
            component={SuggestCareer}
          />

          <AdminRoute
            exact
            path="/admin/career/suggestCareer/:idEmployees"
            component={SuggestCareer}
          />

          <UserManagerRoute
            exact
            path="/okrs/team-planning"
            component={OKRsTeamPlanningPage}
          />
          <ManagerRoute
            exact
            path="/okrs/approval/individual"
            component={OKRsApprovalIndividualPage}
          />
          <ManagerRoute
            exact
            path="/okrs/update-progress/team"
            component={OKRsUpdateProgressTeamPage}
          />
          <ManagerRoute
            exact
            path="/okrs/update-progress/team/objective/:idTeamObjective"
            component={OKRsUpdateProgressTeamObjectivePage}
          />
          <ManagerRoute
            exact
            path="/okrs/update-progress/team/keyresult/:idTeamKeyResult"
            component={OKRsUpdateProgressTeamKeyResultPage}
          />
          <ManagerRoute
            exact
            path="/okrs/dashboard/team"
            component={OKRsDashboardTeamPage}
          />
          <ManagerRoute
            exact
            path="/okrs/evaluation/individual"
            component={OKRsEvaluationIndividualPage}
          />
          <ManagerRoute
            path="/managerteamdashboard"
            component={ManagerTeamDashboard}
          />
          {/* <ManagerRoute
            exact
            path="/manager/dashboard"
            component={DashboardManagerPage}
          /> */}

          {/* <ManagerRoute
            exact
            path="/manager/chartShowMember/Information/:id"
            component={InformationManager}
          /> */}
          {/* <ManagerRoute
            exact
            path="/manager/memberInTeam"
            component={MemberInTeamManager}
          /> */}
          {/* <ManagerRoute exact path="/manager/demand" component={ShowDemand} /> */}
          {/* <ManagerRoute
            exact
            path="/manager/demand/addDemand"
            component={AddDemand}
          /> */}
          {/* <ManagerRoute
            exact
            path="/manager/detailOfMemberWork"
            component={DetailWorkManager}
          /> */}
          {/* <ManagerRoute
            exact
            path="/manager/eachPerson/:id"
            component={EachPersonWhiteManager}
          /> */}
          {/* <ManagerRoute
            exact
            path="/manager/health/BillRequest"
            component={BillRequestM}
          /> */}

          {/* Admin Route */}

          <AdminRoute exact path="/admin/setting" component={SettingPage} />
          {/* <AdminRoute exact path="/employees-documents" component={ReportAdminPage} /> */}
          <AdminUserRoute
            exact
            path="/employees-documents"
            component={EmployeeDocument}
          />
          <AdminRoute
            exact
            path="/employees-documents/edit/:idCompany"
            component={DialogNewDocument}
          />
          <AdminRoute
            exact
            path="/employees-documents/add/:idCompany"
            component={AddDocument}
          />
          {/* ------------------------------------------------- */}
          <AdminUserRoute
            exact
            path="/company-documents"
            component={CompanyDocument}
          />
          <AdminRoute
            exact
            path="/company-documents/edit"
            component={NewDocumentCompany}
          />

          <AdminRoute
            exact
            path="/okrs/management/organization"
            component={OKRsManagementForAdmin}
          />
          <UserRoute
            exact
            path="/okrs/management/individual"
            component={OKRsManagementForUser}
          />
          <UserManagerRoute
            exact
            path="/okrs/management/user-manager"
            component={OKRsManagementForManager}
          />
          <ManagerRoute
            exact
            path="/okrs/management/manager"
            component={OKRsManagementForGeneralManager}
          />

          <AdminRoute
            exact
            path="/smart-documents"
            component={SmartDocuments}
          />

          <AdminRoute
            exact
            path="/smart-documents/create_pdf"
            component={CreatePdf}
          />

          <AdminRoute
            exact
            path="/company-documents/add"
            component={AddCompanyDocument}
          />

          <AdminRoute
            exact
            path="/admin/approve-list"
            component={ApporveStatusListPage}
          />
          <AdminRoute
            exact
            path="/admin/history-approve-list"
            component={HistoryApporveStatusListPage}
          />
          <AdminRoute
            exact
            path="/employees/:id/view"
            component={EmployeeProfilePage}
          />
          <AdminRoute
            exact
            path="/employees/:idEmployees/edit"
            component={KeyAddition}
          />

          <AdminRoute
            exact
            path="/admin/employees/add"
            component={AddEmployeePage}
          />

          <AdminRoute
            exact
            path="/admin/ComparePerson"
            component={SelectPersonToVS}
          />
          <AdminRoute
            exact
            path="/information/:idCompany/:idEmployees"
            component={Information}
          />

          <AdminRoute exact path="/job-profile" component={JobProfile} />

          <AdminRoute
            exact
            path="/admin/shift/list-employee"
            component={ShiftListEmployeePage}
            canFillIn={true}
          />
          <AdminRoute
            exact
            path="/admin/shift/new"
            component={ShiftAddNewPage}
            canFillIn={true}
          />
          <AdminRoute
            exact
            path="/admin/shift/year/:id"
            component={PlanYearPage}
            canFillIn={true}
          />
          <AdminRoute
            exact
            path="/admin/shift"
            component={ShiftPage}
            canFillIn={true}
          />
          <AdminRoute exact path="/admin/talent" component={TalentPage} />
          <AdminRoute
            exact
            path="/talent-management/list-info-page/:name/:idTalent/:idCompany/:year"
            component={ListInfoPage}
          />
          <AdminRoute
            exact
            path="/admin/otmonitoring"
            component={AdminOtMonitoring}
          />

          <AdminRoute
            exact
            path="/admin/monthly-overtime-report"
            component={MonthlyOvertime}
          />

          <ManagerRoute
            exact
            path="/manager/monthly-overtime-report"
            component={MonthlyOvertime}
          />

          <ManagerRoute
            exact
            path="/manager/otmonitoring"
            component={AdminOtMonitoring}
          />
          {/* <AdminRoute
            exact
            path="/admin/manpower/:id"
            component={TeamManPowerPage}
          /> */}
          <AdminRoute exact path="/admin/manpower" component={ManPowerPage} />
          <AdminRoute exact path="/admin/request" component={RequestPage} />
          <AdminRoute
            exact
            path="/admin/request/information/:id"
            component={RequestInformationPage}
          />
          <AdminRoute
            exact
            path="/admin/request/form"
            component={RequestFormPage}
          />
          <AdminRoute
            exact
            path="/admin/request/candidate/:idCandidate"
            component={CandidateProfilePage}
          />
          {/* <AdminRoute
            exact
            path="/admin/organization"
            component={OrganizationChartPage}
          /> */}
          <AdminRoute
            exact
            path="/admin/organization"
            component={OrganizationChartV2Page}
          />
          <AdminRoute
            exact
            path="/admin/project-list"
            component={ProjectListPage}
          />
          <AdminRoute
            exact
            path="/admin/project-assign/:projectName"
            component={ProjectOrganizationChartPage}
          />

          <AdminRoute
            exact
            path="/admin/payroll/run/new"
            component={PayRunsNewPage}
          />

          <AdminRoute
            exact
            path="/payroll/run/:idPayrun"
            component={PayRunsEditPage}
          />

          {/* <AdminRoute
            exact
            path="/admin/payroll/run/edit"
            component={PayRunsEditPage}
          /> */}

          <AdminRoute exact path="/payroll" component={PayRunsPage} />
          <AdminRoute
            exact
            path="/payroll/summary-year/:year/:idCompany"
            component={PayrollSummaryYearPage}
          />
          <AdminRoute
            exact
            path="/payroll/setting"
            component={PayrollSettingPage}
          />

          <AdminRoute
            exact
            path="/payroll/addition-deduction"
            component={PayrollAdditionDeductionPage}
          />

          <AdminRoute
            exact
            path="/admin/company-profile"
            component={CompanyProfilePage}
          />

          <AdminRoute exact path="/admin/selectkey/add" component={SelectKey} />

          {/* <AdminRoute
            exact
            path="/admin/courses/:id"
            component={CourseDetailAdmin}
          />
          <AdminRoute exact path="/admin/courses" component={CoursePageAdmin} /> */}

          <AdminRoute
            exact
            path="/admin/jobStructure"
            component={DetailOfJob}
          />
          {/* <AdminRoute
            exact
            path={[
              "/admin/showAllPosition/showDetail/:JobPosition :backPosition :id :jobPosition",
              "/admin/jobStructure/showDetail/:JobPosition :backPosition :id :jobPosition",
            ]}
            component={ShowDetailPosition}
          /> */}

          <AdminRoute
            exact
            path={["/admin/showAllPosition/showDetail"]}
            component={ShowDetailPosition}
          />
          <AdminRoute
            exact
            path={["/admin/showAllPosition"]}
            component={ShowAllPosition}
          />

          <AdminRoute exact path="/admin/addJobLevel" component={AddJobLevel} />

          {/* <AdminRoute exact path="/admin/showTask" component={ShowJobTask} />
          <AdminRoute
            exact
            path="/admin/task/taskList/:name"
            component={ShowTask}
          />
          <AdminRoute exact path="/admin/task/addTask" component={FormTask} />
          <AdminRoute exact path="/admin/editTask/:id" component={EditTask} /> */}

          {/*  */}
          <AdminRoute
            exact
            path="/admin/showDivision"
            component={ShowDivision}
          />
          <AdminRoute
            exact
            path={[
              "/admin/showDivision/showMemberInDivision/:division :department :section",
            ]}
            component={ShowMemberInDivision}
          />
          <AdminRoute
            exact
            path={[
              "/admin/showDivision/showMemberInDivision/:division :department :section/information/:id",
            ]}
            component={InformationDivisionAdmin}
          />
          <AdminRoute
            exact
            path="/admin/showDivision/addDivision"
            component={AddDivision}
          />

          {/* <AdminRoute
            exact
            path="/admin/manageDemand"
            component={ManageDemand}
          /> */}

          {/* <AdminRoute
            exact
            path="/admin/ComparePerson"
            component={SelectPersonToVS}
          /> */}

          <AdminRoute
            exact
            path="/admin/jobStructure/formJob/:name"
            component={FormJobStructure}
          />

          {/* <AdminRoute
            exact
            path="/warehouse/FormLifeStyle"
            component={FormLifeStyle}
          />
          <AdminRoute
            exact
            path="/warehouse/FormTravel"
            component={FormTravel}
          />
          <AdminRoute
            exact
            path="/warehouse/FormInsurance"
            component={FormInsurance}
          />
          <AdminRoute
            exact
            path="/warehouse/FormPackageHealthCheck"
            component={FormpackageHealthChrck}
          /> */}

          {/* Admin OKRs  */}
          <AdminManagerUserRoute
            exact
            path="/okrs/organization-planning"
            component={OKRsOrganizationPlanningPage}
          />
          <AdminRoute
            exact
            path="/okrs/organization-planning/form"
            component={OKRsOrganizationPlanningAddFormPage}
          />
          <AdminManagerUserRoute
            exact
            path="/okrs/organization-planning/information/:idOrganizationObjective"
            component={OKRsOrganizationPlanningInformationPage}
          />
          <AdminRoute
            exact
            path="/okrs/organization-progress"
            component={OKRsUpdateProgressOrganizationPage}
          />
          <AdminRoute
            exact
            path="/okrs/organization-progress/objective/:idOrganizationObjective"
            component={OKRsUpdateProgressOrganizationObjectivePage}
          />
          <AdminRoute
            exact
            path="/okrs/organization-progress/keyresult/:idOrganizationKeyResult"
            component={OKRsUpdateProgressOrganizationKeyResultPage}
          />
          <AdminRoute
            exact
            path="/okrs/dashboard/organization"
            component={OKRsDashboardOrganizationPage}
          />
          {/* <AdminRoute
            exact
            path="/okrs/organization-evaluation"
            component={OKRsEvaluationOrganizationPage}
          /> */}
          <AdminRoute exact path="/okrs/scoreboard" component={OKRScoreboard} />
          <AdminRoute exact path="/okrs/calibrate" component={Calibrate} />
          <AdminRoute
            exact
            path="/okrs/calibrate-department/:id"
            component={CalibrateDepartment}
          />
          <AdminRoute
            exact
            path="/okrs/status-plan-approve"
            component={OKRStatusPlanApprove}
          />
          <AdminRoute exact path="/okrs/status" component={OKRStatus} />
          <AdminRoute
            exact
            path="/okrs/okr-employee-view"
            component={OKRsEmployeeView}
          />
          {/* <AdminRoute exact path="/okrs/approve" component={ApprovalList} /> */}
          <AdminRoute exact path="/kpi-date" component={KpiEditdate} />
          <AdminRoute
            exact
            path="/kpi-people-assign"
            component={KpiAdminIndividual}
          />
          <AdminManagerRoute
            exact
            path="/kpi-plan-update"
            component={KpiAdminPlanUpdate}
          />
          <AdminRoute
            exact
            path="/kpi-admin-evalution"
            component={KpiAdminEvalution}
          />
          <AdminRoute exact path="/kpi-admin-plan" component={KpiAdminPlan} />
          {/* kpi */}
          <Route exact path="/kpi" component={KPIPage} />
          <UserRoute
            exact
            path="/kpi-individual"
            component={KpisIndividualPlanList}
          />
          <UserManagerRoute
            exact
            path="/kpi-individual-plan"
            component={KpisIndividualPlan}
          />
          <Route
            exact
            path="/kpi-individual-update"
            component={KpisIndividualPlanUpdate}
          />
          <Route
            exact
            path="/kpi-individual-planing"
            component={KpisIndividualPlaningUpdate}
          />
          <ManagerRoute
            exact
            path="/kpi-approval-list"
            component={KpisApprovalList}
          />
          <ManagerRoute
            exact
            path="/kpi-approval-planlist"
            component={KpisApprovalPlanList}
          />
          <ManagerRoute
            exact
            path="/kpi-people-list"
            component={KpiManageIndividual}
          />
          <ManagerRoute
            exact
            path="/kpi-manage-evalution"
            component={KpiManageEvalution}
          />
          <ManagerRoute
            exact
            path="/kpi-manage-plan"
            component={KpiManagePlan}
          />
          <ManagerRoute exact path="/kpi-approval" component={KpiApproval} />
          <UserManagerRoute
            Route
            exact
            path="/kpi-individual-view"
            component={KpisIndividualPlanView}
          />
          <Route exact path="/kpi-update" component={KpisUpdate} />
          <Route exact path="/kpi-plan" component={KpisPlan} />
          <UserManagerRoute
            exact
            path="/kpi-evaluation-list"
            component={KpiEvaluationList}
          />
          <UserManagerRoute
            exact
            path="/kpi-evaluation"
            component={KpiEvaluation}
          />
          <AdminManagerRoute
            Route
            exact
            path="/kpi-calibrate"
            component={KpiCalibrate}
          />
          {/* <Route
            exact
            path="/kpiDepartmentCalibrate/:id"
            component={KpiCalibrateDepartment}
          /> */}
          <AdminRoute
            exact
            path="/kpi-successful-All"
            component={KpiIsSuccessfulAll}
          />
          <ManagerRoute
            exact
            path="/kpi-successful"
            component={KpiIsSuccessful}
          />
          <AdminRoute
            exact
            path="/kpi/organization"
            component={KpiOrganization}
          />
          <AdminRoute
            exact
            path="/kpi/organization/plan"
            component={KpiOrganizationPlan}
          />
          <AdminManagerUserRoute
            exact
            path="/kpi/organization/update"
            component={KpiOrganizationUpdate}
          />
          <AdminRoute
            exact
            path="/kpi/organization/cascade"
            component={KpiOrganizationCascade}
          />
          <AdminRoute
            exact
            path="/kpi/organization/evaluate"
            component={KpiOrganizationEvaluate}
          />
          <UserManagerRoute
            exact
            path="/kpi/organization/user"
            component={KpiOrganizationUser}
          />
          <UserManagerRoute
            exact
            path="/kpi/organization/update/user"
            component={KpiOrganizationUpdateUser}
          />
          <AdminRoute
            exact
            path="/kpi/team/admin"
            component={KpiTeam}
          />
          <ManagerRoute
            exact
            path="/kpi/team/manager"
            component={KpiTeamManager}
          />
          <UserRoute
            exact
            path="/kpi/team/user"
            component={KpiTeamUser}
          />
          <UserRoute
            exact
            path="/kpi/team/user/update"
            component={KpiTeamUpdateUser}
          />
          <AdminRoute
            exact
            path="/kpi/team/plan/admin"
            component={KpiTeamPlan}
          />
          <ManagerRoute
            exact
            path="/kpi/team/plan/manager"
            component={KpiTeamPlanManager}
          />
          <ManagerRoute
            exact
            path="/kpi/team/evaluate/manager"
            component={KpiTeamEvaluateManager}
          />
          <AdminManagerRoute
            exact
            path="/kpi/team/update"
            component={KpiTeamUpdate}
          />
          <AdminManagerRoute
            exact
            path="/kpi/team/evaluate"
            component={KpiTeamEvaluate}
          />
          <AdminRoute
            exact
            path="/organizationList"
            component={OrganizationPlanList}
          />
          <AdminRoute exact path="/organization" component={OrganizationPlan} />
          <AdminRoute exact path="/teamlist" component={TeamPlanList} />
          <AdminRoute exact path="/team" component={TeamPlan} />
          <AdminRoute
            exact
            path="/individualList"
            component={IndividualPlanList}
          />
          <AdminRoute exact path="/individual" component={IndividualPlan} />
          <AdminRoute
            exact
            path="/employeesuccesslist"
            component={EmpSuccessList}
          />
          <AdminRoute
            exact
            path="/failurehistorylist"
            component={FailureHistoryList}
          />
          <AdminRoute exact path="/failurehistory" component={FailureHistory} />
          <AdminRoute
            exact
            path="/failurehistoryview"
            component={FailureHistoryView}
          />
          <AdminRoute exact path="/approval" component={Approval} />
          <AdminRoute
            exact
            path="/employeeplanlist/:name"
            component={EmployeePlanList}
          />
          <AdminRoute
            exact
            path="/individualplanevaluation/:id"
            component={IndividualPlanEvaluation}
          />
          <AdminRoute
            exact
            path="/approval/crossteam/:id"
            component={ManagerTeamApprove}
          />
          <AdminRoute
            exact
            path="/evaluationmanager/:name"
            component={EvaluateManager}
          />
          <AdminRoute
            exact
            path="/organizationupdate"
            component={OrganizationUpdate}
          />
          <AdminRoute
            exact
            path="/organizationrecords/:name"
            component={OrganizationRecord}
          />
          <AdminRoute exact path="/updateKROrg/:name" component={UpdateKROrg} />
          <AdminRoute exact path="/teamupdate" component={TeamUpdate} />
          <AdminRoute
            exact
            path="/updateKRTeam/:name"
            component={UpdateKRTeam}
          />
          <AdminRoute path="/teamrecords" component={TeamRecord} />
          <AdminRoute path="/individualupdate" component={IndividualUpdate} />
          <AdminRoute exact path="/updateKRIdv/:name" component={UpdateKRIdv} />
          <AdminRoute path="/individualrecords" component={IndividualRecord} />
          <AdminRoute path="/okrdashboard" component={DashboardOKR} />
          {/* <AdminRoute path="/orgvaluationlist" component={OrgEvaluationList} /> */}
          <AdminRoute
            path="/organizationevaluation/:name"
            component={OrgEvaluation}
          />
          <AdminRoute
            path="/teamevaluationlist"
            component={TeamEvaluationList}
          />
          <AdminRoute path="/teamevaluation/:name" component={TeamEvaluation} />
          <AdminRoute path="/idvevaluationlist" component={IdvEvaluationList} />
          <AdminRoute
            path="/individual-evaluation/:name"
            component={IdvEvaluation}
          />
          <AdminRoute path="/successprofile" component={SuccessProfile} />
          <AdminRoute path="/dialoguehistory" component={DialogueHistory} />
          <AdminManagerRoute
            path="/dialogue-record"
            component={DialogueRecordView}
          />
          <AdminRoute path="/cascademain" component={CascadeMain} />
          <UserRoute
            path="/individual-dashboard"
            component={IndividualDashboard}
          />
          {/* <AdminRoute
            path="/managerteamdashboard"
            component={ManagerTeamDashboard}
          /> */}
          <AdminRoute
            path="/idvplanlistapproved"
            component={IdvPlanListApproved}
          />
          <AdminRoute path="/leaderboard" component={Leaderboard} />
          <AdminRoute
            path="/organizationdashboard"
            component={OrganizationDashboard}
          />
          <AdminRoute path="/leaderboardOrg" component={LeaderboardOrg} />

          <AdminRoute
            path="/employees-documents/diligence-allowance"
            component={DiligenceAllowancePage}
          />
          <AdminRoute
            path="/employees-documents/:type"
            component={PND91AND50tawi}
          />
          <AdminRoute
            path="/employees-summary-time"
            component={SummaryTimeAdminPage}
          />

          <AdminManagerRoute
            exact
            path="/employees-report"
            component={All_ReportAdminPage}
          />

          {/* <AdminRoute
            exact
            path="/document-service"
            component={ReportAdminPage}
          /> */}
          <AdminRoute exact path="/successor" component={Successor} />
          <AdminRoute
            exact
            path="/successor/detailPage/:idEmployees"
            component={DetailPage}
          />

          <AdminRoute
            exact
            path="/employees-report/leave"
            component={LeavePage}
          />
          <AdminManagerRoute
            exact
            path="/employees-report/leave-absent-late"
            component={LeaveAbsentLate}
          />
          <AdminManagerRoute
            exact
            path="/employees-report/leave-absent-late-payroll"
            component={LeaveAbsentLatePayroll}
          />

          <AdminManagerRoute
            exact
            path="/employees-report/quota-leave-list"
            component={QuotaLeaveList}
          />
          <AdminRoute
            exact
            path="/employees-report/probation"
            component={ProbationReport}
          />
          <AdminRoute
            exact
            path="/employees-report/contractTermination"
            component={ContractTerminationReport}
          />
          <AdminRoute
            exact
            path="/employees-report/newEmployees"
            component={NewEmployeesReport}
          />
          <AdminRoute
            exact
            path="/employees-report/resign"
            component={ResignReport}
          />

          <AdminRoute
            exact
            path="/employees-report/leveling-report"
            component={LevelingReport}
          />

          <AdminRoute
            exact
            path="/employees-report/employment-report"
            component={employmentReport}
          />

          <AdminRoute
            exact
            path="/employees-report/transfer-report"
            component={TransferPosition}
          />

          {/* <AdminRoute
            exact
            path="/employees-report/late-absent"
            component={late_absent}
          /> */}
          <AdminRoute
            exact
            path="/employees-report/cost-element"
            component={CostElement}
          />
          <AdminRoute
            exact
            path="/employees-report/payroll"
            component={Payroll}
          />

          <AdminRoute
            exact
            path="/employees-report/diligence-allowance"
            component={DiligenceAllowancePage}
          />
          <AdminRoute
            exact
            path="/employees-report/addition-special"
            // component={AdditionSpecialReport}
            component={AdditionSpecialReport}
          />
          <AdminRoute
            exact
            path="/employees-report/salary"
            component={ReportAdminPage}
          />
          <AdminManagerRoute
            exact
            path="/employees-report/summary-ot-shift"
            component={SummaryOTShift}
          />
          <AdminRoute
            exact
            path="/employees-report/dashboard-employee"
            component={DashboardEmployee}
          />

          <AdminRoute
            exact
            path="/employees-report/summary-ot-hours-wages"
            component={DashboardOT}
          />
          <AdminRoute
            exact
            path="/employees-report/provident-fund"
            component={DashboardProvidentFund}
          />
          <AdminRoute
            exact
            path="/employees-report/employees-tax"
            component={DashboardTax}
          />
          <AdminRoute
            exact
            path="/admin/organization-chart"
            component={OrganizationChartNewPage}
          />
          <AdminRoute
            exact
            path="/admin/organization-chart-v2"
            component={OrganizationChartV2Page}
          />
          <AdminRoute
            exact
            path="/admin/organization-chart-v3"
            component={OrganizationChartV3Page}
          />
          <AdminRoute exact path="/manage/hunter/peer" component={PeerPage} />
          <AdminRoute
            exact
            path="/manage/hunter/dashboard"
            component={Dashboard}
          />
          <AdminRoute
            exact
            path="/manage/hunter/peer/form"
            component={AddManpowerFormPage}
          />
          <AdminRoute
            exact
            path="/manage/hunter/peer/profile/:idManpower"
            component={VendorManpowerProfilePage}
          />
          <AdminRoute
            exact
            path="/hunter-manpower-interviewed"
            component={HunterInterviewPage}
          />
          <AdminRoute
            exact
            path="/hunter-manpower-sending"
            component={HunterHistorySending}
          />
          <AdminRoute
            exact
            path="/recruitment-interviewer"
            component={RecruitInterviewer}
          />
          <AdminRoute
            exact
            path="/workforce-planning-cal"
            component={WorkforcePlanningCal}
          />
          <AdminRoute
            exact
            path="/workforce-planning-cal/tableUsers"
            component={WorkforceProjectDetails}
          />
          {/* <AdminManagerRoute
            exact
            path="/workforce-planning-cal/tableProject"
            component={TableProject}
          /> */}
          <AdminRoute
            exact
            path="/employees-report/personal-tax"
            component={PersonalTaxAdminPage}
          />
          <AdminRoute
            exact
            path="/employees-report/turnover-company"
            component={TurnoverList}
          />
          <AdminRoute
            exact
            path="/employees-report/turnover-department"
            component={TurnoverDepartmentList}
          />
          <AdminManagerRoute
            exact
            path="/workforce-planning-cal/tableHour"
            component={TableHour}
          />
          <AdminManagerRoute
            exact
            path="/workforce-planning-cal/detailProjectHour"
            component={detailProjectHour}
          />
          <AdminManagerRoute
            exact
            path="/workforce-planning-cal/dashboardProjectHour"
            component={dashboardProjectHour}
          />
          <AdminRoute
            exact
            path="/workforce-planning-cal/projectDetails"
            component={projectDetails}
          />
          {/* <TrainingRoute exact path="/courses/:id" component={CourseDetail} />
          <TrainingRoute exact path="/courses" component={CoursePage} /> */}
          <AdminRoute
            exact
            path="/payroll/tax-summary"
            component={TaxSummaryPage}
          />
          <AdminWelfareRoute
            exact
            path="/admin/manage/RightsManage"
            component={RightsManage}
          />
          <AdminWelfareRoute
            exact
            path="/welfare-expense"
            component={WelfareExpense}
          />
          <AdminWelfareRoute
            exact
            path="/welfare-expense/create"
            component={WelfareBillingPage}
          />
          <AdminWelfareRoute
            exact
            path="/welfare-expense/history/:type"
            component={WelfareHistoryPage}
          />
          <AdminWelfareRoute
            exact
            path="/welfare-expense/:action/:type/:idExpense"
            component={ExpenseVerifyPage}
          />
          <AdminWelfareUserRoute
            exact
            path="/welfare-rights"
            component={WelfareRights}
          />

          <AdminUserManagerAccountantRoute
            exact
            path="/expense"
            component={ExpensePage}
          />
          <AccountantRoute
            exact
            path="/expense-export"
            component={ExpenseExport}
          />
          <AccountantRoute
            exact
            path="/expense-setting"
            component={ExpenseSetting}
          />
          <AdminManagerAccountantRoute
            exact
            path="/expense/detail/:typeExpense/:idExpense/:idEmp/:mode"
            component={ExpenseDetailPage}
          />
          <AdminManagerAccountantRoute
            exact
            path="/expense/history"
            component={ExpenseHistoryPage}
          />

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </Container>
  );
};

export default Routers;
