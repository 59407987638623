import React, { useState, useEffect } from "react";
import { styled } from "@mui/styles";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import {
  Grid,
  Container,
  Typography,
  Box,
  MenuItem,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getKpiOrganization } from "../../../../../actions/kpi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import KpiCard from "./KpiCard";
import DialogKpiDetail from "../DialogKpiDetail";

const ContainerStyled = styled(Container)({
  height: "100%",
  paddingTop: "100px",
  backgroundColor: "transparent",
  minHeight: "100vh",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto",
    padding: "8px",
  },
});

const YearSelect = styled(TextFieldTheme)({
  width: "100px",
  height: "4 0px",
  "& .MuiOutlinedInput-root": {
    height: "100%",
    "& fieldset": {
      borderColor: "#4535C1",
    },
    "&:hover fieldset": {
      borderColor: "#4535C1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4535C1",
    },
  },
  "& .MuiSelect-select": {
    backgroundColor: "white",
    borderRadius: "8px",
    height: "100%",
  },
});

function KpiOrganizationUser() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: kpiOrganization } = useSelector((state) => state.kpiOrganization);
  const [selectedYear, setSelectedYear] = useState(dayjs().get("year"));
  const [planItems, setPlanItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState(null);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleView = (kpi) => {
    setSelectedKpi(kpi);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    dispatch(getKpiOrganization({ year: selectedYear }));
  }, [selectedYear]);

  useEffect(() => {
    if (kpiOrganization) {
      setPlanItems([...kpiOrganization]);
    }
  }, [kpiOrganization]);

  return (
    <ContainerStyled>
      {kpiOrganization && (
        <>
          <Box marginBottom="32px">
            <GridStyled
              container
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  className="kpi"
                  sx={{ ml: 1, fontSize: "30px" }}
                >
                  {t("OrganizationKPI")}
                </Typography>
              </Box>
            </GridStyled>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography
                variant="h7"
                sx={{ color: "#343C6A", fontSize: "20px", fontWeight: "500" }}
              >
                {t("Years")}
              </Typography>
              <YearSelect
                variant="outlined"
                select
                fullWidth
                value={selectedYear}
                onChange={handleYearChange}
              >
                <MenuItem value={dayjs().get("year")}>
                  {dayjs().format(
                    i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                  )}
                </MenuItem>
                <MenuItem value={dayjs().get("year") - 1}>
                  {dayjs()
                    .subtract(1, "year")
                    .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                </MenuItem>
              </YearSelect>
            </Stack>
          </Box>

          {planItems.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={"4px"}
            >
              <FindInPageIcon />
              <Typography variant="h6">{t("NoData")}</Typography>
            </Box>
          ) : (
            planItems.map((item) => (
              <KpiCard
                key={item.idKpiOrganization}
                kpi={item}
                onView={handleView}
              />
            ))
          )}

          {openDialog && selectedKpi && (
            <DialogKpiDetail
              open={openDialog}
              handleClose={handleCloseDialog}
              selectedKpi={selectedKpi}
            />
          )}
        </>
      )}
    </ContainerStyled>
  );
}

export default KpiOrganizationUser;
