import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Box,
  Container,
  Typography,
  Divider,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent, 
  DialogTitle 
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import Dropzone, { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddPhotoIcon from "../../../../components/pages/assets/add.png";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonBlue from "../../shared/general/ButtonBlue";
import BackupIcon from '@mui/icons-material/Backup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff'; 
import DatePickerCustom from "../../shared/date/datePicker";
import { getAffiliateOrganization } from "../../../../actions/company";
import Lottie from 'lottie-react';
import UploadFile from './Animation/UploadFile.json'

import {
  getAllDocuments,
  addAllCompanyDocumentType,
  getAllCompanyDocumentGroupName, 
  getAllCompanyDocumentType
} from "../../../../actions/document";


const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
});

const StyledDropzone = styled(Box)({
    width: "100%",
    marginBottom: 12,
    backgroundColor: "#f9f9fb",
    "& .dropzone-leave": {
      // height: 98,
      borderRadius: 4,
      padding: 8,
      border: "1px dashed rgba(145, 158, 171, 0.32)",
    },
  
    "& .inner-dropzone": {
      cursor: "pointer",
      zIndex: 0,
      width: "100%",
      // height: 90,
      outline: "none",
      display: "flex",
      overflow: "hidden",
      borderRadius: 4,
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "#efefef",
      flexDirection: "column",
      padding: "4px 0",
      "& img": {
        width: 72,
        height: 72,
      },
    },
    "& .inner-dropzone:hover .placeholder": {
      zIndex: 9,
    },
  });

const theme = createTheme({
  palette: {
    primary: {
      main: '#039be5', // สีของปุ่ม primary
    },
    alert: {
      main: '#283593', // สีของ Dialog title background
      text: '#283593', // สีของข้อความใน Dialog content
    },
  },
});

const addCompanyDocument = () => {
  const dispatch = useDispatch();
  const [view, setView] = React.useState(1);
  const [value, setValue] = useState(false);
  const { result: companyDocumentGroup} = useSelector((state) => state.companyDocumentGroup);
  const { result: allCompanyDocumentType} = useSelector((state) => state.allCompanyDocumentType);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllDocuments());
    dispatch(getAffiliateOrganization());
  }, [view, dispatch]);  

  useEffect(() => {
    dispatch(getAllCompanyDocumentGroupName());
    dispatch(getAllCompanyDocumentType());
  }, []);

  const {
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const validFileExtensions = { file: ["pdf"] };

  const validationSchema = Yup.object().shape({
      file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
  });

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const { t, i18n } = useTranslation();
  
  const handleDrop = (acceptedFiles) => {
    const newFileUrls = acceptedFiles.map(file => URL.createObjectURL(file));
    setFormData(prevState => ({
      ...prevState,
      files: [...prevState.files, ...acceptedFiles],
      fileUrls: [...prevState.fileUrls, ...newFileUrls],
    }));
  };

  const  handleDeleteFile = (index) => {
    const newFiles = [...formData.files];
    const newFileUrls = [...formData.fileUrls];
    newFiles.splice(index, 1);
    newFileUrls.splice(index, 1);

    setFormData({
      files: newFiles,
      fileUrls: newFileUrls,
    });
  };

  const handleDeleteAll = () => {

    formData.fileUrls.forEach(url => {
      URL.revokeObjectURL(url);
    });

    setFormData({
      files: [],
      fileUrls: [],
    });
  };

  const truncateFileName = (fileName) => {
    if (fileName.length > 5) {
      return `${fileName.substring(0, 5)}...`;
    }
    return fileName;
  };

  const [formData, setFormData] = useState({ 
    startDate: null,
    endDate: null,
    files: [],
    fileUrls: [],
    detail: null,
  });
  
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(`${t("Please_select_document_typeName")}`);
  const [message, setMessage] = useState('');

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
    setMessage(`${t("Please_select_document_typeName")}`); 
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertMessage(`${t("Please_select_document_typeName")}`);
  };
  
  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const AlertDialog = ({ open, onClose, message }) => {
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.palette.alert.main, color: 'white' }}>
            <Typography variant="h6">
              {t("Alert")}
            </Typography>
            <IconButton aria-label="close" onClick={onClose} sx={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
          <Typography
            variant="body1"
            align="center"
            id="alert-dialog-description"
            sx={{
              color: theme.palette.alert.text,
              marginTop: '19px',
              fontWeight: 'semi-bold', // ใช้ 'bold' แทน 'semi-bold' ถ้าต้องการเพิ่มความหนา
              padding: '16px',
              borderColor: theme.palette.primary.main,
              fontFamily: 'Roboto, Arial, sans-serif', 
              fontSize: '22px', 
              lineHeight: '1.5',
            }}
          >
            {message}
          </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: '16px', backgroundColor: '#f5f5f5' }}>
            <Button onClick={onClose} color="primary" autoFocus variant="contained">
             {`${t("Ok")}`}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");

  const handleChange = (event) => {
    const newValue = event.target.value === 'true'; 
    setValue(newValue);
  
    if (!newValue) {
      setFormData({  
        ...formData,
        startDate: null,
        endDate: null
      });
    }
  };

  const [selectedDocumentType, setSelectedDocumentType] = useState({
    documentTypeName: '',
    idCompanyDocumentType: '',
    idCompanyDocumentGroup: ''
  });
  console.log('selectedDocumentType: ', selectedDocumentType);

  const handleChangeDocument = (event) => {
    const selectedId = event.target.value
    console.log('selectedId: ', selectedId);

    const selectedDocument = allCompanyDocumentType.find(doc => doc.documentTypeName === selectedId);
    console.log('selectedDocument : ', selectedDocument );
    const idCompayDocumentType = selectedDocument.idCompanyDocumentType;
    const idCompanyDocumentGroup  = selectedDocument.idCompanyDocumentGroup;    

    if (selectedDocument) {
      setSelectedDocumentType({
        idCompanyDocumentType:  idCompayDocumentType,
        documentTypeName: selectedDocument.documentTypeName,
        idCompanyDocumentGroup: idCompanyDocumentGroup
      });
    } else {
      setSelectedDocumentType({
        idCompanyDocumentType: '',
        documentTypeName: '',
        idCompanyDocumentGroup: ''
      });
    }
  };
  

  const handleOnSubmit = async () => {
    if (selectedDocumentType.idCompanyDocumentType === '' || !formData.files) {
      setAlertOpen(true);
      handleChangeAlertType(`${t("ThisFieldIsRequired")}`);
    } else {
        const data = {
          startDate: formData.startDate !== undefined ? formData.startDate : null,
          endDate: formData.endDate !== undefined ? formData.endDate : null,
          idCompanyDocumentType: selectedDocumentType.idCompanyDocumentType,
          documentTypeName: selectedDocumentType.documentTypeName,
          idCompanyDocumentGroup: selectedDocumentType.idCompanyDocumentGroup,
          createDate: dayjs(new Date()).format("YYYY-MM-DD"),
          updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
        };
 
        if (formData.endDate === null) delete data.endDate;
        if (formData.startDate === null) delete data.startDate;
        
        if (data.startDate === undefined) {
          data.startDate = null;
        }
        if (data.endDate === undefined) {
          data.endDate = null;
        }

        const postData = new FormData();
  
        Object.keys(data).forEach((key) => {
          postData.append(key, JSON.stringify(data[key]));
        });

        formData.files.forEach((file) => {
          postData.append('files', file);
        });

        setLoading(true);
        try {    
          setUploadStatus("uploading");
          const result = await dispatch(addAllCompanyDocumentType(postData, {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
          }));
          if (result) {
            setAlertMessage(`${t("Successfully uploaded!!")}`);
            setUploadStatus("done");
          } else {
            setAlertMessage("An error occurred uploading.");
            setUploadStatus("select");
          }
          setAlertOpen(true);
        } catch (error) {
          console.error("Error adding document type:", error);
          setAlertMessage("An error occurred uploading.");
          setAlertOpen(true);
          setUploadStatus("select");
        } finally {
          setLoading(false);
        }
      }
  };

  const lottieRef = useRef(null);

  useEffect(() => {
    if (lottieRef.current) {
      if (!loading ) {
        lottieRef.current.play();
      }
    }
  }, [loading]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
      <Box 
        className="wrap-search-action" 
        display="flex" 
        justifyContent="center" 
        alignItems="center"
        sx={{ pt: 0, pb: 2 }}
      >
        <Typography variant="h4" sx={{ pt: 1, pb: 2, userSelect: 'none' }}>
          {t("AddDocumentFile")}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{pt: 4, pb: 5}}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
            <ButtonBlue
              variant="contained"
              sx={{
                borderRadius: 16, 
                padding: 1, 
                boxShadow: 2, 
                width: '50%', 
                margin: 'auto', 
              }}
            >
              <FileUploadOutlinedIcon sx={{ mr: 1, pt: 1.5 }} />
              <Typography variant="h6" sx={{ pt: 1.5, color: "white" }}>
                {t("UploadDocument")}
              </Typography> 
            </ButtonBlue>
        </Box>
      </Box>
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center"
        sx={{ pt: 5, pb:2 }}
      >
        <Grid item xs={12} className="GridTopicInput">
         <Typography 
            className="label" 
           sx={{color: "#424242", userSelect: 'none'}}
         >
            {`${t("DocumentName")}`}
          </Typography>
        </Grid>
     </Box>
     <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center"
        sx={{ pt: 1, pb:2 }}
      >
       <FormControl sx={{ width: '50%', maxWidth: '50%' }}>
                <Select
                      name="documentTypeName"
                      value={selectedDocumentType.documentTypeName || ''}
                      onChange={handleChangeDocument}
                      required
                  >
                       {(allCompanyDocumentType  || []).map((row) => (
                           <MenuItem
                             key={row.documentTypeName}
                             value={row.documentTypeName}
                           >
                              {row.documentTypeName}
                          </MenuItem>
                      ))}
                 </Select>
        </FormControl>  
      </Box>
      {formData.fileUrls.length > 0 && (
        <>
          <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
                sx={{ pt: 4 }}
              >
              <Typography sx={{ mr: 8 }}>{t("ExpirationDate")}</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={`${t("Yes")}`}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={`${t("No")}`}
                  />
                </RadioGroup>
              </FormControl>
          </Box>
          <Box 
                justifyContent="flex-center" 
                alignItems="center"
                sx={{ pt: 4 }}
              >
                      {value ? (
                        <>
                          <Grid item xs={12} sm={6} className="GridTopicInput">
                            {t("Start")}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <DatePickerCustom
                              value={formData.startDate}
                              inputFormat="DD/MM/YYYY"
                              name="startDate"
                              onChange={(newValue) => {
                                setFormData({
                                  ...formData,
                                  ["startDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth helperText={null} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} className="GridTopicInput">
                            {t("End")}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <DatePickerCustom
                              value={formData.endDate}
                              inputFormat="DD/MM/YYYY"
                              name="endDate"
                              onChange={(newValue) => {
                                setFormData({
                                  ...formData,
                                  ["endDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth helperText={null} />
                              )}
                            />
                          </Grid>
                        </>
                      ) : (
                        ""
                  )}
          </Box> 
        </>
      )}
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center"
        sx={{ pt: 2 }}
      >
        <Grid item xs={12} >
         {loading ? (
                 <Box sx={{ pb:10 }}>
                   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
                      <Typography 
                        variant="h6" 
                        style={{
                          marginTop: '16px',
                          color: '#3f51b5',
                          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                          fontWeight: 'bold',
                          userSelect: 'none',
                        }}
                        >
                          กำลังอัพโหลดไฟล์เอกสาร...
                      </Typography>
                   </div>
                   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px', userSelect: 'none' }}>
                    <div style={{ width: '60%', height: '60%' }}>
                        <Lottie
                            lottieRef={lottieRef}
                            animationData={UploadFile} 
                            loop={true}
                            autoplay={true}
                          />   
                      </div>
                   </div>
                   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', userSelect: 'none' }}>
                    <Box>
                          {formData.files.length === 0 ? (
                                <>
                                </>
                              ) : (
                                <>   
                                  <Box 
                                    display="flex" 
                                    justifyContent="center" 
                                    alignItems="center"
                                    sx={{ pt: 2.5, pb: 2 }}
                                  >
                                    {uploadStatus === "uploading" && (
                                      <Box sx={{ mt: 2, width: "100%" }}>
                                        <Typography variant="caption" sx={{ color: "#3f51b5", fontWeight: "bold", mb: 1 }}>
                                          {`กำลังอัปโหลด: ${progress}%`}
                                        </Typography>
                                        <Box sx={{ width: "100%", backgroundColor: "#e0e0e0", height: 10, borderRadius: 5, boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.12)" }}>
                                          <Box sx={{ 
                                            width: `${progress}%`, 
                                            backgroundColor: "#3f51b5", 
                                            height: "100%", 
                                            borderRadius: 5, 
                                            transition: "width 0.5s ease-in-out",
                                            boxShadow: "0 3px 3px -5px #3f51b5, 0 2px 5px #3f51b5" 
                                          }} />
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                              </>
                          )}
                    </Box>
                   </div>
                  </Box>
                 ) : (
                 <>
                  <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center"
                    sx={{ pt: 1, pb:1 }}
                  >
                        {selectedDocumentType && (
                          <div>
                            <Typography 
                                className="label"
                                sx={{color: "#424242"}}
                            >
                                {`${t("AttachFile")} ${selectedDocumentType.documentTypeName}`}
                              </Typography>
                          </div> )}
                        {!selectedDocumentType && (
                          <div>
                            <Typography 
                                className="label"
                                sx={{color: "#424242"}}
                            >  
                                {t("AttachFile")} 
                            </Typography>
                          </div>
                        )}
                  </Box>
                  <Box>  
                        <StyledDropzone>
                          <Dropzone
                            accept="application/pdf"
                            multiple={true}
                            onDrop={handleDrop}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps({ className: "dropzone-leave" })}>
                                <div className="inner-dropzone">
                                  <input {...getInputProps()} />
                                  <div style={{ textAlign: "center" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        userSelect: 'none',
                                      }}
                                    >
                                      <img alt="add" src={AddPhotoIcon} style={{userSelect: 'none'}} />
                                    </div>
                                    <Typography
                                      variant="caption"
                                      style={{ lineHeight: 0.5, color: "#999999", userSelect: 'none' }}
                                    >
                                      {t('AllowedPdfMaxSize')}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </StyledDropzone>
                        <Box>
                          {formData.files.length === 0 ? (
                            <>
                            <Box 
                              display="flex"  
                              justifyContent="center" 
                              alignItems="center"
                              sx={{ pt: 2 }}
                            >
                                <ButtonBlue
                                  variant="contained"
                                  startIcon={<BackupIcon />}
                                  onClick={() => handleOnSubmit(watch("file"))} 
                                  disabled 
                                >
                                  {t("Upload")}
                                </ButtonBlue>
                                <AlertDialog
                                  open={alertOpen}
                                  onClose={handleCloseAlert}
                                  message={alertMessage}
                                />
                              </Box>
                              </>
                            ) : (
                              <>   
                                <Box 
                                  display="flex" 
                                  justifyContent="center" 
                                  alignItems="center"
                                  sx={{ pt: 2.5, pb:2 }}
                                >
                                  <ButtonBlue
                                    variant="contained"
                                    startIcon={<BackupIcon />}
                                    onClick={() => handleOnSubmit(watch("file"))} 
                                  >
                                    {uploadStatus === "select" || uploadStatus === "uploading"
                                        ? "อัพโหลด"
                                        : "เสร็จสิ้น"}
                                  </ButtonBlue>
                                  <AlertDialog
                                  open={alertOpen}
                                  onClose={handleCloseAlert}
                                  message={alertMessage}
                                  />
                                </Box>
                                <Box 
                                  display="flex" 
                                  justifyContent="center" 
                                  alignItems="center"
                                  sx={{ pt: 2.5, pb: 2 }}
                                >
                                  {uploadStatus === "uploading" && (
                                    <Box sx={{ mt: 2, width: "100%" }}>
                                      <Typography variant="caption" sx={{ color: "#3f51b5", fontWeight: "bold", mb: 1 }}>
                                        {`กำลังอัปโหลด: ${progress}%`}
                                      </Typography>
                                      <Box sx={{ width: "100%", backgroundColor: "#e0e0e0", height: 10, borderRadius: 5, boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.12)" }}>
                                        <Box sx={{ 
                                          width: `${progress}%`, 
                                          backgroundColor: "#3f51b5", 
                                          height: "100%", 
                                          borderRadius: 5, 
                                          transition: "width 0.5s ease-in-out",
                                          boxShadow: "0 3px 3px -5px #3f51b5, 0 2px 5px #3f51b5" 
                                        }} />
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </>
                            )}
                        </Box>
                  </Box>
                </>
                 )
           }
        </Grid>   
        </Box>
         <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ pt: 4 }}>
            {formData.fileUrls.length > 0 && (
              <Typography variant="h6">
                  {t("Uploaded Files:")}
              </Typography>
            )}
         <Grid container spacing={2} sx={{ mt: 2 }}>
            {formData.fileUrls.map((url, index) => (
              <Grid item xs={12} sm={6} md={4} lg={1} key={index}>
               <Box sx={{ mb: 2 }}>
                <Box sx={{ position: 'relative' }}>
                  <Box sx={{ width: '100%', height: '100px', overflow: 'hidden' }}>
                    <iframe
                      src={url + '#page=1&zoom=fitwidth'}
                      title="PDF Document"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      scrolling="no"
                    >
                      <p>
                        It appears you don't have a PDF plugin for this browser. No worries,{' '}
                        you can <a href={url} download>download the PDF file</a>.
                      </p>
                    </iframe>
                  </Box>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteFile(index)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 1, 
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
                <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center"
                    sx={{ pt: 2 }}
                >     
                  {formData.files[index] && truncateFileName(formData.files[index].name)}
               </Box>
               </Box>
             </Grid>
            ))}
          </Grid> 
        </Box>
        <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center"
            sx={{ pt: 2, pb: 3 }}
           >    
              {formData.fileUrls.length > 0  && (
              <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteAll}
                    sx={{
                      textTransform: 'none',
                      backgroundColor: '#d32f2f',
                      '&:hover': {
                        backgroundColor: '#e84e40',
                      },
                    }}
                  > 
                    {t("Delete All")}
              </Button>
              )}
        </Box>
    </Container>
    </StyledRoot>
  )

}

export default addCompanyDocument
