import React, { useEffect, useState } from "react";
import {
    Box, Breadcrumbs, Container,
    Grid, styled, Table,
    TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography
} from "@mui/material";
import { getTurnoverByCompany } from "../../../../actions/report";
import { Link } from "react-router-dom";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import DatePickerCustom from "../../shared/date/datePicker";
import dayjs from "dayjs";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import LoadingGif from "../../assets/social-media.gif";
import AlertResponse from "../../shared/general/AlertResponse";
import { TurnoverXlsx } from "./xlsx-export/turnover-xlsx";

const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
    "& .MuiFilledInput-root": {
        height: 56,
        padding: "0px 12px",
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
    },
    "& .search-date-container": {
        marginTop: "24px",
        display: "flex",
        flexWrap: "nowrap",
        gap: "16px",
        "& .search-date-box": {
            maxWidth: "350px",
            width: "100%",
        },
        "& .search-button": {
            marginTop: "29px",
            display: "flex",
            alignItems: "center",
        },
    },

    ["@media only screen and (max-width: 600px)"]: {
        "& .search-date-container": {
            flexDirection: "column",
            "& .search-date-box": {
                maxWidth: "100%",
            },
            "& .search-button": {
                marginTop: "0",
                width: "100%",
            },
        },
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "0px",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    padding: 8,
    minWidth: 50,
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "10px 0px 10px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 16,
        // borderTopRightRadius: 8,
        // borderBottomRightRadius: 8,
    },
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px",
    padding: 8,
    minWidth: 50,
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 8,
    },
    "&:last-of-type": {
        paddingRight: 16,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

export default function TurnoverList() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);

    const [turnoverList, setTurnoverList] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date(dayjs()));
    const [selectedCompany, setSelectedCompany] = useState(null);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [summaryData, setSummaryData] = useState(null);

    const fetchedData = async () => {
        try {
            const response = await getTurnoverByCompany({
                year: dayjs(selectedYear).format("YYYY"),
                idCompany: selectedCompany && selectedCompany.idCompany,
                type: 1
            });

            if (response && response.data && response.data.result) {
                setTurnoverList(response.data.result);
                setSummaryData({
                    numberCurEmp: response.data.numberCurEmp,
                    percentTurnover: response.data.percentTurnover,
                    numberResignedEmp: response.data.numberResignedEmp
                });
            } else {
                setAlertType("error");
            };
        } catch (error) {
            console.error(error);
            setAlertType("error");
        };
    };

    useEffect(() => {
        if (selectedCompany && selectedYear) {
            fetchedData();
        }
    }, [selectedCompany, selectedYear]);


    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            const foundCompany = affiliateOrganizationList.find((x) => x.idCompany === userProfile.idCompany);
            if (foundCompany) {
                setSelectedCompany(foundCompany);
            } else {
                setSelectedCompany(affiliateOrganizationList[0]);
            };
        };
    }, [userProfile, affiliateOrganizationList]);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const columns = [
        { id: "Company", label: `${t("Company")}`, minWidth: 170 },
        { id: "Month", label: `${t("Month")}`, minWidth: 100 },
        { id: "EmpTotal", label: `${t("EmpTotal")}`, minWidth: 170 },
        { id: "NumOfResigningEmp", label: `${t("NumOfResigningEmp")}`, minWidth: 170 },
        { id: "TurnoverRate", label: `%${t("TurnoverRate")}`, minWidth: 170 },
    ];

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    return (
        <StyledRoot className="page">
            <Container
                maxWidth="lg"
                style={{ paddingTop: "24px", paddingBottom: "24px" }}
            >
                <Box marginBottom="24px">
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={"/employees-report"}
                        >
                            {`${t("AllReports")}`}
                        </Link>
                        <Typography color="text.primary">
                            {`${t("turnoverRateReportByCompany")}`}
                        </Typography>
                    </Breadcrumbs>
                </Box>

                <Typography variant="h4">{t("turnoverRateReportByCompany")}</Typography>

                <Box marginTop="24px">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <Typography
                                        fontWeight="600"
                                        fontSize="14px"
                                        paddingBottom="8px"
                                        color="text.third"
                                    >
                                        {t("Year")}
                                    </Typography>
                                    <DatePickerCustom
                                        inputFormat="YYYY"
                                        value={selectedYear}
                                        openTo="year"
                                        views={["year"]}
                                        onChange={(newValue) => {
                                            setSelectedYear(newValue);
                                        }}
                                        disableFuture
                                        renderInput={(params) => (
                                            <TextFieldTheme
                                                {...params}
                                                variant="filled"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Typography
                                        fontWeight="600"
                                        fontSize="14px"
                                        paddingBottom="8px"
                                        color="text.third"
                                    >
                                        {t("Company")}
                                    </Typography>
                                    <SelectAffiliateCompany
                                        options={affiliateOrganizationList}
                                        value={selectedCompany}
                                        onChange={(_, value) => {
                                            onChangeCompany(value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box marginTop="24px">
                    <CardStyle>
                        <Box padding="24px">
                            <Box>
                                <Box
                                    marginBottom="16px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    <ButtonBlue
                                        variant="outlined"
                                        startIcon={<DownloadRoundedIcon />}
                                        onClick={() => TurnoverXlsx(t, turnoverList, summaryData)}
                                        disabled={(turnoverList && turnoverList.length < 1) || !turnoverList}
                                    >
                                        {t("Download")}
                                    </ButtonBlue>
                                </Box>
                                <Box style={{ display: "flex", position: "relative" }}>
                                    <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead
                                                sx={{
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 1,
                                                }}
                                            >

                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <StyledCellHeader
                                                            key={column.id}
                                                            align="center"
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </StyledCellHeader>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {turnoverList &&
                                                    turnoverList.length > 0 &&
                                                    turnoverList.map((item, index) => (
                                                        <StyledRowContent key={index}>
                                                            <StyledCellContent align="center">
                                                                {(i18n.resolvedLanguage === "th" ? item.companyName : item.companyName_EN)}
                                                            </StyledCellContent>
                                                            <StyledCellContent align="center">
                                                                {t(`Months.${item.monthName}`)}
                                                            </StyledCellContent>
                                                            <StyledCellContent align="center">
                                                                {item.NumOfCurrentEmp || 0}
                                                            </StyledCellContent>
                                                            <StyledCellContent align="center">
                                                                {item.NumOfResigningEmp || 0}
                                                            </StyledCellContent>
                                                            <StyledCellContent align="center">
                                                                <Typography fontWeight={500} color={"red"}>
                                                                    {item.NumOfCurrentEmp > 0 ? `${((item.NumOfResigningEmp / item.NumOfCurrentEmp) * 100).toFixed(2)}%` : "0.00%"}
                                                                </Typography>
                                                            </StyledCellContent>
                                                        </StyledRowContent>
                                                    ))
                                                }

                                                {summaryData && (
                                                    <StyledRowContent>
                                                        <StyledCellContent align="center">
                                                            <Typography fontWeight={600}>{t("Average")}</Typography>
                                                        </StyledCellContent>
                                                        <StyledCellContent align="center"></StyledCellContent> {/* Empty Cell for Month */}
                                                        <StyledCellContent align="center">
                                                            <Typography fontWeight={600}>
                                                                {summaryData.numberCurEmp}
                                                            </Typography>
                                                        </StyledCellContent>
                                                        <StyledCellContent align="center">
                                                            <Typography fontWeight={600}>
                                                                {summaryData.numberResignedEmp}
                                                            </Typography>
                                                        </StyledCellContent>
                                                        <StyledCellContent align="center">
                                                            <Typography fontWeight={600} color={"red"}>
                                                                {summaryData.percentTurnover}
                                                            </Typography>
                                                        </StyledCellContent>
                                                    </StyledRowContent>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                            {/* ) : (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img src={LoadingGif} style={{ width: 100, height: 100 }} />
                                </Box>
                            )} */}
                        </Box>
                    </CardStyle>
                </Box>
            </Container >
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </StyledRoot >
    );
}