import { httpClient } from "./httpClient";

const getDataEmployee = (query) => {
  return httpClient.get(`dataEmployeeExpense`, { params: query });
};

const getDataWelfare = (query) => {
  return httpClient.get(`dataWelfareExpense`, { params: query });
};

export default {
  getDataEmployee,
  getDataWelfare,
};
