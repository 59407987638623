import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  CircularProgress,
  DialogContent,
  Stack,
  Dialog,
  DialogTitle,
  Chip,
  DialogActions,
  Tabs,
  Tab,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventIcon from "@mui/icons-material/Event";
import QuizIcon from '@mui/icons-material/Quiz';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import LoadingGif from "../../../assets/social-media.gif";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../../utils/userData";
import {
  answerQuestion5Scale,
  answerQuestion6Scale,
} from "../../../assets/data/answerScale";
import { useDispatch, useSelector } from "react-redux";
import { getProbation } from "../../../../../actions/probation";
import DividerStyle from "../../../shared/general/Divider";

const percentageScore = (questionList = []) => {
  let totalRating = 0;
  for (const item of questionList) {
    if (item.questionType === "choice") {
      totalRating += (item.answer * item.weight) / 100;
    }
  }

  return Math.round(totalRating);
};


const EmployeeProbationTab = ({ questionSet, answerScale, comment }) => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t("Employee")}
      </Typography>
      <Stack spacing={1} marginBottom={4} width={"100%"}>
        {questionSet.length > 0 ? (
          questionSet.map((question, index) => (
            <div key={question.idProbationQuestion}>
              <Typography gutterBottom fontWeight={500}>
                {`${t("Question")} ${index + 1} : ${question.question}`}
              </Typography>
              {question.questionType === "choice" ? (
                <>
                  <Typography color="text.secondary">{`${t("Weight")} ${question.weight}%`}</Typography>
                  <RadioGroup row value={question.answer}>
                    {answerScale === 6
                      ? answerQuestion6Scale.map((item) => (
                          <FormControlLabel
                            key={item.key}
                            value={item.value}
                            control={<Radio />}
                            label={t(item.label)}
                          />
                        ))
                      : answerQuestion5Scale.map((item) => (
                          <FormControlLabel
                            key={item.key}
                            value={item.value}
                            control={<Radio />}
                            label={t(item.label)}
                          />
                        ))}
                  </RadioGroup>
                </>
              ) : (
                <TextFieldTheme
                  fullWidth
                  value={question.answer}
                  InputProps={{ readOnly: true }}
                />
              )}
              <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
            </div>
          )
        )
      ) : (
        <Typography textAlign="center" color="text.secondary">
          {t("NoQuestions")}
        </Typography>
      )}
      </Stack>
      <Stack spacing={2} marginBottom={4}>
        <div>
          <Typography gutterBottom>
            {t("EmployeeFeedback")}
          </Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={comment}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
          <Box style={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              style={{
                color: "#eeeeee",
              }}
              size={70}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={percentageScore(questionSet)}
              style={{
                color: "#007afe",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
              }}
              size={70}
            ></CircularProgress>
            <Box
              style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontSize: 16 }}
                component="div"
                color="text.third"
              >
                {percentageScore(questionSet)}
              </Typography>
            </Box>
          </Box>
        </div>
      </Stack>
    </>
  )
}

const MangerProbationTab = ({
  managerLV,
  questionSet,
  answerScale,
  goodPoint, 
  weakPoint, 
  others,
  comment,
  resignDate,
  probationDate,
  result,
  adminResult,
  approvedBy,
  approvedBy_EN,
  reasonExpandTime,
  reasonTermination
}) => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {`${t("ManagerLV")} ${managerLV}`}
      </Typography>
      <Stack spacing={1} marginBottom={4} width={"100%"}>
        {questionSet && questionSet.length > 0 ? (
          questionSet.map(
            (question, index) => (
              <div key={question.idProbationQuestion}>
                <Typography gutterBottom fontWeight={500}>
                  {`${t("Question")} ${index + 1} : ${question.question}`}
                </Typography>
                {question.questionType === "choice" ? (
                  <>
                    <Typography color="text.secondary">{`${t("Weight")} ${question.weight}%`}</Typography>
                    <RadioGroup row value={question.answer}>
                      {answerScale === 6
                        ? answerQuestion6Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))
                        : answerQuestion5Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))}
                    </RadioGroup>
                  </>
                ) : (
                  <TextFieldTheme
                    fullWidth
                    value={question.answer}
                    InputProps={{ readOnly: true }}
                  />
                )}
                <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
              </div>
            )
          )
        ) : (
          <Typography textAlign="center" color="text.secondary">
            {t("NoQuestions")}
          </Typography>
        )}
      </Stack>
      <Stack spacing={2} marginBottom={4}>
        <div>
          <Typography gutterBottom>{t("Strength")}</Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={goodPoint}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <Typography gutterBottom>{t("Weakness")}</Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={weakPoint}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <Typography gutterBottom>{t("Other")}</Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={others}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <Typography gutterBottom>
            {t("FeedbackFromSupervisor")}
          </Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={comment}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
          <Box style={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              style={{
                color: "#eeeeee",
              }}
              size={70}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={percentageScore(questionSet)}
              style={{
                color: "#007afe",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
              }}
              size={70}
            ></CircularProgress>
            <Box
              style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontSize: 16 }}
                component="div"
                color="text.third"
              >
                {percentageScore(questionSet)}
              </Typography>
            </Box>
          </Box>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography fontWeight={500} color="text.secondary">
            {t("ProbationResult")}
          </Typography>
          {dayjs(resignDate).isBefore(probationDate) ? (
            <Chip label={t("Terminated")} color="error" />
          ) : result === 1 ? (
            <Chip label={t("Pass")} color="success" />
          ) : result === 0 ? (
            <Chip label={t("NotPass")} color="error" />
          ) : (
            <Chip label={t("WaitingForEvaluation")} color="warning" />
          )}
        </div>
        {(adminResult || approvedBy) && (
          <Typography fontWeight={500} color="text.secondary">
            {`${t("EvaluatedBy")} : ${
              adminResult
                ? t("Administrator")
                : i18n.resolvedLanguage === "th"
                ? approvedBy
                : approvedBy_EN
            }`}
          </Typography>
        )}
      </Stack>
      {reasonExpandTime && (
        <Grid item xs={12}>
          <Typography marginBottom={1}>
            {t("ReasonForExtendingProbation")}
          </Typography>
          <TextFieldTheme
            inputProps={{ readOnly: true }}
            multiline
            rows={3}
            value={reasonExpandTime || ""}
          />
        </Grid>
        )}
        {reasonTermination && (
          <div>
            <Typography gutterBottom>
              {t("ReasonForNotPassingProbation")}
            </Typography>
            <TextFieldTheme
              inputProps={{ readOnly: true }}
              multiline
              rows={3}
              value={reasonTermination || t("ReasonForNotPassingProbation")}
            />
          </div>
        )}
    </>
  )
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DialogProbation = ({
  open,
  handleClose,
  idProbation,
  handleSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: probation, isFetching } = useSelector(
    (state) => state.probationAnswer
  );
  const [value, setValue] = useState(null);
  const [probationResult, setProbationResult] = useState(null);

  const clickSubmit = () => {
    handleSubmit({ idProbation, probationResult: probationResult });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getProbation(idProbation));
  }, []);

  useEffect(() => {
    if (probation) {
      const result =
        probation.adminResult !== null
          ? probation.adminResult
          : probation.managerLV2Result !== null
          ? probation.managerLV2Result
          : probation.managerLV1Result;
      setProbationResult(result);
      setValue(
        probation.managerLV2Result !== null
          ? 2
          : probation.managerLV1Result !== null
          ? 1
          : 0
      );
    }
  }, [probation]);

  useEffect(() => {
    dispatch(getProbation(idProbation));
  }, []);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t("Details")}</DialogTitle>
      {!probation || isFetching ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={LoadingGif} style={{ width: 100, height: 100 }} />
        </Box>
      ) : (
        <>
          <DialogContent sx={{ padding: "24px" }}>
            <Grid container gap={2} marginBottom={2}>
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <PersonIcon />
                <Typography>
                  {`${t("PersonBeingAssessed")} : ${getUserFullName({
                    firstname_TH: probation.firstname_TH,
                    lastname_TH: probation.lastname_TH,
                    firstname_EN: probation.firstname_EN,
                    lastname_EN: probation.lastname_EN,
                  })}`}
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <AssessmentIcon />
                <Typography>
                  {`${t("ProbationRound")} : ${probation.probationRound}`}
                </Typography>
              </Grid>
              {probation.expandTime && (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <AddCircleIcon />
                  <Typography>
                    {`${t("ExtendProbationPeriod")}: ${
                      probation.expandTime
                    } ${t("Unit.Days")}`}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <EventIcon />
                <Typography>
                  {`${t("ProbationaryExpirationDate")} : ${dayjs(
                    probation.probationDate
                  ).format(
                    i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
                  )}`}
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <QuizIcon />
                <Typography>
                  {`${t("questionSet")} : ${probation.questionSet.questionSetName}`}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {probation.employeeEvaluateDate && (
                  <Tab label={t("Employee")} {...a11yProps(0)} />
                )}
                {probation.managerLV1Result && (
                  <Tab label={`${t("ManagerLV")} 1`} {...a11yProps(1)} />
                )}
                {probation.managerLV2Result && (
                  <Tab label={`${t("ManagerLV")} 2`} {...a11yProps(2)} />
                )}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <EmployeeProbationTab
                questionSet={probation.questionSet.employeeQuestion}
                answerScale={probation.questionSet.answerScale}
                comment={probation.employeeComment}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <MangerProbationTab
                managerLV={1}
                questionSet={probation.questionSet.managerLV1Question}
                answerScale={probation.questionSet.answerScale}
                goodPoint={probation.managerLV1GoodPoint}
                weakPoint={probation.managerLV1WeakPoint}
                others={probation.managerLV1Others}
                comment={probation.managerLV1Comment}
                resignDate={probation.resignDate}
                probationDate={probation.probationDate}
                result={probation.managerLV1Result}
                adminResult={probation.adminResult}
                approvedBy={probation.managerLV1Name_TH}
                approvedBy_EN={probation.managerLV1Name_EN}
                reasonExpandTime={probation.managerLV1ReasonExpandTime}
                reasonTermination={probation.managerLV1ReasonTermination}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <MangerProbationTab
                managerLV={2}
                questionSet={probation.questionSet.managerLV2Question}
                answerScale={probation.questionSet.answerScale}
                goodPoint={probation.managerLV2GoodPoint}
                weakPoint={probation.managerLV2WeakPoint}
                others={probation.managerLV2Others}
                comment={probation.managerLV2Comment}
                resignDate={probation.resignDate}
                probationDate={probation.probationDate}
                result={probation.managerLV2Result}
                adminResult={probation.adminResult}
                approvedBy={probation.managerLV2Name_TH}
                approvedBy_EN={probation.managerLV2Name_EN}
                reasonExpandTime={probation.managerLV2ReasonExpandTime}
                reasonTermination={probation.managerLV2ReasonTermination}
              />
            </CustomTabPanel>
            <Grid item xs={12}>
              <DividerStyle />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  align: "center",
                  gap: 8,
                }}
              >
                <ButtonBlue
                  sx={{ backgroundColor: "#00c853" }}
                  variant="contained"
                  disabled={probationResult === 1}
                  onClick={() => setProbationResult(1)}
                >{`${t("PassedProbation")}`}</ButtonBlue>
                <ButtonBlue
                  sx={{ backgroundColor: "#ff5252" }}
                  variant="contained"
                  disabled={probationResult === 0}
                  onClick={() => setProbationResult(0)}
                >{`${t("NotPassedProbation")}`}</ButtonBlue>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonBlue onClick={handleClose}>{t("Close")}</ButtonBlue>
            <ButtonBlue variant="contained" onClick={clickSubmit}>
              {t("EditData")}
            </ButtonBlue>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogProbation;
