import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Container,
  Typography,
  Box,
  MenuItem,
  LinearProgress,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/styles";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { useDispatch, useSelector } from "react-redux";
import { getKpiTeamByCompany, deleteKpiTeam } from "../../../../../actions/kpi";
import { getAffiliateOrganization } from "../../../../../actions/company";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import KPIweight from "../assets/KPIweight.png";
import KpiCard from "./KpiCard";
import KpiTeamDetail from "./KpiTeamDetail";
import { useHistory } from "react-router-dom";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const ContainerStyled = styled(Container)({
  height: "100%",
  paddingTop: "100px",
  backgroundColor: "transparent",
  minHeight: "100vh",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto",
    padding: "8px",
  },
});

const TotalWeightProgress = styled(LinearProgress)({
  height: "10px",
  borderRadius: "5px",
  backgroundColor: "#DCFAF8",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#16DBCC",
  },
});

const AddNewButton = styled(Button)(({ disabled }) => ({
  color: disabled ? "#9e9e9e" : "#4535C1",
  backgroundColor: disabled ? "#EEEEEE" : "white",
  border: `1px solid ${disabled ? "#9e9e9e" : "#4535C1"}`,
  borderRadius: "16px",
  "&:hover": {
    backgroundColor: disabled ? "#EEEEEE" : "#f2f2f2",
  },
}));

const YearSelect = styled(TextFieldTheme)({
  "& .MuiOutlinedInput-root": {
    height: "100%",
    "& fieldset": {
      borderColor: "#4535C1",
    },
    "&:hover fieldset": {
      borderColor: "#4535C1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4535C1",
    },
  },
  "& .MuiSelect-select": {
    backgroundColor: "white",
    borderRadius: "8px",
    height: "100%",
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

function KpiTeam() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: kpiTeam } = useSelector((state) => state.kpiTeam);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const [selectedYear, setSelectedYear] = useState(dayjs().get("year"));
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [planItems, setPlanItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState(null);

  const onDeleteKPI = async (idKpiTeam) => {
    const res = await dispatch(deleteKpiTeam(idKpiTeam));
    if (res && res.status === 200) {
      Swal.fire({
        title: t("Success"),
        text: t("KPIRemovedSuccessfully"),
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: t("OK"),
      });
      await dispatch(getKpiTeamByCompany());
    } else {
      Swal.fire({
        title: t("AnErrorOccurred"),
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: t("OK"),
      });
    }
  };

  const onDelete = async (idKpiTeam) => {
    try {
      const result = await Swal.fire({
        title: t("ConfirmKPIDeletion"),
        text: t("CannotUndo") + "!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        await onDeleteKPI(idKpiTeam);
      }
    } catch (error) {
      console.error("Error deleting KPI:", error);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleView = (kpi) => {
    setSelectedKpi(kpi);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (selectedYear && selectedCompany) {
      dispatch(
        getKpiTeamByCompany({
          year: selectedYear,
          idCompany: selectedCompany.idCompany,
        })
      );
    }
  }, [selectedYear, selectedCompany]);

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, [dispatch]);

  useEffect(() => {
    const temp = kpiTeam
      ? kpiTeam.filter((item) => {
          if (
            selectedDivision === null &&
            selectedDepartment === null &&
            selectedDivision === null
          ) {
            return true;
          }

          const passDivision = selectedDivision 
            ? selectedDivision.idDivision === item.idDivision 
            : false

          const passDepartment = selectedDepartment
            ? selectedDepartment.idDepartment === item.idDepartment
            : false

          const passSection = selectedSection
            ? selectedSection.idSection === item.idSection
            : false
          
          return passDivision || passDepartment || passSection
        })
      : [];

    setPlanItems(temp);
  }, [kpiTeam, selectedDivision, selectedDepartment, selectedDivision]);

  useEffect(() => {
    if (affiliateOrganizationList) {
      setSelectedCompany(affiliateOrganizationList[0]);
    }
  }, [affiliateOrganizationList]);

  return (
    <ContainerStyled>
      {kpiTeam && (
        <>
          <Box marginBottom="32px">
            <GridStyled
              container
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  className="kpi"
                  sx={{ ml: 1, fontSize: "30px" }}
                >
                  KPI Team
                </Typography>
              </Box>
            </GridStyled>
            <Grid container spacing={2} marginBottom={4}>
              <Grid item xs={12} md={4}>
                <Typography fontWeight={500} color="text.third">
                  {t("Years")}
                </Typography>
                <YearSelect
                  variant="outlined"
                  select
                  fullWidth
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  <MenuItem value={dayjs().get("year")}>
                    {dayjs().format(
                      i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                    )}
                  </MenuItem>
                  <MenuItem value={dayjs().get("year") - 1}>
                    {dayjs()
                      .subtract(1, "year")
                      .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                  </MenuItem>
                </YearSelect>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography fontWeight={500} color="text.third">
                  {t("Company")}
                </Typography>
                <StyledAutocomplete
                  value={selectedCompany}
                  options={affiliateOrganizationList}
                  getOptionLabel={(option) =>
                    i18n.resolvedLanguage === "th"
                      ? option.companyName
                      : option.companyName_EN
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.idCompany === value.idCompany
                  }
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("Company")}
                    />
                  )}
                  onChange={(_, value) => setSelectedCompany(value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight={500} color="text.third">
                  {t("Division")}
                </Typography>
                <StyledAutocomplete
                  value={selectedDivision}
                  options={
                    selectedCompany
                      ? selectedCompany.division.filter((item) => item.idDivision)
                      : []
                  }
                  getOptionLabel={(option) =>
                    i18n.resolvedLanguage === "th"
                      ? option.divisionName
                      : option.divisionName_EN
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.idDivision === value.idDivision
                  }
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("Division")}
                    />
                  )}
                  onChange={(_, value) => setSelectedDivision(value)}
                  disabled={!selectedCompany}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight={500} color="text.third">
                  {t("Department")}
                </Typography>
                <StyledAutocomplete
                  value={selectedDepartment}
                  options={
                    selectedCompany
                      ? selectedCompany.department.filter((item) => item.idDepartment)
                      : []
                  }
                  getOptionLabel={(option) =>
                    i18n.resolvedLanguage === "th"
                      ? option.departmentName
                      : option.departmentName_EN
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.idDepartment === value.idDepartment
                  }
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("Department")}
                    />
                  )}
                  onChange={(_, value) => setSelectedDepartment(value)}
                  disabled={!selectedCompany}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight={500} color="text.third">
                  {t("Section")}
                </Typography>
                <StyledAutocomplete
                  value={selectedSection}
                  options={
                    selectedCompany
                      ? selectedCompany.section.filter((item) => item.idSection)
                      : []
                  }
                  getOptionLabel={(option) =>
                    i18n.resolvedLanguage === "th"
                      ? option.sectionName
                      : option.sectionName_EN
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.idSection === value.idSection
                  }
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("Section")}
                    />
                  )}
                  onChange={(_, value) => setSelectedSection(value)}
                  disabled={!selectedCompany}
                />
              </Grid>


            </Grid>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={4}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "100%", sm: "29%" },
                  height: "auto",
                }}
              >
                <img
                  src={KPIweight}
                  alt="KPIs"
                  style={{ height: "70px", marginRight: "16px" }}
                />
                <Box>
                  <Typography variant="body1" fontWeight="600">
                    KPIs
                  </Typography>
                  <Typography variant="body1" mt={1}>
                    {`${t("All")} ${planItems.length} ${t("List")}`}
                  </Typography>
                </Box>
              </Box>
              <AddNewButton
                variant="outlined"
                onClick={() => {
                  history.push({
                    pathname: "/kpi/team/plan/admin",
                    state: { planList: null },
                  });
                }}
              >
                +{t("AddNewKPI")}
              </AddNewButton>
            </Box>
          </Box>

          {planItems.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={"4px"}
            >
              <FindInPageIcon />
              <Typography variant="h6">{t("NoData")}</Typography>
            </Box>
          ) : (
            planItems.map((item) => (
              <KpiCard
                key={item.idKpiTeam}
                kpi={item}
                onDelete={onDelete}
                onView={handleView}
              />
            ))
          )}

          {openDialog && selectedKpi && (
            <KpiTeamDetail
              open={openDialog}
              handleClose={handleCloseDialog}
              selectedKpi={selectedKpi}
            />
          )}
        </>
      )}
    </ContainerStyled>
  );
}
export default KpiTeam;
