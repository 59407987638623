import ExcelJS from "exceljs";
import dayjs from 'dayjs';

export const ProvidentFundYearlyXlsx = async (t, data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(t("ProvidentFundYearlyReport"));

  const months = {
    "01": t("Months.January"),
    "02": t("Months.February"),
    "03": t("Months.March"),
    "04": t("Months.April"),
    "05": t("Months.May"),
    "06": t("Months.June"),
    "07": t("Months.July"),
    "08": t("Months.August"),
    "09": t("Months.September"),
    "10": t("Months.October"),
    "11": t("Months.November"),
    "12": t("Months.December"),
  };

  const uniqueMonths = Array.from(
    new Set(data.flatMap((employee) => employee.provident.map((record) => record.month)))
  ).sort();

  const mappedMonths = uniqueMonths.map((month) => {
    const monthValue = month.slice(-2);
    return months[monthValue] || month;
  });

  const headers = [
    t("EmployeeID"),
    t("FirstName"),
    t("LastName"),
    t("PersonalID"),
    t("CompanyName"),
    t("Division"),
    t("Section"),
    t("Position"),
    t("Department"),
    t("TotalProvidentFundEmployee"),
    t("TotalProvidentFundCompany"),
    ...mappedMonths.flatMap((month) => [
      `% ${t("ProvidentFundEmployee")} ${t("Month")}${month}`,
      `${t("ProvidentFundEmployee")} ${t("Month")}${month}`,
    ]),
    ...mappedMonths.flatMap((month) => [
      `% ${t("ProvidentFundCompany")} ${t("Month")}${month}`,
      `${t("ProvidentFundCompany")} ${t("Month")}${month}`,
    ]),
  ];

  const headerRow = worksheet.addRow(headers);

  headerRow.height = 50; 

  const headerStyle1 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '002060' },
    },
  };

  const headerStyle2 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '6e296b' }
    },
  };

  headerRow.eachCell((cell,colNumber) => {
    const headerText = headers[colNumber - 1];
    if (headerText.includes(t("ProvidentFundCompany"))) {
      cell.style = headerStyle2; 
    } else {
      cell.style = headerStyle1; 
    }
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });


  const fixedColWidths = [
    { key: "employeeID", width: 40 },
    { key: "firstname", width: 40 },
    { key: "lastname", width: 40 },
    { key: "personalID", width: 40 },
    { key: "companyName", width: 40 },
    { key: "divisionName", width: 40 },
    { key: "sectionName", width: 40 },
    { key: "positionName", width: 40 },
    { key: "departmentName", width: 40 },
  ];

  const dynamicColWidths = mappedMonths.flatMap(() => [
    { key: "userPF", width: 60 },
    { key: "providentEmployee", width: 60 },
    { key: "companyPF", width: 60 },
    { key: "providentCompany", width: 60 },
  ]);

  const summaryColWidths = [
    { key: "totalProvidentEmployee", width: 60 },
    { key: "totalProvidentCompany", width: 60 },
  ];

  const colWidths = [...fixedColWidths, ...summaryColWidths, ...dynamicColWidths];

  colWidths.forEach((col, index) => {
    worksheet.getColumn(index + 1).width = col.width;
  });

  data.forEach((employee) => {
    const rowData = [
      employee.employeeID,
      employee.firstname,
      employee.lastname,
      employee.personalID,
      employee.companyName,
      employee.divisionName || "-",
      employee.sectionName || "-",
      employee.positionName || "-",
      employee.departmentName || "-",
    ];

    const providentData = uniqueMonths.flatMap((month) => {
      const record = employee.provident.find((entry) => entry.month === month);
      return record ? [record.userPF, record.provident_employee.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")] : ["-", "-"];
    });

    const providentCompanyData = uniqueMonths.flatMap((month) => {
      const record = employee.provident.find((entry) => entry.month === month);
      return record ? [record.companyPF, record.provident_company.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")] : ["-", "-"];
    });

    const totalProvidentEmployee = employee.provident.reduce(
      (sum, record) => sum + (record.provident_employee || 0),
      0
    );

    const totalProvidentCompany = employee.provident.reduce(
      (sum, record) => sum + (record.provident_company || 0),
      0
    );

    const excelRow =worksheet.addRow([
      ...rowData,
      totalProvidentEmployee.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      totalProvidentCompany.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      ...providentData,
      ...providentCompanyData,
    ]);

    const contentStyle = {
      font: { size: 16, name: "TH SarabunPSK", bold: false },
    };
    excelRow.eachCell((cell, colNumber) => {
      cell.style = { ...contentStyle };
      cell.style.alignment = { horizontal: "center", vertical: "middle" };
    });

  });

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${t("ProvidentFundYearlyReport")}.xlsx`;
  link.click();
};
