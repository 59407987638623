import React, { useEffect, useState } from "react";
import DrawerCustom from "../../shared/general/Drawer";
import { Box, Checkbox, Divider, FormControl, FormControlLabel, Grid, MenuItem, Typography, Select, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getShift, updateShiftGroup } from "../../../../actions/shift";
import { useDispatch } from "react-redux";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from 'dayjs';

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
    "&.Mui-disabled": {
      color: 'rgba(0, 0, 0, 0.26)'
    }
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const DrawerShiftGroupSetting = (props) => {

  const { drawerConfig, handleClose } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [listHolidayPattern, setListHolidayPattern] = useState([]);

  const validateYupSchema = yup.object({
    shiftGroupCodeCustom: yup.string().when("isCustomShiftGroupCode", {
      is: true,
      then: schema => schema.required(t(`ThisFieldIsRequired`))
    }),
    idHolidayPattern:  yup.string().when("isMainHoliday", {
      is: (value) => !value,
      then: schema => schema.required(t(`ThisFieldIsRequired`))
    }),
    date: yup.date().nullable().typeError(t(`PleaseSpecifyCorrectDate`))
  });

  const useHookForm = useForm({
    defaultValues: {
      isCustomShiftGroupCode: false,
      shiftGroupCodeCustom: "",
      isMainHoliday: true,
      idHolidayPattern: "",
      year: new Date()
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const isCustomShiftGroupCodeWatch = useHookForm.watch("isCustomShiftGroupCode");

  const onSubmit = async (data) => {
    let isChangeIsMainHoliday = false;
    let defaultValue = drawerConfig.data.holidayPatternList[dayjs(useHookForm.watch("year")).year()];
    let infoHolidayPattern = defaultValue.listHolidayPattern.find((hp) => {
      return hp.idHolidayPattern === defaultValue.idHolidayPattern;
    });

    if(!infoHolidayPattern){
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
      return;
    }
    
    if(useHookForm.watch("isMainHoliday") !== Boolean(infoHolidayPattern.isMainPattern)){
      isChangeIsMainHoliday = true;
    }

    if(
      (isChangeIsMainHoliday || (
        useHookForm.formState.dirtyFields["idHolidayPattern"] && !useHookForm.watch("isMainHoliday")
      )) && !useHookForm.watch("year")
    ){
      useHookForm.setError("year", {
        type: "required",
        message: t(`ThisFieldIsRequired`)
      });
      return ;
    }
    
    const formData = {
      shiftGroupCodeCustom: data.isCustomShiftGroupCode? (data.shiftGroupCodeCustom? data.shiftGroupCodeCustom: null): null,
    }

    let isChangeHolidayPattern = false;
    Object.keys(useHookForm.formState.dirtyFields).map((k) => {
      if(!['shiftGroupCodeCustom', 'isCustomShiftGroupCode', 'date', 'isMainHoliday', 'year'].includes(k)){
        if(!isChangeHolidayPattern){
          isChangeHolidayPattern = true;
        }
        
        if(k === "idHolidayPattern"){
          formData[k] = parseInt(data[k]);
        }else{
          formData[k] = data[k];
        }
      }
    });

    if(isChangeHolidayPattern || isChangeIsMainHoliday){
      formData['year'] = dayjs(data['year']).format("YYYY");
    }

    if(isChangeIsMainHoliday){
      formData["isMainHoliday"] = data["isMainHoliday"];
    }

    const result = await dispatch(updateShiftGroup(drawerConfig.data.idShiftGroup, formData));

    if(result && result.status === 200) {
      handleCloseReset();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getShift({idCompany: drawerConfig.data.idCompany}))
    } else {
      if(result && result.data && result.data.error_code === "SHIFT_GROUP_CODE_DUPLICATED"){
        dispatch(openNotificationAlert({
          type: "error",
          message: t("ShiftGroupCodeIsAlreadyExits", { code: data.shiftGroupCodeCustom })
        }));
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }));
      }
    }
  }

  const handleCloseReset = () => {
    useHookForm.reset({
      isCustomShiftGroupCode: false,
      shiftGroupCodeCustom: "",
      isMainHoliday: true,
      idHolidayPattern: "",
      year: new Date()
    });
    handleClose();
  }

  useEffect(() => {
    if(drawerConfig.isOpen){
      let data = drawerConfig.data;
      useHookForm.setValue("isCustomShiftGroupCode", data.isCustomShiftGroupCode? data.isCustomShiftGroupCode: false, { shouldDirty: false, shouldTouch: false, shouldValidate: false});
      useHookForm.setValue("shiftGroupCodeCustom", data.shiftGroupCodeCustom? data.shiftGroupCodeCustom: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false});
    }
  }, [drawerConfig.isOpen])

  useEffect(() => {
    if(drawerConfig.isOpen){
      let data = drawerConfig.data;
      if(data.holidayPatternList){
        let year = dayjs(useHookForm.watch("year")).year() || dayjs().year();
        if(data.holidayPatternList[year]){
          useHookForm.setValue("idHolidayPattern", data.holidayPatternList[year].idHolidayPattern || "");
          setListHolidayPattern(data.holidayPatternList[year].listHolidayPattern.filter((hp) => hp.isMainPattern !== 1));
          
          let infoHolidayPattern = data.holidayPatternList[year].listHolidayPattern.find((hp) => hp.idHolidayPattern === data.holidayPatternList[year].idHolidayPattern);
          if(infoHolidayPattern){
            useHookForm.setValue("isMainHoliday", infoHolidayPattern.isMainPattern ? true : false);
          }
        }
      }
    }
  },[
    drawerConfig.isOpen, useHookForm.watch("year")
  ]);

  const disableHolidayPattern = () => {
    return parseInt(dayjs(useHookForm.watch("year")).year()) < parseInt(dayjs().year());
  };

  return (
    <DrawerCustom
      title={t("ShiftGroup")}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={() => {
        if(useHookForm.formState.isSubmitting) return
        handleCloseReset()
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="4px">
                <Typography fontSize="14px" fontWeight="500">{t("ShiftGroupCode")}</Typography>
                <Controller
                  name={"isCustomShiftGroupCode"}
                  control={useHookForm.control}
                  render={({field}) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                          }}
                        />
                      }
                      label={<Typography fontSize="14px">{t("Custom")}</Typography>}
                      labelPlacement="start"
                    />
                  )}
                />
              </Box>
              {isCustomShiftGroupCodeWatch? (
                <Controller
                  name={"shiftGroupCodeCustom"}
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <TextFieldTheme
                      {...field}
                      helperText={fieldState.error? fieldState.error.message : null}
                      error={fieldState.error? true : false}
                    />
                  )}
                />
              ): (
                <Typography fontSize="20px" fontWeight="500">{drawerConfig.data.shiftGroupCode}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
            <Grid item xs={12}>
              <Typography 
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
              >รูปแบบวันหยุด</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography 
                  fontSize="14px" fontWeight="500"
                >วันหยุดประจำปี</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller 
                    name={"year"}
                    control={useHookForm.control}
                    render={({field}) => (
                      <DatePicker
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          useHookForm.clearErrors();
                        }}
                        views={['year']}
                        inputFormat="YYYY"
                        minDate={(
                          drawerConfig.data && 
                          drawerConfig.data.shiftStartDate
                        ) ? 
                          dayjs(drawerConfig.data.shiftStartDate).date(1).month(0)
                          : dayjs().date(1).month(0)
                        }
                        maxDate={dayjs().date(1).month(0).add(1, 'year')}
                        renderInput={(params) => {
                          return (
                            <TextFieldTheme 
                              {...params} 
                              style={{
                                width: "fit-content"
                              }}
                            />
                          );
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="4px">
                <Typography fontSize="14px" fontWeight="500">{t("useMainHoliday")}</Typography>
                <Controller
                  name={"isMainHoliday"}
                  control={useHookForm.control}
                  render={({field}) => (
                    <FormControlLabel
                      style={{
                        marginRight:"0"
                      }}
                      control={
                        <Checkbox
                          style={{
                            right:"-9px"
                          }}
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            useHookForm.setValue('isMainHoliday', e.target.checked, { shouldDirty: true })
                            useHookForm.setValue('idHolidayPattern', '')
                          }}
                          disabled={disableHolidayPattern()}
                        />
                      }
                    />
                  )}
                />
              </Box>
              {(!useHookForm.watch("isMainHoliday") && listHolidayPattern.length <= 0) && (
                <Typography
                  style={{
                    fontSize: "12px",
                    color: 'rgba(0, 0, 0, 0.26)',
                    fontWeight: "700"
                  }}
                >* {t("addOtherHolidayType")}</Typography>
              )}
              {!useHookForm.watch("isMainHoliday") &&
                <Box>
                    <Controller 
                      name={"idHolidayPattern"}
                      control={useHookForm.control}
                      render={({field, fieldState}) => (
                        <TextFieldTheme 
                          {...field}
                          style={{
                            marginBottom: "16px"
                          }}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          helperText={fieldState.error? fieldState.error.message : null}
                          error={fieldState.error? true : false}
                          select
                          SelectProps={{
                            displayEmpty : true
                          }}
                          disabled={disableHolidayPattern() || listHolidayPattern.length <= 0}
                        >
                          {(listHolidayPattern.length > 0) ?
                           listHolidayPattern.map((pattern) => {
                            return (
                              <MenuItem key={`${pattern.idHolidayPattern}_${pattern.name}`} value={pattern.idHolidayPattern}>{pattern.name}</MenuItem>
                            )
                          })
                          : <MenuItem value="" disabled>ไม่พบวันหยุด</MenuItem>}
                        </TextFieldTheme>
                      )}
                    />
                </Box>
              }
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={handleCloseReset} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
                startIcon={useHookForm.formState.isSubmitting && (
                  <CircularProgress
                    color="inherit"
                    size={16}
                  />
                )}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerShiftGroupSetting