import {
  ADDITIONS_SPECIAL_1_FETCHING,
  ADDITIONS_SPECIAL_1_FAILED,
  ADDITIONS_SPECIAL_1_SUCCESS,
  ADDITIONS_SPECIAL_2_FETCHING,
  ADDITIONS_SPECIAL_2_FAILED,
  ADDITIONS_SPECIAL_2_SUCCESS,
  ADDITIONS_SPECIAL_100_FETCHING,
  ADDITIONS_SPECIAL_100_FAILED,
  ADDITIONS_SPECIAL_100_SUCCESS,
  ADDITIONS_PERSONAL_FETCHING,
  ADDITIONS_PERSONAL_FAILED,
  ADDITIONS_PERSONAL_SUCCESS,
  EMPLOYEE_ADDITIONS_PERSONAL_FETCHING,
  EMPLOYEE_ADDITIONS_PERSONAL_FAILED,
  EMPLOYEE_ADDITIONS_PERSONAL_SUCCESS,
  ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_FETCHING,
  ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_FAILED,
  ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_SUCCESS,
  ADDITION_SPECIAL_CONDITION_TIME_SETTING_FETCHING,
  ADDITION_SPECIAL_CONDITION_TIME_SETTING_FAILED,
  ADDITION_SPECIAL_CONDITION_TIME_SETTING_SUCCESS,
  ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_FETCHING,
  ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_FAILED,
  ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_SUCCESS,
  ADDITION_SPECIAL_CONDITION_TIME_REPORT_FETCHING,
  ADDITION_SPECIAL_CONDITION_TIME_REPORT_FAILED,
  ADDITION_SPECIAL_CONDITION_TIME_REPORT_SUCCESS,
} from "./types";

import additionSepecialService from "../services/additionSepecial.service";

export const getAdditionSpecial1 = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_SPECIAL_1_FETCHING,
    });
    const res = await additionSepecialService.getAdditionSpecial1(idCompany);
    if (res) {
      dispatch({
        type: ADDITIONS_SPECIAL_1_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_1_FAILED,
    });
  }
};

export const addAdditionSpecial1 = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.addAdditionSpecial1(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_1_FAILED,
    });
  }
};

export const updateAdditionSpecial1 = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.updateAdditionSpecial1(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_1_FAILED,
    });
  }
};

export const getAdditionSpecial2 = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FETCHING,
    });
    const res = await additionSepecialService.getAdditionSpecial2(idCompany);
    if (res) {
      dispatch({
        type: ADDITIONS_SPECIAL_2_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FAILED,
    });
  }
};

export const getAdditionSpecial2New = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FETCHING,
    });
    const res = await additionSepecialService.getAdditionSpecialConditionTimeLevel(query);
    if (res) {
      dispatch({
        type: ADDITIONS_SPECIAL_2_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FAILED,
    });
  }
};

export const addAdditionSpecial2New = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.createAdditionSpecialConditionTimeLevel(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FAILED,
    });
  }
};

export const updateAdditionSpecial2New = (id,formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.updateAdditionSpecialConditionTimeLevel(id,formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FAILED,
    });
  }
};

export const deleteAdditionSpecial2New = (id) => async (dispatch) => {
  try {
    const res = await additionSepecialService.deleteAdditionSpecialConditionTimeLevel(id);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FAILED,
    });
  }
};

export const duplicateAdditionSpecial2New = (id) => async (dispatch) => {
  try {
    const res = await additionSepecialService.duplicateAdditionSpecialConditionTimeLevel(id);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FAILED,
    });
  }
};

export const addAdditionSpecial2 = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.addAdditionSpecial2(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FAILED,
    });
  }
};

export const updateAdditionSpecial2 = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.updateAdditionSpecial2(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_2_FAILED,
    });
  }
};

export const getAdditionSpecial100 = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_SPECIAL_100_FETCHING,
    });
    const res = await additionSepecialService.getAdditionSpecial100(idCompany);
    if (res) {
      dispatch({
        type: ADDITIONS_SPECIAL_100_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_100_FAILED,
    });
  }
};

export const addAdditionSpecial100 = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.addAdditionSpecial100(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_100_FAILED,
    });
  }
};

export const updateAdditionSpecial100 = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.updateAdditionSpecial100(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_SPECIAL_100_FAILED,
    });
  }
};

export const getAdditionPersonal = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_PERSONAL_FETCHING,
    });
    const res = await additionSepecialService.getAdditionPersonal(idCompany);
    if (res) {
      dispatch({
        type: ADDITIONS_PERSONAL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_PERSONAL_FAILED,
    });
  }
};

export const addAdditionPersonal = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.addAdditionPersonal(formData);
    if (res) {
      return res
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_PERSONAL_FAILED,
    });
  }
};

export const updateAdditionPersonal = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.updateAdditionPersonal(formData);
    if (res) {
      return res
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_PERSONAL_FAILED,
    });
  }
};

export const getEmployeeAdditionPersonal = (idEmployee) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_ADDITIONS_PERSONAL_FETCHING,
    });
    const res = await additionSepecialService.getEmployeeAdditionPersonal(idEmployee);
    if (res) {
      dispatch({
        type: EMPLOYEE_ADDITIONS_PERSONAL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_ADDITIONS_PERSONAL_FAILED,
    });
  }
};

export const addAdditionPersonalEmployees = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.addAdditionPersonalEmployees(formData);
    if (res) {
      return res
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_PERSONAL_FAILED,
    });
  }
};

export const deleteAdditionPersonalEmployees = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.deleteAdditionPersonalEmployees(formData);
    if (res) {
      return res
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_PERSONAL_FAILED,
    });
  }
};

export const getAdditionOvertime = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_PERSONAL_FETCHING,
    });
    const res = await additionSepecialService.getAdditionOvertime(idCompany);
    if (res) {
      dispatch({
        type: ADDITIONS_PERSONAL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_PERSONAL_FAILED,
    });
  }
};

export const addAdditionOvertime = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.addAdditionOvertime(formData);
    if (res) {
      return res
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_PERSONAL_FAILED,
    });
  }
};

export const updateAdditionOvertime = (formData) => async (dispatch) => {
  try {
    const res = await additionSepecialService.updateAdditionOvertime(formData);
    if (res) {
      return res
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_PERSONAL_FAILED,
    });
  }
};

export const calculateDiligenceAllowance = (formData) => async () => {
  try {
    const res = await additionSepecialService.calculateDiligenceAllowance(formData);
    if (res) {
      return res
    }
  } catch (error) {
    console.log(error);
    return error.response
  }
};

export const getAdditionSpecialDiligenceAllowanceSetting = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_FETCHING,
    });
    const res = await additionSepecialService.getAdditionSpecialDiligenceAllowanceSetting(query);
    if (res) {
      dispatch({
        type: ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (error) {
    dispatch({
      type: ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_FAILED,
    });
    return error.response
  }
};

export const calculateAdditionSpecialConditionTime = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITION_SPECIAL_CONDITION_TIME_REPORT_FETCHING,
    });
    const res = await additionSepecialService.calculateAdditionSpecialConditionTime(formData);
    if (res) {
      dispatch({
        type: ADDITION_SPECIAL_CONDITION_TIME_REPORT_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (error) {
    dispatch({
      type: ADDITION_SPECIAL_CONDITION_TIME_REPORT_FAILED,
    });
    return error.response
  }
  // try {
  //   const res = await additionSepecialService.calculateAdditionSpecialConditionTime(formData);
  //   if (res) {
  //     return res
  //   }
  // } catch (error) {
  //   console.log(error);
  //   return error.response
  // }
};

export const getAdditionSpecialConditionTimeSetting = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITION_SPECIAL_CONDITION_TIME_SETTING_FETCHING,
    });
    const res = await additionSepecialService.getAdditionSpecialConditionTimeLevel(query);
    if (res) {
      dispatch({
        type: ADDITION_SPECIAL_CONDITION_TIME_SETTING_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (error) {
    dispatch({
      type: ADDITION_SPECIAL_CONDITION_TIME_SETTING_FAILED,
    });
    return error.response
  }
};

export const getAdditionSpecialConditionTimePersonalSetting = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_FETCHING,
    });
    const res = await additionSepecialService.getAdditionSpecialConditionTimeLevel(query);
    if (res) {
      dispatch({
        type: ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (error) {
    dispatch({
      type: ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_FAILED,
    });
    return error.response
  }
};