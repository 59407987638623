import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  TableRow,
  TableCell,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
} from "@mui/material";

import DatePickerCustom from "../../../../shared/date/datePicker";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import TimePicker from "../../../../shared/general/TimePicker";

const StyledTableRow = styled(TableRow)({
  marginBottom: 2,
});

const StyledTableCell = styled(TableCell)({
  padding: 0,
  border: "none",
  "& .wrap": {
    height: 88,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: "1px solid #e1e1e1",
    borderLeft: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRight: "1px solid #e1e1e1",
    },
    "&.time": {
      flexDirection: "row",
      borderLeft: "0px solid #e1e1e1",
    },
  },
  "&.date-start-end": {
    width: 120,
    "& .MuiInputBase-root": {
      paddingRight: 8,
    },
  },
  "&.description": {
    width: 250,
  },
  "&.date": {
    width: 48,
    textAlign: "center",
  },
  "&.delete": {
    width: 32,
  },
  "&.time": {
    width: 200,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  "& .MuiInputBase-root": {
    borderRadius: 0,
    height: 56,
    "& fieldset": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      padding: "13.5px !important",
    },
  },
});

const StyledFormControl = styled(FormControl)({
  marginLeft: 2,
  marginRight: 8,
});

const RowItemExpense = (props) => {
  const {
    index,
    handleDeleteRow,
    count,
    control,
    errors,
    calculateSum,
    addDeleteRow,
    setValue,
    getValues,
    type,
    handleChangeTime,
    setting,
  } = props;

  const calculateDays = () => {
    setValue(
      `listExpense.${index}.countDays`,
      dayjs(getValues(`listExpense.${index}.endDate`)).diff(
        dayjs(
          dayjs(getValues(`listExpense.${index}.startDate`)).format(
            "YYYY/MM/DD HH:mm"
          )
        ),
        "day"
      ) + 1
    );
    setValue(
      `listExpense.${index}.countMinutes`,
      dayjs(getValues(`listExpense.${index}.endDate`)).diff(
        dayjs(
          dayjs(getValues(`listExpense.${index}.startDate`)).format(
            "YYYY/MM/DD HH:mm"
          )
        ),
        "minute"
      )
    );
  };

  return (
    <>
      <StyledTableRow>
        <StyledTableCell className=""></StyledTableCell>

        <StyledTableCell className="date-start-end">
          <div className="wrap first">
            <FormControl
              fullWidth
              error={
                errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].startDate
                  ? true
                  : false
              }
            >
              <Controller
                name={`listExpense.${index}.startDate`}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <DatePickerCustom
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    onChange={(e) => {
                      field.onChange(dayjs(e).format("YYYY/MM/DD"));
                      calculateDays();
                      calculateSum(getValues("listExpense"));
                    }}
                    renderInput={(params) => (
                      <StyledTextField fullWidth {...params} />
                    )}
                  />
                )}
              />
              {errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].startDate &&
                errors.listExpense[index].startDate.message && (
                  <FormHelperText
                    error
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {errors.listExpense[index].startDate.message}
                  </FormHelperText>
                )}
            </FormControl>
          </div>
        </StyledTableCell>
        <StyledTableCell className="date-start-end">
          <div className="wrap time">
            <StyledFormControl
              fullWidth
              error={
                errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].startTimeHour
                  ? true
                  : false
              }
            >
              <Controller
                name={`listExpense.${index}.startTimeHour`}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    handleChangeTime={handleChangeTime}
                    unit="hour"
                    step="1"
                  />
                )}
              />
              {errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].startTimeHour &&
                errors.listExpense[index].startTimeHour.message && (
                  <FormHelperText
                    error
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {errors.listExpense[index].startTimeHour.message}
                  </FormHelperText>
                )}
            </StyledFormControl>

            <StyledFormControl
              fullWidth
              error={
                errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].startTimeMinute
                  ? true
                  : false
              }
            >
              <Controller
                name={`listExpense.${index}.startTimeMinute`}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    handleChangeTime={handleChangeTime}
                    unit="minute"
                    step="1"
                  />
                )}
              />
              {errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].startTimeMinute &&
                errors.listExpense[index].startTimeMinute.message && (
                  <FormHelperText
                    error
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {errors.listExpense[index].startTimeMinute.message}
                  </FormHelperText>
                )}
            </StyledFormControl>
          </div>
        </StyledTableCell>
        <StyledTableCell className="date-start-end">
          <div className="wrap">
            <FormControl
              fullWidth
              error={
                errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].endDate
                  ? true
                  : false
              }
            >
              <Controller
                name={`listExpense.${index}.endDate`}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <DatePickerCustom
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    onChange={(e) => {
                      field.onChange(dayjs(e).format("YYYY/MM/DD"));
                      calculateDays();
                      calculateSum(getValues("listExpense"));
                    }}
                    renderInput={(params) => (
                      <StyledTextField fullWidth {...params} />
                    )}
                  />
                )}
              />
              {errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].endDate &&
                errors.listExpense[index].endDate.message && (
                  <FormHelperText
                    error
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {errors.listExpense[index].endDate.message}
                  </FormHelperText>
                )}
            </FormControl>
          </div>
        </StyledTableCell>
        <StyledTableCell className="date-start-end">
          <div className="wrap time">
            <StyledFormControl
              fullWidth
              error={
                errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].endTimeHour
                  ? true
                  : false
              }
            >
              <Controller
                name={`listExpense.${index}.endTimeHour`}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    handleChangeTime={handleChangeTime}
                    unit="hour"
                    step="1"
                  />
                )}
              />
              {errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].endTimeHour &&
                errors.listExpense[index].endTimeHour.message && (
                  <FormHelperText
                    error
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {errors.listExpense[index].endTimeHour.message}
                  </FormHelperText>
                )}
            </StyledFormControl>

            <StyledFormControl
              fullWidth
              error={
                errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].endTimeMinute
                  ? true
                  : false
              }
            >
              <Controller
                name={`listExpense.${index}.endTimeMinute`}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    handleChangeTime={handleChangeTime}
                    unit="minute"
                    step="1"
                  />
                )}
              />
              {errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].endTimeMinute &&
                errors.listExpense[index].endTimeMinute.message && (
                  <FormHelperText
                    error
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {errors.listExpense[index].endTimeMinute.message}
                  </FormHelperText>
                )}
            </StyledFormControl>
          </div>
        </StyledTableCell>
        <StyledTableCell className="cell-table description">
          <div className="wrap">
            <FormControl
              fullWidth
              error={
                errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].description
                  ? true
                  : false
              }
            >
              <Controller
                name={`listExpense.${index}.description`}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <StyledTextField {...field} multiline />
                  </>
                )}
              />
              {errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].description &&
                errors.listExpense[index].description.message && (
                  <FormHelperText
                    error
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {errors.listExpense[index].description.message}
                  </FormHelperText>
                )}
            </FormControl>
          </div>
        </StyledTableCell>
        <StyledTableCell className="cell-table date">
          <div className="wrap last">
            <FormControl
              fullWidth
              error={
                errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].countDays
                  ? true
                  : false
              }
            >
              <Controller
                name={`listExpense.${index}.countDays`}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <StyledTextField
                      {...field}
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                      }}
                      inputProps={{
                        decimalScale: 1,
                        allowNegative: false,
                        value: field.value,
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value);
                          calculateSum(getValues("listExpense"));
                        },
                      }}
                      onChange={() => {}}
                    />
                  </>
                )}
              />
              {errors &&
                errors.listExpense &&
                errors.listExpense[index] &&
                errors.listExpense[index].countDays &&
                errors.listExpense[index].countDays.message && (
                  <FormHelperText
                    error
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {errors.listExpense[index].countDays.message}
                  </FormHelperText>
                )}
            </FormControl>
          </div>
        </StyledTableCell>
        <StyledTableCell className="delete" align="center">
          {count > 1 && (
            <span>
              {type === "edit" ? (
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    addDeleteRow(index);
                    handleDeleteRow(index);
                  }}
                >
                  <DeleteOutlineRoundedIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    handleDeleteRow(index);
                  }}
                >
                  <DeleteOutlineRoundedIcon fontSize="small" />
                </IconButton>
              )}
            </span>
          )}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default RowItemExpense;
