import React, { useEffect, useState } from "react";
import {
  Avatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import CardStyle from "../../shared/general/Card";
import {
  getUserFullName,
  getUserPosition,
  getUserDepartment,
  getUserSection,
  getUserDivision,
  getUserFirstName,
} from "../../../../utils/userData";
import DialogPerformance from "./DialogPerformance";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  padding: 16,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 120,
  },
  "&.result": {
    backgroundColor: "#defbff",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const PerformanceTable = ({ idCompany, selectYear, filterData }) => {
  const { t, i18n } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [sortData, setSortData] = useState({ 
    estimateLevel: true,
    estimateScore: true,
  });
  const [dataSorted, setDataSorted] = useState([]);
  const [performanceSelected, setPerformanceSelected] = useState(null);

  const handleOpenDetail = (value) => {
    setOpenDialog(true);
    setPerformanceSelected(value);
  };

  const handleCloseDetail = () => {
    setOpenDialog(false);
    setPerformanceSelected(null);
  };

  const columns = [
    { id: "name", label: `${t("FullName")}`, minWidth: 240 },
    {
      id: "division",
      label: t("Division"),
    },
    {
      id: "department",
      label: t("Department"),
    },
    {
      id: "section",
      label: t("Section"),
    },
    {
      id: "position",
      label: t("Position"),
    },
    {
      id: "idEstimateLevel",
      label: t("PerformanceAssessmentLevels"),
      sortable: true,
    },
    {
      id: "estimateScore",
      label: t("PerformanceRating"),
      sortable: true,
    },
  ];

  const handleSortedData = (column) => {
    const data = [...dataSorted].sort((a, b) =>
      sortData[column]
        ? a[column] - b[column]
        : b[column] - a[column]
    );

    setSortData((prev) => ({ ...prev, [column]: !prev[column] }));
    setDataSorted(data);
  };

  useEffect(() => {
    setDataSorted([...filterData])
  }, [filterData])

  return (
    <>
      <CardStyle style={{ padding: "16px" }}>
        <TableContainer style={{ width: "100%", maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledCellHeader
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className={column.className}
                  >
                    {column.label}
                    {column.sortable && (
                      <IconButton
                        onClick={() => handleSortedData(column.id)}
                      >
                        {sortData[column.id] ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )}
                      </IconButton>
                    )}
                  </StyledCellHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSorted.length > 0 ? (
                dataSorted.map((item) => (
                  <StyledRowContent key={item.idEmployees}>
                    <StyledCellContent className="sticky">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          paddingLeft: "4px",
                        }}
                      >
                        <Avatar
                          alt={getUserFirstName(item)}
                          src={`${item.imageURL}`}
                        />
                        <Typography>{getUserFullName(item)}</Typography>
                        {item.estimateLevel ? (
                          <IconButton onClick={() => handleOpenDetail(item)} size="small">
                            <SearchIcon />
                          </IconButton>
                        ) : null}
                      </div>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography className="position">
                        {getUserDivision(item)}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography className="position">
                        {getUserDepartment(item)}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography className="position">
                        {getUserSection(item)}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography className="position">
                        {getUserPosition(item)}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent className="result">
                      <Typography
                        fontSize={item.estimateLevel ? 22 : 16}
                        fontWeight={500}
                        textAlign={"center"}
                      >
                        {item.estimateLevel ? item.estimateLevel : t("notEvaluatedYet")}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent className="result">
                      <Typography
                        fontSize={22}
                        fontWeight={500}
                        textAlign={"center"}
                      >
                        {item.estimateScore ? Math.ceil(item.estimateScore) : ""}
                      </Typography>
                    </StyledCellContent>
                  </StyledRowContent>
                ))
              ) : (
                <StyledRowContent>
                  <StyledCellContent colSpan={5} style={{ height: 150 }}>
                    <Typography align="center">{t("NoData")}</Typography>
                  </StyledCellContent>
                </StyledRowContent>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardStyle>
      {openDialog && (
        <DialogPerformance
          open={openDialog}
          handleClose={handleCloseDetail}
          performance={performanceSelected}
          idCompany={idCompany}
          selectYear={selectYear}
        />
      )}
    </>
  );
};

export default PerformanceTable;
