import React from "react";
import {
  Typography,
  IconButton,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Chip,
  Stack,
  Rating,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EventNoteIcon from "@mui/icons-material/EventNote";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const isDateInRange = (dateRanges, currentDate) => {
  const normalizedCurrentDate = currentDate.startOf('day');
  
  return dateRanges.some((dateRange) => {
    const startDate = dayjs(dateRange.startDate).startOf('day');
    const endDate = dayjs(dateRange.endDate).endOf('day'); 

    return normalizedCurrentDate.isBetween(startDate, endDate, null, '[]');
  });
};

const KpiCard = ({ kpi, onDelete, onView, totalWeight }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const thLang = i18n.resolvedLanguage === "th"

  const { result: kpiDate } = useSelector((state) => state.kpiDate);
  
  const editDates = kpiDate
    ? kpiDate.filter((dateItem) => dateItem.type === "edit")
    : [];
  const isEditDateInRange = isDateInRange(editDates, dayjs());
  
  const evaluateDates = kpiDate
    ? kpiDate.filter((dateItem) => dateItem.type === "evaluate")
    : [];
  const isEvaluateDateInRange = isDateInRange(evaluateDates, dayjs());

  return (
    <Card
      sx={{
        marginBottom: "16px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
        position: "relative",
      }}
    >
      <CardContent>
        {kpi.isCascade && (
          <Box
            position={"absolute"}
            top={"-1px"}
            left={"30px"}
            color={"#ffffff"}
            bgcolor={"#ff4e88"}
            borderRadius={"8px"}
            padding={"2px 4px"}
            fontSize={"14px"}
          >
            {t("Cascade")}
          </Box>
        )}
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="center"
          gap={2}
        >
          <Box
            sx={{
              backgroundColor: "#F4F6FA",
              borderRadius: "16px",
              padding: "16px 24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              color="#4535C1"
              fontSize="14px"
              fontWeight="400"
              textAlign="center"
            >
              {t("Weight")}
              <Typography
                variant="h6"
                component="div"
                fontSize="28px"
                color="#4535C1"
                fontWeight="600"
              >
                {`${Math.round(kpi.weight)}%`}
              </Typography>
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={1}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Box>
              <Typography variant="body2">
                <span style={{ color: "#718EBF", fontWeight: 500 }}>
                  {t("KPIName")}:
                </span>
                <Typography fontSize={16}>{kpi.kpiName}</Typography>
              </Typography>
            </Box>
            <Box>
              {kpi.idDivision ? (
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#718EBF", fontWeight: 500 }}>
                    {t("Division")}:
                  </span>
                  <Typography fontSize={16}>
                    {thLang ? kpi.divisionName : kpi.divisionName_EN}
                  </Typography>
                </Typography>
              ) : null}
              {kpi.idDepartment ? (
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#718EBF", fontWeight: 500 }}>
                    {t("Department")}:
                  </span>
                  <Typography fontSize={16}>
                    {thLang ? kpi.departmentName : kpi.departmentName_EN}
                  </Typography>
                </Typography>
              ) : null}
              {kpi.idSection ? (
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#718EBF", fontWeight: 500 }}>
                    {t("Section")}:
                  </span>
                  <Typography fontSize={16}>
                    {thLang ? kpi.sectionName : kpi.sectionName_EN}
                  </Typography>
                </Typography>
              ) : null}
              <Typography variant="body2">
                <span style={{ color: "#718EBF", fontWeight: 500 }}>
                  {t("Date")}:{" "}
                </span>
                <Typography fontSize={16}>
                  {`${dayjs(kpi.startDate).format("DD/MM/YYYY")} -
                  ${dayjs(kpi.endDate).format("DD/MM/YYYY")}`}
                </Typography>
              </Typography>
            </Box>
            <Stack display="flex" flexDirection="column" gap={1}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="#718EBF"
                  fontWeight={500}
                  mr={1}
                >
                  {t("Progress")}:
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={kpi.progress || 0}
                  sx={{
                    width: { xs: "100px", sm: "150px" },
                    height: "8px",
                    borderRadius: "5px",
                    backgroundColor: "#FFCBCB",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#FF4E88",
                    },
                  }}
                />
                <Typography ml={1} color="#FF4E88" fontWeight={500}>
                  {kpi.progress || 0}%
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="#718EBF"
                  fontWeight={500}
                  mr={1}
                >
                  {t("Rating")}:
                </Typography>
                <Box display="flex" alignItems="flex-end" gap={1}>
                  <Rating value={kpi.rating} readOnly />
                  <Typography fontWeight={500} color="#FFC403">
                    {kpi.rating ? kpi.rating : null}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography
                  variant="body2"
                  color="#718EBF"
                  fontWeight={500}
                >
                  {t("Status")}:
                </Typography>
                {kpi.evaluate === null ? (
                  <Chip label={t("HaveNotBeenEvaluated")} />
                ) : kpi.evaluate === 1 ? (
                  <Chip sx={{ backgroundColor: "#50B498", color: "#ffffff" }} label={t("Success")} />
                ) : (
                  <Chip sx={{ backgroundColor: "#E46A76", color: "#ffffff" }} label={t("Failure")}/>
                )}
              </Box>
            </Stack>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap={1}
          >
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: "#604CC3" }} onClick={() => onView(kpi)}>
                <EventNoteIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  history.push({
                    pathname: "/kpi/team/plan/manager",
                    state: { planList: kpi, isEdit: true, totalWeight },
                  });
                }}
                sx={{ color: isEditDateInRange ? '#4535C1' : '#EEEEEE' }}
                disabled={!isEditDateInRange}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={() => onDelete(kpi.idKpiTeam)}
                sx={{ color: isEditDateInRange ? '#E4003A' : '#EEEEEE' }}
                disabled={!isEditDateInRange}
              >
                <DeleteOutlineRoundedIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Box display={"flex"} gap={1}>
              {isEvaluateDateInRange && (
                <Chip
                  sx={{
                    backgroundColor: "#4535c1",
                    color: "#ffffff",
                    borderRadius: "16px",
                  }}
                  clickable
                  label={t("Evaluate")}
                  onClick={() => {
                    history.push({
                      pathname: "/kpi/team/evaluate/manager",
                      state: { kpi },
                    });
                  }}
                />
              )}
              <Chip
                sx={{
                  backgroundColor: "#4535c1",
                  color: "#ffffff",
                  borderRadius: "16px",
                }}
                clickable
                label={t("Update")}
                onClick={() => {
                  history.push({
                    pathname: "/kpi/team/update",
                    state: { planList: kpi, isEdit: true, totalWeight },
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default KpiCard;
