import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Checkbox, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledDialogTitle = styled(DialogTitle)({
    fontWeight: 'bold',
    fontSize: '1.2rem',
});

const StyledDialogContentText = styled(DialogContentText)({
    marginBottom: '16px',
    lineHeight: 1.6,
});

const StyledTypography = styled(Typography)({
    paddingBottom: '8px',
    lineHeight: 1.8,
});

const StyledDialogActions = styled(DialogActions)({
    padding: '16px 24px',
});

const UsagePopup = ({ open, onClose, isShowOption, setDontShowAgain }) => {
    const { t } = useTranslation();
    const [dontShowAgain, setDontShowAgainLocal] = useState(false);

    const handleDontShowAgainChange = () => {
        const newValue = !dontShowAgain;
        setDontShowAgainLocal(newValue);
        setDontShowAgain(newValue);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <StyledDialogTitle>{t("Instructions")}</StyledDialogTitle>
            <DialogContent>
                <StyledDialogContentText>
                    <StyledTypography variant="body2" component="p">
                        - {t("Press")} <strong style={{ padding: "0 3px" }}>{"{"}</strong> {t("Or")} <strong>{"{{"}</strong> {t("To_search_and_select_tags")}
                    </StyledTypography>
                    <StyledTypography variant="body2" component="p">
                        - {t("Press")} <strong style={{ padding: "0 3px" }}>SHIFT + ARROW DOWN</strong> {t("To_start_a_new_line")}
                    </StyledTypography>
                    <StyledTypography variant="body2" component="p">
                        - {t('Press')} <strong style={{ padding: "0 8px" }}>{t('Preview')}</strong> {t("To_view_a_sample_document")}
                    </StyledTypography>
                    <StyledTypography variant="body2" component="p">
                        - {t('Press')} <strong style={{ padding: "0 8px" }}>{t('Save')}</strong> {t("To_create_a_document")}
                    </StyledTypography>
                </StyledDialogContentText>
                {isShowOption && (
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            checked={dontShowAgain}
                            onChange={handleDontShowAgainChange}
                            color="primary"
                        />
                        <Typography variant="body2">{t("Not_showing_again")}</Typography>
                    </Box>
                )}
            </DialogContent>
            <StyledDialogActions>
                <Button onClick={handleClose} color="primary" variant="contained" sx={{ textTransform: 'none' }}>
                    {t("Close")}
                </Button>
            </StyledDialogActions>
        </Dialog>
    );
};

export default UsagePopup;




