import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import {
  getKpiTeamProgressById,
  addKpiTeamProgress,
  updateKpiTeamProgressPercent,
} from "../../../../../actions/kpi";
import { useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import UploadDataDialog from "../uploadData";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  borderRadius: "30px",
  overflow: "hidden",
  border: "1px solid #EEEEEE",
  display: "flex",
  justifyContent: "center",
  width: "fit-content",
});

const StyledToggleButton = styled(ToggleButton)(({ theme, selectedColor }) => ({
  flex: 1,
  borderRadius: "30px",
  "&.Mui-selected": {
    backgroundColor: selectedColor,
    color: "#FFFFFF",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      borderLeft: "1px solid #EEEEEE",
    },
  },
  "&:hover": {
    backgroundColor: `${selectedColor} !important`,
    color: "#FFFFFF",
  },
  backgroundColor: "#EEEEEE",
  color: "#000000",
  textAlign: "center",
  minWidth: "100px",
  maxWidth: "200px",
}));

const StyledRoot = styled(Box)({
  display: "flex",
  justifyContent: "center",
  padding: "24px 0",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const monthList = [
  "Months.January",
  "Months.February",
  "Months.March",
  "Months.April",
  "Months.May",
  "Months.June",
  "Months.July",
  "Months.August",
  "Months.September",
  "Months.October",
  "Months.November",
  "Months.December",
];

const validationSchema = yup.object({
  kpiPlan: yup.string().max(500).required(),
  kpiAchievement: yup.string().max(300).required(),
  kpiFailure: yup.string().max(300).required(),
  kpiKeyLearning: yup.string().max(300).required(),
  kpiSuccess: yup.number().required(),
  file: yup.mixed(),
  month: yup.number().required(),
});

const KpiTeamProgressDetail = ({ month, idKpiTeam, selectMonthKPI, setExpanded }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isEdit =
    userProfile &&
    (userProfile.roles.includes("ROLE_ADMIN") ||
      userProfile.roles.includes("ROLE_MANAGER"));

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)

  const handleUploadClick = () => {
    setUploadDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setUploadDialogOpen(false);
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      kpiPlan: "",
      kpiAchievement:  "",
      kpiFailure: "",
      kpiKeyLearning: "",
      kpiSuccess: "",
      file: null,
      month,
    },
    resolver: yupResolver(validationSchema)
  });

  const file = watch("file")

  const onSubmit = async (values) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to upload this file?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      handleAddKpiProgress(values);
    }
  };

  const handleAddKpiProgress = async (values) => {
    const formData = new FormData();
    formData.append("kpiPlan", values.kpiPlan);
    formData.append("kpiAchievement", values.kpiAchievement);
    formData.append("kpiFailure", values.kpiFailure);
    formData.append("kpiKeyLearning", values.kpiKeyLearning);
    formData.append("kpiSuccess", values.kpiSuccess);
    formData.append("month", values.month);
    formData.append("file", values.file);
    formData.append("idKpiTeam", idKpiTeam);

    const res = await dispatch(addKpiTeamProgress(formData));
    if (res && res.status === 200) {
      Swal.fire({
        title: "สำเร็จ",
        text: "ข้อมูล KPI ได้รับการอัปเดตแล้ว",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      dispatch(getKpiTeamProgressById(idKpiTeam));
      setExpanded(false);
    } else {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถอัปเดตข้อมูล KPI ได้",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    if (selectMonthKPI) {
      reset({
        kpiPlan: selectMonthKPI.kpiPlan,
        kpiAchievement: selectMonthKPI.kpiAchievement,
        kpiFailure: selectMonthKPI.kpiFailure,
        kpiKeyLearning: selectMonthKPI.kpiKeyLearning,
        kpiSuccess: selectMonthKPI.kpiSuccess,
        month: selectMonthKPI.month,
        file: null,
      })
    }
  }, [month, selectMonthKPI])
  

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {dayjs().get("month") + 1 >= month && (
          <Stack alignItems="flex-start" spacing={2}>
            <Controller
              name="kpiPlan"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("Plan")}
                  multiline
                  rows={3}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error && fieldState.error.message}
                  InputProps={{ readOnly: !isEdit }}
                />
              )}
            />
            <Controller
              name="kpiAchievement"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("Achievement")}
                  multiline
                  rows={3}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error && fieldState.error.message}
                  InputProps={{ readOnly: !isEdit }}
                />
              )}
            />
            <Controller
              name="kpiFailure"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("Failure")}
                  multiline
                  rows={3}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error && fieldState.error.message}
                  InputProps={{ readOnly: !isEdit  }}
                />
              )}
            />
            <Controller
              name="kpiKeyLearning"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("KeyLearning")}
                  multiline
                  rows={3}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error && fieldState.error.message}
                  InputProps={{ readOnly: !isEdit }}
                />
              )}
            />
            {isEdit && (
              <>
                <ButtonBlue
                  variant="contained"
                  onClick={handleUploadClick}
                >
                  {t("Import")}
                </ButtonBlue>
                {file && (
                  <Box display="flex" alignItems="center" mt={2} width="100%">
                    <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                      {file.name}
                    </Typography>
                    <IconButton
                      onClick={() => setValue("file", null)}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    name="kpiSuccess"
                    control={control}
                    render={({ field }) => (
                      <StyledToggleButtonGroup
                        {...field}
                        onChange={(event, newValue) => field.onChange(newValue)}
                        exclusive
                      >
                        <StyledToggleButton value={1} selectedColor="#50B498">
                          {t("Success")}
                        </StyledToggleButton>
                        <StyledToggleButton value={0} selectedColor="#FF4C4C">
                          {t("Failure")}
                        </StyledToggleButton>
                      </StyledToggleButtonGroup>
                    )}
                  />
                  <ButtonBlue
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {t("Update")}
                  </ButtonBlue>
                </div>
              </>
            )}
          </Stack>
        )}
      </form>
      <UploadDataDialog
        open={uploadDialogOpen}
        handleClose={handleCloseDialog}
        onFileSubmit={(value) => setValue("file", value)}
      />
    </>
  );
};

const KpiTeamUpdate = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { result: kpiPlan } = useSelector((state) => state.kpiUpdateId);

  const [expanded, setExpanded] = useState(false);
  const [selectMonthKPI, setSelectMonthKPI] = useState(null);
  const [openDialogProgress, setOpenDialogProgress] = useState(false);
  const [progressValue, setProgressValue] = useState(null);


  const handleChange = (panel) => (event, isExpanded) => {
    const findMonthKPI = kpiPlan.updates.find((item) => item.month === panel);
    if (findMonthKPI && isExpanded) {
      setSelectMonthKPI(findMonthKPI);
    } else {
      setSelectMonthKPI(null);
    }
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenProgressDialog = () => {
    setOpenDialogProgress(true);
  };

  const handleCloseProgressDialog = () => {
    setOpenDialogProgress(false);
  };

  const handleConfirmProgress = async () => {
    const formData = {
      progress: progressValue,
      idKpiTeam: state.planList.idKpiTeam,
    };

    const res = await dispatch(updateKpiTeamProgressPercent(formData));
    if (res && res.status === 200) {
      dispatch(getKpiTeamProgressById(state.planList.idKpiTeam));
      Swal.fire({
        title: t("Success"),
        text: t("UpdateKPISuccessPlanMonth"),
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      handleCloseProgressDialog();
    } else {
      Swal.fire({
        title: t("AnErrorOccurred"),
        text: t("DataSaveUnsuccessful"),
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const columns = [];

  useEffect(() => {
    if (state.planList.idKpiTeam) {
      dispatch(
        getKpiTeamProgressById(state.planList.idKpiTeam)
      );
    }
  }, [dispatch, state.planList.idKpiTeam]);

  useEffect(() => {
    if (kpiPlan) {
      setProgressValue(kpiPlan.progress)
    }
  }, [kpiPlan]);

  return (
    <StyledRoot className="page">
      <Container>
        <GridStyled
          container
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              className="kpi"
              sx={{ ml: 1, fontSize: "30px" }}
            >
              {t("Update")} KPI
            </Typography>
          </Box>
        </GridStyled>
        <div className="cardPlan">
          <Box padding="2px 0">
            <Container maxWidth="lg">
              <Box padding="2px 0" minHeight="700px">
                {kpiPlan && (
                  <Box padding="0px">
                    <Box>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            backgroundColor: "#4f65df",
                            borderRadius: 15,
                            marginBottom: 15,
                            height: "50px",
                          }}
                        >
                          <Typography
                            fontSize={16}
                            variant="h6"
                            sx={{ textAlign: "center", color: "#ffffff" }}
                          >
                            {t("UpdateKPISuccessPlanMonth")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {columns.map((c, index) => (
                              <StyledCellHeader
                                key={index}
                                sx={{
                                  textAlign: c.headerTextAlign
                                    ? c.headerTextAlign
                                    : undefined,
                                  minWidth: c.minWidth ? c.minWidth : undefined,
                                  width: c.width ? c.width : undefined,
                                  maxWidth: c.maxWidth ? c.maxWidth : undefined,
                                }}
                              >
                                {c.name}
                              </StyledCellHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {columns.map((c, cIndex) => (
                              <StyledCellContent
                                key={cIndex}
                                style={{
                                  textAlign: c.cellTextAlign
                                    ? c.cellTextAlign
                                    : undefined,
                                  fontSize: c.cellFontSize
                                    ? c.cellFontSize
                                    : undefined,
                                  minWidth: c.minWidth ? c.minWidth : undefined,
                                  width: c.width ? c.width : undefined,
                                  maxWidth: c.maxWidth ? c.maxWidth : undefined,
                                }}
                              >
                                {c.cellRender(state.planList)}
                              </StyledCellContent>
                            ))}
                          </TableRow>
                          {(!kpiPlan || kpiPlan.length <= 0) && (
                            <TableRow>
                              <TableCell colSpan={8} align="center">
                                <Box
                                  height="120px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Typography variant="h6">
                                    <FindInPageIcon />
                                    {t("NoData")}
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <div>
                        {[
                          ...Array(
                            dayjs(kpiPlan.endDate).get("month") + 1
                          ).keys(),
                        ]
                          .slice(dayjs(kpiPlan.startDate).get("month"))
                          .map((item, index) => (
                            <Accordion
                              key={index}
                              expanded={expanded === item + 1}
                              onChange={handleChange(item + 1)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>
                                  {index + 1}. {t(monthList[item])}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <KpiTeamProgressDetail
                                  month={item + 1}
                                  idKpiTeam={state.planList.idKpiTeam}
                                  selectMonthKPI={selectMonthKPI}
                                  setExpanded={setExpanded}
                                />
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </div>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            </Container>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              mb={1}
            >
              <Typography mr={2} color="#FF4E88" fontWeight={600}>
                {t("Progress")}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progressValue}
                sx={{
                  width: "250px",
                  height: "8px",
                  borderRadius: "5px",
                  backgroundColor: "#FFCBCB",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#FF4E88",
                  },
                }}
              />
              <Typography ml={1} color="#FF4E88" fontWeight={600}>
                {progressValue}%
              </Typography>
            </Box>

            <ButtonBlue
              variant="text"
              onClick={handleOpenProgressDialog}
              sx={{ mt: -1 }}
            >
              {t("Update")} {t("Progress")}
            </ButtonBlue>
          </Box>
        </div>
      </Container>

      {openDialogProgress && (
        <Dialog
          open={openDialogProgress}
          onClose={handleCloseProgressDialog}
          aria-labelledby="progress-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              width: "500px",
              height: "200px",
              borderRadius: "20px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#FAFAFA",
            },
          }}
        >
          <DialogTitle id="progress-dialog-title">
            <Typography variant="h5">{t("Progress")}</Typography>
          </DialogTitle>
          <DialogContent>
            <div style={{ marginBottom: "8px"}}></div>
            <TextFieldTheme
              label={t("Progress")}
              type="number"
              fullWidth
              value={progressValue}
              onChange={(e) => {
                let value = e.target.value
                if (value > 100) value = 100;
                if (value < 0) value = 0;
                setProgressValue(value)
              }}
              InputProps={{
                endAdornment: <Typography>%</Typography>,
                inputProps: { min: 0, max: 100 }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              color="error"
              onClick={handleCloseProgressDialog}
            >
              {`${t("Cancel")}`}
            </Button>
            <ButtonBlue variant="contained" onClick={handleConfirmProgress}>
              {t("Confirm")}
            </ButtonBlue>
          </DialogActions>
        </Dialog>
      )}
    </StyledRoot>
  );
};

export default KpiTeamUpdate;
