import ExcelJS from "exceljs";
import dayjs from "dayjs";

const exportToExcel = (data, t) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("OKRs");

  const headerCellStyle = {
    font: {
      name: "Tahoma",
      size: 12,
      color: { argb: "FFFFFF" },
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };

  const dataCellStyle = {
    font: {
      name: "Tahoma",
      size: 12,
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
    },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };

  sheet.columns = [
    { header: t("EmployeeID"), key: "employeeId", width: 15 },
    { header: t("FirstName"), key: "firstName", width: 20 },
    { header: t("LastName"), key: "lastName", width: 20 },
    { header: t("Division"), key: "division", width: 20 },
    { header: t("Department"), key: "department", width: 20 },
    { header: t("Section"), key: "section", width: 20 },
    { header: t("Position"), key: "position", width: 20 },
    { header: t("KeyResultName"), key: "keyResultName", width: 30 },
    { header: t("Quarter"), key: "quarter", width: 15 },
    { header: t("StartDate"), key: "startDate", width: 20 },
    { header: t("EndDate"), key: "endDate", width: 20 },
    { header: t("OKRCreationDateTime"), key: "okrCreationDateTime", width: 25 },
    { header: t("RequestStatus"), key: "requestStatus", width: 20 },
    { header: t("ApprovalDateTime"), key: "approvalDateTime", width: 25 },
    { header: t("ApprovedBy"), key: "approvedBy", width: 20 },
    { header: t("Description"), key: "description", width: 40 },
    { header: t("Strategy"), key: "strategy", width: 30 },
    { header: t("MeasurementData"), key: "measurementData", width: 30 },
    {
      header: t("TargetMeasurementData"),
      key: "targetMeasurementData",
      width: 30,
    },
    { header: t("MeasurementDataType"), key: "measurementDataType", width: 25 },
    { header: t("moonshot"), key: "moonshot", width: 15 },
    { header: t("ProgressPercentage"), key: "progressPercentage", width: 20 },
    { header: t("evaluator"), key: "evaluator", width: 20 },
    { header: t("Evaluation"), key: "evaluation", width: 20 },
    { header: t("EvaluationStatus"), key: "evaluationStatus", width: 20 },
    { header: t("EvaluationDateTime"), key: "evaluationDateTime", width: 25 },
    { header: t("EvaluatedBy"), key: "evaluatedBy", width: 20 },
    { header: t("OKRsScore"), key: "okrsScore", width: 15 },
    { header: t("Status"), key: "status", width: 15 },
  ];

  const headerRow = sheet.getRow(1);
  headerRow.height = 20;
  headerRow.eachCell((cell) => {
    cell.style = headerCellStyle;
  });

  data.forEach((objective) => {
    const employee = {
      employeeId: objective.idEmployees || "",
      firstName: objective.firstname_TH || "",
      lastName: objective.lastname_TH || "",
      division: objective.divisionName || "",
      department: objective.departmentName || "",
      section: objective.sectionName || "",
      position: objective.positionName || "",
    };

    objective.keyResults.forEach((kr) => {
      const isMoonShot =
        kr.isMoonShotPlanning === 1 || kr.isMoonShotEvaluation === 1;

      const moonshotValue = isMoonShot ? t("Yes") : t("No");

      let progressPercentage = "";
      if (kr.progress !== null && kr.progress !== undefined) {
        progressPercentage = `${kr.progress}%`;
      } else if (kr.isSuccess !== null && kr.isSuccess !== undefined) {
        progressPercentage = kr.isSuccess ? "100%" : "0%";
      } else {
        progressPercentage = "0%";
      }

      const okrsScore =
        kr.managerRating !== null && kr.managerRating !== undefined
          ? kr.managerRating
          : "";

      const row = sheet.addRow({
        ...employee,
        keyResultName: kr.keyResultName,
        quarter: `Q${objective.quarter} ${objective.year}`,
        startDate: kr.startDate ? dayjs(kr.startDate).format("DD/MM/YYYY") : "",
        endDate: kr.endDate ? dayjs(kr.endDate).format("DD/MM/YYYY") : "",
        okrCreationDateTime: objective.createDate
          ? dayjs(objective.createDate).format("DD/MM/YYYY HH:mm")
          : "",
        requestStatus:
          kr.isApprove === null
            ? t("Pending")
            : kr.isApprove
            ? t("Approved")
            : t("Rejected"),
        approvalDateTime: kr.approveDate
          ? dayjs(kr.approveDate).format("DD/MM/YYYY HH:mm")
          : "",
        approvedBy: `${kr.manager_firstname_TH || ""} ${
          kr.manager_lastname_TH || ""
        }`,
        description: kr.description || "",
        strategy: kr.tacticName || "",
        measurementData: kr.measureEvidence || "",
        targetMeasurementData: kr.commitQuality || "",
        measurementDataType:
          kr.measureDataType === 1 ? t("Number") : t("Explanation"),
        moonshot: moonshotValue,
        progressPercentage: progressPercentage,
        evaluator: kr.evaluator || "",
        evaluation: kr.evaluation || "",
        evaluationStatus: kr.evaluationStatus || "",
        evaluationDateTime: kr.evaluationDate
          ? dayjs(kr.evaluationDate).format("DD/MM/YYYY HH:mm")
          : "",
        evaluatedBy: kr.evaluatedBy || "",
        okrsScore: okrsScore,
        status: kr.status || "",
      });
      row.height = 20;
      row.eachCell((cell) => {
        cell.style = dataCellStyle;
      });
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `OKRs_${dayjs().format("YYYYMMDD_HHmmss")}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
};

export default exportToExcel;
