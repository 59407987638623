import {
  LEAVEEMPLOYEES_FETCHING,
  LEAVEEMPLOYEES_SUCCESS,
  LEAVEEMPLOYEES_FAILED,
  REPORT_NEW_EMPLOYEES_FETCHING,
  REPORT_NEW_EMPLOYEES_FAILED,
  REPORT_NEW_EMPLOYEES_SUCCESS,
  REPORT_RESIGN_EMPLOYEES_FETCHING,
  REPORT_RESIGN_EMPLOYEES_FAILED,
  REPORT_RESIGN_EMPLOYEES_SUCCESS,
  REPORT_CONTRACT_TERMINATE_EMPLOYEES_FETCHING,
  REPORT_CONTRACT_TERMINATE_EMPLOYEES_FAILED,
  REPORT_CONTRACT_TERMINATE_EMPLOYEES_SUCCESS,
  ATTENDANCE_ONCE_FETCHING,
  ATTENDANCE_ONCE_FAILED,
  ATTENDANCE_ONCE_SUCCESS,
  TAX_DEDUCTION_FETCHING,
  TAX_DEDUCTION_FAILED,
  TAX_DEDUCTION_SUCCESS,
  USER_PROVIDENTFUNDS_FETCHING,
  USER_PROVIDENTFUNDS_FAILED,
  USER_PROVIDENTFUNDS_SUCCESS,
  LEAVEEMPLOYEES_WITHDRAW_FETCHING,
  LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
  LEAVEEMPLOYEES_WITHDRAW_FAILED,
  REQUESTTIME_FETCHING,
  REQUESTTIME_SUCCESS,
  REQUESTTIME_FAILED,
  REQUESTTIME_WITHDRAW_FETCHING,
  REQUESTTIME_WITHDRAW_SUCCESS,
  REQUESTTIME_WITHDRAW_FAILED,
  PAYRUNS_FETCHING,
  PAYRUNS_FAILED,
  PAYRUNS_SUCCESS,
  PAYSLIP_FETCHING,
  PAYSLIP_FAILED,
  PAYSLIP_SUCCESS,
  REPORT_PROBATION_EMPLOYEES_SUCCESS,
  REPORT_PROBATION_EMPLOYEES_FAILED,
  REPORT_PROBATION_EMPLOYEES_FETCHING,
  REPORT_SUMMARY_OVERTIME_SHIFT_FETCHING,
  REPORT_SUMMARY_OVERTIME_SHIFT_FAILED,
  REPORT_SUMMARY_OVERTIME_SHIFT_SUCCESS,
  REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FETCHING,
  REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FAILED,
  REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_SUCCESS,
  REPORT_LEAVE_ABSENT_LATE_FETCHING,
  REPORT_LEAVE_ABSENT_LATE_FAILED,
  REPORT_LEAVE_ABSENT_LATE_SUCCESS,
  REPORT_LEAVE_ABSENT_LATE_PAYROLL_FETCHING,
  REPORT_LEAVE_ABSENT_LATE_PAYROLL_FAILED,
  REPORT_LEAVE_ABSENT_LATE_PAYROLL_SUCCESS,
  SALARYADJUSTEMPLOYEE_FETCHING,
  SALARYADJUSTEMPLOYEE_FAILED,
  SALARYADJUSTEMPLOYEE_SUCCESS,
  DASHBOARDEMPLOYEE_FETCHING,
  DASHBOARDEMPLOYEE_SUCCESS,
  DASHBOARDEMPLOYEE_FAILED,
  WORKINGTIME_FETCHING,
  WORKINGTIME_FAILED,
  WORKINGTIME_SUCCESS,
  TIMEATTENDANCE_FETCHING,
  TIMEATTENDANCE_SUCCESS,
  TIMEATTENDANCE_FAILED,
  DILIGENCE_ALLOWANCE_REPORT_FETCHING,
  DILIGENCE_ALLOWANCE_REPORT_SUCCESS,
  DILIGENCE_ALLOWANCE_REPORT_FAILED,
} from "./types";

import ReportService from "../services/report.service";
import EmployeeService from "../services/employee.service";
import query from "devextreme/data/query";
import { ConstructionOutlined } from "@mui/icons-material";

export const getAllLeaveWithDrawBy = (filter, search) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FETCHING,
    });
    const res = await ReportService.getAllLeaveWithDraw(filter, search);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FAILED,
    });
    console.log(error);
  }
};

export const getAllRequestTimeBy = (filter, search) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_FETCHING,
    });
    const res = await ReportService.getAllRequestTime(filter, search);
    if (res) {
      dispatch({
        type: REQUESTTIME_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestTimeWithDrawBy =
  (filter, search) => async (dispatch) => {
    try {
      dispatch({
        type: REQUESTTIME_WITHDRAW_FETCHING,
      });
      const res = await ReportService.getAllRequestTimeWithDraw(filter, search);
      if (res) {
        dispatch({
          type: REQUESTTIME_WITHDRAW_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (err) {
      dispatch({
        type: REQUESTTIME_WITHDRAW_FAILED,
      });
      console.log(err);
    }
  };

export const getLeaveRequestByCompany =
  (filter, search) => async (dispatch) => {
    try {
      dispatch({
        type: LEAVEEMPLOYEES_FETCHING,
      });
      const res = await ReportService.getLeaveRequestByCompany(filter, search);
      if (res) {
        dispatch({
          type: LEAVEEMPLOYEES_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (err) {
      dispatch({
        type: LEAVEEMPLOYEES_FAILED,
      });
      console.log(err);
    }
  };

export const getContractTerminateEmployees = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REPORT_CONTRACT_TERMINATE_EMPLOYEES_FETCHING,
    });
    const res = await ReportService.getContractTerminateEmployees(query);
    if (res) {
      dispatch({
        type: REPORT_CONTRACT_TERMINATE_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
      return res
    }
  } catch (error) {
    dispatch({
      type: REPORT_CONTRACT_TERMINATE_EMPLOYEES_FAILED,
    });
    console.log(error);
  }
};

export const getNewemployees = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REPORT_NEW_EMPLOYEES_FETCHING,
    });
    const res = await ReportService.getNewemployees(query);
    if (res) {
      dispatch({
        type: REPORT_NEW_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
      return res
    }
  } catch (error) {
    dispatch({
      type: REPORT_NEW_EMPLOYEES_FAILED,
    });
    console.log(error);
  }
};

export const getResignEmployee = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REPORT_RESIGN_EMPLOYEES_FETCHING,
    });
    const res = await ReportService.getResignEmployee(query);
    if (res) {
      dispatch({
        type: REPORT_RESIGN_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
      return res
    }
  } catch (error) {
    dispatch({
      type: REPORT_RESIGN_EMPLOYEES_FAILED,
    });
    console.log(error);
  }
};

export const getAttendanceEmployees = (filter, start) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_ONCE_FETCHING,
    });
    const res = await ReportService.getAttendanceEmployees(filter, start);
    if (res) {
      dispatch({
        type: ATTENDANCE_ONCE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ATTENDANCE_ONCE_FAILED,
    });
    console.log(error);
  }
};

export const getProbationEmployees = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REPORT_PROBATION_EMPLOYEES_FETCHING,
    });
    const res = await ReportService.getProbationEmployees(query);
    if (res) {
      dispatch({
        type: REPORT_PROBATION_EMPLOYEES_SUCCESS,
        payload: res.data,
      });

      return res
    }
  } catch (error) {
    dispatch({
      type: REPORT_PROBATION_EMPLOYEES_FAILED,
    });
    console.log(error);
  }
};

export const getTaxemployee = (filter, start) => async (dispatch) => {
  try {
    dispatch({
      type: TAX_DEDUCTION_FETCHING,
    });
    const res = await ReportService.getTaxemployee(filter, start);
    if (res) {
      dispatch({
        type: TAX_DEDUCTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: TAX_DEDUCTION_FAILED,
    });
    console.log(error);
  }
};

export const getProvidentemployee = (filter, start) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROVIDENTFUNDS_FETCHING,
    });
    const res = await ReportService.getProvidentemployee(filter, start);
    if (res) {
      dispatch({
        type: USER_PROVIDENTFUNDS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_PROVIDENTFUNDS_FAILED,
    });
    console.log(error);
  }
};

export const getPayrollemployee = (filter, start) => async (dispatch) => {
  try {
    dispatch({
      type: PAYSLIP_FETCHING,
    });
    const res = await ReportService.getPayrollemployee(filter, start);
    if (res) {
      dispatch({
        type: PAYSLIP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PAYSLIP_FAILED,
    });
    console.log(error);
  }
};

export const getPayrollCompany = (filter, start) => async (dispatch) => {
  try {
    dispatch({
      type: PAYRUNS_FETCHING,
    });
    const res = await ReportService.getPayrollCompany(filter, start);
    if (res) {
      dispatch({
        type: PAYRUNS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PAYRUNS_FAILED,
    });
    console.log(error);
  }
};

export const getSummaryOvertimeShift = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REPORT_SUMMARY_OVERTIME_SHIFT_FETCHING,
    });

    const res = await ReportService.getSummaryOvertimeShift(query);

    if (res) {
      dispatch({
        type: REPORT_SUMMARY_OVERTIME_SHIFT_SUCCESS,
        payload: res.data,
      });
    }

    return res;
  } catch (error) {
    dispatch({
      type: REPORT_SUMMARY_OVERTIME_SHIFT_FAILED,
    });
    return error.response;
  }
};

export const getSummaryOvertimeShiftIndividual = (idEmployees, query) => async (dispatch) => {
  try {
    dispatch({
      type: REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FETCHING,
    });

    const res = await ReportService.getSummaryOvertimeShiftIndividual(
      idEmployees,
      query
    );

    if (res) {
      dispatch({
        type: REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_SUCCESS,
        payload: res.data,
      });
    }

    return res;
  } catch (error) {
    dispatch({
      type: REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FAILED,
    });
    return error.response;
  }
};

export const getLeaveAbsentLate = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REPORT_LEAVE_ABSENT_LATE_FETCHING,
    });

    const res = await ReportService.getLeaveAbsentLateReport(query);

    if (res) {
      dispatch({
        type: REPORT_LEAVE_ABSENT_LATE_SUCCESS,
        payload: res.data,
      });
    }

    return res;
  } catch (error) {
    dispatch({
      type: REPORT_LEAVE_ABSENT_LATE_FAILED,
    });
    return error.response;
  }
};

export const getLeaveAbsentLatePayroll = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REPORT_LEAVE_ABSENT_LATE_PAYROLL_FETCHING,
    });

    const res = await ReportService.getLeaveAbsentLatePayrollReport(query);

    if (res) {
      dispatch({
        type: REPORT_LEAVE_ABSENT_LATE_PAYROLL_SUCCESS,
        payload: res.data,
      });
    }

    return res;
  } catch (error) {
    dispatch({
      type: REPORT_LEAVE_ABSENT_LATE_PAYROLL_FAILED,
    });
    return error.response;
  }
};

export const getTotalWorkingHours = async (query) => {
  try {
    const res = await ReportService.getTotalWorkingHours(query);

    if (res) {
      return res;
    }

  } catch (error) {
    return error.response;
  }
};

export const getSalaryAdjustEmployee = (query) => async (dispatch) => {
  try {
    dispatch({
      type: SALARYADJUSTEMPLOYEE_FETCHING,
    });
    const res = await ReportService.getSalaryAdjustEmployee(query);
    if (res) {
      dispatch({
        type: SALARYADJUSTEMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: SALARYADJUSTEMPLOYEE_FAILED,
    });
    console.log(error);
  }
};

export const getDashboardEmployee = (query) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARDEMPLOYEE_FETCHING,
    });
    const res = await ReportService.getDashboardEmployee(query);
    if (res) {
      dispatch({
        type: DASHBOARDEMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DASHBOARDEMPLOYEE_FAILED,
    });
    console.log(error);
  }
};

export const getWorkingTime = (query) => async (dispatch) => {
  try {
    dispatch({
      type: WORKINGTIME_FETCHING,
    });
    const res = await ReportService.getWorkingTime(query);
    if (res) {
      dispatch({
        type: WORKINGTIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: WORKINGTIME_FAILED,
    });
    console.log(error);
  }
};


export const getTimeAttendance = async (query) => {
  try {
    const res = await ReportService.getTimeAttendance(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getWorkingTimeReport = async (query) => {
  try {
    const res = await ReportService.getWorkingTimeReport(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getLeaveDetails = async (query) => {
  try {
    const res = await ReportService.getLeaveDetails(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}

export const getSummaryTimeAttendanceDepartment = async (query) => {
  try {
    const res = await ReportService.getSummaryTimeAttendanceDepartment(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getLevelingReport = async (query) => {
  try {
    const res = await ReportService.getLevelingReport(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
}

export const getEmploymentReport = async (query) => {
  try {
    const res = await ReportService.getEmploymentReport(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
}

export const getTransferPosition = async (query) => {
  try {
    const res = await ReportService.getTransferPosition(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
}

export const getDiligenceAllowanceReport = (query) => async (dispatch) => {
  try {
    dispatch({
      type: DILIGENCE_ALLOWANCE_REPORT_FETCHING,
    });
    const res = await ReportService.getDiligenceAllowanceReport(query);
    if (res) {
      dispatch({
        type: DILIGENCE_ALLOWANCE_REPORT_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (error) {
    dispatch({
      type: DILIGENCE_ALLOWANCE_REPORT_FAILED,
    });
    console.log(error);
    return error.response
  }
};

export const getCompensateLeaveReport = async (query) => {
  try {
    const res = await ReportService.getCompensateLeaveReport(query);
    if (res) {
      return res;
    }
    return res
  } catch (error) {
    console.error(error);
    return error.response
  }
};

export const getEmployeesFamilyReport = async (query) => {
  try {
    const res = await ReportService.getEmployeesFamilyReport(query);
    if (res) {
      return res;
    }
    return res
  } catch (error) {
    console.error(error);
    return error.response
  }
};

export const getTurnoverByCompany = async (query) => {
  try {
    const res = await ReportService.getTurnoverByCompany(query);
    if (res) {
      return res;
    }
    return res
  } catch (error) {
    console.error(error);
    return error.response
  }
};

export const getProvidentFundDate = async (query) =>{
  try {
    const res = await ReportService.getProvidentFundDate(query);
    if (res) {
      return res;
    }
    return res
  } catch (error) {
    console.error(error);
    return error.response
  }
}


export const getProvidentFundYear = async (query) =>{
  try {
    const res = await ReportService.getProvidentFundYear(query);
    if (res) {
      return res;
    }
    return res
  } catch (error) {
    console.error(error);
    return error.response
  }
}


export const getProvidentFundUpdate = async (query) =>{
  try {
    const res = await ReportService.getProvidentFundUpdate(query);
    if (res) {
      return res;
    }
    return res
  } catch (error) {
    console.error(error);
    return error.response
  }
}

export const getIrregularitiesLeaveAbsentLate = async (query) =>{
  try {
    const res = await ReportService.getIrregularitiesLeaveAbsentLate(query);
    if (res) {
      return res;
    }
    return res
  } catch (error) {
    console.error(error);
    return error.response
  }
}

