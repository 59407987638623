// OKRsEmployeeView.jsx

import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  FilledInput,
  FormControl,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { getEmployeeSearch } from "../../../../../actions/employee";
import { getAffiliateOrganization } from "../../../../../actions/company";
import {
  getUserFullName,
  getUserCompany,
  getUserPosition,
  getUserDivision,
  getUserDepartment,
  getUserSection,
} from "../../../../../utils/userData";

import { getAllIndividualObjectiveForAllEmployees } from "../../../../../actions/okrs";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import exportToExcel from "./exportToExcel";

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#FFFFFF",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginTop: theme.spacing(8),
}));

const StyledFilledInputCustom = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledCellHeader = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  fontWeight: "bold",
  minWidth: 200,
}));

const StyledStickyCellHeader = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  fontWeight: "bold",
  minWidth: 200,
  position: "sticky",
  left: 0,
  zIndex: 4,
}));

const StyledRowContent = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  textDecoration: "none",
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#f7f7f7",
  },
}));

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  padding: 16,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 1,
  },
  "& .fullname": {
    display: "flex",
    alignItems: "center",
  },
  "& .company": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const OKRsEmployeeView = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const { result: employeeSearch } = useSelector(
    (state) => state.employeeSearch
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  const [moreFilter, setMoreFilter] = useState(false);

  const [companyList, setCompanyList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);

  const [personalObjectives, setPersonalObjectives] = useState([]);

  const [statusResult, setStatusResult] = useState({
    isOpen: false,
    result: null,
    label: "",
  });

  const currentYear = new Date().getFullYear();
  const yearOptions = [
    { value: "all", label: t("All") },
    ...Array.from({ length: 5 }, (_, i) => ({
      value: (currentYear - 3 + i).toString(),
      label: (currentYear - 3 + i).toString(),
    })),
  ];

  const quarterOptions = [
    { value: "all", label: t("All") },
    { value: "Q1", label: t("Q1") },
    { value: "Q2", label: t("Q2") },
    { value: "Q3", label: t("Q3") },
    { value: "Q4", label: t("Q4") },
  ];

  const useHookForm = useForm({
    defaultValues: {
      name: "",
      company: { idCompany: "all", companyName: `${t("All")}` },
      division: { idDivision: "all", divisionName: `${t("All")}` },
      department: { idDepartment: "all", departmentName: `${t("All")}` },
      position: { idPosition: "all", positionName: `${t("All")}` },
      year: { value: "all", label: t("All") },
      quarter: { value: "all", label: t("All") },
    },
    mode: "all",
  });

  const { watch } = useHookForm;
  const selectedYear = watch("year");
  const selectedQuarter = watch("quarter");

  const filteredObjectives = useMemo(() => {
    if (!personalObjectives || personalObjectives.length === 0) return [];

    let filtered = [...personalObjectives];

    if (selectedYear && selectedYear.value !== "all") {
      filtered = filtered.filter(
        (obj) => obj.year === parseInt(selectedYear.value)
      );
    }

    if (selectedQuarter && selectedQuarter.value !== "all") {
      filtered = filtered.filter(
        (obj) =>
          obj.quarter === parseInt(selectedQuarter.value.replace("Q", ""))
      );
    }

    return filtered;
  }, [personalObjectives, selectedYear, selectedQuarter]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAffiliateOrganization());
      const response = await dispatch(
        getAllIndividualObjectiveForAllEmployees()
      );
      setPersonalObjectives(response.data);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      const defaultCompany = affiliateOrganizationList[0];
      useHookForm.resetField("company", { defaultValue: defaultCompany });
      setCompanyList(affiliateOrganizationList);

      const organization = defaultCompany.organization || [];
      renderFilterOptions(organization);

      const initialSearchData = {
        name: null,
        idCompany:
          defaultCompany.idCompany !== "all" ? defaultCompany.idCompany : null,
        idDivision: null,
        idDepartment: null,
        idPosition: null,
        per_page: rowsPerPage,
        page: 1,
        language: i18n.language,
      };
      dispatch(getEmployeeSearch(initialSearchData)).then((res) => {
        setTotal(res.data.pagination.total);
      });
    }
  }, [
    affiliateOrganizationList,
    dispatch,
    i18n.language,
    rowsPerPage,
    useHookForm,
  ]);

  const renderFilterOptions = (organization) => {
    const divisions = [
      ...new Map(
        organization.map((item) => [item["idDivision"], item])
      ).values(),
    ].filter((x) => x.idDivision);
    setDivisionList(divisions);

    const departments = [
      ...new Map(
        organization.map((item) => [item["idDepartment"], item])
      ).values(),
    ].filter((x) => x.idDepartment);
    setDepartmentList(departments);

    const positions = [
      ...new Map(
        organization.map((item) => [item["idPosition"], item])
      ).values(),
    ].filter((x) => x.idPosition);
    setPositionList(positions);
  };

  const onSubmit = (data) => {
    const searchData = {
      name: data.name ? data.name : null,
      idCompany:
        data.company.idCompany !== "all" ? data.company.idCompany : null,
      idDivision:
        data.division.idDivision !== "all" ? data.division.idDivision : null,
      idDepartment:
        data.department.idDepartment !== "all"
          ? data.department.idDepartment
          : null,
      idPosition:
        data.position.idPosition !== "all" ? data.position.idPosition : null,
      per_page: rowsPerPage,
      page: 1,
      language: i18n.language,
    };

    setPage(0);
    dispatch(getEmployeeSearch(searchData)).then((res) => {
      setTotal(res.data.pagination.total);
      if (res.data.pagination.total === 0) {
        setStatusResult({
          isOpen: true,
          result: "info",
          label: t("NoEmployeeFound"),
        });
      }
    });
  };

  const handleChangePage = (_, newPage) => {
    const searchData = {
      name: useHookForm.getValues("name")
        ? useHookForm.getValues("name")
        : null,
      idCompany:
        useHookForm.getValues("company").idCompany !== "all"
          ? useHookForm.getValues("company").idCompany
          : null,
      idDivision:
        useHookForm.getValues("division").idDivision !== "all"
          ? useHookForm.getValues("division").idDivision
          : null,
      idDepartment:
        useHookForm.getValues("department").idDepartment !== "all"
          ? useHookForm.getValues("department").idDepartment
          : null,
      idPosition:
        useHookForm.getValues("position").idPosition !== "all"
          ? useHookForm.getValues("position").idPosition
          : null,
      per_page: rowsPerPage,
      page: newPage + 1,
      language: i18n.language,
    };

    setPage(newPage);
    dispatch(getEmployeeSearch(searchData)).then((res) => {
      setTotal(res.data.pagination.total);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const countApprovedObjectives = (idEmployees) => {
    if (!filteredObjectives || filteredObjectives.length === 0) return 0;

    const employeeObjectives = filteredObjectives.filter(
      (obj) => obj.idEmployees === idEmployees
    );

    const approvedObjectives = employeeObjectives.filter(
      (obj) =>
        obj.keyResults &&
        obj.keyResults.length > 0 &&
        obj.keyResults.every((kr) => kr.isApprove === 1)
    );

    return approvedObjectives.length;
  };

  const countApprovedKeyResults = (idEmployees) => {
    if (!filteredObjectives || filteredObjectives.length === 0) return 0;

    const employeeObjectives = filteredObjectives.filter(
      (obj) => obj.idEmployees === idEmployees
    );

    const approvedKeyResults = employeeObjectives.reduce((acc, obj) => {
      if (obj.keyResults && obj.keyResults.length > 0) {
        const approvedKR = obj.keyResults.filter((kr) => kr.isApprove === 1);
        return acc + approvedKR.length;
      }
      return acc;
    }, 0);

    return approvedKeyResults;
  };

  const countSelfEvaluatedKeyResults = (idEmployees) => {
    if (!filteredObjectives || filteredObjectives.length === 0) return 0;

    const employeeObjectives = filteredObjectives.filter(
      (obj) => obj.idEmployees === idEmployees
    );

    const selfEvaluatedKR = employeeObjectives.reduce((acc, obj) => {
      if (obj.keyResults && obj.keyResults.length > 0) {
        const evaluatedKR = obj.keyResults.filter(
          (kr) => kr.employeeRating !== null
        );
        return acc + evaluatedKR.length;
      }
      return acc;
    }, 0);

    return selfEvaluatedKR;
  };

  const countPendingSelfEvaluatedKeyResults = (idEmployees) => {
    if (!filteredObjectives || filteredObjectives.length === 0) return 0;

    const employeeObjectives = filteredObjectives.filter(
      (obj) => obj.idEmployees === idEmployees
    );

    const pendingKR = employeeObjectives.reduce((acc, obj) => {
      if (obj.keyResults && obj.keyResults.length > 0) {
        const evaluatedKR = obj.keyResults.filter((kr) => {
          const endDate = new Date(kr.endDate);
          const today = new Date();
          return (
            kr.employeeRating === null &&
            (endDate <= today || kr.progressPercent === 100)
          );
        });
        return acc + evaluatedKR.length;
      }
      return acc;
    }, 0);

    return pendingKR;
  };

  const countManagerEvaluatedKeyResults = (idEmployees) => {
    if (!filteredObjectives || filteredObjectives.length === 0) return 0;

    const employeeObjectives = filteredObjectives.filter(
      (obj) => obj.idEmployees === idEmployees
    );

    const managerEvaluatedKR = employeeObjectives.reduce((acc, obj) => {
      if (obj.keyResults && obj.keyResults.length > 0) {
        const evaluatedKR = obj.keyResults.filter(
          (kr) => kr.employeeRating !== null && kr.managerRating !== null
        );
        return acc + evaluatedKR.length;
      }
      return acc;
    }, 0);

    return managerEvaluatedKR;
  };

  const countPendingManagerEvaluationKeyResults = (idEmployees) => {
    if (!filteredObjectives || filteredObjectives.length === 0) return 0;

    const employeeObjectives = filteredObjectives.filter(
      (obj) => obj.idEmployees === idEmployees
    );

    const pendingManagerEvaluationKR = employeeObjectives.reduce((acc, obj) => {
      if (obj.keyResults && obj.keyResults.length > 0) {
        const pendingKR = obj.keyResults.filter(
          (kr) => kr.employeeRating !== null && kr.managerRating === null
        );
        return acc + pendingKR.length;
      }
      return acc;
    }, 0);

    return pendingManagerEvaluationKR;
  };

  const countMoonShotKeyResults = (idEmployees) => {
    if (!filteredObjectives || filteredObjectives.length === 0) return 0;

    const employeeObjectives = filteredObjectives.filter(
      (obj) => obj.idEmployees === idEmployees
    );

    const moonShotCount = employeeObjectives.reduce((acc, obj) => {
      if (obj.keyResults && obj.keyResults.length > 0) {
        obj.keyResults.forEach((kr) => {
          let count = 0;
          if (kr.isMoonShotPlanning === 1) count += 1;
          if (kr.isMoonShotEvaluation === 1) count += 1;
          acc += count;
        });
      }
      return acc;
    }, 0);

    return moonShotCount;
  };

  const columns = [
    { id: "fullname", label: `${t("FullName")}`, minWidth: 300 },
    { id: "company", label: `${t("Company")}`, minWidth: 300 },
    { id: "division", label: `${t("Division")}`, minWidth: 150 },
    { id: "department", label: `${t("Department")}`, minWidth: 150 },
    { id: "section", label: `${t("Section")}`, minWidth: 150 },
    { id: "position", label: `${t("Position")}`, minWidth: 150 },
    { id: "planObjective", label: `${t("PlanObjective")}`, minWidth: 150 },
    { id: "planKR", label: `${t("PlanKR")}`, minWidth: 150 },
    { id: "selfEvaluate", label: `${t("SelfEvaluate")}`, minWidth: 150 },
    {
      id: "pendingSelfEvaluate",
      label: `${t("PendingSelfEvaluate")}`,
      minWidth: 150,
    },
    {
      id: "managerEvaluated",
      label: `${t("ManagerEvaluated")}`,
      minWidth: 150,
    },
    {
      id: "pendingManagerEvaluate",
      label: `${t("PendingManagerEvaluate")}`,
      minWidth: 150,
    },
    { id: "moonshot", label: `${t("moonShot")}`, minWidth: 150 },
  ];

  const calculateStatus = (kr) => {
    const today = new Date();

    if (kr.isApprove === null) {
      return "รออนุมัติแผน";
    }

    if (kr.isApprove === 1 && kr.isArchieve === null) {
      return "กำลังดำเนินการ";
    }

    if (kr.isApprove === 0) {
      return "ไม่อนุมัติแผน";
    }

    if (
      kr.isApprove === 1 &&
      (kr.progressPercent === 100 ||
        (kr.endDate && new Date(kr.endDate) <= today)) &&
      kr.isSuccess === null
    ) {
      return "รอประเมินตัวเอง";
    }

    if (
      kr.isApprove === 1 &&
      kr.progressPercent === 100 &&
      kr.isSuccess === 1 &&
      kr.isAchieve === null
    ) {
      return "รออนุมัติผล";
    }

    if (kr.isApprove === 1 && kr.isSuccess === 1 && kr.isAchieve === 1) {
      return "เป้าหมายสำเร็จ";
    }

    if (kr.isApprove === 1 && kr.isSuccess === 1 && kr.isAchieve === 0) {
      return "เป้าหมายไม่สำเร็จ";
    }

    return "-";
  };

  const handleExportExcel = () => {
    if (!employeeSearch || !employeeSearch.data) {
      setStatusResult({
        isOpen: true,
        result: "info",
        label: t("No data available for export"),
      });
      return;
    }

    const dataToExport = employeeSearch.data.flatMap((employee) => {
      const objectives = personalObjectives.filter(
        (obj) => obj.idEmployees === employee.idEmployees
      );

      if (objectives.length === 0) {
        return [
          {
            employeeId: employee.employeeID,
            firstName: employee.firstname_TH,
            lastName: employee.lastname_TH,
            division: getUserDivision(employee) || "-",
            section: getUserSection(employee) || "-",
            department: getUserDepartment(employee) || "-",
            position: getUserPosition(employee) || "-",
            keyResult: "-",
            okrQuarter: "-",
            startDate: "-",
            endDate: "-",
            status: "-",
            description: "-",
            strategy: "-",
            measureEvidence: "-",
            quality: "-",
            dataType: "-",
            moonshot: "-",
            progress: "-",
            evaluator: "-",
            evaluation: "-",
            evaluationStatus: "-",
            evaluationTime: "-",
            evaluatedBy: "-",
            okrScore: "-",
            finalStatus: "-",
          },
        ];
      }

      return objectives.flatMap((objective) => {
        return (objective.keyResults || []).map((kr) => ({
          employeeId: employee.employeeID,
          firstName: employee.firstname_TH,
          lastName: employee.lastname_TH,
          division: getUserDivision(employee) || "-",
          section: getUserSection(employee) || "-",
          department: getUserDepartment(employee) || "-",
          position: getUserPosition(employee) || "-",
          keyResult: kr.keyResultName || "-",
          okrQuarter: objective.quarter || "-",
          startDate: kr.startDate || "-",
          endDate: kr.endDate || "-",
          status: calculateStatus(kr) || "-",
          description: kr.description || "-",
          strategy: kr.tacticName || "-",
          measureEvidence: kr.measureEvidence || "-",
          quality: kr.commitQuality || "-",
          dataType: kr.measureDataType || "-",
          moonshot:
            kr.isMoonShotPlanning === 1 && kr.isMoonShotEvaluation === 0
              ? "1"
              : kr.isMoonShotPlanning === 1 && kr.isMoonShotEvaluation === 1
              ? "2"
              : "-",
          progress: `${kr.progressPercent || 0}%`,
          evaluator:
            `${kr.manager_firstname_TH} ${kr.manager_lastname_TH}` || "-",
          evaluation:
            kr.isAchieve === 1
              ? "สำเร็จ"
              : kr.isAchieve === 0
              ? "ไม่สำเร็จ"
              : "-",
          evaluationStatus:
            kr.isAchieve === 1 ? "ประเมินแล้ว" : "ยังไม่ประเมิน",
          evaluationTime: kr.managerEvaluateAt || "-",
          evaluatedBy:
            `${kr.manager_firstname_TH} ${kr.manager_lastname_TH}` || "-",
          okrScore: kr.employeeRating || "-",
          finalStatus: calculateStatus(kr) || "-",
        }));
      });
    });

    exportToExcel(dataToExport, t);
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
        paddingTop: "64px",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        paddingX={{ xs: 2, sm: 3, md: 4 }}
        paddingY={4}
      >
        <StyledContainer
          sx={{
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    {t("OKRsEmployeeView")}
                  </Typography>
                </Box>
                <Box textAlign="right">
                  <Typography variant="h4" color="primary">
                    {total}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontWeight: 500 }}
                  >
                    {t("EmpTotal")}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} container justifyContent="flex-end">
                <Link
                  component="button"
                  type="button"
                  variant="body2"
                  onClick={() => {
                    setMoreFilter(!moreFilter);
                  }}
                  underline="hover"
                >
                  {moreFilter ? `${t("HideSearch")}` : `${t("SearchMore")}`}
                </Link>
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.secondary">
                    {t("Search")}
                  </Typography>
                  <Controller
                    name="name"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth variant="filled">
                        <StyledFilledInputCustom
                          {...field}
                          placeholder={`${t("Search")} ${t("FullName")}`}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    )}
                  />
                </StyledBoxSearch>
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.secondary">
                    {t("Company")}
                  </Typography>
                  <Controller
                    name="company"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <StyledAutocomplete
                        {...field}
                        options={[
                          {
                            idCompany: "all",
                            companyName: `${t("All")}`,
                            companyName_EN: `${t("All")}`,
                          },
                          ...(companyList ? companyList : []),
                        ]}
                        getOptionLabel={(option) =>
                          i18n.resolvedLanguage === "th"
                            ? option.companyName
                            : option.companyName_EN
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.idCompany === value.idCompany
                        }
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("Company")}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value);
                          useHookForm.setValue("division", {
                            idDivision: "all",
                            divisionName: `${t("All")}`,
                          });
                          useHookForm.setValue("department", {
                            idDepartment: "all",
                            departmentName: `${t("All")}`,
                          });
                          useHookForm.setValue("position", {
                            idPosition: "all",
                            positionName: `${t("All")}`,
                          });

                          if (value.idCompany !== "all") {
                            renderFilterOptions(value.organization);
                          } else {
                            setDivisionList([]);
                            setDepartmentList([]);
                            setPositionList([]);
                          }

                          onSubmit(useHookForm.getValues());
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>

              {moreFilter && (
                <Fragment>
                  <Grid item xs={12} md={4}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.secondary">
                        {t("Division")}
                      </Typography>
                      <Controller
                        name="division"
                        control={useHookForm.control}
                        render={({ field, fieldState }) => (
                          <StyledAutocomplete
                            {...field}
                            options={[
                              {
                                idDivision: "all",
                                divisionName: `${t("All")}`,
                                divisionName_EN: `${t("All")}`,
                              },
                              ...(divisionList ? divisionList : []),
                            ]}
                            getOptionLabel={(option) =>
                              i18n.resolvedLanguage === "th"
                                ? option.divisionName
                                : option.divisionName_EN
                            }
                            renderOption={(props, option) => (
                              <MenuItem {...props} key={option.idDivision}>
                                <Box>
                                  <Typography>
                                    {i18n.resolvedLanguage === "th"
                                      ? option.divisionName
                                      : option.divisionName_EN}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.idDivision === value.idDivision
                            }
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                variant="filled"
                                placeholder={t("Division")}
                                error={fieldState.error ? true : false}
                                helperText={
                                  fieldState.error
                                    ? fieldState.error.message
                                    : null
                                }
                              />
                            )}
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);
                              useHookForm.setValue("department", {
                                idDepartment: "all",
                                departmentName: `${t("All")}`,
                              });
                              useHookForm.setValue("position", {
                                idPosition: "all",
                                positionName: `${t("All")}`,
                              });
                              onSubmit(useHookForm.getValues());
                            }}
                            noOptionsText={t("NoData")}
                            disableClearable
                          />
                        )}
                      />
                    </StyledBoxSearch>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.secondary">
                        {t("Department")}
                      </Typography>
                      <Controller
                        name="department"
                        control={useHookForm.control}
                        render={({ field, fieldState }) => (
                          <StyledAutocomplete
                            {...field}
                            options={[
                              {
                                idDepartment: "all",
                                departmentName: `${t("All")}`,
                                departmentName_EN: `${t("All")}`,
                              },
                              ...(departmentList ? departmentList : []),
                            ]}
                            getOptionLabel={(option) =>
                              i18n.resolvedLanguage === "th"
                                ? option.departmentName
                                : option.departmentName_EN
                            }
                            renderOption={(props, option) => (
                              <MenuItem {...props} key={option.idDepartment}>
                                <Box>
                                  <Typography>
                                    {i18n.resolvedLanguage === "th"
                                      ? option.departmentName
                                      : option.departmentName_EN}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.idDepartment === value.idDepartment
                            }
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                variant="filled"
                                placeholder={t("Department")}
                                error={fieldState.error ? true : false}
                                helperText={
                                  fieldState.error
                                    ? fieldState.error.message
                                    : null
                                }
                              />
                            )}
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);
                              useHookForm.setValue("position", {
                                idPosition: "all",
                                positionName: `${t("All")}`,
                              });
                              onSubmit(useHookForm.getValues());
                            }}
                            noOptionsText={t("NoData")}
                            disableClearable
                          />
                        )}
                      />
                    </StyledBoxSearch>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.secondary">
                        {t("Position")}
                      </Typography>
                      <Controller
                        name="position"
                        control={useHookForm.control}
                        render={({ field, fieldState }) => (
                          <StyledAutocomplete
                            {...field}
                            options={[
                              {
                                idPosition: "all",
                                positionName: `${t("All")}`,
                                positionName_EN: `${t("All")}`,
                              },
                              ...(positionList ? positionList : []),
                            ]}
                            getOptionLabel={(option) =>
                              i18n.resolvedLanguage === "th"
                                ? option.positionName
                                : option.positionName_EN
                            }
                            renderOption={(props, option) => (
                              <MenuItem {...props} key={option.idPosition}>
                                <Box>
                                  <Typography>
                                    {i18n.resolvedLanguage === "th"
                                      ? option.positionName
                                      : option.positionName_EN}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.idPosition === value.idPosition
                            }
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                variant="filled"
                                placeholder={t("Position")}
                                error={fieldState.error ? true : false}
                                helperText={
                                  fieldState.error
                                    ? fieldState.error.message
                                    : null
                                }
                              />
                            )}
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);
                              onSubmit(useHookForm.getValues());
                            }}
                            noOptionsText={t("NoData")}
                            disableClearable
                          />
                        )}
                      />
                    </StyledBoxSearch>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.secondary">
                        {t("Year")}
                      </Typography>
                      <Controller
                        name="year"
                        control={useHookForm.control}
                        render={({ field, fieldState }) => (
                          <StyledAutocomplete
                            {...field}
                            options={yearOptions}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                variant="filled"
                                placeholder={t("Year")}
                                error={fieldState.error ? true : false}
                                helperText={
                                  fieldState.error
                                    ? fieldState.error.message
                                    : null
                                }
                              />
                            )}
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);
                              onSubmit(useHookForm.getValues());
                            }}
                            noOptionsText={t("NoData")}
                            disableClearable
                          />
                        )}
                      />
                    </StyledBoxSearch>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.secondary">
                        {t("Quarter")}
                      </Typography>
                      <Controller
                        name="quarter"
                        control={useHookForm.control}
                        render={({ field, fieldState }) => (
                          <StyledAutocomplete
                            {...field}
                            options={quarterOptions}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                variant="filled"
                                placeholder={t("Quarter")}
                                error={fieldState.error ? true : false}
                                helperText={
                                  fieldState.error
                                    ? fieldState.error.message
                                    : null
                                }
                              />
                            )}
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);
                              onSubmit(useHookForm.getValues());
                            }}
                            noOptionsText={t("NoData")}
                            disableClearable
                          />
                        )}
                      />
                    </StyledBoxSearch>
                  </Grid>
                </Fragment>
              )}

              <Grid item xs={12} container justifyContent="flex-end">
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <ButtonBlue
                      variant="outlined"
                      startIcon={<DownloadRoundedIcon />}
                      onClick={handleExportExcel}
                    >
                      {t("Download")}
                    </ButtonBlue>
                  </Grid>
                  <Grid item>
                    <ButtonBlue variant="contained" type="submit">
                      {t("Search")}
                    </ButtonBlue>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>

          <Box marginTop="24px" sx={{ overflowX: "auto" }}>
            <TableContainer style={{ width: "100%", maxHeight: 550 }}>
              <Table stickyHeader aria-label="employee table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) =>
                      column.id === "fullname" ? (
                        <StyledStickyCellHeader
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledStickyCellHeader>
                      ) : (
                        <StyledCellHeader
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledCellHeader>
                      )
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {employeeSearch && employeeSearch.data ? (
                    employeeSearch.data.length > 0 ? (
                      employeeSearch.data.map((item) => (
                        <StyledRowContent key={item.idEmployees}>
                          {/* FullName Column - Fixed */}
                          <StyledCellContent className="sticky">
                            <Box className="fullname">
                              <Avatar
                                src={item.imageProfile}
                                alt={item.firstname_TH}
                              />
                              <Typography sx={{ marginLeft: 2 }}>
                                {getUserFullName(item)}
                              </Typography>
                            </Box>
                          </StyledCellContent>

                          {/* Company Column */}
                          <StyledCellContent>
                            <Typography className="company">
                              {getUserCompany(item) || "-"}
                            </Typography>
                          </StyledCellContent>

                          {/* Division Column */}
                          <StyledCellContent>
                            <Typography>
                              {getUserDivision(item) || "-"}
                            </Typography>
                          </StyledCellContent>

                          {/* Department Column */}
                          <StyledCellContent>
                            <Typography>
                              {getUserDepartment(item) || "-"}
                            </Typography>
                          </StyledCellContent>

                          {/* Section Column */}
                          <StyledCellContent>
                            <Typography>
                              {getUserSection(item) || "-"}
                            </Typography>
                          </StyledCellContent>

                          {/* Position Column */}
                          <StyledCellContent>
                            <Typography>
                              {getUserPosition(item) || "-"}
                            </Typography>
                          </StyledCellContent>

                          <StyledCellContent>
                            <Typography>
                              <Typography>
                                {countApprovedObjectives(item.idEmployees) === 0
                                  ? "-"
                                  : countApprovedObjectives(item.idEmployees)}
                              </Typography>
                            </Typography>
                          </StyledCellContent>

                          <StyledCellContent>
                            <Typography>
                              {countApprovedKeyResults(item.idEmployees) === 0
                                ? "-"
                                : countApprovedKeyResults(item.idEmployees)}
                            </Typography>
                          </StyledCellContent>

                          <StyledCellContent>
                            <Typography>
                              {countSelfEvaluatedKeyResults(
                                item.idEmployees
                              ) === 0
                                ? "-"
                                : countSelfEvaluatedKeyResults(
                                    item.idEmployees
                                  )}
                            </Typography>
                          </StyledCellContent>

                          <StyledCellContent>
                            <Typography>
                              {countPendingSelfEvaluatedKeyResults(
                                item.idEmployees
                              ) === 0
                                ? "-"
                                : countPendingSelfEvaluatedKeyResults(
                                    item.idEmployees
                                  )}
                            </Typography>
                          </StyledCellContent>

                          <StyledCellContent>
                            <Typography>
                              {countManagerEvaluatedKeyResults(
                                item.idEmployees
                              ) === 0
                                ? "-"
                                : countManagerEvaluatedKeyResults(
                                    item.idEmployees
                                  )}
                            </Typography>
                          </StyledCellContent>

                          <StyledCellContent>
                            <Typography>
                              {countPendingManagerEvaluationKeyResults(
                                item.idEmployees
                              ) === 0
                                ? "-"
                                : countPendingManagerEvaluationKeyResults(
                                    item.idEmployees
                                  )}
                            </Typography>
                          </StyledCellContent>

                          <StyledCellContent>
                            <Typography>
                              {countMoonShotKeyResults(item.idEmployees) === 0
                                ? "-"
                                : countMoonShotKeyResults(item.idEmployees)}
                            </Typography>
                          </StyledCellContent>
                        </StyledRowContent>
                      ))
                    ) : (
                      <StyledRowContent>
                        <StyledCellContent
                          colSpan={columns.length}
                          style={{ height: 150 }}
                        >
                          <Typography align="center">
                            {t("NoEmployeeFound")}
                          </Typography>
                        </StyledCellContent>
                      </StyledRowContent>
                    )
                  ) : (
                    <StyledRowContent>
                      <StyledCellContent
                        colSpan={columns.length}
                        style={{ height: 150 }}
                      >
                        <Typography align="center">
                          {t("LoadingEmpData")}
                        </Typography>
                      </StyledCellContent>
                    </StyledRowContent>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* การแบ่งหน้า */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("RowsPerPage")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t("OutOf")} ${
                  count !== -1 ? count : `${t("MoreThan")} ${to}`
                }`
              }
            />
          </Box>

          {/* Snackbar สำหรับข้อความสถานะ */}
          {statusResult.isOpen && (
            <Snackbar
              open={statusResult.isOpen}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={() =>
                setStatusResult({ isOpen: false, result: null, label: "" })
              }
            >
              <Alert
                severity={statusResult.result}
                onClose={() =>
                  setStatusResult({ isOpen: false, result: null, label: "" })
                }
                sx={{ width: "100%" }}
              >
                {statusResult.label}
              </Alert>
            </Snackbar>
          )}
        </StyledContainer>
      </Box>
    </Box>
  );
};

export default OKRsEmployeeView;
