import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../../actions/family";
import dayjs from "dayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import {
  addEducation,
  updateEducation,
} from "../../../../../../actions/education";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../../shared/date/datePicker";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const DialogEducation = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    // fromYear: yup.date().nullable().required(t("PleaseEnterRequiredInformationCompletely")),
    // endYear: yup.date().nullable().required(t("PleaseEnterRequiredInformationCompletely")),
    degree: yup.string().required(`${t("ThisFieldIsRequired")}`),
    university: yup.string().required(`${t("ThisFieldIsRequired")}`),
    // faculty: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
    // major: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
    // gpa: yup.string().required(t("PleaseEnterRequiredInformationCompletely")),
  });

  const useHookForm = useForm({
    defaultValues: {
      fromYear: null,
      endYear: null,
      degree: "",
      university: "",
      faculty: "",
      major: "",
      gpa: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      if (drawerConfig.isEdit) {
        useHookForm.reset({
          fromYear: drawerConfig.data.fromYear
            ? new Date(`01-01-${drawerConfig.data.fromYear}`)
            : null,
          endYear: drawerConfig.data.endYear
            ? new Date(`01-01-${drawerConfig.data.endYear}`)
            : null,
          degree: drawerConfig.data.degree || "",
          university: drawerConfig.data.university || "",
          faculty: drawerConfig.data.faculty || "",
          major: drawerConfig.data.major || "",
          gpa: drawerConfig.data.gpa || "",
        });
      } else {
        useHookForm.reset({
          fromYear: null,
          endYear: null,
          degree: "",
          university: "",
          faculty: "",
          major: "",
          gpa: "",
        });
      }
    }
  }, [drawerConfig.isOpen]);

  const onSubmit = async (data) => {
    // console.log(data)
    let result = null;
    const formData = {
      ...data,
    };

    formData.fromYear = formData.fromYear
      ? dayjs(formData.fromYear).format("YYYY")
      : null;
    formData.endYear = formData.endYear
      ? dayjs(formData.endYear).format("YYYY")
      : null;

    if (drawerConfig.isEdit) {
      // formData.idEducation = drawerConfig.data.idEducation;
      result = await dispatch(
        updateEducation(drawerConfig.data.idEducations, formData)
      );
    } else {
      formData.idEmployees = employeeProfile.idEmployees;
      result = await dispatch(addEducation(formData));
    }

    // const result = {status: 200}

    // console.log(result)

    if (result) {
      if (result.status === 200) {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
        onClose();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: t("ErrorOccurredContactAdmin")
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: t("ErrorOccurredContactAdmin")
      // }))
    }
  };

  return (
    <DrawerCustom
      title={
        drawerConfig.isEdit ? `${t("EditEducation")}` : `${t("AddEducation")}`
      }
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="field-label">{t("Start")}</Typography>
              <Controller
                name="fromYear"
                control={useHookForm.control}
                render={({ field }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year"]}
                    inputFormat="YYYY"
                    disableFuture
                    disableMaskedInput
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: `${t("Year")}`,
                        }}
                        onBlur={field.onBlur}
                        error={
                          useHookForm.formState.errors[field.name]
                            ? true
                            : false
                        }
                        helperText={
                          useHookForm.formState.errors[field.name]
                            ? useHookForm.formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="field-label">{t("End")}</Typography>
              <Controller
                name="endYear"
                control={useHookForm.control}
                render={({ field }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year"]}
                    inputFormat="YYYY"
                    disableFuture
                    disableMaskedInput
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: `${t("Year")}`,
                          // readOnly: true,
                        }}
                        onBlur={field.onBlur}
                        error={
                          useHookForm.formState.errors[field.name]
                            ? true
                            : false
                        }
                        helperText={
                          useHookForm.formState.errors[field.name]
                            ? useHookForm.formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("EducationDegree")}
              </Typography>
              <Controller
                name="degree"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  >
                    <MenuItem value="ประถมศึกษา">{`${t(
                      "PrimaryEducation"
                    )}`}</MenuItem>
                    <MenuItem value="มัธยมศึกษาตอนต้น">
                      {`${t("JuniorHighSchoolEducation")}`}
                    </MenuItem>
                    <MenuItem value="มัธยมศึกษาตอนปลาย">
                      {`${t("SeniorHighSchoolEducation")}`}
                    </MenuItem>
                    <MenuItem value="ปวช.">{`${t(
                      "VocationalCertificate"
                    )}`}</MenuItem>
                    <MenuItem value="ปวส.">{`${t(
                      "​CertificateOfTechnicalVocation"
                    )}`}</MenuItem>
                    <MenuItem value="ปริญญาตรี">{`${t(
                      "BachelorDegrees"
                    )}`}</MenuItem>
                    <MenuItem value="ปริญญาโท">{`${t(
                      "MasterDegrees"
                    )}`}</MenuItem>
                    <MenuItem value="ปริญญาเอก">{`${t(
                      "DoctorDegrees"
                    )}`}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">{t("University")}</Typography>
              <Controller
                name="university"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">{t("Faculty")}</Typography>
              <Controller
                name="faculty"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">{t("Major")}</Typography>
              <Controller
                name="major"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">{t("GPA")}</Typography>
              <Controller
                name="gpa"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      allowNegative: false,
                      allowLeadingZeros: true,
                      allowEmptyFormatting: false,
                      value: field.value,
                      decimalScale: 2,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                      },
                      isAllowed: (values) => {
                        const { value } = values;
                        return value <= 4;
                      },
                    }}
                    onChange={() => {}}
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEducation;
