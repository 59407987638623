import dayjs from "dayjs";
import DocumentService from "../../../../../../services/document.service";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2';

export const handleDownloadPdf = async (id, idEmployees = null, idCompany = null, name) => {
  try {
    const response = await DocumentService.getSmartDocumentPDF(id, idEmployees, idCompany);

    if (!response.data.Content || !response.data.filename) {
      throw new Error("Missing HTML content or filename");
    }
    const date = dayjs(new Date).format("YYYY-MM-DD");
    const contentHtml = response.data.Content;
    const filename = `${name ? `${name}_${date}` : response.data.filename}.pdf`;

    const pdfContent = document.createElement('div');
    pdfContent.id = 'pdfContent';
    pdfContent.className = 'pdfContent';
    pdfContent.innerHTML = contentHtml;

    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
    @import url('https://fonts.googleapis.com/css2?family=THSarabunNew&family=Noto+Sans+Thai&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@400;700&display=swap');
    /* Quill editor styles */
    @import url('https://cdn.quilljs.com/1.3.6/quill.snow.css');
    /* Custom styles if any */

  .pdfContent {
      padding:45px 56px;
    }   
    #pdfContent {
      padding:45px 56px;
    }

     .ql-align-right {
        text-align: right;
    }
     .ql-align-left {
        text-align: left;
    }
     .ql-align-center {
        text-align: center;
    }

    .image-class {
        width: 120px;
        max-width: 100%;
        height: auto;
    }

    .ql-font-THSarabunNew {
        font-family: 'THSarabunNew', sans-serif;
    }

    .ql-font-serif {
        font-family: serif;
    }

    .ql-font-NotoSansThai {
        font-family: 'Noto Sans Thai', sans-serif;
    }

`;

    document.head.appendChild(styleElement);

    document.body.appendChild(pdfContent);

    // รอให้ฟอนต์และรูปภาพโหลดเสร็จ
    await new Promise(resolve => setTimeout(resolve, 100)); // หน่วงเวลา 1 วินาที

    const canvas = await html2canvas(pdfContent, {
      useCORS: true,        // การใช้ CORS เพื่อโหลดแหล่งที่มาผ่านภาพ
      logging: true,        // เปิดการบันทึกการทำงาน
      backgroundColor: '#ffffff', // สีพื้นหลังของ canvas
      imageTimeout: 0,      // ตั้งเวลารอภาพ
      allowTaint: true,     // อนุญาตให้โหลดภาพจากแหล่งที่มาต่างๆ
      scrollX: 0,           // ตั้งค่าการเลื่อนแนวนอน
      scrollY: 0,           // ตั้งค่าการเลื่อนแนวตั้ง
    });

    const imgData = canvas.toDataURL('image/png');

    const doc = new jsPDF('p', 'mm', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const paddingLeft = 0; // in mm
    const paddingRight = 0; // in mm
    const contentWidth = pageWidth - paddingLeft - paddingRight;

    const paddingTop = 0; // in mm
    const paddingBottom = 0; // in mm
    const contentHeight = pageHeight - paddingTop - paddingBottom;

    const imgHeight = (canvas.height * contentWidth) / canvas.width;

    // Ensure the image height does not exceed the page height
    const finalImgHeight = Math.min(imgHeight, contentHeight);

    doc.addImage(imgData, 'PNG', paddingLeft, paddingTop, contentWidth, finalImgHeight);
    doc.save(filename);

    Swal.fire({
      title: 'Success!',
      text: 'Document generated successfully!',
      icon: 'success',
      confirmButtonText: 'Ok',
    });

    document.body.removeChild(pdfContent);
    document.head.removeChild(styleElement);

  } catch (error) {
    console.error('Error generating PDF:', error);
    Swal.fire({
      title: 'Error!',
      text: 'Unable to generate document!',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
  }
};

export const fetchImageAsBase64 = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const updateImageSources = async (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const images = doc.querySelectorAll('img');

  for (const img of images) {
    const base64Data = await fetchImageAsBase64(img.src);
    img.src = base64Data;
  }

  return doc.body.innerHTML;
};
