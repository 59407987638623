import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import "./IndividualPlanEvaluation.css";
import "./ManagerTeamApprove.css";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import { putApprovalIdvPlan } from "../../../../../actions/IndividualPlans";

import {
  ButtonGroup,
  Grid,
  Button,
  Typography,
  Avatar,
  Container,
  Box,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/styles";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

//icon
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircleIcon from "@mui/icons-material/Circle";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const useStyles = makeStyles(() => ({
  dateRange: {
    marginLeft: 8,
    marginRight: 8,
  },
  accordionKR: {
    marginLeft: 48,
    marginRight: 48,
  },
  expandIcon: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "50%",
    fontSize: "25px",
  },
  circle: {
    color: "#bcbcbc",
  },
  notSelectedQ: {
    backgroundColor: "#bcbcbc",
    width: 16,
    height: 16,
  },
  selectedQ: {
    // backgroundColor : '#3f51b5',
    width: 50,
    height: 50,
  },
  selectedIcon: {
    fontSize: 28,
  },
  mainData: {
    fontSize: 19,
  },
  data: {
    fontSize: 21,
    fontWeight: "bold",
  },
}));

const Accordion = withStyles({
  root: {
    backgroundColor: "#fff",
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "5px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "40px 16px 40px 16px",
    display: "block",
    backgroundColor: "#fafafa",
    borderLeft: "5px solid #4f65df",
    "& .MuiTypography-h6": {
      fontSize: "1.1rem",
      color: "#222f3e",
    },
  },
}))(MuiAccordionDetails);

function IndividualPlanEvaluation() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [values, setValues] = useState(location.state.planList);
  const classes = useStyles();
  const { handleSubmit } = useForm();
  const [ratingValue, setRatingValue] = useState(0);
  const [moonShotRating, setMoonShotRating] = useState({
    rating1: 0,
    rating2: 0,
    rating3: 0,
  });
  const [moonShotHover, setMoonShotHover] = useState({
    hover1: 0,
    hover2: 0,
    hover3: 0,
  });

  const onPutApprovalIdvPlan = async (id, formData) => {
    await dispatch(putApprovalIdvPlan(id, formData));
    history.push(`/approval`);
  };

  const onSubmit = () => {
    const formData = {
      planIdvId: values.planIdvId,
      moonShotKR1: values.moonShotKR1,
      kR1Status: values.kR1Status,
      moonShotKR2: values.moonShotKR2,
      kR2Status: values.kR2Status,
      moonShotKR3: values.moonShotKR3,
      kR3Status: values.kR3Status,
      approvedByEmpId: userProfile.idEmployees,
      planStatus: "Approved",
      dateApproved: dayjs(new Date()).format("YYYY-MM-DD"),
    };

    onPutApprovalIdvPlan(formData.planIdvId, formData);
  };

  useEffect(() => {
    setValues({
      ...values,
      moonShotKR1: moonShotRating.rating1,
    });
  }, [moonShotRating.rating1]);

  useEffect(() => {
    setValues({
      ...values,
      moonShotKR2: moonShotRating.rating2,
    });
  }, [moonShotRating.rating2]);

  useEffect(() => {
    setValues({
      ...values,
      moonShotKR3: moonShotRating.rating3,
    });
  }, [moonShotRating.rating3]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Manager Review</h3>
            </Grid>
          </Grid>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <Grid container justifyContent="center">
            {["Q1", "Q2", "Q3", "Q4"].map((value) => {
              return (
                <Grid
                  item
                  xl={2}
                  md={2}
                  xs={2}
                  style={{ textAlign: "-webkit-center" }}
                >
                  {values.quater == value ? (
                    <Avatar
                      sx={{ backgroundColor: "primary.main" }}
                      className={classes.selectedQ}
                    >
                      <LocationOnIcon className={classes.selectedIcon} />
                    </Avatar>
                  ) : (
                    <Avatar className={classes.notSelectedQ}>
                      <CircleIcon className={classes.circle} />
                    </Avatar>
                  )}
                  <Typography className={classes.spaceFromLocationToQ}>
                    {value}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          <br />

          {/* ข้อมูล objective และ impact */}
          <Grid container justifyContent="center">
            <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
              <Typography className={classes.data}>Objective</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.objectiveName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
              <Typography className={classes.data}>Impact</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.impactName}
              </Typography>
            </Grid>
          </Grid>
          <hr className="hrTag" />
          <div className={classes.accordionKR}>
            <Accordion square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography className={classes.data}>Key Result 1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      Key Result 1
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.keyResult1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Description</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.description1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Tactics</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.tactics1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Date Start - End</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {dayjs(values.dateStart1).format("DD/MM/YYYY")}
                      <span className={classes.dateRange}>-</span>
                      {dayjs(values.dateEnd1).format("DD/MM/YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Validator</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.validPerson1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Measure Evidence</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.measureEvidence1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">commitQuality</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.commitQuality1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Type</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.krType1}
                    </Typography>
                  </Grid>
                </Grid>
                <hr className="hrTag" />
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6">Moon Shot</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item justifyContent="center">
                    <div style={{ textAlign: "center" }}>
                      <i
                        class="fa-solid fa-space-station-moon"
                        className={
                          ratingValue <
                          (moonShotHover.hover1 || moonShotRating.rating1)
                            ? "moonShot_Selected"
                            : "moonShot"
                        }
                        onMouseEnter={() => {
                          setMoonShotHover({
                            hover1: ratingValue + 1,
                          });
                        }}
                        onMouseLeave={() => {
                          setMoonShotHover({
                            hover1: 0,
                          });
                        }}
                        onClick={() => {
                          setMoonShotRating({
                            ...moonShotRating,
                            rating1:
                              moonShotRating.rating1 == 0 ? ratingValue + 1 : 0,
                          });
                        }}
                      ></i>
                      <p style={{ textAlign: "center" }}>Moon Shot Stamp</p>
                    </div>
                  </Grid>
                </Grid>
                <hr className="hrTag" />
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6">Approval</Typography>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center">
                  <Button
                    variant="outlined"
                    className={
                      values.kR1Status == "Approved"
                        ? "approved_contained"
                        : "approved_outlined"
                    }
                    startIcon={<CheckCircleIcon />}
                    onClick={() => {
                      setValues({
                        ...values,
                        kR1Status: "Approved",
                      });
                    }}
                  >
                    Approved
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      values.kR1Status == "Not Approved"
                        ? "notApproved_contained"
                        : "notApproved_outlined"
                    }
                    startIcon={<CancelIcon />}
                    onClick={() => {
                      setValues({
                        ...values,
                        kR1Status: "Not Approved",
                      });
                    }}
                  >
                    Not Approved
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography variant="h6" className="keyResult">
                  Key Result 2
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      Key Result 2
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.keyResult2}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Description</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.description2}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Tactics</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.tactics2}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Date Start - End</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {dayjs(values.dateStart2).format("DD/MM/YYYY")}
                      <span className={classes.dateRange}>-</span>
                      {dayjs(values.dateEnd2).format("DD/MM/YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Validator</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.validPerson2}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Measure Evidence</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.measureEvidence2}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">commitQuality</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.commitQuality2}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Type</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.krType2}
                    </Typography>
                  </Grid>
                </Grid>
                <hr className="hrTag" />
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6">Moon Shot</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item>
                    <div style={{ textAlign: "center" }}>
                      <i
                        className={
                          ratingValue <
                          (moonShotHover.hover2 || moonShotRating.rating2)
                            ? "moonShot_Selected"
                            : "moonShot"
                        }
                        onMouseEnter={() => {
                          setMoonShotHover({
                            hover2: ratingValue + 1,
                          });
                        }}
                        onMouseLeave={() => {
                          setMoonShotHover({
                            hover2: 0,
                          });
                        }}
                        onClick={() => {
                          setMoonShotRating({
                            ...moonShotRating,
                            rating2:
                              moonShotRating.rating2 == 0 ? ratingValue + 1 : 0,
                          });
                        }}
                        class="fa-solid fa-space-station-moon"
                      ></i>

                      <p style={{ textAlign: "center" }}>Moon Shot Stamp</p>
                    </div>
                  </Grid>
                </Grid>
                <hr className="hrTag" />
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6">Approval</Typography>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center">
                  <Button
                    variant="outlined"
                    className={
                      values.kR2Status == "Approved"
                        ? "approved_contained"
                        : "approved_outlined"
                    }
                    startIcon={<CheckCircleIcon />}
                    onClick={() => {
                      setValues({
                        ...values,
                        kR2Status: "Approved",
                      });
                    }}
                  >
                    Approved
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      values.kR2Status == "Not Approved"
                        ? "notApproved_contained"
                        : "notApproved_outlined"
                    }
                    startIcon={<CancelIcon />}
                    onClick={() => {
                      setValues({
                        ...values,
                        kR2Status: "Not Approved",
                      });
                    }}
                  >
                    Not Approved
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography variant="h6" className="keyResult">
                  Key Result 3
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      Key Result 3
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.keyResult3}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Description</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.description3}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Tactics</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.tactics3}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Date Start - End</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {dayjs(values.dateStart3).format("DD/MM/YYYY")}
                      <span className={classes.dateRange}>-</span>
                      {dayjs(values.dateEnd3).format("DD/MM/YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Validator</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.validPerson3}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Measure Evidence</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.measureEvidence3}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">commitQuality</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.commitQuality3}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6">Type</Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.krType3}
                    </Typography>
                  </Grid>
                </Grid>
                <hr className="hrTag" />
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6">Moon Shot</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item>
                    <div style={{ textAlign: "center" }}>
                      <i
                        className={
                          ratingValue <
                          (moonShotHover.hover3 || moonShotRating.rating3)
                            ? "moonShot_Selected"
                            : "moonShot"
                        }
                        onMouseEnter={() => {
                          setMoonShotHover({
                            hover3: ratingValue + 1,
                          });
                        }}
                        onMouseLeave={() => {
                          setMoonShotHover({
                            hover3: 0,
                          });
                        }}
                        onClick={() => {
                          setMoonShotRating({
                            ...moonShotRating,
                            rating3:
                              moonShotRating.rating3 == 0 ? ratingValue + 1 : 0,
                          });
                        }}
                        class="fa-solid fa-space-station-moon"
                      ></i>

                      <p style={{ textAlign: "center" }}>Moon Shot Stamp</p>
                    </div>
                  </Grid>
                </Grid>
                <hr className="hrTag" />
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6">Approval</Typography>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center">
                  <Button
                    variant="outlined"
                    className={
                      values.kR3Status == "Approved"
                        ? "approved_contained"
                        : "approved_outlined"
                    }
                    startIcon={<CheckCircleIcon />}
                    onClick={() => {
                      setValues({
                        ...values,
                        kR3Status: "Approved",
                      });
                    }}
                  >
                    Approved
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      values.kR3Status == "Not Approved"
                        ? "notApproved_contained"
                        : "notApproved_outlined"
                    }
                    startIcon={<CancelIcon />}
                    onClick={() => {
                      setValues({
                        ...values,
                        kR3Status: "Not Approved",
                      });
                    }}
                  >
                    Not Approved
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Box sx={{ marginTop: 4, marginRight: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="saveButton"
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </ContainerStyled>
  );
}

export default IndividualPlanEvaluation;
