import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio,
  Switch,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditNoteIcon from "@mui/icons-material/EditNote";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import DialogAddQuestion from "./DialogAddQuestion";

import { useDispatch, useSelector } from "react-redux";
import { getCompanyProfile } from "../../../../../actions/company";
import DialogEdit from "./DialogEdit";
import QuestionSet from "./QuestionSet";
import QuestionSetForm from "./QuestionSetForm";
import EditQuestionSet from "./EditQuestionSet";
import { updateCompanyProbation } from "../../../../../actions/probation";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const BoxStyled = styled("div")({
  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .text": {
      fontSize: 16,
    },
    "& .icon": {
      color: "#637381",
      fontSize: 16,
      marginRight: 8,
    },
  },
  "& .flex-box": {
    justifyContent: "space-between",
    marginTop: 16,
    display: "flex",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginRight: 8,
});

function Probation(props) {
  const { selectedCompany, isReadOnly } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );
  const [state, setstate] = useState({
    probationRoundOne: null,
    probationRoundTwo: null,
  });
  const [openForm, setOpenForm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [checkedValue, setCheckedValue] = React.useState(null);
  const [openQuestion, setOpenQuestion] = useState(false);
  const [selectQuestionSet, setSelectQuestionSet] = useState(null);

  const handleClose = () => {
    setOpenForm(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectQuestionSet(null);
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getCompanyProfile({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (companyProfile) {
      setstate({
        probationRoundOne: companyProfile.probationRoundOne,
        probationRoundTwo: companyProfile.probationRoundTwo,
      });
      setCheckedValue({
        RoundOne: parseInt(companyProfile.probationRoundOne) > 0,
        RoundTwo: parseInt(companyProfile.probationRoundTwo) > 0,
      });
    }
  }, [companyProfile]);

  const handleOpenQuestion = () => {
    setOpenQuestion(true);
  };

  const handleCloseQuestion = () => {
    setOpenQuestion(false);
  };

  const handleClickSave = async () => {
    const formData = {
      probationRoundOne: checkedValue.RoundOne ? state.probationRoundOne : null,
      probationRoundTwo: checkedValue.RoundTwo ? state.probationRoundTwo : null,
    };
    const res = await dispatch(
      updateCompanyProbation({
        ...formData,
        idCompany: selectedCompany.idCompany,
      })
    );
    if (res && res.status === 200) {
      dispatch(getCompanyProfile());
      dispatch(openNotificationAlert({ type: "success", message: t("Success") }));
    } else {
      dispatch(openNotificationAlert({ type: "error", message: t("AnErrorOccurred") }));
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckedValue({ ...checkedValue, [name]: checked });

    if (checked === false) {
      setstate({
        ...state,
        [`probation${name}`]: null,
      });

      if (name === "RoundOne") {
        setCheckedValue({
          RoundOne: companyProfile.probationRoundOne > 0,
          RoundTwo: companyProfile.probationRoundTwo > 0,
        });
        setstate({
          probationRoundOne: null,
          probationRoundTwo: null,
        });
      }
    }
  };

  const handleChangeSelectQuestionSet = (value) => {
    setOpenEdit(true);
    setSelectQuestionSet(value)
  }

  return (
    <div className={classes.root}>
      {!openForm && !openEdit && (
        <>
          <Stack spacing={4}>
            <div>
              <StyledHeadingPage>
                <Typography variant="h6">{t("Probation")}</Typography>
              </StyledHeadingPage>
              <CardStyle style={{ padding: 16 }}>
                {checkedValue && companyProfile && (
                  <BoxStyled>
                    <div className="header">
                      <KeyboardDoubleArrowRightRoundedIcon className="icon" />
                      <StyledContentLabel variant="body1">
                        {t("phase")} 1
                      </StyledContentLabel>
                      <div>
                        <Switch
                          checked={checkedValue.RoundOne}
                          name="RoundOne"
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                        />
                      </div>
                      <Typography
                        style={{ marginLeft: 4 }}
                        variant="body2"
                        color="text.secondary"
                      >
                        {`(${
                          checkedValue.RoundOne ? `${t("Active")}` : `${t("Inactive")}`
                        })`}
                      </Typography>
                    </div>

                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="row-probation-round-one"
                          name="probationRoundOne"
                          value={state.probationRoundOne}
                          onChange={(e) => {
                            setstate({
                              ...state,
                              probationRoundOne: e.target.value,
                            });
                            if (e.target.value === "180") {
                            }
                          }}
                        >
                          <FormControlLabel
                            value="30"
                            control={<Radio />}
                            label={`30 ${t("Unit.Days")}`}
                            disabled={!checkedValue.RoundOne}
                          />
                          <FormControlLabel
                            value="60"
                            control={<Radio />}
                            label={`60 ${t("Unit.Days")}`}
                            disabled={!checkedValue.RoundOne}
                          />
                          <FormControlLabel
                            value="90"
                            control={<Radio />}
                            label={`90 ${t("Unit.Days")}`}
                            disabled={!checkedValue.RoundOne}
                          />
                          <FormControlLabel
                            value="119"
                            control={<Radio />}
                            label={`119 ${t("Unit.Days")}`}
                            disabled={!checkedValue.RoundOne}
                          />
                          <FormControlLabel
                            value="180"
                            control={<Radio />}
                            label={`180 ${t("Unit.Days")}`}
                            disabled={!checkedValue.RoundOne}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="header">
                      <KeyboardDoubleArrowRightRoundedIcon className="icon" />
                      <StyledContentLabel variant="body1">
                        {t("phase")} 2
                      </StyledContentLabel>
                      <div>
                        <Switch
                          checked={checkedValue.RoundTwo}
                          name="RoundTwo"
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                          disabled={
                            !(state.probationRoundOne > 0) ||
                            parseInt(state.probationRoundOne) >= 180
                          }
                        />
                      </div>
                      <Typography
                        style={{ marginLeft: 4 }}
                        variant="body2"
                        color="text.secondary"
                      >
                        {`(${
                          checkedValue.RoundTwo ? `${t("Active")}` : `${t("Inactive")}`
                        })`}
                      </Typography>
                    </div>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="row-probation-round-one"
                          name="probationRoundTwo"
                          value={state.probationRoundTwo}
                          onChange={(e) =>
                            setstate({
                              ...state,
                              probationRoundTwo: e.target.value,
                            })
                          }
                        >
                          <FormControlLabel
                            value="30"
                            control={<Radio />}
                            label={`30 ${t("Unit.Days")}`}
                            disabled={
                              checkedValue.RoundTwo !== true ||
                              checkedValue.RoundOne !== true ||
                              parseInt(state.probationRoundOne) >= 30
                            }
                          />
                          <FormControlLabel
                            value="60"
                            control={<Radio />}
                            label={`60 ${t("Unit.Days")}`}
                            disabled={
                              checkedValue.RoundTwo !== true ||
                              checkedValue.RoundOne !== true ||
                              parseInt(state.probationRoundOne) >= 60
                            }
                          />
                          <FormControlLabel
                            value="90"
                            control={<Radio />}
                            label={`90 ${t("Unit.Days")}`}
                            disabled={
                              checkedValue.RoundTwo !== true ||
                              checkedValue.RoundOne !== true ||
                              parseInt(state.probationRoundOne) >= 90
                            }
                          />
                          <FormControlLabel
                            value="119"
                            control={<Radio />}
                            label={`119 ${t("Unit.Days")}`}
                            disabled={
                              checkedValue.RoundTwo !== true ||
                              checkedValue.RoundOne !== true ||
                              parseInt(state.probationRoundOne) >= 119
                            }
                          />
                          <FormControlLabel
                            value="180"
                            control={<Radio />}
                            label={`180 ${t("Unit.Days")}`}
                            disabled={
                              checkedValue.RoundTwo !== true ||
                              checkedValue.RoundOne !== true ||
                              parseInt(state.probationRoundOne) >= 180
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <ButtonBlue
                        onClick={handleClickSave}
                        variant="contained"
                        startIcon={<SaveRoundedIcon />}
                        disabled={isReadOnly}
                      >
                        {t("Save")}
                      </ButtonBlue>
                    </div>
                  </BoxStyled>
                )}
              </CardStyle>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                <Typography fontSize="24px" fontWeight="500">{t("questionSet")}</Typography>
                {!isReadOnly && (
                  <ButtonBlue 
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenForm(true)}
                  >
                    {t("addQuestionSet")}
                  </ButtonBlue>
                )}
              </div>
              <QuestionSet
                idCompany={selectedCompany.idCompany}
                handleChangeSelectQuestionSet={handleChangeSelectQuestionSet}
              />
            </div>
          </Stack>
        </>
      )}
      {openForm && (
        <QuestionSetForm
          idCompany={selectedCompany.idCompany}
          handleClose={handleClose}
        />
      )}
      {openEdit && (
        <EditQuestionSet
          idCompany={selectedCompany.idCompany}
          handleClose={handleCloseEdit}
          selectQuestionSet={selectQuestionSet}
        />
      )}


      {/* {probationQuestion && (
        <>
          <StyledHeadingPage sx={{ marginTop: "20px" }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t("ProbationReviewQuestion")}</Typography>
              </Grid>
              <Grid item>
                <ButtonBlue
                  variant="contained"
                  onClick={handleOpenQuestion}
                  disabled={probationQuestion.length >= 20}
                >
                  {t("AddQuestion")}
                  <AddCircleRoundedIcon />
                </ButtonBlue>
              </Grid>
            </Grid>
          </StyledHeadingPage>
          <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
            <BoxStyled>
              <div className="flex-box">
                <StyledContentLabel variant="body1">
                  {t("ActiveQuestion")}
                  <i
                    className="fa-sharp fa-solid fa-check fa-xl"
                    style={{ color: "#fde64b" }}
                  ></i>
                </StyledContentLabel>
                <ButtonBlue
                  variant="outlined"
                  onClick={handleOpenEdit}
                  startIcon={<EditNoteIcon />}
                  disabled={probationQuestion.length === 0}
                >
                  {t("Edit")}
                  &nbsp;
                </ButtonBlue>
              </div>
              {probationQuestion
                .filter((item) => item.isActive)
                .map((probation, index) => (
                  <Stack
                    key={probation.idProbationQuestion}
                    spacing={1}
                    marginTop={1}
                  >
                    <Chip
                      sx={{ maxWidth: "fit-content" }}
                      icon={<CheckCircleIcon />}
                      color="success"
                      label={`${t("Question")} ${index + 1}`}
                    />
                    <Typography>{probation.question}</Typography>
                    <Divider sx={{ borderColor: "#9e9e9e66" }} />
                  </Stack>
                ))}
            </BoxStyled>
          </CardStyle>
          <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
            <BoxStyled>
              <div className="flex-box">
                <StyledContentLabel variant="body1">
                  {`${t("CanceledQuestions")}`}
                  &nbsp;&nbsp;
                  <i
                    className="fa-sharp fa-solid fa-xmark fa-xl"
                    style={{ color: "#ff0000" }}
                  ></i>
                </StyledContentLabel>
              </div>
              {probationQuestion
                .filter((item) => !item.isActive)
                .map((probation) => (
                  <Stack
                    key={probation.idProbationQuestion}
                    spacing={1}
                    marginTop={1}
                  >
                    <Typography>{probation.question}</Typography>
                    <Divider sx={{ borderColor: "#9e9e9e66" }} />
                  </Stack>
                ))}
            </BoxStyled>
          </CardStyle>
        </>
      )} */}
      {openQuestion && (
        <DialogAddQuestion
          open={openQuestion}
          handleClose={handleCloseQuestion}
          selectedCompany={selectedCompany}
          isReadOnly={isReadOnly}
        />
      )}      
      {/* {probationQuestion && openEdit && (
        <DialogEdit
          open={openEdit}
          handleClose={handleCloseEdit}
          selectedCompany={selectedCompany}
          isReadOnly={isReadOnly}
        />
      )} */}
    </div>
  );
}

export default Probation;
