import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const kt20kXlsxFile = async (selectedYear, data, contributionRate) => {
    // console.log(data);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const monthData = {
        Jan: `${selectedYear}-01`,
        Feb: `${selectedYear}-02`,
        Mar: `${selectedYear}-03`,
        Apr: `${selectedYear}-04`,
        May: `${selectedYear}-05`,
        Jun: `${selectedYear}-06`,
        Jul: `${selectedYear}-07`,
        Aug: `${selectedYear}-08`,
        Sep: `${selectedYear}-09`,
        Oct: `${selectedYear}-10`,
        Nov: `${selectedYear}-11`,
        Dec: `${selectedYear}-12`,
    };

    const headerRow = worksheet.addRow(['', 'โปรดกรอกเอกสารฉบับนี้และส่งคืนสำนักงานพร้อมแบบ  กท. 20 ก']);
    const headerRow2 = worksheet.addRow(['', `แบบคำนวณค่าจ้างเพื่อประกอบการรายงานค่าจ้างตามแบบ  กท.  20  ก  ประจำปี   ${selectedYear}`]);
    const headerRow3 = worksheet.addRow([`สำนักงานประกันสังคมจังหวัด......${data ? data.employeeDetails[0].province : "........."}......`, '', '', '', '', 'โทร.']);
    const headerRow4 = worksheet.addRow([`ชื่อสถานประกอบการ...........${data ? data.employeeDetails[0].companyName : "........."}...............`, '', '', '', '', 'เลขที่บััญชี', `${data.employeeDetails[0].socialSecurityAccount ? data.employeeDetails[0].socialSecurityAccount : ''}`]);
    const headerRow5 = worksheet.addRow([`ก. รหัสกิจการ ${data.employeeDetails[0].companyCode}`, '', 'อัตราเงิินสมทบ', `${contributionRate}`, '', 'โทร', `${data.employeeDetails[0].mainContactPhone ? data.employeeDetails[0].mainContactPhone : ''}`]);

    worksheet.getColumn('B').width = 15;
    worksheet.getColumn('C').width = 15;
    worksheet.getColumn('D').width = 15;
    worksheet.getColumn('E').width = 15;
    worksheet.getColumn('F').width = 15;
    worksheet.getColumn('G').width = 20;
    worksheet.getColumn('H').width = 15;

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', underline: true },
        alignment: { horizontal: "center", vertical: 'middle' },
    };

    headerRow.height = 30;
    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
    });

    headerRow2.font = { bold: true, size: 14, name: 'TH SarabunPSK' };
    headerRow3.font = { size: 14, name: 'TH SarabunPSK' };
    headerRow4.font = { size: 14, name: 'TH SarabunPSK' };
    headerRow5.font = { size: 14, name: 'TH SarabunPSK' };

    worksheet.mergeCells(`B1:G1`);
    worksheet.mergeCells('B2:G2');
    worksheet.mergeCells('A3:E3');
    worksheet.mergeCells('A4:E4');
    worksheet.mergeCells('A5:B5');

    const columnName1 = worksheet.addRow(['เดือน', 'จำนวนลูกจ้าง', 'ข. ประเภทของค่าจ้างตามกฎหมาย (รวมทุกสาขา)', '', '', '', '2', '1 - 2 = 3']);
    const columnName2 = worksheet.addRow(['', '', 'เงินเดือน', 'ค่าจ้างรายวัน', ' ค่า ..................', '1 รวมค่าจ้าง', 'ส่วนที่เกิน\n20,000/คน/เดือน', 'ค่าจ้างสุทธิที่ต้องแจ้ง']);
    const columnName3 = worksheet.addRow(['', '', '** ไม่รวมเงินที่ไม่ใช่ค่าจ้าง เช่น ค่าล่วงเวลา โบนัส ฯลฯ**', '', '', '', '(รวมของทุกคน)', '']);

    worksheet.mergeCells('C6:F6');
    worksheet.mergeCells('A6:A8');
    worksheet.mergeCells('B6:B8');

    const columnStyle = {
        font: { size: 14, name: 'TH SarabunPSK' },
        alignment: { horizontal: "center", vertical: 'middle' },
    };

    columnName1.eachCell((cell) => {
        cell.style = columnStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    worksheet.mergeCells('C8:E8');

    columnName2.height = 30;
    columnName2.eachCell((cell) => {
        cell.style = columnStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    columnName3.eachCell((cell) => {
        cell.style = columnStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const createMonthData = (monthAbbreviation, data, monthDate) => {
        const item = data.employeeDetails.find(item => dayjs(item.monthPeriod).format("YYYY-MM") === monthDate);

        if (!item) {
            return worksheet.addRow([monthAbbreviation, '', '0.00', '0.00', '0.00', '0.00', '0.00', '0.00']);
        }

        const wage = parseFloat(item.sum_salary + item.totalAdditions);
        const netWages = wage - parseFloat(item.sum_salary2);

        return worksheet.addRow([
            monthAbbreviation,
            item.num_employees ? item.num_employees : '',
            parseFloat(item.sum_salary).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '0.00',
            '0.00',
            parseFloat(item.totalAdditions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '0.00',
            parseFloat(wage).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '0.00',
            parseFloat(item.sum_salary2).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '0.00',
            parseFloat(netWages).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '0.00',
        ]);
    };

    const JanData = createMonthData('ม.ค.', data, monthData.Jan);
    const FebData = createMonthData('ก.พ.', data, monthData.Feb);
    const MarData = createMonthData('มี.ค.', data, monthData.Mar);
    const AprData = createMonthData('เม.ย.', data, monthData.Apr);
    const MayData = createMonthData('พ.ค.', data, monthData.May);
    const JunData = createMonthData('มิ.ย.', data, monthData.Jun);
    const JulData = createMonthData('ก.ค.', data, monthData.Jul);
    const AugData = createMonthData('ส.ค.', data, monthData.Aug);
    const SepData = createMonthData('ก.ย.', data, monthData.Sep);
    const OctData = createMonthData('ต.ค.', data, monthData.Oct);
    const NovData = createMonthData('พ.ย.', data, monthData.Nov);
    const DecData = createMonthData('ธ.ค.', data, monthData.Dec);

    // total row
    const sum_salary = data.employeeDetails.reduce((acc, cur) => acc + cur.sum_salary, 0);
    const sum_salary2 = data.employeeDetails.reduce((acc, cur) => acc + cur.sum_salary2, 0);
    const sum_additions = data.employeeDetails.reduce((acc, cur) => acc + cur.totalAdditions, 0);
    const totalWage = sum_salary + sum_additions;
    const netWages = sum_salary + sum_additions - sum_salary2;

    const totalRow = worksheet.addRow([
        'รวม',
        '',
        `${sum_salary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        '0.00',
        `${sum_additions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        `${totalWage.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        `${sum_salary2.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        `${netWages.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    ]);
    worksheet.mergeCells('A21:B21');
    // totalRow.getCell(3).value = { formula: 'SUM(C9:C20)' };

    totalRow.eachCell((cell) => {
        cell.style = columnStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const applyCellStyle = (cell) => {
        cell.style = columnStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
        };
    };

    const monthsData = [
        { data: JanData },
        { data: FebData },
        { data: MarData },
        { data: AprData },
        { data: MayData },
        { data: JunData },
        { data: JulData },
        { data: AugData },
        { data: SepData },
        { data: OctData },
        { data: NovData },
        { data: DecData },
    ];

    monthsData.forEach(month => {
        month.data.eachCell(cell => {
            applyCellStyle(cell);
        });
    });

    const content = worksheet.addRow([`ง ค่าจ้างรายเดือนของลูกจ้างที่ได้รับต่ำสุด  เดือนละ  บาท ค่าจ้างรายวันของลูกจ้างที่ได้รับต่ำสุดวันละ  บาท`])
    worksheet.mergeCells('A22:H22');

    const contentStyle = {
        font: { size: 14, name: 'TH SarabunPSK', bold: true },
        alignment: { horizontal: "center", vertical: 'middle' },
    };

    const contentStyle2 = {
        font: { size: 14, name: 'TH SarabunPSK' },
    };

    const contentStyle3 = {
        font: { size: 14, name: 'TH SarabunPSK' },
        alignment: { horizontal: "center", vertical: 'middle' },
    };

    content.eachCell((cell) => {
        cell.style = contentStyle;
    });

    const contentRows = [
        [`จ.  รายการเงินได้ตามแบบยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย ภงด. 1 ก`],
        [
            'จำนวน',
            `${data && data.taxPayment ? data.taxPayment[0].num_employees : '-'} ราย`,
            'เงินได้ทั้งสิ้น',
            `${data && data.taxPayment ? data.taxPayment[0].sum_totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}`,
            'บาท',
            'ลงชื่อ',
            '',
            'นายจ้าง'
        ],
        [
            '',
            'ประกอบด้วย',
            'เงินเดือน',
            `${data && data.taxPayment ? data.taxPayment[0].sum_salary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}`,
            'บาท',
            '                        (',
            '',
            '  )'
        ],
        [
            'ค่าจ้างรายวัน',
            'บาท',
            'ค่าล่วงเวลา',
            ``,
            'บาท',
            `ตำแหน่ง`,
        ],
        [
            'โบนัส',
            `บาท`,
            '',
            '...............',
            `บาท`,
        ],
        [
            '...............',
            `บาท`,
            '',
            '...............',
            `บาท`,
        ],
        [''],
        [''],
    ];

    contentRows.forEach(content => {
        const row = worksheet.addRow(content);
        row.eachCell(cell => {
            cell.style = contentStyle2;
        });
    });

    const belowHeader = worksheet.addRow([
        `ประจำปี    ${selectedYear}   รหัสกิจการ ${data && data.employeeDetails ? data.employeeDetails[0].companyCode : ''} อัตราเงินสมทบ ${contributionRate ? contributionRate : ''}`,
        '',
        '',
        '',
        '',
        '',
        '',
        'สำหรับเจ้าหน้าที่'
    ], { font: { size: 14, name: 'TH SarabunPSK' } });    

    belowHeader.eachCell((cell) => {
        cell.style = contentStyle2;
    });

    const contentRows2 = [
        [
            "ประเภท", "", "ค่าจ้าง", "", "ปรับขั้นต่ำ (เฉพาะลูกจ้าง 1 คน)", "", "ค่าจ้างสุทธิ", "เงินสมทบ"
        ],
        [
            "การประเมินต้นปี", "", "", "", "", "", "", ""
        ],
        [
            "การรายงานค่าจ้าง", "", "", "", "", "", "", ""
        ],
        [
            "สปส 1-10", "", "", "", "", "", "", ""
        ],
        [
            "กองทุนเงินทดแทน  สรุปผลเป็น  เรียกเพิ่ม (Dr.), จ่ายคืน (Cr.)", "", "", "", "", "", "", ""
        ]
    ];

    contentRows2.forEach(content => {
        const row = worksheet.addRow(content);
        row.eachCell(cell => {
            cell.style = contentStyle3;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    worksheet.mergeCells('A31:G31');

    worksheet.mergeCells('A32:B32');
    worksheet.mergeCells('C32:D32');
    worksheet.mergeCells('E32:F32');

    worksheet.mergeCells('A33:B33');
    worksheet.mergeCells('C33:D33');
    worksheet.mergeCells('E33:F33');

    worksheet.mergeCells('A34:B34');
    worksheet.mergeCells('C34:D34');
    worksheet.mergeCells('E34:F34');

    worksheet.mergeCells('A35:B35');
    worksheet.mergeCells('C35:D35');
    worksheet.mergeCells('E35:F35');

    worksheet.mergeCells('A36:G36');


    try {
        const data = await workbook.xlsx.writeBuffer();
        const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `กท20ก.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error:', error);
    }
};
