import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import DataGrid, {
  Column,
  ColumnChooser,
  Position,
  HeaderFilter,
  Export,
  Paging,
  Pager,
  Scrolling,
  Sorting,
  Selection,
  ColumnChooserSearch,
  ColumnChooserSelection,
  FilterRow,
} from "devextreme-react/data-grid";
import CardContent from "@mui/material/CardContent";
import { Card, Typography } from "@mui/material";
import {
  getDataEmployee,
  getDataWelfare,
} from "../../../../actions/expenseExport";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledCard = styled(Card)(({ fullheight }) => ({
  //marginTop: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  height: "fit-content",
  ...(fullheight === "true" && {
    height: "100%",
  }),
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
}));
const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#212b36",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
    {
      backgroundColor: "#ef5350",
      color: "#FFFFFF",
      fontWeight: "500",
      "& .dx-header-filter": {
        color: "#FFFFFF",
      },
    },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const rowIndex = (cellInfo) => {
  return cellInfo.rowIndex + 1;
};

const TableExpense = (props) => {
  const { selectedCompany } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: expenseExport } = useSelector((state) => state.expenseExport);
  const { result: expenseExportWelfare } = useSelector(
    (state) => state.expenseExportWelfare
  );
  const [listExpense, setListExpense] = React.useState("");

  const list1 = t("AllExpenses");
  const list2 = t("ReimbursementWithTax");
  const list3 = t("ReimbursementWithOutTax");
  const list4 = t("Deductions");
  const list5 = t("Welfares");
  // const list6 = "Dump ตัด Flex Point";
  useEffect(() => {
    if (selectedCompany) {
      if (listExpense === "list5") {
        dispatch(getDataWelfare({ idCompany: selectedCompany.idCompany }));
      } else {
        dispatch(getDataEmployee({ idCompany: selectedCompany.idCompany }));
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getDataEmployee({ idCompany: selectedCompany.idCompany }));
    }
  }, []);

  if (expenseExport && Array.isArray(expenseExport)) {
    expenseExport.forEach((element) => {
      element["fullname"] = getUserFullName(element);
    });
  }

  const [showpersonalID, setshowpersonalID] = useState(true);
  const [showcompanyName, setshowcompanyName] = useState(true);
  const [showsectionName, setshowsectionName] = useState(true);
  const [showpositionName, setshowpositionName] = useState(true);
  const [showidExpense, setshowidExpense] = useState(true);
  const [showexpenseName, setshowexpenseName] = useState(true);
  const [showname, setshowname] = useState(true);
  const [showcreateDate, setshowcreateDate] = useState(true);
  const [showdate, setshowdate] = useState(true);
  const [showAmountToEmployee, setshowAmountToEmployee] = useState(true);
  const [ShowDifference, setShowDifference] = useState(true);
  const [showDeductFromEmployees, setshowDeductFromEmployees] = useState(true);
  const [showsumSurplus, setshowsumSurplus] = useState(true);
  const [showFlexPoint, setShowFlexPoint] = useState(true);
  const [showSumTax, setShowSumTax] = useState(true);
  const [showpaymentType, setshowpaymentType] = useState(true);
  const [showreportBankName, setshowreportBankName] = useState(true);
  const [showreportBankBankName, setshowreportBankBankName] = useState(true);
  const [showreportBankBookBankID, setshowreportBankBookBankID] =
    useState(true);
  const [showreportBankBankID, setshowreportBankBankID] = useState(true);

  const handleChange = (event) => {
    setListExpense(event.target.value);
    // console.log(event.target.value );
    switch (event.target.value) {
      case "list1":
        // Show columns for list1
        setShowFlexPoint(true);
        setShowSumTax(true);
        setshowDeductFromEmployees(true);
        setshowAmountToEmployee(true);
        setshowreportBankBookBankID(true);
        setshowreportBankBankName(true);
        setshowreportBankBankID(true);
        setshowreportBankName(true);
        setshowpaymentType(true);
        setshowsumSurplus(true);
        setShowDifference(true);
        setshowdate(true);
        setshowcreateDate(true);
        setshowname(true);
        setshowexpenseName(true);
        setshowidExpense(true);
        setshowpositionName(true);
        setshowsectionName(true);
        setshowcompanyName(true);
        break;
      case "list2":
        // Show columns for list2
        setShowDifference(false);
        setShowFlexPoint(false);
        setShowSumTax(false);
        setshowDeductFromEmployees(true);
        setshowAmountToEmployee(true);
        setshowreportBankBookBankID(true);
        setshowreportBankBankName(true);
        setshowreportBankBankID(true);
        setshowreportBankName(true);
        setshowpaymentType(true);
        setshowsumSurplus(true);
        setshowdate(true);
        setshowcreateDate(true);
        setshowname(true);
        setshowexpenseName(true);
        setshowidExpense(true);
        setshowpositionName(true);
        setshowsectionName(true);
        setshowcompanyName(true);
        break;
      case "list3":
        // Show columns for list3
        setshowDeductFromEmployees(false);
        setShowDifference(false);
        setShowFlexPoint(false);
        setShowSumTax(false);
        setshowAmountToEmployee(true);
        setshowreportBankBookBankID(true);
        setshowreportBankBankName(true);
        setshowreportBankBankID(true);
        setshowreportBankName(true);
        setshowpaymentType(true);
        setshowsumSurplus(true);
        setshowdate(true);
        setshowcreateDate(true);
        setshowname(true);
        setshowexpenseName(true);
        setshowidExpense(true);
        setshowpositionName(true);
        setshowsectionName(true);
        setshowcompanyName(true);
        break;
      case "list4":
        // Show columns for list4.
        setshowAmountToEmployee(false);
        setshowDeductFromEmployees(false);
        setShowFlexPoint(false);
        setShowSumTax(false);
        setshowreportBankBookBankID(true);
        setshowreportBankBankName(true);
        setshowreportBankBankID(true);
        setshowreportBankName(true);
        setshowpaymentType(true);
        setshowsumSurplus(true);
        setShowDifference(true);
        setshowdate(true);
        setshowcreateDate(true);
        setshowname(true);
        setshowexpenseName(true);
        setshowidExpense(true);
        setshowpositionName(true);
        setshowsectionName(true);
        setshowcompanyName(true);
        break;
      case "list5":
        // Show columns for list5
        dispatch(getDataWelfare({ idCompany: selectedCompany.idCompany }));

        break;
      // case "list6":
      //   setshowreportBankBookBankID(false);
      //   setshowreportBankBankName(false);
      //   setshowreportBankBankID(false);
      //   setshowreportBankBookBankID(false);
      //   setshowreportBankName(false);
      //   setshowpaymentType(false);
      //   setshowsumSurplus(false);
      //   setshowDeductFromEmployees(false);
      //   setShowDifference(false);
      //   setshowAmountToEmployee(false);
      //   setshowdate(false);
      //   setshowcreateDate(false);
      //   setshowdate(false);
      //   setshowname(false);
      //   setshowexpenseName(false);
      //   setshowidExpense(false);
      //   setshowpositionName(false);
      //   setshowsectionName(false);
      //   setshowcompanyName(false);
      //   setshowDeductFromEmployees(true);
      //   setShowDifference(true);
      //   setShowFlexPoint(true);
      //   setShowSumTax(true);
      //   setshowAmountToEmployee(true);
      //   break;
      default:
        // Default visibility
        setshowreportBankBankName(true);
        setshowreportBankBankID(true);
        setshowreportBankBookBankID(true);
        setshowreportBankName(true);
        setshowpaymentType(true);
        setshowsumSurplus(true);
        setshowDeductFromEmployees(true);
        setShowDifference(true);
        setshowdate(true);
        setshowcreateDate(true);
        setshowname(true);
        setshowexpenseName(true);
        setshowidExpense(true);
        setshowpositionName(true);
        setshowsectionName(true);
        setshowcompanyName(true);
        setShowFlexPoint(true);
        setShowSumTax(true);
        setshowAmountToEmployee(true);
        break;
    }
  };

  return (
    <StyledCard>
      <StyledCardContent>
        <StyledWrapDataGrid>
          <Box
            sx={{
              minWidth: 120,
              marginBottom: "16px",
            }}
          >
            <FormControl fullWidth>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("ExpenseExportOptions")}
              </StyledHeadLabel>
              <TextFieldTheme
                select
                value={listExpense}
                onChange={handleChange}
              >
                <MenuItem value={"list1"}>{list1}</MenuItem>
                <MenuItem value={"list2"}>{list2}</MenuItem>
                <MenuItem value={"list3"}>{list3}</MenuItem>
                <MenuItem value={"list4"}>{list4}</MenuItem>
                <MenuItem value={"list5"}>{list5}</MenuItem>
                {/* <MenuItem value={"list6"}>{list6}</MenuItem> */}
              </TextFieldTheme>
            </FormControl>
          </Box>
          <DataGrid
            dataSource={
              listExpense === "list5" ? expenseExportWelfare : expenseExport
            }
            showBorders={false}
            showColumnLines={false}
            rowAlternationEnabled={true}
            //onSelectionChanged={onSelectionChanged}
            columnAutoWidth={true}
          >
            <ColumnChooser enabled={true} mode={"select"}>
              <Position
                my="right top"
                at="right bottom"
                of=".dx-datagrid-column-chooser-button"
              />

              <ColumnChooserSelection
                allowSelectAll={true}
                selectByClick={true}
              />
            </ColumnChooser>
            <Column
              caption="No."
              width={40}
              cellRender={rowIndex}
              alignment="center"
              cssClass="column-info"
            />

            <Column
              caption={t("EmployeeID")}
              dataField="employeeID"
              dataType="string"
              width={120}
              cssClass="column-info"
            />

            <Column
              caption={t("FullName")}
              dataType="string"
              cssClass="column-info"
              dataField="fullname"
            />

            <Column
              dataField="personalID"
              caption={t("personalID")}
              cssClass="column-info"
              alignment="center"
              visible={showpersonalID}
            />

            <Column
              dataField={
                i18n.resolvedLanguage === "th"
                  ? "companyName"
                  : "companyName_EN"
              }
              caption={t("Company")}
              dataType="string"
              cssClass="column-info"
              visible={showcompanyName}
            />

            <Column
              dataField={
                i18n.resolvedLanguage === "th"
                  ? "sectionName"
                  : "sectionName_EN"
              }
              caption={t("Section")}
              dataType="string"
              cssClass="column-info"
              visible={showsectionName}
            />

            <Column
              dataField={
                i18n.resolvedLanguage === "th"
                  ? "positionName"
                  : "positionName_EN"
              }
              caption={t("Position")}
              dataType="string"
              cssClass="column-info"
              visible={showpositionName}
            />

            <Column
              dataField="documentId"
              caption={t("DocumentNumber")}
              dataType="string"
              cssClass="column-info"
              visible={showidExpense}
            />

            <Column
              dataField="expenseName"
              caption={t("ExpenseTitle")}
              dataType="string"
              cssClass="column-info"
              visible={showexpenseName}
            />

            <Column
              dataField="name"
              caption={t("Type")}
              dataType="string"
              cssClass="column-info"
              visible={showname}
            />

            <Column
              dataField="createDate"
              caption={t("TransactionDate")}
              dataType="date"
              format="yyyy-MM-dd"
              cssClass="column-info"
              visible={showcreateDate}
            />

            <Column
              dataField="date"
              caption={t("CompletedDate")}
              dataType="date"
              format="yyyy-MM-dd"
              cssClass="column-info"
              visible={showdate}
            />

            <Column
              dataField="AmountToEmployee"
              caption={t("ReimbursementAmount")}
              format="###,###,##0.00"
              cssClass="column-salary"
              minWidth={100}
              visible={showAmountToEmployee}
            />

            <Column
              dataField="ShowDifference"
              caption={t("DeductionAmount")}
              format="###,###,##0.00"
              cssClass="column-total-deduction"
              minWidth={100}
              visible={ShowDifference}
            />

            <Column
              dataField="DeductFromEmployees"
              caption={t("TaxAmount")}
              format="###,###,##0.00"
              cssClass="column-shift"
              minWidth={100}
              visible={showDeductFromEmployees}
            />

            <Column
              dataField="flexCoins"
              caption={t("FlexPointDeductionAmount")}
              format="###,###,##0.00"
              cssClass="column-accumulate"
              minWidth={100}
              visible={showFlexPoint}
            />

            <Column
              dataField="sumTax"
              caption={"Vat "}
              // format="###,###,##0.00"
              cssClass="column-shift"
              alignment="center"
              minWidth={100}
              visible={showSumTax}
            />

            <Column
              dataField="paymentType"
              caption={t("ReimbursementType")}
              cssClass="column-accumulate"
              minWidth={100}
              visible={showpaymentType}
            />

            <Column
              dataField="reportBankName"
              caption={t("AccountName")}
              dataType="string"
              cssClass="column-info"
              visible={showreportBankName}
            />

            <Column
              dataField="reportBankBankName"
              caption={t("BankName")}
              dataType="string"
              cssClass="column-info"
              visible={showreportBankBankName}
            />

            <Column
              dataField="reportBankBookBankID"
              caption={t("BookID")}
              dataType="string"
              cssClass="column-info"
              visible={showreportBankBookBankID}
            />

            <Column
              dataField="reportBankBankID"
              caption={t("BranchName")}
              dataType="string"
              cssClass="column-info"
              visible={showreportBankBankID}
            />

            <Export enabled={true} allowExportSelectedData={true} />
            {/* <FilterRow visible={true} applyFilter="auto" /> */}
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={20} />
            <Pager
              visible={true}
              allowedPageSizes={[10, 20, 30]}
              showPageSizeSelector={true}
              showNavigationButtons={true}
              showInfo={true}
            />
            <Selection mode="single" />
            <Sorting mode="multiple" />
            <Scrolling columnRenderingMode="virtual" />
          </DataGrid>
        </StyledWrapDataGrid>
      </StyledCardContent>
    </StyledCard>
  );
};

export default TableExpense;
