import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material";
import utils from "../../../../../../utils";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import { Edit } from "@mui/icons-material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DialogNewLeave from "./DialogNewLeave";
import { getEmployeeLeaveQuotaByIdEmp, getLeaveRequestAllYear, getLeaveWithdrawEmployeeYear } from "../../../../../../actions/employee";
import { allHoliday } from "../../../../../../actions/holiday";
import dayjs from "dayjs";
import { getManager } from "../../../../../../actions/manager";
import CardHistoryLeave from "./CardHistoryLeave";
import { getLeaveAvaiable, withdrawLeaveEmployee } from "../../../../../../actions/leave";
import AlertResponse from "../../../../shared/general/AlertResponse";
import leaveService from "../../../../../../services/leave.service";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DialogLeave from "./DialogLeave";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StyledCardDark = styled("div")({
  marginTop: 16,
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardTitle": {
    fontSize: 22,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const CARD_PER_PAGE = 10

const Leave = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const [drawerLeaveQuotaConfig, setDrawerLeaveQuotaConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const [dataWithDraw, setDataWithDraw] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [page, setPage] = useState(1);
  const [leaveRoundList, setLeaveRoundList] = useState([]);
  const [selectedLeaveRound, setSelectedLeaveRound] = useState("");
  const { result: leaveAvailable } = useSelector((state) => state.leaveAvailable);
  const [leaveRoundConfig, setLeaveRoundConfig] = useState({
    date: "",
    start: "",
    end: ""
  })

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const openDrawer = (data) => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: {
        leaveGroupName: data.name,
        idLeaveQuota: data.leaveQuota ? data.leaveQuota.idLeaveQuota : null,
        leaveValue: data.leaveQuota ? data.leaveQuota.leaveValue : null,
        carry: data.leaveQuota ? data.leaveQuota.carry : null,
        idLeaveType: data.idLeaveType,
        idLeaveGroup: data.idLeaveGroup,
      },
    }));
  };

  const onDrawerClose = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const onDrawerOpen = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {}
    }))
  }

  const onDrawerLeaveQuotaClose = () => {
    setDrawerLeaveQuotaConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const onDrawerLeaveQuotaOpen = (leave) => {
    setDrawerLeaveQuotaConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        idLeaveQuota: leave.leaveQuota ? leave.leaveQuota.idLeaveQuota : null,
        leaveValue: leave.leaveQuota ? leave.leaveQuota.leaveValue : null,
        carry: leave.leaveQuota ? leave.leaveQuota.carryValue : null,
        idLeaveGroup: leave.idLeaveGroup,
        idLeaveType: leave.idLeaveType,
      }
    }))
  }

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const fetchLeaveRound = (idCompany) => {
    leaveService.getLeaveRoundList({ idCompany: idCompany }).then(res => {
      setLeaveRoundList(res.data)
      const today = dayjs();
      const foundLeaveRound = res.data.find(item => (
        dayjs(today).isBetween(
          dayjs(item.start),
          dayjs(item.end),
          "[]"
        )
      ))
      if (foundLeaveRound) {
        setSelectedLeaveRound(foundLeaveRound.date);
        setLeaveRoundConfig(prev => ({
          ...prev,
          ...foundLeaveRound,
        }))
      } else {
        setSelectedLeaveRound("");
        setLeaveRoundConfig(prev => ({
          ...prev,
          date: "",
          start: "",
          end: "",
        }))
      }
      // useHookForm.resetField("leaveRoundDate", { defaultValue: foundLeaveRound.date })
    }).catch(error => {
      setLeaveRoundList([]);
      setSelectedLeaveRound("");
      setLeaveRoundConfig(prev => ({
        ...prev,
        date: "",
        start: "",
        end: "",
      }))
      // useHookForm.resetField("leaveRoundDate", { defaultValue: "" })
    })
  }

  const handleDeleteLeave = async () => {
    const formData = {
      idLeave: dataWithDraw.idLeave,
    };

    const result = await dispatch(withdrawLeaveEmployee(null, formData));
    if (result && result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      setOpenConfirmDialog(false);
      dispatch(
        getLeaveRequestAllYear({
          filter: dayjs().format("YYYY"),
          idEmployees: employeeProfile.idEmployees,
        })
      );
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
      setOpenConfirmDialog(false);
    }
  };

  useEffect(() => {
    dispatch(allHoliday(dayjs(new Date()).format("YYYY"), {idEmployees: employeeProfile.idEmployees}));
    dispatch(getEmployeeLeaveQuotaByIdEmp(employeeProfile.idEmployees));
    dispatch(
      getLeaveRequestAllYear({
        filter: dayjs().format("YYYY"),
        idEmployees: employeeProfile.idEmployees,
      })
    );
    dispatch(
      getLeaveWithdrawEmployeeYear({
        filter: dayjs().format("YYYY"),
        employees: employeeProfile.idEmployees,
      })
    );
    dispatch(getManager());
    fetchLeaveRound(employeeProfile.idCompany);
  }, []);

  const fetchingLeaveAvailable = () => {
    dispatch(getLeaveAvaiable({ date: selectedLeaveRound, idEmployees: employeeProfile.idEmployees }));
  }

  useEffect(() => {
    if (selectedLeaveRound) {
      dispatch(getLeaveAvaiable({ date: selectedLeaveRound, idEmployees: employeeProfile.idEmployees }))
      dispatch(getLeaveRequestAllYear({
        filter: dayjs(selectedLeaveRound).format("YYYY"),
        idEmployees: employeeProfile.idEmployees,
      }))
    }
  }, [selectedLeaveRound])

  const renderValueLeaveRound = (value) => {
    const foundLeaveRoundValue = leaveRoundList.find(item => item.date === value)

    if (foundLeaveRoundValue) {
      return <Typography>
        {i18n.language === "th" ?
          `${dayjs(foundLeaveRoundValue.start).format("D MMMM BBBB")} - ${dayjs(foundLeaveRoundValue.end).format("D MMMM BBBB")}`
          :
          `${dayjs(foundLeaveRoundValue.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(foundLeaveRoundValue.end).locale(i18n.language).format("D MMMM YYYY")}`
        }
      </Typography>
    } else {
      return <Typography>-</Typography>
    }
  }

  return (
    <div>
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          {/* <StyledHeadLabel variant="body2" gutterBottom>
            {t("Leaves")}
          </StyledHeadLabel> */}
          <Box
            width="100%"
            maxWidth="min-content"
          >
            <TextFieldTheme
              value={selectedLeaveRound}
              onChange={(event) => {
                setSelectedLeaveRound(event.target.value)
                setLeaveRoundConfig(prev => ({
                  ...prev,
                  ...leaveRoundList.find(item => item.date === event.target.value)
                }))
              }}
              select
              // helperText={fieldState.error? fieldState.error.message: null}
              // error={fieldState.error? true: false}
              SelectProps={{
                size: "small",
                displayEmpty: true,
                renderValue: (selected) => {
                  if (selected.length === 0) {
                    return <Typography color="text.secondary">{t("SelectLeaveRound")}</Typography>
                  } else {
                    return renderValueLeaveRound(selected)
                  }
                }
              }}
            >
              <MenuItem value="" disabled>{t("SelectLeaveRound")}</MenuItem>
              {leaveRoundList.map(item => (
                <MenuItem value={item.date}>
                  {i18n.language === "th" ?
                    `${dayjs(item.start).format("D MMMM BBBB")} - ${dayjs(item.end).format("D MMMM BBBB")}`
                    :
                    `${dayjs(item.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(item.end).locale(i18n.language).format("D MMMM YYYY")}`
                  }
                </MenuItem>
              ))}
            </TextFieldTheme>
          </Box>
          {!userProfile.readOnly && leaveRoundConfig.end && dayjs().isSameOrBefore(dayjs(leaveRoundConfig.end).add(2, 'month'), 'date') && (
            <div className="wrap-btn-add">
              <ButtonBlue
                startIcon={<AddRoundedIcon />}
                variant="contained"
                onClick={onDrawerOpen}
              >
                {t("AddLeaveTransaction")}
              </ButtonBlue>
            </div>
          )}
        </div>
        {(employeeProfile && selectedLeaveRound) && (
          <TableContainer
            style={{
              border: "1px solid rgba(0,0,0,0.1)",
              borderRadius: "16px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 250 }}>
                    {t("LeaveType")}
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    {t("LeaveRights")}/{t("AccumulatedRights")} ({t("Unit.Days")})
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 50 }}>
                    {t("Spent")} ({t("Unit.Days")})
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 50 }}>
                    {t("Remaining")} ({t("Unit.Days")})
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveAvailable &&
                  leaveAvailable.map((leave) => {
                    return (
                      <TableRow key={leave.idLeaveType}>
                        <TableCell
                          style={{ display: "flex", minHeight: "24.5px" }}
                        >
                          <Box display="flex" alignItems="center" gap="8px">
                            {!userProfile.readOnly && (
                              <IconButton
                                aria-label="edit"
                                onClick={() => {
                                  onDrawerLeaveQuotaOpen(leave);
                                }}
                                size="small"
                                disabled={leave.isLeaveCompensate === 1}
                              >
                                <Edit fontSize="inherit" />
                              </IconButton>
                            )}
                            <Typography>
                              {i18n.resolvedLanguage === "th"
                                ? leave.name
                                : leave.name_EN ? leave.name_EN : ""}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {leave.leaveValue === null ? (
                            <Typography>{t("Unlimited")}</Typography>
                          ) : (
                            <Typography>
                              {`${utils.roundToTwo(leave.leaveValue)}`}
                              {`${leave.carryValue ? "/" + `${utils.roundToTwo(leave.carryValue)}` : ""
                                }`}
                              {leave.isLeaveCompensate === 1 ? " "+t("Unit.ShortHours") : ""}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">{`${utils.roundToTwo(leave.leaveUsed)}`}</TableCell>
                        <TableCell align="center">
                          {leave.leaveValue === null ? (
                            <Typography>{t("Unlimited")}</Typography>
                          ) : (
                            <Typography align="center">
                              {`${utils.roundToTwo(leave.leaveRemain)}`}
                              {`${leave.carryRemain ? "/" + `${utils.roundToTwo(leave.carryRemain)}` : ""
                                }`}
                              {leave.isLeaveCompensate === 1 ? " "+t("Unit.ShortHours") : ""}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledWrapBranch>
      <StyledCardDark>
        <Typography variant="h4" className={`cardTitle`} gutterBottom>
          {t("LeaveRecord")}
        </Typography>
        {leaveEmployeesList && leaveEmployeesList.length > 0 ? (
          <>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Pagination
                count={Math.ceil(leaveEmployeesList.length / CARD_PER_PAGE)}
                page={page}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
            <Grid container spacing={1} marginTop={1}>
              {leaveEmployeesList
                .slice((page - 1) * CARD_PER_PAGE, page * CARD_PER_PAGE)
                .map((item) => (
                  <Grid item xs={12} sm={6} key={item.idLeave}>
                    <CardHistoryLeave
                      data={item}
                      handleDeleteLeave={handleDeleteLeave}
                      setDataWithDraw={setDataWithDraw}
                      openConfirmDialog={openConfirmDialog}
                      setOpenConfirmDialog={setOpenConfirmDialog}
                    />
                  </Grid>
                ))}
            </Grid>
          </>
        ) : (
          <Typography color="text.secondary">ไม่มีประวัติการลางาน</Typography>
        )}
      </StyledCardDark>
      <DialogNewLeave
        fetchingLeaveAvailable={fetchingLeaveAvailable}
        drawerConfig={drawerConfig}
        onClose={onDrawerClose}
        leaveRoundConfig={leaveRoundConfig}
      />
      <DialogLeave
        drawerConfig={drawerLeaveQuotaConfig}
        onClose={onDrawerLeaveQuotaClose}
        leaveRoundConfig={leaveRoundConfig}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
};

export default Leave;
