import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Autocomplete,
  Dialog,
  FormControl,
  Select,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { getDepartment } from "../../../../../actions/company";
import {
  AddOnBoardinglist,
  EditOnBoardinglist,
  getOnBoardingList,
} from "../../../../../actions/onBoarding";
import Swal from "sweetalert2";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogName = ({ open, handleClose, data, selectedCompany }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const [formData, setFormData] = useState();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(getDepartment());
    if (open) {
      if (data) {
        setFormData({
          name: data.name,
          idOnBoardingList: data.idOnBoardingList,
          day: data.day,
          assignmentType: data.assignmentType || "all",
          company: data.company || null,
        });
      } else {
        setFormData({
          name: null,
          day: null,
          assignmentType: "",
          company: null,
        });
      }
    }
  }, [open]);

  const handleSubmit = async () => {
    if (!formData.name || !formData.company || !formData.assignmentType) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("PleaseFillInRequiredInformations")}`);
    } else {
      if (!data) {
        const data = {
          name: formData.name,
          idCompany: formData.company.idCompany,
          day: formData.day,
          assignmentType: formData.assignmentType,
        };
        const res = await dispatch(AddOnBoardinglist(data))
        if (res && res.status === 200) {
          handleClose();
          Swal.fire({
            title: t("TaskAdded"),
            text: t("TheTaskHasBeenAddedSuccessfully"),
            icon: "success",
            confirmButtonColor: "#28a745",
            confirmButtonText: t("OK"),
          });
        }  
      } else {
        const data = {
          name: formData.name,
          idOnBoardingList: formData.idOnBoardingList,
          day: formData.day,
          assignmentType: formData.assignmentType,
        };
        const res = await dispatch(EditOnBoardinglist(data))
        if (res && res.status === 200) {
          Swal.fire({
            title: t("TaskEdited"),
            text: t("TheTaskHasBeenEditedSuccessfully"),
            icon: "success",
            confirmButtonColor: "#28a745",
            confirmButtonText: t("OK"),
          });
        }
      }

      dispatch(getOnBoardingList({ idCompany: selectedCompany.idCompany }));
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="dialog-name-title"
        aria-describedby="dialog-name-description"
      >
        <DialogTitle id="dialog-name-title">
          {data === "Edit" ? t("EditItem") : t("AddItem")}
        </DialogTitle>
        <DialogContent dividers>
          {formData && (
            <StyledRoot>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="GridTopicInput">
                  {t("SelectCompany")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={affiliateOrganizationList || []}
                    getOptionLabel={(option) => option.companyName}
                    value={formData.company || null}
                    onChange={(event, newValue) =>
                      setFormData({ ...formData, company: newValue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        placeholder={t("SelectCompany")}
                      />
                    )}
                    noOptionsText={t("NoData")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 16 }}>
                <Grid item xs={12} sm={6} className="GridTopicInput">
                  {t("ItemName")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    margin="dense"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 16 }}>
                <Grid item xs={12} sm={6} className="GridTopicInput">
                  {t("ProceedInternally")} ({t("Unit.Days")})
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="day"
                    value={formData.day}
                    onChange={handleChange}
                    margin="dense"
                    fullWidth
                    type="number"
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 16 }}>
                <Grid item xs={12} sm={6} className="GridTopicInput">
                  {t("AssignmentType")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" margin="dense">
                    <Select
                      name="assignmentType"
                      value={formData.assignmentType}
                      onChange={handleChange}
                      variant="outlined"
                    >
                      <MenuItem value="all">{t("EnforceTask")}</MenuItem>
                      <MenuItem value="individual">
                        {t("IndividualTask")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </StyledRoot>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonBlue
            className="cancel"
            onClick={handleClose}
            sx={{ minWidth: 120, height: 40 }}
          >
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={handleSubmit}
            autoFocus
            sx={{ minWidth: 120, height: 40 }}
          >
            {t("SaveData")}
          </ButtonBlue>
        </DialogActions>
      </Dialog>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </>
  );
};

export default DialogName;
