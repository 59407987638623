import React, { Fragment, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Controller } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ClearIcon from "@mui/icons-material/Clear";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import employeeService from "../../../../../services/employee.service";
import PersonIcon from "@mui/icons-material/Person";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";

const JobInfo = (props) => {
  const { useForm, registrationMetadata } = props;
  const { t, i18n } = useTranslation();

  const [isOpenImportJobInfo, setIsOpenImportJobInfo] = useState(false);
  const [selectEmployees, setSelectedEmployees] = useState("");
  const [isFetchingJobInfo, setIsFecthingJobInfo] = useState(false);

  const filterOptions = createFilterOptions({
    stringify: (value) =>
      `${getUserFullName(value)} ${value.email}`,
  });

  const filterOptionsPosition = createFilterOptions({
    stringify: (value) => `${value.positionCode} ${getUserPosition(value)}`,
  });

  const onClickLoadJobData = async (idEmployees) => {
    setIsFecthingJobInfo(true);
    const result = await employeeService.getEmployeeProfile(
      idEmployees,
      "admin"
    );
    if (result && result.status === 200) {
      useForm.setValue("employmentTime", result.data.employmentTime || "");
      useForm.setValue("idPaymentType", result.data.idPaymentType || "");
      useForm.setValue("idEmploymentType", result.data.idEmploymentType || "");
      useForm.setValue("idPosition", result.data.idPosition || "");

      let foundPosition = registrationMetadata.positions.find(
        (p) => p.idPosition === result.data.idPosition
      );
      if (foundPosition) {
        useForm.setValue(
          "sectionName",
          foundPosition.sectionName ? foundPosition.sectionName : "-"
        );
        useForm.setValue(
          "departmentName",
          foundPosition.departmentName ? foundPosition.departmentName : "-"
        );
        useForm.setValue(
          "divisionName",
          foundPosition.divisionName ? foundPosition.divisionName : "-"
        );
        useForm.setValue(
          "businessUnitName",
          foundPosition.businessUnitName ? foundPosition.businessUnitName : "-"
        );
        useForm.setValue(
          "jobGroupName",
          foundPosition.jobGroupName ? foundPosition.jobGroupName : "-"
        );
        useForm.setValue(
          "jobLevelName",
          foundPosition.levelName ? foundPosition.levelName : "-"
        );
      }

      // useForm.setValue("sectionName", result.data.sectionName || "-")
      // useForm.setValue("departmentName", result.data.departmentName || "-")
      // useForm.setValue("divisionName", result.data.divisionName || "-")
      // useForm.setValue("jobGroupName", result.data.jobGroupName || "-")
      // useForm.setValue("idJobLevel", result.data.idJobLevel || "")

      useForm.setValue("idPersonnelLevel", result.data.idPersonnelLevel || "");
      useForm.setValue("filed_office", result.data.filed_office || "");
      useForm.setValue("workingType", result.data.workingType || "");
      useForm.setValue(
        "idShift",
        result.data.shift ? result.data.shift.idShift || "" : ""
      );
      useForm.setValue("workingLocation", result.data.workingLocation || "");
      useForm.setValue(
        "mainWorkingLocationPoint",
        result.data.mainWorkingLocationPoint || ""
      );
      useForm.setValue("isFinger", result.data.isFinger || "");
      useForm.setValue("methodAttendance", result.data.methodAttendance || "");
      useForm.setValue(
        "idManagerLV1",
        result.data.managerLV1_idManagerLV1 || ""
      );
      useForm.setValue(
        "idManagerLV2",
        result.data.managerLV2_idManagerLV2 || ""
      );
      useForm.setValue("salary", result.data.salary || "");
      useForm.setValue("costCenterCharge", result.data.costCenterCharge || "");
      useForm.setValue(
        "costElementCharge",
        result.data.costElementCharge || ""
      );
    }
    setIsOpenImportJobInfo(false);
    setIsFecthingJobInfo(false);
    setSelectedEmployees("");
  };

  return (
    <Box>
      <Typography className="title-text">{`${t("JobDescription")}`}</Typography>
      <Grid container spacing={2}>
        {isOpenImportJobInfo ? (
          <Fragment>
            <Grid item xs={12} container gap="8px">
              <Box flexGrow="1">
                <Autocomplete
                  options={registrationMetadata.employeeList}
                  getOptionLabel={(option) =>
                    `${getUserFullName(option)}`
                  }
                  filterOptions={filterOptions}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.idEmployees}>
                      <Box>
                        <Typography>
                          {getUserFullName(option)}
                        </Typography>
                        <Typography color="text.third" fontSize="14px">
                          {option.email}
                        </Typography>
                      </Box>
                    </MenuItem>
                  )}
                  renderInput={(params) => <TextFieldTheme {...params} />}
                  value={
                    registrationMetadata.employeeList.find(
                      (option) => option.idEmployees === selectEmployees
                    ) || null
                  }
                  onChange={(_, value) => {
                    setSelectedEmployees(value ? value.idEmployees : "");
                    if (value && value.idEmployees) {
                      onClickLoadJobData(value.idEmployees);
                    }
                  }}
                  disableClearable
                  disabled={isFetchingJobInfo}
                />
              </Box>
              {isFetchingJobInfo ? (
                <Box color="#919eab">
                  <CircularProgress color="inherit" />
                </Box>
              ) : (
                <ButtonBlue
                  variant="text"
                  onClick={() => {
                    setIsOpenImportJobInfo(false);
                  }}
                >{`${t("Cancel")}`}</ButtonBlue>
              )}
            </Grid>
          </Fragment>
        ) : (
          <Grid item xs={12} container justifyContent="flex-end">
            <ButtonBlue
              variant="outlined"
              onClick={() => {
                setIsOpenImportJobInfo(true);
              }}
              startIcon={<PersonIcon />}
            >
              {`${t("HireSubstitute")}`}
            </ButtonBlue>
          </Grid>
        )}

        <Grid item xs={4}>
          <Typography>{`${t("StartDate")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="hiringDate"
            control={useForm.control}
            render={({ field }) => (
              <DatePickerCustom
                {...field}
                views={["year", "month", "day"]}
                inputFormat="DD MMMM YYYY"
                // disableFuture
                disableMaskedInput
                openTo="year"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: `${t("DayMonthYear(AD)")}`,
                      readOnly: true,
                    }}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("PositionName")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idPosition"
            control={useForm.control}
            render={({ field }) => (
              <Autocomplete
                options={registrationMetadata.positions}
                getOptionLabel={(option) => `${getUserPosition(option)}`}
                filterOptions={filterOptionsPosition}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idPosition}>
                    <Box>
                      <Typography fontSize="12px" color="text.secondary">
                        {option.positionCode}
                      </Typography>
                      <Typography>{getUserPosition(option)}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
                value={
                  registrationMetadata.positions.find(
                    (option) => option.idPosition === field.value
                  ) || null
                }
                onChange={(_, value) => {
                  field.onChange(value ? value.idPosition : "");
                  if (value) {
                    let foundPosition = registrationMetadata.positions.find(
                      (p) => p.idPosition === value.idPosition
                    );
                    if (foundPosition) {
                      useForm.setValue(
                        "sectionName",
                        foundPosition.sectionName
                          ? foundPosition.sectionName
                          : "-"
                      );
                      useForm.setValue(
                        "departmentName",
                        foundPosition.departmentName
                          ? foundPosition.departmentName
                          : "-"
                      );
                      useForm.setValue(
                        "divisionName",
                        foundPosition.divisionName
                          ? foundPosition.divisionName
                          : "-"
                      );
                      useForm.setValue(
                        "businessUnitName",
                        foundPosition.businessUnitName
                          ? foundPosition.businessUnitName
                          : "-"
                      );
                      useForm.setValue(
                        "jobGroupName",
                        foundPosition.jobGroupName
                          ? foundPosition.jobGroupName
                          : "-"
                      );
                      useForm.setValue(
                        "jobLevelName",
                        foundPosition.levelName ? foundPosition.levelName : "-"
                      );

                      if (foundPosition.idManager) {
                        useForm.setValue(
                          "idManagerLV1",
                          foundPosition.idManager,
                          { shouldValidate: true }
                        );
                        const managerL1 = registrationMetadata.managers.find(
                          (item) => item.idEmployees === foundPosition.idManager
                        );
                        if (managerL1 && managerL1.idManagerLV1) {
                          useForm.setValue(
                            "idManagerLV2",
                            managerL1.idManagerLV1,
                            { shouldValidate: true }
                          );
                        }
                      }
                    }
                  } else {
                    useForm.setValue("sectionName", "");
                    useForm.setValue("departmentName", "");
                    useForm.setValue("divisionName", "");
                    useForm.setValue("businessUnitName", "");
                    useForm.setValue("jobGroupName", "");
                    useForm.setValue("jobLevelName", "");
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("JobGroup")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="jobGroupName"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Section")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="sectionName"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Department")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="departmentName"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Division")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="divisionName"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Business")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="businessUnitName"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("JobLevel")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="jobLevelName"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true,
                }}
              />
            )}
          />
        </Grid>

        {/* <Grid item xs={4}>
          <Typography>ระดับงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idJobLevel"
            control={useForm.control}
            render={({field}) => (
              <Autocomplete
                options={registrationMetadata.jobLevels}
                getOptionLabel={(option) => `${option.levelName}`}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idLevel}>
                    <Box>
                      <Typography>{option.levelName}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                  />
                )}
                value={registrationMetadata.jobLevels.find(option => option.idLevel === field.value) || null}
                onChange={(_, value) => {field.onChange(value? value.idLevel: "")}}
              />
            )}
          />
        </Grid> */}

        <Grid item xs={4}>
          <Typography>{`${t("EmployeePersonalLevel")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idPersonnelLevel"
            control={useForm.control}
            render={({ field }) => (
              <Autocomplete
                options={registrationMetadata.jobLevels}
                getOptionLabel={(option) => `${option.levelName}`}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idLevel}>
                    <Box>
                      <Typography>{option.levelName}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
                value={
                  registrationMetadata.jobLevels.find(
                    (option) => option.idLevel === field.value
                  ) || null
                }
                onChange={(_, value) => {
                  field.onChange(value ? value.idLevel : "");
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("FieldOffice")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="filed_office"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              >
                <MenuItem value="Field">Field</MenuItem>
                <MenuItem value="Office">Office</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("WorkingType")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="workingType"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                onChange={(event) => {
                  useForm.setValue("idShift", "");
                  field.onChange(event.target.value);
                }}
                select
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              >
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Shift">Shift</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>
            {`${t("ShiftGroup")}`}/{`${t("ShiftName")}`}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idShift"
            control={useForm.control}
            render={({ field }) => (
              <Autocomplete
                options={registrationMetadata.shifts.filter((s) => {
                  if (
                    useForm.getValues("workingType") === "Shift" &&
                    s.idWorkingType === 1
                  ) {
                    return true;
                  } else if (
                    useForm.getValues("workingType") === "Day" &&
                    s.idWorkingType === 2
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })}
                getOptionLabel={(option) =>
                  `${option.shiftGroupName} : ${option.shiftName}`
                }
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idShift}>
                    <Box>
                      <Typography>{`${option.shiftGroupName} : ${option.shiftName}`}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
                value={
                  registrationMetadata.shifts.find(
                    (option) => option.idShift === field.value
                  ) || null
                }
                onChange={(_, value) => {
                  field.onChange(value ? value.idShift : "");
                  useForm.setValue(
                    "idShiftGroup",
                    value ? value.idShiftGroup : ""
                  );
                }}
                disabled={!useForm.watch("workingType")}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("WorkingLocation")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="workingLocation"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                // placeholder="เช่น Bangkok, Rayong"
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("FingerScan")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="isFinger"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              >
                <MenuItem value="1">{`${t("Yes")}`}</MenuItem>
                <MenuItem value="0">{`${t("No")}`}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("TimekeepingAffectsSalary")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="isFingerAffectPayroll"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              >
                <MenuItem value="1">{`${t("Yes")}`}</MenuItem>
                <MenuItem value="0">{`${t("No")}`}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("MethodAttendance")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="methodAttendance"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                onChange={(e) => {
                  field.onChange(e.target.value);
                  // useForm.setValue("mainWorkingLocationPoint", "", { shouldValidate: true, shouldTouch: false })
                  useForm.resetField("mainWorkingLocationPoint");
                }}
              >
                <MenuItem value="main">{`${t(
                  "OneMainWorkingPoint"
                )}`}</MenuItem>
                <MenuItem value="all">{`${t("AllWorkingPoint")}`}</MenuItem>
                <MenuItem value="point">{`${t(
                  "AllLocation(coordinates)"
                )}`}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        {useForm.watch("methodAttendance") === "main" && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>{`${t("GpsLocationWork")}`}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="mainWorkingLocationPoint"
                control={useForm.control}
                render={({ field }) => (
                  <Autocomplete
                    options={registrationMetadata.groupGpsLocations}
                    getOptionLabel={(option) =>
                      `${option.groupGpsLocationName}`
                    }
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idGroupGpsLocations}>
                        <Box>
                          <Typography>{`${option.groupGpsLocationName}`}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={
                          useForm.formState.errors[field.name] ? true : false
                        }
                        helperText={
                          useForm.formState.errors[field.name]
                            ? useForm.formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                    value={
                      registrationMetadata.groupGpsLocations.find(
                        (option) => option.idGroupGpsLocations === field.value
                      ) || null
                    }
                    onChange={(_, value) => {
                      field.onChange(value ? value.idGroupGpsLocations : "");
                    }}
                  />
                )}
              />
            </Grid>
          </Fragment>
        )}

        <Grid item xs={4}>
          <Typography>{`${t("EndDate")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="contractTermainatoinDate"
            control={useForm.control}
            render={({ field }) => (
              <DatePickerCustom
                {...field}
                views={["year", "month", "day"]}
                inputFormat="DD MMMM YYYY"
                // disableFuture
                disableMaskedInput
                openTo="year"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: `${t("DayMonthYear(AD)")} (${t(
                        "Optional"
                      )})`,
                      readOnly: true,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: [
                        field.value ? (
                          <IconButton
                            key={`contractTermainatoinDate_clear_button`}
                            className="clear-button date"
                            onClick={() => {
                              field.onChange(null);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : null,
                        <Box key={`contractTermainatoinDate_calendar_button`}>
                          {params.InputProps.endAdornment}
                        </Box>,
                      ],
                    }}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobInfo;
