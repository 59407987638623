import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  FormControl,
  Grid,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import Swal from 'sweetalert2';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dropzone, { useDropzone } from "react-dropzone";
import AlertResponse from "../../shared/general/AlertResponse";
import AddPhotoIcon from "../../../../components/pages/assets/add.png";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { addDocument, editDocument } from "../../../../actions/document";
import { RotatingLines } from 'react-loader-spinner';
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 80,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const DialogImport = (props) => {
  const dispatch = useDispatch();
  const { open, doc, type, handleClose, setReload, reload } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [value, setValue] = useState(false);
  const [formData, setFormData] = useState(null);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);


  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const fileDrawer = () => {
    if (formData && formData.file === "none") {
      return doc.fileURL;
    } else if (formData && formData.file) {
      return URL.createObjectURL(formData.file);
    } else {
      return "";
    }
  };

  const handleFileLoadComplete = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      if (type === "edit") {
        setFormData({
          idEmployees: null,
          idCompany: null,
          startDate: doc.startDate,
          endDate: doc.endDate,
          expiration: doc.expiration,
          file: "none",
        });
        setValue(doc.expiration === 1 ? "true" : "false");
      } else {
        setFormData({
          idEmployees: null,
          idCompany: null,
          startDate: null,
          endDate: null,
          expiration: value,
          file: null,
        });
      }
    }
  }, [open, type, doc]);

  useEffect(() => {
    const fileURL = fileDrawer();
    if (fileURL !== "") {
      handleFileLoadComplete();
    }
  }, [formData]);

  const handleChange = (event) => {
    const isExpirationTrue = event.target.value === "true";

    if (type === "edit") {
      setFormData({
        idEmployees: null,
        idCompany: null,
        startDate: isExpirationTrue ? doc.startDate : null,
        endDate: isExpirationTrue ? doc.endDate : null,
        expiration: isExpirationTrue ? 1 : 0,
        file: "none",
      });
    } else {
      setFormData({
        idEmployees: null,
        idCompany: null,
        startDate: null,
        endDate: null,
        expiration: isExpirationTrue ? 1 : 0,
        file: null,
      });
    }
    setValue(event.target.value);
  }

  const handleSubmit = async () => {
    if (!formData.file) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("ThisFieldIsRequired")}`);
    } else {
      const data = {
        idEmployees: doc.idEmployees,
        idCompany: doc.idCompany,
        documentTypeName: doc.documentTypeName,
        startDate: formData.startDate,
        endDate: formData.endDate,
        expiration: formData.expiration,
        idDocumentType: doc.idDocumentType,
        createDate: dayjs(new Date()).format("YYYY-MM-DD"),
        updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
      };

      const postData = new FormData();

      Object.keys(data).map((k) => {
        postData.append(k, JSON.stringify(data[k]));
      });
      if (formData.file) {
        postData.append("file", formData.file);
      }
      setLoading(true);
      try {
        if (type === "import") {
          const result = await dispatch(addDocument(postData));
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'อัพโหลดไฟล์เอกสารสำเร็จ!!',
              showConfirmButton: false,
              timer: 1500,
            });
            setReload(true);
            if (reload === 1) setReload(2);
            else setReload(1);
            handleClose();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'ไม่สามารถแก้ไขไฟล์เอกสารได้!!',
              text: 'กรุณาลองใหม่อีกครั้ง',
              confirmButtonText: 'OK',
            });
          }
        } else {
          const result = await dispatch(editDocument(postData));
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'แก้ไขไฟล์เอกสารสำเร็จ!!',
              showConfirmButton: false,
              timer: 1500,
            });
            setReload(true);
            if (reload === 1) setReload(2);
            else setReload(1);
            handleClose();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'ไม่สามารถแก้ไขไฟล์เอกสารได้!!',
              text: 'กรุณาลองใหม่อีกครั้ง',
              confirmButtonText: 'OK',
            });
          }
        }
      } catch (error) {
        console.error("Error while editing document: ", error);
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const title = `${t("AttachFile")}` + doc.documentTypeName;

  return (
    <DrawerCustom
      title={title}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && (
        <StyledRoot>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2} style={{ marginBottom: 16 }}>
              <Grid item className="GridTopicInput">
                <Typography sx={{ mr: 8 }}>{t("ExpirationDate")}</Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={`${t("Yes")}`}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={`${t("No")}`}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {value === "true" ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Grid className="GridTopicInput">
                    {t("Start")}
                  </Grid>
                  <DatePickerCustom
                    value={formData.startDate}
                    inputFormat="DD/MM/YYYY"
                    name="startDate"
                    onChange={(newValue) => {
                      setFormData({
                        ...formData,
                        ["startDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth helperText={null} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid className="GridTopicInput">
                    {t("End")}
                  </Grid>
                  <DatePickerCustom
                    value={formData.endDate}
                    inputFormat="DD/MM/YYYY"
                    name="endDate"
                    onChange={(newValue) => {
                      setFormData({
                        ...formData,
                        ["endDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth helperText={null} />
                    )}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            {!formData.file && (
              <Grid item xs={12} style={{ padding: '2px' }}>
                <Typography
                  color="text.third"
                  variant="body2"
                  paddingBottom="16px"
                  paddingTop="20px"
                >
                  {t("AttachFile")}
                </Typography>
                <>
                  {loading ? (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                        <Typography
                          variant="h6"
                          style={{
                            marginTop: '16px',
                            color: '#3f51b5',
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                            fontWeight: 'bold',
                            userSelect: 'none',
                          }}
                        >
                          {t("UploadingDocCompanyFiles")}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', userSelect: 'none' }}>
                        <RotatingLines
                          visible={true}
                          height="96"
                          width="96"
                          color="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          ariaLabel="rotating-lines-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <StyledDropzone>
                        <Dropzone
                          accept="application/pdf"
                          maxFiles={1}
                          multiple={false}
                          maxSize={3145728}
                          onDrop={(acceptedFiles, rejectedFiles) => {
                            if (acceptedFiles.length > 0) {
                              setFormData({ ...formData, file: acceptedFiles[0] });
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone-leave" })}>
                              <div className="inner-dropzone">
                                <input {...getInputProps()} />
                                <div style={{ textAlign: "center" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      width: '306px',
                                      height: '250px',
                                    }}
                                  >
                                    <img alt="add" src={AddPhotoIcon} />
                                  </div>
                                  <Typography
                                    variant="caption"
                                    style={{ lineHeight: 0.5, color: "#999999" }}
                                  >
                                    {t('AllowedPdfMaxSize')}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </StyledDropzone>
                    </>
                  )}
                </>
              </Grid>
            )}
            {formData.file && (
              <Grid item xs={12}>
                <Typography
                  color="text.third"
                  variant="body2"
                  paddingBottom="16px"
                  paddingTop="20px"
                >
                  {t("AttachFile")}
                </Typography>
                <Box sx={{ position: "relative", textAlign: "center" }}>
                  {loading ? (
                    <>
                    </>
                  ) : (
                    <>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 34,
                          right: 0,
                          color: "error.main",
                          border: "2px solid",
                          borderColor: "error.main",
                          borderRadius: "0%",
                          transition: "transform 0.3s, box-shadow 0.3s",
                          "&:hover": {
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                            backgroundColor: "rgba(255, 0, 0, 0.1)",
                          },
                        }}
                        onClick={() => {
                          setFormData({ ...formData, file: null });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                  {type === "import" && formData.file instanceof Blob ? (
                    loading ? (
                      <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                          <Typography
                            variant="h6"
                            style={{
                              marginTop: '16px',
                              color: '#3f51b5',
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                              fontWeight: 'bold',
                              userSelect: 'none',
                            }}
                          >
                            {t("UploadingDocCompanyFiles")}
                          </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', userSelect: 'none' }}>
                          <RotatingLines
                            visible={true}
                            height="96"
                            width="96"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <Typography variant="caption" style={{ lineHeight: 0.5, color: "#999999", userSelect: 'none' }}>
                            {t("File")}
                          </Typography>
                        </div>
                        <div style={{ paddingTop: 0 }}>
                          <iframe
                            src={fileDrawer()}
                            style={{
                              width: "100%",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              marginTop: "8px",
                              marginLeft: "0px",
                              height: '320px',
                              zoom: '140%',
                              userSelect: 'none',
                            }}
                            title="Preview"
                          ></iframe>
                        </div>
                        <div>
                        </div>
                      </>
                    )
                  ) : (
                    type === "edit" && doc.fileURL && (
                      <div>
                        {formData.fileURL === "none" ? (
                          <>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                              <Typography
                                variant="h6"
                                style={{
                                  marginTop: '16px',
                                  color: '#3f51b5',
                                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                  fontWeight: 'bold',
                                  userSelect: 'none',
                                }}
                              >
                                {t("LoadingDocumentFile")}
                              </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', userSelect: 'none' }}>
                              <RotatingLines
                                visible={true}
                                height="96"
                                width="96"
                                color="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {loading ? (
                              <>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      marginTop: '16px',
                                      color: '#3f51b5',
                                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                      fontWeight: 'bold',
                                      userSelect: 'none',
                                    }}
                                  >
                                    {t("UploadingDocCompanyFiles")}
                                  </Typography>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', userSelect: 'none' }}>
                                  <RotatingLines
                                    visible={true}
                                    height="96"
                                    width="96"
                                    color="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    ariaLabel="rotating-lines-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <Typography variant="caption" style={{ lineHeight: 0.5, color: "#999999", userSelect: 'none' }}>
                                    {t("File")}
                                  </Typography>
                                </div>
                                <div style={{ paddingTop: 0 }}>
                                  <iframe
                                    src={fileDrawer()}
                                    style={{
                                      width: "100%",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      marginTop: "8px",
                                      marginLeft: "0px",
                                      height: '320px',
                                      zoom: '140%',
                                      userSelect: 'none',

                                    }}
                                    title="Preview"
                                  ></iframe>
                                </div>
                              </>
                            )
                            }
                          </>
                        )}
                      </div>
                    )
                  )}
                </Box>
              </Grid>
            )}
          </Box>
          <StyledFooter>
            {loading ? (
              <></>
            ) : (
              <>
                <ButtonBlue className="cancel" onClick={handleClose}>
                  {t("Cancel")}
                </ButtonBlue>
                <ButtonBlue variant="contained" onClick={handleSubmit}>
                  {t("SaveData")}
                </ButtonBlue>
              </>
            )}
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogImport;