import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControlLabel,
  Box,
  IconButton,
  Typography,
  Tooltip,
  Slider, // Import Slider
  Grid, // Import Grid
} from "@mui/material";
import MoonShotSwitch from "../../../shared/pages/okrs/components/MoonShotSwitch";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  updateApprovalIndividualKeyResult,
  getManagerMoonShotsById,
} from "../../../../../actions/okrs";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import MoonshotIcon from "../../../assets/moonshot_logo.svg";

const ApproveModal = ({ open, handleClose, data, approveTab }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isMoonShot, setIsMoonShot] = useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [moonshotData, setMoonshotData] = useState(null);

  // State for Slider
  const [rating, setRating] = useState(0);
  const evaluationMarks = [
    { value: 0, label: "0.0" },
    { value: 0.1, label: "0.1" },
    { value: 0.2, label: "0.2" },
    { value: 0.3, label: "0.3" },
    { value: 0.4, label: "0.4" },
    { value: 0.5, label: "0.5" },
    { value: 0.6, label: "0.6" },
    { value: 0.7, label: "0.7" },
    { value: 0.8, label: "0.8" },
    { value: 0.9, label: "0.9" },
    { value: 1.0, label: "1.0" },
  ];

  useEffect(() => {
    if (open && userProfile && userProfile.idEmployees) {
      dispatch(getManagerMoonShotsById(userProfile.idEmployees)).then((res) => {
        if (res.status === 200 && res.data && res.data.length > 0) {
          setMoonshotData(res.data[0]); // ตั้งค่าเป็นอ็อบเจ็กต์แรกจากอาร์เรย์
        }
      });
    }
  }, [open, dispatch, userProfile]);

  const handleSwitchChange = (event) => {
    event.stopPropagation();
    setIsMoonShot(event.target.checked);
  };

  const handleSubmit = async (isApprove, event) => {
    event.stopPropagation();

    let updatedFormData = {};

    if (approveTab === 0) {
      updatedFormData = {
        isApprove: isApprove,
        isMoonShotPlanning: isMoonShot ? 1 : 0,
      };
    } else if (approveTab === 1) {
      updatedFormData = {
        isAchieve: isApprove,
        isMoonShotEvaluation: isMoonShot ? 1 : 0,
        managerRating: rating, // Include the rating in the payload
      };
    }

    try {
      const res = await dispatch(
        updateApprovalIndividualKeyResult(
          data.idIndividualKeyResult,
          updatedFormData
        )
      );

      if (res && res.status === 200) {
        handleClose();

        Swal.fire({
          title: isApprove ? t("ApproveSuccess") : t("CancelItem"),
          text: isApprove ? t("อนุมัติรายการสำเร็จ") : t("ยกเลิกรายการสำเร็จ"),
          icon: "success",
          confirmButtonText: t("OK"),
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: t("Error"),
          text: t("An error occurred while processing your request."),
          icon: "error",
          confirmButtonText: t("OK"),
        });
      }
    } catch (error) {
      console.error("Error updating key result approval:", error);
      Swal.fire({
        title: t("Error"),
        text: t("An error occurred while processing your request."),
        icon: "error",
        confirmButtonText: t("OK"),
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "15px",
          padding: "20px",
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: "0px", // Remove default padding
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Grid container alignItems="center">
          {/* Left Section: MoonShot Remaining */}
          <Grid item xs={4}>
            {moonshotData && (
              <Box display="flex" alignItems="center" gap={1}>
                <img
                  src={MoonshotIcon}
                  alt="MoonShot Icon"
                  style={{ width: "24px", height: "24px" }}
                />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  คงเหลือ: {moonshotData.remaining_moonshots} /{" "}
                  {moonshotData.total_moonshots}
                </Typography>
              </Box>
            )}
          </Grid>

          {/* Center Section: Confirm Approval */}
          <Grid item xs={4} textAlign="center">
            <Typography variant="h6">{t("ConfirmApproval")}</Typography>
          </Grid>

          {/* Right Section: Close Button */}
          <Grid item xs={4} textAlign="right">
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent onClick={(e) => e.stopPropagation()}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
          marginTop={2}
        >
          <FormControlLabel
            control={
              <MoonShotSwitch
                checked={isMoonShot}
                onChange={handleSwitchChange}
                name="moonShot"
                disabled={
                  moonshotData && moonshotData.remaining_moonshots === 0
                }
              />
            }
            label={t("moonShot")}
            onClick={(e) => e.stopPropagation()}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          />

          {/* Slider for Performance Rating (only when approveTab = 1) */}
          {approveTab === 1 && (
            <Box
              sx={{ mt: 3, width: "100%" }}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
            >
              <Typography gutterBottom>{t("PerformanceRating")}</Typography>
              <Slider
                value={rating}
                onChange={(e, newValue) => setRating(newValue)}
                valueLabelDisplay="auto"
                min={0}
                max={1.0}
                step={0.1}
                marks={evaluationMarks}
                sx={{
                  color: "#DB4178", // เปลี่ยนสีของ Slider ให้เป็นสีชมพูตามธีมเว็บไซต์
                }}
              />
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Tooltip
            title={isMoonShot ? t("CannotRejectWhenMoonShot") : ""}
            placement="top"
            arrow
          >
            {/* ห่อหุ้มปุ่มด้วย <span> และเพิ่ม onClick เพื่อหยุดการโปรพาเกทเมื่อปุ่มถูกปิดการใช้งาน */}
            <span onClick={isMoonShot ? (e) => e.stopPropagation() : undefined}>
              <Button
                onClick={(e) => handleSubmit(0, e)}
                disabled={isMoonShot} // ปิดการใช้งานปุ่มเมื่อ isMoonShot เป็น true
                sx={{
                  backgroundColor: "#f57c00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#ff9800",
                  },
                  padding: "10px 20px",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {t("Reject")}
              </Button>
            </span>
          </Tooltip>
          <Button
            onClick={(e) => handleSubmit(1, e)}
            sx={{
              backgroundColor: "#4caf50",
              color: "white",
              "&:hover": {
                backgroundColor: "#66bb6a",
              },
              padding: "10px 20px",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {t("Approve")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveModal;
