import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getDiligenceInfoByIdEmployees } from "../../../../../../actions/employee";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { useDispatch, useSelector } from "react-redux";
import { addEmployeeDiligenceAccumulateCustom, deleteEmployeeDiligenceAccumulateCustomById, updateEmployeeDiligenceAccumulateCustomById } from "../../../../../../actions/employeeDiligenceAccumulateCustom";

const StyledRoot = styled(Box)({
  padding: "16px",
});

const DrawerEditDiligenceAccumulateCustom = (props) => {
  
  const { drawerConfig, handleClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validateYupSchema = yup.object({

  });

  const useHookForm = useForm({
    defaultValues: {
      accumulateMonthInRow: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  useEffect(() => {

    if(drawerConfig.isOpen){
    
      useHookForm.reset({
        accumulateMonthInRow: drawerConfig.data.accumulateMonthInRow !== null? drawerConfig.data.accumulateMonthInRow: "",
      });

    }

  }, [drawerConfig.isOpen])
  
  const onSubmit = async (data) => {

    const formData = {
      accumulateMonthInRow: data.accumulateMonthInRow === ""? null: data.accumulateMonthInRow,
    }

    let result = null

    if(drawerConfig.data.idEmployeeDiligenceAccumulateCustom){

      if(formData.accumulateMonthInRow !== null){
        result = await dispatch(updateEmployeeDiligenceAccumulateCustomById(drawerConfig.data.idEmployeeDiligenceAccumulateCustom, formData));
      } else {
        result = await dispatch(deleteEmployeeDiligenceAccumulateCustomById(drawerConfig.data.idEmployeeDiligenceAccumulateCustom));
      }

    } else {

      if(formData.accumulateMonthInRow !== null){
        formData.idEmployees = employeeProfile.idEmployees;
        result = await dispatch(addEmployeeDiligenceAccumulateCustom(formData));
      } else {
        handleClose();
        return;
      }

    }

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getDiligenceInfoByIdEmployees(employeeProfile.idEmployees));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  };

  return (
    <DrawerCustom
      title={t("attendanceBonusInfo")}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
          
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>
                {t("bonusCalculationCountNext")}
              </Typography>
              <Controller
                name="accumulateMonthInRow"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("notDefined")}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      allowNegative: false,
                      allowLeadingZeros: false,
                      value: field.value,
                      thousandSeparator: ",",
                      decimalScale: 0,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                      },
                    }}
                    onChange={() => {}}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <ButtonBlue variant="text" onClick={handleClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
                <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{t("Save")}</ButtonBlue>
              </Grid>
            </Grid>

          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditDiligenceAccumulateCustom;