import React from "react";
import { styled } from "@mui/styles";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import myKPI from "./assets/myKPI.png";
import progressKPI from "./assets/progressKPI.png";
import summaryKPI from "./assets/summaryKPI.png";
import approveKPI from "./assets/approveKPI.png";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#f5f5f5 !important",
  paddingBottom: 50,
});

const StyledClickablePaper = styled(Paper)({
  width: "auto",
  height: "30vh",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

function KpiAdmin() {
  const { t } = useTranslation();
  const menuItems = [
    { label: t("ManageKPI"), path: "kpi-people-assign", icon: approveKPI },
    { label: t("OrganizationKPI"), path: "kpi/organization", icon: approveKPI },
    { label: t("ProgressStatus"), path: "kpi-successful-All", icon: progressKPI },
    // { label: "สถานะรายการ", path: "kpi-status", icon: statusKPI },
    { label: t("SummarizeScoreSandGrades"), path: "kpi-calibrate", icon: summaryKPI }, 
    { label: t("EditPeriod"), path: "kpi-date", icon: myKPI },
    { label: t("TeamKPI"), path: "kpi/team/admin", icon: approveKPI },
  ];

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          {menuItems.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Link to={item.path} style={{ textDecoration: "none" }}>
                <StyledClickablePaper>
                  <img
                    src={item.icon}
                    alt="icon"
                    style={{
                      width: "50%",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography fontSize={"1.2rem"} align="center">
                    {item.label}
                  </Typography>
                </StyledClickablePaper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledRoot>
  );
}
export default KpiAdmin;

