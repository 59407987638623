import React, { useEffect, useState } from "react";
import { Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, styled, TextField, Typography } from "@mui/material";
import DatePickerCustom from "../../shared/date/datePicker";
import ButtonBlue from "../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import XLSX from "xlsx";
import SelectEmployees from "../../shared/general/selectEmployees";
import {
  clearAttendance,
  deleteAttendance,
  getAttendanceById,
} from "../../../../actions/attendance";
import {
  deleteRequestTime
} from "../../../../actions/requestTime";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getPayrollSetting } from "../../../../actions/paytypes";
import TableTimeline from "../../shared/tableTimeline";
import DrawerRequestTime from "../../shared/tableTimeline/drawerRequestTime";
import Notification from "../../shared/general/Notification";
import DialogConfirmDelete from "../../shared/general/DialogConfirmDelete";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import { openNotificationAlert } from "../../../../actions/notificationAlert";
import DrawerShiftChange from "../../shared/tableTimeline/drawerShiftChange";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { getHolidayName, getUserFirstName, getUserLastName } from "../../../../utils/userData";

const StyledRoot = styled(Box)({});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  justifyContent: "space-evenly",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const IndividualPanel = (props) => {
  const { selectedCompany } = props;

  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });

  const [showDate, setShowDate] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [attendanceList, setAttendanceList] = useState([]);

  const { isFetching: fetchingAttendance, result: attendanceStore } = useSelector((state) => state.attendance);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  // const [selectedRequest, setSelectedRequest] = useState(null);

  const [drawerRequestConfig, setDrawerRequestConfig] = useState({
    open: false,
    values: null,
  });

  const [dialogConfirmDelete, setDialogConfirmDelete] = useState({
    open: false,
    values: null
  });

  const [notifyConfig, setNotifyConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  const [drawerShiftChangeConfig, setDrawerShiftChangeConfig] = useState({
    isOpen: false,
    data: {}
  })

  const [attendanceMenuConfig, setAttendanceMenuConfig] = useState({
    context: null,
    data: {}
  })

  const [DialogConfirmDeleteAttendance, setDialogConfirmDeleteAttendance] = useState({
    isOpen: false,
    data: {}
  });

  const handleSelectEmployee = (employee) => {
    dispatch(clearAttendance());
    setSelectedEmployee(employee);
    setAttendanceList([]);
  };

  const handleOnClickSearch = async () => {
    setShowDate(search);
    if (
      selectedEmployee &&
      dayjs(search.end).isSameOrAfter(search.start, "date")
    ) {
      let result = await getAttendance();

      if (result.status !== 200) {
        dispatch(
          openNotificationAlert({ message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}` })
        );
      }
    } else {
      dispatch(
        openNotificationAlert({
          type: "info",
          message: t("PleaseCheckDateAndEmployeeName"),
        })
      );
    }
  };

  const getAttendance = async () => {
    return await dispatch(
      getAttendanceById(
        {
          // start:
          //   dayjs(search.start).add(-7, "day") < dayjs("2023-01-01")
          //     ? dayjs("2023-01-01")
          //     : dayjs(search.start).add(-7, "day"),
          start: search.start,
          end: search.end,
        },
        selectedEmployee.idEmployees
      )
    );
  };

  const onExportExcel = () => {
    const elementList = [];
    for (let index = 0; index < attendanceList.length; index++) {
      if (
        dayjs(attendanceList[index].date).isBetween(
          dayjs(search.start),
          dayjs(search.end),
          "day",
          []
        )
      ) {
        const element = {
          id: attendanceList[index].employee.employeeID,
          firstname: getUserFirstName(attendanceList[index].employee),
          lastname: getUserLastName(attendanceList[index].employee),
          shiftGroupName: attendanceList[index].pattern.shiftGroupName,
          day: dayjs(attendanceList[index].date).format("dddd"),
          date: dayjs(attendanceList[index].date).format("DD/MM/YYYY"),
          timeIn: attendanceList[index].attendance.checkIn.length > 0
            ? attendanceList[index].attendance.checkIn[0] && attendanceList[index].attendance.checkIn[0].attendanceTextTime
            : "",
          timeOut: attendanceList[index].attendance.checkOut.length > 0
            ? attendanceList[index].attendance.checkOut[0] && attendanceList[index].attendance.checkOut[0].attendanceTextTime
            : "",
          status: (attendanceList[index].holiday || attendanceList[index].isCompensation) ? `${getHolidayName(attendanceList[index].holiday) ? t("AnnualHoliday") + " (" + getHolidayName(attendanceList[index].holiday) + ")" : t("AnnualHoliday")}` : attendanceList[index].pattern.isWorkingDay === 0 ? t("DayOff") : attendanceList[index].absent ? t("Absent") : t("Check_In"),
        };
        elementList.push(element);
      }
    }
    const workSheet = XLSX.utils.json_to_sheet(elementList);

    workSheet["A1"].v = t("EmployeeID");
    workSheet["B1"].v = t("FirstName");
    workSheet["C1"].v = t("LastName");
    workSheet["D1"].v = t("ShiftSchedule");
    workSheet["E1"].v = t("Unit.Days");
    workSheet["F1"].v = t("Date");
    workSheet["G1"].v = t("TimeIn");
    workSheet["H1"].v = t("TimeOut");
    workSheet["I1"].v = t("Status");

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, t("WorkingTimeReport"));
    XLSX.writeFile(
      workBook,
      `${t("WorkingTimeReport")} ${dayjs(search.start).format(
        "DD_MMMM_YYYY"
      )} - ${dayjs(search.end).format("DD_MMMM_YYYY")}.xlsx`
    );
  };

  const handleOpenAttendanceMenu = (event, row) => {
    event.preventDefault();
    setAttendanceMenuConfig(prev => ({
      ...prev,
      context: prev.context === null ? {
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      } : null,
      data: row,
    }))
  }

  const handleCloseAttendanceMenu = () => {
    setAttendanceMenuConfig(prev => ({
      ...prev,
      context: null
    }))
  }

  const handleDeleteAttendance = () => {
    // console.log(attendanceMenuConfig.data)
    setDialogConfirmDeleteAttendance(prev => ({
      ...prev,
      isOpen: true,
      data: attendanceMenuConfig.data
    }))
    setAttendanceMenuConfig(prev => ({
      ...prev,
      context: null
    }))
  }

  const handleCloseDialogConfirmDeleteAttendance = () => {
    setDialogConfirmDeleteAttendance(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleConfirmDeleteAttendance = async () => {

    const formData = {
      idAttendance: DialogConfirmDeleteAttendance.data.isFingerScan ? DialogConfirmDeleteAttendance.data.idFingerScan : DialogConfirmDeleteAttendance.data.idAttendance,
      isFingerScan: DialogConfirmDeleteAttendance.data.isFingerScan ? 1 : 0
    }

    const result = await dispatch(deleteAttendance(formData))

    if (result.status === 200) {
      dispatch(
        getAttendanceById(
          {
            // start:
            //   dayjs(search.start).add(-7, "day") < dayjs("2023-01-01")
            //     ? dayjs("2023-01-01")
            //     : dayjs(search.start).add(-7, "day"),
            start: search.start,
            end: search.end,
          },
          selectedEmployee.idEmployees
        )
      );
      dispatch(openNotificationAlert({
        type: "success",
        message: t('DataSaveSuccessful')
      }));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}`
      }));
    }

    handleCloseDialogConfirmDeleteAttendance();
  }

  const fetchAttendanceData = () => {
    dispatch(
      getAttendanceById(
        {
          // start:
          //   dayjs(search.start).add(-7, "day") < dayjs("2023-01-01")
          //     ? dayjs("2023-01-01")
          //     : dayjs(search.start).add(-7, "day"),
          start: search.start,
          end: search.end,
        },
        selectedEmployee.idEmployees
      )
    );
  }

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getPayrollSetting());
      setSelectedEmployee(null);
      setAttendanceList([]);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedEmployee && attendanceStore !== null) {
      const tempAttendance = [];

      attendanceStore.map((a, index) => {
        a.pattern.date = a.date;
        if (index !== 0 && index !== attendanceStore.length - 1) {
          if (a.holiday !== null) {
            a.pattern.isHoliday = 1;
          }

          if (a.isCompensation) {
            a.pattern.isCompensation = true;
          }

          tempAttendance.push({
            ...a,
            previousPattern:
              (attendanceStore[index - 1] &&
                attendanceStore[index - 1].pattern) ||
              null,
            previousLeave:
              (attendanceStore[index - 1] &&
                attendanceStore[index - 1].leave) ||
              [],
            nextPattern:
              (attendanceStore[index + 1] &&
                attendanceStore[index + 1].pattern) ||
              null,
            nextLeave:
              (attendanceStore[index + 1] &&
                attendanceStore[index + 1].leave) ||
              [],
          });
        }
      });

      setAttendanceList(tempAttendance);
    }
  }, [attendanceStore]);

  const handleCloseDrawerRequest = () => {
    setDrawerRequestConfig({
      ...drawerRequestConfig,
      open: false,
      isEdit: false,
    });
  };

  const handleCloseDialogConfirmDelete = () => {
    setDialogConfirmDelete({
      open: false,
      values: null
    })
  };

  const handleConfirmDelete = async () => {
    if (!dialogConfirmDelete.values || !dialogConfirmDelete.values.idRequestTime) {
      dispatch(openNotificationAlert({
        type: "error",
        message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}`
      }));
    } else {
      const result = await dispatch(deleteRequestTime(dialogConfirmDelete.values.idRequestTime));
      if (result.status === 200) {
        await dispatch(
          getAttendanceById(
            {
              // start:
              //   dayjs(search.start).add(-7, "day") < dayjs("2023-01-01")
              //     ? dayjs("2023-01-01")
              //     : dayjs(search.start).add(-7, "day"),
              start: search.start,
              end: search.end,
            },
            selectedEmployee.idEmployees
          )
        );
        dispatch(openNotificationAlert({
          type: "success",
          message: t('DataSaveSuccessful')
        }));
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}`
        }));
      }
    }

    handleCloseDrawerRequest();
    handleCloseDialogConfirmDelete();
  };

  return (
    <StyledRoot>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16 }}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("StartDate")}
            </Typography>
            <div className="search-date">
              <DatePickerCustom
                minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                inputFormat="DD/MM/YYYY"
                value={search.start}
                name="start"
                onChange={(newValue) => {
                  setSearch({ ...search, ["start"]: newValue });
                }}
                onlyDialog={true}
              />
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("EndDate")}
            </Typography>
            <div className="search-date">
              <DatePickerCustom
                minDate={search.start}
                inputFormat="DD/MM/YYYY"
                value={search.end}
                name="end"
                onChange={(newValue) => {
                  setSearch({ ...search, ["end"]: newValue });
                }}
                onlyDialog={true}
              />
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectEmployees
            handleChange={handleSelectEmployee}
            position={userProfile && userProfile.idCompany === 3}
            isShowTerminate={true}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearchButton>
            <div>
              <ButtonBlue
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={handleOnClickSearch}
              >
                {t("Search")}
              </ButtonBlue>
            </div>
            <div>
              <ButtonBlue
                onClick={onExportExcel}
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                disabled={
                  !(attendanceStore && attendanceStore.length > 0) ||
                  selectedEmployee === null
                }
              >
                {t("Download")}
              </ButtonBlue>
            </div>
          </StyledBoxSearchButton>
        </Grid>
      </Grid>

      {payrollSetting && selectedEmployee && (
        <TableTimeline
          attendanceList={attendanceList}
          searchDate={search}
          showDate={showDate}
          handleClickOpenAddNewTimeline={(row) => {
            setDrawerRequestConfig({
              ...drawerRequestConfig,
              open: true,
              values: row,
            });
          }}
          handleClickEdit={(row, overtimeValues) => {
            setDrawerRequestConfig({
              ...drawerRequestConfig,
              open: true,
              values: row,
              overtimeValues: overtimeValues,
              isEdit: true,
            });
          }}
          mode="admin"
          getAttendance={getAttendance}
          handleClickShiftChange={(row) => {
            setDrawerShiftChangeConfig(prev => ({
              ...prev,
              isOpen: true,
              data: row
            }))
          }}
          handleOpenAttendanceMenu={handleOpenAttendanceMenu}
          isFetchingAttendance={fetchingAttendance}
        // setSelectedRequest={setSelectedRequest}
        />
      )}

      {payrollSetting && selectedEmployee && attendanceList.length > 0 && (
        <DrawerRequestTime
          attendanceList={attendanceList}
          open={drawerRequestConfig.open}
          onClose={() => {
            handleCloseDrawerRequest()
          }}
          isEdit={drawerRequestConfig.isEdit}
          employee={selectedEmployee}
          values={drawerRequestConfig.values}
          overtimeValues={drawerRequestConfig.overtimeValues}
          payrollSetting={payrollSetting}
          mode="admin"
          searchDate={search}
          setNotify={setNotifyConfig}
          handleClickOpenConfirmDelete={
            (request) => {
              setDialogConfirmDelete({
                ...dialogConfirmDelete,
                open: true,
                values: request
              });
            }
          }
        />
      )}

      {dialogConfirmDelete && dialogConfirmDelete.open && dialogConfirmDelete.values &&
        <DialogConfirmDelete
          open={dialogConfirmDelete.open}
          handleClose={handleCloseDialogConfirmDelete}
          text={`${dialogConfirmDelete.values.startText} - ${dialogConfirmDelete.values.endText}`}
          label={`${dialogConfirmDelete.values.idRequestType === 2 ?
            `${t("OvertimeRequest")}` :
            `${t("WorkingTimeRequestList")}`
            }`}
          handleDelete={handleConfirmDelete}
        />
      }

      <DrawerShiftChange
        fetchAttendanceData={fetchAttendanceData}
        employee={selectedEmployee}
        drawerConfig={drawerShiftChangeConfig}
        onClose={() => {
          setDrawerShiftChangeConfig(prev => ({
            ...prev,
            isOpen: false,
          }))
        }}
      />

      <Menu
        open={attendanceMenuConfig.context !== null}
        onClose={handleCloseAttendanceMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          attendanceMenuConfig.context !== null
            ? { top: attendanceMenuConfig.context.mouseY, left: attendanceMenuConfig.context.mouseX }
            : undefined
        }
        contextMenu={{ pointerEvents: 'none' }}
      >
        <MenuItem onClick={handleDeleteAttendance}>
          <ListItemIcon>
            <DeleteForeverRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Delete")}</ListItemText>
        </MenuItem>
      </Menu>

      <DialogConfirmDelete
        open={DialogConfirmDeleteAttendance.isOpen}
        label="Check in/Check out"
        text={`${dayjs(DialogConfirmDeleteAttendance.data.attendanceTextDateTime).format("DD/MM/YYYY HH:mm")}`}
        handleClose={handleCloseDialogConfirmDeleteAttendance}
        handleDelete={handleConfirmDeleteAttendance}
      />

      <Notification notify={notifyConfig} setNotify={setNotifyConfig} />
    </StyledRoot>
  );
};

export default IndividualPanel;
