import DoneAll from "@mui/icons-material/DoneAll";
import {
  Avatar,
  Box,
  Typography,
  Divider,
  Grid,
  styled,
  TextField,
  Popper,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import ArticleIcon from "@mui/icons-material/Article";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";

import {
  getExitInterviewEmployeesStatus,
  getEmployeesRoleOneTwo,
} from "../../../../../actions/employee";
import DialogExitInterview from "./DialogExitInterview";
import { getExitInterviewAndAnswer } from "../../../../../actions/exitInterview";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import { useTranslation } from "react-i18next";

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const ExitInterviewStatus = (props) => {
  const { t, i18n } = useTranslation();

  const { selectedCompany } = props;

  const { result: employeesExitInterviewStatus } = useSelector(
    (state) => state.employeesExitInterviewStatus
  );
  const { result: employeesRoleOneTwo } = useSelector(
    (state) => state.employeesRoleOneTwo
  );
  const { result: exitInterviewAnswer } = useSelector(
    (state) => state.exitInterviewAnswer
  );
  const [inputSearch, setInputSearch] = useState(null);
  const [data, setData] = useState(null);

  const [openEducation, setOpenEducation] = useState(false);
  const [idEducation, setIdEducation] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [detail, setDetail] = useState(null);
  const [value, setValue] = useState(null);

  const statusShow = (row) => {
    const currentDate = dayjs();
    if (row.resultExitInterview === 3) {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", align: "center" }}
        >
          <ButtonBlue
            sx={{ backgroundColor: "#FFFF00", color: "#000000" }}
            variant="contained"
          >
            {t("PendingApproval")}
          </ButtonBlue>
        </div>
      );
    } else if (row.resultExitInterview === 1) {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", align: "center" }}
        >
          <ButtonBlue
            sx={{ backgroundColor: "#90EE90", color: "#000000" }}
            variant="contained"
          >
            {t("Approved")}
          </ButtonBlue>
        </div>
      );
    } else if (row.resultExitInterview === 2) {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", align: "center" }}
        >
          <ButtonBlue
            sx={{ backgroundColor: "#ff0000", color: "#000000" }}
            variant="contained"
          >
            {t("Rejected")}
          </ButtonBlue>
        </div>
      );
    }
  };

  const dispatch = useDispatch();

  const handleOpenEducation = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdEducation(idEdu);
    }
    setOpenEducation(true);
  };

  const handleClose = () => {
    setOpenEducation(false);
    setIdEducation(null);
  };

  const handleEachDetail = (row) => {
    let temp2 = exitInterviewAnswer.find(
      (value) => value.idEmployees === row.idEmployees
    );
    setDetail(temp2);
    setValue(row);
  };

  const handleDisable = (row) => {
    const currentDate = dayjs();
    if (
      row.estimatePerformanceApproved === 0 ||
      currentDate.diff(row.updateEstimatePerformance, "year") > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box textAlign="center" flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Assessor")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box textAlign="center" flexGrow={1}>
          <Typography>{getUserFullName(row.Manager)}</Typography>
          <Typography color="text.third" fontSize="14px">
            {row.Manager.email}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("Status")}`,
      headerTextAlign: "center",
      minWidth: "120px",
      width: "120px",
      cellRender: (row) => statusShow(row),
    },
    {
      name: `${t("Descriptions")}`,
      headerTextAlign: "center",
      minWidth: "100px",
      width: "100px",
      cellRender: (row) => (
        <div
          style={{ display: "flex", justifyContent: "center", align: "center" }}
        >
          <ButtonBlue
            variant="contained"
            onClick={() => {
              handleEachDetail(row);
              handleOpenEducation();
            }}
            disabled={handleDisable(row)}
          >
            <ArticleIcon />
          </ButtonBlue>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getExitInterviewEmployeesStatus({
          idCompany: selectedCompany.idCompany,
        })
      );
      dispatch(
        getEmployeesRoleOneTwo({ idCompany: selectedCompany.idCompany })
      );
      dispatch(
        getExitInterviewAndAnswer({ idCompany: selectedCompany.idCompany })
      );
      setData(employeesExitInterviewStatus);
      setSelectedEmployee(null);
    }
  }, [selectedCompany]);

  const handleClickSearch = () => {
    let temp = employeesExitInterviewStatus;
    let serchName = employeesRoleOneTwo.find(
      (e) => e.idEmployees === selectedEmployee
    );

    if (inputSearch !== null) {
      var dataDateSearch = employeesExitInterviewStatus.filter(
        (data) =>
          dayjs(data.endDate).get("month") ===
            dayjs(inputSearch).get("month") &&
          dayjs(data.endDate).get("year") === dayjs(inputSearch).get("year")
      );
      if (selectedEmployee !== null) {
        dataDateSearch.filter(
          (value) => value.idEmployees === serchName.idEmployees
        );
        setData(name);
      }
    } else if (selectedEmployee !== null) {
      var name = temp.filter(
        (value) => value.idEmployees === serchName.idEmployees
      );
      setData(name);
    } else {
      setData(temp);
    }
  };

  return (
    <Box marginTop={5}>
      <StyledHeadingPage>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">{t("ResignStatusEmp")}</Typography>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <StyledWrapFilter>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchDate")}
              </Typography>
              <div className="search-date">
                <DatePickerCustom
                  inputFormat="MMMM YYYY"
                  value={inputSearch}
                  name="start"
                  views={["year", "month"]}
                  openTo="month"
                  onChange={(newValue) => {
                    setInputSearch(newValue);
                  }}
                />
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={4} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchEmp")}
              </Typography>
              <div className="search-name">
                {employeesRoleOneTwo && (
                  <StyledAutocomplete
                    options={employeesRoleOneTwo}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedEmployee(newValue.idEmployees);
                      } else {
                        setSelectedEmployee(null);
                      }
                    }}
                    popupIcon={<i className="fa-light fa-chevron-down"></i>}
                    getOptionLabel={(option) =>
                      `${getUserFullName(option)}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder={t("SelectEmp")}
                      />
                    )}
                    value={
                      selectedEmployee
                        ? employeesRoleOneTwo.find(
                            (e) => e.idEmployees === selectedEmployee
                          ) || null
                        : null
                    }
                    PopperComponent={StyledPopper}
                    noOptionsText={t("NoData")}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={4} sm={4}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <div>
                  <ButtonBlue
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={() => {
                      handleClickSearch();
                    }}
                  >
                    {t("Search")}
                  </ButtonBlue>
                </div>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
      </StyledWrapFilter>
      <TableCustom columns={columns} rows={data ? data : []} />
      {openEducation && (
        <DialogExitInterview
          open={openEducation}
          handleClose={handleClose}
          idEdu={idEducation}
          type={typeDialog}
          value={value}
          detail={detail}
        />
      )}
    </Box>
  );
};

export default ExitInterviewStatus;
