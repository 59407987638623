import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import { Grid, Typography, FormControl, Select, MenuItem } from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import { updateUser } from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

const StyledRoot = styled("div")({
  width: 450,
  padding: 24,
  "& .field-label": {
    paddingBottom: 4,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};

const Payroll = (props) => {
  const { formData, handleChange } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("CalculateSalary")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isPayroll"
            value={formData.isPayroll}
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            <MenuItem value={1}>{t("Yes")}</MenuItem>
            <MenuItem value={0}>{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("IncomeUnderSection")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="incomeUnderSection"
            value={formData.incomeUnderSection}
            onChange={handleChange}
          >
            <MenuItem value={"40(1)"}>40(1)</MenuItem>
            <MenuItem value={"40(2)"}>40(2)</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
        {t("fixedTaxDeduction")}
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <TextFieldTheme
            id="fixTax"
            name="fixTax"
            value={formData.fixTax}
            fullWidth
            onChange={handleChange}
          />
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("CalculateIncomeTax")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isTax"
            value={formData.isTax}
            onChange={handleChange}
          >
            <MenuItem value={1}>{t("Yes")}</MenuItem>
            <MenuItem value={0}>{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("CalculateSSO")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isSso"
            value={formData.isSso}
            onChange={handleChange}
          >
            <MenuItem value={1}>{t("Yes")}</MenuItem>
            <MenuItem value={0}>{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("CalculateProvidentFund")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isPf"
            value={formData.isPf}
            onChange={handleChange}
          >
            <MenuItem value={1}>{t("Yes")}</MenuItem>
            <MenuItem value={0}>{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("CalculateOvertimePay")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isOT"
            value={formData.isOT}
            onChange={handleChange}
          >
            <MenuItem value={1}>{t("Yes")}</MenuItem>
            <MenuItem value={0}>{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("OvertimePayRate")} ({t("Times")})
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="xOT"
            value={formData.xOT}
            onChange={handleChange}
          >
            <MenuItem value={1}>{1}</MenuItem>
            <MenuItem value={1.5}>{1.5}</MenuItem>
            <MenuItem value={2}>{2}</MenuItem>
            <MenuItem value={3}>{3}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("HolidayPayRate")} ({t("Times")})
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="xWorkingHoliday"
            value={formData.xWorkingHoliday}
            onChange={handleChange}
          >
            <MenuItem value={1}>{1}</MenuItem>
            <MenuItem value={1.5}>{1.5}</MenuItem>
            <MenuItem value={2}>{2}</MenuItem>
            <MenuItem value={3}>{3}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("OvertimePayRateOnHolidays")} ({t("Times")})
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="xOTHoliday"
            value={formData.xOTHoliday}
            onChange={handleChange}
          >
            <MenuItem value={1}>{1}</MenuItem>
            <MenuItem value={1.5}>{1.5}</MenuItem>
            <MenuItem value={2}>{2}</MenuItem>
            <MenuItem value={3}>{3}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("CalculateShiftPay")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isShiftFee"
            value={formData.isShiftFee}
            onChange={handleChange}
          >
            <MenuItem value={1}>{t("Yes")}</MenuItem>
            <MenuItem value={0}>{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("CalculateDiligenceAllowance")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isDiligenceAllowance"
            value={formData.isDiligenceAllowance}
            onChange={handleChange}
          >
            <MenuItem value={1}>{t("Yes")}</MenuItem>
            <MenuItem value={0}>{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("recalculateSalary")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isReturnPayroll"
            value={formData.isReturnPayroll}
            onChange={handleChange}
          >
            <MenuItem value={1}>{t("Yes")}</MenuItem>
            <MenuItem value={0}>{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("amountCalculationMethod")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isForeignPaySpecify"
            value={formData.isForeignPaySpecify}
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem value="">{t("notSpecified")}</MenuItem>
            <MenuItem value={1}>{t("specifyQuantity")}</MenuItem>
            <MenuItem value={0}>{t("percentage")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">
          {t("amountCalculationMethod")} ?
        </StyledContentLabel>
        <StyledFormControl fullWidth>
          <TextFieldTheme
            id="foreignPayTH"
            name="foreignPayTH"
            value={formData.foreignPayTH}
            fullWidth
            onChange={handleChange}
          />
        </StyledFormControl>
      </Grid>
    </Grid>
  );
};

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (open) {
      setFormData({
        idEmployees: employeeProfile.idEmployees,
        isPayroll: employeeProfile.isPayroll,
        isPf: employeeProfile.isPf,
        isTax: employeeProfile.isTax,
        isSelfTaxPaid: employeeProfile.isSelfTaxPaid,
        isSso: employeeProfile.isSso,
        incomeUnderSection: employeeProfile.incomeUnderSection,
        fixTax: employeeProfile.fixTax,
        isDiligenceAllowance: employeeProfile.isDiligenceAllowance,
        isForeignPaySpecify:
          employeeProfile.isForeignPaySpecify === null
            ? ""
            : employeeProfile.isForeignPaySpecify,
        foreignPayTH: employeeProfile.foreignPayTH,
        isReturnPayroll: employeeProfile.isReturnPayroll,
        isOT: employeeProfile.isOT,
        isShiftFee: employeeProfile.isShiftFee,
        xOT: employeeProfile.xOT,
        xWorkingHoliday: employeeProfile.xWorkingHoliday,
        xOTHoliday: employeeProfile.xOTHoliday,
      });
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    let data = { ...formData };
    data.UpdateBy = userProfile.idEmployees;

    const result = await dispatch(updateUser(data));
    if (result) {
      handleCloseDialog();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  return (
    <DrawerCustom
      title={`${t("SettingSalary")}`}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Payroll formData={formData} handleChange={handleChange} />
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
