import dayjs from "dayjs";
import { httpClient } from "./httpClient";

const getAllExpense = () => {
  return httpClient.get(`expense`);
};

const getExpenseGeneralById = (idExpense) => {
  return httpClient.get(`expenseById/general/${idExpense}`);
};

const addExpenseGeneral = (formData) => {
  return httpClient.post(`expense/general`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateFileExpense = (formData) => {
  return httpClient.put(`expense/general/updatefile`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseGeneral = (formData) => {
  return httpClient.put(`expense/general/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getExpenseAllowanceById = (idExpense) => {
  return httpClient.get(`expenseById/allowance/${idExpense}`);
};

const addExpenseAllowance = (formData) => {
  return httpClient.post(`expense/allowance`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseAllowance = (formData) => {
  return httpClient.put(`expense/allowance/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getExpenseMileageById = (idExpense) => {
  return httpClient.get(`expenseById/mileage/${idExpense}`);
};

const addExpenseMileage = (formData) => {
  return httpClient.post(`expense/mileage`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseMileage = (formData) => {
  return httpClient.put(`expense/mileage/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getExpenseWelfareById = (idExpense) => {
  return httpClient.get(`expenseById/welfare/${idExpense}`);
};

const addExpenseWelfare = (formData) => {
  return httpClient.post(`expense/welfare`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseWelfare = (formData) => {
  return httpClient.put(`expense/welfare/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getExpenseFlexCoinById = (idExpense) => {
  return httpClient.get(`expenseById/flexCoin/${idExpense}`);
};

const addExpenseFlexCoin = (formData) => {
  return httpClient.post(`expense/flexCoin`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseFlexCoin = (formData) => {
  return httpClient.put(`expense/flexCoin/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteExpenseGeneral = (data) => {
  return httpClient.put(`expense/general/delete`, data);
};

const deleteExpenseMileage = (data) => {
  return httpClient.put(`expense/mileage/delete`, data);
};

const deleteExpenseAllowance = (data) => {
  return httpClient.put(`expense/allowance/delete`, data);
};

const deleteExpenseWelfare = (data) => {
  return httpClient.put(`expense/welfare/delete`, data);
};

const deleteExpenseFlexCoin = (data) => {
  return httpClient.put(`expense/flexCoin/delete`, data);
};

const getAllExpenseByIdCompany = (idCompany) => {
  return httpClient.get(`expense/accountant/all?idCompany=${idCompany}`);
};

const updateStatusExpense = (idExpense, data) => {
  return httpClient.put(`expense/status/${idExpense}/update`, data);
};

const getHistoryExpenseByIdCompany = (idCompany) => {
  return httpClient.get(`expense/accountant/history?idCompany=${idCompany}`);
};

const getFilteredHistoryExpenseAccountant = (data) => {
  return httpClient.get(
    `expense/accountant/history/filter?idCompany=${data.idCompany}&idEmployee=${data.idEmployee}&expenseType=${data.expenseType}&date=${data.date}`
  );
};

const getAllExpenseByIdApprover = () => {
  return httpClient.get(`expense/manager/all`);
};

const getHistoryExpenseForApprover = () => {
  return httpClient.get(`expense/approver/history`);
};

const getFilteredHistoryExpenseApprover = (data) => {
  return httpClient.get(
    `expense/approver/history/filter?idEmployee=${data.idEmployee}&expenseType=${data.expenseType}&date=${data.date}`
  );
};

const getAllExpenseByIdReviewer = () => {
  return httpClient.get(`expense/reviewer/all`);
};

const addExpenseAdvance = (formData) => {
  return httpClient.post(`expense/advance`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseAdvance = (formData) => {
  return httpClient.put(`expense/advance/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteExpenseAdvance = (data) => {
  return httpClient.put(`expense/advance/delete`, data);
};

const getExpenseAdvanceById = (idExpense) => {
  return httpClient.get(`expenseById/advance/${idExpense}`);
};

const getExpensePaymentTypes = () => {
  return httpClient.get(`expense/paymentTypes`);
};

const getExpenseReturnAdvanceTypes = () => {
  return httpClient.get(`expense/returnAdvanceTypes`);
};

const getExpenseTypes = () => {
  return httpClient.get(`expense/types`);
};

const getExpenseAdvances = () => {
  return httpClient.get(`expense/advances`);
};

const addExpenseClearAdvance = (formData) => {
  return httpClient.post(`expense/clearAdvance`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseClearAdvance = (formData) => {
  return httpClient.put(`expense/clearAdvance/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteExpenseClearAdvance = (data) => {
  return httpClient.put(`expense/clearAdvance/delete`, data);
};

const getExpenseClearAdvanceById = (idExpense) => {
  return httpClient.get(`expenseById/clearAdvance/${idExpense}`);
};

export default {
  getAllExpense,
  getExpenseGeneralById,
  addExpenseGeneral,
  updateFileExpense,
  updateStatusExpense,
  updateExpenseGeneral,
  getExpenseAllowanceById,
  addExpenseAllowance,
  updateExpenseAllowance,
  getExpenseMileageById,
  addExpenseMileage,
  updateExpenseMileage,
  getExpenseWelfareById,
  addExpenseWelfare,
  updateExpenseWelfare,
  getExpenseFlexCoinById,
  addExpenseFlexCoin,
  updateExpenseFlexCoin,
  deleteExpenseGeneral,
  deleteExpenseMileage,
  deleteExpenseAllowance,
  deleteExpenseWelfare,
  deleteExpenseFlexCoin,
  getAllExpenseByIdCompany,
  getHistoryExpenseByIdCompany,
  getFilteredHistoryExpenseAccountant,
  getAllExpenseByIdApprover,
  getHistoryExpenseForApprover,
  getFilteredHistoryExpenseApprover,
  getAllExpenseByIdReviewer,
  addExpenseAdvance,
  updateExpenseAdvance,
  deleteExpenseAdvance,
  getExpenseAdvanceById,
  getExpensePaymentTypes,
  getExpenseReturnAdvanceTypes,
  getExpenseTypes,
  getExpenseAdvances,
  addExpenseClearAdvance,
  updateExpenseClearAdvance,
  deleteExpenseClearAdvance,
  getExpenseClearAdvanceById,
};
