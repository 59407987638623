import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import utils from '../../../../../utils';

export const PND91PdfFile = async (type, year, data) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const page1 = pages[0];
    const page2 = pages[1];
    const page3 = pages[2];

    if (year) {
        page1.drawText(`${year}`, { x: 130, y: 768, size: 17, fontWeight: 600 });
    }

    const item = data.selectedEmployeeDetail[0];
    // const pfItem = data.providentFundResult[0];
    const pfItem = data.selectedEmployeeDetail[0].SUM_PF;
    const taxDeducItem = data.taxDeductionResult[0];
    //id card
    let taxID;
    if (item.personalID) {
        taxID = [
            item.personalID[0],
            item.personalID.slice(1, 5),
            item.personalID.slice(5, 10),
            item.personalID.slice(10, 12),
            item.personalID[12]
        ];
        page1.drawText(`${taxID[0]}`, { x: 112, y: 733, size: 11 });
        page1.drawText(`${taxID[1][0]}  ${taxID[1][1]}  ${taxID[1][2]}  ${taxID[1][3]}`, { x: 130, y: 733, size: 11 });
        page1.drawText(`${taxID[2][0]}  ${taxID[2][1]}  ${taxID[2][2]}  ${taxID[2][3]}  ${taxID[2][4]}`, { x: 184, y: 733, size: 11 });
        page1.drawText(`${taxID[3][0]}  ${taxID[3][1]}`, { x: 250, y: 733, size: 11 });
        page1.drawText(`${taxID[4]}`, { x: 280, y: 733, size: 11 });
    }
    //birthdate
    {
        (item && item.birthday) &&
            page1.drawText(`${dayjs(item.birthday).format("D")}`, { x: 175, y: 708, size: 12, font: font });
        page1.drawText(`${dayjs(item.birthday).format("MMMM")}`, { x: 198, y: 708, size: 12, font: font });
        page1.drawText(`${dayjs(item.birthday).format("YYYY")}`, { x: 245, y: 708, size: 12, font: font });
    }
    //first name
    if (item.title_TH && item.firstname_TH) {
        page1.drawText(`${item.title_TH} ${item.firstname_TH}`, { x: 50, y: 696, size: 12, font: font });
    }
    //last name
    if (item && item.lastname_TH) {
        page1.drawText(`${item.lastname_TH}`, { x: 65, y: 648, size: 12, font: font });
    }
    //address
    { (item && item.village) && page1.drawText(`${item.village}`, { x: 290, y: 630, size: 12, font: font }); }
    { (item && item.houseNo) && page1.drawText(`${item.houseNo}`, { x: 49, y: 612, size: 11, font: font }); }
    { (item && item.villageNo) && page1.drawText(`${item.villageNo}`, { x: 95, y: 612, size: 12, font: font }); }
    { (item && item.alley) && page1.drawText(`${item.alley}`, { x: 157, y: 613, size: 12, font: font }); }
    { (item && item.road) && page1.drawText(`${item.road}`, { x: 55, y: 592, size: 12, font: font }); }
    { (item && item.subDistrict) && page1.drawText(`${item.subDistrict}`, { x: 220, y: 592, size: 12, font: font }); }
    { (item && item.district) && page1.drawText(`${item.district}`, { x: 80, y: 572, size: 12, font: font }); }
    { (item && item.province) && page1.drawText(`${item.province}`, { x: 216, y: 572, size: 12, font: font }); }

    //PIN code
    if (item && item.areaCode) {
        page1.drawText(`${item.areaCode[0]}`, { x: 80, y: 552, size: 12, font: font });
        page1.drawText(`${item.areaCode[1]}`, { x: 91, y: 552, size: 12, font: font });
        page1.drawText(`${item.areaCode[2]}`, { x: 102, y: 552, size: 12, font: font });
        page1.drawText(`${item.areaCode[3]}`, { x: 114, y: 552, size: 12, font: font });
        page1.drawText(`${item.areaCode[4]}`, { x: 125, y: 552, size: 12, font: font });
    }

    page1.drawText(`X`, { x: 76, y: 523, size: 8 });

    //status
    page1.drawText(`X`, { x: (item && item.maritalStatus === "หม้าย") ? 200 : (item && item.maritalStatus) === "สมรส" ? 142 : 95, y: 496, size: 8 });

    if (item && item.personalID) {
        page2.drawText(`${taxID[0]}`, { x: 102, y: 805, size: 11 });
        page2.drawText(`${taxID[1][0]}  ${taxID[1][1]}  ${taxID[1][2]}  ${taxID[1][3]}`, { x: 120, y: 805, size: 11 });
        page2.drawText(`${taxID[2][0]}  ${taxID[2][1]}  ${taxID[2][2]}  ${taxID[2][3]}  ${taxID[2][4]}`, { x: 174, y: 805, size: 11 });
        page2.drawText(`${taxID[3][0]}  ${taxID[3][1]}`, { x: 240, y: 805, size: 11 });
        page2.drawText(`${taxID[4]}`, { x: 270, y: 805, size: 11 });
    }

    // Function to format salary without dot
    function formatAndDrawText(page, salaryArray, coordinates) {
        let formattedValue = salaryArray.split('.');
        let salaryWithOutDot = "";

        formattedValue[0].split("").map((n, index) => {
            if (index === 0) {
                salaryWithOutDot += n;
            } else {
                salaryWithOutDot += `  ${n}`;
            }
        });

        page.drawText(`${salaryWithOutDot}   ${formattedValue.length > 1 ? `${formattedValue[1][0]} ${formattedValue[1][1] || "0"}` : "0  0"}`, {
            x: coordinates.x - ((formattedValue[0].length - 1) * 11),
            y: coordinates.y,
            size: coordinates.size
        })
    }

    function formatPersonalID(page, personalID, coordinates) {
        if (personalID) {
            let number = personalID.split('').join('   ');
            page.drawText(`${number}`, {
                x: coordinates.x,
                y: coordinates.y,
                size: coordinates.size
            })
        } else {
            page.drawText("", {
                x: coordinates.x,
                y: coordinates.y,
                size: coordinates.size
            })
        }
    }

    let workMonth = 12;
    if (dayjs(item.hiringDate).format("YYYY") === `${year - 543}`) {
        workMonth = 12 - parseInt(dayjs(item.hiringDate).format("M")) + 1;
    }

    // let AllSalary = `${item.salary * workMonth}`;
    // let HalfSalary = `${(item.salary * workMonth) / 2}`;
    // let pfValue = pfItem > 0 ? (pfItem - 10000).toFixed(2) : "0.00";
    // let AllSalaryAfterDeduction = ((item.salary * workMonth) - pfValue).toFixed(2);
    // let AllSalaryAfter50deduction = (AllSalaryAfterDeduction / 2).toFixed(2);

    let HundredThousand = 100000
    // let AllSalary = `${(item.SUM_salary || 0) + (item.totalShiftPay || 0) + (item.totalOT || 0) + (item.totalAdditions || 0)}`;
    let AllSalary = item.SUM_AlltaxValue ? item.SUM_AlltaxValue + (item.beforeAccumulateEarnings ? item.beforeAccumulateEarnings : 0) : 0;
    let HalfSalary = AllSalary / 2 > HundredThousand ? HundredThousand.toFixed(2) : (AllSalary / 2).toFixed(2);
    let pfValue = pfItem > 0 ? (pfItem + (item.beforeAccumulatePF || 0)).toFixed(2) : "0.00";
    let AllSalaryAfterDeduction = (AllSalary - pfValue).toFixed(2);
    let HalfSalaryAfterDeduction = (AllSalaryAfterDeduction / 2) > HundredThousand.toFixed(2) ?
        HundredThousand.toFixed(2) :
        (AllSalaryAfterDeduction / 2).toFixed(2);

    // ข1
    {
        pfItem > 0 ?
            formatAndDrawText(page2, pfValue, { x: 523, y: 282, size: 10 }) :
            page2.drawText(`0   0  0`, { x: 523, y: 282, size: 10 })
    }
    page2.drawText(`0   0  0`, { x: 523, y: 265, size: 10 });
    page2.drawText(`0   0  0`, { x: 523, y: 250, size: 10 });
    page2.drawText(`0   0  0`, { x: 523, y: 218, size: 10 });
    page2.drawText(`0   0  0`, { x: 523, y: 170, size: 10 });
    {
        pfItem > 0 ?
            formatAndDrawText(page2, pfValue, { x: 523, y: 155, size: 10 }) :
            page2.drawText(`0   0  0`, { x: 523, y: 155, size: 10 })
    }


    //page3
    page3.drawText(`X`, { x: 294, y: 807, size: 10 });
    //id card
    if (item.personalID) {
        page3.drawText(`${taxID[0]}`, { x: 111, y: 771, size: 10 });
        page3.drawText(`${taxID[1][0]}`, { x: 130, y: 771, size: 10 });
        page3.drawText(`${taxID[1][1]}`, { x: 141, y: 771, size: 10 });
        page3.drawText(`${taxID[1][2]}`, { x: 154, y: 771, size: 10 });
        page3.drawText(`${taxID[1][3]}`, { x: 165, y: 771, size: 10 });
        page3.drawText(`${taxID[2][0]}`, { x: 183, y: 771, size: 10 });
        page3.drawText(`${taxID[2][1]}`, { x: 195, y: 771, size: 10 });
        page3.drawText(`${taxID[2][2]}`, { x: 207, y: 771, size: 10 });
        page3.drawText(`${taxID[2][3]}`, { x: 219, y: 771, size: 10 });
        page3.drawText(`${taxID[2][4]}`, { x: 231, y: 771, size: 10 });
        page3.drawText(`${taxID[3][0]}`, { x: 250, y: 771, size: 10 });
        page3.drawText(`${taxID[3][1]}`, { x: 261, y: 771, size: 10 });
        page3.drawText(`${taxID[4]}`, { x: 280, y: 771, size: 10 });
    }

    if (item) {
        page3.drawText(`${item.firstname_TH}`, { x: 90, y: 760, size: 10, font: font });
        page3.drawText(`${item.lastname_TH}`, { x: 90, y: 740, size: 10, font: font });
    }

    let defaultValue = taxDeducItem && taxDeducItem.childDeduction > 0 ? 120000 : 60000;
    //Topic 1.
    formatAndDrawText(page3, defaultValue.toFixed(2), { x: 550, y: 720, size: 9 });

    //Topic 2.
    page3.drawText(`0   0 0`, { x: 546, y: 708, size: 9 })

    //Topic 3.
    {
        taxDeducItem && taxDeducItem.childDeduction > 0 &&
            page3.drawText(`${taxDeducItem.childDeduction}`, { x: 136, y: 694, size: 9 });
    }

    let childSupport = taxDeducItem && taxDeducItem.childDeduction > 0 ? taxDeducItem.childDeduction * 30000 : 0.00;

    {
        taxDeducItem && taxDeducItem.childDeduction > 0 ?
            formatAndDrawText(page3, childSupport.toFixed(2), { x: 550, y: 695, size: 9 }) :
            page3.drawText(`0   0 0`, { x: 546, y: 695, size: 9 })
    }

    page3.drawText(`0   0 0`, { x: 546, y: 635, size: 9 });

    //Topic 4.
    formatPersonalID(page3, taxDeducItem && taxDeducItem.childSupport_empFather ? taxDeducItem.childSupport_empFather : null, { x: 39, y: 563, size: 9 });
    formatPersonalID(page3, taxDeducItem && taxDeducItem.childSupport_empMother ? taxDeducItem.childSupport_empMother : null, { x: 39, y: 528, size: 9 });
    formatPersonalID(page3, taxDeducItem && taxDeducItem.childSupport_mateFather ? taxDeducItem.childSupport_mateFather : null, { x: 39, y: 492, size: 9 });
    formatPersonalID(page3, taxDeducItem && taxDeducItem.childSupport_mateMother ? taxDeducItem.childSupport_mateMother : null, { x: 39, y: 455, size: 9 });

    page3.drawText(`0   0 0`, { x: 546, y: 563, size: 9 });
    page3.drawText(`0   0 0`, { x: 546, y: 528, size: 9 });
    page3.drawText(`0   0 0`, { x: 546, y: 492, size: 9 });
    page3.drawText(`0   0 0`, { x: 546, y: 455, size: 9 });

    //Topic 5.
    page3.drawText(`0   0 0`, { x: 546, y: 431, size: 9 });

    //Topic 6.
    let totalHealthInsurance = taxDeducItem ?
        (taxDeducItem.healthInsurance_empFather || 0) +
        (taxDeducItem.healthInsurance_empMother || 0) +
        (taxDeducItem.healthInsurance_mateFather || 0) +
        (taxDeducItem.healthInsurance_mateMother || 0) : 0.00;

    {
        totalHealthInsurance > 0 ?
            formatAndDrawText(page3, totalHealthInsurance.toFixed(2), { x: 550, y: 420, size: 9 }) :
            page3.drawText(`0   0 0`, { x: 546, y: 420, size: 9 })
    }

    formatPersonalID(page3, taxDeducItem && taxDeducItem.childSupport_empFather ? taxDeducItem.childSupport_empFather : null, { x: 39, y: 395, size: 9 });
    formatPersonalID(page3, taxDeducItem && taxDeducItem.childSupport_empMother ? taxDeducItem.childSupport_empMother : null, { x: 39, y: 360, size: 9 });
    formatPersonalID(page3, taxDeducItem && taxDeducItem.childSupport_mateFather ? taxDeducItem.childSupport_mateFather : null, { x: 248, y: 395, size: 9 });
    formatPersonalID(page3, taxDeducItem && taxDeducItem.childSupport_mateMother ? taxDeducItem.childSupport_mateMother : null, { x: 248, y: 360, size: 9 });

    //Topic 7.
    {
        taxDeducItem && taxDeducItem.lifeInsurance > 0 ?
            formatAndDrawText(page3, taxDeducItem.lifeInsurance.toFixed(2), { x: 550, y: 336, size: 9 }) :
            page3.drawText(`0   0 0`, { x: 546, y: 336, size: 9 })
    }
    {
        taxDeducItem && taxDeducItem.healthInsurance > 0 ?
            formatAndDrawText(page3, taxDeducItem.healthInsurance.toFixed(2), { x: 550, y: 323, size: 9 }) :
            page3.drawText(`0   0 0`, { x: 546, y: 323, size: 9 })
    }
    {
        taxDeducItem && taxDeducItem.empInsure > 0 ?
            formatAndDrawText(page3, taxDeducItem.empInsure.toFixed(2), { x: 550, y: 312, size: 9 }) :
            page3.drawText(`0   0 0`, { x: 546, y: 312, size: 9 })
    }

    //Topic 8
    {
        taxDeducItem && taxDeducItem.rmfFund > 0 ?
            formatAndDrawText(page3, taxDeducItem.rmfFund.toFixed(2), { x: 550, y: 300, size: 9 }) :
            page3.drawText(`0   0 0`, { x: 546, y: 300, size: 9 })
    }
    //Topic 9
    page3.drawText(`0   0 0`, { x: 546, y: 287, size: 9 });
    //Topic 10
    page3.drawText(`0   0 0`, { x: 546, y: 275, size: 9 });
    //Topic 11
    page3.drawText(`0   0 0`, { x: 546, y: 263, size: 9 });
    //Topic 12
    // {
    //     taxDeducItem && taxDeducItem.loanInterestHome > 0 ?
    //         formatAndDrawText(page3, taxDeducItem.loanInterestHome.toFixed(2), { x: 550, y: 252, size: 9 }) :
    //         page3.drawText(`0   0 0`, { x: 546, y: 252, size: 9 })
    // }
    //Topic 13
    page3.drawText(`0   0 0`, { x: 546, y: 240, size: 9 });
    //Topic 14
    page3.drawText(`0   0 0`, { x: 546, y: 228, size: 9 });
    //Topic 15
    page3.drawText(`0   0 0`, { x: 546, y: 216, size: 9 });
    //Topic 16
    // {
    //     taxDeducItem && taxDeducItem.maternityExpenses && taxDeducItem.orderOfPregnancy > 0 ?
    //         formatAndDrawText(page3, (taxDeducItem.maternityExpenses * taxDeducItem.orderOfPregnancy).toFixed(2), { x: 550, y: 204, size: 9 }) :
    //         page3.drawText(`0   0 0`, { x: 546, y: 204, size: 9 })
    // }
    //Topic 17
    page3.drawText(`0   0 0`, { x: 546, y: 191, size: 9 });
    //Topic 18
    page3.drawText(`0   0 0`, { x: 546, y: 168, size: 9 });

    let totalPage3 = parseFloat(
        defaultValue +
        childSupport +
        totalHealthInsurance +
        (taxDeducItem && taxDeducItem.lifeInsurance || 0) +
        (taxDeducItem && taxDeducItem.healthInsurance || 0) +
        (taxDeducItem && taxDeducItem.empInsure || 0) +
        (taxDeducItem && taxDeducItem.rmfFund || 0) +
        (taxDeducItem && taxDeducItem.loanInterestHome || 0) +
        (taxDeducItem && taxDeducItem.maternityExpenses || 0)
    );

    //Topic 19
    formatAndDrawText(page3, totalPage3.toFixed(2), { x: 550, y: 155, size: 9 });
    // page3.drawText(`0   0 0`, { x: 546, y: 155, size: 9 });


    // page 2
    //1
    formatAndDrawText(page2, AllSalary.toFixed(2), { x: 523, y: 741, size: 10 });
    //2
    {
        pfItem > 0 ?
            formatAndDrawText(page2, pfValue, { x: 523, y: 726, size: 10 }) :
            page2.drawText(`0   0  0`, { x: 523, y: 726, size: 10 })
    }
    //3
    formatAndDrawText(page2, pfItem > 0 ? AllSalaryAfterDeduction : AllSalary.toFixed(2), { x: 523, y: 712, size: 10 });
    //4
    formatAndDrawText(page2, pfItem > 0 ? HalfSalaryAfterDeduction : HalfSalary, { x: 523, y: 680, size: 10 });
    //5
    let fifthSummary = pfItem > 0 ? AllSalaryAfterDeduction - HalfSalaryAfterDeduction : AllSalary.toFixed(2) - HalfSalary;
    formatAndDrawText(page2, fifthSummary.toFixed(2), { x: 523, y: 665, size: 10 });
    //6
    formatAndDrawText(page2, totalPage3.toFixed(2), { x: 523, y: 635, size: 10 });

    //7
    let AfterTotalPage3 = fifthSummary - totalPage3;
    formatAndDrawText(page2, AfterTotalPage3.toFixed(2), { x: 523, y: 620, size: 10 });

    //8
    const etcDonation = taxDeducItem && taxDeducItem.etcDonation || 0;
    const eduDonation = taxDeducItem && taxDeducItem.eduDonation || 0;

    const totalDonation = (etcDonation + eduDonation) * 2;
    if (taxDeducItem && (taxDeducItem.etcDonation > 0 || taxDeducItem.eduDonation > 0)) {
        formatAndDrawText(page2, totalDonation.toFixed(2), { x: 523, y: 590, size: 10 });
    } else {
        page2.drawText(`0   0  0`, { x: 523, y: 590, size: 10 });
    }

    //9
    formatAndDrawText(page2, (AfterTotalPage3 - totalDonation).toFixed(2), { x: 523, y: 575, size: 10 });

    //10
    page2.drawText(`0   0  0`, { x: 523, y: 560, size: 10 });

    //11
    formatAndDrawText(page2, (AfterTotalPage3 - totalDonation).toFixed(2), { x: 523, y: 545, size: 10 });

    //12
    let net = AfterTotalPage3 - totalDonation

    function calculateNet() {
        if (net <= 150000) {
            return 0
        } else if (net > 150000 && net <= 300000) {
            return ((net - 150000) * 0.05) + 0
        } else if (net > 300000 && net <= 500000) {
            return ((net - 300000) * 0.10) + 7500
        } else if (net > 500000 && net <= 750000) {
            return ((net - 500000) * 0.15) + 27500
        } else if (net > 750000 && net <= 1000000) {
            return ((net - 750000) * 0.20) + 65000
        } else if (net > 1000000 && net <= 2000000) {
            return ((net - 1000000) * 0.25) + 115000
        } else if (net > 2000000 && net <= 5000000) {
            return ((net - 2000000) * 0.30) + 365000
        } else if (net > 5000000) {
            return ((net - 5000000) * 0.35) + 1265000
        }
    }

    // function calculateNet() {
    //     let tax = 0;
    //     if (net <= 150000) {
    //         tax = 0;
    //     } else if (net <= 300000) {
    //         tax = (net - 150000) * 0.05;
    //     } else if (net <= 500000) {
    //         tax = (300000 - 150000) * 0.05 + (net - 300000) * 0.1;
    //     } else if (net <= 750000) {
    //         tax = (300000 - 150000) * 0.05 + (500000 - 300000) * 0.1 + (net - 500000) * 0.15;
    //     } else if (net <= 1000000) {
    //         tax = (300000 - 150000) * 0.05 + (500000 - 300000) * 0.1 + (750000 - 500000) * 0.15 + (net - 750000) * 0.2;
    //     } else if (net <= 2000000) {
    //         tax = (300000 - 150000) * 0.05 + (500000 - 300000) * 0.1 + (750000 - 500000) * 0.15 + (1000000 - 750000) * 0.2 + (net - 1000000) * 0.25;
    //     } else if (net <= 5000000) {
    //         tax = (300000 - 150000) * 0.05 + (500000 - 300000) * 0.1 + (750000 - 500000) * 0.15 + (1000000 - 750000) * 0.2 + (2000000 - 1000000) * 0.25 + (net - 2000000) * 0.3;
    //     } else {
    //         tax = (300000 - 150000) * 0.05 + (500000 - 300000) * 0.1 + (750000 - 500000) * 0.15 + (1000000 - 750000) * 0.2 + (2000000 - 1000000) * 0.25 + (5000000 - 2000000) * 0.3 + (net - 5000000) * 0.35;
    //     }
    //     return tax;
    // }

    let netTax = calculateNet();
    // formatAndDrawText(page2, netTax.toFixed(2), { x: 523, y: 530, size: 10 });
    // page2.drawText(`0   0  0`, { x: 523, y: 530, size: 10 });

    //13
    let deduceTax = item.SUM_TaxValue > 0 ? item.SUM_TaxValue + (item.beforeAccumulateTax ? item.beforeAccumulateTax : 0) : 0;
    formatAndDrawText(page2, deduceTax.toFixed(2), { x: 523, y: 515, size: 10 });
    //14
    let resultFrom13 = netTax - deduceTax;
    let convertValueToPositive = deduceTax - netTax;
    // page2.drawText(`X`, { x: resultFrom13 < 0 ? 162 : 95, y: 500, size: 10 });
    // formatAndDrawText(page2, resultFrom13 < 0 ? convertValueToPositive.toFixed(2) : resultFrom13.toFixed(2), { x: 523, y: 500, size: 10 });
    //15
    // page2.drawText(`0   0  0`, { x: 523, y: 457, size: 10 });
    //16
    // page2.drawText(`0   0  0`, { x: 523, y: 428, size: 10 });
    //17
    // page2.drawText(`0   0  0`, { x: 523, y: 398, size: 10 });
    //18
    // page2.drawText(`X`, { x: resultFrom13 < 0 ? 137 : 60, y: 382, size: 10 });
    // formatAndDrawText(page2, resultFrom13 < 0 ? convertValueToPositive.toFixed(2) : resultFrom13.toFixed(2), { x: 523, y: 383, size: 10 });
    //19
    // page2.drawText(`0   0  0`, { x: 523, y: 368, size: 10 });
    //20
    // page2.drawText(`X`, { x: resultFrom13 < 0 ? 146 : 76, y: 352, size: 10 });
    // formatAndDrawText(page2, resultFrom13 < 0 ? convertValueToPositive.toFixed(2) : resultFrom13.toFixed(2), { x: 523, y: 353, size: 10 });

    // formatAndDrawText(page1, resultFrom13 < 0 ? convertValueToPositive.toFixed(2) : resultFrom13.toFixed(2), { x: resultFrom13 < 0 ? 520 : 210, y: 431, size: 11 });

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}