import React, { Fragment, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  FormControl,
  Grid,
  ListSubheader,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  Divider,
  Link,
} from "@mui/material";
import DrawerCustom from "../general/Drawer";
import dayjs from "dayjs";
import {
  addRequestTime,
  getRequestReason,
  putRequestTime,
} from "../../../../actions/requestTime";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../date/datePicker";
import TextFieldTheme from "../general/TextFieldTheme";
import TimePicker from "../general/TimePicker";
import ButtonBlue from "../general/ButtonBlue";
import {
  calculatorOvertime,
  calculatorOvertimeByWorkingHours,
} from "./calculatorOvertime";
import { getAttendanceById } from "../../../../actions/attendance";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import { Delete } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import calculateService from "../../../../services/calculate.service";
import fileService from "../../../../services/file.service";
import DragDrop from "../../shared/general/DragDrop";
import PaperClipIcon from "../../assets/paper-clip.png";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const StyledRoot = styled(Box)({
  width: 350,
  padding: 24,
  "@media only screen and (max-width: 375px)": {
    width: 320,
  },
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiList-root": {
    maxHeight: 300,
    overflow: "auto",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280,
    },
  },
};

const StyledFooter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  // "& .cancel": {
  //   marginRight: 8,
  // },
});

const DrawerRequestTime = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    open,
    onClose,
    values,
    payrollSetting,
    mode,
    isEdit,
    employee,
    searchDate,
    overtimeValues,
    setNotify,
    attendanceList,
    handleClickOpenConfirmDelete,
  } = props;

  const useHookForm = useForm({
    defaultValues: {
      file: [],
      idRequestType: "",
      startHours: "0",
      startMinute: "0",
      endHours: "0",
      endMinute: "0",
    },
  });

  const [formInputData, setFormData] = useState(null);
  const [reason, setReason] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resultCalOT, setResultCalOT] = useState(null);
  const [isAttach, setAttach] = useState(null);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const fetchRequestReason = async (idCompany) => {
    const result = await dispatch(
      getRequestReason({ idCompany: idCompany || userProfile.idCompany })
    );
    if (result) {
      setReason(result);
    } else {
    }
  };

  useEffect(() => {
    if (values) {
      let idCompany =
        values.employee && values.employee.idCompany
          ? values.employee.idCompany
          : null;
      fetchRequestReason(idCompany);
      useHookForm.setValue("startDate", new Date(values.date));
      useHookForm.setValue("endDate", new Date(values.date));
      setAttach({
        requesttime: values.employee.isAttachRequesttime,
        ot: values.employee.isAttachOt,
      });
      if (isEdit) {
        // setFormData({
        //   idRequestTime: overtimeValues.idRequestTime,
        //   idRequestType: overtimeValues.idRequestType,
        //   startDate: dayjs(overtimeValues.startText, "DD/MM/YYYY HH:mm"),
        //   startMinute: dayjs(
        //     overtimeValues.startText,
        //     "DD/MM/YYYY HH:mm"
        //   ).format("mm"),
        //   startHours: dayjs(
        //     overtimeValues.startText,
        //     "DD/MM/YYYY HH:mm"
        //   ).format("HH"),
        //   endDate: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm"),
        //   endMinute: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm").format(
        //     "mm"
        //   ),
        //   endHours: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm").format(
        //     "HH"
        //   ),
        //   idManagerLV1: overtimeValues.idManagerLV1,
        //   otherReason: overtimeValues.otherReason,
        //   idRequestReason: overtimeValues.idRequestReason,
        //   isDoubleApproval: overtimeValues.isDoubleApproval,
        //   approvalLevel: overtimeValues.approvalLevel,
        //   managerLV1Name: overtimeValues.managerLV1Name,
        //   isManagerLV1Approve: overtimeValues.isManagerLV1Approve,
        //   managerLV1ApproveDate: overtimeValues.managerLV1ApproveDate
        //     ? dayjs(overtimeValues.managerLV1ApproveDate).format(
        //       "DD/MM/BBBB HH:mm"
        //     )
        //     : null,
        //   positionManagerLV1: overtimeValues.positionManagerLV1,
        //   managerLV2Name: overtimeValues.managerLV2Name,
        //   isManagerLV2Approve: overtimeValues.isManagerLV2Approve,
        //   managerLV2ApproveDate: overtimeValues.managerLV2ApproveDate
        //     ? dayjs(overtimeValues.managerLV2ApproveDate).format(
        //       "DD/MM/BBBB HH:mm"
        //     )
        //     : null,
        //   positionManagerLV2: overtimeValues.positionManagerLV2,
        //   createDateText: overtimeValues.createDateText,
        // });

        useHookForm.reset(
          {
            file: [],
            fileName: overtimeValues.filename,
            idCompany: overtimeValues.idCompany,
            idRequestTime: overtimeValues.idRequestTime,
            idRequestType: overtimeValues.idRequestType,
            startDate: dayjs(overtimeValues.startText, "DD/MM/YYYY HH:mm"),
            startMinute: dayjs(
              overtimeValues.startText,
              "DD/MM/YYYY HH:mm"
            ).format("mm"),
            startHours: dayjs(
              overtimeValues.startText,
              "DD/MM/YYYY HH:mm"
            ).format("HH"),
            endDate: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm"),
            endMinute: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm").format(
              "mm"
            ),
            endHours: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm").format(
              "HH"
            ),
            idManagerLV1: overtimeValues.idManagerLV1,
            otherReason: overtimeValues.otherReason,
            idRequestReason: overtimeValues.idRequestReason,
            isDoubleApproval: overtimeValues.isDoubleApproval,
            approvalLevel: overtimeValues.approvalLevel,
            managerLV1Name: overtimeValues.managerLV1Name,
            isManagerLV1Approve: overtimeValues.isManagerLV1Approve,
            managerLV1ApproveDate: overtimeValues.managerLV1ApproveDate
              ? dayjs(overtimeValues.managerLV1ApproveDate).format(
                  "DD/MM/BBBB HH:mm"
                )
              : null,
            positionManagerLV1: overtimeValues.positionManagerLV1,
            managerLV2Name: overtimeValues.managerLV2Name,
            isManagerLV2Approve: overtimeValues.isManagerLV2Approve,
            managerLV2ApproveDate: overtimeValues.managerLV2ApproveDate
              ? dayjs(overtimeValues.managerLV2ApproveDate).format(
                  "DD/MM/BBBB HH:mm"
                )
              : null,
            positionManagerLV2: overtimeValues.positionManagerLV2,
            createDateText: overtimeValues.createDateText,
          },
          {
            keepErrors: true,
            keepDirty: true,
          }
        );
      } else {
        // setFormData({
        //   idRequestType: "",
        //   startDate: dayjs(values.date, "YYYY-MM-DD"),
        //   startMinute: "",
        //   startHours: "",
        //   endDate: dayjs(values.date, "YYYY-MM-DD"),
        //   endMinute: "",
        //   endHours: "",
        //   idRequestReason: "",
        //   otherReason: "",
        // });

        useHookForm.reset(
          {
            file: [],
            fileName: null,
            idRequestType: "",
            startDate: dayjs(values.date, "YYYY-MM-DD"),
            startMinute: "0",
            startHours: "0",
            endDate: dayjs(values.date, "YYYY-MM-DD"),
            endMinute: "0",
            endHours: "0",
            idRequestReason: "",
            otherReason: "",
          },
          {
            keepErrors: true,
            keepDirty: true,
          }
        );
      }
    } else {
      fetchRequestReason();
    }
    setIsSubmitting(false);
  }, [open, values]);

  // useEffect(async () => {
  //   if(watch("idRequestType") === 2){
  //     console.log("watch : ",watch());
  //     if(
  //       watch("startDate") &&
  //       watch("startHours") &&
  //       watch("startMinute") &&
  //       watch("endDate") &&
  //       watch("endHours") &&
  //       watch("endMinute")
  //     ){
  //       let curReason = reasonChosen();
  //       let result = await calculateService.calculateOT(
  //         values.date,
  //         dayjs(watch("startDate"))
  //           .set("hour", watch("startHours"))
  //           .set("minute", watch("startMinute"))
  //           .set("second", 0).format("YYYY-MM-DD HH:mm"),
  //         dayjs(watch("endDate"))
  //           .set("hour", watch("endHours"))
  //           .set("minute", watch("endMinute"))
  //           .set("second", 0).format("YYYY-MM-DD HH:mm"),
  //         {
  //           ...values,
  //           isReasonBreak: curReason
  //           ? Boolean(curReason.isBreak) : false
  //         }
  //       );
  //       if(result.status == 200){
  //         console.log("result API : ",result.data);
  //         setResultCalOT(result.data);
  //       }
  //     }
  //   }
  // },[
  //   watch("idRequestType"), watch("startDate"), watch("startHours"), watch("startMinute"),
  //   watch("endDate"), watch("endHours"), watch("endMinute"), watch("idRequestReason")
  // ]);

  const handleSaveRequestTime = async () => {
    setIsSubmitting(true);
    if (
      useHookForm.watch("idRequestType") === "" ||
      useHookForm.watch("startDate") === null ||
      useHookForm.watch("startMinute") === "" ||
      useHookForm.watch("startHours") === "" ||
      useHookForm.watch("endDate") === null ||
      useHookForm.watch("endMinute") === "" ||
      useHookForm.watch("endHours") === "" ||
      useHookForm.watch("idRequestReason") === ""
    ) {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `${t("PleaseFillInRequiredInformations")}`,
        })
      );
      setIsSubmitting(false);
      return;
    }

    const startDate = dayjs(useHookForm.watch("startDate"))
      .set("hour", useHookForm.watch("startHours"))
      .set("minute", useHookForm.watch("startMinute"))
      .set("second", 0);
    const endDate = dayjs(useHookForm.watch("endDate"))
      .set("hour", useHookForm.watch("endHours"))
      .set("minute", useHookForm.watch("endMinute"))
      .set("second", 0);

    if (startDate.isSameOrAfter(endDate)) {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `${t("InputDateTimeIncorrect")}`,
        })
      );
      setIsSubmitting(false);
      return;
    }

    //console.log("employee: ", employee);

    const formData = {
      workDate: values.date,
      start: startDate.format("YYYY-MM-DD HH:mm:ss"),
      end: endDate.format("YYYY-MM-DD HH:mm:ss"),
      idRequestReason: useHookForm.watch("idRequestReason"),
      idRequestType: useHookForm.watch("idRequestType"),
      otherReason: useHookForm.watch("otherReason"),
      idEmployees: employee.idEmployees,
      isManagerLV1Approve: null,
      isManagerLV2Approve: null,
      isDoubleApproval: 0,
      approvalLevel: 1,
      xOT: 0,
      xOTHoliday: 0,
      xWorkingDaily: 0,
      xWorkingDailyHoliday: 0,
      xWorkingMonthlyHoliday: 0,
      isActive: 1,
      managerLV1ApproveBy: employee.idManagerLV1,
      managerLV2ApproveBy: employee.idManagerLV2,
      idManagerGroupLV1: employee.idManagerGroupLV1,
      idManagerGroupLV2: employee.idManagerGroupLV2,
      idCompany: employee.idCompany,
    };

    console.log("formData:", formData);

    const workStartDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
    const workEndDate = workStartDate.add(
      values.pattern.workingHours,
      "minute",
      true
    );

    if (isEdit) {
      formData.isManagerLV1Approve = overtimeValues.isManagerLV1Approve;
      formData.isManagerLV2Approve = overtimeValues.isManagerLV2Approve;
      formData.isDoubleApproval = overtimeValues.isDoubleApproval;
      formData.approvalLevel = overtimeValues.approvalLevel;
    }

    if (!isEdit && ["admin", "manager"].includes(mode)) {
      formData.fillInCreate = userProfile.idEmployees;
    }

    if (formData.idRequestType === 2 || formData.idRequestType === 3) {
      const overtime = calculatorChoosing(
        dayjs(formData.start),
        dayjs(formData.end),
        (payrollSetting && payrollSetting.dayCutOffTime) || null
      );

      if (formData.idRequestType === 2) {
        formData.xOT = overtime.xOT;
        formData.xOTHoliday = overtime.xOTHoliday;
        formData.xWorkingDaily = overtime.xWorkingDaily;
        formData.xWorkingDailyHoliday = overtime.xWorkingDailyHoliday;
        formData.xWorkingMonthlyHoliday = overtime.xWorkingMonthlyHoliday;

        if (formData.xOTHoliday > 0) {
          formData.isDoubleApproval = 1;
          formData.approvalLevel = 2;
        } else {
          formData.isDoubleApproval = 0;
          formData.approvalLevel = 1;
        }
      }

      formData.amountHours =
        overtime.xOT +
        overtime.xOTHoliday +
        overtime.xWorkingDaily +
        overtime.xWorkingDailyHoliday +
        overtime.xWorkingMonthlyHoliday;
    } else {
      formData.amountHours = endDate.diff(startDate, "minute", true);
    }

    if (mode === "admin" && !userProfile.isFillIn) {
      if (isEdit) {
        if (overtimeValues.managerLV1ApproveBy) {
          formData.isManagerLV1Approve = true;
          formData.fillInApproveLV1 = userProfile.idEmployees;
          // formData.managerLV1ApproveDate = dayjs().format("YYYY-MM-DD HH:mm")
        }
        if (
          overtimeValues.managerLV2ApproveBy ||
          formData.isDoubleApproval === 1
        ) {
          formData.isManagerLV2Approve = true;
          formData.fillInApproveLV2 = userProfile.idEmployees;
          // formData.managerLV2ApproveDate = dayjs().format("YYYY-MM-DD HH:mm")
        }
      } else {
        formData.isManagerLV1Approve = true;
        formData.fillInApproveLV1 = userProfile.idEmployees;
        // formData.managerLV1ApproveDate = dayjs().format("YYYY-MM-DD HH:mm")
      }
    }

    if (
      formData.idRequestType === 2 &&
      [10, 11, 12, 13, 15].includes(Number(formData.idRequestReason))
    ) {
      formData.idShiftGroup = values.pattern.idShiftGroup;

      if (Number(formData.idRequestReason) === 15) {
        formData.workEndDate = workEndDate;
      }
    }

    const postData = new FormData();

    Object.keys(formData).map((k) => {
      postData.append(k, JSON.stringify(formData[k]));
    });

    if (useHookForm.watch("file") && useHookForm.watch("file").length > 0) {
      postData.append("file", useHookForm.watch("file")[0].file);
    }

    if (!isEdit) {
      const result = await dispatch(addRequestTime(postData));

      if (result) {
        if (result.status === 200) {
          if (result.data.errorCode === "ERROR_DUPLICATED") {
            dispatch(
              openNotificationAlert({
                type: "info",
                message: `${t("DuplicateData")}`,
              })
            );
            setIsSubmitting(false);
          } else {
            dispatch(
              openNotificationAlert({
                type: "success",
                message: `${t("DataSaveSuccessful")}`,
              })
            );
            await dispatch(
              getAttendanceById(
                {
                  start: searchDate.start
                    // .add(-7, 'day')
                    .format("YYYY-MM-DD"),
                  end: searchDate.end.format("YYYY-MM-DD"),
                },
                employee.idEmployees
              )
            );
            onClose();
            setIsSubmitting(false);
          }
        } else {
          dispatch(
            openNotificationAlert({
              type: "error",
              message: `${t("AnErrorOccurred")}`,
            })
          );
          await dispatch(
            getAttendanceById(
              {
                start: searchDate.start
                  // .add(-7, 'day')
                  .format("YYYY-MM-DD"),
                end: searchDate.end.format("YYYY-MM-DD"),
              },
              employee.idEmployees
            )
          );
          onClose();
          setIsSubmitting(false);
        }
      }
    } else {
      const result = await dispatch(
        putRequestTime(formData, useHookForm.watch("idRequestTime"))
      );

      if (result) {
        if (result.status === 200) {
          if (result.data.errorCode === "ERROR_DUPLICATED") {
            dispatch(
              openNotificationAlert({
                type: "info",
                message: `${t("DuplicateData")}`,
              })
            );
            setIsSubmitting(false);
          } else {
            dispatch(
              openNotificationAlert({
                type: "success",
                message: `${t("DataSaveSuccessful")}`,
              })
            );
            await dispatch(
              getAttendanceById(
                {
                  start: searchDate.start
                    // .add(-7, 'day')
                    .format("YYYY-MM-DD"),
                  end: searchDate.end.format("YYYY-MM-DD"),
                },
                employee.idEmployees
              )
            );
            onClose();
            setIsSubmitting(false);
          }
        } else {
          dispatch(
            openNotificationAlert({
              type: "error",
              message: `${t("AnErrorOccurred")}`,
            })
          );
          await dispatch(
            getAttendanceById(
              {
                start: searchDate.start
                  // .add(-7, 'day')
                  .format("YYYY-MM-DD"),
                end: searchDate.end.format("YYYY-MM-DD"),
              },
              employee.idEmployees
            )
          );
          onClose();
          setIsSubmitting(false);
        }
      }
    }
    setIsSubmitting(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "idRequestType" && (value === 2 || value === 3)) {
      if (values.pattern.isTimeFrame === 1) {
        if (values.pattern.isWorkingDay === 1) {
          let leaveFind =
            values.leave &&
            values.leave.length > 0 &&
            values.leave.find(
              (v) =>
                v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1
            );
          if (values.pattern.isCompensation || values.holiday || leaveFind) {
            const startDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
            const endDate = startDate.add(
              values.pattern.workingHours,
              "minute",
              true
            );

            let startHours =
              (values.pattern &&
                values.pattern.timeIn &&
                values.pattern.timeIn.split(":")[0]) ||
              "";
            let startMinute =
              (values.pattern &&
                values.pattern.timeIn &&
                values.pattern.timeIn.split(":")[1]) ||
              "";
            let endHours =
              (values.pattern &&
                values.pattern.timeOut &&
                Number(values.pattern.timeOut.split(":")[0])) ||
              "";
            let endMinute =
              (values.pattern &&
                values.pattern.timeOut &&
                values.pattern.timeOut.split(":")[1]) ||
              "";

            useHookForm.setValue(name, value);
            useHookForm.setValue("startHours", startHours);
            useHookForm.setValue("startMinute", startMinute);
            useHookForm.setValue("endHours", endHours);
            useHookForm.setValue("endMinute", endMinute);
            useHookForm.setValue("startDate", startDate);
            useHookForm.setValue("endDate", endDate);

            return;
          }

          let startHours =
            (values.pattern &&
              values.pattern.timeOut &&
              values.pattern.timeOut.split(":")[0]) ||
            "";
          let startMinute =
            (values.pattern &&
              values.pattern.timeOut &&
              values.pattern.timeOut.split(":")[1]) ||
            "";
          let endHours =
            (values.attendance &&
              values.attendance.checkOut &&
              values.attendance.checkOut.length > 0 &&
              values.attendance.checkOut[0] &&
              values.attendance.checkOut[0].attendanceTextTime.split(":")[0]) ||
            "";
          let endMinute =
            (values.attendance &&
              values.attendance.checkOut &&
              values.attendance.checkOut.length > 0 &&
              values.attendance.checkOut[0] &&
              values.attendance.checkOut[0].attendanceTextTime.split(":")[1]) ||
            "";
          let startDate = dayjs(values.date, "YYYY-MM-DD");
          let endDate = dayjs(values.date, "YYYY-MM-DD");

          if (startHours < Number(values.pattern.timeIn.split(":")[0])) {
            startDate = startDate.add(1, "day");
            endDate = endDate.add(1, "day");
          }
          useHookForm.setValue(name, value);
          useHookForm.setValue("startHours", startHours);
          useHookForm.setValue("startMinute", startMinute);
          useHookForm.setValue("endHours", endHours);
          useHookForm.setValue("endMinute", endMinute);
          useHookForm.setValue("startDate", startDate);
          useHookForm.setValue("endDate", endDate);
        } else {
          const startDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
          const endDate = startDate.add(
            values.pattern.workingHours,
            "minute",
            true
          );

          let startHours =
            (values.pattern &&
              values.pattern.timeIn &&
              values.pattern.timeIn.split(":")[0]) ||
            "";
          let startMinute =
            (values.pattern &&
              values.pattern.timeIn &&
              values.pattern.timeIn.split(":")[1]) ||
            "";
          let endHours =
            (values.pattern &&
              values.pattern.timeOut &&
              values.pattern.timeOut.split(":")[0]) ||
            "";
          let endMinute =
            (values.pattern &&
              values.pattern.timeOut &&
              values.pattern.timeOut.split(":")[1]) ||
            "";
          useHookForm.setValue(name, value);
          useHookForm.setValue("startHours", startHours);
          useHookForm.setValue("startMinute", startMinute);
          useHookForm.setValue("endHours", endHours);
          useHookForm.setValue("endMinute", endMinute);
          useHookForm.setValue("startDate", startDate);
          useHookForm.setValue("endDate", endDate);
        }
      } else {
        useHookForm.setValue(name, value);
      }
      return;
    } else if (name === "idRequestType" && value === 1) {
      let startTime = dayjs(`${values.date} ${values.pattern.timeIn}`);
      let endTime = startTime.add(values.pattern.workingHours, "minute", true);

      if(values && values.attendance){
        let maxLength = (values.attendance.checkIn || values.attendance.checkOut || []).length;
        if(maxLength > 0){
          for(let i = 0; i < maxLength; i++){
            if(
              values.attendance.checkIn[0] && 
              !values.attendance.checkOut[0] && 
              dayjs(values.attendance.checkIn[0].attendanceTextDateTime).isBefore(endTime, 'minute')
            ){
              startTime = dayjs(values.attendance.checkIn[0].attendanceTextDateTime);
              break;
            }else if(
              !values.attendance.checkIn[0] && 
              values.attendance.checkOut[0] &&
              dayjs(values.attendance.checkOut[0].attendanceTextDateTime).isAfter(startTime, 'minute')
            ){
              endTime = dayjs(values.attendance.checkOut[0].attendanceTextDateTime);
              break;
            }
          }
        }
      }
      
      useHookForm.setValue(name, value);
      useHookForm.setValue("startDate", startTime.format("YYYY-MM-DD"));
      useHookForm.setValue("endDate", endTime.format("YYYY-MM-DD"));
      useHookForm.setValue("startHours", startTime.format("H"));
      useHookForm.setValue("startMinute", startTime.format("m"));
      useHookForm.setValue("endHours", endTime.format("H"));
      useHookForm.setValue("endMinute", endTime.format("m"));
      return;
    }
    useHookForm.setValue(name, value);
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    useHookForm.setValue(name, value);
    setFormData({
      ...formInputData,
      [name]: value,
    });
  };

  const renderTitle = () => {
    if (isEdit) {
      if (overtimeValues.idRequestType === 2) {
        return `${t("OvertimeRequest")}`;
      } else {
        return `${t("WorkingTimeRequestList")}`;
      }
    } else {
      return `${t("AddItem")}`;
    }
  };

  const reasonChosen = () => {
    let result = null;

    if (reason && reason.length > 0) {
      result = reason.find(
        (r) => r.idRequestReason === useHookForm.watch("idRequestReason")
      );
    }

    return result;
  };

  const calculatorChoosing = (start, end, dayCutOffTime) => {
    let curReason = reasonChosen();
    if (values.pattern.isTimeFrame === 0 && values.pattern.workingHours) {
      return calculatorOvertimeByWorkingHours(start, end, {
        curPattern: values.pattern,
        idPaymentType: values.employee.idPaymentType,
        attendance: values.attendance,
        requestTime: values.requestTime,
        date: values.date,
        ot: values.ot,
        leave: values.leave,
        employee: values.employee,
        isFinger: values.employee.isFinger,
        idRequestType: useHookForm.watch("idRequestType"),
      });
    } else {
      return calculatorOvertime(start, end, {
        prePattern: values.previousPattern,
        curPattern: values.pattern,
        nextPattern: values.nextPattern,
        idPaymentType: values.employee.idPaymentType,
        attendances: values.attendance,
        requestTime: values.requestTime,
        isFinger: values.employee.isFinger,
        leave: values.leave,
        preLeave: values.previousLeave,
        nextLeave: values.nextLeave,
        reason: curReason,
        dayCutOffTime: dayCutOffTime,
        employee: values.employee,
        idRequestType: useHookForm.watch("idRequestType"),
      });
    }
  };

  const disabledButtonSubmit = () => {
    let overtimeObj = calculatorChoosing(
      dayjs(useHookForm.watch("startDate"))
        .set("hour", useHookForm.watch("startHours"))
        .set("minute", useHookForm.watch("startMinute"))
        .set("second", 0),
      dayjs(useHookForm.watch("endDate"))
        .set("hour", useHookForm.watch("endHours"))
        .set("minute", useHookForm.watch("endMinute"))
        .set("second", 0),
      (payrollSetting && payrollSetting.dayCutOffTime) || null
    );

    return (
      overtimeObj.overlapWorking > 0 ||
      !overtimeObj.isInTimeFrame ||
      overtimeObj.isErrorBreakTime ||
      overtimeObj.isOutOfRange
    );
  };

  return (
    <DrawerCustom
      title={renderTitle()}
      header={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>{renderTitle()}</Typography>
          {mode === "admin" && isEdit && (
            <IconButton
              size="small"
              onClick={() => {
                handleClickOpenConfirmDelete(overtimeValues);
              }}
            >
              <Delete style={{ color: "#FF0000", fontSize: "20px" }} />
            </IconButton>
          )}
        </div>
      }
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        {values && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                {t("WorkingDay")}: {dayjs(values.date).format("D/MM/BBBB ")}
              </Typography>
              <Typography>
                {t("WorkingTime")}:{" "}
                {values.pattern.idWorkingType === 1
                  ? `${values.pattern.shiftGroupName}  (${values.pattern.shiftName})`
                  : `${values.pattern.shiftGroupName}`}
                <Typography
                  component="span"
                  color="text.third"
                  style={{
                    fontStyle: "oblique",
                    fontWeight: "normal",
                  }}
                >
                  {values.pattern.isWorkingDay === 1 &&
                  values.pattern.isTimeFrame === 1
                    ? ` (${values.pattern.timeIn.slice(
                        0,
                        -3
                      )} - ${values.pattern.timeOut.slice(0, -3)})`
                    : ` (${values.pattern.shiftTypeName})`}
                </Typography>
              </Typography>
              {values.holiday && (
                <Typography
                  fontWeight={600}
                  fontSize={12}
                  textAlign="right"
                  style={{ color: "#7451ff" }}
                >
                  {t("AnnualHoliday")}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("RequestType")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Controller
                  name="idRequestType"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...field}
                      onChange={handleChange}
                      inputProps={{ readOnly: isEdit }}
                      displayEmpty
                    >
                      <MenuItem value={""} disabled style={{ padding: 0, height: 0 }}></MenuItem>
                      <MenuItem value={1}>{t("WorkingTimeRequest")}</MenuItem>
                      <MenuItem value={2}>{t("OvertimeRequest")}</MenuItem>
                      <MenuItem value={3}>{t("CompensateRequest")}</MenuItem>
                    </Select>
                  )}
                />
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("StartDate")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller
                control={useHookForm.control}
                name="startDate"
                render={({ field }) => (
                  <DatePickerCustom
                    minDate={
                      values.pattern.isTimeFrame === 0
                        ? new Date(dayjs(values.date))
                        : new Date(
                            dayjs(values.date)
                              .subtract(1, "day")
                              .format("YYYY-MM-DD")
                          )
                    }
                    maxDate={
                      values.pattern.isTimeFrame === 0
                        ? new Date(dayjs(values.date))
                        : new Date(
                            dayjs(values.date)
                              .add(1, "day")
                              .format("YYYY-MM-DD")
                          )
                    }
                    {...field}
                    renderInput={(params) => (
                      <TextFieldTheme style={{ width: "100%" }} {...params} />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("StartTime")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <Controller
                    control={useHookForm.control}
                    name="startHours"
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        handleChangeTime={handleChangeTime}
                        unit="hour"
                      />
                    )}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <Controller
                    control={useHookForm.control}
                    name="startMinute"
                    render={({ field }) => (
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="minute"
                        step="1"
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EndDate")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller
                control={useHookForm.control}
                name="endDate"
                render={({ field }) => (
                  <DatePickerCustom
                    maxDate={
                      values.pattern.isTimeFrame === 0
                        ? new Date(dayjs(values.date))
                        : new Date(
                            dayjs(values.date)
                              .add(1, "day")
                              .format("YYYY-MM-DD")
                          )
                    }
                    minDate={
                      values.pattern.isTimeFrame === 0
                        ? new Date(dayjs(values.date))
                        : new Date(
                            dayjs(values.date)
                              .subtract(1, "day")
                              .format("YYYY-MM-DD")
                          )
                    }
                    {...field}
                    renderInput={(params) => (
                      <TextFieldTheme style={{ width: "100%" }} {...params} />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EndTime")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <Controller
                    control={useHookForm.control}
                    name="endHours"
                    render={({ field }) => (
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="hour"
                        {...field}
                      />
                    )}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <Controller
                    control={useHookForm.control}
                    name="endMinute"
                    render={({ field }) => (
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="minute"
                        step="1"
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Reason")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                {reason && (
                  <Controller
                    control={useHookForm.control}
                    name="idRequestReason"
                    render={({ field }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        {...field}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        disabled={useHookForm.watch("idRequestType") === null}
                      >
                        {(useHookForm.watch("idRequestType") === 2 ||
                          userProfile.idCompany === 2) && (
                          <ListSubheader>
                            {t("AppMenu.NoShiftValue")}
                          </ListSubheader>
                        )}
                        {reason
                          .filter((x) => {
                            if (userProfile.idCompany === 2) {
                              return !x.isPayShift;
                            } else {
                              return (
                                !x.isPayShift &&
                                (useHookForm.watch("idRequestType") ===
                                  x.idRequestType ||
                                  (useHookForm.watch("idRequestType") === 3 &&
                                    x.idRequestType === 2))
                              );
                            }
                          })
                          .sort((a, b) => a.index - b.index)
                          .map((item, index) => (
                            <MenuItem key={index} value={item.idRequestReason}>
                              {i18n.resolvedLanguage === "th"
                                ? item.name
                                : item.name_EN}
                            </MenuItem>
                          ))}
                        {(useHookForm.watch("idRequestType") === 2 ||
                          userProfile.idCompany === 2) &&
                          employee.shift &&
                          employee.shift.idActualWorkingType === 1 && (
                            <ListSubheader>
                              {t("AppMenu.ShiftValueExists")}
                            </ListSubheader>
                          )}
                        {reason
                          .filter((x) => {
                            if (
                              employee.shift &&
                              employee.shift.idActualWorkingType === 1
                            ) {
                              if (userProfile.idCompany === 2) {
                                return x.isPayShift;
                              } else {
                                return (
                                  x.isPayShift &&
                                  (useHookForm.watch("idRequestType") ===
                                    x.idRequestType ||
                                    (useHookForm.watch("idRequestType") === 3 &&
                                      x.idRequestType === 2))
                                );
                              }
                            } else {
                              return false;
                            }
                          })
                          .sort((a, b) => a.index - b.index)
                          .map((item, index) => (
                            <MenuItem key={index} value={item.idRequestReason}>
                              {i18n.resolvedLanguage === "th"
                                ? item.name
                                : item.name_EN}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                )}
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("OtherReason")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Controller
                  control={useHookForm.control}
                  name="otherReason"
                  render={({ field }) => (
                    <TextField
                      onChange={handleChange}
                      id="outlined-multiline-static"
                      multiline
                      valuess={3}
                      {...field}
                    />
                  )}
                />
              </StyledFormControl>
            </Grid>

            {useHookForm.watch("idRequestType") === 2 && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel>{t("TotalHours")}</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {RenderOT(
                    payrollSetting,
                    calculatorChoosing(
                      dayjs(useHookForm.watch("startDate"))
                        .set("hour", useHookForm.watch("startHours"))
                        .set("minute", useHookForm.watch("startMinute"))
                        .set("second", 0),
                      dayjs(useHookForm.watch("endDate"))
                        .set("hour", useHookForm.watch("endHours"))
                        .set("minute", useHookForm.watch("endMinute"))
                        .set("second", 0),
                      (payrollSetting && payrollSetting.dayCutOffTime) || null
                    ),
                    t
                  )}
                </Grid>
              </Fragment>
            )}

            {useHookForm.watch("idRequestType") === 3 && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel>{t("TotalHours")}</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {RenderCompensateHour(
                    calculatorChoosing(
                      dayjs(useHookForm.watch("startDate"))
                        .set("hour", useHookForm.watch("startHours"))
                        .set("minute", useHookForm.watch("startMinute"))
                        .set("second", 0),
                      dayjs(useHookForm.watch("endDate"))
                        .set("hour", useHookForm.watch("endHours"))
                        .set("minute", useHookForm.watch("endMinute"))
                        .set("second", 0),
                      (payrollSetting && payrollSetting.dayCutOffTime) || null
                    ),
                    t
                  )}
                </Grid>
              </Fragment>
            )}

            {(useHookForm.watch("idRequestType") === 2 ||
              useHookForm.watch("idRequestType") === 3) &&
              (values.pattern.isTimeFrame === 0
                ? values.isEarlyOut
                : false) && (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel style={{ color: "#FF0000" }}>
                    *{t("IncompleteWorkingTime")}
                  </StyledContentLabel>
                </Grid>
              )}
            {((useHookForm.watch("idRequestType") === 1 &&
              isAttach.requesttime === 1) ||
              (useHookForm.watch("idRequestType") === 2 &&
                isAttach.ot === 1)) && (
              <Fragment>
                <Grid item xs={12}>
                  <StyledContentLabel>{`${t(
                    "AttachFile"
                  )}`}</StyledContentLabel>
                </Grid>
                {useHookForm.watch("fileName") &&
                  useHookForm.watch("file") &&
                  useHookForm.watch("file").length <= 0 && (
                    <Grid item xs={12}>
                      <Link
                        component="button"
                        onClick={async () => {
                          await fileService
                            .downloadBucketFile(
                              `request-time-ot/${
                                useHookForm.watch("idRequestType") === 1
                                  ? "time"
                                  : "ot"
                              }/${useHookForm.watch(
                                "idCompany"
                              )}/${useHookForm.watch(
                                "idRequestTime"
                              )}/${useHookForm.watch("fileName")}`
                            )
                            .then((res) => {
                              const url = window.URL.createObjectURL(
                                new Blob([res.data])
                              );
                              const link = document.createElement("a");
                              link.href = url;
                              link.setAttribute(
                                "download",
                                useHookForm.watch("fileName")
                              );
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            });
                        }}
                      >
                        <i
                          className="fa-regular fa-file"
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontSize: "20px",
                          }}
                        />
                        &nbsp;{useHookForm.watch("fileName")}
                      </Link>
                    </Grid>
                  )}
                {useHookForm.watch("fileName") === null &&
                  useHookForm.watch("file") &&
                  useHookForm.watch("file").length <= 0 && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        minHeight: "fit-content",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <DragDrop
                        useHookForm={useHookForm}
                        name="file"
                        type="image/jpeg, image/png, application/pdf"
                        size={1024 * 1024 * 5}
                        mulitple={false}
                      />
                    </Grid>
                  )}
                {useHookForm.watch("fileName") === null &&
                  useHookForm.watch("file") &&
                  useHookForm.watch("file").length > 0 && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        minHeight: "fit-content",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        {useHookForm.watch("file").map((f) => (
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            style={{ width: "100%" }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                              style={{ minWidth: "0" }}
                            >
                              <Avatar
                                src={PaperClipIcon}
                                variant="rounded"
                                style={{
                                  marginRight: "8px",
                                  marginLeft: "8px",
                                  width: "32px",
                                  height: "32px",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                  pointerEvents: "none",
                                }}
                              />
                              <div
                                style={{
                                  minWidth: "0px",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  textOverflow="ellipsis"
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                >
                                  {f.file.name}
                                </Typography>
                                <Typography
                                  style={{ fontSize: "12px" }}
                                  color="text.secondary"
                                >
                                  {f.sizeDisplay}
                                </Typography>
                              </div>
                            </Stack>
                            <IconButton
                              color="error"
                              onClick={() => {
                                useHookForm.setValue("file", []);
                              }}
                            >
                              <ClearOutlinedIcon />
                            </IconButton>
                          </Stack>
                        ))}
                      </Stack>
                    </Grid>
                  )}
              </Fragment>
            )}

            <Grid item xs={12}>
              <StyledFooter>
                <ButtonBlue
                  className="cancel"
                  color="secondary"
                  variant="text"
                  onClick={onClose}
                >
                  {t("Cancel")}
                </ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  onClick={handleSaveRequestTime}
                  disabled={
                    isSubmitting ||
                    ((useHookForm.watch("idRequestType") === 2 ||
                      useHookForm.watch("idRequestType") === 3) &&
                      disabledButtonSubmit()) ||
                    ((useHookForm.watch("idRequestType") === 2 ||
                      useHookForm.watch("idRequestType") === 3) &&
                      (values.pattern.isTimeFrame === 0
                        ? values.isEarlyOut
                        : false))
                  }
                >
                  {t("SaveData")}
                </ButtonBlue>
              </StyledFooter>
            </Grid>
          </Grid>
        )}
      </StyledRoot>
    </DrawerCustom>
  );
};

const RenderOT = (payrollSetting, overtimeObj, t) => {
  const {
    xOT,
    xOTHoliday,
    xWorkingDaily,
    xWorkingDailyHoliday,
    xWorkingMonthlyHoliday,
    overlapWorking,
    isInTimeFrame,
    isBeforeWorkTime,
    isErrorBreakTime,
    isOutOfRange,
  } = overtimeObj;
  let otList = [];
  // console.log("overtimeObj : ",overtimeObj);
  if (xWorkingDailyHoliday > 0) {
    otList.push({
      x: payrollSetting.xWorkingDailyHoliday,
      hours: (xWorkingDailyHoliday / 60).toFixed(2),
    });
  }
  if (xWorkingMonthlyHoliday > 0) {
    otList.push({
      x: payrollSetting.xWorkingMonthlyHoliday,
      hours: (xWorkingMonthlyHoliday / 60).toFixed(2),
    });
  }
  if (xWorkingDaily > 0) {
    otList.push({
      x: 1,
      hours: (xWorkingDaily / 60).toFixed(2),
    });
  }
  if (xOT > 0) {
    otList.push({
      x: payrollSetting.xOT,
      hours: (xOT / 60).toFixed(2),
    });
  }
  if (xOTHoliday > 0) {
    otList.push({
      x: payrollSetting.xOTHoliday,
      hours: (xOTHoliday / 60).toFixed(2),
    });
  }

  return (
    <Fragment>
      {overlapWorking > 0 ? (
        <Typography style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}>
          {t("CantRequestOTNormalHours")}
        </Typography>
      ) : (
        <Fragment>
          {isOutOfRange ? (
            <Typography
              style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
            >
              ไม่สามารถขอค่าล่วงเวลาที่เลือกในวันนี้ได้
            </Typography>
          ) : isBeforeWorkTime ? (
            <Typography
              style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
            >
              ไม่สามารถขอค่าล่วงเวลาก่อนเวลาทำงานได้
            </Typography>
          ) : !isInTimeFrame ? (
            <Typography
              style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
            >
              {t("CantRequestToWorkHaveRecorded")}
            </Typography>
          ) : (
            isErrorBreakTime && (
              <Typography
                style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
              >
                เวลาทำงานช่วงพัก ไม่ถูกต้อง
              </Typography>
            )
          )}
          {isInTimeFrame &&
            !isOutOfRange &&
            !isBeforeWorkTime &&
            !isErrorBreakTime &&
            otList.map((item, index) => (
              <Box
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontWeight={500}
                  variant="subtitle1"
                  color="text.third"
                >
                  OT x {item.x}
                </Typography>
                <Typography variant="h6">
                  {item.hours} {t("Unit.ShortHours")}
                </Typography>
              </Box>
            ))}
        </Fragment>
      )}
    </Fragment>
  );
};

const RenderCompensateHour = (overtimeObj, t) => {
  const {
    xOT,
    xOTHoliday,
    xWorkingDaily,
    xWorkingDailyHoliday,
    xWorkingMonthlyHoliday,
    overlapWorking,
    isInTimeFrame,
  } = overtimeObj;

  console.log("overtimeObj: ", overtimeObj);

  let otList = 0;

  if (xWorkingDailyHoliday > 0) {
    otList += xWorkingDailyHoliday / 60;
  }
  if (xWorkingMonthlyHoliday > 0) {
    otList += xWorkingMonthlyHoliday / 60;
  }
  if (xWorkingDaily > 0) {
    otList += xWorkingDaily / 60;
  }
  if (xOT > 0) {
    otList += xOT / 60;
  }
  if (xOTHoliday > 0) {
    otList += xOTHoliday / 60;
  }

  return (
    <Fragment>
      {overlapWorking > 0 ? (
        <Typography style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}>
          {t("CantRequestCompensateNormalHours")}
        </Typography>
      ) : (
        <Fragment>
          {!isInTimeFrame && (
            <Typography
              style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
            >
              {t("CantRequestCompensateHaveRecorded")}
            </Typography>
          )}
          {isInTimeFrame && (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                fontWeight={500}
                variant="subtitle1"
                color="text.third"
              >
                Compensate
              </Typography>
              <Typography variant="h6">
                {Math.round(otList * 100) / 100} {t("Unit.ShortHours")}
              </Typography>
            </Box>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default DrawerRequestTime;
