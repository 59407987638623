import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  InputAdornment,
  FormControl,
  styled,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import PropTypes from "prop-types";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

import {
  addEmploymentType,
  getEmploymentTypeByidCompany,
  updateEmploymentType,
} from "../../../../../actions/employment-type";
import { t } from "i18next";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .description-pattern": {
    marginTop: 16,
    "& .line-1": {
      fontSize: 14,
      textDecoration: "underline",
    },
    "& .line-label": {
      fontSize: 14,
      fontWeight: 500,
    },
    "& .line-remark": {
      fontSize: 14,
    },
    "& .line-2, .line-3": {
      letterSpacing: 8,
      fontWeight: 600,
    },
  },
  "& .footer": {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      letterSpacing: 8,
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledNumber = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
  },
});

const DialogDetail = ({ open, handleClose, mode, data, selectedCompany, isReadOnly }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    employmentTypeName: "",
    pattern: null,
    maxSalary: "",
    minSalary: "",
    idEmploymentTypePattern: null,
  });
  const [isUseWithOtherPattern, setIsUseWithOtherPattern] = useState(false);
  const { result: employmentType } = useSelector(
    (state) => state.employmentType
  );
  const [employmentTypePattern, setEmploymentTypePattern] = useState({
    idEmploymentType: null,
  });
  const [disabledCheckbox, setDisabledCheckbox] = useState(false);
  const [isParentOfPattern, setIsParentOfPattern] = useState(false);

  useEffect(() => {
    if (data) {
      setFormData(data);
      if (data.idEmploymentTypePattern) {
        setIsUseWithOtherPattern(true);
      } else {
        setIsUseWithOtherPattern(false);
        setEmploymentTypePattern({});
      }
    } else {
      setFormData({
        employmentTypeName: "",
        pattern: null,
        maxSalary: "",
        minSalary: "",
        idEmploymentTypePattern: null,
      });
      setIsUseWithOtherPattern(false);
      setEmploymentTypePattern({});
      setDisabledCheckbox(false);
    }
  }, [data]);

  useEffect(() => {
    if (employmentType && data) {
      setDisabledCheckbox(false);
      employmentType.map((e) => {
        if (e.idEmploymentType === data.idEmploymentTypePattern) {
          setEmploymentTypePattern(e);
        }
        if (e.idEmploymentTypePattern === data.idEmploymentType) {
          setDisabledCheckbox(true);
          setIsParentOfPattern(true);
        }
      });
      if (
        employmentType.filter(
          (e) => e.idEmploymentTypePattern === null && e.pattern != null
        ).length === 0
      ) {
        setDisabledCheckbox(true);
      }
    }

    if (employmentType) {
      if (
        employmentType.filter(
          (e) => e.idEmploymentTypePattern === null && e.pattern != null
        ).length === 0
      ) {
        setDisabledCheckbox(true);
      }
    }
  }, [data, employmentType]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (mode === "add") {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      let temp = { ...formData };
      setFormData({ ...temp, [name]: value });
    }
    // console.log(formData, "data");
  };

  const handleSaveChange = async () => {
    // console.log("formData: ", formData);
    let data = { ...formData, idCompany: selectedCompany.idCompany };
    data.maxSalary = data.maxSalary === "" ? null : data.maxSalary;
    data.minSalary = data.minSalary === "" ? null : data.minSalary;
    data.idEmploymentTypePattern =
      data.idEmploymentTypePattern === null
        ? null
        : data.idEmploymentTypePattern;

    if (mode === "add") {
      let result = await dispatch(addEmploymentType(data));

      if (result) {
        ///handleOpenAlert();
        if (result.status === 200) {
          dispatch(
            getEmploymentTypeByidCompany({
              idCompany: selectedCompany.idCompany,
            })
          );
        } else {
          //handleChangeAlertType(result.data.message);
        }
        handleClose();
      } else {
        // handleOpenAlert();
        // handleChangeAlertType("error");
        handleClose();
      }
    } else if (mode === "edit") {
      let result = await dispatch(updateEmploymentType(data));

      if (result) {
        ///handleOpenAlert();
        if (result.status === 200) {
          dispatch(
            getEmploymentTypeByidCompany({
              idCompany: selectedCompany.idCompany,
            })
          );
        } else {
          //handleChangeAlertType(result.data.message);
        }
        handleClose();
      } else {
        // handleOpenAlert();
        // handleChangeAlertType("error");
        handleClose();
      }
    }
  };

  const handleChangeCheckbox = (event) => {
    setIsUseWithOtherPattern(Boolean(event.target.checked));
    if (event.target.checked === true) {
      setFormData({
        ...formData,
        pattern: null,
      });
    } else {
      setFormData({
        ...formData,
        pattern: null,
        idEmploymentTypePattern: null,
      });
      setEmploymentTypePattern({});
    }
  };

  return (
    <DrawerCustom
      title={mode === "edit" ? t("EditEmploymentType") : t("AddEmploymentType")}
      anchor={"right"}
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        {formData && (
          <Fragment>
            <div>
              <StyledContentLabel color="text.third" gutterBottom>
                {t("EmploymentType")}
              </StyledContentLabel>
              <TextFieldTheme
                name="employmentTypeName"
                value={formData.employmentTypeName}
                onChange={handleChange}
              />
            </div>
            <div style={{ marginTop: 16 }}>
              <StyledContentLabel color="text.third" gutterBottom>
                {t("FormatEmployeeID")}
              </StyledContentLabel>
              <FormControlLabel
                name="isUseWithOtherPattern"
                control={
                  <Checkbox
                    onChange={handleChangeCheckbox}
                    defaultChecked={isUseWithOtherPattern}
                    sx={{
                      "&.Mui-checked": {
                        color: "#46CBE2",
                      },
                    }}
                    disabled={disabledCheckbox}
                  />
                }
                label={t("UseWithExistedPattern")}
                sx={{ marginBottom: 1 }}
              />
              {isUseWithOtherPattern === false ? (
                <TextFieldTheme
                  name="pattern"
                  value={formData.pattern}
                  onChange={handleChange}
                  disabled={Boolean(isUseWithOtherPattern)}
                />
              ) : (
                <>
                  <Autocomplete
                    disablePortal
                    options={
                      employmentType
                        ? employmentType.filter(
                            (e) =>
                              e.idEmploymentTypePattern === null &&
                              e.pattern != null &&
                              (data
                                ? e.idEmploymentType != data.idEmploymentType
                                : true)
                          )
                        : []
                    }
                    name="employmentTypePattern"
                    value={employmentTypePattern}
                    getOptionLabel={(option) =>
                      option.employmentTypeName ? option.employmentTypeName : ""
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        key={option.idEmploymentType}
                      >
                        {option.employmentTypeName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme {...params} label={t("selectEmploymentType")} />
                    )}
                    onChange={(_, value) => {
                      if (value) {
                        setFormData({
                          ...formData,
                          idEmploymentTypePattern: value.idEmploymentType,
                        });
                        setEmploymentTypePattern(value);
                      } else {
                        setFormData({
                          ...formData,
                          idEmploymentTypePattern: null,
                        });
                        setEmploymentTypePattern({});
                      }
                    }}
                  />
                </>
              )}

              <div className="description-pattern">
                {/* <Typography className="line-1" color="text.secondary">
                  โปรดระบุรูปแบบรหัสพนักงาน
                </Typography> */}
                <Typography className="line-1" color="text.secondary">
                  {t("Example")}
                </Typography>
                <Typography className="line-label" color="text.secondary">
                  {t("EmployeeID")}:{" "}
                </Typography>
                <Typography className="line-2" color="text.third">
                  ABC-00001
                </Typography>
                <Typography className="line-label" color="text.secondary">
                  {t("EmployeeIdPattern")}:{" "}
                </Typography>
                <Typography className="line-3" color="text.third">
                  ABC-*****
                </Typography>
                <Typography
                  className="line-remark"
                  variant="caption"
                  color="text.secondary"
                >
                  ({t("EmployeeIdPatternRemark")})
                </Typography>
              </div>
            </div>
            {/* <div style={{ marginTop: 8 }}>
              <StyledContentLabel color="text.third" gutterBottom>
                ค่าจ้างสูงสุด
              </StyledContentLabel>
              <StyledNumber variant="outlined" fullWidth>
                <TextFieldTheme
                  name="maxSalary"
                  value={formData.maxSalary}
                  onChange={handleChange}
                  variant="outlined"
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="ไม่บังคับ"
                  InputProps={{
                    inputComponent: NumberFormatTheme,
                    endAdornment: (
                      <InputAdornment position="end">บาท</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </StyledNumber>
            </div>
            <div style={{ marginTop: 8 }}>
              <StyledContentLabel color="text.third" gutterBottom>
                ค่าจ้างต่ำสุด
              </StyledContentLabel>
              <StyledNumber variant="outlined" fullWidth>
                <TextFieldTheme
                  name="minSalary"
                  value={formData.minSalary}
                  onChange={handleChange}
                  variant="outlined"
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="ไม่บังคับ"
                  InputProps={{
                    inputComponent: NumberFormatTheme,
                    endAdornment: (
                      <InputAdornment position="end">บาท</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </StyledNumber>
            </div> */}

            <div className="footer">
              <ButtonBlue
                onClick={() => {
                  if (!data) {
                    setFormData({
                      employmentTypeName: "",
                      pattern: null,
                      maxSalary: "",
                      minSalary: "",
                      idEmploymentTypePattern: null,
                    });
                    setEmploymentTypePattern({});
                    setIsUseWithOtherPattern(false);
                  } else {
                    setFormData({ ...formData, idEmploymentTypePattern: null });
                  }

                  handleClose();
                }}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                onClick={handleSaveChange}
                disabled={
                  formData.employmentTypeName === "" ||
                  (isUseWithOtherPattern === true &&
                    formData.idEmploymentTypePattern === null) ||
                  (isParentOfPattern === true &&
                    (formData.pattern
                      ? !formData.pattern.includes("*")
                      : formData.pattern != null)) || isReadOnly
                }
              >
                {t("SaveData")}
              </ButtonBlue>
            </div>
          </Fragment>
        )}
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogDetail;
