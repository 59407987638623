import { httpClient } from "./httpClient";

const addOnBoardingList = (formData) => {
  return httpClient.post(`/OnBoardingList/add`, formData);
};

const addOnBoardingReviewer = (formData) => {
  return httpClient.post(`/OnBoardingReviewer/add`, formData);
};

const addOnBoardingEmployee = (formData) => {
  return httpClient.post(`/OnBoardingEmployee/add`, formData);
};

const editOnBoardingList = (formData) => {
  return httpClient.put(`/OnBoardingList/edit`, formData);
};

const approveOnBoardingEmployee = (formData) => {
  return httpClient.put(`/OnBoardingEmployee/approve`, formData);
};

const approveOnBoardingEmployeeByReviewer = (id, formData) => {
  return httpClient.put(`/OnBoardingEmployee/approve/${id}`, formData);
};

const getOnBoardingList = (query) => {
  return httpClient.get(`/OnBoardingList`, { params: query });
};

const getOnBoardingReviewer = (idOnBoardingList) => {
  return httpClient.get(`/OnBoardingReviewer/${idOnBoardingList}`);
};

const getOnBoardingEmployee = (query) => {
  return httpClient.get(`/OnBoardingEmployee`, { params: query });
};

const getOnBoardingEmployeeByID = (id) => {
  return httpClient.get(`/OnBoardingEmployee/${id}`);
};

const getOnBoardingEmployeeByReviewer = () => {
  return httpClient.get(`/OnBoardingEmployeeByReviewer`);
};

const getOnBoardingHistoryByReviewer = () => {
  return httpClient.get(`/OnBoardingEmployeeByReviewer/history`);
};

const deleteOnBoardingReviewer = (idOnBoardingReviewer) => {
  return httpClient.delete(
    `/OnBoardingReviewer/delete/${idOnBoardingReviewer}`
  );
};

export default {
  addOnBoardingList,
  editOnBoardingList,
  getOnBoardingList,
  addOnBoardingReviewer,
  getOnBoardingReviewer,
  addOnBoardingEmployee,
  getOnBoardingEmployee,
  getOnBoardingEmployeeByID,
  approveOnBoardingEmployee,
  getOnBoardingEmployeeByReviewer,
  getOnBoardingHistoryByReviewer,
  approveOnBoardingEmployeeByReviewer,
  deleteOnBoardingReviewer,
};
