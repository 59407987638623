import React, { useEffect, useState } from "react";
import { Autocomplete, Box, MenuItem, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PDFDocument } from "pdf-lib";
import { getOrganizationChart } from "../../../../actions/orgChart";
import OrgChartComponent from "./OrgChart";
import DrawerEdit from "./DrawerEdit";
import { getOrganizationStructure } from "../../../../actions/organizationStructure";
import { clearEmployeeNetwork, getAllEmployees, getEmployeeNetwork } from "../../../../actions/employee";
import Profile from "./DrawerProfile";
import LabelDotted from "./LabelDotted";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import { getAffiliateOrganization, getCompanyOrganization } from "../../../../actions/company";
import { getUserCompany } from "../../../../utils/userData";

const StyledRoot = styled(Box)({
  "& .diagram-component": {
    width: "100vw",
    height: "calc(100vh - 80px)",
    backgroundColor: "white",
  },
  "& .react-transform-wrapper": {
    width: "100vw",
    height: "calc(100vh - 80px)",
    cursor: "grab"
  },
})

const OrganizationChart = React.memo(() => {

  const dispatch = useDispatch();
  const { result: OrganizationStructureData } = useSelector(state => state.organizationStructure);
  const { result: EmployeeData } = useSelector(state => state.employees);

  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const [drawerEditConfig, setDrawerEditConfig] = useState({
    isOpen: false,
  })
  const [open, setOpen] = useState(false);

  const onNodeClick = (node) => {
    dataList.map((item) => {
      if (item.id == node) {
        // console.log(item)
        if (item.nodeType === "EMPLOYEE" && item.employeeList[0]) {
          dispatch(getEmployeeNetwork(item.employeeList[0].idEmployees))
          setOpen(true)
          // console.log(item.employeeList[0].idEmployees)
        } else if (item.mainPosition && item.mainPosition.employee) {
          dispatch(getEmployeeNetwork(item.mainPosition.employee.idEmployees))
          setOpen(true)
          // console.log(item.mainPosition.employee.idEmployees)
        }
      }
    })
  }

  // useEffect(() => {
  //   // dispatch(getOrganizationChart())
  //   dispatch(getOrganizationStructure())
  //   dispatch(getAllEmployees());
  // }, [])

  // useEffect(() => {
  //   if(OrganizationStructureData && EmployeeData){
  //     getOrganizationStructureList()
  //   }
  // }, [OrganizationStructureData, EmployeeData])
  let render = 1

  useEffect(() => {
    console.count("renderOrgChart")
    // dispatch(getCompanyOrganization());
    dispatch(getAffiliateOrganization());
    // dispatch(getOrganizationStructure()).then(res => {
      // dispatch(getAllEmployees()).then(res => {
      //   // getOrganizationStructureList()
      // })
    // })
  }, [])

  // useEffect(() => {

  //   if(selectedCompany){
  //     getOrganizationStructure({idCompany: selectedCompany.idCompany})
  //     // getAllEmployees()
  //     // getOrganizationStructureList()
  //   }

  // }, [selectedCompany])

  const getOrganizationStructureList = async (OrganizationStructureData) => {
    if (OrganizationStructureData 
        // && EmployeeData
      ) {
      const data = [];
      const totalEmployees = {};
      const pictureEmployeeMap = {};

      // const EmployeePromise = await Promise.all(EmployeeData.map(async item => {
      //   return await new Promise(async (resolve, reject) => {

      //     if(item.imageProfile){
      //       const response = await fetch(item.imageProfile, { mode: 'no-cors' })
      //       const blob = await response.blob();
      //       const buf = await blob.arrayBuffer();
  
      //       resolve({
      //         ...item,
      //         blob: blob ? URL.createObjectURL(new Blob([buf], { type: "image/png" })) : null
      //       })
      //     } else {
      //       resolve({
      //         ...item,
      //         blob: null
      //       })
      //     }
      //   })
      // }))

      // EmployeePromise.map(async item => {
      //   pictureEmployeeMap[item.idEmployees] = {
      //     url: item.imageProfile,
      //     blob: item.blob ? item.blob : null
      //   }
      // })

      const tempData = [
        ...OrganizationStructureData.ceos,
        ...OrganizationStructureData.businessUnits,
        ...OrganizationStructureData.divisions,
        ...OrganizationStructureData.departments,
        ...OrganizationStructureData.sections,
        ...OrganizationStructureData.positions,
      ]

      const keyMap = {};

      tempData.map((item, i) => {

        if(i === 0){
          data.push(
            {
              ...item,
              id: item.code,
              parentId: item.parent ? item.parent.parentCode : null
            }
          )
          keyMap[item.code] = true;
        } else {

          if(item.code && item.parent && item.parent.parentCode && keyMap[item.parent.parentCode]){
            data.push(
              {
                ...item,
                id: item.code,
                parentId: item.parent ? item.parent.parentCode : null
              }
            )
            keyMap[item.code] = true;
          }
        }
      })

      const employeePositionMap = {};

      OrganizationStructureData.employees.map(item => {
        if (!totalEmployees["CEO-00001"]) {
          totalEmployees["CEO-00001"] = 0;
        }
        totalEmployees["CEO-00001"] += 1
        if (item.parent.businessUnitCode) {
          if (!totalEmployees[item.parent.businessUnitCode]) {
            totalEmployees[item.parent.businessUnitCode] = 0;
          }
          totalEmployees[item.parent.businessUnitCode] += 1
        }
        if (item.parent.divisionCode) {
          if (!totalEmployees[item.parent.divisionCode]) {
            totalEmployees[item.parent.divisionCode] = 0;
          }
          totalEmployees[item.parent.divisionCode] += 1
        }
        if (item.parent.departmentCode) {
          if (!totalEmployees[item.parent.departmentCode]) {
            totalEmployees[item.parent.departmentCode] = 0;
          }
          totalEmployees[item.parent.departmentCode] += 1
        }
        if (item.parent.sectionCode) {
          if (!totalEmployees[item.parent.sectionCode]) {
            totalEmployees[item.parent.sectionCode] = 0;
          }
          totalEmployees[item.parent.sectionCode] += 1
        }

        if (!employeePositionMap[item.parent.parentCode]) {
          employeePositionMap[item.parent.parentCode] = [];
        }
        employeePositionMap[item.parent.parentCode].push({
          ...item,
          imageProfile: pictureEmployeeMap[item.idEmployees] ? pictureEmployeeMap[item.idEmployees] : null,
        })
      })

      Object.keys(employeePositionMap).map((key, index) => {
        // data.push(
        //   {
        //     id: `EMP-GROUP${key}`,
        //     parentId: key,
        //     employeeList: employeePositionMap[key],
        //     nodeType: "EMPLOYEE",
        //   }
        // )
        employeePositionMap[key].map((item, i) => {
          if(keyMap[key]){
            data.push({
              id: `EMP-GROUP${item.idEmployees}`,
              parentId: key,
              employeeList: [item],
              nodeType: "EMPLOYEE",
            })
          }
        })
      })

      data.map(item => {
        item.totalEmployees = 0;
        item.totalEmployees = totalEmployees[item.id] ? totalEmployees[item.id] : 0
      })
      setDataList([...data]);
      console.log("data", data)
      return data;
    } else {
      return [];
    }
  }

  const onSelectCompany = (value) => {
    if(value){
      setIsFetching(true)
      dispatch(getOrganizationStructure({idCompany: value.idCompany})).then(res => {
        getOrganizationStructureList(res.data)
        setIsFetching(false)
      })
    }
    setSelectedCompany({...value})
  }

  const downloadPdf = async (chart) => {
    chart.exportImg({
      save: false,
      onLoad: async (base64) => {
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([595 / 3, ((chart.height / chart.width) * 595) / 3]);

        const imageBytes = Uint8Array.from(atob(base64.split(',')[1]), c => c.charCodeAt(0));
        const image = await pdfDoc.embedPng(imageBytes);

        page.drawImage(image, {
          x: 5,
          y: page.getHeight() - 5 - ((chart.height / chart.width) * 595) / 3,
          width: 595 / 3,
          height: ((chart.height / chart.width) * 595) / 3,
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'organization-chart.pdf';
        link.click();
      },
    });
  }

  return (
    <StyledRoot className="page">
      <Box position="relative" height="100vh">
        <Box position="absolute" top="0" left="0" right="0" zIndex="1001">
          <Box padding="16px 16px 0">
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap="16px" flexWrap="wrap" style={{ userSelect: "none" }}>
                <LabelDotted color="#d50000" text="CEO Office" />
                <LabelDotted color="#6C0BA9" text="Business Unit" />
                <LabelDotted color="#e65100" text="Division" />
                <LabelDotted color="#0091ea" text="Department" />
                <LabelDotted color="#4caf50" text="Section" />
                <LabelDotted color="#9e9e9e" text="Position" />
              </Box>

              <Box maxWidth="500px" width="100%">
                <Autocomplete
                  options={affiliateOrganizationList? affiliateOrganizationList: []}
                  getOptionLabel={(option) => `${getUserCompany(option)}`}
                  isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                  sele
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.idCompany}>
                      <Box>
                        <Typography>{getUserCompany(option)}</Typography>
                        {/* <Typography color="text.secondary" fontSize="12px">{option.companyName}</Typography> */}
                      </Box>
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      placeholder={t("SelectCompany")}
                    />
                  )}
                  value={selectedCompany}
                  onChange={(_, value) => {
                    onSelectCompany(value)
                    // setSelectedCompany(value);
                  }}
                  noOptionsText={t("NoData")}
                  disableClearable
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {isFetching && (
          <Typography position="absolute" top="50%" left="50%">Loading data...</Typography>
        )}

        {!isFetching && dataList.length > 0 && 
          <OrgChartComponent
            // data={dataList}
            data={dataList}
            onNodeClick={onNodeClick}
            onDownload={downloadPdf}
          />
        }

      </Box>
      <DrawerEdit
        config={drawerEditConfig}
        onClose={() => {
          setDrawerEditConfig(p => ({
            ...p,
            isOpen: false
          }))
        }}
      />
      <Profile
        open={open}
        handleClose={() => {
          setOpen(false)
          dispatch(clearEmployeeNetwork());
        }}
      />
    </StyledRoot>
  )
})

export default OrganizationChart;