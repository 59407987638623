import React, { useEffect, useState } from "react";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateOrganization } from "../../../../actions/company";
import AssessmentPerformance from "./assessmentPerformance";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAllEmployees } from "../../../../actions/employee";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Assessment = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      setSelectedCompany(affiliateOrganizationList[0]);
    }
  }, [affiliateOrganizationList]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAllEmployees(false, selectedCompany.idCompany));
    }
  }, [selectedCompany]);

  return (
    <div className="page" style={{ backgroundColor: "#ffffff" }}>
      <Container>
        <Typography variant="h4" margin={"16px 0px"}>
          {t("assessment")}
        </Typography>
        <Box marginBottom="24px">
          <SelectAffiliateCompany
            options={affiliateOrganizationList}
            value={selectedCompany}
            onChange={(_, value) => setSelectedCompany(value)}
          />
        </Box>
        {selectedCompany && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label={t("annualAssessment")} {...a11yProps(0)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <AssessmentPerformance selectedCompany={selectedCompany} />
            </CustomTabPanel>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default Assessment;
