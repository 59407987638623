import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Popper,
  TextField,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import ButtonBlue from "./ButtonBlue";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const StyledDialog = styled(Dialog)({
  borderRadius: 8,
  "& .MuiDialogContent-root": {
    padding: 0,
    "& .content": {
      padding: 16,
      textAlign: "center",
      "& .delete-icon-label": {
        fontSize: 90,
        color: "#46cbe2",
      },
      "& .header-label": {
        padding: "20px 0",
      },
      "& .detail": {
        fontSize: 16,
        "& span": {
          color: "#46cbe2",
          fontSize: 20,
        },
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  marginTop: 16,
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: "transparent",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "transparent",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const DialogConfirm = (props) => {
  const {
    open,
    handleClose,
    text,
    handleConfirm,
    content1,
    content2,
    setSelectedHoliday,
    data
  } = props;
  const { t } = useTranslation();
  const [state, setstate] = useState(true);
  const [listHoliday, setListHoliday] = useState([]);

  useEffect(() => {
    if(data){
      setListHoliday(data);
    }else{
      setListHoliday([]);
    }
  },[data]);
  
  return (
    <StyledDialog fullWidth maxWidth={"xs"} open={open} onClose={handleClose}>
      <DialogContent>
        <div
          style={{ width: "100%", height: 6, backgroundColor: "#46cbe2" }}
        ></div>
        <div className="content">
          <InfoOutlinedIcon className="delete-icon-label" />
          <div className="header-label">
            <Typography variant="subtitle1" color="text.third" gutterBottom>
              {t("AreYouSure")}
            </Typography>
            {content1 && <Typography variant="h5">{content1}</Typography>}
            {content2 && <Typography variant="h5">{content2}</Typography>}
          </div>
          <div>
            <StyledAutocomplete
              options={listHoliday}
              onChange={(_, newValue) => {
                if (newValue) {
                  setSelectedHoliday(newValue.idHoliday);
                  setstate(false)
                } else {
                  setSelectedHoliday(null);
                  setstate(true)
                }
              }}
              popupIcon={<i className="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => `${option.name} (${dayjs(option.dateHoliday).format("DD MMM BBBB")})`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t("SelectAnnualHoliday")}
                  label={t("SelectAnnualHoliday")}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={t("NoData")}
            />
          </div>
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <ButtonBlue
              color="secondary"
              variant="text"
              style={{ marginRight: "10px" }}
              onClick={handleClose}
            >
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleConfirm} disabled={state}>
              {`${t("Confirm")} ${text}`}
            </ButtonBlue>
          </Box>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default DialogConfirm;
