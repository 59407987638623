import React, { useRef, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Grid,
    Typography,
    DialogContentText,
} from '@mui/material'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import 'react-quill/dist/quill.snow.css';
import '../styles/editorStyles.css';
import '../styles/quillStyles.css'

import BackspaceIcon from '@mui/icons-material/Backspace';
import PdfGenerator from '../Pdf/PdfGenerator.js';
import TextEditor from '../TextEditor.js';
import CreatPdf from '../images/document.png'
import Eye from '../images/eye.png'
import LoadingIcon from '../../../assets/social-media.gif';
import UsagePopup from '../dialog/UsagePopup.js';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import {
    createPdfDocument,
} from "../../../../../actions/document";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        overflowX: 'hidden',
        width: '100%',
    },
    gridItem: {
        flex: '1 1 auto',
        margin: theme.spacing(1),
    },
    button: {
        backgroundColor: '#ADD8E6',
        boxShadow: '0 3px 5px rgba(0,0,0,0.3)',
        '&:hover': {
            backgroundColor: '#87CEEB',
        },
    },
    cancelButton: {
        color: 'rgba(0, 0, 255, 0.7)',
        border: '1px solid blue',
        borderRadius: '5px',
        padding: '5px 10px',
        fontSize: '13px',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: 'rgba(173, 216, 230, 0.6)',
        },
    },
    confirmButton: {
        color: 'white',
        borderRadius: '5px',
        padding: '5px 10px',
        fontSize: '13px',
        fontWeight: 'bold',
        backgroundColor: '#A2D1A5',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#99DE86'
        },
    },
    confirmCloseSmartDoc: {
        color: 'white',
        borderRadius: '5px',
        padding: '5px 10px',
        fontSize: '13px',
        fontWeight: 'bold',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 0.5)'
        },
    },
    dialogBackground: {
        '& .MuiDialog-paper': {
            backgroundColor: '##BEBEBE',
            borderRadius: '7px',
        },
        '& .css-ut4szi': {
            fontSize: '24px',
            width: '90vw',
            height: '90vh',
        },
        '& .MuiDialog-container': {
            '& .MuiPaper-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: 'none',
                border: '0px solid black',
                background: 'white',
            },
        },
        '& .MuiBackdrop-root': {
            backdropFilter: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '40vw',
            maxHeight: '60vh',
        },
        '& .MuiBackdrop-root.MuiModal-backdrop': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '0vw',
            maxHeight: '0vh',
        },
    },
}));

const StyledFloatingButtonCancel = styled('div')({
    position: 'absolute',
    paddingBottom: '0px',
    right: '200px',
    width: '150px',
    userSelect: 'none',
    zIndex: 3,
});

const StyledCancelButton = styled(Button)({
    color: '#000000',
    padding: '8px 8px',
    borderRadius: '8px',
    border: '2px solid #4f4f4f',
    '&:hover': {
        backgroundColor: '#DCDCDC',
    },
    '&:active': {
        backgroundColor: '#bdbdbd',
    },
});

const StyledPdfButton = styled(Button)({
    backgroundColor: '#4FC3F7',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.3)',
    opacity: 0.9,
    color: '#FFFFFF',
    fontWeight: 'semi-bold',
    borderRadius: '20px',
    padding: '15px 30px',
    fontSize: '1.1rem',
    backgroundImage: 'linear-gradient(45deg, #A7DCEC, #79CDE5)',
});

const StyledInstructionsButton = styled(Button)({
    backgroundColor: '#B0BEC5',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.3)',
    opacity: 0.9,
    color: '#FFFFFF',
    fontWeight: 'semi-bold',
    borderRadius: '20px',
    padding: '15px 12px',
    fontSize: '1.1rem',
    backgroundImage: 'linear-gradient(45deg, #CFD8DC, #B0BEC5)',
});

const StyledCreatePdfButton = styled('div')({
    backgroundColor: '#A2D1A5',
    color: '#FFFFFF',
    borderRadius: '12px',
    width: '65x',
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)',
    padding: '6px',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: '#AADBAD',
    },
});

const InnerButton = styled('div')({
    borderRadius: '12px',
    width: '40px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});


const CreatePdf = (props) => {
    const { row, userProfile } = props.location.state;

    const history = useHistory();
    const dispatch = useDispatch();
    const { result: employees } = useSelector((state) => state.employees);
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const inputRef = useRef(null);
    const [pdfData, setPdfData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingIcon, setLoadingIcon] = useState(false);
    const quillRef = useRef(null);
    const [text, setText] = useState();
    const [signatureDataList, setSignatureDataList] = useState([]);
    const [preview, setPreview] = useState(null);
    const [textFormat, setTextFormat] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [isShowOption, setIsShowOption] = useState(true);
    let Idcompany;

    useEffect(() => {
        const showNotification = localStorage.getItem("showNotification");
        if (showNotification === null) {
            localStorage.setItem("showNotification", "true");
            setShowPopup(true);
        } else if (showNotification === "false") {
            setShowPopup(false);
        } else {
            setShowPopup(true);
        }
    }, []);

    const handleClosePopup = () => {
        if (dontShowAgain) {
            localStorage.setItem("showNotification", "false");
        }
        setShowPopup(false);
    };

    useEffect(() => {
        setSignatureDataList([]);
    }, [row]);

    useEffect(() => {
        if (quillRef.current && text) {
            const quill = quillRef.current.getEditor();
            if (quill) {
                const defaultFontSize = '18px';
                const editor = quill.root;
                editor.style.fontSize = defaultFontSize;
            }
        } else {
            console.error('Quill reference is not set');
        }
    }, [text, quillRef]);

    const handleChange = (value, delta, source, editor) => {
        if (editor) {
            setText(editor.getContents());
            updateTextFormat();
        } else {
            console.error('Editor is not available');
        }
    };

    const updateTextFormat = () => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            if (quill) {

                const lineData = getLineData(quill);

                const delta = quill.getContents();

                const textupdate = lineData && lineData.map((formatItem) => {
                    if (formatItem.text.startsWith("#")) {
                        const cleanedText = formatItem.text.replace("×✎", "");

                        delta.ops.forEach((op) => {
                            if (op.insert === cleanedText) {

                                formatItem.format = formatItem.format || {};

                                if (Object.keys(formatItem.format).length === 1 && formatItem.format.size) {
                                    formatItem.format.background = formatItem.format.background || op.attributes.background || "#e0f7fa";

                                    formatItem.format.size = formatItem.format.size || op.attributes.size || "18px";

                                    formatItem.format.mention = formatItem.format.mention || [];
                                    formatItem.format.mention[0] = {
                                        id: op.attributes.mention.id || null,
                                        value: op.attributes.mention.value || null,
                                        width: op.attributes.mention.width || null,
                                        size: op.attributes.mention.size || "18px"
                                    };

                                    formatItem.format.editableField = formatItem.format.editableField || [];
                                    formatItem.format.editableField[0] = {
                                        editable: op.attributes.editableField.editable || false
                                    };
                                } else {
                                    formatItem.format.background = formatItem.format.background || op.attributes.background || "#e0f7fa";
                                    formatItem.format.size = formatItem.format.size || op.attributes.size || "18px";
                                    formatItem.format.mention = formatItem.format.mention || [];
                                    formatItem.format.mention[0] = {
                                        id: op.attributes.mention.id || null,
                                        value: op.attributes.mention.value || null,
                                        width: op.attributes.mention.width || null,
                                        size: op.attributes.mention.size || "18px"
                                    };
                                    formatItem.format.editableField = formatItem.format.editableField || [];
                                    formatItem.format.editableField[0] = {
                                        editable: op.attributes.editableField.editable || false
                                    };
                                }
                            }
                        });
                    }
                    return formatItem;
                });

                setTextFormat(textupdate);

                quill.root.childNodes.forEach((line, index) => {
                    if (line.getAttribute('data-line-index') !== String(index)) {
                        line.setAttribute('data-line-index', index);
                    }
                });
            } else {
                console.error('Quill editor is not available');
            }
        } else {
            console.error('Quill reference is not set');
        }
    };

    useEffect(() => {
        if (quillRef.current) {
            const delay = setTimeout(() => {
                updateTextFormat();
            }, 500);
            return () => {
                clearTimeout(delay);
            };
        }
    }, [quillRef]);

    const getLineData = (editor) => {
        const lines = editor.getText().split('\n');
        const lineData = [];

        let currentIndex = 0;

        lines.forEach((line, lineIndex) => {
            const words = line.split(/(\s+)/);
            let currentWordIndex = currentIndex;

            words.forEach((word, wordIndex) => {
                const wordLength = word.length;
                const wordSelection = { index: currentWordIndex, length: wordLength };

                try {
                    const formats = editor.getFormat(wordSelection.index, wordSelection.length);
                    const bounds = editor.getBounds(wordSelection.index, wordSelection.length);

                    const adjustedBounds = {
                        ...bounds,
                        left: bounds.left - 2,
                        right: bounds.right + 2
                    };

                    const { padding, margin } = getPaddingAndMargin(editor, wordSelection);
                    const isWhitespace = /^\s+$/.test(word);

                    const tagMatch = word.match(/#\w+×✎/);
                    if (tagMatch) {
                        const tag = tagMatch[0];
                        const tagIndex = word.indexOf(tag);
                        const precedingText = word.substring(0, tagIndex);
                        const remainingText = word.substring(tagIndex + tag.length);

                        if (precedingText) {
                            lineData.push({
                                text: precedingText,
                                format: formats,
                                position: { index: currentWordIndex, length: precedingText.length },
                                bounds: editor.getBounds(currentWordIndex, precedingText.length),
                                padding: padding,
                                margin: margin,
                                isWhitespace: false
                            });
                        }

                        lineData.push({
                            text: tag,
                            format: formats,
                            position: { index: currentWordIndex + precedingText.length, length: tag.length },
                            bounds: editor.getBounds(currentWordIndex + precedingText.length, tag.length),
                            padding: padding,
                            margin: margin,
                            isWhitespace: false
                        });

                        if (remainingText) {
                            lineData.push({
                                text: remainingText,
                                format: formats,
                                position: { index: currentWordIndex + precedingText.length + tag.length, length: remainingText.length },
                                bounds: editor.getBounds(currentWordIndex + precedingText.length + tag.length, remainingText.length),
                                padding: padding,
                                margin: margin,
                                isWhitespace: false
                            });
                        }
                    } else {
                        lineData.push({
                            text: word,
                            format: formats,
                            position: wordSelection,
                            bounds: adjustedBounds,
                            padding: padding,
                            margin: margin,
                            isWhitespace: isWhitespace
                        });
                    }
                    currentWordIndex += wordLength;
                } catch (error) {
                    console.error('Error calculating bounds:', error);
                }
            });
            currentIndex += line.length + 1;
        });
        return lineData;
    };

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();

            const handleTextChange = (delta) => {

                const selection = quill.getSelection() || { index: 0 };

                if (delta.ops.some(op => op.delete)) {
                    const [currentLine] = quill.getLine(selection.index);

                    if (currentLine && currentLine.length() === 0 && selection.index > 0) {
                        const prevIndex = selection.index - 1;
                        const [prevLine] = quill.getLine(prevIndex);

                        if (prevLine) {
                            const newIndex = quill.getIndex(prevLine) + prevLine.length() - 1;

                            quill.setSelection(newIndex, 0);
                        }
                    }
                }
            };

            quill.on('text-change', handleTextChange);

            return () => {
                quill.off('text-change', handleTextChange);
            };
        } else {
            console.error('Quill reference is not set');
        }
    }, [quillRef]);

    const getPaddingAndMargin = (editor) => {
        if (!editor || !editor.root) {
            console.error('Editor or editor root is not available');
            return {
                padding: { top: '0px', right: '0px', bottom: '0px', left: '0px' },
                margin: { top: '0px', right: '0px', bottom: '0px', left: '0px' },
            };
        }

        const editorElement = editor.root;

        if (editorElement instanceof HTMLElement) {
            const computedStyle = window.getComputedStyle(editorElement);
            const padding = {
                top: computedStyle.paddingTop,
                right: computedStyle.paddingRight,
                bottom: computedStyle.paddingBottom,
                left: computedStyle.paddingLeft,
            };

            const margin = {
                top: computedStyle.marginTop,
                right: computedStyle.marginRight,
                bottom: computedStyle.marginBottom,
                left: computedStyle.marginLeft,
            };

            return { padding, margin };
        } else {
            console.error('editor.root is not a valid DOM element');
            return {
                padding: { top: '0px', right: '0px', bottom: '0px', left: '0px' },
                margin: { top: '0px', right: '0px', bottom: '0px', left: '0px' },
            };
        }
    };

    useEffect(() => {
        if (userProfile && userProfile.idCompany) {
            const Idcompany = userProfile.idCompany;
            setFormData(prevFormData => ({
                ...prevFormData,
                idCompany: Idcompany,
            }));
        }
    }, [userProfile]);

    const [formData, setFormData] = useState({
        idCompany: Idcompany,
        documentTypeName: '',
        files: [],
    });

    const handleOnSubmit = async (pdfFile) => {

        const newTextFormat = textFormat.map(item => {
            const { padding, margin, format, ...rest } = item;

            let newFormat = { ...format };

            if (format) {
                newFormat.editableField = Array.isArray(format.editableField) ? [format.editableField[0]] : [];
                newFormat.mention = Array.isArray(format.mention) ? [format.mention[0]] : [];
            }
            const newText = item.text.replace("×✎", "") || item.text;

            return {
                ...rest,
                format: newFormat,
                text: newText
            };
        });
        const data = {
            idCompany: formData.idCompany,
            documentTypeName: row.documentTypeName,
            createDate: dayjs(new Date()).format("YYYY-MM-DD"),
            updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
            textFormat: newTextFormat,
            signatureDataList: signatureDataList,
        };
        const postData = new FormData();

        Object.keys(data).forEach((key) => {
            postData.append(key, JSON.stringify(data[key]));
        });

        if (pdfFile) {
            postData.append('file', pdfFile);
        }
        setLoading(true);
        setLoadingIcon(true);
        try {
            const result = await dispatch(createPdfDocument(postData));
            if (result) {
                setLoadingIcon(false)
                Swal.fire({
                    title: `${t("Succeed")}!!`,
                    text: `${t("Document_created_successfully")}!!`,
                    icon: 'success',
                    confirmButtonText: `${t("Ok")}`,
                });
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            Swal.fire({
                title: `${t("AnErrorOccurred")}`,
                text: `${t("Unable_to_create_document")}!!`,
                icon: 'error',
                confirmButtonText: `${t("Ok")}`,
            });
        } finally {
            setLoading(false);
            setTypeDialog(null);
        }
    };

    const [typeDialog, setTypeDialog] = useState(null);
    const [open, setOpen] = useState(false);

    const handleCloseDialog = () => {
        setTypeDialog(null);
        setOpen(false);
    };

    const handleDialog = () => {
        setTypeDialog('create');
        setOpen(true);
    };

    const handleClose = () => {
        setTypeDialog('closeForm');
        setOpen(true);
    };

    const handleCreatePdf = () => {
        handleGeneratePdf(true);
        setPreview(false)
        history.push('/smart-documents');
    };

    const handlePreviewClick = () => {
        setPreview(true)
    };

    useEffect(() => {
        if (preview && preview === true) {
            handleGeneratePdf(false);
            setPreview(false)
        }
    }, [preview]);

    const handleGeneratePdf = async (shouldCallOnSubmit = true) => {
        await PdfGenerator({
            textFormat,
            signatureDataList,
            preview,
            handleOnSubmit: (pdfBlob) => {
                setPdfData(pdfBlob);
                if (shouldCallOnSubmit) {
                    handleOnSubmit(pdfBlob);
                }
            }
        });
    };

    return (
        <Box sx={{ backgroundColor: 'white', minHeight: '110vh', minWidth: '840px' }}>
            <UsagePopup open={showPopup} onClose={handleClosePopup} isShowOption={isShowOption} setDontShowAgain={setDontShowAgain} />
            <Grid container
                className={classes.gridContainer}
                style={{ paddingBottom: '200px', paddingTop: '100px' }}
            >
                {/* <Grid container justifyContent="flex-end" style={{ position: 'relative', paddingBottom: '0px' }} sx={{ left: { xs: '8px', sm: '16px', md: '50px' } }}>
                    <StyledFloatingButtonCancel>
                        <StyledCancelButton
                            startIcon={<BackspaceIcon />}
                            onClick={handleClose}
                        >
                            {t('Close')}
                        </StyledCancelButton>
                    </StyledFloatingButtonCancel>
                </Grid> */}
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ paddingBottom: '40px' }}>
                        <Grid container justifyContent="center" alignItems="center">
                            <div>
                                <>
                                    <Grid
                                        container
                                        display={'flex'}
                                        justifyContent="space-between"
                                        alignItems="center"
                                        flexWrap={'nowrap'}
                                        sx={{
                                            paddingBottom: '32px',
                                            userSelect: 'none',
                                        }}
                                    >
                                        <Grid item>
                                            <Typography sx={{ fontSize: '1.3rem' }}>
                                                {i18n.language === 'th'
                                                    ? `${t('Form')}${row.documentTypeName}`
                                                    : `${t('Form')}  ${row.documentTypeName}`
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <StyledCancelButton
                                                startIcon={<BackspaceIcon />}
                                                onClick={handleClose}
                                            >
                                                {t('Close')}
                                            </StyledCancelButton>
                                        </Grid>
                                    </Grid>
                                    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
                                        <TextEditor
                                            text={text}
                                            textFormat={textFormat}
                                            setTextFormat={setTextFormat}
                                            setText={setText}
                                            onChange={handleChange}
                                            quillRef={quillRef}
                                            userProfile={userProfile}
                                            signatureDataList={signatureDataList}
                                            setSignatureDataList={setSignatureDataList}
                                        />
                                    </div>
                                </>
                            </div>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        position: 'fixed', bottom: '16px', left: '50%', transform: 'translateX(-50%)'
                    }}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={2}
                            sx={{ width: '350px'}}
                        >
                            <StyledCreatePdfButton
                                onClick={handleDialog}
                            >
                                <InnerButton>
                                    {<img src={CreatPdf} alt="PDF Icon"
                                        style={{ width: "35px", height: "34px" }} />}
                                </InnerButton>
                                <Typography
                                    variant="h6"
                                    style={{ marginTop: '2px', fontSize: '0.8rem', fontWeight: 'bold', color: '#fff' }}
                                >
                                    {t("Create")}
                                </Typography>
                            </StyledCreatePdfButton>
                            <StyledPdfButton
                                variant="contained"
                                className={classes.button}
                                onClick={handlePreviewClick}
                                startIcon={<img src={Eye} alt="PDF Icon"
                                    style={{ width: "35px", height: "36px", marginRight: "8px" }}
                                />}
                            >
                                {t('Preview')}
                            </StyledPdfButton>
                            <StyledInstructionsButton
                                variant="contained"
                                className={classes.button}
                                onClick={() => {
                                    setShowPopup(true);
                                    setIsShowOption(false);
                                }}
                                startIcon={
                                    <HelpOutlineIcon style={{ width: "35px", height: "36px", marginLeft: "8px" }} />
                                }
                            >
                            </StyledInstructionsButton >
                        </Box>
                    </Box>

                    {(typeDialog === "create" || typeDialog === "closeForm") && (
                        <Dialog
                            open={open}
                            onClose={handleCloseDialog}
                            className={classes.dialogBackground}
                            style={{ backgroundColor: 'rgba(100, 100, 100, 0.06)', }}
                        >
                            {loadingIcon ? (
                                <img src={LoadingIcon} style={{ height: 80, width: 80 }} />
                            ) : (
                                <>
                                    <DialogTitle style={{ fontSize: '17px', fontWeight: 'bold' }}>
                                        {typeDialog === "create" ? t("Create_form") : t("Close")}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText style={{ fontSize: '14px', color: 'black' }}>
                                            {typeDialog === "create" ? (
                                                t("AreYouSure")
                                            ) : (
                                                <>
                                                    <div style={{ color: 'red' }}>
                                                        {t("CloseSmartDoc")}
                                                    </div>
                                                    <br />
                                                    <div style={{ textAlign: 'center', paddingTop: '4px' }}>
                                                        {t("AreYouSure")}
                                                    </div>
                                                </>
                                            )}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            className={classes.cancelButton}
                                            onClick={handleCloseDialog}
                                        >
                                            {t("Cancel")}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className={`${typeDialog === "create" ? classes.confirmButton : classes.confirmCloseSmartDoc}`}
                                            onClick={() => {
                                                if (typeDialog === "create") {
                                                    handleCreatePdf();
                                                } else {
                                                    history.push('/smart-documents');
                                                }
                                            }}
                                        >
                                            {t("Confirm")}
                                        </Button>
                                    </DialogActions>
                                </>
                            )}
                        </Dialog>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
export default CreatePdf;



