import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';

export const ManpowerXlsx = async (t,data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Asset");

    const headerRow = worksheet1.addRow([
        // t("EmployeeID"), 
        t("departmentName"), t("departmentLevel"), "Plan", "Actual"
    ]);

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    headerRow.height = 50;

    const colWidths = [
        // { key: "employeeID", width: 30 },
        { key: "businees_unit_name", width: 50 },
        { key: "level", width: 30 },
        { key: "Plan", width: 30 },
        { key: "Actual", width: 30 },
    ];

    colWidths.forEach((col, index) => {
        worksheet1.getColumn(index + 1).width = col.width;
    });

    data.forEach((item) => {
        const row = [
            // item.employeeID ? item.employeeID : "",
            item.businessUnitName ? item.businessUnitName : "",
            item.level ? t(item.level) : "",
            item.manpowerPlan ? item.manpowerPlan : "",
            item.manpowerActual ? item.manpowerActual : "",
        ];

        const excelRow = worksheet1.addRow(row);
        const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("ManPower")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}