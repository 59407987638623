import {
  EXPENSE_SETTING_FETCHING,
  EXPENSE_SETTING_FAILED,
  EXPENSE_SETTING_SUCCESS,
  EXPENSE_SETTINGS_FETCHING,
  EXPENSE_SETTINGS_FAILED,
  EXPENSE_SETTINGS_SUCCESS,
  EXPENSE_PRORATE_TYPES_FETCHING,
  EXPENSE_PRORATE_TYPES_FAILED,
  EXPENSE_PRORATE_TYPES_SUCCESS,
  EXPENSE_ALLOWANCE_TYPES_FETCHING,
  EXPENSE_ALLOWANCE_TYPES_FAILED,
  EXPENSE_ALLOWANCE_TYPES_SUCCESS,
  EXPENSE_SETTINGS_MILEAGE_FETCHING,
  EXPENSE_SETTINGS_MILEAGE_FAILED,
  EXPENSE_SETTINGS_MILEAGE_SUCCESS,
  EXPENSE_SETTINGS_ALLOWANCE_FETCHING,
  EXPENSE_SETTINGS_ALLOWANCE_FAILED,
  EXPENSE_SETTINGS_ALLOWANCE_SUCCESS,
  EXPENSE_SETTING_MILEAGE_FETCHING,
  EXPENSE_SETTING_MILEAGE_FAILED,
  EXPENSE_SETTING_MILEAGE_SUCCESS,
  EXPENSE_SETTING_ALLOWANCE_FETCHING,
  EXPENSE_SETTING_ALLOWANCE_FAILED,
  EXPENSE_SETTING_ALLOWANCE_SUCCESS,
} from "./types";

import ExpenseSettingService from "../services/expenseSetting.service";

export const getExpenseSettings = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_SETTINGS_FETCHING,
    });
    const res = await ExpenseSettingService.getExpenseSettings(idCompany);
    if (res) {
      dispatch({
        type: EXPENSE_SETTINGS_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_SETTINGS_FAILED,
    });
    return error;
  }
};

export const updateExpenseSetting = (formData) => async () => {
  try {
    const res = await ExpenseSettingService.updateExpenseSetting(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const addExpenseSetting = (formData) => async () => {
  try {
    const res = await ExpenseSettingService.addExpenseSetting(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseSetting = (idExpenseSetting) => async () => {
  try {
    const res = await ExpenseSettingService.deleteExpenseSetting(
      idExpenseSetting
    );
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getExpenseSetting = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_SETTING_FETCHING,
    });
    const res = await ExpenseSettingService.getExpenseSetting();
    if (res) {
      dispatch({
        type: EXPENSE_SETTING_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_SETTING_FAILED,
    });
    return error;
  }
};

export const getExpenseProrateTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_PRORATE_TYPES_FETCHING,
    });
    const res = await ExpenseSettingService.getExpenseProrateTypes();
    if (res) {
      dispatch({
        type: EXPENSE_PRORATE_TYPES_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_PRORATE_TYPES_FAILED,
    });
    return error;
  }
};

export const getExpenseAllowanceTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_ALLOWANCE_TYPES_FETCHING,
    });
    const res = await ExpenseSettingService.getExpenseAllowanceTypes();
    if (res) {
      dispatch({
        type: EXPENSE_ALLOWANCE_TYPES_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_ALLOWANCE_TYPES_FAILED,
    });
    return error;
  }
};

export const getExpenseSettingsMileage = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_SETTINGS_MILEAGE_FETCHING,
    });
    const res = await ExpenseSettingService.getExpenseSettingsMileage(
      idCompany
    );
    if (res) {
      dispatch({
        type: EXPENSE_SETTINGS_MILEAGE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_SETTINGS_MILEAGE_FAILED,
    });
    return error;
  }
};

export const updateExpenseSettingMileage = (formData) => async () => {
  try {
    const res = await ExpenseSettingService.updateExpenseSettingMileage(
      formData
    );
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const addExpenseSettingMileage = (formData) => async () => {
  try {
    const res = await ExpenseSettingService.addExpenseSettingMileage(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseSettingMileage =
  (idExpenseSettingMileage) => async () => {
    try {
      const res = await ExpenseSettingService.deleteExpenseSettingMileage(
        idExpenseSettingMileage
      );
      if (res) {
        console.log(res);
        return res;
      }
    } catch (error) {
      return error;
    }
  };

export const getExpenseSettingsAllowance = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_SETTINGS_ALLOWANCE_FETCHING,
    });
    const res = await ExpenseSettingService.getExpenseSettingsAllowance(
      idCompany
    );
    if (res) {
      dispatch({
        type: EXPENSE_SETTINGS_ALLOWANCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_SETTINGS_ALLOWANCE_FAILED,
    });
    return error;
  }
};

export const updateExpenseSettingAllowance = (formData) => async () => {
  try {
    const res = await ExpenseSettingService.updateExpenseSettingAllowance(
      formData
    );
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const addExpenseSettingAllowance = (formData) => async () => {
  try {
    const res = await ExpenseSettingService.addExpenseSettingAllowance(
      formData
    );
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteExpenseSettingAllowance =
  (idExpenseSettingMileage) => async () => {
    try {
      const res = await ExpenseSettingService.deleteExpenseSettingAllowance(
        idExpenseSettingMileage
      );
      if (res) {
        console.log(res);
        return res;
      }
    } catch (error) {
      return error;
    }
  };

export const getExpenseSettingMileage = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_SETTING_MILEAGE_FETCHING,
    });
    const res = await ExpenseSettingService.getExpenseSettingMileage(idCompany);
    if (res) {
      dispatch({
        type: EXPENSE_SETTING_MILEAGE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_SETTING_MILEAGE_FAILED,
    });
    return error;
  }
};

export const getExpenseSettingAllowance = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_SETTING_ALLOWANCE_FETCHING,
    });
    const res = await ExpenseSettingService.getExpenseSettingAllowance(
      idCompany
    );
    if (res) {
      dispatch({
        type: EXPENSE_SETTING_ALLOWANCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EXPENSE_SETTING_ALLOWANCE_FAILED,
    });
    return error;
  }
};
