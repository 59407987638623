import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Box, MenuItem, FormControl
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import CardStyle from "../../../shared/general/Card";
import { getCostElement } from "../../../../../actions/costElement";
import Setting from "./Setting";
import { useTranslation } from "react-i18next";
import { CostElementXlsx } from "./costElementXlsx";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: "30px",
        paddingBttom: "100px",
        "& .MuiTypography-h6": {

            fontSize: "1.5rem",
        },
        "& .MuiAccordionSummary-root": {
            paddingLeft: 8,
            paddingRight: 16,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
    },
    headingPage: {
        marginBottom: 32,
    },
    wrapIcon: {
        display: "flex",
        alignItems: "center",
    },
    iconHeader: {
        marginRight: 16,
    },
    paper: {
        padding: 8,
        borderRadius: "20px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    },
}));

const StyledHeadingPage = styled("div")({
    marginBottom: 16,
});

const StyledEmpty = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 350,
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

function CostElement(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { result: costElement } = useSelector((state) => state.costElement);
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [typeDialog, setTypeDialog] = useState();
    const { selectedCompany } = props;
    const [inputSearch, setInputSearch] = useState({ year: new Date(), month: new Date() });
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        if (selectedCompany && (inputSearch && inputSearch.month && inputSearch.year)) {
            dispatch(getCostElement({
                idCompany: selectedCompany ? selectedCompany.idCompany : null,
                month: dayjs(inputSearch.month).format("MM"),
                year: dayjs(inputSearch.year).format("YYYY")
            }));
        }
    }, [openDialog, selectedCompany, inputSearch]);

    const handleClose = () => {
        setTypeDialog(null)
        setOpenDialog(false)
        setData(null)
    }

    return (
        <div className={classes.root}>

            <StyledHeadingPage>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">{t("costCodeSetting")}</Typography>
                    </Grid>
                    <Grid item>
                        <ButtonBlue
                            variant="contained"
                            onClick={() => CostElementXlsx(costElement ? costElement : null, selectedCompany && selectedCompany.companyName ,t)}
                            disabled={!costElement || (costElement && costElement.length < 1)}
                        >
                            {t("DownloadData")}
                        </ButtonBlue>
                    </Grid>
                </Grid>
            </StyledHeadingPage>

            <CardStyle style={{ padding: 16, marginBottom: 20 }}>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item xs={3}>
                        <StyledBoxSearch>
                            <Typography className="label" color="text.third">
                                {t("SelectYear")}
                            </Typography>
                            <div className="search-date">
                                <DatePickerCustom
                                    maxDate={dayjs()}
                                    inputFormat="YYYY"
                                    value={inputSearch.year}
                                    name="start"
                                    views={['year']}
                                    openTo={'year'}
                                    onChange={(newValue) => {
                                        setInputSearch(prevState => ({
                                            ...prevState,
                                            year: newValue
                                        }));
                                    }}
                                // disabled={isFetching}
                                />
                            </div>
                        </StyledBoxSearch>
                    </Grid>

                    <Grid item xs={3}>
                        <StyledBoxSearch>
                            <Typography className="label" color="text.third">
                                {t("SelectMonth")}
                            </Typography>
                            <div className="search-date">
                                <DatePickerCustom
                                    maxDate={dayjs()}
                                    inputFormat="MMMM"
                                    value={inputSearch.month}
                                    name="start"
                                    views={['month']}
                                    openTo={'month'}
                                    onChange={(newValue) => {
                                        setInputSearch(prevState => ({
                                            ...prevState,
                                            month: newValue
                                        }));
                                    }}
                                // disabled={isFetching}
                                />
                            </div>
                        </StyledBoxSearch>
                    </Grid>
                </Grid>

                {costElement && costElement.length > 0 ?
                    <TableContainer component={Paper} sx={{ minWidth: 600, marginTop: 6 }}>
                        <Table sx={{ minWidth: 600 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t("TitleName")}</TableCell>
                                    <TableCell align="center">Cost Code</TableCell>
                                    <TableCell align="center">{t("Status")}</TableCell>
                                    <TableCell align="center">{t("Total")}</TableCell>
                                    <TableCell align="center">{t("Setting")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {costElement.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            {item.name_TH ? item.name_TH :
                                            item.name_EN ? item.name_EN :
                                                item.AdditionName_TH ? item.AdditionName_TH :
                                                    item.AdditionName_EN ? item.AdditionName_EN :
                                                    item.DeductionName_TH ? item.DeductionName_TH : 
                                                    item.DeductionName_EN ? item.DeductionName_EN : "-"}
                                        </TableCell>
                                        <TableCell align="center">{item.code ? item.code : "-"}</TableCell>
                                        <TableCell align="center">
                                            {item.isActive !== undefined && (item.isActive === 1 || item.isActive === 0) ? (
                                                item.isActive === 1 ? (
                                                    <Typography color="green">Active</Typography>
                                                ) : (
                                                    <Typography color="red">Inactive</Typography>
                                                )
                                            ) : (
                                                <Typography>-</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {item.SummaryPayroll
                                                    ? parseFloat(item.SummaryPayroll).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                    : "0.00"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <EditIcon
                                                onClick={() => {
                                                    setOpenDialog(true);
                                                    setTypeDialog("Edit");
                                                    setData(item);
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <StyledEmpty className="empty-holiday">
                        <Typography color="text.secondary" align="center">
                            {t("NoData")}
                        </Typography>
                    </StyledEmpty>
                }
            </CardStyle>

            {openDialog ?
                <Setting
                    typeDialog={typeDialog}
                    handleClose={handleClose}
                    open={openDialog}
                    data={data}
                    selectedCompany={selectedCompany && selectedCompany.idCompany}
                /> : ""
            }

        </div>
    );
}

export default CostElement;

{/* <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                >
                    <ButtonBlue
                        variant={"contained"}
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setOpenDialog(true)
                            setTypeDialog("Add")
                        }}
                    >
                        {t("AddItem")}
                    </ButtonBlue>
                </Box> */}