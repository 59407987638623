import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  CircularProgress,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  Tabs,
  Tab,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DomainIcon from "@mui/icons-material/Domain";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventIcon from "@mui/icons-material/Event";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import dayjs from "dayjs";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import {
  answerQuestion5Scale,
  answerQuestion6Scale,
} from "../../../../assets/data/answerScale";

const percentageScore = (questionList = []) => {
  let totalRating = 0;
  for (const item of questionList) {
    if (item.questionType === "choice") {
      totalRating += (item.answer * item.weight) / 100;
    }
  }

  return Math.round(totalRating);
};


const EmployeeProbationTab = ({ questionSet, answerScale, comment }) => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t("Employee")}
      </Typography>
      <Stack spacing={1} marginBottom={4} width={"100%"}>
        {questionSet.length > 0 ? (
          questionSet.map((question, index) => (
            <div key={question.idProbationQuestion}>
              <Typography gutterBottom fontWeight={500}>
                {`${t("Question")} ${index + 1} : ${question.question}`}
              </Typography>
              {question.questionType === "choice" ? (
                <>
                  <Typography color="text.secondary">{`${t("Weight")} ${question.weight}%`}</Typography>
                  <RadioGroup row value={question.answer}>
                    {answerScale === 6
                      ? answerQuestion6Scale.map((item) => (
                          <FormControlLabel
                            key={item.key}
                            value={item.value}
                            control={<Radio />}
                            label={t(item.label)}
                          />
                        ))
                      : answerQuestion5Scale.map((item) => (
                          <FormControlLabel
                            key={item.key}
                            value={item.value}
                            control={<Radio />}
                            label={t(item.label)}
                          />
                        ))}
                  </RadioGroup>
                </>
              ) : (
                <TextFieldTheme
                  fullWidth
                  value={question.answer}
                  InputProps={{ readOnly: true }}
                />
              )}
              <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
            </div>
          )
        )
      ) : (
        <Typography textAlign="center" color="text.secondary">
          {t("NoQuestions")}
        </Typography>
      )}
      </Stack>
      <Stack spacing={2} marginBottom={4}>
        <div>
          <Typography gutterBottom>
            {t("EmployeeFeedback")}
          </Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={comment}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
          <Box style={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              style={{
                color: "#eeeeee",
              }}
              size={70}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={percentageScore(questionSet)}
              style={{
                color: "#007afe",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
              }}
              size={70}
            ></CircularProgress>
            <Box
              style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontSize: 16 }}
                component="div"
                color="text.third"
              >
                {percentageScore(questionSet)}
              </Typography>
            </Box>
          </Box>
        </div>
      </Stack>
    </>
  )
}

const MangerProbationTab = ({
  managerLV,
  questionSet,
  answerScale,
  goodPoint, 
  weakPoint, 
  others,
  comment,
  resignDate,
  probationDate,
  result,
  adminResult,
  approvedBy,
  approvedBy_EN,
  reasonExpandTime,
  reasonTermination
}) => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {`${t("ManagerLV")} ${managerLV}`}
      </Typography>
      <Stack spacing={1} marginBottom={4} width={"100%"}>
        {questionSet && questionSet.length > 0 ? (
          questionSet.map(
            (question, index) => (
              <div key={question.idProbationQuestion}>
                <Typography gutterBottom fontWeight={500}>
                  {`${t("Question")} ${index + 1} : ${question.question}`}
                </Typography>
                {question.questionType === "choice" ? (
                  <>
                    <Typography color="text.secondary">{`${t("Weight")} ${question.weight}%`}</Typography>
                    <RadioGroup row value={question.answer}>
                      {answerScale === 6
                        ? answerQuestion6Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))
                        : answerQuestion5Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))}
                    </RadioGroup>
                  </>
                ) : (
                  <TextFieldTheme
                    fullWidth
                    value={question.answer}
                    InputProps={{ readOnly: true }}
                  />
                )}
                <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
              </div>
            )
          )
        ) : (
          <Typography textAlign="center" color="text.secondary">
            {t("NoQuestions")}
          </Typography>
        )}
      </Stack>
      <Stack spacing={2} marginBottom={4}>
        <div>
          <Typography gutterBottom>{t("Strength")}</Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={goodPoint}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <Typography gutterBottom>{t("Weakness")}</Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={weakPoint}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <Typography gutterBottom>{t("Other")}</Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={others}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <Typography gutterBottom>
            {t("FeedbackFromSupervisor")}
          </Typography>
          <TextFieldTheme
            multiline
            fullWidth
            value={comment}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
          <Box style={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              style={{
                color: "#eeeeee",
              }}
              size={70}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={percentageScore(questionSet)}
              style={{
                color: "#007afe",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
              }}
              size={70}
            ></CircularProgress>
            <Box
              style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontSize: 16 }}
                component="div"
                color="text.third"
              >
                {percentageScore(questionSet)}
              </Typography>
            </Box>
          </Box>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography fontWeight={500} color="text.secondary">
            {t("ProbationResult")}
          </Typography>
          {dayjs(resignDate).isBefore(probationDate) ? (
            <Chip label={t("Terminated")} color="error" />
          ) : result === 1 ? (
            <Chip label={t("Pass")} color="success" />
          ) : result === 0 ? (
            <Chip label={t("NotPass")} color="error" />
          ) : (
            <Chip label={t("WaitingForEvaluation")} color="warning" />
          )}
        </div>
        {(adminResult || approvedBy) && (
          <Typography fontWeight={500} color="text.secondary">
            {`${t("EvaluatedBy")} : ${
              adminResult
                ? t("Administrator")
                : i18n.resolvedLanguage === "th"
                ? approvedBy
                : approvedBy_EN
            }`}
          </Typography>
        )}
      </Stack>
      {reasonExpandTime && (
        <Grid item xs={12}>
          <Typography marginBottom={1}>
            {t("ReasonForExtendingProbation")}
          </Typography>
          <TextFieldTheme
            inputProps={{ readOnly: true }}
            multiline
            rows={3}
            value={reasonExpandTime || ""}
          />
        </Grid>
        )}
        {reasonTermination && (
          <div>
            <Typography gutterBottom>
              {t("ReasonForNotPassingProbation")}
            </Typography>
            <TextFieldTheme
              inputProps={{ readOnly: true }}
              multiline
              rows={3}
              value={reasonTermination || t("ReasonForNotPassingProbation")}
            />
          </div>
        )}
    </>
  )
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const DialogDetail = ({ open, handleClose, probation }) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (probation) {
      setValue(
        probation.managerLV2Result !== null
          ? 2
          : probation.managerLV1Result !== null
          ? 1
          : 0
      );
    }
  }, [probation]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t("Details")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} marginBottom={6}>
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <PersonIcon />
            <Typography>
              {` ${t("PersonBeingAssessed")} : ${probation.firstname_TH} ${
                probation.lastname_TH
              }`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <AssignmentIcon />
            <Typography>
              {`${t("questionSet")} : ${probation.questionSet.questionSetName}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <AssessmentIcon />
            <Typography>
              {`${t("ProbationRound")} : ${probation.probationRound}`}
            </Typography>
          </Grid>
          {probation.expandTime && (
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <AddCircleIcon />
              <Typography>
                {`${t("ExtendProbationPeriod")}: ${probation.expandTime} ${t(
                  "Unit.Days"
                )}`}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <EventIcon />
            <Typography>
              {`${t("ProbationaryExpirationDate")} :  ${dayjs(
                probation.probationDate
              ).format(
                i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
              )}`}
            </Typography>
          </Grid>
          {probation.expandTime && (
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <AddCircleIcon />
              <Typography>
                {`${t("ExtendProbationPeriod")}: ${probation.expandTime} ${t(
                  "Unit.Days"
                )}`}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <CheckCircleIcon />
            <Typography>
              {`${t("EvaluatedBy")} : ${
                probation.adminEvaluateDate
                  ? t("Administrator")
                  : i18n.resolvedLanguage === "th"
                  ? probation.approvedBy
                  : probation.approvedBy_EN
              }`}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {probation.employeeEvaluateDate && (
              <Tab label={t("Employee")} {...a11yProps(0)} />
            )}
            {probation.managerLV1Result && (
              <Tab label={`${t("ManagerLV")} 1`} {...a11yProps(1)} />
            )}
            {probation.managerLV2Result && (
              <Tab label={`${t("ManagerLV")} 2`} {...a11yProps(2)} />
            )}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <EmployeeProbationTab
            questionSet={probation.questionSet.employeeQuestion}
            answerScale={probation.questionSet.answerScale}
            comment={probation.employeeComment}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <MangerProbationTab
            managerLV={1}
            questionSet={probation.questionSet.managerLV1Question}
            answerScale={probation.questionSet.answerScale}
            goodPoint={probation.managerLV1GoodPoint}
            weakPoint={probation.managerLV1WeakPoint}
            others={probation.managerLV1Others}
            comment={probation.managerLV1Comment}
            resignDate={probation.resignDate}
            probationDate={probation.probationDate}
            result={probation.managerLV1Result}
            adminResult={probation.adminResult}
            approvedBy={probation.managerLV1Name_TH}
            approvedBy_EN={probation.managerLV1Name_EN}
            reasonExpandTime={probation.managerLV1ReasonExpandTime}
            reasonTermination={probation.managerLV1ReasonTermination}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <MangerProbationTab
            managerLV={2}
            questionSet={probation.questionSet.managerLV2Question}
            answerScale={probation.questionSet.answerScale}
            goodPoint={probation.managerLV2GoodPoint}
            weakPoint={probation.managerLV2WeakPoint}
            others={probation.managerLV2Others}
            comment={probation.managerLV2Comment}
            resignDate={probation.resignDate}
            probationDate={probation.probationDate}
            result={probation.managerLV2Result}
            adminResult={probation.adminResult}
            approvedBy={probation.managerLV2Name_TH}
            approvedBy_EN={probation.managerLV2Name_EN}
            reasonExpandTime={probation.managerLV2ReasonExpandTime}
            reasonTermination={probation.managerLV2ReasonTermination}
          />
        </CustomTabPanel>
        {/* <Typography variant="h6" gutterBottom>
          {"ส่วนพนักงาน"}
        </Typography> */}
        {/* <Stack spacing={1} marginBottom={4}>
          {probation.questionSet.employeeQuestion.length > 0 ? (
            probation.questionSet.employeeQuestion.map((question, index) => (
              <div key={question.idProbationQuestion}>
                <Typography gutterBottom fontWeight={500}>
                  {`${t("Question")} ${index + 1} : ${question.question}`}
                </Typography>
                {question.questionType === "choice" ? (
                  <>
                    <Typography color="text.secondary">{`${t("Weight")} ${question.weight}%`}</Typography>
                    <RadioGroup row value={question.answer}>
                      {probation.questionSet.answerScale === 6
                        ? answerQuestion6Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))
                        : answerQuestion5Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))}
                    </RadioGroup>
                  </>
                ) : (
                  <TextFieldTheme
                    fullWidth
                    value={question.answer}
                    InputProps={{ readOnly: true }}
                  />
                )}
                <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
              </div>
            ))
          ) : (
            <Typography textAlign="center" color="text.secondary">
              {t("NoQuestions")}
            </Typography>
          )}
        </Stack> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px 0px",
          }}
        >
          <Box style={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              style={{
                color: "#eeeeee",
              }}
              size={70}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={percentageScore(probation.questionSet.employeeQuestion)}
              style={{
                color: "#007afe",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
              }}
              size={70}
            ></CircularProgress>
            <Box
              style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontSize: 16 }}
                component="div"
                color="text.third"
              >
                {percentageScore(probation.questionSet.employeeQuestion)}
              </Typography>
            </Box>
          </Box>
        </div> */}

        {/* <Typography variant="h6" gutterBottom>
          {t("Manager")}
        </Typography> */}
        {/* <Stack spacing={1} marginBottom={4}>
          {probation.questionSet.managerQuestion.length > 0 ? (
            probation.questionSet.managerQuestion.map((question, index) => (
              <div key={question.idProbationQuestion}>
                <Typography gutterBottom fontWeight={500}>
                  {`${t("Question")} ${index + 1} : ${question.question}`}
                </Typography>
                {question.questionType === "choice" ? (
                  <>
                    <Typography color="text.secondary">
                      {`${t("Weight")} ${question.weight}%`}
                    </Typography>
                    <RadioGroup value={question.answer} row>
                      {probation.questionSet.answerScale === 6
                        ? answerQuestion6Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))
                        : answerQuestion5Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))}
                    </RadioGroup>
                  </>
                ) : (
                  <TextFieldTheme
                    multiline
                    value={question.answer}
                    InputProps={{ readOnly: true }}
                  />
                )}
                <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
              </div>
            ))
          ) : (
            <Typography textAlign="center" color="text.secondary">
              {t("NoQuestions")}
            </Typography>
          )}
        </Stack> */}
        {/* <Stack spacing={2} marginBottom={4}>
          <div>
            <Typography gutterBottom>{t("Strength")}</Typography>
            <TextFieldTheme
              multiline
              value={probation.goodPoint}
              InputProps={{ readOnly: true }}
            />
          </div>
          <div>
            <Typography gutterBottom>{t("Weakness")}</Typography>
            <TextFieldTheme
              multiline
              value={probation.weakPoint}
              InputProps={{ readOnly: true }}
            />
          </div>
          <div>
            <Typography gutterBottom>{t("Other")}</Typography>
            <TextFieldTheme
              multiline
              value={probation.others}
              InputProps={{ readOnly: true }}
            />
          </div>
          <div>
            <Typography gutterBottom>
              {t("FeedbackFromSupervisor")}
            </Typography>
            <TextFieldTheme
              multiline
              value={probation.managerComment}
              InputProps={{ readOnly: true }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Box style={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                variant="determinate"
                style={{
                  color: "#eeeeee",
                }}
                size={70}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                value={percentageScore(probation.questionSet.managerQuestion)}
                style={{
                  color: "#007afe",
                  animationDuration: "550ms",
                  position: "absolute",
                  left: 0,
                }}
                size={70}
              ></CircularProgress>
              <Box
                style={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ fontSize: 16 }}
                  component="div"
                  color="text.third"
                >
                  {percentageScore(probation.questionSet.managerQuestion)}
                </Typography>
              </Box>
            </Box>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography fontWeight={500} color="text.secondary">
              {t("ProbationResult")}
            </Typography>
            {dayjs(probation.resignDate).isBefore(probation.probationDate) ? (
              <Chip label={t("Terminated")} color="error" />
            ) : result === 1 ? (
              <Chip label={t("Pass")} color="success" />
            ) : result === 0 ? (
              <Chip label={t("NotPass")} color="error" />
            ) : (
              <Chip label={t("WaitingForEvaluation")} color="warning" />
            )}
          </div>
          <Typography fontWeight={500} color="text.secondary">
            {`${t("EvaluatedBy")} : ${
              probation.adminResult
                ? t("Administrator")
                : i18n.resolvedLanguage === "th"
                ? probation.approvedBy
                : probation.approvedBy_EN
            }`}
          </Typography>
        </Stack> */}
        {/* {probation.reasonTermination && (
          <Box marginBottom={4}>
            <Typography marginBottom={1}>
              {t("ReasonForNotPassingProbation")}
            </Typography>
            <TextFieldTheme
              multiline
              value={probation.reasonTermination}
              InputProps={{ readOnly: true }}
            />
          </Box>
        )} */}
      </DialogContent>
      <DialogActions>
        <ButtonBlue variant="contained" onClick={handleClose}>
          {t("Close")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDetail;
