import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdditionSpecial2New, duplicateAdditionSpecial2New, getAdditionSpecial2New } from "../../../../../../../actions/additionSpecial";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import AS2Form from "./AS2Form";
import additionSepecialService from "../../../../../../../services/additionSepecial.service";
import DrawerDownload from "../drawerDownload";
import { openNotificationAlert } from "../../../../../../../actions/notificationAlert";
import { getUserPosition } from "../../../../../../../utils/userData";
import utils from '../../../../../../../utils'
import Loading from "../../../../../shared/loading";

const AS2 = ({ selectedCompany }) => {
  const category = 'AS2'
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: additionSpecial,isFetching:additionSpecialFetching } = useSelector(
    (state) => state.additionSpecial2
  );
  const [openEdit, setOpenEdit] = useState(false);
  const [insert, setInsert] = useState(false);
  const [selectAddition, setSelectAddition] = useState(null);
  const [openDownload, setOpenDownload] = useState(false);

  const handleDelete = async (item) => {
    if (selectedCompany) {
      const res = await dispatch(deleteAdditionSpecial2New(item.idAdditionSpecialConditionTime))
      if (res && res.status === 201) {
        dispatch(getAdditionSpecial2New({type:category,idCompany:selectedCompany.idCompany}));
      } else {
        dispatch(openNotificationAlert({ message: "error", type: "error" }));
      }
    }
  };

  const handleDuplicate = async (item) => {
    if (selectedCompany) {
      const res = await dispatch(duplicateAdditionSpecial2New(item.idAdditionSpecialConditionTime))
      if (res && res.status === 201) {
        dispatch(getAdditionSpecial2New({type:category,idCompany:selectedCompany.idCompany}));
      } else {
        dispatch(openNotificationAlert({ message: "error", type: "error" }));
      }
    }
  };

  const handleOpenDownload = () => {
    setOpenDownload(true);
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
  };

  const handleOpenAdd = () => {
    setOpenEdit(true);
    setInsert(true);
  };

  const handleOpenEdit = (item) => {
    setSelectAddition(item);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setSelectAddition(null);
    setOpenEdit(false);
    setInsert(false);
    if (selectedCompany) {
      dispatch(getAdditionSpecial2New({type:category,idCompany:selectedCompany.idCompany}));
    }
  };

  const handleDownload = async (data) => {
    const res = await additionSepecialService.exportExcelAdditionSpecial2({
      idCompany: selectedCompany.idCompany,
      searchDate: dayjs(data.searchDate).format("YYYY-MM-DD"),
      idAdditionSpecial2: data.additionSpecial.idAdditionSpecial2,
    });

    if (res && res.status === 200) {
      const excelBlob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(excelBlob);

      downloadLink.download = `${data.additionSpecial.additionSpecialConditionTimeName} ${selectedCompany.companyName_EN
        } ${res.headers["content-disposition"]
          .split("filename")[1]
          .slice(2, -1)}`;

      downloadLink.click();
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
  };

  const handleCalculateAdditionSpecialConditionTime = async () => {
    const res = await additionSepecialService.calculateAdditionSpecialConditionTime({
      idCompany: selectedCompany.idCompany,
      month: "7",
      year: "2024"
    });
  }

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAdditionSpecial2New({type:category,idCompany:selectedCompany.idCompany}));
      handleCloseEdit();
    }
  }, [selectedCompany.idCompany]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        minHeight={"40px"}
        marginBottom={"24px"}
      >
        <Typography
          sx={{
            paddingLeft: "8px",
            borderLeft: "8px solid #46cbe2",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
          fontSize="20px"
          fontWeight="500"
        >
          {`${t("TimeConditions")} (AS2)`}
        </Typography>
        {!openEdit && (
          <div style={{ display: "flex", gap: "8px" }}>
            <IconButton
              onClick={handleOpenAdd}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <AddIcon />
            </IconButton>
            {/* <IconButton
              onClick={handleOpenDownload}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              onClick={handleCalculateAdditionSpecialConditionTime}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <DownloadIcon />
            </IconButton> */}
          </div>
        )}
      </Stack>
      {openEdit && (
        <AS2Form
          insert={insert}
          handleClose={handleCloseEdit}
          selectedCompany={selectedCompany}
          selectAddition={selectAddition || []}
          category={category}
        />
      )}
      {!openEdit && additionSpecial && additionSpecial.length > 0 ? additionSpecial.map((addition) => (
        <Grid
          container
          spacing={2}
          paddingBottom={2}
          marginTop={2}
          borderRadius={"8px"}
          bgcolor={"#e7fbff"}
          key={addition.idAdditionSpecialConditionTime}
          wrap={'wrap'}
          style={{
            padding: "16px",
            borderRadius: "8px",
            backgroundColor: "#f2f2f2",
          }}
        >
          <Grid item xs={12} display={"flex"} gap={"8px"}>
            <Typography variant="h5">{addition.additionSpecialConditionTimeName}</Typography>
            <IconButton
              onClick={() => handleOpenEdit(addition)}
              sx={{
                color: "#ffffff",
                backgroundColor: "#ffb74d",
                "&:hover": { backgroundColor: "#ffa726" },
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
            {/* <IconButton
              onClick={() => handleDelete(addition)}
              sx={{
                color: "#ffffff",
                backgroundColor: "#f44336",
                "&:hover": { backgroundColor: "#e53935" },
              }}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDuplicate(addition)}
              sx={{
                color: "#ffffff",
                backgroundColor: "#2196f3",
                "&:hover": { backgroundColor: "#1976d2" },
              }}
              size="small"
            >
              <ContentCopyIcon />
            </IconButton> */}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography fontWeight={500} fontSize={18}>
              {t("EmployeeType")}
            </Typography>
            <Box>
              {addition.employmentTypes.length === 0 ? (
                <Typography>{t("AllEmployeeType")}</Typography>
              ) : (
                addition.employmentTypes.map((item) => (
                  <Typography key={item.idEmploymentType}>
                    {item.employmentTypeName}
                  </Typography>
                ))
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography fontWeight={500} fontSize={18}>
              {t("Position")}
            </Typography>
            <Box>
              {addition.positions.length === 0 ? (
                <Typography>{t("AllPosition")}</Typography>
              ) : (
                addition.positions.map((item) => (
                  <Typography key={item.idPosition}>
                    {getUserPosition(item)}
                  </Typography>
                ))
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography fontWeight={500} fontSize={18}>
              {t("GroupLevel")}
            </Typography>
            <Box>
              {addition && addition.levels && addition.levels.length > 0 ?
                addition.groupLevels.length === 0 ? <Typography>{t("JobLevel")}</Typography> : (
                  <Typography>{t("AllGroupLevel")}</Typography>
                ) : (
                  addition.groupLevels.length === 0 ? <Typography>{t("AllGroupLevel")}</Typography> :
                  addition.groupLevels.map((item) => (
                    <Typography key={item.idGroupLevel}>
                      {item.groupLevelName}
                    </Typography>
                  ))
                )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography fontWeight={500} fontSize={18}>
              {t("Probation")}
            </Typography>
            <Box>
              {addition.isProbation === 1 ? (
                <Typography>{t("PassedProbation")}</Typography>
              ) : (
                <Typography>{t("NotPassedProbation")}</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography fontWeight={500} fontSize={18} marginBottom={2}>
              {t("Condition")}
            </Typography>
            <TableContainer component={Paper} sx={{  overflow: 'auto' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("TimeConditions")}</TableCell>
                    <TableCell align="center">{t("Minimum")}</TableCell>
                    <TableCell align="center">{t("Maximum")}</TableCell>
                    {(addition && addition.groupLevels && addition.groupLevels.length > 0) || (addition && addition.levels && addition.levels.length === 0) && <TableCell align="center">{t("AmountOfMoney")}</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addition ? (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {addition.type ? t(addition.type) : t('NoFee')}
                      </TableCell>

                      <TableCell align="center">
                        {addition.min != null ? utils.numberWithCommasWithoutDot(addition.min) : '0'}
                      </TableCell>

                      <TableCell align="center">
                        {addition.max != null ? utils.numberWithCommasWithoutDot(addition.max) : '0'}
                      </TableCell>
                      {(addition && addition.groupLevels && addition.groupLevels.length > 0) || (addition && addition.levels && addition.levels.length === 0) && 
                      <TableCell align="center">
                        {addition.amount != null ? utils.numberWithCommasWithoutDot(addition.amount) : '0'}
                      </TableCell>}
                    </TableRow>
                  ) : (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" colSpan={3}>No data found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {addition && addition.levels && addition.levels.length > 0 && <Grid item xs={12} sm={6}>
            <Typography fontWeight={500} fontSize={18} marginBottom={2}>
              {t("JobLevel")}
            </Typography>
            <TableContainer component={Paper} sx={{ overflow: 'auto' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("Name")}</TableCell>
                    <TableCell align="center">{t("Amount")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addition && addition.levels && addition.levels.slice(0, 5).map((item) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      key={item.idAdditionSpecialConditionTimePersonalLevelPrice}
                    >
                      <TableCell align="center">{item.levelName}</TableCell>
                      <TableCell align="center">{utils.numberWithCommasWithoutDot(item.price)}</TableCell>
                    </TableRow>
                  ))}
                  {
                    addition && addition.levels && addition.levels.length > 5 && (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">...</TableCell>
                        <TableCell align="center">...</TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>}
        </Grid>
      )) :
        !openEdit && additionSpecialFetching && <Loading />
      }
      {additionSpecial && openDownload && (
        <DrawerDownload
          openDownload={openDownload}
          handleDownload={handleDownload}
          handleCloseDownload={handleCloseDownload}
          additionSpecial={additionSpecial}
          idCompareOption={"idAdditionSpecial2"}
        />
      )}
    </>
  );
};

export default AS2;
