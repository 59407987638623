import React, { useEffect, useState } from "react";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import TableCustom from "../../shared/tableCustom";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import { getProbationApproveHistory } from "../../../../actions/probation";
import StatusRequest from "../../shared/general/stausRequest";

const RequestProbation = ({ searchDate }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: employeesProbation } = useSelector(
    (state) => state.employeesProbation
  );

  const [pendingRows, setPendingRows] = useState([]);

  const columns = [
    {
      name: `${t("Status")}`,
      cellRender: (row) => (
        <StatusRequest
          boxSize={40}
          fontSize={22}
          borderRadius={8}
          status={row.adminResult !== null ? row.adminResult : row.managerResult}
        />
      ),
    },
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("HiringDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign="center">
          {dayjs(row.hiringDate).format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: `${t("ProbationaryExpirationDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {dayjs(row.probationDate).format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: `${t("ProbationRound")}`,
      minWidth: "150px",
      width: "150px",
      headerTextAlign: "center",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.probationRound === 1 ? (
            <Chip color="primary" label={`${t("RoundNo")} 1`} />
          ) : (
            <Chip color="secondary" label={`${t("RoundNo")} 2`} />
          )}
        </Box>
      ),
    },
    {
      name: `${t("EvaluationDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {dayjs(
            row.adminEvaluateDate !== null
              ? row.adminEvaluateDate
              : row.managerEvaluateDate
          ).format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: `${t("Assessor")}`,
      headerTextAlign: "center",
      minWidth: "120px",
      width: "120px",
      cellRender: (row) => (
        <Typography fontSize="16px" color="text.secondary" textAlign="center">
          {row.adminResult !== null ? t("Administrator") : t("Manager")}
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    if (employeesProbation) {
      let tempPending = [...employeesProbation];
      if (searchDate.start || searchDate.end) {
        tempPending = employeesProbation.filter((item) =>
          dayjs(
            item.adminEvaluateDate !== null
              ? item.adminEvaluateDate
              : item.managerEvaluateDate
          ).isBetween(searchDate.start || dayjs(), searchDate.end || dayjs())
        );
      }
      setPendingRows(tempPending);
    }
  }, [employeesProbation, searchDate]);

  useEffect(() => {
    dispatch(getProbationApproveHistory());
  }, []);

  return (
    <TableCustom columns={columns} rows={pendingRows ? pendingRows : []} />
  );
};

export default RequestProbation;
