import ExcelJS from "exceljs";
import dayjs from "dayjs";
import "dayjs/locale/th";
import utils from "../../../../utils";
import { getUserCompany, getUserFirstName, getUserLastName, getUserPosition } from "../../../../utils/userData";

function getAgeMonthYear(dateString, t) {
  var now = new Date();

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(
    dateString.substring(6, 10),
    dateString.substring(3, 5) - 1,
    dateString.substring(0, 2)
  );

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = "";
  var yearString = t("Year");
  var monthString = t("Month");

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  if (age.years > 0 && age.months > 0 && age.days > 0)
    ageString = age.years + yearString + " " + age.months + monthString;
  else if (age.years > 0 && age.months === 0 && age.days === 0)
    ageString = age.years + yearString;
  else if (age.years > 0 && age.months > 0 && age.days === 0)
    ageString = age.years + yearString + " " + age.months + monthString;
  else if (age.years === 0 && age.months > 0 && age.days > 0)
    ageString = age.months + monthString + " ";
  else if (age.years > 0 && age.months === 0 && age.days > 0)
    ageString = age.years + yearString + " ";
  else if (age.years === 0 && age.months > 0 && age.days === 0)
    ageString = age.months + monthString;
  else ageString = "ไม่สามารถคำนวณอายุได้";

  return ageString;
}

export const performanceXlsxFile = async (
  t,
  currentLanguage,
  data,
  year,
  companyName
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet1 = workbook.addWorksheet(t(`Performance ${year}`));

  const headerRow = worksheet1.addRow([
    t("EmployeeID"),
    t("FirstName"),
    t("LastName"),
    t("Division"),
    t("Department"),
    t("Section"),
    t("Position"),
    t("HiringDate"),
    t("LevelGroup"),
    t("Level"),
    t("JobLevel"),
    t("TIG"),
    t("ESY"),
    t("ServiceYear"),
    t("Age"),
    t("EmployeeEvaluation"),
    t("EvaluationDate"),
    t("ManagerEvaluation"),
    t("EvaluationDate"),
    t("EvaluatedBy"),
    t("PerformanceRating"),
    t("PerformanceAssessmentLevels"),
    t("LastUpdate"),
  ]);

  const headerStyle = {
    font: {
      bold: true,
      size: 18,
      name: "TH SarabunPSK",
      color: { argb: "FFFFFF" },
    },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    },
  };

  headerRow.eachCell((cell) => {
    cell.style = headerStyle;
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  headerRow.height = 50;

  let colWidths = [
    { key: "employeeID", width: 30 },
    { key: "firstname_TH", width: 30 },
    { key: "lastname_TH", width: 30 },
    { key: "division", width: 30 },
    { key: "department", width: 30 },
    { key: "section", width: 30 },
    { key: "position", width: 30 },
    { key: "hiringDate", width: 40 },
    { key: "personnelLevelGroupName", width: 40 },
    { key: "personnelLevelName", width: 40 },
    { key: "jobLevelName", width: 70 },
    { key: "tig", width: 40 },
    { key: "esy", width: 30 },
    { key: "serviceYear", width: 40 },
    { key: "age", width: 40 },
    { key: "employeeEvaluateDate", width: 70 },
    { key: "employeeEvaluateStatus", width: 40 },
    { key: "managerEvaluateDate", width: 30 },
    { key: "managerEvaluateStatus", width: 30 },
    { key: "approvedBy", width: 30 },
    { key: "estimateScore", width: 30 },
    { key: "estimateLevel", width: 30 },
    { key: "updateDate", width: 30 },
  ];

  const calculatorESY = (employee) => {
    let Serv = utils.getAgeNumber(
      dayjs(employee.hiringDate).format("DD/MM/YYYY")
    );

    let monthAge = (Serv.months + employee.OESY_M) % 12;
    let yearAge = Math.floor((Serv.months + employee.OESY_M) / 12);

    return `${Serv.years + employee.OESY_Y + yearAge} ${t(
      "Year"
    )} ${monthAge} ${t("Month")}`;
  };

  data.forEach((item) => {
    const tig = item.employeePersonnelLevelStart
      ? `${getAgeMonthYear(
          dayjs(item.employeePersonnelLevelStart).format("DD/MM/YYYY"), t)}`
      : "-";

    const esy = item.hiringDate ? calculatorESY(item) : "-";

    const serviceYear = item.hiringDate
      ? `${getAgeMonthYear(
          dayjs(item.hiringDate).format("DD/MM/YYYY"), t)}`
      : "-";

    const age = utils.getAge(
      dayjs(item.birthday).format("DD/MM/YYYY"),
      currentLanguage
    );

    const row = [
      item.employeeID ? item.employeeID : "-",
      getUserFirstName(item) ? getUserFirstName(item) : "-",
      getUserLastName(item) ? getUserLastName(item) : "-",
      item.divisionName ? item.divisionName : "-",
      item.departmentName ? item.departmentName : "-",
      item.sectionName ? item.sectionName : "-",
      getUserPosition(item) ? getUserPosition(item) : "-",
      item.hiringDate,
      item.personnelLevelGroupName,
      item.personnelLevelName,
      item.jobLevelName,
      tig,
      esy,
      serviceYear,
      age,
      item.employeeEvaluateDate ? t("Evaluated") : t("WaitingForEvaluation"),
      item.employeeEvaluateDate
        ? dayjs(item.employeeEvaluateDate).format(
            currentLanguage === "th" ? "DD-MM-YYYY" : "DD-MM-BBBB"
          )
        : "-",
      item.managerEvaluateDate ? t("Evaluated") : t("WaitingForEvaluation"),
      item.managerEvaluateDate
        ? dayjs(item.managerEvaluateDate).format(
            currentLanguage === "th" ? "DD-MM-YYYY" : "DD-MM-BBBB"
          )
        : "-",
      item.approvedBy ? item.approvedBy : "-",
      item.estimateScore ? item.estimateScore : "-",
      item.estimateLevel ? item.estimateLevel : "-",
      item.updateDate
        ? dayjs(item.updateDate).format(
            currentLanguage === "th" ? "DD-MM-YYYY" : "DD-MM-BBBB"
          )
        : "-",
    ];

    const excelRow = worksheet1.addRow(row);

    colWidths.forEach((col, index) => {
      worksheet1.getColumn(index + 1).width = col.width;
    });

    excelRow.eachCell((cell, colNumber) => {
      cell.font = { size: 18, name: "TH SarabunPSK" };

      if (colNumber >= 16 && colNumber <= 23) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "E4DFEC" },
        };
      }

      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${getUserCompany(companyName)}-${t("Performance")}-${year}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
};
