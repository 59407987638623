import {
  DOCUMENT_FETCHING,
  DOCUMENT_FAILED,
  DOCUMENT_SUCCESS,
  DOCUMENT_COMAPANY_FETCHING,
  DOCUMENT_COMAPANY_FAILED,
  DOCUMENT_COMAPANY_SUCCESS,
  DOCUMENT_ITEM_FETCHING,
  DOCUMENT_ITEM_FAILED,
  DOCUMENT_ITEM_SUCCESS,
  DOCUMENT_GROUP_FAILED,
  DOCUMENT_GROUP_SUCCESS,
  DOCUMENT_GROUP_FETCHING,
  SMART_DOCUMENT_FETCHING,
  SMART_DOCUMENT_FAILED,
  SMART_DOCUMENT_SUCCESS,
  EMPLOYEE_DOCUMENTS_FETCHING,
  EMPLOYEE_DOCUMENTS_FAILED,
  EMPLOYEE_DOCUMENTS_SUCCESS,
  COMPANY_DOCUMENTS_FETCHING,
  COMPANY_DOCUMENTS_FAILED,
  COMPANY_DOCUMENTS_SUCCESS,
  GET_UNION_DOCUMENT_GROUP_TYPE_FETCHING,
  GET_UNION_DOCUMENT_GROUP_TYP_SUCCESS,
  GET_UNION_DOCUMENT_GROUP_TYPE_FAILURE,
  GET_ALL_DOCUMENT_TYPE_NAME_FETCHING,
  GET_ALL_DOCUMENT_TYPE_NAME_FAILURE,
  GET_ALL_DOCUMENT_TYPE_NAME_SUCCESS,
  GET_SMART_DOCUMENTS_FETCHING,
  GET_SMART_DOCUMENT_SUCCESS,
  GET_SMART_DOCUMENT_FAILED,
  GET_ISUPLOAD_DOCUMENTS_FETCHING,
  GET_ISUPLOAD_DOCUMENTS_SUCCESS,
  GET_ISUPLOAD_DOCUMENTS_FAILED,
  GET_ALL_DOCUMENT_TYPE_FETCHING,
  GET_ALL_DOCUMENT_TYPE_FAILURE,
  GET_ALL_DOCUMENT_TYPE_SUCCESS,
  GET_ALL_DOCUMENT_GROUP_NAME_FETCHING,
  GET_ALL_DOCUMENT_GROUP_NAME_FAILURE,
  GET_ALL_DOCUMENT_GROUP_NAME_SUCCESS,
  SMART_DOCUMENT_FETCHING_FILE,
  SMART_DOCUMENT_FAILED_FILE,
  SMART_DOCUMENT_SUCCESS_FILE,
} from "./types";

import DocumentService from "../services/document.service";

export const getAllDocuments = () => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_FETCHING,
    });
    const res = await DocumentService.getAllDocuments();
    if (res) {
      dispatch({
        type: DOCUMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_FAILED,
    });
    console.log(err);
  }
};

export const getAllDocumentsById = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_FETCHING,
    });
    const res = await DocumentService.getAllDocumentsById(idCompany);
    if (res) {
      dispatch({
        type: DOCUMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_FAILED,
    });
    console.log(err);
  }
};

export const getAllDocumentsByIdFilter = (query) => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_FETCHING,
    });
    const res = await DocumentService.getAllDocumentsByIdFilter(query);
    if (res) {
      dispatch({
        type: DOCUMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_FAILED,
    });
    console.log(err);
  }
};

export const getallCompanyDocuments = () => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_COMAPANY_FETCHING,
    });
    const res = await DocumentService.getallCompanyDocuments();
    if (res) {
      dispatch({
        type: DOCUMENT_COMAPANY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_COMAPANY_FAILED,
    });
    console.log(err);
  }
};

export const getallSmartDocumentsById = (IdCompay, page = null, isShow) => async (dispatch) => {
  try {
    dispatch({
      type: SMART_DOCUMENT_FETCHING,
    });
    const res = await DocumentService.getallSmartDocumentsById(IdCompay);
    let payload = res.data

    if (page === 'additional') {
      const additional = await DocumentService.getAllAdditionalSmartDocument(IdCompay, isShow && isShow);
      payload = {
        option: res.data,
        additional: additional.data
      }
    }

    if (res) {
      dispatch({
        type: SMART_DOCUMENT_SUCCESS,
        payload: payload,
      });
    }
  } catch (err) {
    dispatch({
      type: SMART_DOCUMENT_FAILED,
    });
    console.log(err);
  }
};

export const getDocument = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_ITEM_FETCHING,
    });
    const res = await DocumentService.getDocument(id);
    if (res) {
      dispatch({
        type: DOCUMENT_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const getDowloadCompanyDocument = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_DOCUMENTS_FETCHING
    });
    const res = await DocumentService.getDowloadCompanyDocument(idCompany);
    if (res) {
      dispatch({
        type: COMPANY_DOCUMENTS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: COMPANY_DOCUMENTS_FAILED,
    });
  }
};

export const getDowloadEmployeeDocument = (idEmployees, idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_DOCUMENTS_FETCHING
    });
    const res = await DocumentService.getDowloadEmployeeDocument(idEmployees, idCompany);
    if (res) {
      dispatch({
        type: EMPLOYEE_DOCUMENTS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DOCUMENTS_FAILED,
    });
  }
};

// export const getisUploadEmployeeDocument = (idEmployees, idCompany) => async (dispatch) => {
//   try {
//     dispatch({
//       type: GET_ISUPLOAD_DOCUMENTS_FETCHING
//     });
//     const res = await DocumentService.getisUploadEmployeeDocument(idEmployees, idCompany);
//     if (res) {
//       dispatch({
//         type: GET_ISUPLOAD_DOCUMENTS_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type:  GET_ISUPLOAD_DOCUMENTS_FAILED
//     });
//   }
// }; 

export const getDowloadSmartDocument = (idEmployees, idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SMART_DOCUMENTS_FETCHING
    });
    const res = await DocumentService.getDowloadSmartDocument(idEmployees, idCompany);
    if (res) {
      dispatch({
        type: GET_SMART_DOCUMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SMART_DOCUMENT_FAILED,
    });
  }
};

export const getDocumentGroupName = () => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_GROUP_FETCHING,
    });
    const res = await DocumentService.getDocumentGroupName();
    if (res) {
      dispatch({
        type: DOCUMENT_GROUP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_GROUP_FAILED,
    });
    console.log(err);
  }
};

export const getDocumentTypeName = (idDocumentGroup, id, idDocumentType, documentTypeName, idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_ITEM_FETCHING,
    });
    const res = await DocumentService.getDocumentTypeName(idDocumentGroup, id, idDocumentType, documentTypeName, idCompany);
    if (res) {
      dispatch({
        type: DOCUMENT_ITEM_SUCCESS,
        payload: res,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_ITEM_FAILED,
    });
    console.log(err);
    return err.response;
  }
};

export const getAllDocumentTypesByCompanyId = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_DOCUMENT_TYPE_NAME_FETCHING,
    });
    const res = await DocumentService.getAllDocumentTypesByCompanyId(idCompany);
    if (res) {
      dispatch({
        type: GET_ALL_DOCUMENT_TYPE_NAME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_DOCUMENT_TYPE_NAME_FAILURE,
    });
    console.log(err);
  }
};

// export const getAllDocumentGroupName = () => async (dispatch) => {
//   try {
//     dispatch({
//       type: GET_ALL_DOCUMENT_TYPE_NAME_FAILURE,
//     });
//     const res = await DocumentService.getAllDocumentTypeName();
//     if (res) {
//       dispatch({
//         type: GET_ALL_DOCUMENT_TYPE_NAME_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: GET_ALL_DOCUMENT_TYPE_NAME_FAILURE,
//     });
//     console.log(err);
//   }
// };

export const getAllCompanyDocumentGroupName = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_DOCUMENT_GROUP_NAME_FETCHING,
    });
    const res = await DocumentService.getAllCompanyDocumentGroupName();
    if (res) {
      dispatch({
        type: GET_ALL_DOCUMENT_GROUP_NAME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_DOCUMENT_GROUP_NAME_FAILURE,
    });
    console.log(err);
  }
};

export const getUnionDocumentGroupAndTypeName = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_UNION_DOCUMENT_GROUP_TYPE_FETCHING,
    });
    const res = await DocumentService.getUnionDocumentGroupAndTypeName();
    if (res) {
      dispatch({
        type: GET_UNION_DOCUMENT_GROUP_TYP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_UNION_DOCUMENT_GROUP_TYPE_FAILURE,
    });
    console.log(err);
  }
};

export const getAllCompanyDocumentType = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_DOCUMENT_TYPE_FETCHING,
    });
    const res = await DocumentService.getAllCompanyDocumentType();
    if (res) {
      dispatch({
        type: GET_ALL_DOCUMENT_TYPE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_DOCUMENT_TYPE_FAILURE,
    });
    console.log(err);
  }
};

export const getAllCompanyDocumentFile = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_DOCUMENT_TYPE_FETCHING,
    });
    const res = await DocumentService.getAllCompanyDocumentFile();
    if (res) {
      dispatch({
        type: GET_ALL_DOCUMENT_TYPE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_DOCUMENT_TYPE_FAILURE,
    });
    console.log(err);
  }
};

export const addDocument = (formData) => async () => {
  try {
    console.log(formData);
    const res = await DocumentService.addDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addDocumentType = (formData) => async () => {
  try {
    console.log(formData);
    const res = await DocumentService.addDocumentType(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addCompanyDocumentType = (formData) => async () => {
  try {
    const res = await DocumentService.addCompanyDocumentType(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addAllDocumentTypeName = (formData) => async () => {
  try {
    const res = await DocumentService.addAllDocumentTypeName(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addDocumentZip = (formData) => async () => {
  try {
    const res = await DocumentService.addDocumentZip(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addAllCompanyDocumentType = (formData) => async () => {
  try {
    const res = await DocumentService.addAllCompanyDocumentType(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addCompanyDocumentGroup = (formData) => async () => {
  try {
    const res = await DocumentService.addCompanyDocumentGroup(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addCompanyDocument = (formData) => async () => {
  try {
    const res = await DocumentService.addCompanyDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const createPdfDocument = (formData) => async () => {
  try {
    const res = await DocumentService.createPdfDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const editDocument = (formData) => async () => {
  try {
    console.log(formData);
    const res = await DocumentService.editDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const editCompanyDocument = (formData) => async () => {
  try {
    const res = await DocumentService.editCompanyDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const editDocumentType = (formData) => async () => {
  try {
    const res = await DocumentService.editDocumentType(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const editCompanyDocumentTypeGroup = (formData) => async () => {
  try {
    const res = await DocumentService.editCompanyDocumentTypeGroup(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const deleteEmployeeDocumentInfo = (formData) => async (dispatch) => {
  try {
    const res = await DocumentService.deleteEmployeeDocumentInfo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const deleteCompanyDocumentInfo = (formData) => async (dispatch) => {
  try {
    const res = await DocumentService.deleteCompanyDocumentInfo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const deleteCompanyDocumentGroup = (formData) => async (dispatch) => {
  try {
    const res = await DocumentService.deleteCompanyDocumentGroup(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const deleteCompanyDocument = (formData) => async (dispatch) => {
  try {
    const res = await DocumentService.deleteCompanyDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getSmartDocumentsTextInfoById = (smartDocumentId, UserProfile, companyProfile) => async (dispatch) => {
  try {
    dispatch({
      type: SMART_DOCUMENT_FETCHING_FILE,
    });
    const res = await DocumentService.getSmartDocumentsTextInfoById(smartDocumentId, UserProfile, companyProfile);
    if (res && res.data) {
      dispatch({
        type: SMART_DOCUMENT_SUCCESS_FILE,
        payload: res.data,
      });
      return res;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (err) {
    dispatch({
      type: SMART_DOCUMENT_FAILED_FILE,
    });
    console.log(err);
  }
};

export const addSmartDocument = (formData) => async () => {
  try {
    const res = await DocumentService.addSmartDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const editSmartDocument = (formData) => async () => {
  try {
    const res = await DocumentService.editSmartDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const deleteSmartDocumentInfo = (formData) => async (dispatch) => {
  try {
    const res = await DocumentService.deleteSmartDocumentInfo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getSmartDocumentHtmlById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SMART_DOCUMENT_FETCHING,
    });
    const res = await DocumentService.getSmartDocumentHtmlById(id);
    if (res) {
      dispatch({
        type: SMART_DOCUMENT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SMART_DOCUMENT_FAILED,
    });
    return error;
  }
};

export const upsertPdfSmartDocument = (formData) => async () => {
  try {
    const res = await DocumentService.upsertPdfSmartDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const upsertAdditionalSmartDocument = (formData) => async () => {
  try {
    const res = await DocumentService.upsertAdditionalSmartDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteAdditionalSmartDocument = (id) => async () => {
  try {
    const res = await DocumentService.deleteAdditionalSmartDocument(id);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};