import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Paper,
  Divider,
  Avatar,
  InputAdornment,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getKpiCalibrateDepartment } from "../../../../../actions/kpi";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { useTranslation } from "react-i18next";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Sector,
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip as RechartsTooltip
} from "recharts";
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import ButtonBlue from "../../../../pages/shared/general/ButtonBlue";
import TextFieldTheme from "../../../../pages/shared/general/TextFieldTheme";
import { styled } from "@mui/system";
import TableCustom from "../../../../pages/shared/tableCustom/index";
import Autocomplete from "@mui/material/Autocomplete";
import { getUserFullName } from "../../../../../utils/userData";

const COLORS = ['#FFB200', '#EB5B00', '#E4003A', '#B60071'];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const gradeData = payload[0]; 

    const gradeName = gradeData.payload.grade; 
    const count = gradeData.payload.count; 

    const totalCount = payload.reduce((sum, entry) => sum + (entry.payload.grade === gradeName ? entry.payload.count : 0), 0);

    return (
      <div style={{ 
        backgroundColor: '#fff', 
        padding: '10px', 
        border: '1px solid #ccc', 
        borderRadius: '8px', 
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' 
      }}>
        <p style={{ margin: 0, color: gradeData.payload.fill, fontWeight: 'bold' }}>{`Grade ${gradeName}`}</p>
        <p style={{ color: gradeData.payload.fill, margin: 0 }}>
          {`จำนวนคน: ${totalCount}`} 
        </p>
      </div>
    );
  }
  return null;
};

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StylePaper = styled(Paper)({
  padding: "20px 40px 20px 40px",
  minWidth: 350,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  marginTop: "20px",
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextFieldTheme = styled(TextFieldTheme)({
  "& .MuiFilledInput-root": {
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
    paddingBottom: 0,
    height: 56,
  },
  "& .MuiFilledInput-input": {
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: "56px",
  },
  "& .MuiInputAdornment-root": {
    marginTop: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "24px",
    verticalAlign: "middle",
    marginTop: "-15px",
  },
});

const ActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={20}
        textAnchor="middle"
        fill={fill}
        style={{
          fontSize: "54px",
          fontWeight: "500"
        }}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const EmployeeSearch = ({ onSearch, companies, departments, sections }) => {
  const { t, i18n } = useTranslation();
  const useHookForm = useForm({
    defaultValues: {
      company: { idCompany: "all", companyName: `${t("All")}`, companyName_EN: t("All") },
      department: { idDepartment: "all", departmentName: `${t("All")}`, departmentName_EN: t("All") },
      section: { idSection: "all", sectionName: `${t("All")}`, sectionName_EN: t("All") },
    },
    mode: "all",
  });

  const handleSearchSubmit = (data) => {
    onSearch(data);
  };

  return (
    <form onSubmit={useHookForm.handleSubmit(handleSearchSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ marginTop: '-30px' }}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Company")}
            </Typography>
            <Controller
              name="company"
              control={useHookForm.control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={[
                    { idCompany: "all", companyName: `${t("All")}`, companyName_EN: `${t("All")}` },
                    ...companies,
                  ]}
                  getOptionLabel={(option) =>
                    i18n.resolvedLanguage === "th" ? option.companyName : option.companyName_EN
                  }
                  isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                  renderInput={(params) => (
                    <StyledTextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("Company")}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onChange={(_, value) => field.onChange(value)} 
                  disableClearable
                />
              )}
            />
          </StyledBoxSearch>
        </Grid>

        <Grid item xs={12} md={4} sx={{ marginTop: '-30px' }}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Department")}
            </Typography>
            <Controller
              name="department"
              control={useHookForm.control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={[
                    { idDepartment: "all", departmentName: `${t("All")}` },
                    ...departments,
                  ]}
                  getOptionLabel={(option) => option.departmentName}
                  renderInput={(params) => (
                    <StyledTextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("Department")}
                    />
                  )}
                  onChange={(_, value) => field.onChange(value)} 
                  disableClearable
                />
              )}
            />
          </StyledBoxSearch>
        </Grid>

        <Grid item xs={12} md={4} sx={{ marginTop: '-30px' }}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Section")}
            </Typography>
            <Controller
              name="section"
              control={useHookForm.control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={[
                    { idSection: "all", sectionName: `${t("All")}` },
                    ...sections,
                  ]}
                  getOptionLabel={(option) => option.sectionName}
                  renderInput={(params) => (
                    <StyledTextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("Section")}
                    />
                  )}
                  onChange={(_, value) => field.onChange(value)} 
                  disableClearable
                />
              )}
            />
          </StyledBoxSearch>
        </Grid>

        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonBlue variant="contained" type="submit">
            {t("Search")}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  );
};


function calculateAverage(rowData) {
  if (rowData.kpi && rowData.kpi.length > 0) {
    let weightedSum = 0; 
    let totalWeight = 0;

    for (let i = 0; i < rowData.kpi.length; i++) {
      const managerRating = rowData.kpi[i].managerRating;
      const weight = parseInt(rowData.kpi[i].weight);

      if (managerRating !== null) {
        weightedSum += parseFloat(managerRating) * weight; 
        totalWeight += weight; 
      }
    }

    const average = totalWeight > 0 ? (weightedSum / totalWeight) : 0;
    return (average / 5) * 100; 
  }

  return 0; 
}

function DepartmentCalibrate() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [calibrateData, setCalibrateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [calibrateNoData, setCalibrateNoData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("all");
  const [activeIndex, setActiveIndex] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    let isMounted = true;
    dispatch(getKpiCalibrateDepartment())
      .then((res) => {
        if (isMounted && res && res.status) {
          if (res.status === 200) {
            const departmentData = res.data.filter((item) => item.KpiGrade);
            const departmentNoData = res.data.filter((item) => !item.KpiGrade);

            setCalibrateData(departmentData);
            setFilteredData(departmentData);
            setCalibrateNoData(departmentNoData);

            const uniqueSections = Array.from(new Set(res.data.map(item => item.sectionName)))
              .map(sectionName => ({
                idSection: sectionName,
                sectionName
              }));
            
            setSections(uniqueSections);
          } else {
            console.error(`Received non-200 status code: ${res.status}`);
          }
        } else {
          console.error("Invalid response format:", res);
        }
      })
      .catch((error) => {
        console.error("Error fetching KPI department data:", error);
      });

    dispatch(getAffiliateOrganization())
      .then((res) => {
        if (res && res.status === 200) {
          setCompanies(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching companies:", error);
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const allGrades = filteredData.reduce((acc, item) => {
    return acc.concat(item.KpiGrade);
  }, []);

  const departmentsData = filteredData.reduce((acc, item) => {
    const existingDepartment = acc.find((dept) => dept.idDepartment === item.idDepartment);
    const gradesArray = Array.from(item.KpiGrade);

    if (existingDepartment) {
      existingDepartment.KpiGrade = existingDepartment.KpiGrade.concat(gradesArray);
    } else {
      acc.push({ idDepartment: item.idDepartment, departmentName: item.departmentName, KpiGrade: gradesArray });
    }

    return acc;
  }, []);

  const pieChartData = (grades) => {
    const gradeCount = { A: 0, B: 0, C: 0, D: 0 };
    grades.forEach((grade) => {
      if (gradeCount[grade]) {
        gradeCount[grade]++;
      } else {
        gradeCount[grade] = 1;
      }
    });
    return Object.keys(gradeCount).map((key) => ({
      name: key,
      value: gradeCount[key],
      fill: COLORS[key.charCodeAt(0) - 65],
    }));
  };

  const handlePieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleSearch = (data) => {
    const selectedCompany = data.company.idCompany;
    const selectedDept = data.department.idDepartment;
    const selectedSection = data.section.idSection;

    const filteredData = calibrateData.filter((item) => {
      const matchCompany = selectedCompany === "all" || item.idCompany === selectedCompany;
      const matchDepartment = selectedDept === "all" || item.idDepartment === selectedDept;
      const matchSection = selectedSection === "all" || item.sectionName === selectedSection;
      return matchCompany && matchDepartment && matchSection;
    });

    setFilteredData(filteredData);
    setSelectedDepartment(filteredData);
  };

  const selectedGrades = selectedDepartment === "all" 
    ? allGrades 
    : (departmentsData.find(dept => dept.idDepartment === selectedDepartment.idDepartment) || {}).KpiGrade || [];

  const rows = filteredData.map(item => ({
    ...item,
    name: `${getUserFullName(item)}`,
    score: calculateAverage(item), 
    KpiGrade: item.KpiGrade,
  })).sort((a, b) => b.score - a.score); 

  const columns = [
    {
      name: t("Name"),
      fieldKey: "name",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar src={row.imageURL} alt={`${getUserFullName(row)}`} sx={{ marginRight: 2 }} />
          <Typography>{`${getUserFullName(row)}`}</Typography>
        </Box>
      ),
    },
    { name: t("Division"), fieldKey: "divisionName" },
    { name: t("Department"), fieldKey: "departmentName" },
    { name: t("Section"), fieldKey: "sectionName" },
    { name: t("Position"), fieldKey: "positionName" },
    {
      name: t("Score"),
      fieldKey: "score",
      cellRender: (row) => {
        const score = calculateAverage(row); 
        return score.toFixed(2); 
      },
    },
    { name: t("Grade"), fieldKey: "KpiGrade" },
  ];

const bellCurveData = filteredData.reduce((acc, item) => {
  const score = calculateAverage(item); 
  const grade = item.KpiGrade; 

  const existingEntry = acc.find((entry) => entry.score === score && entry.grade === grade);

  if (existingEntry) {
    existingEntry.count += 1;
  } else {
    acc.push({ score, count: 1, grade }); 
  }

  return acc;
}, []);

const hasStartPoint = bellCurveData.some((entry) => entry.score === 0 && entry.grade === 'D');
const hasEndPoint = bellCurveData.some((entry) => entry.score === 100 && entry.grade === 'A');

if (!hasStartPoint) {
  bellCurveData.unshift({ score: 0, count: 0, grade: 'D' }); 
}

if (!hasEndPoint) {
  bellCurveData.push({ score: 100, count: 0, grade: 'A' }); 
}
  
  return (
    <Box padding={"16px"}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={6}>
        <Typography fontWeight={700} color={"#4f65df"} fontSize={"1.5rem"}>
          {`${t("DepartmentalPerformanceSummary")}`}
        </Typography>
      </Box>
      <EmployeeSearch onSearch={handleSearch} companies={companies} departments={departmentsData} sections={sections} />
      {/* <Box marginTop={-5} marginBottom={3}>
        <StylePaper>
          <ResponsiveContainer width="100%" height={520}>
            <PieChart>
              <Pie
                data={pieChartData(selectedGrades)}
                dataKey="value"
                outerRadius={200}
                innerRadius={100}
                fill="#FF9A00"
                activeIndex={activeIndex}
                activeShape={ActiveShape}
                onMouseEnter={handlePieEnter}
              >
                {pieChartData(selectedGrades).map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </StylePaper>
      </Box> */}
      <Box marginTop={5} marginBottom={5}>
        <StylePaper>
        <ResponsiveContainer width="100%" height={400}   style={{ transform: 'translateX(-20px)' }}>
        <AreaChart data={bellCurveData}>
          <XAxis
            type="number"
            dataKey="score"
            domain={[0, 100]}
            label={{ value: "Score", position: 'insideBottom', offset: -5 }}
          />
          <YAxis
            type="number"
            dataKey="count"
            domain={[0, 'dataMax']}
            // label={{ value: "Number of Employees", angle: -90, position: 'center', dy: 0, dx: -30 }}
          />
          <RechartsTooltip content={<CustomTooltip />} />

          {bellCurveData.some(d => d.grade === 'D') && (
            <Area
              type="monotone"
              dataKey="count"
              data={bellCurveData.filter(d => d.grade === 'D')}
              stroke="#B60071"
              fill="#B60071"
              fillOpacity={1}
              name="Grade D"
            />
          )}

          {bellCurveData.some(d => d.grade === 'C') && (
            <Area
              type="monotone"
              dataKey="count"
              data={bellCurveData.filter(d => d.grade === 'C')}
              stroke="#E4003A"
              fill="#E4003A"
              fillOpacity={1}
              name="Grade C"
            />
          )}

          {bellCurveData.some(d => d.grade === 'B') && (
            <Area
              type="monotone"
              dataKey="count"
              data={bellCurveData.filter(d => d.grade === 'B')}
              stroke="#EB5B00"
              fill="#EB5B00"
              fillOpacity={1}
              name="Grade B"
            />
          )}

          {bellCurveData.some(d => d.grade === 'A') && (
            <Area
              type="monotone"
              dataKey="count"
              data={bellCurveData.filter(d => d.grade === 'A')}
              stroke="#FFB200"
              fill="#FFB200"
              fillOpacity={1}
              name="Grade A"
            />
          )}

          <Legend
            verticalAlign="bottom"
            align="center"
            wrapperStyle={{
              paddingTop: '20px',
              transform: 'translateX(40px)',
            }}
            payload={[
              { value: 'A', type: 'square', color: '#FFB200' },
              { value: 'B', type: 'square', color: '#EB5B00' },
              { value: 'C', type: 'square', color: '#E4003A' },
              { value: 'D', type: 'square', color: '#B60071' },
            ]}
          />
        </AreaChart>
      </ResponsiveContainer>
        </StylePaper>
      </Box>
      <Grid container spacing={2} marginBottom={5}>
        {calibrateNoData.map((item) => (
          <Grid item xs={12} md={6} lg={4} key={item.idDepartment}>
            <Card sx={{ backgroundColor: "#f7f7f7", borderRadius: "15px"}}>
              <CardHeader title={item.departmentName} />
              <CardContent>
                <Typography textAlign={"center"}>{`${t("NoData")}`}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <StyledDivider />
      <Box marginTop={3}>
        <TableCustom
          columns={columns}
          rows={rows}
          canSelect={false}
          selectedRows={[]}
          setSelectedRows={() => {}}
        />
      </Box>
    </Box>
  );
}

export default DepartmentCalibrate;
